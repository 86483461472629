import React from "react";

function SampleIcon({ style, fill }) {
  return (
    <svg
      style={style}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          style={fill}
          d="M1330 4476 c-677 -271 -1215 -492 -1227 -504 -26 -24 -30 -68 -9 -97
     9 -14 273 -124 775 -325 419 -167 761 -307 761 -310 0 -3 -342 -143 -761 -310
     -502 -201 -766 -311 -775 -325 -18 -25 -18 -65 0 -90 9 -14 273 -124 775 -325
     419 -167 761 -307 761 -310 0 -3 -342 -143 -761 -310 -502 -201 -766 -311
     -775 -325 -19 -27 -18 -69 4 -93 24 -29 2415 -986 2462 -986 47 0 2438 957
     2462 986 22 24 23 66 4 93 -9 14 -273 124 -775 325 -419 167 -761 307 -761
     310 0 3 342 143 761 310 502 201 766 311 775 325 18 25 18 65 0 90 -9 14 -273
     124 -775 325 -419 167 -761 307 -761 310 0 3 342 143 761 310 502 201 766 311
     775 325 19 27 18 69 -3 93 -20 24 -2408 984 -2458 988 -21 2 -400 -145 -1235
     -480z m2328 -121 c590 -236 1072 -432 1072 -435 0 -3 -488 -201 -1085 -440
     l-1085 -434 -1085 434 c-597 239 -1085 437 -1085 440 0 11 2155 870 2176 867
     11 -2 503 -196 1092 -432z m-1451 -1340 c186 -74 344 -135 353 -135 8 0 169
     62 357 137 l343 137 735 -294 c404 -162 735 -297 735 -300 0 -3 -488 -201
     -1085 -440 l-1085 -434 -1085 434 c-597 239 -1085 437 -1085 440 0 7 1448 589
     1468 590 6 0 164 -61 349 -135z m0 -1360 c186 -74 344 -135 353 -135 8 0 169
     62 357 137 l343 137 735 -294 c404 -162 735 -297 735 -300 0 -3 -488 -201
     -1085 -440 l-1085 -434 -1085 434 c-597 239 -1085 437 -1085 440 0 7 1448 589
     1468 590 6 0 164 -61 349 -135z"
        />
      </g>
    </svg>
  );
}

export default SampleIcon;
