/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import { Delete as DeleteIcon, Save as SaveIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  apiGetDepartmentDetails,
  apiOrganizationRemoveMembers,
  apiUpdateDepartmentDetails,
} from "src/api";
import { VirtualMembersTable } from "../membersTable";
import AddMemberDialog from "../addMembersDialog";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import Permissions from "../permissions";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  main: {
    marginTop: theme.spacing(4),
  },
  summary: {
    [theme.breakpoints.up("sm")]: {
      borderRight: "1px solid #e1e1e1",
    },
  },
  formTitle: {
    marginBottom: theme.spacing(3),
  },
  formActions: {
    width: "100%",
    marginTop: "10px",
  },
  formField: {
    marginBottom: "15px",
  },
}));

const SummaryForm = (props) => {
  const classes = useStyles();
  const { departmentData, setDepartmentData, organization_id, id } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formElements = event.target.elements;
    const body = {
      name: formElements["name"].value,
      description: formElements["description"].value
        ? formElements["description"].value
        : null,
    };
    setLoading(true);
    apiUpdateDepartmentDetails(organization_id, id, body, (data, status) => {
      setLoading(false);
      if (status === 200) {
        setDepartmentData(data);
        enqueueSnackbar(t("Department information was changed successfully."), {
          variant: "success",
        });
      }
    });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel className={classes.formTitle} component="legend">
            {t("Summary")}
          </FormLabel>
          <FormGroup>
            <TextField
              defaultValue={departmentData.name}
              variant="outlined"
              label={t("Name")}
              name={"name"}
              required
            />
            <TextField
              style={{ marginTop: "10px", marginBottom: "15px" }}
              fullWidth={true}
              multiline={true}
              variant="outlined"
              label={t("Description")}
              name="description"
              defaultValue={departmentData.description ?? ""}
            />
          </FormGroup>
        </FormControl>
        <div className={classes.formActions}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<SaveIcon />}
            disabled={loading}
          >
            {t("Save")}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </div>
      </form>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return { userObject: state.userObject, appConf: state.appConf };
};

const ConnectedEditDepartment = (props) => {
  const classes = useStyles();
  const { userObject, appConf } = props;
  const { id } = useParams();
  const [departmentData, setDepartmentData] = useState(undefined);
  const [currentMemberIds, setCurrentMemberIds] = useState(undefined);
  const [checkedRows, setCheckedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [removeMemberDialogOpen, setRemoveMemberDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleRemoveMemberDialogOpen = () => {
    setRemoveMemberDialogOpen(true);
  };
  const handleRemoveMemberDialogClose = () => {
    setRemoveMemberDialogOpen(false);
  };

  const handleRemoveMembers = () => {
    setLoading(true);
    apiOrganizationRemoveMembers(
      userObject.activeOrganization.id,
      "department",
      id,
      checkedRows,
      (data, status) => {
        setLoading(false);
        handleRemoveMemberDialogClose();
        if (status === 200) {
          setDepartmentData(data);
          setCheckedRows([]);
          enqueueSnackbar(t("Selected members were removed successfully."), {
            variant: "success",
          });
        }
      }
    );
  };

  useEffect(() => {
    let mounted = true;
    apiGetDepartmentDetails(
      userObject.activeOrganization.id,
      id,
      (data, status) => {
        if (status === 200) {
          if (mounted) {
            setDepartmentData(data);
          }
        }
      }
    );

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (departmentData) {
      const memberIds = departmentData.members.reduce(
        (arr, currVal) => arr.concat([currVal.id]),
        []
      );
      setCurrentMemberIds(memberIds);
    }
  }, [departmentData]);

  if (!departmentData || !currentMemberIds) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Account")}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" width="100%">
                <Typography component="h2" variant="h2">
                  {t(
                    "edit_department_page_title",
                    "Edit department - {{department_name}}",
                    {
                      department_name: departmentData.name,
                    }
                  )}
                </Typography>
                <AddMemberDialog
                  organization_id={userObject.activeOrganization.id}
                  department_id={id}
                  type="department"
                  currentMemberIds={currentMemberIds}
                  setDepartmentData={setDepartmentData}
                />
              </Box>
              <Box>
                <Typography variant="body2">
                  {t("edit_department_subtitle", "{{members_count}} members", {
                    members_count: departmentData.members.length,
                  })}
                </Typography>
              </Box>
            </Grid>
            <Grid item container spacing={8} xs={12} className={classes.main}>
              <Grid item xs={12} sm={6} className={classes.summary}>
                <SummaryForm
                  departmentData={departmentData}
                  setDepartmentData={setDepartmentData}
                  organization_id={userObject.activeOrganization.id}
                  id={id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Permissions
                  appConf={appConf}
                  permissions={departmentData.permissions}
                  organization_id={userObject.activeOrganization.id}
                  id={id}
                  type="department"
                  membersCount={departmentData.members.length}
                />
              </Grid>
            </Grid>
            {Boolean(departmentData.members.length) && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" mt={6} mb={2}>
                    <Typography variant="h4" component="h4">
                      {t("Members")}
                    </Typography>
                    <Button
                      style={{ marginLeft: "auto" }}
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      color="secondary"
                      disabled={!Boolean(checkedRows.length) || loading}
                      onClick={handleRemoveMemberDialogOpen}
                    >
                      {t("Remove members")}
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                    <Dialog
                      open={removeMemberDialogOpen}
                      onClose={handleRemoveMemberDialogClose}
                      aria-labelledby="remove-members-dialog-title"
                      aria-describedby="remove-members-dialog-description"
                    >
                      <DialogTitle id="remove-members-dialog-title">
                        {t("Remove members")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="remove-members-dialog-description">
                          {t(
                            "remove_members_prompt_message",
                            "The selected members will be removed. Please note that removing members will not change the permissions set to the members that will be removed."
                          )}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleRemoveMemberDialogClose}
                          color="secondary"
                        >
                          {t("Cancel")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleRemoveMembers}
                          disabled={loading}
                        >
                          {t("Proceed")}
                          {loading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <VirtualMembersTable
                    checkedRows={checkedRows}
                    setCheckedRows={setCheckedRows}
                    membersData={departmentData.members}
                    memberIds={currentMemberIds}
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Container>
      </Page>
    );
  }
};

export const EditDepartment = connect(mapStateToProps)(ConnectedEditDepartment);
