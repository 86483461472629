/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Checkbox,
  colors,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Slider,
  Tooltip,
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Rating } from "@material-ui/lab";
import { SelectFilterHead } from "../virtualizedTable/selectFilterHead";
import { VirtualizedAutocomplete } from "../virtualizedTable/autoComplete";

const useStyles = makeStyles((theme) => ({
  label: {
    color: colors.grey[600],
    marginRight: theme.spacing(1),
  },
  filterBtn: {
    padding: "5px",

    "& svg": {
      width: "0.8em",
      height: "0.8em",
    },
    "& .filtered path": {
      color: colors.orange[500],
    },
  },
  sliderMenu: {
    "& ul": {
      width: "350px",
      padding: "40px 20px 20px",
    },
  },
  applyFiltersBtn: {
    color: theme.palette.common.white,
    background: colors.green[400],
    "&:hover": {
      background: colors.green[600],
    },
  },
}));

export const FilterButton = (props) => {
  const classes = useStyles();
  const {
    colIndexes,
    colDetails,
    setColDetails,
    filterState,
    column,
    filterType,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [rangeStep, setRangeStep] = useState(null);
  const [checked, setChecked] = useState(
    colDetails[colIndexes[column.name]]?.filterRange
      ? colDetails[colIndexes[column.name]]?.filterRange?.length >= 3
        ? true
        : false
      : false,
  );

  const [activeRange, setActiveRange] = useState([
    colDetails[colIndexes[column.name]]?.filterRange
      ? colDetails[colIndexes[column.name]]?.filterRange[0]
      : colDetails[colIndexes[column.name]]?.minValue,
    colDetails[colIndexes[column.name]]?.filterRange
      ? colDetails[colIndexes[column.name]]?.filterRange[1]
      : colDetails[colIndexes[column.name]]?.maxValue,
  ]);

  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [tmpColumns, setTmpColumns] = useState([...colDetails]);

  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    if (filterType === "range") {
      if (
        colDetails[colIndexes[column.name]] &&
        colDetails[colIndexes[column.name]].filterRange &&
        colDetails[colIndexes[column.name]]?.filterRange[1] -
          colDetails[colIndexes[column.name]]?.filterRange[0] <
          10
      ) {
        if (mounted) {
          setRangeStep(
            (colDetails[colIndexes[column.name]]?.filterRange[1] -
              colDetails[colIndexes[column.name]]?.filterRange[0]) /
              20,
          );
        }
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setTmpColumns([...colDetails]);
    if (filterType === "search") {
      if (
        colDetails[colIndexes[column.name]] &&
        !colDetails[colIndexes[column.name]].activeFilterCount
      ) {
        setAutoCompleteValue([]);
      } else {
        const newValue = [];
        if (colDetails[colIndexes[column.name]]?.filterValues) {
          Object.keys(
            colDetails[colIndexes[column.name]]?.filterValues,
          ).forEach((key) => {
            if (colDetails[colIndexes[column.name]]?.filterValues[key]) {
              newValue.push(key);
            }
          });
        }
        setAutoCompleteValue(newValue);
      }
    }
  }, [colDetails]);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
      setTmpColumns([...colDetails]);
      if (filterType === "search") {
        const newAutocompleteValues = [];
        for (let item in colDetails[colIndexes[column.name]].filterValues) {
          if (colDetails[colIndexes[column.name]].filterValues[item]) {
            newAutocompleteValues.push(item);
          }
        }
        setAutoCompleteValue(newAutocompleteValues);
      }
    }
    setAnchorEl(null);
  };

  const handleApplyRange = (event, newValue) => {
    event.stopPropagation();
    const newColDetails = JSON.parse(JSON.stringify(colDetails));

    if (
      newValue[0] !== newColDetails[colIndexes[column.name]].minValue ||
      newValue[1] !== newColDetails[colIndexes[column.name]].maxValue
    ) {
      newColDetails[colIndexes[column.name]].activeFilterCount = 1;
    } else {
      newColDetails[colIndexes[column.name]].activeFilterCount = 0;
    }

    if (checked) {
      newColDetails[colIndexes[column.name]].activeFilterCount = 1;
      newValue.push("null");
    }

    newColDetails[colIndexes[column.name]].filterRange = newValue;

    setActiveRange(newValue);
    setColDetails(newColDetails);
  };

  const handleRangeChange = (event, newValue) => {
    event.stopPropagation();
    setActiveRange(newValue);
  };

  const checkboxHandle = (e) => {
    setChecked(e.target.checked);
    // handleApplyRange({ stopPropagation: () => {} }, activeRange);
  };

  const handleSelectAll = () => {
    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));

    if (
      !newColDetails[colIndexes[column.name]].activeFilterCount ||
      (newColDetails[colIndexes[column.name]].activeFilterCount &&
        newColDetails[colIndexes[column.name]].filterValues &&
        newColDetails[colIndexes[column.name]].activeFilterCount !==
          Object.keys(newColDetails[colIndexes[column.name]].filterValues)
            .length)
    ) {
      const newAutocompleteValues = [];
      for (let item in newColDetails[colIndexes[column.name]].filterValues) {
        newAutocompleteValues.push(item);
        if (!newColDetails[colIndexes[column.name]].filterValues[item]) {
          newColDetails[colIndexes[column.name]].filterValues[item] = true;
          newColDetails[colIndexes[column.name]].activeFilterCount++;
        }
      }
      if (filterType === "search") {
        setAutoCompleteValue(newAutocompleteValues);
      }
    } else {
      for (let item in newColDetails[colIndexes[column.name]].filterValues) {
        newColDetails[colIndexes[column.name]].filterValues[item] = false;
      }
      newColDetails[colIndexes[column.name]].activeFilterCount = 0;
      if (filterType === "search") {
        setAutoCompleteValue([]);
      }
    }

    setTmpColumns(newColDetails);
  };

  const handleAutocompleteChange = (event, activeValues) => {
    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));

    const filterKeys = newColDetails[colIndexes[column.name]].filterValues
      ? Object.keys(newColDetails[colIndexes[column.name]].filterValues)
      : [];
    for (let i = 0; i < filterKeys.length; i++) {
      if (
        activeValues.includes(filterKeys[i]) &&
        !newColDetails[colIndexes[column.name]].filterValues[filterKeys[i]]
      ) {
        newColDetails[colIndexes[column.name]].filterValues[filterKeys[i]] =
          true;
        newColDetails[colIndexes[column.name]].activeFilterCount++;
        continue;
      } else if (
        !activeValues.includes(filterKeys[i]) &&
        newColDetails[colIndexes[column.name]].filterValues[filterKeys[i]]
      ) {
        newColDetails[colIndexes[column.name]].filterValues[filterKeys[i]] =
          false;
        newColDetails[colIndexes[column.name]].activeFilterCount--;
        continue;
      }
    }

    setTmpColumns(newColDetails);
    setAutoCompleteValue(activeValues);
  };

  const handleSelect = (event, item) => {
    event.stopPropagation();

    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));

    if (!newColDetails[colIndexes[column.name]].filterValues[item]) {
      newColDetails[colIndexes[column.name]].activeFilterCount++;
    } else {
      newColDetails[colIndexes[column.name]].activeFilterCount--;
    }

    newColDetails[colIndexes[column.name]].filterValues[item] =
      !newColDetails[colIndexes[column.name]].filterValues[item];

    setTmpColumns(newColDetails);
  };

  const handleApplyFilters = () => {
    setColDetails(tmpColumns);
    handleClose();
  };

  if (!column || !colIndexes) {
    return "";
  } else {
    return (
      <React.Fragment>
        <IconButton
          className={classes.filterBtn}
          aria-controls="filter-menu-button"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <FilterListIcon
            style={{ color: "#388e3c" }}
            className={clsx({
              filtered:
                filterState && Object.keys(filterState).includes(column.name),
            })}
          />
        </IconButton>
        {filterType === "range" ? (
          <Menu
            onClick={(event) => event.stopPropagation()}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.sliderMenu}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Slider
                value={activeRange}
                onChange={handleRangeChange}
                onChangeCommitted={handleApplyRange}
                getAriaValueText={(value) => value}
                aria-labelledby={`${column.name}-silder`}
                min={colDetails[colIndexes[column.name]].minValue}
                max={colDetails[colIndexes[column.name]].maxValue}
                step={rangeStep ? rangeStep : 1}
                valueLabelDisplay="on"
              />
              {["details3.af", "details3.af_1000g"].includes(
                colDetails[colIndexes[column.name]].name,
              ) && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={t("Filters empty values")}>
                    <Checkbox
                      style={{ marginLeft: 25 }}
                      checked={checked}
                      onChange={checkboxHandle}
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={t("Apply filters")}>
                    <IconButton
                      onClick={(e) => {
                        handleApplyRange(e, activeRange);
                      }}
                      className={classes.applyFiltersBtn}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Menu>
        ) : filterType === "select" ? (
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <SelectFilterHead
              handleSelectAll={handleSelectAll}
              tmpColumns={tmpColumns}
              columnIndex={colIndexes[column.name]}
              handleApplyFilters={handleApplyFilters}
              classes={classes}
            />
            {tmpColumns[colIndexes[column.name]] &&
              tmpColumns[colIndexes[column.name]].filterValues &&
              Object.entries(
                tmpColumns[colIndexes[column.name]].filterValues,
              ).map((item, index) => {
                let altLabel;
                if (
                  colDetails[colIndexes[column.name]].representation.options
                    .component === "star-rating"
                ) {
                  altLabel =
                    item[0] >= 0 ? (
                      <Rating
                        className={classes.starRating}
                        name="revstat"
                        readOnly
                        max={4}
                        key={index}
                        value={parseFloat(item[0])}
                        precision={0.1}
                      />
                    ) : (
                      ""
                    );
                } else if (
                  colDetails[colIndexes[column.name]].representation.options
                    .truncate === true
                ) {
                  altLabel = item[0].split(".")[0];
                }
                return (
                  <MenuItem
                    onClick={(e) => handleSelect(e, item[0])}
                    key={index}
                    data-index={index}
                  >
                    <Checkbox
                      checked={item[1]}
                      inputProps={{ "aria-labelledby": index }}
                    />
                    {altLabel ?? item[0]}
                  </MenuItem>
                );
              })}
          </Menu>
        ) : filterType === "search" ? (
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={(event) => event.stopPropagation()}
          >
            <SelectFilterHead
              handleSelectAll={handleSelectAll}
              tmpColumns={tmpColumns}
              columnIndex={colIndexes[column.name]}
              handleApplyFilters={handleApplyFilters}
              classes={classes}
            />
            <MenuItem onKeyDown={(e) => e.stopPropagation()}>
              <VirtualizedAutocomplete
                classes={classes}
                columns={tmpColumns}
                columnIndex={colIndexes[column.name]}
                handleChange={handleAutocompleteChange}
                options={
                  tmpColumns[colIndexes[column.name]] &&
                  tmpColumns[colIndexes[column.name]].filterValues
                    ? Object.keys(
                        tmpColumns[colIndexes[column.name]].filterValues,
                      )
                    : []
                }
                controlledValue={autoCompleteValue}
              />
            </MenuItem>
          </Menu>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
};
