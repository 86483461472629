import { Divider, IconButton, Link, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Download } from "react-feather";
import { connect } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";
import { userHasPermission } from "src/components/utils";
import { getBaseURL } from "src/config/api";

function ConnectedSeeText({ userObj }) {
  let [searchParams] = useSearchParams();
  let [txt, setTxt] = useState("");
  const regex = new RegExp(/(\r\n?|\n)/g);
  useEffect(() => {
    fetch(getBaseURL() + searchParams.get("download_address"))
      .then((response) => response.text())
      .then((value) => setTxt(value));
  }, []);

  return (
    <>
      {userHasPermission(userObj.activePermissions, "diagnose_analysis") ? (
        <>
          <div
            style={{
              display: "flex",
              padding: 16,
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "wheat",
              position: "sticky",
              top: 0,
            }}
          >
            <Typography color="primary">
              File Name :
              <Typography>{searchParams.get("file_name")}</Typography>
            </Typography>
            <Link
              target="_blank"
              href={getBaseURL() + searchParams.get("download_address")}
            >
              <IconButton color="primary">
                <Download />
              </IconButton>
            </Link>
          </div>
          <Divider />
          <div
            style={{ padding: 16 }}
            dangerouslySetInnerHTML={{
              __html: txt
                .replace(regex, "<br/>")
                .replace(new RegExp(/\t/g), `&nbsp;`),
            }}
          />
        </>
      ) : (
        <Navigate to={"/403"} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userObj: state.userObject,
});

const SeeText = connect(mapStateToProps)(ConnectedSeeText);

export default SeeText;
