/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getBaseURL } from "src/config/api";
import igv from "../../../../node_modules/igv/dist/igv.esm";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  hidden: {
    display: "none",
  },
}));

export const generateLocus = (locationString) => {
  const location = locationString.split("_");
  const lowerBound = parseInt(location[1]) - 20;
  const upperBound = parseInt(location[1]) + 20;
  const locus =
    "chr" +
    location[0] +
    ":" +
    lowerBound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    "-" +
    upperBound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return locus;
};

export const IGVBrowser = (props) => {
  const classes = useStyles();
  const { location, files, reportMeta } = props;
  const [igvDiv, setIgvDiv] = useState(null);
  const [locus, setLocus] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.addedNodes.length) {
        setLoading(false);
        observer.disconnect();
      }
    });
  });

  useEffect(() => {
    observer.observe(document.getElementById("igv-div"), { childList: true });
    setIgvDiv(document.getElementById("igv-div"));

    return () => {
      setLocus(null);
      setIgvDiv(null);
      igv.removeAllBrowsers();
      igv.browser = undefined;
    };
  }, []);

  useEffect(() => {
    if (location) {
      igv.removeAllBrowsers();
      igv.browser = undefined;
      if (reportMeta.project_type === "acnv") {
        setLocus(location);
      } else {
        setLocus(generateLocus(location));
      }
    } else {
      setLocus(null);
      setIgvDiv(null);
      igv.removeAllBrowsers();
      igv.browser = undefined;
    }
  }, [location]);

  useEffect(() => {
    if (!igvDiv) {
      setIgvDiv(document.getElementById("igv-div"));
    } else {
      if (locus) {
        if (!igv.browser) {
          const igvTracks = [];
          if (files["vcf"]) {
            igvTracks.push({
              name: "Variant",
              type: "variant",
              url: getBaseURL() + files["vcf"].download_address,
              indexURL: getBaseURL() + files["vcf_idx"].download_address,
            });
          }
          if (reportMeta.project_type === "trio") {
            igvTracks.push({
              type: "alignment",
              name: "Mother - Alignment",
              url: getBaseURL() + files["bam-mother"].download_address,
              maxFragmentLength: 1000000,
              indexURL: getBaseURL() + files["bai-mother"].download_address,
              sort: {
                chr: "chr" + location.split("_")[0],
                position: location.split("_")[1],
                option: "BASE",
                direction: "ASC",
              },
            });
            igvTracks.push({
              type: "alignment",
              name: "Father - Alignment",
              url: getBaseURL() + files["bam-father"].download_address,
              maxFragmentLength: 1000000,
              indexURL: getBaseURL() + files["bai-father"].download_address,
              sort: {
                chr: "chr" + location.split("_")[0],
                position: location.split("_")[1],
                option: "BASE",
                direction: "ASC",
              },
            });
            igvTracks.push({
              type: "alignment",
              name: "Child - Alignment",
              url: getBaseURL() + files["bam-child"].download_address,
              maxFragmentLength: 1000000,
              indexURL: getBaseURL() + files["bai-child"].download_address,
              sort: {
                chr: "chr" + location.split("_")[0],
                position: location.split("_")[1],
                option: "BASE",
                direction: "ASC",
              },
            });
            // } else if (reportMeta.project_type === "acnv") {
            // igvTracks.push() // all bam files or the samples bam file
          } else {
            if (files["mutect2_bam"] && files["mutect2_bai"]) {
              igvTracks.push({
                name: "Mutect 2",
                type: "alignment",
                url: getBaseURL() + files["mutect2_bam"].download_address,
                indexURL: getBaseURL() + files["mutect2_bai"].download_address,
              });
            }
            if (files["haplotypecaller_bam"] && files["haplotypecaller_bai"]) {
              igvTracks.push({
                name: "Haplotypecaller",
                type: "alignment",
                url:
                  getBaseURL() + files["haplotypecaller_bam"].download_address,
                indexURL:
                  getBaseURL() + files["haplotypecaller_bai"].download_address,
              });
            }
            if (
              !(files["haplotypecaller_bam"] && files["haplotypecaller_bai"]) &&
              !(files["mutect2_bam"] && files["mutect2_bai"])
            )
              igvTracks.push({
                type: "alignment",
                name: "Alignment",
                url: getBaseURL() + files["bam"].download_address,
                maxFragmentLength: 1000000,
                indexURL: getBaseURL() + files["bai"].download_address,
                sort: {
                  chr: "chr" + location.split("_")[0],
                  position: location.split("_")[1],
                  option: "BASE",
                  direction: "ASC",
                },
              });
          }

          setLoading(true);
          igv
            .createBrowser(igvDiv, {
              showCenterGuide:
                reportMeta.project_type !== "acnv" ? true : false,
              locus: locus,
              genome: "hg38",
              tracks: igvTracks,
              trackDefaults: {
                annotation: {
                  // visibilityWindow: 1000,
                  // height: 800,
                  autoHeight: true,
                  altColor: "rgb(0,150,0)",
                },
                alignment: {
                  autoHeight: true,
                  format: "bam",
                  filter: {},
                  colorBy: "strand",
                },
                variant: {
                  autoHeight: true,
                  format: "vcf",
                },
              },
            })
            .then((browser) => {
              igv.browser = browser;
            });
        } else {
          igv.browser.search(locus).then((searchResult) => {
            // safeLogger(searchResult);
          });
        }
      }
    }
  }, [locus, igvDiv]);

  return (
    <React.Fragment>
      <Box>
        <CircularProgress
          className={clsx(classes.spinner, {
            [classes.hidden]: !loading,
          })}
        />
        {!location ? (
          <Box p={4}>
            <Typography>{t("Select a row to see the results")}</Typography>
          </Box>
        ) : null}
        <div id="igv-div" className={classes.root}></div>
      </Box>
    </React.Fragment>
  );
};
