import React, { useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { authSignin } from "src/redux/actions";
import { apiResetPassword, setAuthToken } from "src/api";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { apiSignIn } from "src/api";
import { Copyright } from "../copyright";
import { useNavigate } from "react-router-dom";
import { ReportProblem as ReportProblemIcon } from "@material-ui/icons";
import {
  CssBaseline,
  Grid,
  Container,
  Box,
  Hidden,
  Button,
  TextField,
  Checkbox,
  CircularProgress,
  makeStyles,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundImage: "url('/signinMainBg.png')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100vw",
    height: `calc(100vh - 64px)`,
    display: "flex",
  },
  paper: {
    backgroundImage: "url('/signinBg.jpg')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "1.5em",
    overflow: "hidden",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  errorBox: {
    display: "flex",
    padding: "13px 22px",
    border: "1px solid lightcoral",
    background: "lightpink",
    borderRadius: "8px",
    color: "#414141",
  },
  form: {
    minWidth: "250px", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  signinForm: {
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  poster: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    borderTopRightRadius: "1.5em",
    borderBottomRightRadius: "1.5em",
    boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.4)",
    color: "white",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const formElements = event.target.elements;

    setLoading(true);

    apiResetPassword(
      { action: "generate-token", email: formElements["email"].value },
      (data, status) => {
        setLoading(false);
        if (status === 201) {
          enqueueSnackbar("Password reset link was sent to your email.", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Something went wrong. Please try again later.", {
            variant: "error",
          });
        }
        handleClose();
      }
    );
  };

  return (
    <div style={{ marginLeft: "auto" }}>
      <Link href="#" onClick={handleClickOpen}>
        {t("Forgot password")}
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Reset password")}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              {t("Please enter your email to receive the reset password link.")}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="email"
              label={t("Email")}
              type="email"
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={handleClose} color="primary">
              {t("Cancel")}
            </Button>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t("Send")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.authToken || localStorage.getItem("mbiAuth"),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    authSignin: (data) => dispatch(authSignin(data)),
  };
}

const ConnectedSignInPage = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const errorMessages = {
    // "Request failed with status code 400": t(
    //   "Username and password did not match!"
    // ),
    400: t("Username and password did not match!"),
  };

  const signInCallback = (data, status) => {
    if (status === 200) {
      setSuccess(true);
      props.authSignin(data.token);
      setAuthToken(data.token, () => {
        navigate("/dashboard"); // TODO or the reference page});
      });
    } else {
      // setErrorMessage(errorMessages[data.message]);
      setErrorMessage(errorMessages[status]);
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      username: event.target[0].value,
      password: event.target[2].value,
    };
    setLoading(true);
    sessionStorage.setItem("organizationName", data.username);
    if (data.username === "system") {
      setErrorMessage(errorMessages[400]);
      setLoading(false);
    } else {
      apiSignIn(data, signInCallback);
    }
  };

  return (
    <main>
      <Box
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Container>
          <CssBaseline />
          <Grid container className={classes.paper}>
            <Grid item xs={12} md={6} className={classes.signinForm}>
              <Trans i18nKey="signin-welcome">
                <Typography
                  component="p"
                  variant="h5"
                  style={{ marginBottom: "4px" }}
                >
                  WELCOME TO
                </Typography>
                <img
                  src="/exomeLogo.png"
                  alt="logo"
                  style={{ height: "60px", marginBottom: "4px" }}
                />
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  style={{ marginBottom: "8px" }}
                >
                  MASSIVE ANALYSER
                </Typography>
                <Typography variant="body1">
                  Sign in to your dashboard
                </Typography>
              </Trans>
              {errorMessage && (
                <Box className={classes.errorBox}>
                  <ReportProblemIcon style={{ marginRight: "8px" }} />
                  <Typography>{errorMessage}</Typography>
                </Box>
              )}
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("Email Address")}
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label={t("Remember me")}
                  />
                  <ForgotPassword />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={clsx(classes.submit, {
                    [classes.buttonSuccess]: success,
                  })}
                  disabled={loading}
                >
                  {t("Sign in")}
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </form>
            </Grid>
            <Hidden smDown>
              <Grid id="sign_in" item xs={12} md={6} className={classes.poster}>
                <img
                  src="/exomeLogoWhite.png"
                  alt="logo"
                  style={{ height: "60px", marginBottom: "4px" }}
                />
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  style={{ marginBottom: "8px" }}
                >
                  MASSIVE ANALYSER
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "white !important" }}
                >
                  {t("app_intro")}
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </Box>
    </main>
  );
};

export const SignInPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSignInPage);
