/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  // Add as AddIcon,
  // Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { apiGetOrganizationMembers } from "src/api";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "src/components/utils";
import Permissions from "../permissions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const tableHeaders = {
  username: "Username",
  full_name: "Full name",
  email: "Email",
};

const UserTableRow = (props) => {
  const { user, setUserData, organization_id, appConf } = props;
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  // const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { t } = useTranslation();

  // const handleCloseDialog = () => {
  //   setDialogOpen(false);
  // };

  const handleCloseEditDialog = (userData) => {
    setEditDialogOpen(false);
    if (userData) {
      setUserData((prevState) => {
        const newUserData = Object.assign({}, prevState);
        newUserData[userData.username] = userData;
        return newUserData;
      });
    }
  };

  // const deleteObjCallback = (data, status) => {};

  // const handleDeleteObj = () => {
  //   setLoading(true);
  //   // apiDeleteDepartment(department.id, deleteObjCallback);
  // };

  return (
    <TableRow data-id={user.id} key={user.id}>
      {Object.keys(tableHeaders).map((headerKey, index) => (
        <TableCell key={index}>
          {t(
            headerKey === "full_name" ? getUserFullName(user) : user[headerKey]
          )}
        </TableCell>
      ))}
      <TableCell>
        <IconButton
          onClick={() => setEditDialogOpen(true)}
          // onClick={() => navigate(`edit-user/${user.id}`)}
        >
          <EditIcon />
        </IconButton>
        {/*<IconButton onClick={() => setDialogOpen(true)}>
          <DeleteIcon />
        </IconButton> */}
        <Dialog
          fullWidth
          maxWidth="sm"
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          aria-labelledby="edit-dialog-title"
          aria-describedby="edit-dialog-description"
        >
          <DialogTitle id="edit-dialog-title">
            {t("Edit user permissions")}
          </DialogTitle>
          <DialogContent>
            <Permissions
              appConf={appConf}
              permissions={user.permissions}
              organization_id={organization_id}
              id={user.id}
              type="user"
              submitCallback={handleCloseEditDialog}
            />
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleCloseEditDialog} color="secondary">
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleSavePermissions}
              startIcon={<SaveIcon />}
              color="primary"
              autoFocus
              disabled={loading}
              variant="contained"
            >
              {t("Save")}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions> */}
        </Dialog>
        {/* <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">{"Delete user?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              {`Are you sure you want to delete user "${user.username}"?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              No
            </Button>
            <Button
              // onClick={handleDeleteObj}
              color="secondary"
              autoFocus
              disabled={loading}
            >
              Yes
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog> */}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return { appConf: state.appConf };
};

const ConnectedUsersManagement = (props) => {
  const classes = useStyles();
  const { organization_id, appConf } = props;
  const [addUserDialog, setAddUserDialog] = useState(false);
  const [userData, setUserData] = useState(undefined);
  const { t } = useTranslation();

  const handleOpenDialog = () => {
    setAddUserDialog(true);
  };
  const handleCloseDialog = () => {
    setAddUserDialog(false);
  };

  useEffect(() => {
    let mounted = true;
    apiGetOrganizationMembers(organization_id, (data, status) => {
      if (status === 200) {
        if (mounted) {
          const users = {};
          for (let i = 0; i < data.length; i++) {
            users[data[i].username] = data[i];
          }
          setUserData(users);
        }
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  if (!userData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <div>
        <Grid container>
          <Grid item xs={12} style={{ display: "flex", marginBottom: "20px" }}>
            <Typography component="h4" variant="h4">
              {t("Users Management")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "auto" }}
              onClick={handleOpenDialog}
            >
              {t("Add/Remove users")}
            </Button>
            <Dialog
              open={addUserDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("Add/Remove users")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t(
                    "add_remove_user_message",
                    "For adding or removing users, please contact support."
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  {t("Close")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {Object.keys(tableHeaders).map((headerKey, index) => (
                    <TableCell key={index}>
                      {t(tableHeaders[headerKey])}
                    </TableCell>
                  ))}
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(userData).map((user) => (
                  <UserTableRow
                    user={userData[user]}
                    setUserData={setUserData}
                    key={user}
                    appConf={appConf}
                    organization_id={organization_id}
                  />
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export const UsersManagement = connect(mapStateToProps)(
  ConnectedUsersManagement
);
