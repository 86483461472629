/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { apiGetTableConf } from "src/api";
import { useSnackbar } from "notistack";
import { apiDeleteTableConf, apiPartialUpdateTableConf } from "src/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { connect } from "react-redux";
import { BsBuildingFillCheck, BsBuildingFillX } from "react-icons/bs";
import { userHasPermission } from "src/components/utils";
import { apiPublishFilter, apiUnPublishFilter } from "src/api/endpoints";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  defaultRow: {
    backgroundColor: theme.palette.success.light,
  },
}));

const TableConfigRow = (props) => {
  const classes = useStyles();
  const { setConfList, confList, index, projectType, userObject } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const deleteObjCallback = (data, status) => {
    if (status === 204) {
      let newList = confList.slice(0);
      newList.splice(index, 1);
      setConfList(newList);
      setLoading(false);
      handleCloseDialog();
      enqueueSnackbar(t("Table configuration was successfully deleted"), {
        variant: "success",
      });
    } else {
      safeLogger(data);
      enqueueSnackbar(
        t("unhandled_error_response", "There was an error. Please try again"),
        {
          variant: "error",
        }
      );
    }
  };

  const handleDeleteObj = () => {
    setLoading(true);
    apiDeleteTableConf(confList[index].id, deleteObjCallback);
  };

  const setDefaultCallback = (data, status) => {
    if (status === 200) {
      let newConfList = confList.slice(0);
      newConfList[index].is_default = true;
      setConfList(newConfList);
      enqueueSnackbar(
        t(
          "table_conf_set_as_default_message",
          "{{name}} was set as default table configuration",
          {
            name: confList[index].name,
          }
        ),
        { variant: "success" }
      );
    } else {
      safeLogger(data);
      enqueueSnackbar(
        t("unhandled_error_response", "There was an error. Please try again"),
        {
          variant: "error",
        }
      );
    }
  };

  const handleSetDefault = () => {
    const newConfList = [...confList];
    for (let i = 0; i < newConfList.length; i++) {
      if (newConfList[i].is_default) {
        newConfList[i].is_default = false;

        apiPartialUpdateTableConf(
          confList[i].id,
          { is_default: false },
          (data, status) => {
            if (status !== 200) {
              enqueueSnackbar(
                t(
                  "unhandled_error_response",
                  "There was an error. Please try again"
                ),
                {
                  variant: "error",
                }
              );
              return;
            }
          }
        );

        break;
      }
    }

    apiPartialUpdateTableConf(
      confList[index].id,
      { is_default: true },
      setDefaultCallback
    );
  };

  const unsetDefaultCallback = (data, status) => {
    if (status === 200) {
      let newConfList = confList.slice(0);
      newConfList[index].is_default = false;
      setConfList(newConfList);
      enqueueSnackbar(
        t(
          "table_default_status_cleared",
          "{{name}} default status was cleared",
          {
            name: confList[index].name,
          }
        ),
        {
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(data, { variant: "error" });
    }
  };

  const handlePublishApi = (status) => {
    if (status === 200) {
      confList[index].organization = !confList[index].organization
        ? userObject.activeOrganization.id
        : null;

      enqueueSnackbar(
        t(
          `Filter is ${
            !confList[index].organization ? "hidden from" : "shared with"
          } organization`
        ),
        { variant: "success" }
      );
      setConfList([...confList]);
    } else {
      enqueueSnackbar(t("Something went wrong !"), {
        variant: "error",
      });
    }
  };

  const publishFilter = () => {
    if (!confList[index].organization) {
      apiPublishFilter(
        confList[index].id,
        userObject.activeOrganization.id,
        (_data, status) => {
          handlePublishApi(status);
        }
      );
    } else {
      apiUnPublishFilter(
        confList[index].id,
        userObject.activeOrganization.id,
        (_data, status) => {
          handlePublishApi(status);
        }
      );
    }
  };

  const handleUnsetDefault = () => {
    apiPartialUpdateTableConf(
      confList[index].id,
      { is_default: false },
      unsetDefaultCallback
    );
  };

  return (
    <TableRow>
      <TableCell>{confList[index].name}</TableCell>
      <TableCell>
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            justifyItems: "center",
            gap: 5,
          }}
        >
          {confList[index].is_default ? (
            <Chip
              icon={<CheckIcon />}
              label={t("Default")}
              onDelete={handleUnsetDefault}
            />
          ) : (
            <Button variant="outlined" onClick={handleSetDefault}>
              {t("Set as default")}
            </Button>
          )}
          {/* {userHasPermission(
            userObject.activePermissions,
            "manage_organization_filters"
          ) && ( //TODO: MAKE MANAGE ORGANIZATION FILTERS */}
          <>
            <Link
              to={`tableconf/${confList[index].id}?project_type=${projectType}`}
            >
              <IconButton size="medium">
                <EditIcon fontSize="small" />
              </IconButton>
            </Link>
            <IconButton onClick={handleOpenDialog}>
              <DeleteIcon fontSize="small" />
            </IconButton>
            {/* <Tooltip
                title={t(
                  `${
                    confList[index].organization ===
                    userObject.activeOrganization.id
                      ? "Hide"
                      : "Share"
                  } filter ${
                    confList[index].organization ===
                    userObject.activeOrganization.id
                      ? "from"
                      : "with"
                  } organization`
                )}
              >
                <IconButton onClick={publishFilter}>
                  {confList[index].organization ===
                  userObject.activeOrganization.id ? (
                    <BsBuildingFillCheck fontSize="small" color="green" />
                  ) : (
                    <BsBuildingFillX fontSize="small" color="red" />
                  )}
                </IconButton>
              </Tooltip> */}
          </>
          {/* )} */}
          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("Delete table configuration?")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(
                  "delete_table_conf_prompt",
                  "Are you sure you want to delete table configuration '{{name}}'?",
                  { name: confList[index].name }
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                {t("No")}
              </Button>
              <Button
                onClick={handleDeleteObj}
                color="secondary"
                autoFocus
                disabled={loading}
              >
                {t("Yes")}
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    userObject: state.userObject,
  };
};

export const ConnectedTableConfigSection = (props) => {
  const { projectTypeConf, userObject } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [tableConfList, setTableConfList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const tableHeaders = [t("Name"), t("Actions")];

  const tableConfCallback = (data, status) => {
    if (status === 200) {
      // safeLogger(data);
      setTableConfList(data);
    } else {
      safeLogger(data);
      enqueueSnackbar(
        t("unhandled_error_response", "There was an error. Please try again"),
        {
          variant: "error",
        }
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    apiGetTableConf(
      projectTypeConf.name,
      userObject.activeOrganization.id,
      tableConfCallback
    );
  }, []);

  if (loading) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return tableConfList.length ? (
      <Box display="flex">
        <Table size="small" style={{ marginTop: "15px" }}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell width={"50%"} key={index}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableConfList.map((configObj, index) => (
              <TableConfigRow
                projectType={projectTypeConf.name}
                confList={tableConfList}
                userObject={userObject}
                index={index}
                setConfList={setTableConfList}
                key={index}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    ) : (
      <div></div>
    );
  }
};

export const TableConfigSection = connect(mapStateToProps)(
  ConnectedTableConfigSection
);
