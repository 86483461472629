import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import {
  Add as AddIcon,
  PlayArrow as PlayArrowIcon,
  Info as InfoIcon,
  Check as CheckIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
} from "@material-ui/icons";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  customTimeline: {
    paddingLeft: 0,
    "& .timeline-content": {
      display: "flex",
      flexDirection: "row",
      height: "50%",
      alignItems: "center",
      justifyContent: "right",
    },
    "& .timeline-content-date": {
      display: "flex",
      flexDirection: "row",
      height: "50%",
      alignItems: "center",
      justifyContent: "left",
    },
    "& .MuiTimelineItem-content": {
      paddingLeft: 0,
    },
    "& .MuiTimelineItem-oppositeContent": {
      paddingRight: 0,
    },
    "& .MuiTimelineDot-root .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
}));

const timelineDotTypes = {
  created: {
    color: "primary",
    icon: <AddIcon />,
  },
  started: {
    color: "primary",
    icon: <PlayArrowIcon />,
  },
  info: {
    color: "primary",
    icon: <InfoIcon />,
  },
  cancelled: {
    color: "secondary",
    icon: <CancelIcon />,
  },
  failed: {
    color: "secondary",
    icon: <ErrorIcon />,
  },
  finished: {
    style: { backgroundColor: "#388e3c" },
    icon: <CheckIcon />,
  },
};

export const ProjectTimeline = (props) => {
  const classes = useStyles();
  const { data } = props;
  const [dates, setDates] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      const timelineDates = Object.keys(data).sort();
      setDates(timelineDates);
    }
  }, [data]);

  if (!dates) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Timeline className={classes.customTimeline}>
        {dates.map((date, _index) =>
          data[date].map((item, index) => {
            const eventType = item.event.split("_");
            let eventLabel = t(eventType[0]) + " ";
            eventLabel += eventType[0] === "sample" ? item.sample + " " : "";
            eventLabel += t(eventType[1]);
            return (
              <TimelineItem key={index}>
                <TimelineContent color="textSecondary">
                  <Typography variant="body2" className="timeline-content">
                    {eventLabel}
                  </Typography>
                </TimelineContent>
                <TimelineSeparator>
                  <TimelineDot
                    color={
                      timelineDotTypes[item.event.split("_")[1]].color
                        ? timelineDotTypes[item.event.split("_")[1]].color
                        : "primary"
                    }
                    style={
                      timelineDotTypes[item.event.split("_")[1]].style
                        ? timelineDotTypes[item.event.split("_")[1]].style
                        : {}
                    }
                  >
                    {timelineDotTypes[item.event.split("_")[1]].icon}
                  </TimelineDot>
                  {_index === dates.length - 1 &&
                  index === data[date].length - 1 ? null : (
                    <TimelineConnector />
                  )}
                </TimelineSeparator>
                <TimelineOppositeContent>
                  <Typography variant="body2" className="timeline-content-date">
                    {format(new Date(date), "dd/MM/yyyy HH:mm:ss")}
                  </Typography>
                </TimelineOppositeContent>
              </TimelineItem>
            );
          })
        )}
      </Timeline>
    );
  }
};
