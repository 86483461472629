import React from "react";
import { Doughnut } from "react-chartjs-2";

function PieChart({ datas, height, width }) {
  const image = new Image();
  image.src = "/exomeLogoWhite.png";
  const plugin = {
    id: "customCanvasBackgroundImage",
    beforeDraw: (chart) => {
      if (image.complete) {
        const ctx = chart.ctx;
        const { top, left, width, height } = chart.chartArea;
        const x = left + width / 2 - image.width / 2;
        const y = top + height / 2 - image.height / 2;
        ctx.drawImage(image, x, y);
      } else {
        image.onload = () => chart.draw();
      }
    },
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {height > 0 && width > 0 && (
        <Doughnut
          data={datas}
          legend={{
            display: true,
            labels: {
              fontSize: 12,
            },
          }}
          height={width}
          width={width}
          plugins={[plugin]}
          options={{
            responsive: true,
            layout: {
              autoPadding: false,
              padding: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              },
            },
            indexAxis: "y",
            plugins: {
              legend: {},
            },
          }}
        />
      )}
    </div>
  );
}

export default PieChart;
