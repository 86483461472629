import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  alpha,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import InnerRow from "./InnerRow";
import { innerTableHeaders } from "src/config/projects";
import { connect } from "react-redux";
import ProjectTableRowCell from "./cell";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.default, 0.4),
    },
  },
  collapseCell: {
    width: "100%",
    padding: 0,
  },
  innerTable: {
    width: "100%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
}));

function ConnectedProjectTableRow({ project, columns, userObj, appConf }) {
  const [open, setOpen] = useState();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  function refineSamples(projectData) {
    // check sample files and reports and group them based on project type
    //   if (tableType === "reports") {
    //     setReportsData(projectData.reports);
    //   } else
    if (projectData.sample_kind === "paired_end") {
      let tmpSamplesData = [];
      let pairedSamples = [];
      for (let i = 0; i < projectData.samples.length; i++) {
        if (!pairedSamples.includes(projectData.samples[i].name)) {
          pairedSamples.push(projectData.samples[i].name);
          tmpSamplesData.push(projectData.samples[i]);
        }
      }
      return tmpSamplesData;
    } else if (projectData.sample_kind === "single_end") {
      return projectData.samples;
    }
  }

  const rowClickHandler = (event) => {
    navigate(`/projects/${project.id}`);
  };

  return (
    <>
      <TableRow onClick={rowClickHandler} className={classes.tableRow}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column, index) => (
          <TableCell size="small" key={index}>
            <ProjectTableRowCell
              userObj={userObj}
              appConf={appConf}
              data={project}
              type={column.type}
              name={column.name}
            />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.collapseCell}
          colSpan={columns.length + 1}
        >
          <Collapse in={open}>
            <InnerRow
              rowData={project}
              samples={refineSamples(project)}
              classes={classes}
              expandOverride={false}
              innerTableHeaders={innerTableHeaders}
              userObj={userObj}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const mapProgressStateToProps = (state) => {
  return {
    userObj: state.userObject,
    appConf: state.appConf,
  };
};

export const ProjectTableRow = connect(mapProgressStateToProps)(
  ConnectedProjectTableRow
);
