/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import { CircularProgress, makeStyles, Grid, Paper } from "@material-ui/core";
import { apiLoadDashboard } from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SampleIcon from "./icons/sampleIcon";
import Stats from "./stats";
import Table from "./table";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { MdOutlineCancel } from "react-icons/md";
import DnaIcon from "./icons/dnaIcon";
import Announcment from "./announcment";
import RunnedSamples from "../account/manage/runnedSamples";
import { getBlogBaseURL, urlAPIConf } from "src/config/api";
import { getBaseLanguage } from "../reports/utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  runned: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: theme.spacing(3),
    // width: "40%",
  },
  runnedItem: {
    padding: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.secondBackground,
    color: theme.palette.text.primary,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: "10px",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  Announcment: {},
}));

const mapStateToPropsPage = (state) => {
  return {
    userObj: state.userObject,
    appConf: state.appConf,
  };
};

const ConnectedAppHomePage = (props) => {
  const classes = useStyles();
  const { userObj, appConf } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [dashboardData, setDashboardData] = useState([]);
  const [announcementsPosts, setAnnouncementsPosts] = useState([]);
  const [tutorialPosts, setTutorialPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;

    if (mounted && !dashboardData.length) {
      apiLoadDashboard(userObj.activeOrganization.id, (data, status) => {
        if (status === 200) {
          if (mounted) {
            setDashboardData(data);
            setLoading(false);
          }
        } else {
          enqueueSnackbar(data, { variant: "error" });
        }
      });
    }

    return () => {
      mounted = false;
      return mounted;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (appConf.lang) {
      fetch(
        `${getBlogBaseURL()}/${
          urlAPIConf.BASE_API_SLUG
        }/pages/?fields=_,id,title,first_published_at,categories,tags,main_image&type=blog.BlogPage&order=-first_published_at&categories__in=1&locale=${getBaseLanguage()}&limit=5`
      )
        .then((response) => response.json())
        .then((data) => {
          if (mounted && data && data.items && data.items.length) {
            setTutorialPosts(data);
          }
        })
        .catch();
    }
    return () => {
      mounted = false;
      return mounted;
    };
  }, [appConf]);

  useEffect(() => {
    let mounted = true;
    if (appConf.lang) {
      fetch(
        `${getBlogBaseURL()}/${
          urlAPIConf.BASE_API_SLUG
        }/pages?fields=_,id,title,first_published_at,categories,tags,main_image&type=blog.BlogPage&order=-first_published_at&categories__in=2&locale=${getBaseLanguage()}&limit=5`
      )
        .then((response) => response.json())
        .then((data) => {
          if (mounted && data && data.items && data.items.length) {
            setAnnouncementsPosts(data);
          }
        });
    }

    return () => {
      mounted = false;
      return mounted;
    };
  }, [appConf]);

  if (loading || announcementsPosts.length || tutorialPosts.length) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Exome Dashboard")}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item lg={9} xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item md={6} xs={12}>
                            <Paper className={classes.paper}>
                              <Stats
                                data={dashboardData.completed_projects}
                                icon={
                                  <SampleIcon
                                    style={{ width: "75%", height: "75%" }}
                                    fill={{ fill: "#3E55B1" }}
                                  />
                                }
                                title={t("Completed Projects")}
                              />
                            </Paper>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Paper className={classes.paper}>
                              <Stats
                                data={dashboardData.completed_samples}
                                icon={
                                  <DnaIcon
                                    style={{
                                      fill: "#6FCF97",
                                      width: "75%",
                                      height: "75%",
                                    }}
                                    fill={{ fill: "#6FCF97" }}
                                  />
                                }
                                title={t("Completed Samples")}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Paper className={classes.paper}>
                              <Table
                                title={t("Latest Projects")}
                                url={
                                  '/projects?page=0&pageSize=10&value=&filterValue={}&sort={"created_at":"DESC"}'
                                }
                                icon={
                                  <SampleIcon
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      paddingRight: 5,
                                    }}
                                    fill={{ fill: "#3E55B1" }}
                                  />
                                }
                                data={dashboardData.projects}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item md={6} xs={12}>
                            <Paper className={classes.paper}>
                              <Stats
                                title={t("Continuing Samples")}
                                data={dashboardData.in_progres_projects}
                                icon={
                                  <HourglassEmptyIcon
                                    style={{
                                      fill: "#F7B924",
                                      width: "75%",
                                      height: "75%",
                                    }}
                                  />
                                }
                              />
                            </Paper>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Paper className={classes.paper}>
                              <Stats
                                data={dashboardData.canceled_projects}
                                title={t("Canceled Projects")}
                                icon={
                                  <MdOutlineCancel
                                    style={{
                                      fill: "#EB5757",
                                      width: "75%",
                                      height: "75%",
                                    }}
                                  />
                                }
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} className={classes.paper}>
                            <Table
                              title={t("Latest Samples")}
                              url={
                                '/projects?page=0&pageSize=10&value=&filterValue={}&sort={"created_at":"DESC"}'
                              }
                              icon={
                                <DnaIcon
                                  style={{
                                    fill: "#6FCF97",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                  fill={{ fill: "#6FCF97" }}
                                />
                              }
                              data={dashboardData.samples}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <RunnedSamples
                    organization_id={userObj.activeOrganization.id}
                    classes={classes}
                  />
                </Grid>
              </Grid>
              <Grid item lg={3} xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Announcment
                      values={tutorialPosts}
                      title={
                        tutorialPosts
                          ? t("Tutorials")
                          : t("Not Found Tutorials")
                      }
                      url="/search/?category=1"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Announcment
                      values={announcementsPosts}
                      url="/search/?category=2"
                      title={
                        announcementsPosts
                          ? t("Announcements")
                          : t("Not Found Announcement")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <RunnedSamples
                organization_id={userObj.activeOrganization.id}
                classes={classes}
              /> */}
            </Grid>
          </Grid>
          {/* <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              a
            </Grid>
            <Grid item xs={12} md={5}>
              a
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: "20px" }}>
            <Grid item xs={12} md={12} lg={5}>
              <LatestProjects projectsData={dashboardData.projects} />
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <LatestSamples samplesData={dashboardData.samples} />
            </Grid>
            <Grid item xs={12} md={12} lg={2}>
              <NewsFeed announcementsData={dashboardData.announcements} />
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: "20px" }}>
            <Grid item xs={12} md={12} lg={3}>
              <TutorialMenu tutorialsData={dashboardData.tutorials} />
            </Grid>
          </Grid> */}
        </Grid>
      </Page>
    );
  }
};

export const AppHomePage = connect(mapStateToPropsPage)(ConnectedAppHomePage);
