/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { apiLoadSchemaColumnUniqueValues, apiUpdateProfile } from "src/api";
import { Autocomplete } from "@material-ui/lab";
import { List } from "react-virtualized";
import safeLogger from "src/services/safeLogger";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  basicChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& span": {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  iconBtn: {
    marginLeft: theme.spacing(1),
  },
}));

const DeleteFiltersetDialog = (props) => {
  const classes = useStyles();
  const { open, setOpen, userObject, loadUser, colName, filtersetKey } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleDelete = () => {
    delete userObject.settings.report.filtersets[colName][filtersetKey];
    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      if (status === 200) {
        setLoading(false);
        setOpen(false);
        loadUser(data);
      } else {
        safeLogger(status, data);
      }
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      // onClose={handleDeleteDialogClose}
      aria-labelledby="delete-filterset-dialog-title"
      aria-describedby="delete-filterset-dialog-description"
    >
      <DialogTitle id="delete-filterset-dialog-title">
        {t("Delete {{filtersetLabel}} filterset?", {
          filtersetLabel:
            userObject.settings.report.filtersets[colName] &&
            userObject.settings.report.filtersets[colName][filtersetKey]
              ? userObject.settings.report.filtersets[colName][filtersetKey]
                  .label
              : "",
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-filterset-dialog-description">
          {t(
            "delete_filterset_message",
            "Are you sure you want to delete {{filtersetLabel}} filterset? This action cannot be undone!",
            {
              filtersetLabel:
                userObject.settings.report.filtersets[colName] &&
                userObject.settings.report.filtersets[colName][filtersetKey]
                  ? userObject.settings.report.filtersets[colName][filtersetKey]
                      .label
                  : "",
            }
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          disabled={loading}
        >
          {t("Delete")}
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ListboxComponent = function ListboxComponent(props) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 45;
  const ref = useRef(null);
  const [parentDimension, setParentDimension] = useState(null);

  React.useLayoutEffect(() => {
    const parentWidth = ref?.current.offsetWidth;
    const parentHeight = ref?.current?.offsetHeight;
    setParentDimension({ width: parentWidth, height: parentHeight });
  }, [ref]);

  return (
    <div data-testid={`listBox-component`} ref={ref}>
      <div {...other}>
        <List
          height={300}
          width={parentDimension ? parentDimension.width : 300}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          data-testid={`${children[props.index]}`}
          rowRenderer={(props) => {
            return React.cloneElement(children[props.index], {
              style: props.style,
              "data-testid": `${children[props.index].props.children}`,
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
};

const EditFiltersetDialog = (props) => {
  const classes = useStyles();
  const {
    kind,
    open,
    setOpen,
    userObject,
    loadUser,
    schemaId,
    colName,
    filtersetKey,
  } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [filtersetTitle, setFiltersetTitle] = useState(
    userObject.settings.report.filtersets[colName] &&
      userObject.settings.report.filtersets[colName][filtersetKey]
      ? userObject.settings.report.filtersets[colName][filtersetKey].label
      : ""
  );
  const [value, setValue] = useState(
    userObject.settings.report.filtersets[colName] &&
      userObject.settings.report.filtersets[colName][filtersetKey]
      ? kind === "numeric"
        ? {
            minFilterValue:
              userObject.settings.report.filtersets[colName][filtersetKey]
                .values[0],
            maxFilterValue:
              userObject.settings.report.filtersets[colName][filtersetKey]
                .values[1],
          }
        : userObject.settings.report.filtersets[colName][filtersetKey].values
      : []
  );
  const [error] = useState({
    minFilterValue: false,
    maxFilterValue: false,
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;

    if (open && !options.length) {
      setLoading(true);

      apiLoadSchemaColumnUniqueValues(schemaId, colName, (data, status) => {
        if (status === 200) {
          if (mounted) {
            setOptions(data);
            setLoading(false);
          }
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [autoCompleteOpen]);

  const customFilterOptions = (options, state) => {
    return options.filter((option) => {
      for (let i = 0; i < value.length; i++) {
        if (value[i] === option) {
          return false;
        }
      }

      return option.includes(state.inputValue);
    });
  };

  const handleFiltersChange = (event, activeValues, reason) => {
    setValue(activeValues);
  };

  const handleSaveFilterset = () => {
    const key = filtersetTitle.toLowerCase().replace(" ", "_");
    const newFiltersetObject = {
      label: filtersetTitle,
      values:
        kind === "numeric"
          ? [value["minFilterValue"], value["maxFilterValue"]]
          : value,
    };

    delete userObject.settings.report.filtersets[colName][filtersetKey];
    userObject.settings.report.filtersets[colName][key] = newFiltersetObject;

    setSaveLoading(true);
    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      if (status === 200) {
        setSaveLoading(false);
        setOpen(false);
        loadUser(data);
      } else {
        safeLogger(status, data);
      }
    });
  };

  const changeHandler = (event) => {
    const re = new RegExp(/^[-]?(\d+\.?\d*|\.\d+)$/);
    const name = event.target.name;
    const newValue = event.target.value;
    if (newValue === "" || re.test(newValue)) {
      // setError({ ...error, [name]: false });
      setValue({ ...value, [name]: newValue });
    }
    // else {
    //   setError({ ...error, [name]: true });
    // }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      keepMounted
      // onClose={handleEditDialogClose}
      itemProp={{ "data-testid": "edit-filterset-dialog-title" }}
      aria-labelledby="edit-filterset-dialog-title"
      aria-describedby="edit-filterset-dialog-description"
    >
      <DialogTitle id="edit-filterset-dialog-title">
        {t("Edit {{filtersetLabel}} filterset", {
          filtersetLabel:
            userObject.settings.report.filtersets[colName] &&
            userObject.settings.report.filtersets[colName][filtersetKey]
              ? userObject.settings.report.filtersets[colName][filtersetKey]
                  .label
              : "",
        })}
      </DialogTitle>
      <DialogContent
        data-testid={`${
          userObject.settings.report.filtersets[colName] &&
          userObject.settings.report.filtersets[colName][filtersetKey]
            ? userObject.settings.report.filtersets[colName][filtersetKey].label
            : ""
        }-filtersets-content-${open ? "open" : "closed"}`}
      >
        <TextField
          required
          autoFocus
          margin="dense"
          id="name"
          label={t("Filterset name")}
          fullWidth
          value={filtersetTitle}
          onChange={(event) => setFiltersetTitle(event.target.value)}
        />
        {kind === "numeric" ? (
          <Box mt={2}>
            <TextField
              error={error["minFilterValue"]}
              onChange={changeHandler}
              name="minFilterValue"
              label={t("Minimum")}
              variant="outlined"
              value={value["minFilterValue"]}
              style={{ marginRight: "20px" }}
              helperText={t("Only numeric values are accepted.")}
            />
            <TextField
              error={error["maxFilterValue"]}
              onChange={changeHandler}
              name="maxFilterValue"
              label={t("Maximum")}
              variant="outlined"
              value={value["maxFilterValue"]}
              helperText={t("Only numeric values are accepted.")}
            />
          </Box>
        ) : (
          <Autocomplete
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
            fullWidth
            id={`asynchronous-${
              userObject.settings.report.filtersets[colName] &&
              userObject.settings.report.filtersets[colName][filtersetKey]
                ? userObject.settings.report.filtersets[colName][filtersetKey]
                    .name
                : ""
            }-filter`}
            open={autoCompleteOpen}
            onOpen={() => {
              setAutoCompleteOpen(true);
            }}
            onClose={() => {
              setAutoCompleteOpen(false);
            }}
            multiple
            freeSolo
            filterSelectedOptions
            ListboxComponent={ListboxComponent}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) => option}
            options={options}
            loading={loading}
            value={value}
            // data-testid={`${
            //   userObject.settings.report.filtersets[colName] &&
            //   userObject.settings.report.filtersets[colName][filtersetKey]
            //     ? userObject.settings.report.filtersets[colName][filtersetKey]
            //         .label
            //     : ""
            // }-edit-filterset-dialog-input`}
            filterOptions={customFilterOptions}
            onChange={handleFiltersChange}
            style={{ marginTop: "15px", marginBottom: "15px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Filterset values")}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  "data-testid": `${
                    userObject.settings.report.filtersets[colName] &&
                    userObject.settings.report.filtersets[colName][filtersetKey]
                      ? userObject.settings.report.filtersets[colName][
                          filtersetKey
                        ].label
                      : ""
                  }-edit-filterset-dialog-input`,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>
        <Button
          onClick={handleSaveFilterset}
          variant="contained"
          color="primary"
          data-testid="edit-filterset-dialog-save"
          startIcon={<SaveIcon />}
          disabled={saveLoading}
        >
          {t("Save")}
          {saveLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FiltersetDetailsDialog = (props) => {
  const classes = useStyles();
  const {
    kind,
    open,
    setOpen,
    type,
    defaultFiltersets,
    userObject,
    colName,
    filtersetKey,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="filterset-details-dialog-title"
      aria-describedby="filterset-details-dialog-description"
    >
      <DialogTitle id="filterset-details-dialog-title">
        {t("{{filtersetLabel}} values", {
          filtersetLabel:
            type === "userDefined"
              ? userObject.settings.report.filtersets[colName] &&
                userObject.settings.report.filtersets[colName][filtersetKey]
                ? userObject.settings.report.filtersets[colName][filtersetKey]
                    .label
                : ""
              : defaultFiltersets[filtersetKey].label,
        })}
      </DialogTitle>
      {open && (
        <DialogContent>
          {type === "userDefined" ? (
            kind === "numeric" ? (
              userObject.settings.report.filtersets[colName] &&
              userObject.settings.report.filtersets[colName][filtersetKey]
                .values && (
                <Box>
                  <Typography>
                    {t("Minimum")}
                    {`: ${userObject.settings.report.filtersets[colName][filtersetKey].values[0]}`}
                  </Typography>
                  <Typography>
                    {t("Maximum")}
                    {`: ${userObject.settings.report.filtersets[colName][filtersetKey].values[1]}`}
                  </Typography>
                </Box>
              )
            ) : userObject.settings.report.filtersets[colName] &&
              userObject.settings.report.filtersets[colName][filtersetKey]
                .values.constructor === Object ? (
              Object.keys(
                userObject.settings.report.filtersets[colName][filtersetKey]
                  .values
              ).map((item, index) => (
                <Chip
                  className={classes.basicChip}
                  key={index}
                  label={
                    userObject.settings.report.filtersets[colName][filtersetKey]
                      .values[item]
                  }
                />
              ))
            ) : (
              userObject.settings.report.filtersets[colName] &&
              userObject.settings.report.filtersets[colName][
                filtersetKey
              ].values.map((item, index) => (
                <Chip className={classes.basicChip} key={index} label={item} />
              ))
            )
          ) : kind === "numeric" ? (
            <Box>
              <Typography>
                {t("Minimum")}
                {`: ${defaultFiltersets[filtersetKey].values[0]}`}
              </Typography>
              <Typography>
                {t("Maximum")}
                {`: ${defaultFiltersets[filtersetKey].values[1]}`}
              </Typography>
            </Box>
          ) : defaultFiltersets[filtersetKey].values.constructor === Object ? (
            Object.keys(defaultFiltersets[filtersetKey].values).map(
              (item, index) => (
                <Chip
                  className={classes.basicChip}
                  key={index}
                  label={defaultFiltersets[filtersetKey].values[item]}
                />
              )
            )
          ) : (
            defaultFiltersets[filtersetKey].values.map((item, index) => (
              <Chip className={classes.basicChip} key={index} label={item} />
            ))
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => {
            /**
             * copy filterset filters
             */
            let copyData = [];
            if (type === "userDefined") {
              copyData =
                userObject.settings.report.filtersets[colName] &&
                userObject.settings.report.filtersets[colName][filtersetKey]
                  .values.constructor === Object
                  ? Object.keys(
                      userObject.settings.report.filtersets[colName][
                        filtersetKey
                      ].values
                    )
                  : userObject.settings.report.filtersets[colName] &&
                    userObject.settings.report.filtersets[colName][filtersetKey]
                      .values;
            } else {
              copyData =
                defaultFiltersets[filtersetKey].values.constructor === Object
                  ? Object.values(defaultFiltersets[filtersetKey].values)
                  : defaultFiltersets[filtersetKey].values;
            }
            navigator.clipboard.writeText(copyData.join("\n"));
          }}
          color="primary"
        >
          {t("Copy")}
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FiltersetTableRow = (props) => {
  const classes = useStyles();
  const {
    kind,
    schemaId,
    appConf,
    colName,
    handleSetFilterset,
    userObject,
    loadUser,
    filtersetKey,
    type,
    defaultFiltersets,
  } = props;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <TableRow>
      <DeleteFiltersetDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        userObject={userObject}
        loadUser={loadUser}
        colName={colName}
        filtersetKey={filtersetKey}
      />
      <EditFiltersetDialog
        kind={kind}
        schemaId={schemaId}
        appConf={appConf}
        open={editDialogOpen}
        setOpen={setEditDialogOpen}
        userObject={userObject}
        loadUser={loadUser}
        colName={colName}
        filtersetKey={filtersetKey}
      />
      <FiltersetDetailsDialog
        kind={kind}
        open={detailsDialogOpen}
        setOpen={setDetailsDialogOpen}
        type={type}
        defaultFiltersets={defaultFiltersets}
        userObject={userObject}
        colName={colName}
        filtersetKey={filtersetKey}
      />
      <TableCell data-testid={type === "userDefined" && "filter-value"}>
        {type === "userDefined"
          ? userObject.settings.report.filtersets[colName] &&
            userObject.settings.report.filtersets[colName][filtersetKey]
            ? userObject.settings.report.filtersets[colName][filtersetKey].label
            : ""
          : defaultFiltersets[filtersetKey].label}
      </TableCell>
      <TableCell>
        <Button
          startIcon={<CheckIcon />}
          variant="contained"
          color="primary"
          onClick={() => {
            handleSetFilterset(
              type === "userDefined"
                ? userObject.settings.report.filtersets[colName][filtersetKey]
                  ? userObject.settings.report.filtersets[colName][filtersetKey]
                      .values
                  : []
                : defaultFiltersets[filtersetKey].values,
              type === "userDefined"
                ? userObject.settings.report.filtersets[colName][filtersetKey]
                    .label
                : defaultFiltersets[filtersetKey].label
            );
          }}
        >
          {t("Use as filter")}
        </Button>
        <Tooltip title={t("View values")}>
          <IconButton
            className={classes.iconBtn}
            onClick={() => {
              setDetailsDialogOpen(true);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        {type === "userDefined" && (
          <>
            <Tooltip title={t("Edit")}>
              <IconButton
                data-testid="edit-filterset"
                className={classes.iconBtn}
                onClick={() => setEditDialogOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("Delete")}>
              <IconButton
                data-testid="delete-filterset"
                className={classes.iconBtn}
                onClick={() => setDeleteDialogOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export const FiltersetTable = (props) => {
  const {
    kind,
    schemaId,
    appConf,
    colName,
    setOpen,
    userObject,
    loadUser,
    defaultFiltersets,
    handleFiltersChange,
  } = props;
  const { t } = useTranslation();

  const filter = Object.assign(
    {},
    userObject.settings.report.filtersets[colName]
  );

  const [tmpFilter, setTmpFilter] = useState(filter ? filter : []);
  const [defaultFilter, setDefaultFilter] = useState(
    defaultFiltersets ? defaultFiltersets : []
  );
  const [filterValue, setFilterValue] = useState("");
  const handleSetFilterset = (values, filtersetName) => {
    handleFiltersChange(undefined, values, "filterset-apply", filtersetName);
    setOpen(false);
  };

  const handleTextField = (e) => {
    setFilterValue(e.target.value);
    if (tmpFilter) {
      let tmpFilterSet = [];
      Object.keys(filter).forEach((filtersetKey, index) => {
        if (filtersetKey.toLowerCase().includes(e.target.value.toLowerCase())) {
          tmpFilterSet = {
            ...tmpFilterSet,
            ...{
              [filtersetKey]:
                userObject.settings.report.filtersets[colName][filtersetKey],
            },
          };
        }
      });
      setTmpFilter(tmpFilterSet);
      tmpFilterSet = [];
      Object.keys(defaultFiltersets).forEach((filtersetKey, index) => {
        if (filtersetKey.toLowerCase().includes(e.target.value.toLowerCase())) {
          tmpFilterSet = {
            ...tmpFilterSet,
            ...{
              [filtersetKey]: defaultFiltersets[filtersetKey],
            },
          };
        }
      });
      setDefaultFilter(tmpFilterSet);
    }
  };

  return (
    <React.Fragment>
      <TextField
        style={{ width: "100%" }}
        onChange={handleTextField}
        value={filterValue}
        label={t("Search")}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Label")}</TableCell>
            <TableCell>{t("Actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {defaultFilter &&
            Object.keys(defaultFilter).map((filtersetKey, index) => (
              <FiltersetTableRow
                key={index}
                kind={kind}
                type="default"
                defaultFiltersets={defaultFilter}
                appConf={appConf}
                schemaId={schemaId}
                colName={colName}
                handleSetFilterset={handleSetFilterset}
                userObject={userObject}
                loadUser={loadUser}
                filtersetKey={filtersetKey}
              />
            ))}
          {tmpFilter &&
            Object.keys(tmpFilter).map((filtersetKey, index) => (
              <FiltersetTableRow
                key={index}
                kind={kind}
                type="userDefined"
                appConf={appConf}
                schemaId={schemaId}
                colName={colName}
                handleSetFilterset={handleSetFilterset}
                userObject={userObject}
                loadUser={loadUser}
                filtersetKey={filtersetKey}
              />
            ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

const SaveFiltersetDialog = (props) => {
  const classes = useStyles();
  const { colName, open, setOpen, values, loadUser, userObject } = props;
  const [filtersetTitle, setFiltersetTitle] = useState("");
  const [filtersetKey, setFiltersetKey] = useState(undefined);
  const [isKeyUnique, setIsKeyUnique] = useState(false);
  const [uniqueOverrideConfirmed, setUniqueOverrideConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uniquePromptOpen, setUniquePromptOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (filtersetKey && (isKeyUnique || uniqueOverrideConfirmed)) {
      if (uniquePromptOpen) setUniquePromptOpen(false);
      saveFilterset(filtersetKey, filtersetTitle);
    }
  }, [filtersetKey, isKeyUnique, uniqueOverrideConfirmed]);

  const handleSaveDialogClose = () => {
    setFiltersetTitle("");
    setUniqueOverrideConfirmed(false);
    setFiltersetKey(undefined);
    setIsKeyUnique(false);
    setLoading(false);
    setOpen(false);
  };

  const saveFilterset = (filtersetKey, filtersetLabel) => {
    setLoading(true);
    userObject.settings.report.filtersets[colName][filtersetKey] = {
      label: filtersetLabel,
      values: values,
    };
    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      if (status === 200) {
        setLoading(false);
        setOpen(false);
        loadUser(data);
      } else {
        safeLogger(status, data);
      }
    });
  };

  const checkFiltersetKey = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const key = filtersetTitle.toLowerCase().replace(" ", "_");
    setFiltersetKey(key);
    if (!userObject.settings.report.filtersets[colName]) {
      userObject.settings.report.filtersets[colName] = {};
      setIsKeyUnique(true);
    } else {
      if (userObject.settings.report.filtersets[colName][key]) {
        setIsKeyUnique(false);
        setUniquePromptOpen(true);
      } else {
        setIsKeyUnique(true);
      }
    }
  };

  const handleUniquePromptClose = () => {
    setUniquePromptOpen(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleSaveDialogClose}
      aria-labelledby="save-filterset-dialog-title"
      aria-describedby="save-filterset-dialog-description"
    >
      <DialogTitle id="save-filterset-dialog-title">
        {t("Save the current filterset")}
      </DialogTitle>
      <form onSubmit={checkFiltersetKey}>
        <DialogContent>
          <DialogContentText id="save-filterset-dialog-description">
            {t(
              "You can save your current filterset by specifying a name in the field below"
            )}
          </DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label={t("Filterset name")}
            fullWidth
            value={filtersetTitle}
            onChange={(event) => setFiltersetTitle(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveDialogClose}>{t("Cancel")}</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={loading}
          >
            {t("Save")}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
          <Dialog
            open={uniquePromptOpen}
            keepMounted
            onClose={handleUniquePromptClose}
            aria-labelledby="unique-prompt-dialog-title"
            aria-describedby="unique-prompt-dialog-description"
          >
            <DialogTitle id="unique-prompt-dialog-title">
              {t("Filterset name conflict")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="unique-prompt-dialog-description">
                {t(
                  "filterset_conflict_message",
                  "There is another filterset with the same content. Do you want to override the filterset with current values?"
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUniquePromptClose} color="primary">
                {t("Cancel")}
              </Button>
              <Button
                onClick={() => setUniqueOverrideConfirmed(true)}
                variant="contained"
                color="primary"
              >
                {t("Yes")}
              </Button>
            </DialogActions>
          </Dialog>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const FiltersetDialog = (props) => {
  const {
    kind,
    schemaId,
    appConf,
    colName,
    open,
    setOpen,
    handleFiltersChange,
    userObject,
    loadUser,
    defaultFiltersets,
  } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      data-testid="filterset-dialog"
      onClose={handleClose}
      aria-labelledby="filterset-dialog-title"
      aria-describedby="filterset-dialog-description"
    >
      <DialogTitle id="filterset-dialog-title">
        {t("Use Filtersets")}
      </DialogTitle>
      <DialogContent>
        <FiltersetTable
          kind={kind}
          schemaId={schemaId}
          appConf={appConf}
          colName={colName}
          setOpen={setOpen}
          userObject={userObject}
          loadUser={loadUser}
          defaultFiltersets={defaultFiltersets}
          handleFiltersChange={handleFiltersChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export { FiltersetDialog, SaveFiltersetDialog };
