import {
  Box,
  IconButton,
  TableCell,
  useTheme,
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import React from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import { useTranslation } from "react-i18next";

function ProjectTableHeader({
  classes,
  onFilterClick,
  tableSort,
  value,
  setTableSort,
  tableFilters,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const onRowClick = (e, name) => {
    setTableSort((prev) => {
      const newState = {
        [value.name]: !prev[value.name]
          ? "ASC"
          : prev[value.name] === "ASC"
          ? "DESC"
          : prev[value.name] === "DESC"
          ? ""
          : "ASC",
      };
      return newState[value.name] === "" ? {} : newState;
    });
  };

  return (
    <TableCell
      size="small"
      onClick={!["action"].includes(value.type) ? onRowClick : () => {}}
      width={value?.width ?? ""}
    >
      <Box className={classes.center}>
        <Box
          className={classes.center}
          style={{ cursor: "pointer" }}
          color="white"
        >
          {t(value.label)}
          {tableSort && tableSort[value.name] ? (
            tableSort[value.name] === "ASC" ? (
              <ArrowDown />
            ) : (
              <ArrowUp />
            )
          ) : (
            ""
          )}
        </Box>
        {!["nofilter", "action", "date", "progress"].includes(value.type) &&
          value.label !== "User" && (
            <IconButton onClick={(e) => onFilterClick(e, value.name)}>
              <FilterList
                style={{
                  color: tableFilters[value.name]
                    ? theme.palette.success.main
                    : "white",
                }}
              />
            </IconButton>
          )}
      </Box>
    </TableCell>
  );
}

export default ProjectTableHeader;
