/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  apiCreateAnnotationStatus,
  apiUpdateAnnotationStatus,
  apiUpdateDefaultAnnotationStatus,
} from "src/api/endpoints";
import { getTranslated } from "src/components/pages/reports/utils/helpers";

function StatusCreateDialog({
  createDialog,
  setCreateDialog,
  userId,
  setRefreshData,
  organizationId,
  type,
  count,
  val,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createAnnotationStatusData, setCreateAnnotationStatusData] = useState({
    created_by: userId,
    organization: organizationId,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === "update") {
      setCreateAnnotationStatusData({
        ...val,
        tr: type === "create" ? "" : getTranslated(val.label, "tr"),
        en: type === "create" ? "" : getTranslated(val.label, "en"),
      });
    } else {
      setCreateAnnotationStatusData({
        ...val,
        tr: type === "create" ? "" : getTranslated(val.label, "tr"),
        en: type === "create" ? "" : getTranslated(val.label, "en"),
      });
    }
  }, [createDialog]);

  const handleInput = (event) => {
    const name = event.target.name;
    const data = event.target.value;

    setCreateAnnotationStatusData({
      ...createAnnotationStatusData,
      [name]: data,
    });
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    const id = createAnnotationStatusData.id;
    delete createAnnotationStatusData.id;
    apiUpdateAnnotationStatus(
      organizationId,
      id,
      {
        ordinal: createAnnotationStatusData.ordinal,
        label: {
          "*": "(missing translation)",
          en: createAnnotationStatusData.en,
          "tr-TR": createAnnotationStatusData.tr,
        },
        completed: true,
      },
      (data, status) => {
        enqueueSnackbar(t("Successfully updated"), {
          variant: "success",
        });
        setTimeout(() => {
          setRefreshData((prev) => !prev);
          setLoading(false);
          setCreateDialog((prev) => !prev);
        }, 500);
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    apiCreateAnnotationStatus(
      organizationId,
      {
        ordinal: count + 1,
        label: {
          "*": "(missing translation)",
          en: createAnnotationStatusData.en,
          "tr-TR": createAnnotationStatusData.tr,
        },
        completed: createAnnotationStatusData?.completed ?? false,
      },
      (data, status) => {
        if (status === 201) {
          enqueueSnackbar(t("Successfully creeated"), {
            variant: "success",
          });
          setTimeout(() => {
            setRefreshData((prev) => !prev);
            setLoading(false);
            setCreateDialog((prev) => !prev);
          }, 500);
        }
      }
    );
  };

  const handleCheckbox = ({ target }) => {
    setCreateAnnotationStatusData((prev) => ({
      ...prev,
      [target.name]: target.checked,
    }));
  };

  const makeDefault = () => {
    apiUpdateDefaultAnnotationStatus(
      organizationId,
      {
        default_report_status: createAnnotationStatusData.id,
      },
      (data, status) => {
        setRefreshData((prev) => !prev);
        setLoading(false);
        setCreateDialog((prev) => !prev);
      }
    );
  };

  return (
    <Dialog
      open={createDialog}
      onClose={() => {
        setCreateDialog((prev) => !prev);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {type === "create"
          ? t("Report Annotation Status Create")
          : t("Report Annotation Status Update")}
      </DialogTitle>

      <DialogContent>
        <TextField
          variant="outlined"
          name="tr"
          value={createAnnotationStatusData?.tr}
          onChange={handleInput}
          label={t("Label") + " TR"}
          required
          fullWidth
        />
        <TextField
          variant="outlined"
          name="en"
          value={createAnnotationStatusData?.en}
          onChange={handleInput}
          label={t("Label") + " EN"}
          required
          style={{ marginTop: "10px" }}
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              name="completed"
              checked={createAnnotationStatusData?.completed}
              onChange={handleCheckbox}
            />
          }
          label={t(
            "Completed (Is report has this status. Report not to be updated variant frequency)"
          )}
        />

        {/* <TextField
          variant="outlined"
          name="description"
          value={createAnnotationStatusData?.description}
          onChange={handleInput}
          label={t("Description") + " TR"}
          fullWidth
          multiline
          style={{ marginTop: "10px" }}
        />
        <TextField
          variant="outlined"
          name="description_en"
          value={createAnnotationStatusData?.description_en}
          onChange={handleInput}
          label={t("Description") + " EN"}
          fullWidth
          multiline
          style={{ marginTop: "10px" }}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setCreateDialog((prev) => !prev);
          }}
          color="secondary"
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={type === "create" ? handleSubmit : handleUpdate}
          color="primary"
          autoFocus
          disabled={loading}
        >
          {type === "create" ? t("Save") : t("Update")}
          {loading && <CircularProgress size={24} />}
        </Button>
        {type === "update" && (
          <Button onClick={makeDefault}>{t("Make default status")}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default StatusCreateDialog;
