import {
  List,
  Box,
  IconButton,
  makeStyles,
  Typography,
  ListItem,
  Collapse,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TooltipTableCell } from "../virtualizedTable/tooltipTableCell";
import { ArrowDown } from "react-feather";
import { getTranslated } from "../utils/helpers";

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      display: "inline-flex",
      maxWidth: "100%",
      position: "sticky",
      top: 30,
      borderRadius: theme.spacing(1),
      flexDirection: "column",
      width: props.isOpened ? 300 : 0,
      opacity: props.isOpened ? 1 : 0,
      maxHeight: "85vh",
      overflow: "auto",
      padding: props.isOpened ? theme.spacing(0.5) : 0,
      marginRight: props.isOpened ? theme.spacing(2) : 0,
      backgroundColor: theme.palette.background.paper,
    },
    rootChild: {
      position: "relative",
    },
    closeButton: {
      width: "100&",
      height: "5vh",
      zIndex: 9999,
      backgroundColor: theme.palette.background.secondBackground,
      position: "sticky",
      top: 0,
    },
    close: {
      border: `1px solid ${theme.palette.secondary.main}`,
      float: "right",
    },
  }))();

function SpecifiedValues({
  specifiedValues,
  tableColDetails,
  setSpecifiedValues,
  defaultColDetailsObject,
  rowData,
}) {
  const [processedCols, setProcessedCols] = useState(null);
  const classes = useStyles({
    isOpened: processedCols && Object.entries(processedCols)?.length,
  });

  const getText = (type) => {
    if (type === "main_table_cell") {
      return "Variant Table";
    } else if (type === "details1") {
      return "Main details";
    } else if (type === "details2") {
      return "Pathogenicity score";
    } else if (type === "details3") {
      return "Population frequencies and conservation scores";
    } else if (type === "details4") {
      return "External databases (ClinVar, COSMIC, Phenotype)";
    }
  };

  useEffect(() => {
    let tmpProcessedCols = {};
    if (specifiedValues) {
      const colDetails = tableColDetails;
      for (let i = 0; i < colDetails.length; i++) {
        const colDetail = colDetails[i];

        for (let i = 0; i < specifiedValues.length; i++) {
          const specifiedValue = specifiedValues[i];
          const name = specifiedValue[0];
          const value = specifiedValue[1];

          if (colDetail.name === name) {
            let objectName = colDetail.representation.kind;
            if (
              defaultColDetailsObject[name].representation.options.kind !==
                "main_table_cell" &&
              defaultColDetailsObject[name].representation.options.group_name
            )
              objectName =
                defaultColDetailsObject[name].representation.options.group_name;
            tmpProcessedCols = {
              ...tmpProcessedCols,
              [objectName]: {
                ...tmpProcessedCols[objectName],
                [name]: value,
              },
            };
            break;
          }
        }
      }

      setProcessedCols(tmpProcessedCols);
    }
  }, [specifiedValues]);

  if (!processedCols) return "";
  else
    return (
      <Box className={classes.root}>
        <Box className={classes.rootChild}>
          <Box padding={1} className={classes.closeButton}>
            <IconButton
              size="small"
              className={classes.close}
              onClick={() => setSpecifiedValues([])}
            >
              <Close color="secondary" />
            </IconButton>
          </Box>
          <List>
            {Object.entries(processedCols).map((val) => (
              <ListItems val={val} rowData={rowData} />
            ))}
          </List>
        </Box>
      </Box>
    );

  function ListItems({ val, rowData }) {
    const [open, setOpen] = useState(true);
    const { t, i18n } = useTranslation();
    const innerRef = useRef();
    return (
      <>
        <ListItem button onClick={() => setOpen((prev) => !prev)}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              width: "100%",
            }}
          >
            <Typography style={{ width: "90%" }} variant="h5">
              {t(getText(val[0]))}
            </Typography>
            <ArrowDown
              style={{
                width: "10%",
                transform: `rotate(${open ? "180" : "0"}deg)`,
                transition: "all ease 500ms",
              }}
            />
          </Box>
        </ListItem>
        <Collapse in={open}>
          <Table size="small" style={{ width: "100%", tableLayout: "fixed" }}>
            <TableBody>
              {Object.entries(val[1]).map(
                ([name, value]) =>
                  value && (
                    <>
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            {getTranslated(
                              defaultColDetailsObject[name].label
                            ) ??
                              JSON.stringify(
                                defaultColDetailsObject[name].label
                              )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell ref={innerRef}>
                          <TooltipTableCell
                            column={defaultColDetailsObject[name]}
                            className={""}
                            height={200}
                            alignment={
                              defaultColDetailsObject[name].numeric || false
                                ? "right"
                                : "left"
                            }
                            rowData={rowData}
                            ref={innerRef}
                            data={value}
                            exceptions={[]}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  )
              )}
            </TableBody>
          </Table>
        </Collapse>
      </>
    );
  }
}

export default SpecifiedValues;
