import { Box, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  finished: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  status: {
    animation: `$tableRow 1.5s ${theme.transitions.easing.easeOut} infinite`,
  },
  "@keyframes tableRow": {
    "0%": {
      opacity: 0.3,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.3,
    },
  },
}));

function ProgressBar({ status, value, fail, completed }) {
  const classes = useStyles();
  return (
    <div className={classes.finished}>
      {["failed", "cancelled"].includes(status) ? (
        fail
      ) : status === "completed" ? (
        completed
      ) : (
        <Box display="flex" alignItems="center" width="100%">
          <Box width="100%" className={classes.status} mr={1}>
            <LinearProgress variant="determinate" value={value} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2">{`${Math.round(value)}%`}</Typography>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default ProgressBar;
