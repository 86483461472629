import { Box, CircularProgress, Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Filter from "./filter/filter";
import OutSource from "./outsource";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  evenly: {
    display: "flex",
    justifyContent: "space-evenly",
    alignContent: "center",
    width: 300,
  },
}));

function AdvanceFilter({
  fields,
  setAdvanceFilter,
  version,
  advanceFilter,
  reportMeta,
  schemaId,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [visibleFields] = useState(fields);
  const callback = (panel, pheno, filtersets, colName) => {
    let tmpColName = "";
    if (reportMeta.pipeline_class === "trio") {
      tmpColName = "main.gene_symbol";
    } else {
      if (version >= 12) {
        tmpColName = "main.gene_symbol";
      } else {
        tmpColName = "main.symbol";
      }
    }
    let rules = JSON.parse(JSON.stringify(advanceFilter.rules));
    rules.push({
      combinator: "and",
      id: "g-" + Math.random().toString(),
      not: false,
      rules: [],
    });

    if (panel.length === 0 && pheno.length === 0) {
      filtersets.forEach((filterset) => {
        if (filterset.name !== "") {
          rules[rules.length - 1].rules.push({
            field: tmpColName,
            id: "r-0." + (Math.random() * 1000).toString(),
            operator: "in",
            value: filterset.values.join(","),
          });
        }
      });
    }

    setAdvanceFilter((prev) => ({ ...prev, rules: [...rules] }));

    for (let i = 0; i < pheno.length; i++) {
      const item = pheno[i];
      rules[rules.length - 1].rules.push({
        field: tmpColName,
        id: "r-0." + (Math.random() * 1000).toString(),
        operator: "in",
        value: item.hugo_symbol.join(","),
      });
      setAdvanceFilter((prev) => ({ ...prev, rules: [...rules] }));
    }

    for (let i = 0; i < panel.length; i++) {
      const item = Object.values(panel[i][1])
        .map((value) => value.genes.map((val) => val.label).join(","))
        .map((genes) => genes.replaceAll(" ", ""))
        .toString();
      rules[rules.length - 1].rules.push({
        field: tmpColName,
        id: "r-0." + (Math.random() * 1000).toString(),
        operator: "in",
        value: item,
      });
      setAdvanceFilter((prev) => ({ ...prev, rules: [...rules] }));
    }
  };

  if (!visibleFields) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Filter
          setAdvanceFilter={setAdvanceFilter}
          advanceFilter={advanceFilter}
          schemaId={schemaId}
          setOpen={setOpen}
          fields={visibleFields}
        />
        <Box className={classes.root}>
          <OutSource
            callback={callback}
            schemaId={schemaId}
            type={"hpo"}
            label={t("HPO Filter")}
          />
          <OutSource
            callback={callback}
            type={"panel"}
            schemaId={schemaId}
            label={t("Massive Panel")}
          />
          <OutSource
            callback={callback}
            type={"filtersets"}
            colName={version >= 12 ? "main.gene_symbol" : "main.symbol"}
            schemaId={schemaId}
            label={t("Gene Symbol Filterset")}
          />
        </Box>
      </>
    );
  }
}

export default AdvanceFilter;
