/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Save as SaveIcon } from "@material-ui/icons";
import { loadUser } from "src/redux/actions";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { apiUpdateProfile } from "src/api";

const useStyles = makeStyles((theme) => ({
  dropdownWrapper: {
    marginBottom: "8px",
    minWidth: "200px",
    "& #preset-charts-dropdown-label": {
      backgroundColor: `${theme.palette.background.mainBackground}`,
      borderRight: `4px solid ${theme.palette.background.mainBackground}`,
      borderLeft: `4px solid ${theme.palette.background.mainBackground}`,
    },
    "& #preset-charts-dropdown": {
      backgroundColor: `${theme.palette.background.mainBackground}`,
    },
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CustomDropDown = (props) => {
  const classes = useStyles();
  const { chartsConf, activeChartsPreset, setActiveChartsPreset } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormControl
        key="filter-select"
        className={classes.dropdownWrapper}
        margin="dense"
        variant="outlined"
      >
        <InputLabel id="preset-charts-dropdown-label">
          {t("Preset charts")}
        </InputLabel>
        <Select
          labelId="preset-charts-dropdown-label"
          id="preset-charts-dropdown"
          value={activeChartsPreset}
          onChange={(event) => setActiveChartsPreset(event.target.value)}
          onClose={(event) => event.stopPropagation()}
        >
          <MenuItem value={"default"}>{t("Default")}</MenuItem>
          {chartsConf &&
            Boolean(Object.keys(chartsConf)?.length) &&
            Object.keys(chartsConf).map((presetKey, index) => {
              return (
                <MenuItem key={index} value={presetKey}>
                  {chartsConf[presetKey].name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => dispatch(loadUser()),
  };
}

const ConnectedChartsPresets = (props) => {
  const classes = useStyles();
  const {
    reportMeta,
    userObject,
    loadUser,
    chartOptions,
    activeChartsPreset,
    setActiveChartsPreset,
  } = props;
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const chartsConf =
      userObject.settings.report[reportMeta.project_type].charts;

    if (
      chartsConf &&
      chartsConf[reportMeta.schema_version] &&
      Object.keys(chartsConf[reportMeta.schema_version])?.length
    ) {
      for (let item in chartsConf[reportMeta.schema_version]) {
        if (chartsConf[reportMeta.schema_version][item].is_default) {
          setActiveChartsPreset(item);
        }
      }
    }
  }, []);

  const handleSaveDialogClose = () => {
    setSaveDialogOpen(false);
  };

  const handleSaveDialogOpen = () => {
    setSaveDialogOpen(true);
  };

  const handleSavePreset = (event) => {
    setLoading(true);
    event.preventDefault();
    const title = event.target[0].value;
    const newPresetKey = title.toLowerCase().replace(" ", "_");

    if (
      userObject.settings.report[reportMeta.project_type].charts[
        reportMeta.schema_version
      ] === undefined
    ) {
      userObject.settings.report[reportMeta.project_type].charts[
        reportMeta.schema_version
      ] = {};
    } else if (
      Object.keys(
        userObject.settings.report[reportMeta.project_type].charts[
          reportMeta.schema_version
        ]
      ).includes(newPresetKey)
    ) {
      enqueueSnackbar(
        t(
          "chart_preset_name_not_unique_error",
          "A chart preset with the name '{{title}}' already exists! Please use a unique name and try again.",
          { title: title }
        ),
        { variant: "error" }
      );
      return;
    }

    const chartsList = [];
    const chartOptionKeys = Object.keys(chartOptions);
    for (let i = 0; i < chartOptionKeys?.length; i++) {
      if (chartOptions[chartOptionKeys[i]]) {
        chartsList.push(chartOptionKeys[i]);
      }
    }
    userObject.settings.report[reportMeta.project_type].charts[
      reportMeta.schema_version
    ][newPresetKey] = {
      name: title,
      charts: chartsList,
    };
    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      setLoading(false);
      handleSaveDialogClose();
      if (status === 200) {
        setActiveChartsPreset(newPresetKey);
        loadUser(userObject);
        enqueueSnackbar(
          t(
            "charts_preset_saved_message",
            "Charts preset {{name}} successfully saved.",
            { name: title }
          ),
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(data, { variant: "error" });
      }
    });
  };

  return (
    <React.Fragment>
      <Button
        style={{ marginLeft: "auto", marginRight: "20px", height: "48px" }}
        color="primary"
        variant="contained"
        startIcon={<SaveIcon />}
        onClick={handleSaveDialogOpen}
      >
        {t("Save current charts as preset")}
      </Button>
      <Dialog
        onClick={(event) => event.stopPropagation()}
        open={saveDialogOpen}
        keepMounted
        onClose={handleSaveDialogClose}
        aria-labelledby="save-charts-dialog-title"
        aria-describedby="save-charts-dialog-description"
      >
        <DialogTitle id="save-charts-dialog-title">
          {t("Save the current charts settings")}
        </DialogTitle>
        <form onSubmit={handleSavePreset}>
          <DialogContent>
            <DialogContentText id="save-charts-dialog-description">
              {t(
                "You can save your current charts settings by specifying a name in the field below"
              )}
            </DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              label={t("Preset name")}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveDialogClose} color="secondary">
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={loading}
            >
              {t("Save")}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CustomDropDown
        chartsConf={
          userObject.settings.report[reportMeta.project_type].charts[
            reportMeta.schema_version
          ]
        }
        activeChartsPreset={activeChartsPreset}
        setActiveChartsPreset={setActiveChartsPreset}
      />
    </React.Fragment>
  );
};

export const ChartsPresets = connect(
  null,
  mapDispatchToProps
)(ConnectedChartsPresets);
