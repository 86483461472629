import { latestSettingsVersion } from "src/config";

function v0_v1(settingsObj) {
  const mainTabsIdxToName = {
    1: "statistics",
    2: "variant_table",
    3: "cnv",
  };

  const detailsTabsIdxToName = {
    1: "main",
    2: "pathogenicity",
    3: "population_conservation",
    4: "curated",
    5: "igv",
  };

  const newSettingsObj = {
    version: 1,
    general: {
      defaultLang:
        settingsObj && settingsObj.defaultLang ? settingsObj.defaultLang : "en",
      searchConf: ["pages", "tutorials", "announcements"],
    },
    report: {
      exome_fastq: {
        charts: {},
        statistics: {},
        defaultMainTab:
          settingsObj && settingsObj.defaultReportTab
            ? mainTabsIdxToName[settingsObj.defaultReportTab]
            : "statistics",
        defaultDetailsTab:
          settingsObj && settingsObj.defaultDetailsTab
            ? detailsTabsIdxToName[settingsObj.defaultDetailsTab]
            : "main",
      },
      exome_vcf: {
        charts: {},
        statistics: {},
        defaultMainTab: "statistics",
        defaultDetailsTab: "main",
      },
      exome_bam: {
        charts: {},
        statistics: {},
        defaultMainTab: "statistics",
        defaultDetailsTab: "main",
      },
      trio: {
        charts: {},
        statistics: {},
        defaultMainTab: "statistics",
        defaultDetailsTab: "main",
      },
      acnv: {
        charts: {},
        statistics: {},
        defaultMainTab: "statistics",
        defaultDetailsTab: "igv",
      },
      filtersets:
        settingsObj && settingsObj.filtersets
          ? Object.assign({}, settingsObj.filtersets)
          : {},
    },
  };

  for (let i = 0; i < newSettingsObj.general.searchConf.length; i++) {
    if (
      !settingsObj.searchConf.includes(newSettingsObj.general.searchConf[i])
    ) {
      newSettingsObj.general.searchConf.splice(i, 1);
      i--;
    }
  }

  if (settingsObj && settingsObj.charts) {
    for (let item in settingsObj.charts) {
      if (item !== "default") {
        newSettingsObj.report.exome_fastq.charts[item] = Object.assign(
          {},
          settingsObj.charts[item]
        );
      }
    }
  }

  return newSettingsObj;
}

function v1_v2(settingsObj) {
  const newSettingsObj = { ...settingsObj };
  newSettingsObj.version = 2;
  newSettingsObj.general["hideSystemNotification"] = null;

  return newSettingsObj;
}

const migrationFunctions = {
  v0_v1: v0_v1,
  v1_v2: v1_v2,
};

export function adaptUserSettings(settingsObj) {
  if (settingsObj === null) {
    settingsObj = {
      chartsConf: {
        default: {
          name: "Default",
          charts: [
            "cnv_diagram",
            "genomic_fraction_coverage",
            "chromosome_based_copy_ratio",
            "chromosome_mean_coverage",
            "mapping_quality_across_reference",
            "coverage_histogram",
            "indel_length",
            "transitions_and_transversions",
            "acgt_content",
            "insert_size_his",
            "mapped_reads_nucleotide_content",
            "mapped_reads_gc_content_disribution",
            "mapped_read_clipping_profile",
          ],
        },
      },
      filtersets: {
        "main.symbol": {},
        "main.gene_symbol": {},
        "main.consequence": {},
        "main.significancy": {},
        "main.pathogenicity": {},
      },
      searchConf: ["pages", "tutorials", "announcements"],
      defaultLang: "tr",
      defaultReportTab: "2",
      defaultDetailsTab: "3",
    };
  }
  if (settingsObj.version === latestSettingsVersion) {
    return false;
  }

  const currentVersion = settingsObj.version ?? 0;
  let tmpSettingsObj = settingsObj ? Object.assign({}, settingsObj) : undefined;

  for (let i = currentVersion; i < latestSettingsVersion; i++) {
    tmpSettingsObj = migrationFunctions[`v${i}_v${i + 1}`](tmpSettingsObj);
  }

  return tmpSettingsObj;
}
