import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import typography from "src/theme/typography";
const useStyles = makeStyles({
  header: {},
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "50%",
    color: "red",
  },
  iconGrid: {
    height: "13vh",
    width: "13vh",
    borderRadius: "50%",
  },
  fill: {
    fill: (props) => props.color,
  },
  typography: {
    "& p": {
      ...typography.h6,
      opacity: 0.5,
    },
    "& h2": {
      ...typography.h2,
    },
  },
});

function Stats({ icon, data, title, ...props }) {
  const classes = useStyles(props);
  return (
    <Grid container spacing={2}>
      <Grid item xs={5} className={clsx(classes.center, classes.iconGrid)}>
        {icon}
      </Grid>
      <Grid item xs={7} className={classes.center}>
        <Grid container spacing={2} className={classes.typography}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" component="p">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" component="h3">
              {data}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Stats;
