/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  HomopolymerBarChart,
  STBarChart,
  ChromosomesLineChart,
  IndelLenChart,
  MismatchIndelsPieChart,
  ACGTContentPieChart,
  ChromosomeCopyRatioLineChart,
  MappingQualityAcrossReferenceLineChart,
  CoverageHistogramBarChart,
  GenomicFractionCoverageBarChart,
  DuplicationRateHistogram,
  MappedReadsNucleotideContentLineChart,
  MappedReadsGCContentDistributionLineChart,
  MappedReadsClippingProfileLineChart,
  MappingQualityHistogramBarChart,
  InsertSizeAcrossReferenceLineChart,
  InsertSizeHistogramBarChart,
  CoverageAcrossRefLineChart,
  ChromosomeCopyRatioLineChartNoTreshold,
  ChromosomeCopyRatioLineChartMaleGender,
} from "../charts";
import StatsTableSection from "./statsTable";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import CNVChromosomesChart from "../charts/CNVChromosomeChart";
import { ChartsPresets } from "./chartsPresets";
import safeLogger from "src/services/safeLogger";
import CNLineChart from "../charts/CNChart";
import { reportSectionsConf } from "src/config";
import TrioCharts from "./trioCharts";
import CNVChromosomesChartNoGender from "../charts/CNVChromosomeChartNoGender";
import { getBaseLanguage } from "../utils/helpers";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  chartContainer: {
    position: "relative",
  },
}));

export const Statistics = (props) => {
  const classes = useStyles();
  const { reportMeta, files, version, userObject, appConf } = props;
  const { t } = useTranslation();
  let reportSections =
    reportSectionsConf[reportMeta.project_type].charts[
      reportMeta.schema_version
    ];
  if (!reportSections) {
    return (
      <Alert severity="error">
        {t(
          "The version of the statistics schema needs to be updated. Please contact the Massive Bioinformatics administrators.",
        )}
      </Alert>
    );
  }
  const [chartOptions, setChartOptions] = useState(
    Array.from(reportSections).reduce((dict, currVal) => {
      return Object.assign(dict, { [currVal]: true });
    }, {}),
  );
  const [activeChartsPreset, setActiveChartsPreset] = useState("default");
  const [helpInformation, setHelpInformation] = useState(undefined);
  const [qualimapJson, setQualimapJson] = useState(undefined);
  const [sex, setSex] = useState(undefined); // used for ACNV copy ratio chart distinction

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["report_information"].download_address)
        .then((response) => response.json())
        .then((data) => {
          const gender = data.gender
            ? data.gender === "XY"
              ? "male"
              : "female"
            : "male";
          if (mounted) {
            setSex(gender);
          }
        });
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSex("male");
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (files["qualimap_json"]) {
      try {
        fetch(getBaseURL() + files["qualimap_json"].download_address)
          .then((response) => response.json())
          .then((data) => {
            if (mounted) {
              setQualimapJson(data);
            }
          });
      } catch (e) {
        safeLogger(e);
        if (mounted) {
          // setQualimapJson(null);
          setQualimapJson({});
        }
      }
    } else {
      if (reportMeta.project_type === "trio") {
        const tmpQualimap = {};
        try {
          fetch(getBaseURL() + files["qualimap_json-child"].download_address)
            .then((response) => response.json())
            .then((data) => {
              tmpQualimap["child"] = data;
              fetch(
                getBaseURL() + files["qualimap_json-mother"].download_address,
              )
                .then((response) => response.json())
                .then((data) => {
                  tmpQualimap["mother"] = data;
                  fetch(
                    getBaseURL() +
                      files["qualimap_json-father"].download_address,
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      tmpQualimap["father"] = data;
                      if (mounted) {
                        setQualimapJson(tmpQualimap);
                      }
                    });
                });
            });
        } catch (e) {
          safeLogger(e);
          if (mounted) {
            // setQualimapJson(null);
            setQualimapJson({});
          }
        }
      } else {
        // setQualimapJson(null);
        setQualimapJson({});
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    Chart.plugins.unregister(ChartDataLabels); // TODO if charts are on another page too, unregister at app level
  }, []);

  useEffect(() => {
    let mounted = true;
    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-cache");
    fetch(
      `/assets/statistics/stats_doc_${getBaseLanguage(appConf.lang)}.json`,
      {
        cache: "no-cache",
        headers: myHeaders,
      },
    ) // TODO translate to all languages
      .then((response) => response.json())
      .then((data) => {
        if (mounted) {
          setHelpInformation(data);
        }
      });

    return function cleanup() {
      mounted = false;
    };
  }, [appConf]);

  useEffect(() => {
    if (activeChartsPreset) {
      const chartsList =
        activeChartsPreset === "default"
          ? reportSectionsConf[reportMeta.project_type].charts[
              reportMeta.schema_version
            ]
          : userObject.settings.report[reportMeta.project_type].charts[
              reportMeta.schema_version
            ][activeChartsPreset].charts;
      for (let chartKey in chartOptions) {
        chartOptions[chartKey] = chartsList.includes(chartKey);
      }

      if (chartOptions) {
        setChartOptions({ ...chartOptions });
      }
    }
  }, [activeChartsPreset]);

  const handleChange = (event) => {
    if (chartOptions) {
      setChartOptions({
        ...chartOptions,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const getChartComponent = (chartKey, tagSuffix) => {
    const components = {
      cnv_diagram: {
        fileDependencies: ["cnv"],
        fullwidth: true,
        component: (
          <CNVChromosomesChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      cnv_diagram_no_gender: {
        fileDependencies: ["cnv"],
        fullwidth: true,
        component: (
          <CNVChromosomesChartNoGender
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      coverage_across_reference: {
        fileDependencies: ["coverage_across_reference"],
        fullwidth: false,
        component: (
          <CoverageAcrossRefLineChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      chromosome_mean_coverage: {
        fileDependencies: ["qualimap_json"],
        fullwidth: false,
        component: (
          <ChromosomesLineChart
            qualimapJson={tagSuffix ? qualimapJson[tagSuffix] : qualimapJson}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      homopolymer_indels: {
        fullwidth: false,
        component: (
          <HomopolymerBarChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      transitions_and_transversions: {
        fileDependencies: ["st"],
        fullwidth: false,
        component: (
          <STBarChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      indel_length: {
        fileDependencies: ["indel_len"],
        fullwidth: false,
        component: (
          <IndelLenChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      mismatch_indels: {
        fullwidth: false,
        component: (
          <MismatchIndelsPieChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      acgt_content: {
        fileDependencies: ["qualimap_json"],
        fullwidth: false,
        component: (
          <ACGTContentPieChart
            qualimapJson={tagSuffix ? qualimapJson[tagSuffix] : qualimapJson}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      chromosome_based_copy_ratio: {
        fileDependencies: ["copy_ratio"],
        fullwidth: false,
        component: (
          <ChromosomeCopyRatioLineChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      chromosome_based_copy_ratio_male_gender: {
        fileDependencies: ["copy_ratio"],
        fullwidth: false,
        component: (
          <ChromosomeCopyRatioLineChartMaleGender
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      chromosome_based_copy_ratio_no_treshold: {
        fileDependencies: ["copy_ratio"],
        fullwidth: false,
        component: (
          <ChromosomeCopyRatioLineChartNoTreshold
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      copy_number_copy_ratio: {
        fileDependencies: ["copy_ratio"],
        fullwidth: false,
        component: (
          <CNLineChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      mapping_quality_across_reference: {
        fileDependencies: ["mapping_quality_across_reference"],
        fullwidth: false,
        component: (
          <MappingQualityAcrossReferenceLineChart
            tagSuffix={tagSuffix}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      coverage_histogram: {
        fileDependencies: ["coverage_histogram"],
        fullwidth: false,
        component: (
          <CoverageHistogramBarChart
            tagSuffix={tagSuffix}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      genomic_fraction_coverage: {
        fileDependencies: ["genome_fraction_coverage"],
        fullwidth: false,
        component: (
          <GenomicFractionCoverageBarChart
            tagSuffix={tagSuffix}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      duplication_rate_histogram: {
        fullwidth: false,
        component: (
          <DuplicationRateHistogram
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      mapped_reads_nucleotide_content: {
        fileDependencies: ["mapped_reads_nucleotide_content"],
        fullwidth: false,
        component: (
          <MappedReadsNucleotideContentLineChart
            tagSuffix={tagSuffix}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      mapped_reads_gc_content_disribution: {
        fileDependencies: ["mapped_reads_gc-content_distribution"],
        fullwidth: false,
        component: (
          <MappedReadsGCContentDistributionLineChart
            tagSuffix={tagSuffix}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      mapped_read_clipping_profile: {
        fileDependencies: ["mapped_reads_clipping_profile"],
        fullwidth: false,
        component: (
          <MappedReadsClippingProfileLineChart
            tagSuffix={tagSuffix}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      mapping_quality_histogram: {
        fileDependencies: ["mapped_reads_clipping_profile"],
        fullwidth: false,
        component: (
          <MappingQualityHistogramBarChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      insert_size_across_reference: {
        fullwidth: false,
        component: (
          <InsertSizeAcrossReferenceLineChart
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
      insert_size_his: {
        fileDependencies: ["insert_size_histogram"],
        fullwidth: false,
        component: (
          <InsertSizeHistogramBarChart
            tagSuffix={tagSuffix}
            files={files}
            helpInformation={helpInformation[chartKey]}
          />
        ),
      },
    };

    return components[chartKey];
  };

  if (!chartOptions || !helpInformation || qualimapJson === undefined || !sex) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <React.Fragment>
        <Box>
          <Typography style={{ margin: "30px auto" }}>
            {helpInformation.tab_information.description}
          </Typography>
          {files && qualimapJson && (
            <StatsTableSection
              reportMeta={reportMeta}
              version={version}
              files={files}
              userObject={userObject}
              qualimapJson={qualimapJson}
              helpInformation={helpInformation}
            />
          )}
          <Box display="flex" mt={4}>
            <Typography
              component="h4"
              variant="h4"
              style={{ margin: "30px 0" }}
            >
              {t("Charts and graphs")}
            </Typography>
            <ChartsPresets
              reportMeta={reportMeta}
              userObject={userObject}
              chartOptions={chartOptions}
              activeChartsPreset={activeChartsPreset}
              setActiveChartsPreset={setActiveChartsPreset}
            />
          </Box>
          <FormGroup row style={{ margin: "30px auto" }}>
            {Object.keys(chartOptions).map((chartKey, index) => {
              if (
                reportMeta.project_type === "acnv" &&
                chartKey === "chromosome_based_copy_ratio_male_gender" &&
                sex !== "male"
              ) {
                return <div key={index}></div>;
              }
              const chartComponentObj = getChartComponent(chartKey);
              if (chartComponentObj.fileDependencies) {
                for (
                  let i = 0;
                  i < chartComponentObj.fileDependencies?.length;
                  i++
                ) {
                  if (!files[chartComponentObj.fileDependencies[i]]) {
                    return <div key={index}></div>;
                  }
                }
              }
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      checked={chartOptions[chartKey]}
                      onChange={handleChange}
                      name={chartKey}
                    />
                  }
                  // label={chartKey.replace(/_/g, " ")}

                  label={helpInformation[chartKey].label}
                />
              );
            })}
          </FormGroup>
        </Box>
        <Grid container spacing={6} id="etseeee">
          {Object.keys(chartOptions).map((chartKey, index) => {
            if (chartOptions[chartKey]) {
              const chartComponentObj = getChartComponent(chartKey);
              if (!chartComponentObj) {
                return <div key={index}></div>;
              }
              if (
                reportMeta.project_type === "acnv" &&
                chartKey === "chromosome_based_copy_ratio_male_gender" &&
                sex !== "male"
              ) {
                return <div key={index}></div>;
              }

              if (chartComponentObj.fileDependencies) {
                for (
                  let i = 0;
                  i < chartComponentObj.fileDependencies?.length;
                  i++
                ) {
                  if (!files[chartComponentObj.fileDependencies[i]]) {
                    return <div key={index}></div>;
                  }
                }
              } else {
                safeLogger(chartComponentObj);
              }
              return (
                <Grid
                  className={classes.chartContainer}
                  item
                  xs={12}
                  sm={chartComponentObj.fullwidth ? 12 : 6}
                  key={index}
                >
                  {chartComponentObj.component}
                </Grid>
              );
            }
          })}
        </Grid>
        {reportMeta.project_type === "trio" && (
          <TrioCharts
            qualimapJson={qualimapJson}
            files={files}
            getChartComponent={getChartComponent}
            chartOptions={chartOptions}
          />
        )}
      </React.Fragment>
    );
  }
};
