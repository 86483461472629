import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    marginTop: 5,
    overflow: "hidden",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    "& .MuiCardContent-root:last-child": {
      padding: "2px 24px",
    },
    "&:hover": {
      backgroundColor: theme.palette.background.secondBackground,
    },
  },
  details: {
    display: "flex",
    width: "100%",

    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
}));

export default function MediaCard({ value }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {value.main_image && (
        <CardMedia
          className={classes.cover}
          image={
            value.main_image
              ? `${/*getBlogBaseURL()*/ "https://tea.massiveanalyser.com/"}/${
                  value.main_image.url
                }`
              : "/assets/images/misc/placeholder.png"
          }
          title={value.title}
        />
      )}
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {value.title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {format(new Date(value.meta.first_published_at), "dd/MM/yyyy")}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}
