import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  colors,
  Divider,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { reportSectionsConf } from "src/config";
import { DefaultDetailsTabSection } from "./defaultDetailsTabSection";
import { DefaultReportTabSection } from "./defaultReportTabSection";
import { TableConfigSection } from "./tableConfigSection";
import { ChartsConfigSection } from "./chartsConfigSection";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    background: "inherit",
  },
  tabRoot: {
    minWidth: '126px',
  },
  pageTitle: {
    marginBottom: "40px",
    color: colors.grey[800],
  },
  sectionTitle: {
    marginBottom: "10px",
  },
  sectionDivider: {
    marginTop: "25px",
    width: "100%",
  },
  warningBox: {
    background: theme.palette.warning.light,
    border: `1px solid ${colors.orange[200]}`,
    borderRadius: "12px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export const ReportSection = (props) => {
  const { userObject, appConf, loadUser } = props;
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  delete reportSectionsConf["nanopore"];

  return (
    <div>
      <Typography variant="h4" component="h4" style={{ marginBottom: "15px" }}>
        {t("Advanced")}
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="Report main tabs"
      >
        {Object.keys(reportSectionsConf).map((item, idx) => {
          if (item === "nanopore") return "";
          return (
            <Tab
              key={idx}
              data-testid={item}
              label={t(reportSectionsConf[item].label)}
              classes={{
                root: classes.tabRoot
              }}
            />
          );
        })}
      </Tabs>
      {Object.keys(reportSectionsConf).map((item, idx) => {
        return (
          item !== "nanopore" && (
            <TabPanel key={idx} value={tabValue} index={idx}>
              {Object.keys(reportSectionsConf[item].tabs).length > 1 && (
                <React.Fragment>
                  <Grid item xs={12} style={{ paddingTop: "20px" }}>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.sectionTitle}
                    >
                      {t("Default analysis tab")}
                    </Typography>
                    <Typography>
                      {t(
                        "Choose which tab should be the default in the analysis page"
                      )}
                    </Typography>
                    <DefaultReportTabSection
                      projectTypeConf={reportSectionsConf[item]}
                      loadUser={loadUser}
                      userObject={userObject}
                    />
                  </Grid>
                  <Divider className={classes.sectionDivider} />
                </React.Fragment>
              )}
              {Object.keys(reportSectionsConf[item].detailsTabs).length > 1 && (
                <React.Fragment>
                  <Grid item xs={12} style={{ paddingTop: "20px" }}>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.sectionTitle}
                    >
                      {t("Default analysis details tab")}
                    </Typography>
                    <Typography>
                      {t(
                        "Choose which details tab should be the default in the analysis page"
                      )}
                    </Typography>
                    <DefaultDetailsTabSection
                      projectTypeConf={reportSectionsConf[item]}
                      loadUser={loadUser}
                      userObject={userObject}
                      appConf={appConf}
                    />
                  </Grid>
                  <Divider className={classes.sectionDivider} />
                </React.Fragment>
              )}

              <Grid item xs={12}>
                <Box display="flex" pt={6}>
                  <Typography
                    component="h2"
                    variant="h3"
                    className={classes.sectionTitle}
                  >
                    {t("Variant Table Configurations")}
                  </Typography>
                  <Link
                    to={`tableconf/create?project_type=${item}`}
                    style={{ marginLeft: "auto" }}
                    data-testid="to-tableconf-create"
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleIcon />}
                    >
                      {t("New Configuration")}
                    </Button>
                  </Link>
                </Box>
                <Typography>
                  {t("Manage your table layout and filter presets")}
                </Typography>
                <Box className={classes.warningBox} display="flex">
                  <Typography>
                    {t(
                      "table_config_compatibility_message",
                      "Please note: In case of a major update in the application, the old table layout configuration may be incompatible with the new analysis results table. On the analysis result page, only the compatible layouts are shown."
                    )}
                  </Typography>
                </Box>
                <TableConfigSection
                  projectTypeConf={reportSectionsConf[item]}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" pt={6}>
                  <Typography
                    component="h2"
                    variant="h3"
                    className={classes.sectionTitle}
                  >
                    {t("Statistics charts presets")}
                  </Typography>
                  <Link
                    to={`chartsconf/create?project_type=${item}`}
                    style={{ marginLeft: "auto" }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleIcon />}
                    >
                      {t("New statistics chart preset")}
                    </Button>
                  </Link>
                </Box>
                <Typography>
                  {t("Manage your statistics charts presets")}
                </Typography>
                <Box className={classes.warningBox}>
                  <Typography>
                    {t(
                      "statistics_config_compatibility_message",
                      "Please note: In case of a major update in the application, old statistics configuration may be incompatible with the new analysis results. In the analysis result page, only the compatible configurations are shown."
                    )}
                  </Typography>
                </Box>
                <ChartsConfigSection
                  projectTypeConf={reportSectionsConf[item]}
                  appConf={appConf}
                  userObject={userObject}
                  loadUser={loadUser}
                />
              </Grid>
            </TabPanel>
          )
        );
      })}
    </div>
  );
};
