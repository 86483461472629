/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import Chart from "chart.js";
import { ChartLegend } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const getLastChrSuffix = (dataset, chr) => {
  if (!dataset.length) {
    return 0;
  }

  let suffix = 0;

  for (let i = 0; i < dataset.length; i++) {
    const datasetLabel = dataset[i].label.split(" ");
    if (datasetLabel[0] === chr && parseInt(datasetLabel[1]) > suffix) {
      suffix = parseInt(datasetLabel[1]);
    }
  }

  return suffix;
};

const copyNumberLines = [
  { value: 1.1699, cn: 2, color: "blue" },
  { value: 0.8073, cn: 1, color: "green" },
  { value: -0.4151, cn: 0, color: "pink" },
];

const stripes = [
  { color: "rgba(13, 71, 161, 0.2)", height: 0.073355556 },
  { color: "rgba(0, 188, 212, 0.2)", height: 0.080577778 },
  { color: "rgba(76, 175, 80, 0.2)", height: 0.271644444 },
  { color: "rgba(255, 64, 129, 0.2)", height: 0.574422222 },
];

const ChromosomeCopyRatioLineChartMaleGender = (props) => {
  const classes = useStyles();
  const { helpInformation, files } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const [xRange, setXRange] = useState([]);
  const [chrReverse, setChrReverse] = useState(undefined);
  const [chromosomePositions, setChromosomePositions] = useState(undefined);
  const [chromosomeLines, setChromosomeLines] = useState(undefined);
  const [cnRanges, setCnRanges] = useState(undefined);
  const [sex, setSex] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["report_information"].download_address)
        .then((response) => response.json())
        .then((data) => {
          const gender = data.gender
            ? data.gender === "XY"
              ? "male"
              : "female"
            : "male";
          if (mounted) {
            setSex(gender);
          }
        });
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSex("male");
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    Chart.pluginService.register({
      beforeDraw: function (chart, easing) {
        if (chart.config.options.chartArea === "cnv_male_gender") {
          var ctx = chart.chart.ctx;
          var chartArea = chart.chartArea;

          ctx.save();

          ctx.clearRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
          );

          let filledHeight = 0;
          for (let i = 0; i < stripes.length; i++) {
            const stripHeight =
              (chartArea.bottom - chartArea.top) * stripes[i].height;
            ctx.fillStyle = stripes[i].color;
            ctx.fillRect(
              chartArea.left,
              chartArea.top + filledHeight,
              chartArea.right - chartArea.left,
              stripHeight
            );
            filledHeight += stripHeight;
          }

          ctx.restore();
        }
      },
    });
  }, []);

  useEffect(() => {
    const rangeLines = [];
    for (let i = 0; i < copyNumberLines.length; i++) {
      rangeLines.push({
        drawTime: "afterDatasetsDraw",
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: copyNumberLines[i].value,
        borderWidth: 1,
        borderColor: colors[copyNumberLines[i].color][300],
        label: {
          content: `cn ${copyNumberLines[i].cn} (${copyNumberLines[i].value}) `,
          enabled: false,
          position: "right",
          fontColor: "#313131",
          fontSize: 10,
          backgroundColor: "rgba(0,0,0,0)",
          yAdjust: 10,
        },
      });
    }
    setCnRanges(rangeLines);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (sex) {
      let prevPosition = 0;
      const chrPositions = {};
      const chrLines = [];
      fetch("/assets/statistics/bam_chr_statsfrom_samtools")
        .then((response) => response.text())
        .then((data) => {
          let chrData = data.split("\n");

          chrData = chrData.slice(0, chrData.length - 1);

          for (let i = 0; i < chrData.length; i++) {
            const chromosome = chrData[i].split("\t");
            const position = prevPosition + parseFloat(chromosome[1]);
            chrPositions[position] = chromosome[0];
            chrLines.push({
              drawTime: "afterDatasetsDraw",
              type: "line",
              mode: "vertical",
              scaleID: "x-axis-0",
              value: position,
              borderWidth: 1,
              borderColor: colors[materialColors[16]][300],
              borderDash: [10, 5],
              label: {
                enabled: false,
              },
            });
            prevPosition = position;
          }

          const reverseChromosomes = {};
          const chrPos = Object.keys(chrPositions);
          for (let i = 0; i < chrPos.length; i++) {
            if (i === 0) {
              reverseChromosomes["chr1"] = 0;
            } else {
              reverseChromosomes[chrPositions[chrPos[i]]] = chrPos[i - 1];
            }
          }

          if (mounted) {
            setChrReverse(reverseChromosomes);
            setChromosomePositions(chrPositions);
          }
        });
      if (mounted) {
        setChromosomeLines(chrLines);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [sex]);

  useEffect(() => {
    let mounted = true;
    if (chromosomePositions && chromosomeLines && sex && chrReverse) {
      const chrPos = Object.keys(chromosomePositions);

      try {
        fetch(getBaseURL() + files["calling"].download_address)
          .then((response) => response.text())
          .then((rawData) => {
            const labels = [];
            const dataset = [];

            rawData = rawData.split("\n");
            const columns = rawData[0].split("\t");
            const columnIndex = {};
            for (let i = 0; i < columns.length; i++) {
              columnIndex[columns[i]] = i;
            }

            rawData = rawData.slice(1);

            for (let i = 0; i < rawData.length - 1; i++) {
              const data = [];
              const row = rawData[i].split("\t");
              const chromosomeName =
                row[columnIndex["chromosome"]].split("_")[0];

              const start = parseInt(row[columnIndex["start"]]);
              let end = parseInt(row[columnIndex["end"]]);

              const position = parseInt(chrReverse[chromosomeName]);
              const log2 = parseFloat(row[columnIndex["log2"]]);
              labels.push(start + position);
              data.push({
                x: start + position,
                y: log2,
              });
              labels.push(end + position);
              data.push({
                x: end + position,
                y: log2,
              });

              let suffix = getLastChrSuffix(dataset, chromosomeName) + 1;

              const copyNumber = parseInt(row[columnIndex["cn"]]);

              dataset.push({
                label: chromosomeName + " " + suffix,
                data: data,
                borderWidth: 5,
                pointRadius: 0,
                backgroundColor: "rgba(1, 1, 1, 0.2)",
                borderColor:
                  copyNumber === 0
                    ? colors.red[400]
                    : copyNumber === 1
                    ? colors.green[400]
                    : copyNumber === 2
                    ? colors.pink[400]
                    : copyNumber === 3
                    ? colors.blue[400]
                    : copyNumber === 4
                    ? colors.indigo[400]
                    : colors.grey[400],
              });
            }
            if (mounted) {
              setSampleData({
                labels: labels,
                datasets: [...dataset],
              });
              setXRange([chrReverse["chrX"], chrPos[chrPos.length - 1]]);
            }
          });
      } catch (e) {
        safeLogger(e);
        if (mounted) {
          setSampleData({ labels: [], datasets: [] });
        }
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [chromosomePositions, chromosomeLines, sex, chrReverse]);

  const options = {
    chartArea: "cnv_male_gender",
    animation: {
      duration: 0,
    },
    annotation: {
      annotations: cnRanges,
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    title: {
      display: true,
      text: t("Chromosomes-based copy ratio"),
    },
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: function (context) {
          return t("Copy ratio (log2)") + ": " + context.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: -3,
            max: 1.5,
          },
          afterBuildTicks: function (axis, ticks) {
            const cnLineValues = [];
            for (let i = 0; i < copyNumberLines.length; i++) {
              cnLineValues.push(copyNumberLines[i].value);
            }
            return [1.5, 0, -3].concat(cnLineValues);
          },
          scaleLabel: {
            display: true,
            labelString: t("Copy ratio (log2)"),
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            minRotation: 90,
            min: xRange[0],
            max: xRange[1],
            callback: (value) => {
              return chromosomePositions[value];
            },
          },
          afterBuildTicks: function (axis, ticks) {
            return [chrReverse["chrY"], chrReverse["chrM"]];
          },
          scaleLabel: {
            display: true,
            labelString: t("Positions"),
          },
        },
      ],
    },
  };

  if (!sampleData || !chrReverse || !xRange.length) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <ChartLegend
          style={{ marginTop: "-24px" }}
          items={[
            { name: t("CN 3"), color: "rgba(13, 71, 161, 0.5)" },
            { name: t("CN 2"), color: "rgba(0, 188, 212, 0.5)" },
            { name: t("CN 1"), color: "rgba(76, 175, 80, 0.5)" },
            { name: t("CN 0"), color: "rgba(255, 64, 129, 0.5)" },
          ]}
        />
        <Line height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default ChromosomeCopyRatioLineChartMaleGender;
