import {
  Box,
  Button,
  IconButton,
  Menu,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Check, Filter1Rounded, FilterList } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: 500,
    color: "white !important",
  },
  textField: {
    width: "100%",
    color: "white !important",
    marginTop: theme.spacing(2),
  },
  typography: {
    display: "flex",
    color: "white !important",
    justifyContent: "space-between",
    alignItems: "center",
  },
  check: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.text.primary,
  },
  button: {
    padding: theme.spacing(1),
  },
}));

const FilterButton = ({
  tmpProjects,
  header,
  filterRef,
  matchedProjects,
  setFilterRef,
  setMatchedProjects,
}) => {
  const [uniqueValues, setUniqueValues] = useState();
  const [value, setValue] = useState(null);
  const classes = useStyles();
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterRef({ target: e.currentTarget, id: header.column.id });
  };

  useEffect(() => {
    const tmpUniqueValues = [];
    tmpProjects.forEach((project) => {
      if (!tmpUniqueValues.includes(project[header.column.id]))
        tmpUniqueValues.push(project[header.column.id]);
    });
    setUniqueValues(tmpUniqueValues);
  }, []);

  const handleApply = () => {
    const matches = [];

    tmpProjects.forEach((filter) => {
      if (value === null || filter[header.column.id] === value) {
        matches.push(filter);
      }
      return filter;
    });
    matchedProjects.matches = matches;
    setMatchedProjects({ ...matchedProjects });
  };

  const handleClose = () => {
    setFilterRef({ target: null, id: null });
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Box display="flex">
      <Menu
        onClose={handleClose}
        anchorEl={filterRef?.target}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        open={filterRef?.id === header.column.id}
      >
        <Box className={classes.root}>
          <Box className={classes.typography}>
            <Typography variant="h3">
              {header.column.columnDef.header()}
            </Typography>
            <IconButton
              variant="contained"
              className={classes.check}
              onClick={handleApply}
            >
              <Check />
            </IconButton>
          </Box>
          <Autocomplete
            id="autocomplete"
            onChange={handleChange}
            disableListWrap
            on
            value={value}
            className={classes.textField}
            getOptionLabel={(option) => option}
            options={uniqueValues}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Box>
      </Menu>
      <IconButton
        className={classes.button}
        size="small"
        color="white"
        onClick={handleClick}
      >
        <FilterList color="white" />
      </IconButton>
    </Box>
  );
};

export default FilterButton;
