import {
  Avatar,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { connect } from "react-redux";
import { sessionLock, sessionUnlock } from "src/api/endpoints";
import { getUserFullName } from "src/components/utils";
import { loadUser, signOut } from "src/redux/actions";

const mapStateToProps = (state) => {
  return { userObject: state.userObject, appConf: state.appConf };
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    marginBottom: "40px",
    color: colors.grey[800],
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "75px",
    height: "75px",
    marginTop: "-55px",
    "& img": {
      width: "75px",
      height: "75px",
    },
  },
}));

function mapDispatchToPropsSignOut(dispatch) {
  return {
    signOut: () => dispatch(signOut()),
    loadUser: () => dispatch(loadUser()),
  };
}

const AutoLogin = ({ userObject }) => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const classes = useStyles();
  const onIdle = () => {
    if (!open) {
      sessionLock(() => {});
      setOpen(!open);
      setPassword("");
    }
  };

  useEffect(() => {
    sessionUnlock({}, (_data, status) => {
      if ([403, 500, 404].includes(status)) {
      } else {
      }
    });
  }, []);

  const { t } = useTranslation();

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
    element: document,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
  });
  const signInCallback = (data, status) => {
    if (status === 200 || status === 403) {
      setOpen(false);
    } else {
      // setErrorMessage(errorMessages[data.message]);
      //   setErrorMessage(errorMessages[status]);
    }
  };
  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      username: userObject.username,
      password: password,
    };
    sessionUnlock(data, signInCallback);
  };
  return (
    <div>
      <Dialog
        open={open && window.location.pathname !== "projects/create"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Re Login Header")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Paper
              className={classes.paper}
              style={{ color: colors.grey[700] }}
            >
              <Avatar className={classes.avatar} src={userObject.avatar} />
              <Typography component="h1" variant="h5">
                {getUserFullName(userObject)}
              </Typography>
              <Typography variant="subtitle1">{userObject.email}</Typography>
              <Typography
                style={{ color: colors.grey[500], textAlign: "center" }}
              >
                {t("Re Login")}
              </Typography>
            </Paper>
          </DialogContentText>
          <form onSubmit={handleLogin}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              onChange={({ target }) => {
                setPassword(target.value);
              }}
              label={t("Confirm password")}
              type="password"
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary">
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToPropsSignOut)(AutoLogin);
