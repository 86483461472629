import {
  Box,
  Button,
  CircularProgress,
  colors,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  apiGetOrganizationDetails,
  apiTopupCredit,
  apiTransactions,
} from "src/api/endpoints";
import Page from "src/components/Page";
import _ from "lodash";
import { Add, HourglassEmpty, OpenInNew, TrendingUp, Repeat, CardGiftcardSharp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { userHasPermission } from "src/components/utils";
import { useSnackbar } from "notistack";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
  },
  tableHeader: {
    fontWeight: 700,
    fontSize: theme.spacing(2),
  },
  link: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    textDecoration: "underline",
    color: `${theme.palette.primary.main} !important`,
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px 0`,
    gap: theme.spacing(1),
  },
}));

function ConnectedTransactions({ userObject }) {
  const [transactions, setTransactions] = useState([]);
  const [organization, setOrganization] = useState({});
  const [credit, setCredit] = useState({});
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    apiTransactions(null, null, (data, status) => {
      if (status === 200) {
        setTransactions([...data]);
      }
    });
    apiGetOrganizationDetails(
      localStorage.getItem("activeOrganizationId"),
      (data, status) => {
        if (status === 200) {
          setOrganization({ ...data });
        }
      }
    );
  }, []);

  const handleTopupCredit = () => {
    apiTopupCredit(credit, (data, status) => {
      if (status === 201) {
        enqueueSnackbar(
          t("Successfuly added credits for {{organization}}", {
            organization: organization.name,
          }),
          {
            variant: "success",
          }
        );

        setTransactions([
          {
            organization: organization.id,
            project_id: null,
            project_name: null,
            credit: credit.credits,
            kind: "top-up",
            blocked_at: moment().format("DD/MM/YYYY hh:mm:ss"),
            settled_at: moment().format("DD/MM/YYYY hh:mm:ss"),
          },
          ...transactions,
        ]);

        handleClickOpen();
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleChangeCredit = (e) => {
    const { value } = e.target;

    setCredit({ ...{ credits: value } });
  };

  if (_.isEmpty(organization) && _.isEmpty(transactions)) {
    return (
      <Box
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else
    return (
      <Page className={classes.root} title={t("Transactions")}>
        <Dialog fullWidth onClose={handleClickOpen} open={open}>
          <DialogTitle>
            <Typography variant="h3">
              {t("Credit top up for {{organization}}", {
                organization: organization.name,
              })}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box className={classes.dialogContent}>
              <TextField
                type="number"
                onChange={handleChangeCredit}
                label={t("Credit Amount")}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              color="secondary"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleTopupCredit}
              variant="contained"
              color="primary"
            >
              {t("Top up")}
            </Button>
          </DialogActions>
        </Dialog>
        <Container>
          <Box className={classes.header}>
            <Typography variant="h2">{t("Transactions")}</Typography>
            {userHasPermission(userObject.permissions, "credit_topup") && (
              <Button
                onClick={handleClickOpen}
                color="primary"
                variant="contained"
                startIcon={<Add />}
              >
                {t("Add credits")}
              </Button>
            )}
          </Box>
          <Box>
            <Table>
              <TableHead color="primary">
                <TableRow>
                  <TableCell className={classes.tableHeader}>
                    {t("Organization")}
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    {t("Project")}
                  </TableCell>
                  {/* <TableCell className={classes.tableHeader}>
                    {t("Blocked At")}
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    {t("Settled At")}
                  </TableCell> */}
                  <TableCell className={classes.tableHeader}>
                    {t("Kind")}
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    {t("Credit")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow>
                    <TableCell>
                      {transaction.organization === organization.id
                        ? organization.name
                        : transaction.organization}
                    </TableCell>
                    <TableCell>
                      {transaction.project_name && (
                        <Link
                          className={classes.link}
                          to={`/projects/${transaction.project_id}`}
                        >
                          <Typography>{transaction.project_name}</Typography>
                          <OpenInNew fontSize="small" color="primary" />
                        </Link>
                      )}
                    </TableCell>
                    {/* <TableCell>{transaction.blocked_at}</TableCell>
                    <TableCell>{transaction.settled_at}</TableCell> */}
                    <TableCell>
                      {transaction.kind === "top-up" ? (
                        <Tooltip title={t("Top Up")}>
                          <TrendingUp
                            fontSize="medium"
                            style={{ color: "#339900" }}
                          />
                        </Tooltip>
                      ) : transaction.kind === "provision" ? (
                        <Tooltip title={t("Prevision")}>
                          <HourglassEmpty
                            fontSize="medium"
                            style={{
                              transform: "rotate(180deg)",
                              color: "#ffcc00",
                            }}
                          />
                        </Tooltip>
                      ) : transaction.kind === "spend" ? (
                        <Tooltip title={t("Spend")}>
                          <TrendingUp
                            fontSize="medium"
                            color="error"
                            style={{ transform: "rotate(180deg)" }}
                          />
                        </Tooltip>
                      ) : transaction.kind === "refund" ? (
                        <Tooltip title={t("Refund")}>
                        <Repeat 
                          fontSize="medium"
                        />
                        </Tooltip>
                      ) : transaction.kind === "gift" ? (
                        <Tooltip title={t("Gift")}>
                        <CardGiftcardSharp 
                          fontSize="medium"
                          style={{ color: "#339900" }}
                        />
                        </Tooltip>
                      ) : (
                        "fff"
                      )}
                    </TableCell>
                    <TableCell>{transaction.credit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Container>
      </Page>
    );
}

const mapStateToProps = (state) => ({
  userObject: state.userObject,
});

const Transactions = connect(mapStateToProps)(ConnectedTransactions);

export default Transactions;
