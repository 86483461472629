import { Box, TextField, makeStyles } from "@material-ui/core";
import React from "react";
import { pageSizes } from "../pages/reports/utils/helpers";
import { Pagination as MuiPagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  pageSize: {
    minWidth: 100,
  },
  pagination: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: 4,
  },
}));

function Pagination({
  projectDataCount,
  pageSize,
  setLoading,
  setPageSize,
  page,
  setPage,
}) {
  const classes = useStyles();

  const handlePageSize = (e) => {
    if (pageSize !== Number(e.target.value - 1)) {
      if (Number(e.target.value) * page >= projectDataCount) setPage(0);
      setLoading(true);
      setPageSize(Number(e.target.value));
    }
  };

  const handlePageChange = (e, value) => {
    if (page !== Number(value - 1)) {
      setLoading(true);
      setPage(Number(value - 1));
    }
  };

  return (
    <Box display="flex" justifyContent="end" mr={4}>
      {
        <Box className={classes.pagination}>
          <TextField
            id="outlined-select-currency-native"
            select
            onChange={handlePageSize}
            className={classes.pageSize}
            defaultValue={pageSize}
            SelectProps={{
              native: true,
            }}
            // helperText={t("fasfasfasfas")}
          >
            {pageSizes.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>
          <MuiPagination
            count={Math.floor(projectDataCount / pageSize) + 1}
            onChange={handlePageChange}
            page={page + 1}
          />
        </Box>
      }
    </Box>
  );
}

export default Pagination;
