import theme from "src/theme";

const { makeStyles, colors, alpha } = require("@material-ui/core");

export const tableConfigStyle = makeStyles((themes) => ({
  root: {
    maxHeight: "80vh",
  },
  title: {
    fontSize: 14,
  },
  menu: {
    "& .MuiMenu-paper": {
      maxWidth: "400px",
    },
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  mainTableRow: {
    "& *": {
      color: `${themes.palette.tableLayout.text} !important`,
      borderColor: `${themes.palette.tableLayout.text} !important`,
    },
    background: `${
      themes.palette.type === "dark"
        ? alpha(themes.palette.primary.main, 0.9)
        : "rgba(232, 232, 232,0.8)"
    } !important`,
  },
  draggable: {
    "&:hover": {
      color: colors.grey[700],
    },
    color: colors.grey[500],
    width: "40px",
    padding: 0,
    textAlign: "center",
  },
  checkboxCell: {
    padding: 0,
  },
  tableHead: {
    "& .MuiTableCell-root": {
      fontSize: "1rem",
    },
  },
  tableRow: {
    color: `white !important`,
    "*": {
      color: "white",
    },
    background: theme === "dark" ? theme.palette.background.paper : "",
  },
  tableCell: {
    borderColor: colors.grey[400],
  },
  filterTableCell: {
    maxWidth: "80%",
  },
  autoCompleteInput: {
    "& > .MuiInputBase-root": {
      maxHeight: "200px",
      overflowY: "auto",
    },
    "& input ": {},
    "& fieldset": {},
  },
  filtersetButton: {
    maxHeight: "86px",
  },
  numericActiveFiltersetIcon: {
    marginLeft: theme.spacing(1),
    color: colors.grey[600],
  },
}));
