import React, { useState } from "react";
import clsx from "clsx";
import {
  Box,
  colors,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { loadUser } from "src/redux/actions";
import { connect } from "react-redux";
import { apiUpdateProfile } from "src/api";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  messageBox: {
    position: "absolute",
    zIndex: 99,
    width: "100vw",
    minHeight: "64px",
    marginTop: "64px",
    padding: theme.spacing(2),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(5),
    border: "1px solid",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      //   marginLeft: "255px",
      //   width: "calc(100vw - 255px)",
    },
  },
  closeBtn: {
    position: "absolute",
    top: 0,
    right: "16px",
  },
  closed: {
    display: "none",
  },
  information: {
    background: colors.lightBlue[200],
    borderColor: colors.lightBlue[300],
  },
  success: {
    background: colors.green[200],
    borderColor: colors.green[300],
  },
  warning: {
    background: colors.orange[200],
    borderColor: colors.orange[300],
  },
  error: {
    background: colors.red[200],
    borderColor: colors.red[300],
    "& p": {
      fontWeight: "bold",
    },
  },
}));

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => dispatch(loadUser()),
  };
}

const mapStateToProps = (state) => {
  return {
    userObject: state.userObject,
  };
};

const ConnectedSystemNotification = (props) => {
  const classes = useStyles();
  const { id, message, kind, isPersistent, loadUser, userObject } = props;
  const [closed, setClosed] = useState(false);
  const { t } = useTranslation();

  const kindIcons = {
    information: <InfoIcon />,
    success: <CheckCircleIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />,
  };

  const handleClose = () => {
    setClosed(true);
    userObject.settings.general.hideSystemNotification = id;
    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      if (status === 200) {
        loadUser(data);
      } else {
        safeLogger(status, data);
      }
    });
  };

  return (
    <Box
      className={clsx(classes.messageBox, {
        [classes.information]: kind === "information",
        [classes.success]: kind === "success",
        [classes.warning]: kind === "warning",
        [classes.error]: kind === "error",
        [classes.closed]: closed,
      })}
    >
      {!isPersistent && (
        <IconButton onClick={handleClose} className={classes.closeBtn}>
          <CloseIcon />
        </IconButton>
      )}
      <Box display="flex">
        {kindIcons[kind]}
        <Typography style={{ marginLeft: "15px" }} variant="body1">
          {t(message)}
        </Typography>
      </Box>
    </Box>
  );
};

const SystemNotification = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSystemNotification);

export default SystemNotification;
