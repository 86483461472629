import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Switch,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Save } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AutoSizer, List } from "react-virtualized";
import { getBaseURL } from "src/config/api";
import { updateFilterStats } from "src/redux/actions";
import InputRow from "./inputRow";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  geneSymbols: {
    paddingTop: theme.spacing(2),
  },
}));

function HboFilter({
  reportFiles,
  intersection,
  setPheno,
  pheno,
  setIntersection,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [options, setOptions] = useState(null);
  const [value, setValue] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const deleteRow = (rowValue) => {
    setPheno((prev) => {
      return [...prev.filter((item) => item !== rowValue)];
    });
    setOptions((prev) => {
      prev.push(rowValue);
      return [...prev];
    });
  };

  const addPheno = () => {
    if (value) {
      pheno.push(value);
      options.splice(options.indexOf(value), 1);
      setOptions([...options]);
      setValue(null);
      setPheno([...pheno]);
    } else {
      enqueueSnackbar(t("Please select a HPO term"), {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetch(
      reportFiles && reportFiles["hpo_pheno"]
        ? getBaseURL() + reportFiles["hpo_pheno"].download_address
        : "/hpo_pheno.tsv"
    )
      .then((response) => response.text())
      .then((res) => {
        let data = [];
        data = res.split("\n");
        let header = data[0].split("\t");
        data.shift();
        let newData = data.map((hpo) => {
          let tmpValue = {};
          hpo.split("\t").forEach((row, index) => {
            tmpValue = {
              ...tmpValue,
              [header[index]]: header[index].includes("symbol")
                ? row
                  ? row?.replaceAll(" ", "").split(",")
                  : []
                : row,
            };
          });
          return tmpValue;
        });
        setOptions(newData);
      });
  }, []);

  if (!options) {
    return (
      <Box display="flex" ml={1} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="row" width="100%">
          <Autocomplete
            autoComplete
            autoHighlight
            freeSolo
            fullWidth
            options={options}
            className={classes.textField}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            ListboxComponent={ListboxComponent}
            getOptionLabel={(option) =>
              option.hpo_label + ` (${option.hpo_id})`
            }
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                label={t("Select HPO")}
              />
            )}
            variant="outlined"
          />
          <Button variant="contained" onClick={addPheno}>
            {t("Add")}
          </Button>
        </Box>
        {pheno.map((item, index) => (
          <InputRow deleteRow={deleteRow} key={index} value={item} />
        ))}
        {intersection !== undefined && (
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            marginTop={3}
            gridGap={20}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>{t("Combination")}</Box>
              <Box>
                <Switch
                  checked={intersection}
                  onChange={() => setIntersection(!intersection)}
                />
              </Box>
              <Box>{t("Intersection")}</Box>
            </Box>
          </Box>
        )}
        <Divider />
        <Box className={classes.geneSymbols}>
          {t("Gene symbols")}:{" "}
          {pheno.map((val, index) => (
            <>
              {index !== 0 && ", "}
              {val.hugo_symbol.join(", ")}
            </>
          ))}
        </Box>
      </Container>
    );
  }
}

export const ListboxComponent = function ListboxComponent(props) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 45;
  return (
    <div {...other}>
      <List
        height={600}
        width={1800}
        rowHeight={itemSize}
        overscanCount={5}
        rowCount={itemCount}
        rowRenderer={(props) => {
          return React.cloneElement(children[props.index], {
            style: {
              ...props.style,
              overflow: "hidden",
            },
          });
        }}
        role={role}
      />
    </div>
  );
};

export default HboFilter;
