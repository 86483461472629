import React from "react";
import Draggable from "react-draggable";

function DraggableHeader({
  visibleCols,
  headerIndex,
  fullWidth,
  setVisibleCols,
}) {
  return (
    <Draggable
      axis="x"
      onDrag={(event, { deltaX }) => {
        const prevWidths =
          visibleCols[headerIndex].representation.options.width > 100
            ? visibleCols[headerIndex].representation.options.width
            : 100;
        const percentDelta = (deltaX / fullWidth) * 3000;
        visibleCols[headerIndex].representation.options.width =
          prevWidths + percentDelta;
        setVisibleCols([...visibleCols]);
      }}
      position={{ x: 0 }}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "e-resize",
        }}
      >
        <span>⋮</span>
      </span>
    </Draggable>
  );
}

export default DraggableHeader;
