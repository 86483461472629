// const morbidGenes = [
//   "FANCL",
//   "WDR62",
//   "OPN1SW",
//   "TACSTD2",
//   "PCARE",
//   "SERPINB7",
//   "SLC30A2",
//   "RSPH1",
//   "PICALM",
//   "CETP",
//   "SLC25A46",
//   "CFAP53",
//   "AMPD3",
//   "NODAL",
//   "PERCC1",
//   "ANXA11",
//   "FANCM",
//   "EPCAM",
//   "NDUFB9",
//   "GNAI3",
//   "CLPP",
//   "RAB33B",
//   "KCNK3",
//   "ALPK3",
//   "SLC25A19",
//   "POMK",
//   "NPM1",
//   "DLAT",
//   "MSN",
//   "ZDHHC9",
//   "CHRNA3",
//   "ACP4",
//   "MYO9A",
//   "RETREG1",
//   "RRAS2",
//   "AKT1",
//   "TBX3",
//   "IFT81",
//   "ABCA5",
//   "RP1",
//   "DHX16",
//   "SLC52A2",
//   "EIF2B3",
//   "NCF4",
//   "LPIN1",
//   "ZNF469",
//   "RP2",
//   "CARD9",
//   "RPS27",
//   "SLC22A12",
//   "STAG2",
//   "IL36RN",
//   "PIGL",
//   "CXorf56",
//   "LHB",
//   "TMC6",
//   "COL9A2",
//   "CRTC1",
//   "IGSF1",
//   "CEL",
//   "BDP1",
//   "TYR",
//   "TBX21",
//   "C12orf4",
//   "FGD1",
//   "PMVK",
//   "WDPCP",
//   "ABL1",
//   "POMT2",
//   "NF2",
//   "ADAMTS10",
//   "FANCB",
//   "GUCA1A",
//   "VCP",
//   "FGF16",
//   "USP27X",
//   "ALB",
//   "HPS5",
//   "NDP",
//   "TCHH",
//   "MOCS2",
//   "NUDT15",
//   "TRIM71",
//   "SNX14",
//   "MIF",
//   "SLC35A2",
//   "AKAP9",
//   "STAG1",
//   "GYPC",
//   "H1-4",
//   "ALDH1A3",
//   "RARA",
//   "DMXL2",
//   "TSEN15",
//   "SMAD6",
//   "RAB7A",
//   "EYA4",
//   "SPATA5",
//   "XPA",
//   "ATAD1",
//   "BRAF",
//   "IRF6",
//   "ALDH7A1",
//   "PITX2",
//   "RSPH3",
//   "NT5E",
//   "EMG1",
//   "ALDOB",
//   "SPTAN1",
//   "EMC1",
//   "PYGL",
//   "ARMC4",
//   "GRIK2",
//   "UVSSA",
//   "B4GAT1",
//   "TECTA",
//   "CDK13",
//   "ACSM3",
//   "TRMT5",
//   "DSC2",
//   "PIGC",
//   "MRPS28",
//   "PPP1CB",
//   "NHLRC1",
//   "TGFB1",
//   "IRF2BPL",
//   "HLA-DRB1",
//   "FOXP1",
//   "SGCB",
//   "CR1",
//   "ARMS2",
//   "TUBA4A",
//   "KCNK9",
//   "PPCS",
//   "HR",
//   "ETV6",
//   "DCX",
//   "GPHN",
//   "NDUFS7",
//   "ADGRG1",
//   "ALDH18A1",
//   "RNF43",
//   "AMMECR1",
//   "CAMK2G",
//   "ATP2B3",
//   "TRPC6",
//   "CD320",
//   "POMGNT2",
//   "CIC",
//   "ADAM10",
//   "EXOSC3",
//   "MTMR2",
//   "GATAD1",
//   "FYB1",
//   "SLC34A3",
//   "INPPL1",
//   "EXOC6B",
//   "CSTB",
//   "DNAJC12",
//   "NDUFAF4",
//   "SLC9A7",
//   "LAMC3",
//   "REPS1",
//   "PEX10",
//   "MED12",
//   "LRP5",
//   "NEK2",
//   "ARHGDIA",
//   "VIM",
//   "NTN1",
//   "ACO2",
//   "NKX2-5",
//   "TBCK",
//   "KRT86",
//   "GIPC3",
//   "RNASEH1",
//   "CRAT",
//   "FRMD7",
//   "ABCG2",
//   "LMBR1",
//   "GLRX5",
//   "ANOS1",
//   "DIAPH1",
//   "DNMBP",
//   "IRS4",
//   "GREM2",
//   "IL17RC",
//   "CHKB",
//   "SPRY4",
//   "THRB",
//   "HNRNPDL",
//   "IMPAD1",
//   "ATP2B2",
//   "PDP1",
//   "SNRPE",
//   "ST3GAL5",
//   "TRAK1",
//   "TAPBP",
//   "ENAM",
//   "C19orf12",
//   "RLBP1",
//   "ALOX12B",
//   "PIGT",
//   "CWF19L1",
//   "WDR37",
//   "CHST3",
//   "MYH8",
//   "ZC4H2",
//   "DLX5",
//   "HSD17B10",
//   "MYH2",
//   "MARK3",
//   "ADA",
//   "HS6ST1",
//   "ALG12",
//   "B2M",
//   "AMPD1",
//   "RPL5",
//   "GABRA2",
//   "DSE",
//   "ZFHX3",
//   "SLC34A2",
//   "TNXB",
//   "STAC3",
//   "MID2",
//   "DNM1L",
//   "LGALS2",
//   "TWNK",
//   "ANKRD11",
//   "ADAMTSL4",
//   "CCT5",
//   "PCCA",
//   "FA2H",
//   "IFT140",
//   "ELP1",
//   "MYBPC3",
//   "RBP4",
//   "NR3C2",
//   "RPS15A",
//   "ITPR1",
//   "CES1",
//   "TLR5",
//   "VAPB",
//   "LRIT3",
//   "NIPAL4",
//   "IL17F",
//   "CCN6",
//   "KCNMB1",
//   "FRAS1",
//   "FCGR2B",
//   "NDUFAF1",
//   "TNNT3",
//   "CLN8",
//   "RAI1",
//   "DDX41",
//   "CRYBA1",
//   "EDAR",
//   "CNTNAP1",
//   "PEX26",
//   "SDHD",
//   "ORAI1",
//   "SH3KBP1",
//   "TOP3A",
//   "ZBTB20",
//   "CSTA",
//   "HARS2",
//   "AP1S3",
//   "NDUFAF8",
//   "AGRN",
//   "RPS26",
//   "NBN",
//   "AHR",
//   "TP53RK",
//   "NUP93",
//   "DRD4",
//   "CTNNA1",
//   "BCO1",
//   "MMP2",
//   "CSNK2B",
//   "FUCA1",
//   "GCK",
//   "APOC2",
//   "RAPSN",
//   "PDE8B",
//   "SCARF2",
//   "TPRN",
//   "KNL1",
//   "ESR2",
//   "CDC45",
//   "GAS8",
//   "MSMB",
//   "KCNQ1",
//   "TPMT",
//   "MAFA",
//   "TMC8",
//   "TMEM199",
//   "CSRP3",
//   "SLC26A1",
//   "CCND1",
//   "KCNQ3",
//   "ADGRG6",
//   "PISD",
//   "SECISBP2",
//   "RHD",
//   "PEX16",
//   "TTC29",
//   "TIMM22",
//   "HNF1A",
//   "NPR3",
//   "COL3A1",
//   "WBP2",
//   "FUS",
//   "HMGA2",
//   "BTD",
//   "PIGQ",
//   "NXN",
//   "RPGRIP1L",
//   "ENPP1",
//   "NFU1",
//   "ZAP70",
//   "EPOR",
//   "GHRH",
//   "FCSK",
//   "ACVR1B",
//   "FZD4",
//   "SLC29A3",
//   "TAL2",
//   "DBH",
//   "CCNO",
//   "SLC2A2",
//   "DPM2",
//   "SCN9A",
//   "COX4I2",
//   "ATP7A",
//   "TET2",
//   "CBS",
//   "EXOSC9",
//   "BHLHE41",
//   "NHS",
//   "KCNQ4",
//   "COL17A1",
//   "PLVAP",
//   "RAB39B",
//   "ALG11",
//   "DNAH11",
//   "TIMM8A",
//   "HCN1",
//   "VEGFC",
//   "MMP14",
//   "CACNA1S",
//   "KCNJ11",
//   "AUTS2",
//   "PCNT",
//   "SIAE",
//   "MEIOB",
//   "MAD1L1",
//   "TRIT1",
//   "NDE1",
//   "LGR4",
//   "COQ8A",
//   "GRID2",
//   "SMAD4",
//   "ENO3",
//   "SCN2A",
//   "GLI1",
//   "MMUT",
//   "CCDC103",
//   "TMEM216",
//   "SHANK2",
//   "EMX2",
//   "GJB6",
//   "LYRM7",
//   "SFXN4",
//   "AEBP1",
//   "WDR11",
//   "ALG2",
//   "FGF8",
//   "WNT7A",
//   "CAPN3",
//   "PIGU",
//   "KCNB1",
//   "SNAI2",
//   "APOL2",
//   "ITGA2B",
//   "ACY1",
//   "GFM1",
//   "HSD3B7",
//   "ACAD9",
//   "GARS1",
//   "SRY",
//   "ELANE",
//   "ERF",
//   "RAC1",
//   "MMP3",
//   "IRS1",
//   "TKT",
//   "GRM1",
//   "CYP26C1",
//   "RPIA",
//   "RET",
//   "MEGF10",
//   "RAD54B",
//   "SLC6A19",
//   "FBLN5",
//   "ARV1",
//   "TIA1",
//   "HSPG2",
//   "TLL1",
//   "MED13",
//   "GALK1",
//   "KDF1",
//   "RNU4ATAC",
//   "MC2R",
//   "LRPPRC",
//   "MAGI2",
//   "CHSY1",
//   "DGUOK",
//   "P4HTM",
//   "RUBCN",
//   "TKFC",
//   "CYP27B1",
//   "NPPA",
//   "C2CD3",
//   "TRAPPC2",
//   "WIPI2",
//   "KARS1",
//   "CHM",
//   "MPV17",
//   "MASP1",
//   "COL1A1",
//   "NLRP1",
//   "A2M",
//   "FLRT3",
//   "COA5",
//   "CITED2",
//   "GNAT1",
//   "TTC7A",
//   "PKD1L1",
//   "PIK3C2A",
//   "SLC25A22",
//   "ANO10",
//   "PEX19",
//   "CEP41",
//   "BOLA3",
//   "PAX7",
//   "HNRNPU",
//   "CHST6",
//   "MSH3",
//   "NSMCE3",
//   "PRF1",
//   "SOST",
//   "PYCR2",
//   "EDNRA",
//   "SETD2",
//   "FXN",
//   "TSPAN7",
//   "ATR",
//   "ERMARD",
//   "MTRR",
//   "UBE3A",
//   "ERBB4",
//   "APOA5",
//   "P2RX2",
//   "GATAD2B",
//   "SIX3",
//   "BCL10",
//   "ADGRE2",
//   "KCNA4",
//   "RNF13",
//   "EIF3F",
//   "EPB41L1",
//   "ABCA12",
//   "SPATA16",
//   "VRK1",
//   "GUCA1B",
//   "KLF1",
//   "MMADHC",
//   "IRF4",
//   "NFIX",
//   "IRAK3",
//   "MC3R",
//   "DTNBP1",
//   "SCN10A",
//   "MYLK2",
//   "USF1",
//   "PPP1R3A",
//   "IL10",
//   "PRDX1",
//   "VPS33A",
//   "LAMB2",
//   "SHOX",
//   "EEF2",
//   "SMARCAD1",
//   "LTBP4",
//   "UQCC3",
//   "SGCE",
//   "APOL4",
//   "CORIN",
//   "AMT",
//   "COCH",
//   "RTN4IP1",
//   "ALG1",
//   "G6PD",
//   "SPEF2",
//   "CFAP300",
//   "TRPV3",
//   "ADAM22",
//   "THAP1",
//   "KRT74",
//   "CCDC78",
//   "BMPR1B",
//   "AVIL",
//   "TET3",
//   "HSD3B2",
//   "ESR1",
//   "TOE1",
//   "POLR1B",
//   "GFER",
//   "DDX3X",
//   "TCTN1",
//   "MYB",
//   "NOTCH2",
//   "JAK1",
//   "VCL",
//   "ALDH3A2",
//   "PITX1",
//   "IL7",
//   "NOG",
//   "GATA6",
//   "MAK",
//   "NDUFS4",
//   "LNPK",
//   "CCL3",
//   "SOX4",
//   "FAM92A",
//   "ZNF687",
//   "ARMC2",
//   "PYCR1",
//   "PROK2",
//   "SPG21",
//   "XPNPEP3",
//   "GPAA1",
//   "LIFR",
//   "DRAM2",
//   "SPTA1",
//   "OCLN",
//   "DACT1",
//   "MED25",
//   "STAMBP",
//   "PPM1K",
//   "ADD1",
//   "MYOZ2",
//   "PET100",
//   "MAP2K2",
//   "SAMD9",
//   "NLRP12",
//   "FLAD1",
//   "MKS1",
//   "PSEN1",
//   "PPP2CA",
//   "CSNK2A1",
//   "REEP6",
//   "FDXR",
//   "PITPNM3",
//   "EPB42",
//   "RUNX1",
//   "AGT",
//   "SLC6A20",
//   "SUOX",
//   "VPS13B",
//   "B3GALNT1",
//   "NTF4",
//   "MYORG",
//   "TUBG1",
//   "PLEKHM1",
//   "CYP3A5",
//   "PRPF6",
//   "RASA1",
//   "ASPRV1",
//   "QDPR",
//   "COX10",
//   "REEP1",
//   "COG5",
//   "TRPM4",
//   "TUBB6",
//   "GJB1",
//   "NAXE",
//   "JAK3",
//   "TBXA2R",
//   "MTO1",
//   "SNRNP200",
//   "LEP",
//   "GNAS",
//   "ILDR1",
//   "NDUFAF3",
//   "SMARCA4",
//   "SMAD9",
//   "GDF3",
//   "MEFV",
//   "MAX",
//   "PMS2",
//   "MAT1A",
//   "ERLIN2",
//   "EXTL3",
//   "FIGLA",
//   "TUBA1A",
//   "ACKR1",
//   "UQCRB",
//   "RNASEH2A",
//   "CALM3",
//   "SALL1",
//   "TEAD1",
//   "CARD14",
//   "XYLT2",
//   "SLC2A10",
//   "MSL3",
//   "TGFB3",
//   "ATP6V0A2",
//   "SEMA7A",
//   "GM2A",
//   "PAM16",
//   "TSC1",
//   "PLCD1",
//   "MSH5",
//   "ACTN3",
//   "CEP164",
//   "MST1R",
//   "FKBP5",
//   "BMP2",
//   "INPP5K",
//   "PIGV",
//   "ANG",
//   "KRT81",
//   "HEPHL1",
//   "TCTEX1D2",
//   "GATM",
//   "GYS1",
//   "NUP155",
//   "HSD11B2",
//   "UBR1",
//   "PLA2G2A",
//   "COL6A2",
//   "FECH",
//   "AGXT",
//   "OTOF",
//   "TH",
//   "CPN1",
//   "RPL18",
//   "ANO3",
//   "SLC45A2",
//   "GTPBP2",
//   "PRIMPOL",
//   "FMO3",
//   "BCAM",
//   "TMEM138",
//   "NR3C1",
//   "EMD",
//   "CYP11A1",
//   "MYO5A",
//   "NUP107",
//   "RAG1",
//   "CTSF",
//   "CFH",
//   "AGL",
//   "SLC40A1",
//   "SEMA6B",
//   "DRD5",
//   "PMFBP1",
//   "PGM1",
//   "GATA1",
//   "GUCY1A1",
//   "RTEL1",
//   "CD79B",
//   "RAD21",
//   "DEPDC5",
//   "NDUFS8",
//   "INTS1",
//   "NGF",
//   "CCDC8",
//   "BBS9",
//   "SLC25A24",
//   "RB1CC1",
//   "FGFR1OP",
//   "PLS3",
//   "MRPL3",
//   "PROC",
//   "CYP11B1",
//   "IQCE",
//   "DKC1",
//   "RNF113A",
//   "AGRP",
//   "POF1B",
//   "SPTLC2",
//   "NEUROD1",
//   "ESPN",
//   "KDM6B",
//   "PRKCG",
//   "ACOX2",
//   "SDHAF1",
//   "CDC42",
//   "LHX4",
//   "NIN",
//   "FAT2",
//   "OSTM1",
//   "BRIP1",
//   "SH3BP2",
//   "PROKR2",
//   "COL10A1",
//   "GNPAT",
//   "COQ7",
//   "ATP1A3",
//   "TBX18",
//   "HOXB13",
//   "KLF6",
//   "FLVCR2",
//   "DLG4",
//   "PRSS2",
//   "ELOVL5",
//   "LMNB1",
//   "POP1",
//   "SLC25A11",
//   "TUBGCP6",
//   "KCNJ2",
//   "LYST",
//   "KRT12",
//   "ROBO3",
//   "HOMER2",
//   "KRT16",
//   "TMEM240",
//   "ACTG2",
//   "COX7B",
//   "BLOC1S6",
//   "CRX",
//   "NBEAL2",
//   "SELENON",
//   "ZNF711",
//   "PAX2",
//   "NSD1",
//   "BCL6",
//   "AK2",
//   "TRPC3",
//   "ZNF335",
//   "GNB1",
//   "MIR140",
//   "SYP",
//   "HTR2A",
//   "ASB10",
//   "ITGA6",
//   "JPH2",
//   "ITPR2",
//   "CYP2D6",
//   "CTSC",
//   "SP110",
//   "DNAL4",
//   "DOCK8",
//   "TTC21B",
//   "GP6",
//   "BCAP31",
//   "SIX6",
//   "HMGB3",
//   "WDR35",
//   "ALX4",
//   "TOR1A",
//   "SLC25A13",
//   "NTHL1",
//   "NT5C2",
//   "AGPS",
//   "LARS1",
//   "CC2D2A",
//   "RAB11B",
//   "PQBP1",
//   "PPP1R12A",
//   "SNX10",
//   "C1R",
//   "SLC9A9",
//   "CASP10",
//   "IDH3B",
//   "CPAMD8",
//   "ESCO2",
//   "IFNAR2",
//   "PTCHD1",
//   "RELT",
//   "CD19",
//   "SLC22A4",
//   "FLNB",
//   "ADH1C",
//   "GUSB",
//   "ECEL1",
//   "IL1RAPL1",
//   "AGBL1",
//   "B4GALT1",
//   "QRICH2",
//   "TERT",
//   "RNASET2",
//   "IL21",
//   "BVES",
//   "SSX1",
//   "ZFPM2",
//   "AGTPBP1",
//   "CEP83",
//   "PGAP1",
//   "ANLN",
//   "CNGA1",
//   "OTOA",
//   "HRG",
//   "UBTF",
//   "ARID2",
//   "ATP5F1A",
//   "CPS1",
//   "CEP152",
//   "OTX2",
//   "SLC12A3",
//   "FBXO11",
//   "MOCOS",
//   "CXCR4",
//   "ACSL6",
//   "PTDSS1",
//   "GABRA5",
//   "CDC14A",
//   "PEPD",
//   "CRPPA",
//   "TCF3",
//   "ATXN10",
//   "IL13",
//   "GLI3",
//   "TNPO3",
//   "MRPL44",
//   "MKRN3",
//   "NPSR1",
//   "FASLG",
//   "TFG",
//   "DLG3",
//   "SRD5A3",
//   "AMPD2",
//   "ZNHIT3",
//   "FOXN1",
//   "VANGL1",
//   "CDH3",
//   "C12orf65",
//   "NCAPD3",
//   "ESRRB",
//   "KIAA0825",
//   "PIP5K1C",
//   "NDUFB8",
//   "MIR17HG",
//   "CLDN14",
//   "AIFM1",
//   "KRT6B",
//   "ZNF341",
//   "DAZL",
//   "GRAP",
//   "SNTA1",
//   "ALG6",
//   "MRPS14",
//   "OPLAH",
//   "WASHC5",
//   "HNRNPH2",
//   "AAGAB",
//   "PPARG",
//   "IDH1",
//   "TCL1B",
//   "IFT122",
//   "TYRP1",
//   "ROGDI",
//   "MAP3K1",
//   "PNPT1",
//   "GCLC",
//   "JUP",
//   "STS",
//   "GP1BA",
//   "TFAP2B",
//   "MPZL2",
//   "CCND2",
//   "SH3TC2",
//   "HPD",
//   "DUOX2",
//   "RP9",
//   "GPR179",
//   "UCP2",
//   "CTCF",
//   "KLHDC8B",
//   "TAT",
//   "MOCS1",
//   "STN1",
//   "CD40",
//   "MYT1L",
//   "DHCR24",
//   "DPM1",
//   "OPN1LW",
//   "SPRTN",
//   "ERLIN1",
//   "KIF2A",
//   "CIB1",
//   "RP1L1",
//   "CEP250",
//   "GLIS3",
//   "PDHB",
//   "PRMT7",
//   "LAMB1",
//   "EDNRB",
//   "SLC9A3",
//   "AP4S1",
//   "PTHLH",
//   "PDGFRB",
//   "GJA3",
//   "OSGEP",
//   "ABCC1",
//   "SMAD3",
//   "C6",
//   "LRRC6",
//   "MAST1",
//   "XPR1",
//   "PYGM",
//   "ATG5",
//   "SNORD118",
//   "GATC",
//   "MYOT",
//   "NYX",
//   "CLRN1",
//   "RARS1",
//   "LFNG",
//   "CYP17A1",
//   "CDAN1",
//   "TK2",
//   "DUSP6",
//   "CHRND",
//   "TBX15",
//   "ZMIZ1",
//   "RCBTB1",
//   "SLC30A10",
//   "TPO",
//   "STING1",
//   "RUNX2",
//   "DHH",
//   "HNRNPA2B1",
//   "ANKLE2",
//   "LAMA2",
//   "KIF22",
//   "CRYBB2",
//   "PTH1R",
//   "MASP2",
//   "ADCY10",
//   "PIK3CA",
//   "NNT",
//   "OTOG",
//   "IREB2",
//   "NOVA2",
//   "DNMT3A",
//   "HGF",
//   "TUBA8",
//   "CAV1",
//   "NALCN",
//   "ETFA",
//   "TNFSF4",
//   "CRELD1",
//   "RTTN",
//   "HOXA2",
//   "IL4R",
//   "ASNS",
//   "NOP10",
//   "LMF1",
//   "CCNQ",
//   "NPRL2",
//   "SLC3A1",
//   "ABCD1",
//   "GYPA",
//   "CDKL5",
//   "SLC39A14",
//   "HTT",
//   "CFAP410",
//   "CYP2R1",
//   "ASIP",
//   "HTRA2",
//   "CA2",
//   "CFAP69",
//   "FTCD",
//   "CALM2",
//   "CLCN7",
//   "TSHR",
//   "OFD1",
//   "HDAC6",
//   "CUL7",
//   "SAG",
//   "IVD",
//   "SLC37A4",
//   "MED23",
//   "GULOP",
//   "FANCA",
//   "CFTR",
//   "IRGM",
//   "PLTP",
//   "GFI1B",
//   "UFSP2",
//   "CDK10",
//   "ARG1",
//   "GBE1",
//   "FKBP14",
//   "PRSS56",
//   "CHAT",
//   "RSPO4",
//   "DDX59",
//   "RMRP",
//   "MTAP",
//   "GTPBP3",
//   "NUBPL",
//   "PRDM5",
//   "EOGT",
//   "OPA1",
//   "SHH",
//   "SYNGAP1",
//   "CLMP",
//   "OPTN",
//   "COL4A1",
//   "ISCA1",
//   "TBP",
//   "SOD2",
//   "ARHGAP26",
//   "ATP11C",
//   "SDHA",
//   "RTN2",
//   "HSD17B3",
//   "MBTPS1",
//   "ACSL4",
//   "TIRAP",
//   "AURKA",
//   "TCF7L2",
//   "SERPINA1",
//   "CHMP4B",
//   "SMN2",
//   "NR4A3",
//   "ALDOA",
//   "EPHX2",
//   "PUS7",
//   "SARDH",
//   "USP9X",
//   "UCHL1",
//   "ARSL",
//   "CEP290",
//   "PCDH19",
//   "INS",
//   "C1GALT1C1",
//   "CCR5",
//   "GRIP1",
//   "CELA2A",
//   "IGFALS",
//   "CNTNAP2",
//   "NRAS",
//   "KDM6A",
//   "SCYL1",
//   "CYB561",
//   "DPY19L2",
//   "ADPRHL2",
//   "SI",
//   "KRT71",
//   "TENT5A",
//   "DHDDS",
//   "NSDHL",
//   "MDM4",
//   "BBS2",
//   "MRTFA",
//   "SYT2",
//   "LAMB3",
//   "ALG3",
//   "SCN4A",
//   "ALDH2",
//   "COX15",
//   "IGHMBP2",
//   "PSMB4",
//   "HLA-C",
//   "F13A1",
//   "CHRNA5",
//   "POMGNT1",
//   "AR",
//   "PLP1",
//   "TCOF1",
//   "C1QA",
//   "COA8",
//   "AFP",
//   "PURA",
//   "DPM3",
//   "SLC4A1",
//   "MIP",
//   "TGM3",
//   "MYH7",
//   "AGXT2",
//   "WAS",
//   "PIH1D3",
//   "ATCAY",
//   "IDUA",
//   "PRDM8",
//   "SLC25A15",
//   "KY",
//   "WDR81",
//   "ZEB1",
//   "CHRDL1",
//   "SLC35D1",
//   "HSPA9",
//   "RMND1",
//   "SPARC",
//   "TBC1D24",
//   "NHEJ1",
//   "CTPS1",
//   "CDH11",
//   "HMOX1",
//   "KRT3",
//   "PLG",
//   "CHRNG",
//   "MORC2",
//   "HTR1A",
//   "BGN",
//   "CFC1",
//   "ZIC3",
//   "SUCLG1",
//   "GEMIN4",
//   "EPHB2",
//   "LGI4",
//   "WNT4",
//   "NDUFAF6",
//   "PANX1",
//   "EIF4A3",
//   "SLC2A9",
//   "SGCG",
//   "ARPC1B",
//   "ERCC3",
//   "SYN2",
//   "GDF5",
//   "BRCA1",
//   "GPX1",
//   "NEFL",
//   "OTC",
//   "TRHR",
//   "NDUFA6",
//   "GDF9",
//   "PHF21A",
//   "SLC1A4",
//   "FREM1",
//   "MCM4",
//   "ATXN7",
//   "CRY1",
//   "MYRF",
//   "AP4M1",
//   "GPNMB",
//   "SIK3",
//   "POLH",
//   "OSBPL2",
//   "ITGA3",
//   "XRCC2",
//   "AURKC",
//   "PSTPIP1",
//   "MMP20",
//   "LMNB2",
//   "CCBE1",
//   "ACHE",
//   "CST3",
//   "GMNN",
//   "FUT1",
//   "FAM111B",
//   "ACE",
//   "LRP12",
//   "UBQLN2",
//   "ANKS6",
//   "P4HB",
//   "RBM28",
//   "PLCZ1",
//   "TGM5",
//   "BLOC1S3",
//   "SASS6",
//   "ELMO2",
//   "SLC26A3",
//   "TUBB3",
//   "KIF1C",
//   "FKRP",
//   "HJV",
//   "KIF21A",
//   "KLK1",
//   "EPS8",
//   "MARCHF6",
//   "PIGA",
//   "PON2",
//   "TNNC1",
//   "SMG9",
//   "SLC26A4",
//   "NPHP4",
//   "GK",
//   "CD40LG",
//   "PLCG2",
//   "RPS6KA3",
//   "ALG13",
//   "PDE6D",
//   "LHX3",
//   "CNOT2",
//   "PDCD10",
//   "ANGPTL3",
//   "LIMA1",
//   "PGK1",
//   "DSG2",
//   "PRPF4",
//   "TLK2",
//   "SPNS2",
//   "ALG9",
//   "TGFBR1",
//   "PARS2",
//   "FCGR3A",
//   "SERPINH1",
//   "CPT1C",
//   "MCFD2",
//   "TRAF3IP2",
//   "IMPG2",
//   "MGAT2",
//   "MEGF8",
//   "ACAN",
//   "ITK",
//   "SLC4A4",
//   "GJB4",
//   "PCSK1",
//   "TMEM107",
//   "GINS1",
//   "CACNA1G",
//   "RAD51C",
//   "CEP55",
//   "TLR2",
//   "TBX6",
//   "UBAP1",
//   "BTK",
//   "ABCD4",
//   "AGPAT2",
//   "RDH5",
//   "LGI1",
//   "HBEGF",
//   "SEC23A",
//   "AGA",
//   "KLHL10",
//   "ASCL1",
//   "LMO2",
//   "SPINT2",
//   "ATL1",
//   "TP53",
//   "AXIN2",
//   "XK",
//   "NOS3",
//   "STT3B",
//   "MUC1",
//   "SPTLC1",
//   "HSPB1",
//   "CCL2",
//   "CDH15",
//   "ZP3",
//   "ASPA",
//   "MCM5",
//   "CACNA2D4",
//   "DLC1",
//   "EFNB1",
//   "PEX5",
//   "SDHC",
//   "SLC18A2",
//   "IGF1",
//   "DNMT1",
//   "COL6A3",
//   "FLI1",
//   "DPYS",
//   "CCR2",
//   "TWIST1",
//   "GAB1",
//   "PKHD1",
//   "NLRP7",
//   "PMP22",
//   "CD4",
//   "LZTFL1",
//   "PIKFYVE",
//   "SEMA4A",
//   "MECR",
//   "COX14",
//   "SOX18",
//   "TCAP",
//   "EYS",
//   "DMGDH",
//   "BMS1",
//   "SLC4A11",
//   "FRMPD4",
//   "KIF14",
//   "GPC3",
//   "MUSK",
//   "PDCD1",
//   "MLH3",
//   "NEU1",
//   "CLCF1",
//   "NOP56",
//   "PROP1",
//   "PCDH15",
//   "ENTPD1",
//   "CHAMP1",
//   "DNAH1",
//   "RAB18",
//   "DOCK3",
//   "NR2F1",
//   "MAPKBP1",
//   "SLC30A8",
//   "CEBPA",
//   "DOK7",
//   "LMO1",
//   "RSRC1",
//   "SPAG1",
//   "SYT14",
//   "DSTYK",
//   "FMR1",
//   "GAA",
//   "WNK1",
//   "MYO6",
//   "MBTPS2",
//   "MRAP",
//   "PTPN23",
//   "KIF5C",
//   "WFS1",
//   "SUN5",
//   "IQCB1",
//   "TUBB",
//   "DNAJB11",
//   "MPZ",
//   "TBX22",
//   "TFRC",
//   "CTH",
//   "CD70",
//   "CHIC2",
//   "SOBP",
//   "SYNE4",
//   "TYK2",
//   "INF2",
//   "UROC1",
//   "VPS35",
//   "RAB23",
//   "ZNF750",
//   "FBN1",
//   "ACER3",
//   "CEP19",
//   "CDC6",
//   "FIG4",
//   "RUSC2",
//   "EHHADH",
//   "SFTPC",
//   "WHRN",
//   "KCNMA1",
//   "PCK2",
//   "CCDC40",
//   "AP2M1",
//   "COQ8B",
//   "SEPTIN12",
//   "TNNT1",
//   "KIF1A",
//   "HDC",
//   "DAG1",
//   "PDHX",
//   "UPB1",
//   "ATF6",
//   "NAA10",
//   "WDR4",
//   "AKT2",
//   "DENND5A",
//   "INTS8",
//   "KCNT1",
//   "TFAP2A",
//   "WNT10B",
//   "GFPT1",
//   "HCRT",
//   "IMPG1",
//   "F2",
//   "SEC31A",
//   "PPARA",
//   "TBX2",
//   "GATA5",
//   "GNPTAB",
//   "MYOC",
//   "NAA15",
//   "WNT2B",
//   "RPL26",
//   "F13B",
//   "CAMK2A",
//   "JAK2",
//   "WT1",
//   "CFHR3",
//   "H6PD",
//   "PUS1",
//   "TSC2",
//   "ACADS",
//   "CTNNA3",
//   "AMN",
//   "DYM",
//   "PGAP3",
//   "UNC119",
//   "SOS2",
//   "GCSH",
//   "PHEX",
//   "MRM2",
//   "FADD",
//   "TAL1",
//   "PAX9",
//   "RAF1",
//   "SMARCB1",
//   "FZD2",
//   "GDF2",
//   "PPP2R5D",
//   "FLT4",
//   "EXT2",
//   "BLVRA",
//   "NR0B2",
//   "RPE65",
//   "RPS29",
//   "AIMP1",
//   "IRS2",
//   "CHD1",
//   "SOHLH1",
//   "CTU2",
//   "RNASEL",
//   "VCAN",
//   "DCN",
//   "CDK19",
//   "NME8",
//   "IL1RN",
//   "LAGE3",
//   "GLE1",
//   "FOXI1",
//   "AP1B1",
//   "SLC25A3",
//   "NFKB1",
//   "APCS",
//   "TMLHE",
//   "NEK10",
//   "ABCC8",
//   "MMP9",
//   "SS18",
//   "DNAAF3",
//   "AXIN1",
//   "KCNE2",
//   "SEMA3A",
//   "DCDC2",
//   "MCM9",
//   "HLCS",
//   "KCNJ18",
//   "KISS1R",
//   "CANT1",
//   "STRA6",
//   "TAF6",
//   "UQCRC2",
//   "CHD3",
//   "CYB5A",
//   "ACACA",
//   "UNC93B1",
//   "NAXD",
//   "SHANK3",
//   "KDR",
//   "GRK1",
//   "ATP8B1",
//   "TBX1",
//   "LTA",
//   "LSS",
//   "GALC",
//   "PLCB3",
//   "RNF6",
//   "PKD2",
//   "GATA4",
//   "KMT2C",
//   "CERS1",
//   "WNT3",
//   "PSMB8",
//   "IQSEC1",
//   "PLA2G5",
//   "HOXC13",
//   "RPS10",
//   "PTEN",
//   "CCDC28B",
//   "JAGN1",
//   "GJC2",
//   "KLHL41",
//   "MIR204",
//   "TMEM63A",
//   "MCCC1",
//   "ACADSB",
//   "SIN3A",
//   "TTC37",
//   "RECQL4",
//   "XDH",
//   "CHI3L1",
//   "ANTXR1",
//   "TSPYL1",
//   "WASHC4",
//   "TRIP4",
//   "ATP13A2",
//   "BICD2",
//   "LRSAM1",
//   "COG4",
//   "GORAB",
//   "SNCB",
//   "EBP",
//   "SLC25A1",
//   "NPC2",
//   "PBX1",
//   "FOXE1",
//   "DYNC2H1",
//   "LRTOMT",
//   "BCKDK",
//   "HSPD1",
//   "USP9Y",
//   "PCDH12",
//   "STARD7",
//   "CDC73",
//   "GSTZ1",
//   "ARHGEF10",
//   "B9D2",
//   "SMO",
//   "GALM",
//   "TRAC",
//   "SMOC1",
//   "CRYAB",
//   "PHYKPL",
//   "ANXA5",
//   "WWOX",
//   "CLEC7A",
//   "PNPLA8",
//   "ITGB4",
//   "CDH1",
//   "FGF12",
//   "RBBP8",
//   "SELENOI",
//   "ACOX1",
//   "NDUFA11",
//   "CHEK2",
//   "CARS1",
//   "SLC30A9",
//   "COG2",
//   "MFN2",
//   "MTFMT",
//   "PTPN22",
//   "DYNC1H1",
//   "TRIM2",
//   "TIMMDC1",
//   "RBMX",
//   "SLCO1B3",
//   "PRKD1",
//   "IGFBP7",
//   "KIF11",
//   "KCNH2",
//   "LIPT1",
//   "POLR1D",
//   "ACADM",
//   "SORT1",
//   "SOX2",
//   "SALL2",
//   "SCAPER",
//   "ALOX5",
//   "SCGB3A2",
//   "WNT5A",
//   "CEP135",
//   "PDGFB",
//   "DLX4",
//   "MCM8",
//   "PRPH",
//   "TMEM127",
//   "WRN",
//   "COMT",
//   "HFE",
//   "STX16",
//   "CHP1",
//   "TEX14",
//   "IFT27",
//   "PSAT1",
//   "SMARCAL1",
//   "VPS33B",
//   "SLC12A5",
//   "HNF1B",
//   "TECPR2",
//   "NLGN1",
//   "AMELX",
//   "PTPRJ",
//   "ACTG1",
//   "FANCI",
//   "CLEC1A",
//   "SLC5A6",
//   "FUT6",
//   "NDUFB3",
//   "MIAT",
//   "ITPR3",
//   "KIR3DL1",
//   "DCPS",
//   "NKX2-6",
//   "HERC1",
//   "RAD50",
//   "GABRB2",
//   "SERPINB8",
//   "MATN3",
//   "CDHR1",
//   "ALX1",
//   "ZP2",
//   "RIT1",
//   "SDHB",
//   "GNMT",
//   "BBIP1",
//   "CACNA2D2",
//   "ZC3H14",
//   "RAC3",
//   "RERE",
//   "UROS",
//   "LTC4S",
//   "CASK",
//   "SGO1",
//   "AMER1",
//   "KCND3",
//   "MPC1",
//   "MYCN",
//   "COMP",
//   "ARID1A",
//   "B3GALNT2",
//   "SMARCA2",
//   "IFITM5",
//   "CABP4",
//   "CXCL12",
//   "KRT14",
//   "PRKCSH",
//   "CRADD",
//   "PTPRQ",
//   "FHL1",
//   "RIPK1",
//   "JPH1",
//   "KMT2E",
//   "FANCD2",
//   "OGT",
//   "GABRA1",
//   "ECM1",
//   "LPL",
//   "EEF1AKNMT",
//   "RHAG",
//   "AMTN",
//   "ASPH",
//   "PAX3",
//   "EFHC1",
//   "CSPP1",
//   "CLPX",
//   "HAAO",
//   "ZP1",
//   "CTRC",
//   "NOBOX",
//   "VDR",
//   "TPRKB",
//   "DOLK",
//   "LCAT",
//   "SLC1A3",
//   "ALAS2",
//   "CX3CR1",
//   "DDRGK1",
//   "LMAN1",
//   "NDUFS6",
//   "XRCC3",
//   "FZD6",
//   "IL17RA",
//   "ABHD12",
//   "FAM20C",
//   "HAGH",
//   "SLC5A7",
//   "C4B",
//   "GFM2",
//   "IGF2BP2",
//   "C1QTNF5",
//   "PAX4",
//   "BACH2",
//   "CYP4F22",
//   "SCNN1A",
//   "COL4A6",
//   "KL",
//   "KYNU",
//   "TEK",
//   "PAK3",
//   "ARNT2",
//   "APCDD1",
//   "SCARB1",
//   "GYG1",
//   "TANGO2",
//   "CDH2",
//   "CRYAA",
//   "GABRG2",
//   "SULT2B1",
//   "PRPF3",
//   "SLC28A1",
//   "CYC1",
//   "MED12L",
//   "TRH",
//   "AHI1",
//   "VNN1",
//   "FUT2",
//   "PAPSS2",
//   "GCH1",
//   "STK11",
//   "PSMA6",
//   "ACAT1",
//   "TMPRSS3",
//   "SNAP29",
//   "KRT18",
//   "TLR3",
//   "TPH2",
//   "ABCC9",
//   "DNASE1",
//   "TAF13",
//   "BCL7A",
//   "CYP2C8",
//   "HOXA13",
//   "GH1",
//   "ADCY5",
//   "IFNGR2",
//   "ATP2C1",
//   "CTSD",
//   "TBC1D8B",
//   "C2",
//   "KCNJ13",
//   "ACTC1",
//   "MN1",
//   "COL2A1",
//   "MAPKAPK3",
//   "HCFC1",
//   "POLD1",
//   "EFEMP2",
//   "TMC1",
//   "LDLRAP1",
//   "ITM2B",
//   "ARCN1",
//   "C9",
//   "PGM3",
//   "ABCC6",
//   "HINT1",
//   "TAZ",
//   "UMPS",
//   "PDGFRA",
//   "L1CAM",
//   "MAF",
//   "PRUNE1",
//   "NFKBIA",
//   "ANO6",
//   "ARMC9",
//   "CNBP",
//   "PJVK",
//   "CD44",
//   "GJA8",
//   "CYBB",
//   "UQCRQ",
//   "FSHB",
//   "KCNJ1",
//   "CLIC5",
//   "POU3F3",
//   "SERPINE1",
//   "MAGEL2",
//   "MC4R",
//   "OCRL",
//   "GFAP",
//   "GPIHBP1",
//   "SLC6A17",
//   "EPG5",
//   "BCKDHB",
//   "WAC",
//   "TALDO1",
//   "PHF8",
//   "ABCG8",
//   "POLG2",
//   "LRRC8A",
//   "NMNAT1",
//   "VPS37A",
//   "FFAR4",
//   "DDHD2",
//   "ZCCHC8",
//   "RS1",
//   "RNASEH2B",
//   "NKX6-2",
//   "IL11RA",
//   "HUWE1",
//   "DNM2",
//   "COX6A1",
//   "HLA-A",
//   "PHKA1",
//   "SLC1A1",
//   "ABCA1",
//   "COL1A2",
//   "SMS",
//   "GSS",
//   "CTC1",
//   "PCSK9",
//   "PRX",
//   "CNGA3",
//   "KRT8",
//   "ADAM17",
//   "ATP6V1A",
//   "STAT1",
//   "SACS",
//   "CA8",
//   "NHP2",
//   "HOGA1",
//   "VAMP1",
//   "SLC35A3",
//   "EYA1",
//   "CYP2C9",
//   "FOXL2",
//   "HOXA1",
//   "TNNT2",
//   "RETN",
//   "HBB",
//   "LIPA",
//   "TRIP11",
//   "MRPS23",
//   "BAX",
//   "YARS1",
//   "RYR2",
//   "TBK1",
//   "SCARB2",
//   "DTNA",
//   "HPS3",
//   "SMN1",
//   "PON1",
//   "MSH6",
//   "DYSF",
//   "PIK3CD",
//   "MEF2A",
//   "DST",
//   "RSPO1",
//   "RRM2B",
//   "ALAD",
//   "ODAPH",
//   "SCN1A",
//   "FAM111A",
//   "SLC39A5",
//   "FOXO1",
//   "NCF1",
//   "VSX2",
//   "CRYGB",
//   "HIVEP2",
//   "CACNA1D",
//   "IL21R",
//   "HMGCR",
//   "TGM6",
//   "DCAF17",
//   "DSG1",
//   "CDK6",
//   "SCO1",
//   "COL13A1",
//   "ODC1",
//   "HOXA11",
//   "NTRK1",
//   "ABCB7",
//   "CLCN5",
//   "AFF2",
//   "SMARCE1",
//   "GRHPR",
//   "GJA1",
//   "STAT2",
//   "EFEMP1",
//   "CPLANE1",
//   "ANKRD26",
//   "FGFR1",
//   "COA3",
//   "ICAM1",
//   "NT5C3A",
//   "LIPE",
//   "DHX37",
//   "LAMA1",
//   "PRRT2",
//   "APOC3",
//   "SSR4",
//   "HGSNAT",
//   "CARS2",
//   "RGR",
//   "MMP19",
//   "RAD54L",
//   "MTHFR",
//   "STOX1",
//   "DZIP1L",
//   "WDR45",
//   "CLTC",
//   "VPS13A",
//   "EPM2A",
//   "SLC24A1",
//   "SYN1",
//   "TBL1Y",
//   "ANTXR2",
//   "PRSS1",
//   "MYD88",
//   "IRX5",
//   "DEAF1",
//   "DES",
//   "DAB1",
//   "TRAPPC4",
//   "FAM83H",
//   "IGF2R",
//   "TMEM165",
//   "PRPF31",
//   "MCC",
//   "PIEZO1",
//   "TNFRSF11A",
//   "COX8A",
//   "FLT3",
//   "DGAT1",
//   "CIITA",
//   "GMPPB",
//   "COL11A1",
//   "HSD17B4",
//   "IL7R",
//   "ABCC11",
//   "UGT1A1",
//   "IGF2",
//   "C12orf57",
//   "COL25A1",
//   "PDLIM4",
//   "SCN1B",
//   "OLR1",
//   "PTPRF",
//   "CALR",
//   "GRIA4",
//   "EIF2S3",
//   "IGHG2",
//   "DPP6",
//   "SLC26A8",
//   "MMP13",
//   "CDK8",
//   "BSND",
//   "TMEM126B",
//   "ATAD3A",
//   "CFB",
//   "GRIN1",
//   "MICOS13",
//   "PDZD7",
//   "TMPRSS15",
//   "DPAGT1",
//   "FCGR1A",
//   "MC1R",
//   "ARSA",
//   "AIMP2",
//   "CD3D",
//   "IFT57",
//   "MEN1",
//   "SPOP",
//   "PRKG1",
//   "FAT4",
//   "MAPT",
//   "PIGH",
//   "COLEC10",
//   "TYROBP",
//   "CSGALNACT1",
//   "GNPTG",
//   "SLC25A38",
//   "SRGAP1",
//   "FAAH",
//   "KCTD17",
//   "MAPRE2",
//   "ATN1",
//   "LRP2",
//   "NCF2",
//   "EEF1A2",
//   "DVL3",
//   "SDCCAG8",
//   "NPR2",
//   "HADHA",
//   "EIF2B4",
//   "CUX2",
//   "ASAH1",
//   "HK1",
//   "BUB1B",
//   "PDE1C",
//   "RAD51D",
//   "CYP27A1",
//   "RHOA",
//   "CYP2C19",
//   "TGIF1",
//   "TBX19",
//   "SAMD12",
//   "MRPS7",
//   "CRYBA2",
//   "MCIDAS",
//   "ARHGEF1",
//   "MAP1B",
//   "PRKRA",
//   "SLC17A5",
//   "SMCHD1",
//   "TNFRSF13B",
//   "ZMYND10",
//   "AAAS",
//   "NUP205",
//   "HPCA",
//   "FBXL3",
//   "HGD",
//   "COL12A1",
//   "ZBTB11",
//   "MTHFS",
//   "RIN2",
//   "HPS4",
//   "PABPN1",
//   "SCN2B",
//   "PRPH2",
//   "DHODH",
//   "MFSD8",
//   "RPS14",
//   "MANBA",
//   "TP63",
//   "C7",
//   "SDR9C7",
//   "SLC45A1",
//   "ELMOD3",
//   "TECR",
//   "RAX2",
//   "HRAS",
//   "OPA3",
//   "SETD1A",
//   "DIABLO",
//   "RPS28",
//   "PLAG1",
//   "RBCK1",
//   "MYO7A",
//   "PEX6",
//   "PPP2R1A",
//   "ENG",
//   "PIGY",
//   "GAMT",
//   "DNAAF4",
//   "CHD4",
//   "NPHP1",
//   "SON",
//   "STXBP2",
//   "CORO1A",
//   "INPP5E",
//   "RPS24",
//   "TRAIP",
//   "ETFB",
//   "PLEC",
//   "GRHL3",
//   "COQ4",
//   "NDUFS3",
//   "PRKAR1A",
//   "OCA2",
//   "BCL2",
//   "LRMDA",
//   "ADAM9",
//   "PANK2",
//   "SH2D1A",
//   "ACD",
//   "VPS13C",
//   "NUP88",
//   "FANCF",
//   "NCKAP1L",
//   "USP18",
//   "CUL3",
//   "STAT3",
//   "KCNQ1OT1",
//   "RELN",
//   "MBOAT7",
//   "FCN3",
//   "SLC6A4",
//   "CHRNA2",
//   "PTCH2",
//   "MIR184",
//   "TUFM",
//   "PRKAG2",
//   "ASL",
//   "GNB4",
//   "TRMT10A",
//   "NDN",
//   "FTH1",
//   "PARN",
//   "CERT1",
//   "TFE3",
//   "YEATS2",
//   "FOXRED1",
//   "ERMAP",
//   "KDM5B",
//   "PSMB9",
//   "RIC1",
//   "BHLHA9",
//   "COL8A2",
//   "OSMR",
//   "NDUFA1",
//   "NUP214",
//   "RBM8A",
//   "FGF23",
//   "SUMF1",
//   "GDAP1",
//   "CAD",
//   "SLCO2A1",
//   "POFUT1",
//   "FOXC1",
//   "MYL2",
//   "CYB5R3",
//   "TUBB4B",
//   "TARS1",
//   "CBLIF",
//   "GLA",
//   "NDST1",
//   "BCOR",
//   "LAMC2",
//   "CACNB4",
//   "TSHB",
//   "TRIP13",
//   "DVL1",
//   "HBA2",
//   "PLCB4",
//   "FAS",
//   "DLX3",
//   "PDSS2",
//   "PIGK",
//   "AQP5",
//   "FKTN",
//   "TSEN2",
//   "CCL3L1",
//   "PHOX2B",
//   "GALNT12",
//   "CHRNE",
//   "AP3B1",
//   "HPS1",
//   "USH2A",
//   "IRAK4",
//   "FLCN",
//   "WWC1",
//   "DISC2",
//   "ABCA2",
//   "SCYL2",
//   "SIK1",
//   "MAGED2",
//   "IBA57",
//   "AGBL5",
//   "DAOA",
//   "ABCB1",
//   "IFT172",
//   "PRSS12",
//   "SYT1",
//   "EVC2",
//   "PTPN11",
//   "KIF1B",
//   "ERCC4",
//   "SGPL1",
//   "CLN5",
//   "CRLF1",
//   "PRG4",
//   "DCLRE1C",
//   "FAM149B1",
//   "CHMP2B",
//   "CDK5RAP2",
//   "CTNNA2",
//   "PLA2G6",
//   "STAT5B",
//   "INVS",
//   "TNFAIP3",
//   "WARS2",
//   "NRL",
//   "SARS1",
//   "G6PC",
//   "ACR",
//   "RPS7",
//   "MAFB",
//   "CCL11",
//   "DNAJB2",
//   "ACTN2",
//   "NEFH",
//   "SIL1",
//   "GLRB",
//   "CD79A",
//   "NRROS",
//   "EARS2",
//   "BCR",
//   "EVC",
//   "HOXB1",
//   "CIB2",
//   "ORC6",
//   "COLGALT1",
//   "ZMYND15",
//   "STAT4",
//   "FN1",
//   "PDE6H",
//   "TRPA1",
//   "CFAP43",
//   "GRM6",
//   "CD96",
//   "NEK1",
//   "SIX5",
//   "FRMD4A",
//   "PDE6G",
//   "APRT",
//   "CD2AP",
//   "FGF10",
//   "FOXF1",
//   "RPGR",
//   "PRKCA",
//   "HNMT",
//   "ATG16L1",
//   "ASXL2",
//   "EIF2B5",
//   "NLGN3",
//   "GJB2",
//   "ADAMTS17",
//   "SLC25A12",
//   "KNG1",
//   "RHOBTB2",
//   "RFXANK",
//   "TNFRSF4",
//   "KIT",
//   "COLEC11",
//   "PRKACA",
//   "IL6",
//   "LRP1",
//   "CNOT3",
//   "CYBC1",
//   "LIPC",
//   "PRDM16",
//   "CYP21A2",
//   "CSF3R",
//   "SATB2",
//   "GOT2",
//   "LMNA",
//   "SPRY2",
//   "RPSA",
//   "KANK1",
//   "KLHL7",
//   "SIX1",
//   "BARD1",
//   "SLC44A4",
//   "ALG14",
//   "MFHAS1",
//   "SOX11",
//   "FREM2",
//   "GALNT2",
//   "POR",
//   "NFIA",
//   "MTNR1B",
//   "SERPINA7",
//   "AKR1D1",
//   "SEMA3E",
//   "TBR1",
//   "CD164",
//   "CLCN1",
//   "CYCS",
//   "CRBN",
//   "TARDBP",
//   "RAB3GAP1",
//   "VHL",
//   "FAH",
//   "SSX2",
//   "XPNPEP2",
//   "LMOD3",
//   "TUBB4A",
//   "DIAPH3",
//   "KIF3B",
//   "ACSF3",
//   "KCNK4",
//   "ZFYVE27",
//   "CACNA1H",
//   "CDCA7",
//   "TASP1",
//   "WIPF1",
//   "ITGA7",
//   "ZBTB42",
//   "PDE3A",
//   "CAMTA1",
//   "LRPAP1",
//   "ECHS1",
//   "IL17RD",
//   "SLC19A2",
//   "ADNP",
//   "MYF5",
//   "TTI2",
//   "CASP14",
//   "CENPT",
//   "ELN",
//   "ADRB2",
//   "ATXN8",
//   "BCL11A",
//   "KRT10",
//   "KCNC3",
//   "GRXCR2",
//   "MLYCD",
//   "NIPA1",
//   "FGF14",
//   "ELAC2",
//   "EHBP1",
//   "FUT8",
//   "ERAL1",
//   "PLK4",
//   "ESRP1",
//   "FARS2",
//   "PPM1D",
//   "CA5A",
//   "AVP",
//   "SLC7A9",
//   "CYFIP2",
//   "SNAP25",
//   "PADI4",
//   "SDC3",
//   "MCCC2",
//   "SLC6A2",
//   "POLR2A",
//   "DHFR",
//   "KRT17",
//   "CPLX1",
//   "KLHL15",
//   "TREH",
//   "MYO3A",
//   "NFKBIL1",
//   "JAG1",
//   "TSEN34",
//   "GPD1L",
//   "KIAA0753",
//   "SERPINI1",
//   "TRIP12",
//   "CCDC151",
//   "CNNM2",
//   "INAVA",
//   "CACNA1A",
//   "FBXL4",
//   "FGFR2",
//   "KLKB1",
//   "SLC6A3",
//   "PLA2G4A",
//   "KAT6A",
//   "UGT2B17",
//   "IFNA1",
//   "APOB",
//   "CILK1",
//   "PAK1",
//   "BAG3",
//   "PEX7",
//   "JAM3",
//   "DDX11",
//   "GLUL",
//   "NEXN",
//   "RPL13",
//   "KLF11",
//   "PSAP",
//   "SLCO1B1",
//   "NADK2",
//   "PALLD",
//   "NUP62",
//   "AQP1",
//   "PMPCA",
//   "AFF4",
//   "TPP1",
//   "ACVRL1",
//   "RASGRP2",
//   "SCO2",
//   "PNPLA6",
//   "PTGER2",
//   "NEUROG3",
//   "ATXN8OS",
//   "PPP1R17",
//   "TBCE",
//   "NACC1",
//   "TMEM132E",
//   "GRIN2A",
//   "YME1L1",
//   "TMEM260",
//   "PLS1",
//   "SLC9A1",
//   "SYCP3",
//   "TELO2",
//   "KRT5",
//   "AIP",
//   "NDUFV1",
//   "BFSP1",
//   "PLN",
//   "CPSF1",
//   "ABCB11",
//   "PROS1",
//   "SLC5A1",
//   "PLAU",
//   "C15orf41",
//   "NDUFB11",
//   "TRAF3IP1",
//   "IFT52",
//   "PIEZO2",
//   "CFAP70",
//   "KCNN4",
//   "SLC17A3",
//   "PHGDH",
//   "SLC17A9",
//   "MYO15A",
//   "CCDC114",
//   "CPA6",
//   "PLAA",
//   "TONSL",
//   "SCP2",
//   "CDT1",
//   "ROR2",
//   "NAGLU",
//   "ACVR1",
//   "MFRP",
//   "GNB3",
//   "SPG11",
//   "DARS2",
//   "C4A",
//   "WDR73",
//   "CACNA1E",
//   "GNAS-AS1",
//   "AQP7",
//   "CTDP1",
//   "SLC7A14",
//   "HOXD10",
//   "COQ6",
//   "PAX5",
//   "BTNL2",
//   "SLC35C1",
//   "CRYM",
//   "MEOX1",
//   "FAN1",
//   "NRXN1",
//   "MS4A1",
//   "HSD11B1",
//   "TXN2",
//   "SOX17",
//   "DIAPH2",
//   "APC2",
//   "B3GLCT",
//   "GDF1",
//   "MSX1",
//   "CREB1",
//   "NCR3",
//   "DGKE",
//   "ADAR",
//   "KMT2D",
//   "MXI1",
//   "RNPC3",
//   "COG7",
//   "TRDN",
//   "ARL13B",
//   "EXPH5",
//   "WDR60",
//   "RALGAPA1",
//   "WRAP53",
//   "AQP2",
//   "RPL11",
//   "TBX20",
//   "MPO",
//   "RORA",
//   "RNF170",
//   "DNAH17",
//   "ARMC5",
//   "ASXL3",
//   "MED13L",
//   "MYO9B",
//   "GIGYF2",
//   "SLC26A2",
//   "HPGD",
//   "LPIN2",
//   "CD151",
//   "KCTD7",
//   "FBXO7",
//   "SLC10A2",
//   "A4GALT",
//   "DCC",
//   "EIF2AK4",
//   "ABCB6",
//   "TRPV4",
//   "VARS2",
//   "MMP1",
//   "SALL4",
//   "CYP2B6",
//   "EPO",
//   "RB1",
//   "DDC",
//   "IGF1R",
//   "VEGFA",
//   "TRIO",
//   "LAMA3",
//   "QARS1",
//   "NCAPD2",
//   "MAP3K8",
//   "VMA21",
//   "RPL35",
//   "AKT3",
//   "ZNF423",
//   "CTSK",
//   "CCDC88C",
//   "MGME1",
//   "GRHL2",
//   "TRMT10C",
//   "NOTCH1",
//   "TGFB2",
//   "RNASEH2C",
//   "CD247",
//   "COL5A1",
//   "CAPN10",
//   "CLCN4",
//   "CCL5",
//   "NSMF",
//   "MSMO1",
//   "SEC23B",
//   "ABCA7",
//   "NUP37",
//   "DNAAF1",
//   "KRT83",
//   "DYRK1A",
//   "ISCA2",
//   "THG1L",
//   "PTCH1",
//   "AP3D1",
//   "FUT3",
//   "GALT",
//   "IL2RA",
//   "SEPSECS",
//   "SLC34A1",
//   "HLA-DPB1",
//   "BANF1",
//   "VPS51",
//   "OR2J3",
//   "SLC18A3",
//   "COL6A1",
//   "KCNJ6",
//   "AK1",
//   "TMIE",
//   "EDARADD",
//   "TMEM126A",
//   "TYMP",
//   "TUBB2B",
//   "FSCN2",
//   "MSR1",
//   "SERPING1",
//   "ERCC2",
//   "PDX1",
//   "GATB",
//   "ERCC5",
//   "GSC",
//   "MLLT10",
//   "FGF17",
//   "LARS2",
//   "BIN1",
//   "COA7",
//   "HCCS",
//   "SHPK",
//   "SP7",
//   "PER2",
//   "RSPH9",
//   "ADAMTS2",
//   "RTN4R",
//   "SLC22A5",
//   "GPR88",
//   "UQCC2",
//   "KRIT1",
//   "KLHL3",
//   "LIPN",
//   "ZBTB24",
//   "PRLR",
//   "PUS3",
//   "FOXP2",
//   "HYOU1",
//   "ATP2A2",
//   "SLC2A1",
//   "CLN3",
//   "NONO",
//   "CDK4",
//   "GPD2",
//   "STXBP1",
//   "ITCH",
//   "PATL2",
//   "TWIST2",
//   "LZTS1",
//   "B4GALT7",
//   "IVNS1ABP",
//   "KCNK18",
//   "CCDC174",
//   "NPC1",
//   "RHBDF2",
//   "TJP2",
//   "CSF2RA",
//   "WNT1",
//   "NOS2",
//   "STIM1",
//   "LMX1A",
//   "KANSL1",
//   "TRIM44",
//   "POLR1A",
//   "COLQ",
//   "WDR1",
//   "CILP",
//   "TMEM43",
//   "MLPH",
//   "TGFBR2",
//   "TCTN2",
//   "MCM3AP",
//   "FOXP3",
//   "SUMO4",
//   "PRNP",
//   "VAC14",
//   "OTULIN",
//   "SGCD",
//   "CYP2A6",
//   "ROBO2",
//   "CNOT1",
//   "LRP6",
//   "LTBP2",
//   "VPS53",
//   "NUP85",
//   "DEK",
//   "MESP2",
//   "WNK4",
//   "TSEN54",
//   "FGFR3",
//   "ZNF408",
//   "CLN6",
//   "ATP1A2",
//   "GZF1",
//   "NLRP3",
//   "DLD",
//   "GPSM2",
//   "KRT85",
//   "ERCC1",
//   "CHD8",
//   "MACF1",
//   "NUP188",
//   "KIAA1109",
//   "SETX",
//   "IL6R",
//   "UBA5",
//   "DRC1",
//   "CA12",
//   "POPDC3",
//   "CP",
//   "AK7",
//   "PDHA1",
//   "RPGRIP1",
//   "SKIV2L",
//   "GLMN",
//   "MTMR14",
//   "GBA",
//   "CARMIL2",
//   "THSD1",
//   "ST14",
//   "MYH9",
//   "PML",
//   "PNP",
//   "MAN2B1",
//   "NFASC",
//   "LDLR",
//   "RAB3GAP2",
//   "MCPH1",
//   "BCHE",
//   "SNRPN",
//   "TFR2",
//   "NPHS1",
//   "PDK3",
//   "SLC22A18",
//   "IL12RB1",
//   "KCNN3",
//   "CRIPT",
//   "TSHZ1",
//   "PRRX1",
//   "GALNS",
//   "RIMS1",
//   "ACTB",
//   "GRM7",
//   "HS6ST2",
//   "HADHB",
//   "EMP2",
//   "FGF20",
//   "GRXCR1",
//   "PDE6B",
//   "C5",
//   "STAG3",
//   "KCNV2",
//   "CASR",
//   "HMCN1",
//   "LEF1",
//   "KRT75",
//   "TINF2",
//   "PRDM12",
//   "SEC24D",
//   "CXCR1",
//   "OPN1MW",
//   "PCYT2",
//   "FBP1",
//   "TRAPPC11",
//   "PIGB",
//   "CDK5",
//   "CEP63",
//   "ISCU",
//   "VARS1",
//   "UROD",
//   "EGR2",
//   "SPR",
//   "HIKESHI",
//   "SHOC2",
//   "CPOX",
//   "CLCNKB",
//   "VKORC1",
//   "CDSN",
//   "TAF4B",
//   "AP2S1",
//   "AQP3",
//   "GMPPA",
//   "PLA2G7",
//   "MYO18B",
//   "ETHE1",
//   "GALNT3",
//   "DARS1",
//   "GAN",
//   "CD27",
//   "KIAA0586",
//   "VPS13D",
//   "DHX30",
//   "MFSD2A",
//   "USP7",
//   "TTC19",
//   "SLC16A12",
//   "NFE2L2",
//   "LRIG2",
//   "HLA-DQA1",
//   "DCTN1",
//   "SLC46A1",
//   "ACTA1",
//   "MAPK8IP1",
//   "MRAP2",
//   "FDPS",
//   "MPI",
//   "PDE10A",
//   "EP300",
//   "MCM6",
//   "TSPAN12",
//   "NCSTN",
//   "PLEKHG2",
//   "ANKH",
//   "ADA2",
//   "IGBP1",
//   "MDH1",
//   "MYL1",
//   "TTBK2",
//   "FTL",
//   "PRICKLE1",
//   "DHX38",
//   "NEDD4L",
//   "NNMT",
//   "BBS12",
//   "SERAC1",
//   "MITF",
//   "IFNG",
//   "SERPINF2",
//   "TM4SF20",
//   "ROM1",
//   "THRA",
//   "TDP2",
//   "PRKN",
//   "LRBA",
//   "MLC1",
//   "MPIG6B",
//   "CFD",
//   "DNAJB6",
//   "TAF2",
//   "RAG2",
//   "MRE11",
//   "GHR",
//   "TBXT",
//   "IFIH1",
//   "MYBPC1",
//   "PIGN",
//   "POLR3A",
//   "RINT1",
//   "CHCHD2",
//   "GLYCTK",
//   "CHMP1A",
//   "FKBP10",
//   "IL18BP",
//   "GDNF",
//   "DYNC2LI1",
//   "EPHA2",
//   "PAH",
//   "DNA2",
//   "SEPTIN9",
//   "NDUFS2",
//   "AMHR2",
//   "MYH14",
//   "COG1",
//   "BNC2",
//   "DNAI2",
//   "SERPINA3",
//   "PRKCH",
//   "UNG",
//   "GHRL",
//   "TMEM38B",
//   "LOXL1",
//   "UPF3B",
//   "ATP6V0A4",
//   "EIF2AK1",
//   "PCBD1",
//   "AMH",
//   "SCN5A",
//   "ALPL",
//   "BLK",
//   "RNF212",
//   "PRDM6",
//   "GUCY2C",
//   "TDGF1",
//   "SPECC1L",
//   "IHH",
//   "MAN1B1",
//   "ALS2",
//   "UNC13D",
//   "ALMS1",
//   "EIF2AK2",
//   "TBXAS1",
//   "IYD",
//   "ATP5F1E",
//   "SCNN1G",
//   "COL4A5",
//   "HMGCS2",
//   "IMPDH2",
//   "RPL27",
//   "TUBB1",
//   "GAD1",
//   "TMTC3",
//   "ACP5",
//   "CASP12",
//   "ZFHX4",
//   "IFNGR1",
//   "PDE4D",
//   "CHST8",
//   "SLC19A3",
//   "GCNT2",
//   "DDX6",
//   "RELB",
//   "PPT1",
//   "ECE1",
//   "F5",
//   "CLDN1",
//   "KEL",
//   "ZIC2",
//   "FGB",
//   "CNPY3",
//   "AP1S2",
//   "GLI2",
//   "EDA",
//   "TRIM32",
//   "SDHAF2",
//   "TXNRD2",
//   "CHST14",
//   "TPCN2",
//   "DHCR7",
//   "GIPC1",
//   "LARGE1",
//   "THOC6",
//   "RIMS2",
//   "MMP21",
//   "NAT8L",
//   "BBS7",
//   "COPB2",
//   "XYLT1",
//   "DYNC1I2",
//   "ABO",
//   "CLIC2",
//   "LRP4",
//   "CHD7",
//   "COL11A2",
//   "POLR1C",
//   "AVPR2",
//   "EIF2B2",
//   "ISG15",
//   "MCEE",
//   "DMPK",
//   "TNF",
//   "KRT6C",
//   "RFXAP",
//   "KMT2A",
//   "CD3G",
//   "TBC1D4",
//   "ADK",
//   "SERPIND1",
//   "PHC1",
//   "ICOS",
//   "HNF4A",
//   "SPG7",
//   "ACTL6B",
//   "MYH6",
//   "ASPSCR1",
//   "PAX6",
//   "REST",
//   "ZNF141",
//   "CLCNKA",
//   "GGCX",
//   "KRAS",
//   "AHSG",
//   "KRT9",
//   "HSPB8",
//   "MSTN",
//   "KIZ",
//   "ARF1",
//   "TUB",
//   "RDH11",
//   "TICAM1",
//   "MARS2",
//   "DOCK6",
//   "SEC61A1",
//   "F9",
//   "NRIP1",
//   "EPB41",
//   "DSPP",
//   "FITM2",
//   "FYCO1",
//   "SRP54",
//   "TOR1AIP1",
//   "ATP5F1D",
//   "ARL3",
//   "MIB1",
//   "HMMR",
//   "CNTN1",
//   "BEST1",
//   "LPP",
//   "DCHS1",
//   "MIPEP",
//   "ADCY1",
//   "ADRA2C",
//   "BBS5",
//   "NUP160",
//   "MERTK",
//   "PNPO",
//   "TCF4",
//   "DPF2",
//   "NOTCH2NLC",
//   "SPTBN4",
//   "IGLL1",
//   "RANBP2",
//   "COL4A4",
//   "LDB3",
//   "PEX14",
//   "FGD4",
//   "CNKSR2",
//   "ASXL1",
//   "MAD2L2",
//   "SYNE1",
//   "PAX1",
//   "STK4",
//   "LBR",
//   "DPYD",
//   "SPINK5",
//   "WDR66",
//   "FH",
//   "SOD1",
//   "CRYGD",
//   "GNS",
//   "ARX",
//   "TMEM106B",
//   "PROZ",
//   "TMCO1",
//   "KREMEN1",
//   "PNPLA1",
//   "CLP1",
//   "SLC38A8",
//   "LIG4",
//   "HMX1",
//   "GLDN",
//   "KDM5C",
//   "SLC39A8",
//   "RBM12",
//   "FGA",
//   "EED",
//   "TUBGCP4",
//   "IFT74",
//   "CEP104",
//   "SCN3B",
//   "CENPF",
//   "GCDH",
//   "P3H1",
//   "PLIN1",
//   "SLC36A2",
//   "SLC52A3",
//   "EBF3",
//   "ADAMTS13",
//   "IL12B",
//   "VLDLR",
//   "BPTF",
//   "PCYT1A",
//   "GUCY2D",
//   "ARID1B",
//   "TCF20",
//   "ABCD3",
//   "RNF168",
//   "ATL3",
//   "LRP8",
//   "LYL1",
//   "MRAS",
//   "LHCGR",
//   "MAP3K7",
//   "TUBA3D",
//   "MLH1",
//   "MSTO1",
//   "TFAM",
//   "GNAL",
//   "ARSB",
//   "PPP2R2B",
//   "SCN3A",
//   "SLC1A2",
//   "FAM20A",
//   "MYH3",
//   "DLST",
//   "CEACAM16",
//   "SAMHD1",
//   "CST6",
//   "HPRT1",
//   "GGT1",
//   "MYL4",
//   "DRD3",
//   "MTR",
//   "RIPOR2",
//   "TSGA10",
//   "GUF1",
//   "YAP1",
//   "DNAAF2",
//   "GPX4",
//   "NLGN4X",
//   "SPTB",
//   "TAC3",
//   "DLL1",
//   "OPHN1",
//   "FERMT1",
//   "EPAS1",
//   "HBG2",
//   "PHIP",
//   "CIDEC",
//   "GRIN2B",
//   "GABRB3",
//   "ZNF365",
//   "MMACHC",
//   "NCAPG2",
//   "ADARB1",
//   "SOX10",
//   "KCNT2",
//   "DDR2",
//   "FEZF1",
//   "ZFAT",
//   "F10",
//   "LYZ",
//   "POLR3B",
//   "PEX13",
//   "SOS1",
//   "PFKL",
//   "SLC26A5",
//   "PITX3",
//   "PROM1",
//   "TCL1A",
//   "CHN1",
//   "ATP1B1",
//   "PIBF1",
//   "DPH1",
//   "XBP1",
//   "VAMP2",
//   "SLC6A5",
//   "LAS1L",
//   "ACTN4",
//   "CCNK",
//   "TUSC3",
//   "NADSYN1",
//   "ROR1",
//   "EXT1",
//   "LINS1",
//   "GDI1",
//   "PUF60",
//   "USB1",
//   "FTSJ1",
//   "MIR2861",
//   "BBS10",
//   "AGK",
//   "SCN4B",
//   "NEPRO",
//   "EIF2AK3",
//   "OXR1",
//   "HTRA1",
//   "S1PR2",
//   "EGFR",
//   "USP45",
//   "TRIM37",
//   "DNM1",
//   "RPS23",
//   "PTPRC",
//   "CLDN10",
//   "TACO1",
//   "CEP78",
//   "SLC25A42",
//   "IMPDH1",
//   "KLC2",
//   "BCORL1",
//   "ARR3",
//   "CFAP65",
//   "CD207",
//   "ENO1",
//   "PSMC3IP",
//   "ZFHX2",
//   "ATP2A1",
//   "REEP2",
//   "CASP8",
//   "SLC5A5",
//   "NFIB",
//   "STUB1",
//   "SMC1A",
//   "GNB5",
//   "WEE2",
//   "BMP15",
//   "XIAP",
//   "FGG",
//   "GHRHR",
//   "ADRB1",
//   "HNRNPK",
//   "RABL3",
//   "POU1F1",
//   "ACADVL",
//   "ACVR2B",
//   "VAX1",
//   "JPH3",
//   "NECAP1",
//   "KDM3B",
//   "PUM1",
//   "PPP3CA",
//   "ALDH6A1",
//   "CASQ1",
//   "HADH",
//   "NBAS",
//   "PIGM",
//   "CRYGC",
//   "SLC6A1",
//   "FGFR4",
//   "EIF4G1",
//   "KDSR",
//   "PKP2",
//   "PPP2R1B",
//   "ITGB6",
//   "CTLA4",
//   "NARS2",
//   "AP5Z1",
//   "STIL",
//   "MSX2",
//   "DMP1",
//   "SLC9A3R1",
//   "GPC6",
//   "PTPRO",
//   "NANS",
//   "NOTCH3",
//   "DCAF8",
//   "LEMD2",
//   "HFM1",
//   "MECP2",
//   "ALOXE3",
//   "BBS1",
//   "UFC1",
//   "SLC17A8",
//   "TOPORS",
//   "CA4",
//   "ATP8A2",
//   "NUTM2B-AS1",
//   "ORC1",
//   "MMAA",
//   "DNMT3B",
//   "AIPL1",
//   "GATA3",
//   "CTHRC1",
//   "SLC12A1",
//   "ICAM4",
//   "SMAD7",
//   "SETBP1",
//   "GNAI2",
//   "SLC39A4",
//   "TBC1D23",
//   "SLC35A1",
//   "ATP6V1E1",
//   "IQSEC2",
//   "VWF",
//   "CENPE",
//   "PTS",
//   "PIK3R1",
//   "EGLN1",
//   "ARFGEF2",
//   "GPR143",
//   "CDON",
//   "DONSON",
//   "MPL",
//   "DALRD3",
//   "PXDN",
//   "OTUD6B",
//   "STEAP3",
//   "FLG",
//   "DNAJC3",
//   "MVD",
//   "LHFPL5",
//   "F11",
//   "PCCB",
//   "FGF3",
//   "METTL23",
//   "FAM126A",
//   "HPSE2",
//   "COL18A1",
//   "HECW2",
//   "PTRH2",
//   "PLD1",
//   "PINK1",
//   "AP1S1",
//   "MPLKIP",
//   "ERBB3",
//   "MAP2K1",
//   "USP8",
//   "GABRB1",
//   "DHTKD1",
//   "AKR1C4",
//   "TTN",
//   "MME",
//   "B9D1",
//   "ZNF462",
//   "RAP1GDS1",
//   "PER3",
//   "GRIA3",
//   "FCGR2A",
//   "PDXK",
//   "RGS5",
//   "MTTP",
//   "HEXA",
//   "NEK9",
//   "HBD",
//   "LARP7",
//   "CLPB",
//   "ATP1A1",
//   "ZMYND11",
//   "ZBTB16",
//   "PPP1R15B",
//   "CALCRL",
//   "SHROOM4",
//   "PSEN2",
//   "NTRK2",
//   "TRAPPC2L",
//   "MKKS",
//   "SBF2",
//   "SLC11A2",
//   "RHO",
//   "USH1C",
//   "HYAL1",
//   "EPS8L3",
//   "HIBCH",
//   "IGHM",
//   "TBCD",
//   "CATSPER1",
//   "KMT2B",
//   "HLA-G",
//   "SLC13A3",
//   "SYCP2",
//   "HYLS1",
//   "TSPEAR",
//   "GPT2",
//   "TGM1",
//   "SF3B1",
//   "NGLY1",
//   "TMEM70",
//   "REN",
//   "WDR72",
//   "HMGA1",
//   "GALE",
//   "PFKM",
//   "ADGRG2",
//   "DMD",
//   "FOXJ1",
//   "FUZ",
//   "LOXHD1",
//   "CAV3",
//   "C1QC",
//   "FERMT3",
//   "FTO",
//   "CAVIN1",
//   "COL5A2",
//   "TAPT1",
//   "MTPAP",
//   "CSF2RB",
//   "GPRASP2",
//   "KCNJ10",
//   "ALX3",
//   "PTPN12",
//   "DNAH9",
//   "OTOGL",
//   "SPART",
//   "SLC5A2",
//   "GNE",
//   "MYPN",
//   "XRCC1",
//   "RDX",
//   "LACC1",
//   "CNTN2",
//   "MID1",
//   "TEX11",
//   "PRKDC",
//   "MAML2",
//   "CCDC115",
//   "GLCCI1",
//   "BPGM",
//   "RNF125",
//   "IGSF3",
//   "POU6F2",
//   "RDH12",
//   "SOX5",
//   "TENM4",
//   "COA6",
//   "COL9A1",
//   "LEMD3",
//   "TRAPPC6B",
//   "VSX1",
//   "RFT1",
//   "SLC33A1",
//   "CLDN19",
//   "SARS2",
//   "MYO1E",
//   "DDX58",
//   "LAMTOR2",
//   "LAMA4",
//   "IGKC",
//   "CUL4B",
//   "CFHR5",
//   "BRWD3",
//   "HAVCR2",
//   "MAP11",
//   "MET",
//   "SORD",
//   "GNRH1",
//   "CHUK",
//   "LCA5",
//   "TMEM98",
//   "NR5A1",
//   "C8orf37",
//   "GPC4",
//   "ELOVL4",
//   "DYRK1B",
//   "FSIP2",
//   "ADCY6",
//   "FBXO38",
//   "HOXD13",
//   "DNAL1",
//   "BCL3",
//   "ZBTB18",
//   "IL23R",
//   "TRAPPC12",
//   "ING1",
//   "SZT2",
//   "TMEM67",
//   "TNNI3",
//   "ART4",
//   "FCGR3B",
//   "MDH2",
//   "F12",
//   "SMARCC2",
//   "GNAT2",
//   "TRAF3",
//   "PDE11A",
//   "SLC24A4",
//   "B3GAT3",
//   "TRRAP",
//   "HMGCL",
//   "MATR3",
//   "NR1H4",
//   "SOX9",
//   "ADH1B",
//   "MFF",
//   "PNKD",
//   "CWC27",
//   "COX20",
//   "GJA5",
//   "KCNH1",
//   "MUTYH",
//   "ALKBH8",
//   "TNFRSF1A",
//   "QRICH1",
//   "GGT2",
//   "G6PC3",
//   "TBC1D7",
//   "RAD51",
//   "FGF9",
//   "CYP7B1",
//   "RHCE",
//   "CTBP1",
//   "PIGO",
//   "GLB1",
//   "LCT",
//   "NDRG1",
//   "TAF1",
//   "IRF9",
//   "COQ9",
//   "TRPV6",
//   "POU4F3",
//   "TG",
//   "PKLR",
//   "NSUN2",
//   "CLDN16",
//   "FIBP",
//   "BAP1",
//   "SOD3",
//   "CRYGS",
//   "LTBP3",
//   "TREX1",
//   "CDKN1C",
//   "KHK",
//   "CRB1",
//   "THBD",
//   "GSDME",
//   "YY1",
//   "STX1B",
//   "MINPP1",
//   "SRP72",
//   "NKX2-1",
//   "PLOD2",
//   "PPP2R3C",
//   "LAT",
//   "CHRNA1",
//   "KAT6B",
//   "TEX15",
//   "A2ML1",
//   "SLFN14",
//   "EDC3",
//   "EFL1",
//   "MNS1",
//   "CHRNA4",
//   "HERC2",
//   "NDUFAF5",
//   "BMP1",
//   "PDE6A",
//   "PTGIS",
//   "STAR",
//   "RAB28",
//   "TNNI3K",
//   "QRSL1",
//   "C1QB",
//   "RFX5",
//   "TLR1",
//   "MCM2",
//   "ST3GAL3",
//   "SLC25A26",
//   "TTPA",
//   "HBA1",
//   "COQ2",
//   "TCIRG1",
//   "METTL5",
//   "CRTAP",
//   "TTC12",
//   "MAG",
//   "PIGW",
//   "PHKG2",
//   "LRRK2",
//   "GBA2",
//   "TRNT1",
//   "TUBB8",
//   "SBF1",
//   "ANK3",
//   "APOL1",
//   "SLC44A1",
//   "CUBN",
//   "CACNG2",
//   "SMARCD2",
//   "STT3A",
//   "BUB1",
//   "TAF15",
//   "CHCHD10",
//   "MEF2C",
//   "SERPINB6",
//   "SSTR5",
//   "ERCC8",
//   "AP4B1",
//   "CFAP298",
//   "SRCAP",
//   "CNGB3",
//   "PYROXD1",
//   "WDFY3",
//   "ABCA4",
//   "FOXD3",
//   "ZIC1",
//   "NDUFAF2",
//   "LITAF",
//   "TACR3",
//   "SMOC2",
//   "ACAT2",
//   "RARS2",
//   "SYCE1",
//   "TRPS1",
//   "ERCC6L2",
//   "CCDC65",
//   "SPINK2",
//   "CHRNB2",
//   "RC3H1",
//   "RSPO2",
//   "FLG2",
//   "PFN1",
//   "PHKA2",
//   "CYBA",
//   "BMPR2",
//   "ADSS1",
//   "SRC",
//   "IKZF1",
//   "NR2E3",
//   "KRT6A",
//   "CAST",
//   "PLAT",
//   "ADAMTS3",
//   "RSPRY1",
//   "RFC1",
//   "LMAN2L",
//   "DNAJC19",
//   "ANGPTL4",
//   "THPO",
//   "RIPK4",
//   "POU3F4",
//   "MRPS16",
//   "EGF",
//   "DIP2B",
//   "BRCA2",
//   "ITGA8",
//   "NEXMIF",
//   "SLC25A21",
//   "GRN",
//   "SNCA",
//   "KIF7",
//   "ADAT3",
//   "PDE6C",
//   "EXOSC8",
//   "CHRNB1",
//   "TNFSF11",
//   "FASTKD2",
//   "LPAR6",
//   "MMAB",
//   "UNC80",
//   "CHST11",
//   "TMEM231",
//   "CREBBP",
//   "IL1B",
//   "NF1",
//   "COG6",
//   "COPA",
//   "KCNA2",
//   "RNF213",
//   "FANCC",
//   "ASPN",
//   "CHIT1",
//   "PHYH",
//   "TLR7",
//   "APTX",
//   "SLC16A1",
//   "CTNNB1",
//   "DEGS1",
//   "POLA1",
//   "FXYD2",
//   "TERC",
//   "CHD2",
//   "PTH",
//   "NEB",
//   "CRB2",
//   "SETD5",
//   "NDUFA2",
//   "ATOH7",
//   "NR2F2",
//   "INSL3",
//   "SYNJ1",
//   "TENM3",
//   "SQSTM1",
//   "CD3E",
//   "IL31RA",
//   "IFT43",
//   "SPATA7",
//   "NEK8",
//   "KLHL40",
//   "PRPS1",
//   "SPAST",
//   "CFHR1",
//   "GABBR2",
//   "TAS2R38",
//   "SIPA1L3",
//   "TPM3",
//   "TNFRSF13C",
//   "DCXR",
//   "HSPB3",
//   "LAMP2",
//   "TRPM6",
//   "GLIS2",
//   "KRT25",
//   "MBD5",
//   "SET",
//   "CAMK2B",
//   "DOCK7",
//   "UQCRFS1",
//   "NHLRC2",
//   "HAL",
//   "RAB27A",
//   "EIF2B1",
//   "GRIA2",
//   "FOXC2",
//   "NDUFA12",
//   "LIPT2",
//   "SMIM1",
//   "CENPJ",
//   "HMBS",
//   "SLC24A5",
//   "PAFAH1B1",
//   "SLC25A20",
//   "DNAAF5",
//   "PARK7",
//   "TTR",
//   "PTGDR",
//   "LCK",
//   "SERPINC1",
//   "GCKR",
//   "SLC6A8",
//   "SLC7A7",
//   "NUP133",
//   "RAX",
//   "ADAMTSL2",
//   "SC5D",
//   "COX6A2",
//   "IDS",
//   "RBM10",
//   "RORC",
//   "CD209",
//   "IRF8",
//   "SLC9A6",
//   "PKDCC",
//   "CBL",
//   "SERPINA6",
//   "ZNF148",
//   "CLCC1",
//   "PTPN1",
//   "HABP2",
//   "MRPS34",
//   "GNAQ",
//   "POGZ",
//   "SNRPB",
//   "PLOD3",
//   "SH3GL1",
//   "CFP",
//   "C11orf80",
//   "TRMU",
//   "KRT13",
//   "AUH",
//   "TARS2",
//   "TDRD9",
//   "PLPBP",
//   "CD59",
//   "TLE6",
//   "KANK2",
//   "TPM2",
//   "CACNA1B",
//   "ATM",
//   "EPHX1",
//   "LRRC56",
//   "SLC10A7",
//   "MYSM1",
//   "FBN2",
//   "SCN8A",
//   "MICU1",
//   "TNC",
//   "BCS1L",
//   "MRPL12",
//   "PC",
//   "ZNF142",
//   "EML1",
//   "RAC2",
//   "PKP1",
//   "RELA",
//   "BCAT2",
//   "NUMA1",
//   "HEXB",
//   "HSF4",
//   "ERGIC1",
//   "SGCA",
//   "APOE",
//   "JAM2",
//   "TRMT1",
//   "C8A",
//   "GDAP2",
//   "ATP7B",
//   "MESD",
//   "TUBB2A",
//   "LINGO1",
//   "HCN4",
//   "SCN11A",
//   "PPOX",
//   "PCNA",
//   "CEP85L",
//   "NCAPH",
//   "SLURP1",
//   "GPI",
//   "POT1",
//   "CCDC50",
//   "IL6ST",
//   "KCTD1",
//   "CYP2U1",
//   "SH2B3",
//   "KITLG",
//   "DSC3",
//   "CPT2",
//   "CCM2",
//   "TCN2",
//   "GATA2",
//   "CBFB",
//   "DNAJC6",
//   "MIR96",
//   "SURF1",
//   "FBLN1",
//   "XPC",
//   "KIDINS220",
//   "MPDZ",
//   "TTC8",
//   "GDF6",
//   "KRT1",
//   "CNGB1",
//   "CNNM4",
//   "GSN",
//   "EXOSC2",
//   "DLL3",
//   "COL7A1",
//   "KIAA0556",
//   "IFITM3",
//   "MAMLD1",
//   "KATNB1",
//   "CABP2",
//   "CHRM3",
//   "IRF2BP2",
//   "NLRC4",
//   "FOLR1",
//   "GTF2E2",
//   "PGAM2",
//   "SFTPA2",
//   "PLEKHG5",
//   "TMEM237",
//   "MYLK",
//   "IKBKG",
//   "LOR",
//   "PIGS",
//   "UBE3B",
//   "PALB2",
//   "SLITRK1",
//   "GJB3",
//   "AMACR",
//   "ITGB2",
//   "RIPPLY2",
//   "COX6B1",
//   "HPS6",
//   "CSF1R",
//   "MEI1",
//   "ACTN1",
//   "PSPH",
//   "BRF1",
//   "FLVCR1",
//   "COL27A1",
//   "YY1AP1",
//   "POGLUT1",
//   "ZSWIM6",
//   "RPL10",
//   "ZFP57",
//   "TMEM94",
//   "CYP19A1",
//   "ERCC6",
//   "RGS9",
//   "GYS2",
//   "APP",
//   "NECTIN1",
//   "ATP6AP1",
//   "DNAI1",
//   "FAM161A",
//   "RPL21",
//   "UBE2T",
//   "TSR2",
//   "TRAF7",
//   "ACTA2",
//   "POMP",
//   "BCKDHA",
//   "KCNA5",
//   "ABHD5",
//   "LIM2",
//   "AICDA",
//   "ADAMTS18",
//   "RORB",
//   "L2HGDH",
//   "KERA",
//   "GABRD",
//   "LIPH",
//   "KLHL24",
//   "IL2RG",
//   "DNAJB13",
//   "HNRNPA1",
//   "SH3PXD2B",
//   "CARTPT",
//   "GPR101",
//   "TBC1D20",
//   "SLC6A9",
//   "SVBP",
//   "WARS1",
//   "TF",
//   "IL10RA",
//   "IARS2",
//   "GLUD2",
//   "SBDS",
//   "NPRL3",
//   "ANAPC1",
//   "RD3",
//   "TDO2",
//   "RPL35A",
//   "LPA",
//   "BCL11B",
//   "MTM1",
//   "KCNQ2",
//   "PREPL",
//   "C3",
//   "CEBPE",
//   "KIAA1549",
//   "SLC25A10",
//   "ATPAF2",
//   "RPS19",
//   "TECRL",
//   "RAPGEF2",
//   "SGMS2",
//   "MRPS2",
//   "CAT",
//   "GPD1",
//   "MUC5B",
//   "UBA1",
//   "CYP4V2",
//   "ADSL",
//   "DLL4",
//   "HES7",
//   "ABAT",
//   "MSRB3",
//   "UBE2A",
//   "CCDC39",
//   "CYLD",
//   "NAGA",
//   "DNAJC21",
//   "RBM20",
//   "ITGB3",
//   "CISD2",
//   "SAR1B",
//   "FRRS1L",
//   "NIPBL",
//   "TOP2A",
//   "CFI",
//   "ATXN2",
//   "COASY",
//   "OPCML",
//   "NQO2",
//   "MARVELD2",
//   "MALT1",
//   "BBS4",
//   "CAPN1",
//   "KRT4",
//   "AHDC1",
//   "FDFT1",
//   "FSHR",
//   "SUMO1",
//   "CERKL",
//   "PRCD",
//   "SPEG",
//   "DOCK2",
//   "PPIP5K2",
//   "EIF4E",
//   "TNNI2",
//   "FAM50A",
//   "PMM2",
//   "TTLL5",
//   "MUC7",
//   "TPM1",
//   "FGF5",
//   "ADIPOQ",
//   "WDR45B",
//   "POMT1",
//   "MRPS22",
//   "CIT",
//   "FMN2",
//   "ZNF513",
//   "VPS45",
//   "ROBO4",
//   "CCDC88A",
//   "MAGT1",
//   "CLCN2",
//   "SUFU",
//   "PLCE1",
//   "PMPCB",
//   "SLC20A2",
//   "NUS1",
//   "BAAT",
//   "ITPA",
//   "TMPRSS6",
//   "SGSH",
//   "KCNJ5",
//   "EDN1",
//   "PACS2",
//   "VWA3B",
//   "KIF5A",
//   "CRYBA4",
//   "NEUROD2",
//   "CFAP44",
//   "CEP120",
//   "NR0B1",
//   "GP9",
//   "CUX1",
//   "STRC",
//   "COL9A3",
//   "BICC1",
//   "RFX6",
//   "CISH",
//   "NFKB2",
//   "CYP1B1",
//   "GLS",
//   "CDH23",
//   "ADRB3",
//   "AHCY",
//   "GLRA1",
//   "TNRC6A",
//   "CKAP2L",
//   "LRAT",
//   "HYDIN",
//   "PRODH",
//   "CRYBB1",
//   "WDR26",
//   "PSENEN",
//   "IRF1",
//   "HAX1",
//   "NDUFA10",
//   "PTF1A",
//   "PHKB",
//   "ARHGEF2",
//   "PEX11B",
//   "DHPS",
//   "TNFRSF11B",
//   "LIMS2",
//   "LDHB",
//   "PIGP",
//   "GCLM",
//   "VANGL2",
//   "KBTBD13",
//   "ASCC1",
//   "PLOD1",
//   "TBL1XR1",
//   "DUOXA2",
//   "D2HGDH",
//   "BRDT",
//   "FDX2",
//   "CALM1",
//   "HAMP",
//   "RSPH4A",
//   "ASS1",
//   "CASQ2",
//   "POC1B",
//   "UBIAD1",
//   "IRF3",
//   "P2RY12",
//   "ARSG",
//   "SLC25A4",
//   "GLDC",
//   "NAT2",
//   "SUZ12",
//   "NKX3-2",
//   "LONP1",
//   "ALG8",
//   "GPR68",
//   "SLC39A13",
//   "YWHAG",
//   "MDM2",
//   "IFNL3",
//   "ARHGAP31",
//   "CSH1",
//   "CACNB2",
//   "PHF6",
//   "GRIN2D",
//   "DSG4",
//   "EZH2",
//   "ATRX",
//   "TNIK",
//   "ARL6",
//   "EPX",
//   "RXYLT1",
//   "C1S",
//   "CYP26B1",
//   "B4GALNT1",
//   "PKD1",
//   "DNAJC5",
//   "BMP4",
//   "KMT5B",
//   "AMBN",
//   "IKBKB",
//   "ELP2",
//   "INTU",
//   "KISS1",
//   "BLNK",
//   "FARSB",
//   "SEC63",
//   "ZNF644",
//   "CAPN5",
//   "OGG1",
//   "HARS1",
//   "APC",
//   "RARB",
//   "PEX1",
//   "SMARCD1",
//   "TRPM7",
//   "ASPM",
//   "CD55",
//   "RPL15",
//   "SLC25A32",
//   "GLUD1",
//   "TNFRSF10B",
//   "NKAP",
//   "PRKACG",
//   "MFAP5",
//   "LDHD",
//   "PSMD12",
//   "IL10RB",
//   "GNA11",
//   "C8B",
//   "ATXN1",
//   "FANCE",
//   "LOX",
//   "AARS2",
//   "AASS",
//   "SLC27A4",
//   "PIK3R5",
//   "HLA-B",
//   "AARS1",
//   "GTF2H5",
//   "DSP",
//   "APOA2",
//   "KDM1A",
//   "SMPX",
//   "TBX4",
//   "GHSR",
//   "ATXN3",
//   "APOA1",
//   "UMOD",
//   "TAP1",
//   "FAR1",
//   "OBSL1",
//   "TULP1",
//   "TXNL4A",
//   "PMP2",
//   "TRPM1",
//   "GYPB",
//   "NRG1",
//   "PCLO",
//   "RPS17",
//   "CARD11",
//   "CEP57",
//   "RNF216",
//   "TGDS",
//   "SUGCT",
//   "XRCC4",
//   "PHOX2A",
//   "NDUFV2",
//   "MTHFD1",
//   "KLK4",
//   "CRYBB3",
//   "NSMCE2",
//   "TTC25",
//   "NDNF",
//   "ABCA3",
//   "TCF12",
//   "TOP1",
//   "AIRE",
//   "OXCT1",
//   "NDUFA9",
//   "CTSA",
//   "UCP3",
//   "GSX2",
//   "P3H2",
//   "MPDU1",
//   "ATP6AP2",
//   "LZTR1",
//   "TANC2",
//   "KIFBP",
//   "KCNC1",
//   "CD8A",
//   "KRT2",
//   "NTNG2",
//   "PCK1",
//   "PCGF2",
//   "HELLS",
//   "KCNE3",
//   "SLITRK6",
//   "RBP3",
//   "ALOX5AP",
//   "ADGRV1",
//   "AP4E1",
//   "ADD3",
//   "KIF4A",
//   "ARL6IP1",
//   "PIK3R2",
//   "GSR",
//   "BSCL2",
//   "NOL3",
//   "SRPX2",
//   "ARHGEF9",
//   "MYOD1",
//   "BRAT1",
//   "NQO1",
//   "NPHS2",
//   "PPA2",
//   "TREM2",
//   "F7",
//   "MAOA",
//   "DDHD1",
//   "EFTUD2",
//   "DBT",
//   "BLM",
//   "SLC14A1",
//   "KHDC3L",
//   "RBPJ",
//   "BNC1",
//   "WDR19",
//   "SLC11A1",
//   "MYL3",
//   "SMPD1",
//   "CACNA1F",
//   "CYP24A1",
//   "ZMPSTE24",
//   "FOXG1",
//   "SLC13A5",
//   "TSFM",
//   "ATP5MD",
//   "ARL2BP",
//   "ANK1",
//   "PLCB2",
//   "ZFYVE26",
//   "SMC3",
//   "SFTPB",
//   "AGTR1",
//   "RHOH",
//   "ELP4",
//   "SASH1",
//   "CD244",
//   "SMPD4",
//   "INSR",
//   "POLE",
//   "ABCB4",
//   "RGS9BP",
//   "GREB1L",
//   "MVK",
//   "DNAH5",
//   "PEX3",
//   "POLG",
//   "MCOLN1",
//   "ETFDH",
//   "CTSB",
//   "TAB2",
//   "DISC1",
//   "ASH1L",
//   "GOSR2",
//   "B3GALT6",
//   "MARS1",
//   "KAT8",
//   "USH1G",
//   "MECOM",
//   "PEX2",
//   "VPS11",
//   "SRD5A2",
//   "ALDH5A1",
//   "PACS1",
//   "CCDC47",
//   "SOX3",
//   "SCNN1B",
//   "PEX12",
//   "RBM15",
//   "PHACTR1",
//   "APPL1",
//   "NECTIN4",
//   "AKAP10",
//   "LIAS",
//   "ZEB2",
//   "ORC4",
//   "UOX",
//   "CREB3L1",
//   "SIGMAR1",
//   "LYRM4",
//   "XIST",
//   "THOC2",
//   "F8",
//   "PHB",
//   "FBXW11",
//   "FXR1",
//   "CD36",
//   "DICER1",
//   "POC1A",
//   "TBX5",
//   "SELENBP1",
//   "UGDH",
//   "ACP2",
//   "GAL",
//   "KLLN",
//   "SUCLA2",
//   "COL4A3",
//   "IRF7",
//   "ALG10B",
//   "HACE1",
//   "DIS3L2",
//   "PGR",
//   "TIMP3",
//   "BMPER",
//   "HLA-DQB1",
//   "IER3IP1",
//   "PDSS1",
//   "THBS2",
//   "PRPF8",
//   "ELOVL1",
//   "IDH2",
//   "CERS3",
//   "ALDH4A1",
//   "MNX1",
//   "ERBB2",
//   "ACBD5",
//   "SERPINF1",
//   "FANCG",
//   "MYO5B",
//   "WASF1",
//   "MYOCD",
//   "UFM1",
//   "CDKN1B",
//   "PIGG",
//   "CALCR",
//   "RFWD3",
//   "WDR36",
//   "TAS2R16",
//   "TAP2",
//   "PGAP2",
//   "CBX2",
//   "MAP3K20",
//   "CTNS",
//   "CYP11B2",
//   "ARHGEF18",
//   "CD46",
//   "TTC21A",
//   "CCDC22",
//   "KCNQ5",
//   "GNRHR",
//   "STX11",
//   "PNPLA2",
//   "PLCB1",
//   "PORCN",
//   "FLNA",
//   "P4HA2",
//   "SPINK1",
//   "SLX4",
//   "ATP6V1B1",
//   "SFRP4",
//   "GCM2",
//   "PNKP",
//   "TGFBI",
//   "SKI",
//   "ABCC2",
//   "ATIC",
//   "TUBGCP2",
//   "PAX8",
//   "PLD3",
//   "EDN3",
//   "GP1BB",
//   "SLC12A6",
//   "UNC45B",
//   "CSNK1D",
//   "MBL2",
//   "PTPN14",
//   "RYR1",
//   "COL4A2",
//   "CC2D1A",
//   "RLIM",
//   "EWSR1",
//   "RASGRP1",
//   "TPK1",
//   "MYH11",
//   "GANAB",
//   "WDR34",
//   "HP",
//   "MAPK8IP3",
//   "GFI1",
//   "NPHP3",
//   "CACNA1C",
//   "VIPAS39",
//   "NDUFA13",
//   "AFG3L2",
//   "BEAN1",
//   "IMPA1",
//   "IFT80",
//   "PADI3",
//   "SAMD9L",
//   "MSH2",
//   "CPT1A",
//   "OGDH",
//   "TMX2",
//   "UGP2",
//   "MEIS2",
//   "FBXO31",
//   "MYC",
//   "SLC52A1",
//   "MS4A2",
//   "BSG",
//   "TIMM50",
//   "NAGS",
//   "PRCC",
//   "FOXE3",
//   "PNLIP",
//   "ATP6V1B2",
//   "WNT10A",
//   "AKR1C2",
//   "TBL1X",
//   "HEPACAM",
//   "MOG",
//   "SLC16A2",
//   "TPI1",
//   "NPC1L1",
//   "GOT1",
//   "CTNND1",
//   "C9orf72",
//   "MYMK",
//   "MAB21L1",
//   "SPTBN2",
//   "DNASE1L3",
//   "KCNE1",
//   "FLNC",
//   "STRADA",
//   "ANK2",
//   "COG8",
//   "NANOS1",
//   "BRPF1",
//   "BMPR1A",
//   "SOX6",
//   "POMC",
//   "ADCY3",
//   "SNIP1",
//   "MTOR",
//   "TDRD7",
//   "ANO5",
//   "MAB21L2",
//   "TDP1",
//   "IL2RB",
//   "TRAPPC9",
//   "TRIOBP",
//   "OVOL2",
//   "LMX1B",
//   "HBG1",
//   "YARS2",
//   "EPS8L2",
//   "CDKN2A",
//   "SYNE2",
//   "PRKCD",
//   "EHMT1",
//   "LMBRD1",
//   "NOD2",
//   "CD81",
//   "TRIM36",
//   "MGP",
//   "ACAD8",
//   "KPTN",
//   "ABCG5",
//   "KCNA1",
//   "PDGFRL",
//   "PADI6",
//   "DDOST",
//   "IARS1",
//   "AP3B2",
//   "LEPR",
//   "TCTN3",
//   "GNAO1",
//   "HDAC8",
//   "EPHB4",
//   "EPRS1",
//   "IRF5",
//   "NDUFS1",
//   "C1QBP",
//   "LDHA",
//   "PI4KA",
//   "OAT",
//   "PPIB",
//   "SF3B4",
//   "CR2",
//   "GAS2L2",
//   "RNF139",
//   "CFL2",
//   "HESX1",
//   "DDB2",
//   "MOGS",
//   "PDYN",
//   "MED17",
//   "SPRED1",
//   "FRZB",
//   "BFSP2",
//   "ALK",
// ];

const morbidGenes = [
  "A2M",
  "A2ML1",
  "A4GALT",
  "AAAS",
  "AAGAB",
  "AARS1",
  "AARS2",
  "AASS",
  "ABAT",
  "ABCA1",
  "ABCA12",
  "ABCA2",
  "ABCA3",
  "ABCA4",
  "ABCA7",
  "ABCB11",
  "ABCB4",
  "ABCB6",
  "ABCB7",
  "ABCC2",
  "ABCC6",
  "ABCC8",
  "ABCC9",
  "ABCD1",
  "ABCD4",
  "ABCG5",
  "ABCG8",
  "ABHD12",
  "ABHD16A",
  "ABHD5",
  "ABL1",
  "ABO",
  "ACACA",
  "ACAD8",
  "ACAD9",
  "ACADM",
  "ACADS",
  "ACADSB",
  "ACADVL",
  "ACAN",
  "ACAT1",
  "ACBD5",
  "ACD",
  "ACE",
  "ACE2",
  "ACER3",
  "ACHE",
  "ACKR1",
  "ACO2",
  "ACOX1",
  "ACOX2",
  "ACP4",
  "ACP5",
  "ACSF3",
  "ACSL4",
  "ACSL6",
  "ACTA1",
  "ACTA2",
  "ACTB",
  "ACTC1",
  "ACTG1",
  "ACTG2",
  "ACTL6A",
  "ACTL6B",
  "ACTL9",
  "ACTN1",
  "ACTN2",
  "ACTN4",
  "ACVR1",
  "ACVR1B",
  "ACVR2B",
  "ACVRL1",
  "ACY1",
  "ADA",
  "ADA2",
  "ADAM10",
  "ADAM17",
  "ADAM22",
  "ADAM9",
  "ADAMTS10",
  "ADAMTS13",
  "ADAMTS17",
  "ADAMTS18",
  "ADAMTS19",
  "ADAMTS2",
  "ADAMTS3",
  "ADAMTS9",
  "ADAMTSL2",
  "ADAMTSL4",
  "ADAR",
  "ADARB1",
  "ADAT3",
  "ADCY10",
  "ADCY3",
  "ADCY5",
  "ADCY6",
  "ADD3",
  "ADGRE2",
  "ADGRG1",
  "ADGRG2",
  "ADGRG6",
  "ADGRV1",
  "ADH1B",
  "ADH5",
  "ADIPOQ",
  "ADIPOR1",
  "ADK",
  "ADNP",
  "ADORA2A",
  "ADPRS",
  "ADRB1",
  "ADRB2",
  "ADSL",
  "ADSS1",
  "AEBP1",
  "AFF2",
  "AFF3",
  "AFF4",
  "AFG3L2",
  "AFP",
  "AGA",
  "AGBL1",
  "AGBL5",
  "AGK",
  "AGL",
  "AGMO",
  "AGO1",
  "AGO2",
  "AGPAT2",
  "AGPS",
  "AGR2",
  "AGRN",
  "AGT",
  "AGTPBP1",
  "AGTR1",
  "AGXT",
  "AHCY",
  "AHDC1",
  "AHI1",
  "AICDA",
  "AIFM1",
  "AIM2",
  "AIMP1",
  "AIMP2",
  "AIP",
  "AIPL1",
  "AIRE",
  "AK1",
  "AK2",
  "AKAP9",
  "AKR1C2",
  "AKR1D1",
  "AKT1",
  "AKT2",
  "AKT3",
  "ALAD",
  "ALAS2",
  "ALB",
  "ALDH18A1",
  "ALDH1A2",
  "ALDH1A3",
  "ALDH2",
  "ALDH3A2",
  "ALDH4A1",
  "ALDH5A1",
  "ALDH6A1",
  "ALDH7A1",
  "ALDOA",
  "ALDOB",
  "ALG1",
  "ALG11",
  "ALG12",
  "ALG13",
  "ALG14",
  "ALG2",
  "ALG3",
  "ALG6",
  "ALG8",
  "ALG9",
  "ALK",
  "ALKBH8",
  "ALMS1",
  "ALOX12B",
  "ALOXE3",
  "ALPI",
  "ALPK1",
  "ALPK3",
  "ALPL",
  "ALS2",
  "ALX1",
  "ALX3",
  "ALX4",
  "AMACR",
  "AMBN",
  "AMBRA1",
  "AMELX",
  "AMER1",
  "AMH",
  "AMHR2",
  "AMMECR1",
  "AMN",
  "AMPD1",
  "AMPD2",
  "AMPD3",
  "AMT",
  "ANAPC1",
  "ANAPC7",
  "ANG",
  "ANGPT2",
  "ANGPTL3",
  "ANGPTL4",
  "ANGPTL6",
  "ANK1",
  "ANK2",
  "ANK3",
  "ANKH",
  "ANKLE2",
  "ANKRD1",
  "ANKRD11",
  "ANKRD17",
  "ANKRD26",
  "ANKRD31",
  "ANKS1B",
  "ANKS6",
  "ANLN",
  "ANO10",
  "ANO3",
  "ANO5",
  "ANO6",
  "ANOS1",
  "ANTXR1",
  "ANTXR2",
  "ANXA11",
  "ANXA5",
  "AOPEP",
  "AP1B1",
  "AP1G1",
  "AP1S1",
  "AP1S2",
  "AP1S3",
  "AP2M1",
  "AP2S1",
  "AP3B1",
  "AP3B2",
  "AP3D1",
  "AP4B1",
  "AP4E1",
  "AP4M1",
  "AP4S1",
  "AP5Z1",
  "APC",
  "APC2",
  "APCDD1",
  "APEX1",
  "APOA1",
  "APOA2",
  "APOA5",
  "APOB",
  "APOC2",
  "APOC3",
  "APOC4-APOC2",
  "APOE",
  "APOL1",
  "APP",
  "APPL1",
  "APRT",
  "APTX",
  "AQP1",
  "AQP2",
  "AQP3",
  "AQP5",
  "AR",
  "ARCN1",
  "ARF1",
  "ARFGEF1",
  "ARFGEF2",
  "ARFGEF3",
  "ARG1",
  "ARHGAP26",
  "ARHGAP29",
  "ARHGAP31",
  "ARHGAP32",
  "ARHGDIA",
  "ARHGEF1",
  "ARHGEF10",
  "ARHGEF18",
  "ARHGEF28",
  "ARHGEF9",
  "ARID1A",
  "ARID1B",
  "ARID2",
  "ARIH1",
  "ARL11",
  "ARL13B",
  "ARL2BP",
  "ARL3",
  "ARL6",
  "ARL6IP1",
  "ARMC2",
  "ARMC5",
  "ARMC9",
  "ARMS2",
  "ARPC1B",
  "ARPC4",
  "ARR3",
  "ARSA",
  "ARSB",
  "ARSG",
  "ARSK",
  "ARSL",
  "ART4",
  "ARV1",
  "ARX",
  "ASAH1",
  "ASB10",
  "ASCC1",
  "ASCC3",
  "ASH1L",
  "ASL",
  "ASMT",
  "ASNS",
  "ASPA",
  "ASPH",
  "ASPM",
  "ASPN",
  "ASPRV1",
  "ASPSCR1",
  "ASS1",
  "ASTN1",
  "ASXL1",
  "ASXL2",
  "ASXL3",
  "ATAD1",
  "ATAD3A",
  "ATCAY",
  "ATF1",
  "ATF6",
  "ATG16L1",
  "ATG4D",
  "ATG7",
  "ATIC",
  "ATL1",
  "ATL3",
  "ATM",
  "ATN1",
  "ATOH7",
  "ATP10B",
  "ATP13A2",
  "ATP13A3",
  "ATP1A1",
  "ATP1A2",
  "ATP1A3",
  "ATP2A1",
  "ATP2A2",
  "ATP2A3",
  "ATP2B2",
  "ATP2B3",
  "ATP2C1",
  "ATP5F1A",
  "ATP5F1D",
  "ATP5MC3",
  "ATP5MK",
  "ATP6AP1",
  "ATP6AP2",
  "ATP6V0A1",
  "ATP6V0A2",
  "ATP6V0A4",
  "ATP6V1A",
  "ATP6V1B1",
  "ATP6V1B2",
  "ATP6V1E1",
  "ATP7A",
  "ATP7B",
  "ATP8A2",
  "ATP8B1",
  "ATP9A",
  "ATPAF2",
  "ATR",
  "ATRX",
  "ATXN1",
  "ATXN10",
  "ATXN2",
  "ATXN3",
  "ATXN7",
  "ATXN8OS",
  "AUH",
  "AURKC",
  "AUTS2",
  "AVIL",
  "AVP",
  "AVPR2",
  "AXIN1",
  "AXIN2",
  "AXL",
  "B2M",
  "B3GALNT1",
  "B3GALNT2",
  "B3GALT6",
  "B3GAT3",
  "B3GLCT",
  "B4GALNT1",
  "B4GALNT2",
  "B4GALT1",
  "B4GALT7",
  "B4GAT1",
  "B9D1",
  "B9D2",
  "BAAT",
  "BACH2",
  "BAG3",
  "BANF1",
  "BAP1",
  "BARD1",
  "BAX",
  "BAZ2B",
  "BBS1",
  "BBS10",
  "BBS12",
  "BBS2",
  "BBS4",
  "BBS5",
  "BBS7",
  "BBS9",
  "BCAP31",
  "BCAS3",
  "BCAT2",
  "BCHE",
  "BCKDHA",
  "BCKDHB",
  "BCKDK",
  "BCL10",
  "BCL11A",
  "BCL11B",
  "BCL2",
  "BCL6",
  "BCL7A",
  "BCOR",
  "BCORL1",
  "BCR",
  "BCS1L",
  "BEAN1",
  "BEST1",
  "BFSP1",
  "BFSP2",
  "BGN",
  "BHLHA9",
  "BHLHE41",
  "BICD2",
  "BICRA",
  "BIN1",
  "BIRC3",
  "BLK",
  "BLM",
  "BLNK",
  "BLOC1S1",
  "BLOC1S3",
  "BLOC1S5",
  "BLOC1S6",
  "BLVRA",
  "BMP1",
  "BMP15",
  "BMP2",
  "BMP4",
  "BMP6",
  "BMP7",
  "BMPER",
  "BMPR1A",
  "BMPR1B",
  "BMPR2",
  "BNC1",
  "BNC2",
  "BOD1",
  "BOLA3",
  "BPGM",
  "BPNT2",
  "BPTF",
  "BRAF",
  "BRAT1",
  "BRCA1",
  "BRCA2",
  "BRD4",
  "BRF1",
  "BRIP1",
  "BRPF1",
  "BRSK2",
  "BRWD1",
  "BRWD3",
  "BSCL2",
  "BSN",
  "BSND",
  "BTD",
  "BTG4",
  "BTK",
  "BTLA",
  "BTNL2",
  "BUB1",
  "BUB1B",
  "BUB3",
  "BUD23",
  "BVES",
  "C11orf80",
  "C12orf4",
  "C12orf57",
  "C14orf39",
  "C19orf12",
  "C1GALT1C1",
  "C1QA",
  "C1QB",
  "C1QBP",
  "C1QC",
  "C1QTNF5",
  "C1R",
  "C1S",
  "C2",
  "C2CD3",
  "C2orf69",
  "C3",
  "C4A",
  "C4B",
  "C5",
  "C6",
  "C7",
  "C8A",
  "C8B",
  "C8G",
  "C9",
  "C9orf72",
  "CA12",
  "CA2",
  "CA4",
  "CA5A",
  "CA8",
  "CABIN1",
  "CABP2",
  "CABP4",
  "CACNA1A",
  "CACNA1B",
  "CACNA1C",
  "CACNA1D",
  "CACNA1E",
  "CACNA1F",
  "CACNA1G",
  "CACNA1H",
  "CACNA1I",
  "CACNA1S",
  "CACNA2D2",
  "CACNA2D4",
  "CACNB2",
  "CACNB4",
  "CAD",
  "CADM3",
  "CALHM1",
  "CALM1",
  "CALM2",
  "CALM3",
  "CALR",
  "CAMK2A",
  "CAMK2B",
  "CAMK2G",
  "CAMK4",
  "CAMTA1",
  "CANT1",
  "CAPN1",
  "CAPN10",
  "CAPN15",
  "CAPN3",
  "CAPN5",
  "CARD11",
  "CARD14",
  "CARD9",
  "CARMIL2",
  "CARS1",
  "CARS2",
  "CASK",
  "CASP1",
  "CASP10",
  "CASP14",
  "CASP3",
  "CASP8",
  "CASQ1",
  "CASQ2",
  "CASR",
  "CAST",
  "CAT",
  "CATSPER1",
  "CATSPER2",
  "CAV1",
  "CAV3",
  "CAVIN1",
  "CAVIN4",
  "CBL",
  "CBLB",
  "CBLIF",
  "CBS",
  "CBY1",
  "CC2D1A",
  "CC2D2A",
  "CCBE1",
  "CCDC103",
  "CCDC115",
  "CCDC174",
  "CCDC22",
  "CCDC32",
  "CCDC39",
  "CCDC40",
  "CCDC47",
  "CCDC50",
  "CCDC6",
  "CCDC65",
  "CCDC78",
  "CCDC8",
  "CCDC82",
  "CCDC88A",
  "CCDC88C",
  "CCL2",
  "CCL5",
  "CCM2",
  "CCN6",
  "CCND1",
  "CCND2",
  "CCNF",
  "CCNO",
  "CCNQ",
  "CCR2",
  "CCR5",
  "CCT5",
  "CD14",
  "CD151",
  "CD160",
  "CD163",
  "CD19",
  "CD2",
  "CD209",
  "CD244",
  "CD247",
  "CD27",
  "CD274",
  "CD28",
  "CD2AP",
  "CD320",
  "CD36",
  "CD38",
  "CD3D",
  "CD3E",
  "CD3G",
  "CD4",
  "CD40",
  "CD40LG",
  "CD46",
  "CD55",
  "CD59",
  "CD70",
  "CD79A",
  "CD79B",
  "CD80",
  "CD81",
  "CD86",
  "CD8A",
  "CD8B",
  "CD96",
  "CDAN1",
  "CDC14A",
  "CDC20",
  "CDC42",
  "CDC42BPB",
  "CDC45",
  "CDC6",
  "CDC73",
  "CDCA7",
  "CDCA8",
  "CDH1",
  "CDH11",
  "CDH15",
  "CDH2",
  "CDH23",
  "CDH3",
  "CDHR1",
  "CDIN1",
  "CDK10",
  "CDK13",
  "CDK19",
  "CDK4",
  "CDK5RAP2",
  "CDK6",
  "CDK8",
  "CDK9",
  "CDKL5",
  "CDKN1B",
  "CDKN1C",
  "CDKN2A",
  "CDKN2B",
  "CDON",
  "CDSN",
  "CDT1",
  "CDX1",
  "CEACAM16",
  "CEBPA",
  "CEBPE",
  "CEL",
  "CELA2A",
  "CELF2",
  "CELSR1",
  "CELSR2",
  "CELSR3",
  "CEMIP",
  "CENPE",
  "CENPF",
  "CENPJ",
  "CEP104",
  "CEP112",
  "CEP120",
  "CEP135",
  "CEP152",
  "CEP164",
  "CEP19",
  "CEP250",
  "CEP290",
  "CEP41",
  "CEP55",
  "CEP57",
  "CEP63",
  "CEP78",
  "CEP83",
  "CEP85L",
  "CERKL",
  "CERS1",
  "CERS3",
  "CERT1",
  "CES1",
  "CETP",
  "CFAP251",
  "CFAP298",
  "CFAP300",
  "CFAP410",
  "CFAP418",
  "CFAP43",
  "CFAP44",
  "CFAP45",
  "CFAP47",
  "CFAP52",
  "CFAP53",
  "CFAP58",
  "CFAP65",
  "CFAP69",
  "CFAP74",
  "CFAP91",
  "CFB",
  "CFC1",
  "CFD",
  "CFH",
  "CFHR1",
  "CFHR2",
  "CFHR3",
  "CFHR4",
  "CFHR5",
  "CFI",
  "CFL2",
  "CFP",
  "CFTR",
  "CHAMP1",
  "CHAT",
  "CHCHD10",
  "CHCHD2",
  "CHD1",
  "CHD1L",
  "CHD2",
  "CHD3",
  "CHD4",
  "CHD5",
  "CHD7",
  "CHD8",
  "CHEK1",
  "CHEK2",
  "CHIT1",
  "CHKB",
  "CHM",
  "CHMP1A",
  "CHMP2B",
  "CHMP4B",
  "CHN1",
  "CHP1",
  "CHRDL1",
  "CHRM2",
  "CHRM3",
  "CHRNA1",
  "CHRNA2",
  "CHRNA3",
  "CHRNA4",
  "CHRNB1",
  "CHRNB2",
  "CHRND",
  "CHRNE",
  "CHRNG",
  "CHST14",
  "CHST3",
  "CHST6",
  "CHSY1",
  "CHUK",
  "CIB1",
  "CIB2",
  "CIC",
  "CIITA",
  "CILK1",
  "CILP",
  "CISD2",
  "CISH",
  "CIT",
  "CITED2",
  "CKAP2L",
  "CLCC1",
  "CLCF1",
  "CLCN1",
  "CLCN2",
  "CLCN3",
  "CLCN4",
  "CLCN5",
  "CLCN6",
  "CLCN7",
  "CLCNKA",
  "CLCNKB",
  "CLDN1",
  "CLDN10",
  "CLDN11",
  "CLDN14",
  "CLDN16",
  "CLDN19",
  "CLDN9",
  "CLEC7A",
  "CLMP",
  "CLN3",
  "CLN5",
  "CLN6",
  "CLN8",
  "CLP1",
  "CLPB",
  "CLPP",
  "CLRN1",
  "CLTC",
  "CLU",
  "CLUAP1",
  "CNBP",
  "CNGA1",
  "CNGA3",
  "CNGB1",
  "CNGB3",
  "CNKSR1",
  "CNKSR2",
  "CNNM2",
  "CNNM4",
  "CNOT1",
  "CNOT2",
  "CNOT3",
  "CNOT9",
  "CNP",
  "CNPY3",
  "CNTN1",
  "CNTN2",
  "CNTNAP1",
  "CNTNAP2",
  "COA5",
  "COA6",
  "COA7",
  "COA8",
  "COASY",
  "COCH",
  "COG1",
  "COG2",
  "COG4",
  "COG5",
  "COG6",
  "COG7",
  "COG8",
  "COL10A1",
  "COL11A1",
  "COL11A2",
  "COL12A1",
  "COL13A1",
  "COL17A1",
  "COL18A1",
  "COL1A1",
  "COL1A2",
  "COL25A1",
  "COL27A1",
  "COL2A1",
  "COL3A1",
  "COL4A1",
  "COL4A2",
  "COL4A3",
  "COL4A4",
  "COL4A5",
  "COL4A6",
  "COL5A1",
  "COL5A2",
  "COL6A1",
  "COL6A2",
  "COL6A3",
  "COL7A1",
  "COL8A2",
  "COL9A1",
  "COL9A2",
  "COL9A3",
  "COLEC10",
  "COLEC11",
  "COLGALT1",
  "COLQ",
  "COMP",
  "COPA",
  "COPB1",
  "COPB2",
  "COQ2",
  "COQ4",
  "COQ6",
  "COQ7",
  "COQ8A",
  "COQ8B",
  "COQ9",
  "CORIN",
  "CORO1A",
  "COX10",
  "COX14",
  "COX15",
  "COX16",
  "COX20",
  "COX4I1",
  "COX4I2",
  "COX6A1",
  "COX6A2",
  "COX6B1",
  "COX7B",
  "CP",
  "CPA1",
  "CPA6",
  "CPAMD8",
  "CPB1",
  "CPE",
  "CPLANE1",
  "CPLX1",
  "CPN1",
  "CPOX",
  "CPS1",
  "CPSF1",
  "CPT1A",
  "CPT1C",
  "CPT2",
  "CR2",
  "CRADD",
  "CRB1",
  "CRB2",
  "CRBN",
  "CREB1",
  "CREB3L1",
  "CREB3L2",
  "CREB3L3",
  "CREBBP",
  "CRELD1",
  "CRIPT",
  "CRLF1",
  "CRPPA",
  "CRTAP",
  "CRTC1",
  "CRX",
  "CRY1",
  "CRYAA",
  "CRYAB",
  "CRYBA1",
  "CRYBA4",
  "CRYBB1",
  "CRYBB2",
  "CRYBB3",
  "CRYGB",
  "CRYGC",
  "CRYGD",
  "CRYGS",
  "CRYM",
  "CSDE1",
  "CSF1R",
  "CSF2RA",
  "CSF2RB",
  "CSF3R",
  "CSGALNACT1",
  "CSNK1D",
  "CSNK1G1",
  "CSNK2A1",
  "CSNK2B",
  "CSPP1",
  "CSRP3",
  "CST3",
  "CSTA",
  "CSTB",
  "CTBP1",
  "CTC1",
  "CTCF",
  "CTDP1",
  "CTH",
  "CTHRC1",
  "CTLA4",
  "CTNNA1",
  "CTNNA2",
  "CTNNA3",
  "CTNNB1",
  "CTNND1",
  "CTNND2",
  "CTNS",
  "CTPS1",
  "CTR9",
  "CTRC",
  "CTSA",
  "CTSC",
  "CTSD",
  "CTSF",
  "CTSK",
  "CTU2",
  "CUBN",
  "CUL3",
  "CUL4B",
  "CUL7",
  "CUX1",
  "CUX2",
  "CWC27",
  "CWF19L1",
  "CXCL10",
  "CXCL8",
  "CXCL9",
  "CXCR1",
  "CXCR4",
  "CXCR6",
  "CYB561",
  "CYB5A",
  "CYB5R3",
  "CYBA",
  "CYBB",
  "CYBC1",
  "CYBRD1",
  "CYC1",
  "CYCS",
  "CYFIP2",
  "CYLD",
  "CYP11A1",
  "CYP11B1",
  "CYP11B2",
  "CYP17A1",
  "CYP19A1",
  "CYP1A2",
  "CYP1B1",
  "CYP21A2",
  "CYP24A1",
  "CYP26B1",
  "CYP26C1",
  "CYP27A1",
  "CYP27B1",
  "CYP2A6",
  "CYP2B6",
  "CYP2C19",
  "CYP2C9",
  "CYP2D6",
  "CYP2R1",
  "CYP2U1",
  "CYP3A4",
  "CYP3A5",
  "CYP4F22",
  "CYP4V2",
  "CYP51A1",
  "CYP7A1",
  "CYP7B1",
  "D2HGDH",
  "DAAM2",
  "DAB1",
  "DACT1",
  "DAG1",
  "DAO",
  "DARS1",
  "DARS2",
  "DAXX",
  "DAZ1",
  "DAZ2",
  "DAZ3",
  "DAZ4",
  "DBH",
  "DBR1",
  "DBT",
  "DCAF17",
  "DCC",
  "DCDC2",
  "DCHS1",
  "DCLRE1B",
  "DCLRE1C",
  "DCN",
  "DCPS",
  "DCT",
  "DCTN1",
  "DCX",
  "DCXR",
  "DDB1",
  "DDB2",
  "DDC",
  "DDHD1",
  "DDHD2",
  "DDIT3",
  "DDOST",
  "DDR2",
  "DDRGK1",
  "DDX11",
  "DDX23",
  "DDX24",
  "DDX3X",
  "DDX41",
  "DDX54",
  "DDX58",
  "DDX59",
  "DDX6",
  "DEAF1",
  "DECR1",
  "DEF6",
  "DEGS1",
  "DENND5A",
  "DEPDC5",
  "DES",
  "DGAT1",
  "DGKE",
  "DGUOK",
  "DHCR24",
  "DHCR7",
  "DHDDS",
  "DHFR",
  "DHH",
  "DHODH",
  "DHPS",
  "DHTKD1",
  "DHX16",
  "DHX30",
  "DHX37",
  "DHX38",
  "DIABLO",
  "DIAPH1",
  "DIAPH3",
  "DICER1",
  "DIO1",
  "DIP2B",
  "DIRC3",
  "DIS3L2",
  "DISC1",
  "DISP1",
  "DKC1",
  "DLAT",
  "DLC1",
  "DLD",
  "DLG2",
  "DLG3",
  "DLG4",
  "DLG5",
  "DLK1",
  "DLL1",
  "DLL3",
  "DLL4",
  "DLST",
  "DLX3",
  "DLX5",
  "DMC1",
  "DMD",
  "DMGDH",
  "DMP1",
  "DMPK",
  "DMXL2",
  "DNA2",
  "DNAAF1",
  "DNAAF11",
  "DNAAF2",
  "DNAAF3",
  "DNAAF4",
  "DNAAF5",
  "DNAAF6",
  "DNAH1",
  "DNAH10",
  "DNAH11",
  "DNAH17",
  "DNAH2",
  "DNAH5",
  "DNAH6",
  "DNAH8",
  "DNAH9",
  "DNAI1",
  "DNAI2",
  "DNAJB11",
  "DNAJB13",
  "DNAJB2",
  "DNAJB6",
  "DNAJC12",
  "DNAJC13",
  "DNAJC19",
  "DNAJC21",
  "DNAJC3",
  "DNAJC30",
  "DNAJC5",
  "DNAJC6",
  "DNAJC7",
  "DNAL1",
  "DNASE1",
  "DNASE1L3",
  "DNASE2",
  "DNHD1",
  "DNM1",
  "DNM1L",
  "DNM2",
  "DNMBP",
  "DNMT1",
  "DNMT3A",
  "DNMT3B",
  "DOCK2",
  "DOCK3",
  "DOCK6",
  "DOCK7",
  "DOCK8",
  "DOHH",
  "DOK7",
  "DOLK",
  "DONSON",
  "DPAGT1",
  "DPF2",
  "DPH1",
  "DPM1",
  "DPM2",
  "DPM3",
  "DPP6",
  "DPY19L2",
  "DPYD",
  "DPYS",
  "DPYSL5",
  "DRAM2",
  "DRC1",
  "DRD2",
  "DRD3",
  "DRD4",
  "DRP2",
  "DSC2",
  "DSC3",
  "DSCAM",
  "DSE",
  "DSG1",
  "DSG2",
  "DSG3",
  "DSG4",
  "DSP",
  "DSPP",
  "DST",
  "DSTYK",
  "DTNA",
  "DTNBP1",
  "DUOX1",
  "DUOX2",
  "DUOXA2",
  "DUSP6",
  "DVL1",
  "DVL3",
  "DYM",
  "DYNC1H1",
  "DYNC1I1",
  "DYNC1I2",
  "DYNC2H1",
  "DYNC2I1",
  "DYNC2I2",
  "DYNC2LI1",
  "DYNLT2B",
  "DYRK1A",
  "DYRK1B",
  "DYSF",
  "DZIP1",
  "DZIP1L",
  "EARS2",
  "EBF3",
  "EBP",
  "ECE1",
  "ECEL1",
  "ECHS1",
  "ECM1",
  "EDA",
  "EDAR",
  "EDARADD",
  "EDEM3",
  "EDN1",
  "EDN3",
  "EDNRA",
  "EDNRB",
  "EED",
  "EEF1A2",
  "EEF1B2",
  "EEF2",
  "EFEMP1",
  "EFEMP2",
  "EFHC1",
  "EFL1",
  "EFNA4",
  "EFNB1",
  "EFTUD2",
  "EGFR",
  "EGLN1",
  "EGR2",
  "EHBP1",
  "EHMT1",
  "EIF2AK2",
  "EIF2AK3",
  "EIF2AK4",
  "EIF2B1",
  "EIF2B2",
  "EIF2B3",
  "EIF2B4",
  "EIF2B5",
  "EIF2S3",
  "EIF3F",
  "EIF4A3",
  "EIF4E",
  "EIF4G1",
  "EIF5A",
  "ELAC2",
  "ELANE",
  "ELMO2",
  "ELN",
  "ELOVL1",
  "ELOVL4",
  "ELOVL5",
  "ELP1",
  "ELP2",
  "EMC1",
  "EMC10",
  "EMD",
  "EMG1",
  "EML1",
  "EMP2",
  "EMX2",
  "EN1",
  "ENAM",
  "ENG",
  "ENO3",
  "ENPP1",
  "ENTPD1",
  "EOGT",
  "EOMES",
  "EP300",
  "EPAS1",
  "EPB41",
  "EPB42",
  "EPCAM",
  "EPG5",
  "EPHA2",
  "EPHA3",
  "EPHB2",
  "EPHB4",
  "EPM2A",
  "EPO",
  "EPOR",
  "EPRS1",
  "EPS8",
  "EPS8L2",
  "EPX",
  "ERAL1",
  "ERBB2",
  "ERBB3",
  "ERBB4",
  "ERBIN",
  "ERC1",
  "ERCC1",
  "ERCC2",
  "ERCC3",
  "ERCC4",
  "ERCC5",
  "ERCC6",
  "ERCC6L2",
  "ERCC8",
  "ERF",
  "ERGIC1",
  "ERLEC1",
  "ERLIN1",
  "ERLIN2",
  "ESCO2",
  "ESM1",
  "ESPN",
  "ESR1",
  "ESRRB",
  "ETFA",
  "ETFB",
  "ETFDH",
  "ETHE1",
  "ETV1",
  "ETV6",
  "EVC",
  "EVC2",
  "EWSR1",
  "EXOC2",
  "EXOC3L2",
  "EXOC6B",
  "EXOC7",
  "EXOSC2",
  "EXOSC3",
  "EXOSC5",
  "EXOSC8",
  "EXOSC9",
  "EXPH5",
  "EXT1",
  "EXT2",
  "EXTL3",
  "EYA1",
  "EYA4",
  "EYS",
  "EZH2",
  "F10",
  "F11",
  "F12",
  "F13A1",
  "F13B",
  "F2",
  "F5",
  "F7",
  "F8",
  "F9",
  "FA2H",
  "FAAP20",
  "FAAP24",
  "FADD",
  "FAH",
  "FAM111A",
  "FAM111B",
  "FAM126A",
  "FAM149B1",
  "FAM161A",
  "FAM20A",
  "FAM20C",
  "FAM50A",
  "FAM83H",
  "FAN1",
  "FANCA",
  "FANCB",
  "FANCC",
  "FANCD2",
  "FANCE",
  "FANCF",
  "FANCG",
  "FANCI",
  "FANCL",
  "FANCM",
  "FAP",
  "FAR1",
  "FARS2",
  "FARSA",
  "FARSB",
  "FAS",
  "FASLG",
  "FASTKD2",
  "FAT1",
  "FAT2",
  "FAT4",
  "FBLN5",
  "FBN1",
  "FBN2",
  "FBP1",
  "FBRSL1",
  "FBXL3",
  "FBXL4",
  "FBXO11",
  "FBXO28",
  "FBXO31",
  "FBXO38",
  "FBXO43",
  "FBXO7",
  "FBXW11",
  "FBXW7",
  "FCGR2A",
  "FCGR3A",
  "FCGR3B",
  "FCHO1",
  "FCN3",
  "FCSK",
  "FDFT1",
  "FDPS",
  "FDX2",
  "FDXR",
  "FECH",
  "FEM1B",
  "FERMT1",
  "FERMT3",
  "FEZF1",
  "FFAR4",
  "FGA",
  "FGB",
  "FGD1",
  "FGD4",
  "FGF10",
  "FGF12",
  "FGF13",
  "FGF14",
  "FGF16",
  "FGF17",
  "FGF23",
  "FGF3",
  "FGF5",
  "FGF8",
  "FGF9",
  "FGFR1",
  "FGFR2",
  "FGFR3",
  "FGFR4",
  "FGG",
  "FH",
  "FHL1",
  "FHOD3",
  "FIBP",
  "FIG4",
  "FIGLA",
  "FIP1L1",
  "FITM2",
  "FKBP10",
  "FKBP14",
  "FKBP4",
  "FKRP",
  "FKTN",
  "FLAD1",
  "FLCN",
  "FLG",
  "FLG2",
  "FLI1",
  "FLII",
  "FLNA",
  "FLNB",
  "FLNC",
  "FLRT3",
  "FLT3",
  "FLT4",
  "FLVCR1",
  "FLVCR2",
  "FMN2",
  "FMO3",
  "FMR1",
  "FN1",
  "FNIP1",
  "FOLR1",
  "FOXA2",
  "FOXC1",
  "FOXC2",
  "FOXD3",
  "FOXE1",
  "FOXE3",
  "FOXF1",
  "FOXG1",
  "FOXH1",
  "FOXI1",
  "FOXJ1",
  "FOXL2",
  "FOXN1",
  "FOXO1",
  "FOXP1",
  "FOXP2",
  "FOXP3",
  "FOXP4",
  "FOXRED1",
  "FPR1",
  "FPR2",
  "FRA10AC1",
  "FRAS1",
  "FREM1",
  "FREM2",
  "FRMD4A",
  "FRMD7",
  "FRMPD4",
  "FRRS1L",
  "FRY",
  "FRZB",
  "FSCN2",
  "FSHB",
  "FSHR",
  "FSIP2",
  "FTCD",
  "FTL",
  "FTO",
  "FTSJ1",
  "FUCA1",
  "FUS",
  "FUT1",
  "FUT2",
  "FUT8",
  "FUZ",
  "FXN",
  "FXR1",
  "FXYD2",
  "FXYD6",
  "FYB1",
  "FYCO1",
  "FZD2",
  "FZD4",
  "FZD5",
  "FZD6",
  "FZR1",
  "G6PC1",
  "G6PC3",
  "G6PD",
  "GAA",
  "GABBR2",
  "GABRA1",
  "GABRA2",
  "GABRA3",
  "GABRA5",
  "GABRB1",
  "GABRB2",
  "GABRB3",
  "GABRD",
  "GABRE",
  "GABRG2",
  "GAD1",
  "GALC",
  "GALE",
  "GALK1",
  "GALM",
  "GALNS",
  "GALNT12",
  "GALNT2",
  "GALNT3",
  "GALT",
  "GAMT",
  "GAN",
  "GANAB",
  "GARS1",
  "GAS2L2",
  "GAS8",
  "GATA1",
  "GATA2",
  "GATA3",
  "GATA4",
  "GATA5",
  "GATA6",
  "GATAD1",
  "GATAD2B",
  "GATC",
  "GATM",
  "GBA",
  "GBA2",
  "GBE1",
  "GBF1",
  "GCDH",
  "GCGR",
  "GCH1",
  "GCK",
  "GCKR",
  "GCLC",
  "GCM2",
  "GCNA",
  "GCNT2",
  "GCSH",
  "GDAP1",
  "GDAP2",
  "GDF1",
  "GDF11",
  "GDF2",
  "GDF3",
  "GDF5",
  "GDF6",
  "GDF9",
  "GDI1",
  "GDNF",
  "GEMIN4",
  "GEMIN5",
  "GFAP",
  "GFER",
  "GFI1",
  "GFI1B",
  "GFM1",
  "GFM2",
  "GFPT1",
  "GFRA1",
  "GGCX",
  "GGPS1",
  "GH1",
  "GHR",
  "GHRHR",
  "GHSR",
  "GIGYF2",
  "GIMAP5",
  "GINS1",
  "GIPC1",
  "GIPC3",
  "GIT1",
  "GJA1",
  "GJA3",
  "GJA4",
  "GJA5",
  "GJA8",
  "GJB1",
  "GJB2",
  "GJB3",
  "GJB4",
  "GJB6",
  "GJC2",
  "GJC3",
  "GK",
  "GLA",
  "GLB1",
  "GLCCI1",
  "GLDC",
  "GLDN",
  "GLE1",
  "GLI1",
  "GLI2",
  "GLI3",
  "GLIS2",
  "GLIS3",
  "GLMN",
  "GLRA1",
  "GLRA2",
  "GLRB",
  "GLRX5",
  "GLS",
  "GLT8D1",
  "GLUD1",
  "GLUL",
  "GLYCTK",
  "GM2A",
  "GMNN",
  "GMPPA",
  "GMPPB",
  "GNA11",
  "GNA14",
  "GNAI1",
  "GNAI2",
  "GNAI3",
  "GNAL",
  "GNAO1",
  "GNAQ",
  "GNAS",
  "GNAS-AS1",
  "GNAT1",
  "GNAT2",
  "GNB1",
  "GNB2",
  "GNB3",
  "GNB4",
  "GNB5",
  "GNE",
  "GNMT",
  "GNPAT",
  "GNPTAB",
  "GNPTG",
  "GNRH1",
  "GNRHR",
  "GNS",
  "GOLGA2",
  "GOLGA5",
  "GON7",
  "GORAB",
  "GOSR2",
  "GOT1",
  "GOT2",
  "GP1BA",
  "GP1BB",
  "GP6",
  "GP9",
  "GPAA1",
  "GPATCH3",
  "GPC3",
  "GPC4",
  "GPC6",
  "GPD1",
  "GPD1L",
  "GPHN",
  "GPI",
  "GPIHBP1",
  "GPNMB",
  "GPR101",
  "GPR143",
  "GPR161",
  "GPR179",
  "GPR68",
  "GPSM2",
  "GPT2",
  "GPX4",
  "GRAP",
  "GRB10",
  "GREB1L",
  "GREM1",
  "GREM2",
  "GRHL2",
  "GRHL3",
  "GRHPR",
  "GRIA1",
  "GRIA2",
  "GRIA3",
  "GRIA4",
  "GRID2",
  "GRIK2",
  "GRIN1",
  "GRIN2A",
  "GRIN2B",
  "GRIN2C",
  "GRIN2D",
  "GRIN3A",
  "GRIN3B",
  "GRIP1",
  "GRK1",
  "GRM1",
  "GRM6",
  "GRM7",
  "GRN",
  "GRXCR1",
  "GSC",
  "GSDME",
  "GSN",
  "GSR",
  "GSS",
  "GSX2",
  "GTF2E2",
  "GTF2H5",
  "GTF3C3",
  "GTPBP2",
  "GTPBP3",
  "GUCA1A",
  "GUCA1B",
  "GUCY1A1",
  "GUCY2C",
  "GUCY2D",
  "GUSB",
  "GYG1",
  "GYPA",
  "GYPB",
  "GYS1",
  "GYS2",
  "GZF1",
  "GZMA",
  "GZMB",
  "H1-4",
  "H19",
  "H3-3A",
  "H3-3B",
  "H4C3",
  "H6PD",
  "HAAO",
  "HACD1",
  "HACE1",
  "HADH",
  "HADHA",
  "HADHB",
  "HAL",
  "HAMP",
  "HAND1",
  "HAO1",
  "HARS1",
  "HARS2",
  "HAVCR1",
  "HAVCR2",
  "HAX1",
  "HBA1",
  "HBA2",
  "HBB",
  "HBD",
  "HBG1",
  "HBG2",
  "HCCS",
  "HCFC1",
  "HCN1",
  "HCN2",
  "HCN4",
  "HCRT",
  "HDAC4",
  "HDAC6",
  "HDAC8",
  "HECW2",
  "HELLS",
  "HEPACAM",
  "HERC1",
  "HERC2",
  "HES7",
  "HESX1",
  "HEXA",
  "HEXB",
  "HFE",
  "HFM1",
  "HGD",
  "HGF",
  "HGSNAT",
  "HHAT",
  "HIBCH",
  "HID1",
  "HIKESHI",
  "HINT1",
  "HIRA",
  "HIVEP2",
  "HJV",
  "HK1",
  "HKDC1",
  "HLA-A",
  "HLA-B",
  "HLA-DRB1",
  "HLCS",
  "HMBS",
  "HMGA2",
  "HMGB1",
  "HMGCL",
  "HMGCS2",
  "HMOX1",
  "HMX1",
  "HNF1A",
  "HNF1B",
  "HNF4A",
  "HNMT",
  "HNRNPA1",
  "HNRNPD",
  "HNRNPDL",
  "HNRNPH1",
  "HNRNPH2",
  "HNRNPK",
  "HNRNPR",
  "HNRNPU",
  "HNRNPUL2",
  "HOGA1",
  "HOMER2",
  "HOXA1",
  "HOXA11",
  "HOXA13",
  "HOXA2",
  "HOXB1",
  "HOXB13",
  "HOXC13",
  "HOXD10",
  "HOXD13",
  "HP",
  "HPCA",
  "HPD",
  "HPDL",
  "HPGD",
  "HPRT1",
  "HPS1",
  "HPS3",
  "HPS4",
  "HPS5",
  "HPS6",
  "HPSE2",
  "HR",
  "HRAS",
  "HRG",
  "HS2ST1",
  "HS6ST1",
  "HSD11B1",
  "HSD11B2",
  "HSD17B10",
  "HSD17B3",
  "HSD17B4",
  "HSD3B2",
  "HSD3B7",
  "HSF2BP",
  "HSF4",
  "HSPA1L",
  "HSPA9",
  "HSPB1",
  "HSPB8",
  "HSPD1",
  "HSPG2",
  "HTR1A",
  "HTRA1",
  "HTRA2",
  "HTT",
  "HUWE1",
  "HYAL1",
  "HYAL2",
  "HYDIN",
  "HYLS1",
  "HYOU1",
  "IARS1",
  "IARS2",
  "IBA57",
  "ICAM4",
  "ICOS",
  "ICOSLG",
  "IDH1",
  "IDH2",
  "IDH3A",
  "IDH3B",
  "IDO1",
  "IDS",
  "IDUA",
  "IER3IP1",
  "IFIH1",
  "IFITM3",
  "IFITM5",
  "IFNA1",
  "IFNAR1",
  "IFNAR2",
  "IFNE",
  "IFNG",
  "IFNGR1",
  "IFNGR2",
  "IFT122",
  "IFT140",
  "IFT172",
  "IFT27",
  "IFT43",
  "IFT52",
  "IFT74",
  "IFT80",
  "IFT81",
  "IGF1",
  "IGF1R",
  "IGF2",
  "IGF2R",
  "IGFALS",
  "IGFBP7",
  "IGHMBP2",
  "IGLL1",
  "IGSF1",
  "IGSF10",
  "IHH",
  "IKBKB",
  "IKBKG",
  "IKZF1",
  "IKZF2",
  "IKZF5",
  "IL10",
  "IL10RA",
  "IL10RB",
  "IL11RA",
  "IL12B",
  "IL12RB1",
  "IL12RB2",
  "IL13",
  "IL17A",
  "IL17F",
  "IL17RA",
  "IL17RC",
  "IL17RD",
  "IL18BP",
  "IL1B",
  "IL1RAPL1",
  "IL1RN",
  "IL21",
  "IL21R",
  "IL23R",
  "IL2RA",
  "IL2RB",
  "IL2RG",
  "IL31RA",
  "IL36RN",
  "IL37",
  "IL4",
  "IL6",
  "IL6R",
  "IL6ST",
  "IL7R",
  "ILDR1",
  "ILF2",
  "ILK",
  "IMPA1",
  "IMPDH1",
  "IMPDH2",
  "IMPG1",
  "IMPG2",
  "INF2",
  "ING1",
  "INHA",
  "INO80",
  "INPP4A",
  "INPP5E",
  "INPP5K",
  "INPPL1",
  "INS",
  "INSL3",
  "INSR",
  "INTS1",
  "INTS8",
  "INTU",
  "INVS",
  "IPO8",
  "IPP",
  "IQCB1",
  "IQCE",
  "IQSEC1",
  "IQSEC2",
  "IRAK1",
  "IRAK4",
  "IREB2",
  "IRF1",
  "IRF2",
  "IRF2BP2",
  "IRF2BPL",
  "IRF3",
  "IRF4",
  "IRF5",
  "IRF6",
  "IRF7",
  "IRF8",
  "IRF9",
  "IRS1",
  "IRS4",
  "IRX5",
  "ISCA1",
  "ISCA2",
  "ISCU",
  "ISG15",
  "ISL1",
  "ITCH",
  "ITGA2",
  "ITGA2B",
  "ITGA3",
  "ITGA6",
  "ITGA7",
  "ITGA8",
  "ITGAE",
  "ITGAV",
  "ITGB2",
  "ITGB3",
  "ITGB4",
  "ITGB6",
  "ITK",
  "ITM2B",
  "ITPA",
  "ITPKC",
  "ITPR1",
  "ITSN1",
  "ITSN2",
  "IVD",
  "IVNS1ABP",
  "IYD",
  "JAG1",
  "JAG2",
  "JAGN1",
  "JAK1",
  "JAK2",
  "JAK3",
  "JAM2",
  "JAM3",
  "JARID2",
  "JMJD1C",
  "JPH2",
  "JPH3",
  "JPT1",
  "JUP",
  "KAAG1",
  "KANK1",
  "KANK2",
  "KANSL1",
  "KARS1",
  "KAT5",
  "KAT6A",
  "KAT6B",
  "KAT8",
  "KATNAL2",
  "KATNB1",
  "KATNIP",
  "KBTBD13",
  "KCNA1",
  "KCNA2",
  "KCNA4",
  "KCNA5",
  "KCNB1",
  "KCNC1",
  "KCNC3",
  "KCND2",
  "KCND3",
  "KCNE1",
  "KCNE2",
  "KCNE3",
  "KCNE5",
  "KCNH1",
  "KCNH2",
  "KCNJ1",
  "KCNJ10",
  "KCNJ11",
  "KCNJ13",
  "KCNJ16",
  "KCNJ2",
  "KCNJ5",
  "KCNJ6",
  "KCNJ8",
  "KCNK18",
  "KCNK3",
  "KCNK4",
  "KCNK9",
  "KCNMA1",
  "KCNMB1",
  "KCNN2",
  "KCNN3",
  "KCNN4",
  "KCNQ1",
  "KCNQ1OT1",
  "KCNQ2",
  "KCNQ3",
  "KCNQ4",
  "KCNQ5",
  "KCNT1",
  "KCNT2",
  "KCNV2",
  "KCTD1",
  "KCTD17",
  "KCTD3",
  "KCTD7",
  "KDELR2",
  "KDF1",
  "KDM1A",
  "KDM3B",
  "KDM4B",
  "KDM5A",
  "KDM5B",
  "KDM5C",
  "KDM6A",
  "KDM6B",
  "KDR",
  "KDSR",
  "KERA",
  "KHDC3L",
  "KHDRBS1",
  "KIAA0319L",
  "KIAA0586",
  "KIAA0753",
  "KIAA0825",
  "KIAA1109",
  "KIAA1549",
  "KIDINS220",
  "KIF11",
  "KIF12",
  "KIF14",
  "KIF1A",
  "KIF1B",
  "KIF1C",
  "KIF21A",
  "KIF21B",
  "KIF22",
  "KIF23",
  "KIF2A",
  "KIF3B",
  "KIF4A",
  "KIF5A",
  "KIF5C",
  "KIF7",
  "KIFBP",
  "KIR3DL1",
  "KIRREL1",
  "KIRREL3",
  "KISS1",
  "KISS1R",
  "KIT",
  "KITLG",
  "KIZ",
  "KLB",
  "KLC2",
  "KLF1",
  "KLF10",
  "KLF11",
  "KLF2",
  "KLF6",
  "KLF7",
  "KLHDC8B",
  "KLHL10",
  "KLHL15",
  "KLHL24",
  "KLHL3",
  "KLHL40",
  "KLHL41",
  "KLHL7",
  "KLK1",
  "KLK4",
  "KLKB1",
  "KLLN",
  "KLRD1",
  "KMT2A",
  "KMT2B",
  "KMT2C",
  "KMT2D",
  "KMT2E",
  "KMT5B",
  "KNG1",
  "KNL1",
  "KPNA3",
  "KPNA7",
  "KPTN",
  "KRAS",
  "KREMEN1",
  "KRIT1",
  "KRT1",
  "KRT10",
  "KRT12",
  "KRT13",
  "KRT14",
  "KRT16",
  "KRT17",
  "KRT18",
  "KRT2",
  "KRT25",
  "KRT3",
  "KRT4",
  "KRT5",
  "KRT6A",
  "KRT6B",
  "KRT6C",
  "KRT74",
  "KRT75",
  "KRT8",
  "KRT81",
  "KRT83",
  "KRT85",
  "KRT86",
  "KRT9",
  "KSR2",
  "KY",
  "KYNU",
  "L1CAM",
  "L2HGDH",
  "L3MBTL1",
  "LACC1",
  "LAG3",
  "LAGE3",
  "LAMA1",
  "LAMA2",
  "LAMA3",
  "LAMA4",
  "LAMA5",
  "LAMB1",
  "LAMB2",
  "LAMB3",
  "LAMC2",
  "LAMC3",
  "LAMP2",
  "LAMTOR2",
  "LARGE1",
  "LARP7",
  "LARS1",
  "LARS2",
  "LAS1L",
  "LAT",
  "LBR",
  "LCA5",
  "LCAT",
  "LCK",
  "LCT",
  "LDB3",
  "LDHA",
  "LDHB",
  "LDHD",
  "LDLR",
  "LDLRAP1",
  "LEF1",
  "LEMD2",
  "LEMD3",
  "LEO1",
  "LEP",
  "LEPR",
  "LFNG",
  "LGI1",
  "LGI4",
  "LGR4",
  "LHB",
  "LHCGR",
  "LHFPL5",
  "LHX3",
  "LHX4",
  "LIAS",
  "LIFR",
  "LIG1",
  "LIG3",
  "LIG4",
  "LIM2",
  "LINGO1",
  "LINGO4",
  "LINS1",
  "LIPA",
  "LIPC",
  "LIPE",
  "LIPG",
  "LIPH",
  "LIPN",
  "LIPT1",
  "LIPT2",
  "LITAF",
  "LMAN1",
  "LMBR1",
  "LMBRD1",
  "LMBRD2",
  "LMF1",
  "CIROP",
  "LMNA",
  "LMNB1",
  "LMNB2",
  "LMOD3",
  "LMX1A",
  "LMX1B",
  "LNPK",
  "LONP1",
  "LORICRIN",
  "LOX",
  "LOXHD1",
  "LOXL1",
  "LPAR6",
  "LPIN1",
  "LPIN2",
  "LPL",
  "LPP",
  "LRAT",
  "LRBA",
  "LRIG2",
  "LRIT3",
  "LRMDA",
  "LRP1",
  "LRP10",
  "LRP12",
  "LRP2",
  "LRP4",
  "LRP5",
  "LRP6",
  "LRPAP1",
  "LRPPRC",
  "LRRC32",
  "LRRC56",
  "LRRFIP2",
  "LRRK1",
  "LRRK2",
  "LRSAM1",
  "LRTOMT",
  "LSS",
  "LTA",
  "LTBP1",
  "LTBP2",
  "LTBP3",
  "LTBP4",
  "LTC4S",
  "LYN",
  "LYRM7",
  "LYST",
  "LYZ",
  "LZTFL1",
  "LZTR1",
  "LZTS1",
  "M1AP",
  "MAB21L1",
  "MAB21L2",
  "MACF1",
  "MAD1L1",
  "MAD2L2",
  "MADD",
  "MAF",
  "MAFA",
  "MAFB",
  "MAG",
  "MAGED2",
  "MAGEL2",
  "MAGI2",
  "MAGT1",
  "MAK",
  "MALT1",
  "MAML2",
  "MAMLD1",
  "MAN1B1",
  "MAN2B1",
  "MAN2C1",
  "MANBA",
  "MANF",
  "MAOA",
  "MAP1B",
  "MAP2K1",
  "MAP2K2",
  "MAP3K1",
  "MAP3K14",
  "MAP3K20",
  "MAP3K3",
  "MAP3K6",
  "MAP3K7",
  "MAP3K8",
  "MAPK1",
  "MAPK8IP1",
  "MAPK8IP3",
  "MAPKAPK5",
  "MAPKBP1",
  "MAPRE2",
  "MAPT",
  "MARCHF6",
  "MARS1",
  "MARS2",
  "MARVELD2",
  "MASP1",
  "MASP2",
  "MAST1",
  "MAST3",
  "MAT1A",
  "MATN3",
  "MATR3",
  "MAX",
  "MBD4",
  "MBD5",
  "MBL2",
  "MBOAT7",
  "MBTPS1",
  "MBTPS2",
  "MC1R",
  "MC2R",
  "MC3R",
  "MC4R",
  "MCAT",
  "MCC",
  "MCCC1",
  "MCCC2",
  "MCEE",
  "MCFD2",
  "MCIDAS",
  "MCM10",
  "MCM2",
  "MCM3AP",
  "MCM4",
  "MCM6",
  "MCM7",
  "MCM8",
  "MCM9",
  "MCOLN1",
  "MCPH1",
  "MDH2",
  "MDM2",
  "MECOM",
  "MECP2",
  "MECR",
  "MED12",
  "MED12L",
  "MED13",
  "MED13L",
  "MED17",
  "MED23",
  "MED25",
  "MED27",
  "MEF2C",
  "MEFV",
  "MEG3",
  "MEGF10",
  "MEGF8",
  "MEI1",
  "MEIOB",
  "MEIS1",
  "MEIS2",
  "MEN1",
  "MEOX1",
  "MERTK",
  "MESD",
  "MESP2",
  "MEST",
  "MET",
  "METTL23",
  "METTL5",
  "MFAP5",
  "MFF",
  "MFN2",
  "MFRP",
  "MFSD2A",
  "MFSD8",
  "MGAT2",
  "MGME1",
  "MGMT",
  "MGP",
  "MIB1",
  "MICOS13",
  "MICU1",
  "MID1",
  "MIF",
  "MINPP1",
  "MIP",
  "MIPEP",
  "MIR140",
  "MIR17HG",
  "MIR182",
  "MIR183",
  "MIR184",
  "MIR936",
  "MIR96",
  "MITF",
  "MKKS",
  "MKRN3",
  "MKS1",
  "MLC1",
  "MLH1",
  "MLH3",
  "MLIP",
  "MLLT10",
  "MLPH",
  "MLYCD",
  "MMAA",
  "MMAB",
  "MMACHC",
  "MMADHC",
  "MME",
  "MMP1",
  "MMP13",
  "MMP19",
  "MMP2",
  "MMP20",
  "MMP21",
  "MMP9",
  "MMUT",
  "MN1",
  "MNS1",
  "MNX1",
  "MOCOS",
  "MOCS1",
  "MOCS2",
  "MOCS3",
  "MOG",
  "MOGS",
  "MORC2",
  "MPC1",
  "MPDU1",
  "MPDZ",
  "MPEG1",
  "MPI",
  "MPIG6B",
  "MPL",
  "MPLKIP",
  "MPO",
  "MPV17",
  "MPZ",
  "MPZL2",
  "MRAP",
  "MRAP2",
  "MRAS",
  "MRE11",
  "MRPL3",
  "MRPL44",
  "MRPS16",
  "MRPS2",
  "MRPS22",
  "MRPS23",
  "MRPS34",
  "MS4A1",
  "MSH2",
  "MSH3",
  "MSH4",
  "MSH5",
  "MSH6",
  "MSL3",
  "MSMB",
  "MSMO1",
  "MSN",
  "MSR1",
  "MSRB3",
  "MST1R",
  "MSTO1",
  "MSX1",
  "MSX2",
  "MTAP",
  "MT-ATP6",
  "MT-ATP8",
  "MTCL1",
  "MT-CO1",
  "MT-CO2",
  "MT-CO3",
  "MT-CYB",
  "MTFMT",
  "MTHFD1",
  "MTHFR",
  "MTHFS",
  "MTM1",
  "MTMR2",
  "MT-ND1",
  "MT-ND2",
  "MT-ND3",
  "MT-ND4",
  "MT-ND4L",
  "MT-ND5",
  "MT-ND6",
  "MTNR1B",
  "MTO1",
  "MTOR",
  "MTPAP",
  "MTR",
  "MTRFR",
  "MT-RNR1",
  "MTRR",
  "MT-TA",
  "MT-TC",
  "MT-TD",
  "MT-TE",
  "MT-TF",
  "MT-TG",
  "MT-TH",
  "MT-TI",
  "MT-TK",
  "MT-TL1",
  "MT-TL2",
  "MT-TM",
  "MT-TN",
  "MTTP",
  "MT-TP",
  "MT-TQ",
  "MT-TR",
  "MT-TS1",
  "MT-TS2",
  "MT-TV",
  "MT-TW",
  "MT-TY",
  "MTX2",
  "MUC1",
  "MUSK",
  "MUTYH",
  "MVD",
  "MVK",
  "MX1",
  "MXI1",
  "MYBPC1",
  "MYBPC3",
  "MYC",
  "MYCN",
  "MYD88",
  "MYF5",
  "MYH1",
  "MYH10",
  "MYH11",
  "MYH14",
  "MYH2",
  "MYH3",
  "MYH6",
  "MYH7",
  "MYH7B",
  "MYH8",
  "MYH9",
  "MYL1",
  "MYL2",
  "MYL3",
  "MYL9",
  "MYLK",
  "MYLK2",
  "MYLPF",
  "MYMK",
  "MYO15A",
  "MYO18B",
  "MYO1A",
  "MYO1E",
  "MYO3A",
  "MYO5A",
  "MYO5B",
  "MYO6",
  "MYO7A",
  "MYO9A",
  "MYOC",
  "MYOCD",
  "MYOD1",
  "MYOM1",
  "MYORG",
  "MYOT",
  "MYOZ2",
  "MYPN",
  "MYRF",
  "MYSM1",
  "MYT1",
  "MYT1L",
  "NAA10",
  "NAA15",
  "NAA20",
  "NABP1",
  "NACC1",
  "NADK2",
  "NADSYN1",
  "NAF1",
  "NAGA",
  "NAGLU",
  "NAGS",
  "NALCN",
  "NANOS1",
  "NANS",
  "NAPB",
  "NARS1",
  "NARS2",
  "NAT2",
  "NAT8L",
  "NAXD",
  "NAXE",
  "NBAS",
  "NBEA",
  "NBEAL2",
  "NBN",
  "NCAPD2",
  "NCAPD3",
  "NCAPG2",
  "NCDN",
  "NCF1",
  "NCF2",
  "NCF4",
  "NCKAP1",
  "NCKAP1L",
  "NCOA4",
  "NCOR1",
  "NCR3",
  "NCSTN",
  "NDE1",
  "NDN",
  "NDNF",
  "NDP",
  "NDRG1",
  "NDST1",
  "NDUFA1",
  "NDUFA10",
  "NDUFA11",
  "NDUFA12",
  "NDUFA13",
  "NDUFA2",
  "NDUFA4",
  "NDUFA6",
  "NDUFA7",
  "NDUFA8",
  "NDUFA9",
  "NDUFAF1",
  "NDUFAF2",
  "NDUFAF3",
  "NDUFAF4",
  "NDUFAF5",
  "NDUFAF6",
  "NDUFAF7",
  "NDUFAF8",
  "NDUFB10",
  "NDUFB11",
  "NDUFB3",
  "NDUFB8",
  "NDUFB9",
  "NDUFC2",
  "NDUFS1",
  "NDUFS2",
  "NDUFS3",
  "NDUFS4",
  "NDUFS5",
  "NDUFS6",
  "NDUFS7",
  "NDUFS8",
  "NDUFV1",
  "NDUFV2",
  "NEB",
  "NEBL",
  "NECAP1",
  "NECTIN1",
  "NECTIN4",
  "NEDD4L",
  "NEFH",
  "NEFL",
  "NEK1",
  "NEK10",
  "NEK2",
  "NEK8",
  "NEK9",
  "NEMF",
  "NEPRO",
  "NEU1",
  "NEURL4",
  "NEUROD1",
  "NEUROD2",
  "NEUROG1",
  "NEUROG3",
  "NEXMIF",
  "NEXN",
  "NF1",
  "NF2",
  "NFASC",
  "NFAT5",
  "NFATC1",
  "NFE2L2",
  "NFIA",
  "NFIB",
  "NFIX",
  "NFKB1",
  "NFKB2",
  "NFKBIA",
  "NFKBIL1",
  "NFS1",
  "NFU1",
  "NGF",
  "NGLY1",
  "NHEJ1",
  "NHLRC1",
  "NHLRC2",
  "NHP2",
  "NHS",
  "NID1",
  "NIPA1",
  "NIPAL4",
  "NIPBL",
  "NKAP",
  "NKX2-1",
  "NKX2-2",
  "NKX2-5",
  "NKX2-6",
  "NKX3-2",
  "NKX6-2",
  "NLGN1",
  "NLGN2",
  "NLGN3",
  "NLGN4X",
  "NLRC4",
  "NLRP1",
  "NLRP12",
  "NLRP2",
  "NLRP3",
  "NLRP5",
  "NLRP7",
  "NME8",
  "NMNAT1",
  "NNT",
  "NOBOX",
  "NOD2",
  "NODAL",
  "NOG",
  "NOL3",
  "NONO",
  "NOP10",
  "NOP56",
  "NOS1AP",
  "NOS2",
  "NOTCH1",
  "NOTCH2",
  "NOTCH3",
  "NOVA2",
  "NPC1",
  "NPC1L1",
  "NPC2",
  "NPHP1",
  "NPHP3",
  "NPHP4",
  "NPHS1",
  "NPHS2",
  "NPM1",
  "NPPA",
  "NPPC",
  "NPR2",
  "NPR3",
  "NPRL2",
  "NPRL3",
  "NPSR1",
  "NR0B1",
  "NR0B2",
  "NR1H4",
  "NR2E3",
  "NR2F1",
  "NR2F2",
  "NR3C1",
  "NR3C2",
  "NR4A2",
  "NR4A3",
  "NR5A1",
  "NRAP",
  "NRAS",
  "NRG1",
  "NRGN",
  "NRL",
  "NRROS",
  "NRXN1",
  "NSD1",
  "NSD2",
  "NSDHL",
  "NSF",
  "NSMCE2",
  "NSMCE3",
  "NSMF",
  "NSRP1",
  "NSUN2",
  "NSUN3",
  "NT5C2",
  "NT5C3A",
  "NT5E",
  "NTF4",
  "NTHL1",
  "NTN1",
  "NTNG2",
  "NTRK1",
  "NTRK2",
  "NUBPL",
  "NUDT15",
  "NUDT2",
  "NUMA1",
  "NUP107",
  "NUP133",
  "NUP188",
  "NUP205",
  "NUP210",
  "NUP214",
  "NUP62",
  "NUP85",
  "NUP88",
  "NUP93",
  "NUS1",
  "NXN",
  "NXNL1",
  "NYX",
  "OAS1",
  "OAT",
  "OBSCN",
  "OBSL1",
  "OCA2",
  "OCLN",
  "OCRL",
  "ODAD1",
  "ODAD2",
  "ODAD3",
  "ODAD4",
  "ODAPH",
  "ODC1",
  "OFD1",
  "OGDHL",
  "OGG1",
  "OGT",
  "OPA1",
  "OPA3",
  "OPCML",
  "OPHN1",
  "OPLAH",
  "OPN1LW",
  "OPN1MW",
  "OPN1SW",
  "OPTN",
  "OR2J3",
  "ORAI1",
  "ORC1",
  "ORC4",
  "ORC6",
  "OSBPL2",
  "OSGEP",
  "OSMR",
  "OSTM1",
  "OTC",
  "OTOA",
  "OTOF",
  "OTOG",
  "OTOGL",
  "OTUD1",
  "OTUD5",
  "OTUD6B",
  "OTULIN",
  "OTX2",
  "OVOL2",
  "OXCT1",
  "OXR1",
  "P2RX2",
  "P2RY11",
  "P2RY12",
  "P3H1",
  "P3H2",
  "P4HA2",
  "P4HB",
  "P4HTM",
  "PABPN1",
  "PACS1",
  "PACS2",
  "PADI3",
  "PADI6",
  "PAFAH1B1",
  "PAH",
  "PAK1",
  "PAK3",
  "PALB2",
  "PALLD",
  "PAM16",
  "PANK2",
  "PANX1",
  "PAPPA2",
  "PAPSS2",
  "PARK7",
  "PARN",
  "PARP6",
  "PARS2",
  "PATL2",
  "PAX1",
  "PAX2",
  "PAX3",
  "PAX4",
  "PAX5",
  "PAX6",
  "PAX7",
  "PAX8",
  "PAX9",
  "PBX1",
  "PC",
  "PCARE",
  "PCBD1",
  "PCCA",
  "PCCB",
  "PCDH12",
  "PCDH15",
  "PCDH19",
  "PCDHGC4",
  "PCGF2",
  "PCK1",
  "PCK2",
  "PCLO",
  "PCM1",
  "PCNT",
  "PCSK1",
  "PCSK9",
  "PCYT1A",
  "PCYT2",
  "PDCD1",
  "PDCD10",
  "PDCD1LG2",
  "PDE10A",
  "PDE11A",
  "PDE2A",
  "PDE3A",
  "PDE4D",
  "PDE6A",
  "PDE6B",
  "PDE6C",
  "PDE6D",
  "PDE6G",
  "PDE6H",
  "PDE8B",
  "PDGFB",
  "PDGFRA",
  "PDGFRB",
  "PDGFRL",
  "PDHA1",
  "PDHB",
  "PDHX",
  "PDK3",
  "PDLIM3",
  "PDP1",
  "PDSS1",
  "PDSS2",
  "PDX1",
  "PDXK",
  "PDYN",
  "PDZD7",
  "PECAM1",
  "PEPD",
  "PER2",
  "PER3",
  "PERCC1",
  "PERP",
  "PET100",
  "PEX1",
  "PEX10",
  "PEX11B",
  "PEX12",
  "PEX13",
  "PEX14",
  "PEX16",
  "PEX19",
  "PEX2",
  "PEX26",
  "PEX3",
  "PEX5",
  "PEX6",
  "PEX7",
  "PFKM",
  "PFN1",
  "PGAM2",
  "PGAP1",
  "PGAP2",
  "PGAP3",
  "PGK1",
  "PGM1",
  "PGM2L1",
  "PGM3",
  "PHACTR1",
  "PHEX",
  "PHF11",
  "PHF21A",
  "PHF6",
  "PHF8",
  "PHGDH",
  "PHIP",
  "PHKA1",
  "PHKA2",
  "PHKB",
  "PHKG2",
  "PHOX2A",
  "PHOX2B",
  "PHYH",
  "PI4KA",
  "PI4KB",
  "PIBF1",
  "PICALM",
  "PIDD1",
  "PIEZO1",
  "PIEZO2",
  "PIGA",
  "PIGB",
  "PIGC",
  "PIGF",
  "PIGG",
  "PIGH",
  "PIGK",
  "PIGL",
  "PIGM",
  "PIGN",
  "PIGO",
  "PIGP",
  "PIGQ",
  "PIGS",
  "PIGT",
  "PIGU",
  "PIGV",
  "PIGW",
  "PIGY",
  "PIK3C2A",
  "PIK3CA",
  "PIK3CD",
  "PIK3CG",
  "PIK3R1",
  "PIK3R2",
  "PIK3R5",
  "PIKFYVE",
  "PINK1",
  "PIP5K1C",
  "PISD",
  "PITPNM3",
  "PITRM1",
  "PITX1",
  "PITX2",
  "PITX3",
  "PJVK",
  "PKD1",
  "PKD1L1",
  "PKD2",
  "PKDCC",
  "PKHD1",
  "PKLR",
  "PKP1",
  "PKP2",
  "PLA2G4A",
  "PLA2G5",
  "PLA2G6",
  "PLA2G7",
  "PLAA",
  "PLAG1",
  "PLAGL1",
  "PLAT",
  "PLAU",
  "PLCB1",
  "PLCB3",
  "PLCB4",
  "PLCD1",
  "PLCE1",
  "PLCG2",
  "PLCH1",
  "PLCZ1",
  "PLD1",
  "PLD3",
  "PLEC",
  "PLEKHG2",
  "PLEKHG5",
  "PLEKHM1",
  "PLG",
  "PLIN1",
  "PLK1",
  "PLK4",
  "PLN",
  "PLOD1",
  "PLOD2",
  "PLOD3",
  "PLP1",
  "PLPBP",
  "PLS1",
  "PLS3",
  "PLVAP",
  "PLXNA1",
  "PLXNA3",
  "PLXND1",
  "PMFBP1",
  "PML",
  "PMM2",
  "PMP2",
  "PMP22",
  "PMPCA",
  "PMPCB",
  "PMS1",
  "PMS2",
  "PMVK",
  "PNKD",
  "PNKP",
  "PNLDC1",
  "PNP",
  "PNPLA1",
  "PNPLA2",
  "PNPLA3",
  "PNPLA6",
  "PNPLA8",
  "PNPO",
  "PNPT1",
  "POC1A",
  "POC1B",
  "POC5",
  "PODXL",
  "POFUT1",
  "POGLUT1",
  "POGZ",
  "POLA1",
  "POLD1",
  "POLD2",
  "POLE",
  "POLE2",
  "POLG",
  "POLG2",
  "POLH",
  "POLK",
  "POLQ",
  "POLR1A",
  "POLR1B",
  "POLR1C",
  "POLR1D",
  "POLR2A",
  "POLR3A",
  "POLR3B",
  "POLR3C",
  "POLR3F",
  "POLR3GL",
  "POLR3K",
  "POLRMT",
  "POMC",
  "POMGNT1",
  "POMGNT2",
  "POMK",
  "POMP",
  "POMT1",
  "POMT2",
  "POP1",
  "POPDC3",
  "POR",
  "PORCN",
  "POT1",
  "POU1F1",
  "POU3F3",
  "POU3F4",
  "POU4F1",
  "POU4F3",
  "PPA2",
  "PPARA",
  "PPARG",
  "PPCS",
  "PPIB",
  "PPIL1",
  "PPIP5K2",
  "PPM1D",
  "PPM1K",
  "PPOX",
  "PPP1CB",
  "PPP1R12A",
  "PPP1R13L",
  "PPP1R15B",
  "PPP1R21",
  "PPP1R3A",
  "PPP2CA",
  "PPP2R1A",
  "PPP2R1B",
  "PPP2R2B",
  "PPP2R3C",
  "PPP2R5D",
  "PPP3CA",
  "PPT1",
  "PQBP1",
  "PRCC",
  "PRCD",
  "PRDM1",
  "PRDM12",
  "PRDM13",
  "PRDM15",
  "PRDM16",
  "PRDM5",
  "PRDM6",
  "PRDM9",
  "PRDX1",
  "PRDX3",
  "PREPL",
  "PRF1",
  "PRG4",
  "PRICKLE1",
  "PRICKLE2",
  "PRIMPOL",
  "PRKACA",
  "PRKACB",
  "PRKACG",
  "PRKAG2",
  "PRKAR1A",
  "PRKAR1B",
  "PRKCA",
  "PRKCD",
  "PRKCG",
  "PRKCSH",
  "PRKD1",
  "PRKDC",
  "PRKG1",
  "PRKG2",
  "PRKN",
  "PRKRA",
  "PRLR",
  "PRMT7",
  "PRNP",
  "PROC",
  "PRODH",
  "PRODH2",
  "PROK2",
  "PROKR2",
  "PROM1",
  "PROP1",
  "PRORP",
  "PROS1",
  "PRPF3",
  "PRPF31",
  "PRPF4",
  "PRPF6",
  "PRPF8",
  "PRPH",
  "PRPH2",
  "PRPS1",
  "PRR12",
  "PRRT2",
  "PRRX1",
  "PRSS1",
  "PRSS12",
  "PRSS56",
  "PRUNE1",
  "PRX",
  "PSAP",
  "PSAT1",
  "PSEN1",
  "PSEN2",
  "PSENEN",
  "PSMB10",
  "PSMB4",
  "PSMB8",
  "PSMC2",
  "PSMC3IP",
  "PSMD12",
  "PSMG2",
  "PSPH",
  "PSTPIP1",
  "PTCH1",
  "PTCH2",
  "PTCHD1",
  "PTDSS1",
  "PTEN",
  "PTF1A",
  "PTGDR",
  "PTGIS",
  "PTGS2",
  "PTH",
  "PTH1R",
  "PTHLH",
  "PTPN11",
  "PTPN12",
  "PTPN14",
  "PTPN22",
  "PTPN23",
  "PTPN3",
  "PTPN4",
  "PTPRC",
  "PTPRJ",
  "PTPRO",
  "PTPRQ",
  "PTRH2",
  "PTRHD1",
  "PTS",
  "PUF60",
  "PUM1",
  "PURA",
  "PUS1",
  "PUS3",
  "PUS7",
  "PXDN",
  "PYCR1",
  "PYCR2",
  "PYGL",
  "PYGM",
  "PYROXD1",
  "QARS1",
  "QDPR",
  "QRICH1",
  "QRICH2",
  "QRSL1",
  "RAB11A",
  "RAB11B",
  "RAB12",
  "RAB18",
  "RAB23",
  "RAB27A",
  "RAB28",
  "RAB33B",
  "RAB39B",
  "RAB3GAP1",
  "RAB3GAP2",
  "RAB7A",
  "RABL3",
  "RABL6",
  "RAC1",
  "RAC2",
  "RAC3",
  "RAD21",
  "RAD50",
  "RAD51",
  "RAD51B",
  "RAD51C",
  "RAD51D",
  "RAD54B",
  "RAD54L",
  "RAD9B",
  "RAF1",
  "RAG1",
  "RAG2",
  "RAI1",
  "RALA",
  "RALGAPA1",
  "RALGAPB",
  "RAMP2",
  "RANBP2",
  "RAP1B",
  "RAP1GDS1",
  "RAPSN",
  "RARA",
  "RARB",
  "RARS1",
  "RARS2",
  "RASA1",
  "RASGRP1",
  "RASGRP2",
  "RAX",
  "RAX2",
  "RB1",
  "RB1CC1",
  "RBBP6",
  "RBBP8",
  "RBCK1",
  "RBFOX1",
  "RBL2",
  "RBM10",
  "RBM12",
  "RBM20",
  "RBM28",
  "RBM8A",
  "RBP3",
  "RBP4",
  "RBPJ",
  "RCBTB1",
  "RD3",
  "RDH12",
  "RDH5",
  "RDX",
  "REC114",
  "RECQL",
  "RECQL4",
  "REEP1",
  "REEP2",
  "REEP6",
  "REL",
  "RELA",
  "RELB",
  "RELN",
  "RELT",
  "REN",
  "RERE",
  "REST",
  "RET",
  "RETREG1",
  "REV3L",
  "RFC1",
  "RFC2",
  "RFT1",
  "RFWD3",
  "RFX3",
  "RFX4",
  "RFX5",
  "RFX6",
  "RFX7",
  "RFXANK",
  "RFXAP",
  "RGR",
  "RGS2",
  "RGS6",
  "RGS9",
  "RGS9BP",
  "RHAG",
  "RHBDF2",
  "RHCE",
  "RHD",
  "RHEB",
  "RHO",
  "RHOA",
  "RHOBTB2",
  "RHOH",
  "RHOT1",
  "RIC1",
  "RIMS1",
  "RIMS2",
  "RIN2",
  "RINT1",
  "RIPK1",
  "RIPK4",
  "RIPOR2",
  "RIPPLY2",
  "RIT1",
  "RLBP1",
  "RLIM",
  "RMND1",
  "RMRP",
  "RNASEH1",
  "RNASEH2A",
  "RNASEH2B",
  "RNASEH2C",
  "RNASEL",
  "RNASET2",
  "RNF113A",
  "RNF114",
  "RNF125",
  "RNF13",
  "RNF139",
  "RNF168",
  "RNF170",
  "RNF2",
  "RNF212",
  "RNF213",
  "RNF216",
  "RNF220",
  "RNF31",
  "RNF43",
  "RNF6",
  "RNFT2",
  "RNPC3",
  "RNU12",
  "RNU4ATAC",
  "RNU7-1",
  "ROBO1",
  "ROBO2",
  "ROBO3",
  "ROBO4",
  "ROGDI",
  "ROM1",
  "ROR2",
  "RORA",
  "RORB",
  "RORC",
  "RP1",
  "RP1L1",
  "RP2",
  "RP9",
  "RPA1",
  "RPE65",
  "RPGR",
  "RPGRIP1",
  "RPGRIP1L",
  "RPIA",
  "RPL10",
  "RPL11",
  "RPL13",
  "RPL15",
  "RPL21",
  "RPL23",
  "RPL26",
  "RPL27",
  "RPL31",
  "RPL35A",
  "RPL36",
  "RPL3L",
  "RPL5",
  "RPL9",
  "RPS10",
  "RPS14",
  "RPS15",
  "RPS17",
  "RPS19",
  "RPS20",
  "RPS23",
  "RPS24",
  "RPS26",
  "RPS27",
  "RPS27A",
  "RPS28",
  "RPS29",
  "RPS6KA3",
  "RPS7",
  "RPSA",
  "RRAS",
  "RRAS2",
  "RRM2B",
  "RS1",
  "RSPH1",
  "RSPH3",
  "RSPH4A",
  "RSPH9",
  "RSPO1",
  "RSPO2",
  "RSPO4",
  "RSPRY1",
  "RSRC1",
  "RTEL1",
  "RTN2",
  "RTN4IP1",
  "RTTN",
  "RUBCN",
  "RUNX1",
  "RUNX2",
  "RUSC2",
  "RXYLT1",
  "RYR1",
  "RYR2",
  "RYR3",
  "S1PR2",
  "SACS",
  "SAG",
  "SALL1",
  "SALL4",
  "SAMD12",
  "SAMD9",
  "SAMD9L",
  "SAMHD1",
  "SAR1B",
  "SARDH",
  "SARS2",
  "SASH1",
  "SASH3",
  "SASS6",
  "SATB1",
  "SATB2",
  "SBDS",
  "SBF1",
  "SBF2",
  "SC5D",
  "SCAF4",
  "SCAMP5",
  "SCAPER",
  "SCARB1",
  "SCARB2",
  "SCARF2",
  "SCLT1",
  "SCN10A",
  "SCN11A",
  "SCN1A",
  "SCN1B",
  "SCN2A",
  "SCN2B",
  "SCN3A",
  "SCN3B",
  "SCN4A",
  "SCN4B",
  "SCN5A",
  "SCN8A",
  "SCN9A",
  "SCNN1A",
  "SCNN1B",
  "SCNN1G",
  "SCO1",
  "SCO2",
  "SCP2",
  "SCRIB",
  "SCUBE3",
  "SCYL1",
  "SCYL2",
  "SDCCAG8",
  "SDHA",
  "SDHAF1",
  "SDHAF2",
  "SDHB",
  "SDHC",
  "SDHD",
  "SDR9C7",
  "SEC23A",
  "SEC23B",
  "SEC24B",
  "SEC24D",
  "SEC61A1",
  "SEC63",
  "SECISBP2",
  "SELENBP1",
  "SELENOI",
  "SELENON",
  "SEM1",
  "SEMA3A",
  "SEMA3E",
  "SEMA3F",
  "SEMA4A",
  "SEMA6B",
  "SEPSECS",
  "SEPTIN12",
  "SEPTIN9",
  "SERAC1",
  "SERPINA1",
  "SERPINA3",
  "SERPINA6",
  "SERPINA7",
  "SERPINB6",
  "SERPINB7",
  "SERPINB8",
  "SERPINC1",
  "SERPIND1",
  "SERPINE1",
  "SERPINF1",
  "SERPINF2",
  "SERPING1",
  "SERPINH1",
  "SERPINI1",
  "SET",
  "SETBP1",
  "SETD1A",
  "SETD1B",
  "SETD2",
  "SETD5",
  "SETX",
  "SF3B1",
  "SF3B2",
  "SF3B4",
  "SFRP4",
  "SFTPA1",
  "SFTPA2",
  "SFTPB",
  "SFTPC",
  "SFXN4",
  "SGCA",
  "SGCB",
  "SGCD",
  "SGCE",
  "SGCG",
  "SGMS2",
  "SGO1",
  "SGPL1",
  "SGSH",
  "SH2B1",
  "SH2B3",
  "SH2D1A",
  "SH3BP2",
  "SH3KBP1",
  "SH3PXD2B",
  "SH3TC2",
  "SHANK1",
  "SHANK2",
  "SHANK3",
  "SHH",
  "SHMT2",
  "SHOC2",
  "SHOX",
  "SHOX2",
  "SHROOM4",
  "SI",
  "SIAE",
  "SIAH1",
  "SIGMAR1",
  "SIK1",
  "SIK3",
  "SIL1",
  "SIM1",
  "SIN3A",
  "SIN3B",
  "SIRT3",
  "SIX1",
  "SIX2",
  "SIX3",
  "SIX5",
  "SIX6",
  "SKI",
  "SKIV2L",
  "SLC10A1",
  "SLC10A2",
  "SLC10A7",
  "SLC11A1",
  "SLC11A2",
  "SLC12A1",
  "SLC12A2",
  "SLC12A3",
  "SLC12A5",
  "SLC12A6",
  "SLC13A3",
  "SLC13A5",
  "SLC14A1",
  "SLC16A1",
  "SLC16A12",
  "SLC16A2",
  "SLC17A3",
  "SLC17A5",
  "SLC17A8",
  "SLC17A9",
  "SLC18A2",
  "SLC18A3",
  "SLC19A2",
  "SLC19A3",
  "SLC1A1",
  "SLC1A2",
  "SLC1A3",
  "SLC1A4",
  "SLC20A1",
  "SLC20A2",
  "SLC22A12",
  "SLC22A18",
  "SLC22A4",
  "SLC22A5",
  "SLC24A1",
  "SLC24A4",
  "SLC24A5",
  "SLC25A1",
  "SLC25A11",
  "SLC25A12",
  "SLC25A13",
  "SLC25A15",
  "SLC25A19",
  "SLC25A20",
  "SLC25A22",
  "SLC25A24",
  "SLC25A26",
  "SLC25A3",
  "SLC25A32",
  "SLC25A38",
  "SLC25A4",
  "SLC25A42",
  "SLC25A46",
  "SLC26A2",
  "SLC26A3",
  "SLC26A4",
  "SLC26A5",
  "SLC26A7",
  "SLC26A8",
  "SLC27A4",
  "SLC29A3",
  "SLC2A1",
  "SLC2A10",
  "SLC2A2",
  "SLC2A3",
  "SLC2A4",
  "SLC2A9",
  "SLC30A10",
  "SLC30A2",
  "SLC30A8",
  "SLC32A1",
  "SLC33A1",
  "SLC34A1",
  "SLC34A2",
  "SLC34A3",
  "SLC35A1",
  "SLC35A2",
  "SLC35A3",
  "SLC35C1",
  "SLC35D1",
  "SLC36A2",
  "SLC37A4",
  "SLC38A3",
  "SLC38A8",
  "SLC39A13",
  "SLC39A14",
  "SLC39A4",
  "SLC39A5",
  "SLC39A7",
  "SLC39A8",
  "SLC3A1",
  "SLC40A1",
  "SLC44A1",
  "SLC45A1",
  "SLC45A2",
  "SLC46A1",
  "SLC4A1",
  "SLC4A11",
  "SLC4A2",
  "SLC4A4",
  "SLC52A1",
  "SLC52A2",
  "SLC52A3",
  "SLC5A1",
  "SLC5A2",
  "SLC5A5",
  "SLC5A6",
  "SLC5A7",
  "SLC6A1",
  "SLC6A17",
  "SLC6A19",
  "SLC6A2",
  "SLC6A20",
  "SLC6A3",
  "SLC6A5",
  "SLC6A6",
  "SLC6A8",
  "SLC6A9",
  "SLC7A14",
  "SLC7A6OS",
  "SLC7A7",
  "SLC7A8",
  "SLC7A9",
  "SLC9A1",
  "SLC9A3",
  "SLC9A3R1",
  "SLC9A6",
  "SLC9A7",
  "SLC9A9",
  "SLCO1B1",
  "SLCO1B3",
  "SLCO2A1",
  "SLFN14",
  "SLIT2",
  "SLITRK1",
  "SLITRK6",
  "SLURP1",
  "SLX4",
  "SMAD1",
  "SMAD2",
  "SMAD3",
  "SMAD4",
  "SMAD6",
  "SMAD7",
  "SMAD9",
  "SMARCA2",
  "SMARCA4",
  "SMARCA5",
  "SMARCAD1",
  "SMARCAL1",
  "SMARCB1",
  "SMARCC1",
  "SMARCC2",
  "SMARCD1",
  "SMARCD2",
  "SMARCE1",
  "SMC1A",
  "SMC3",
  "SMCHD1",
  "SMG8",
  "SMG9",
  "SMIM1",
  "SMN1",
  "SMN2",
  "SMO",
  "SMOC1",
  "SMOC2",
  "SMPD1",
  "SMPD4",
  "SMPX",
  "SMS",
  "SNAI2",
  "SNAP25",
  "SNAP29",
  "SNCA",
  "SNCAIP",
  "SNCB",
  "SNIP1",
  "SNORA31",
  "SNORD118",
  "SNRNP200",
  "SNRPB",
  "SNRPE",
  "SNRPN",
  "SNTA1",
  "SNX10",
  "SNX14",
  "SNX27",
  "SNX3",
  "SOBP",
  "SOCS1",
  "SOD1",
  "SOHLH1",
  "SON",
  "SORD",
  "SORL1",
  "SORT1",
  "SOS1",
  "SOS2",
  "SOST",
  "SOX10",
  "SOX11",
  "SOX17",
  "SOX18",
  "SOX2",
  "SOX3",
  "SOX4",
  "SOX5",
  "SOX6",
  "SOX9",
  "SP110",
  "SP6",
  "SP7",
  "SPAG1",
  "SPAG7",
  "SPARC",
  "SPART",
  "SPAST",
  "SPATA13",
  "SPATA16",
  "SPATA5",
  "SPATA5L1",
  "SPATA7",
  "SPECC1L",
  "SPEF2",
  "SPEG",
  "SPEN",
  "SPG11",
  "SPG21",
  "SPG7",
  "SPI1",
  "SPIDR",
  "SPINK1",
  "SPINK5",
  "SPINT2",
  "SPOP",
  "SPPL2A",
  "SPR",
  "SPRED1",
  "SPRED2",
  "SPRTN",
  "SPRY1",
  "SPRY4",
  "SPTA1",
  "SPTAN1",
  "SPTB",
  "SPTBN1",
  "SPTBN2",
  "SPTBN4",
  "SPTLC1",
  "SPTLC2",
  "SQOR",
  "SQSTM1",
  "SRA1",
  "SRC",
  "SRCAP",
  "SRD5A2",
  "SRD5A3",
  "SREBF1",
  "SRGAP1",
  "SRP54",
  "SRP72",
  "SRRM2",
  "SRY",
  "SS18L1",
  "SSBP1",
  "SSR4",
  "ST14",
  "ST3GAL3",
  "ST3GAL5",
  "STAC3",
  "STAG1",
  "STAG2",
  "STAG3",
  "STAMBP",
  "STAP1",
  "STAR",
  "STARD7",
  "STAT1",
  "STAT2",
  "STAT3",
  "STAT5B",
  "STEEP1",
  "STIL",
  "STIM1",
  "STING1",
  "STK11",
  "STK4",
  "STN1",
  "STOX1",
  "STRA6",
  "STRADA",
  "STRC",
  "STS",
  "STT3A",
  "STUB1",
  "STX11",
  "STX16",
  "STX1B",
  "STX2",
  "STX3",
  "STX4",
  "STXBP1",
  "STXBP2",
  "STXBP3",
  "SUCLA2",
  "SUCLG1",
  "SUCO",
  "SUFU",
  "SUGCT",
  "SULT2B1",
  "SUMF1",
  "SUMO4",
  "SUN5",
  "SUOX",
  "SUPT16H",
  "SUPT5H",
  "SURF1",
  "SUZ12",
  "SVBP",
  "SVIL",
  "SYCE1",
  "SYCP2",
  "SYCP3",
  "SYK",
  "SYN1",
  "SYNCRIP",
  "SYNE1",
  "SYNE2",
  "SYNE4",
  "SYNGAP1",
  "SYNJ1",
  "SYP",
  "SYT1",
  "SYT2",
  "SZT2",
  "TAB2",
  "TAC3",
  "TACC2",
  "TACO1",
  "TACR3",
  "TACSTD2",
  "TAF1",
  "TAF13",
  "TAF15",
  "TAF2",
  "TAF6",
  "TAFAZZIN",
  "TAL1",
  "TAL2",
  "TALDO1",
  "TAMM41",
  "TANC2",
  "TANGO2",
  "TAOK1",
  "TAP1",
  "TAP2",
  "TAPBP",
  "TAPT1",
  "TARDBP",
  "TARS1",
  "TARS2",
  "TAS2R16",
  "TAS2R38",
  "TASP1",
  "TAT",
  "TBC1D1",
  "TBC1D20",
  "TBC1D23",
  "TBC1D24",
  "TBC1D2B",
  "TBC1D32",
  "TBC1D7",
  "TBC1D8B",
  "TBCD",
  "TBCE",
  "TBCK",
  "TBK1",
  "TBL1X",
  "TBL1XR1",
  "TBP",
  "TBR1",
  "TBX1",
  "TBX15",
  "TBX18",
  "TBX19",
  "TBX2",
  "TBX20",
  "TBX21",
  "TBX22",
  "TBX3",
  "TBX4",
  "TBX5",
  "TBX6",
  "TBXA2R",
  "TBXAS1",
  "TBXT",
  "TCAP",
  "TCF12",
  "TCF20",
  "TCF3",
  "TCF4",
  "TCF7L2",
  "TCIRG1",
  "TCN2",
  "TCOF1",
  "TCTN1",
  "TCTN2",
  "TCTN3",
  "TDGF1",
  "TDP1",
  "TDP2",
  "TDRD7",
  "TEAD1",
  "TECPR2",
  "TECR",
  "TECRL",
  "TECTA",
  "TEK",
  "TELO2",
  "TENM3",
  "TENM4",
  "TENT5A",
  "TERB1",
  "TERC",
  "TERT",
  "TET2",
  "TET3",
  "TEX11",
  "TEX14",
  "TEX15",
  "TF",
  "TFAP2A",
  "TFAP2B",
  "TFE3",
  "TFG",
  "TFR2",
  "TFRC",
  "TG",
  "TGDS",
  "TGFB1",
  "TGFB2",
  "TGFB3",
  "TGFBI",
  "TGFBR1",
  "TGFBR2",
  "TGIF1",
  "TGM1",
  "TGM3",
  "TGM5",
  "TGM6",
  "TH",
  "THAP1",
  "THBD",
  "THBS2",
  "THG1L",
  "THOC2",
  "THOC6",
  "THPO",
  "THRA",
  "THRB",
  "THSD1",
  "THSD4",
  "THUMPD1",
  "TIA1",
  "TICAM1",
  "TIE1",
  "TIMM44",
  "TIMM50",
  "TIMM8A",
  "TIMMDC1",
  "TIMP3",
  "TINF2",
  "TIRAP",
  "TJP2",
  "TK2",
  "TKFC",
  "TKT",
  "TLCD3B",
  "TLE6",
  "TLK2",
  "TLL1",
  "TLR2",
  "TLR3",
  "TLR4",
  "TLR5",
  "TLR7",
  "TLR8",
  "TM4SF20",
  "TMC1",
  "TMC6",
  "TMC8",
  "TMCO1",
  "TMEM106B",
  "TMEM107",
  "TMEM126A",
  "TMEM126B",
  "TMEM127",
  "TMEM132E",
  "TMEM138",
  "TMEM165",
  "TMEM199",
  "TMEM216",
  "TMEM218",
  "TMEM222",
  "TMEM230",
  "TMEM231",
  "TMEM237",
  "TMEM240",
  "TMEM251",
  "TMEM260",
  "TMEM38B",
  "TMEM43",
  "TMEM53",
  "TMEM63A",
  "TMEM67",
  "TMEM70",
  "TMEM94",
  "TMEM98",
  "TMIE",
  "TMLHE",
  "TMPRSS15",
  "TMPRSS2",
  "TMPRSS3",
  "TMPRSS6",
  "TMTC3",
  "TMX2",
  "TNC",
  "TNF",
  "TNFAIP3",
  "TNFRSF10B",
  "TNFRSF11A",
  "TNFRSF11B",
  "TNFRSF13B",
  "TNFRSF13C",
  "TNFRSF14",
  "TNFRSF18",
  "TNFRSF1A",
  "TNFRSF21",
  "TNFRSF4",
  "TNFRSF9",
  "TNFSF11",
  "TNFSF12",
  "TNFSF4",
  "TNIK",
  "TNNC1",
  "TNNI2",
  "TNNI3",
  "TNNI3K",
  "TNNT1",
  "TNNT2",
  "TNNT3",
  "TNPO2",
  "TNPO3",
  "TNR",
  "TNRC6B",
  "TNS2",
  "TNXB",
  "TOE1",
  "TOGARAM1",
  "TOMM70",
  "TONSL",
  "TOP1",
  "TOP2A",
  "TOP2B",
  "TOP3A",
  "TOPORS",
  "TOR1A",
  "TOR1AIP1",
  "TP53",
  "TP53RK",
  "TP63",
  "TP73",
  "TPH1",
  "TPH2",
  "TPI1",
  "TPK1",
  "TPM1",
  "TPM2",
  "TPM3",
  "TPM4",
  "TPMT",
  "TPO",
  "TPP1",
  "TPP2",
  "TPRKB",
  "TPRN",
  "TPTE2P5",
  "TRAF3",
  "TRAF3IP1",
  "TRAF3IP2",
  "TRAF7",
  "TRAIP",
  "TRAK1",
  "TRAP1",
  "TRAPPC11",
  "TRAPPC12",
  "TRAPPC2",
  "TRAPPC2L",
  "TRAPPC4",
  "TRAPPC6B",
  "TRAPPC9",
  "TRDN",
  "TREH",
  "TREM2",
  "TREX1",
  "TRHR",
  "TRIM2",
  "TRIM22",
  "TRIM28",
  "TRIM32",
  "TRIM37",
  "TRIM63",
  "TRIM71",
  "TRIM8",
  "TRIO",
  "TRIOBP",
  "TRIP11",
  "TRIP12",
  "TRIP13",
  "TRIP4",
  "TRIT1",
  "TRMT1",
  "TRMT10A",
  "TRMT10C",
  "TRMT5",
  "TRMU",
  "TRNT1",
  "TRPA1",
  "TRPC6",
  "TRPM1",
  "TRPM3",
  "TRPM4",
  "TRPM6",
  "TRPS1",
  "TRPV3",
  "TRPV4",
  "TRPV6",
  "TRRAP",
  "TSC1",
  "TSC2",
  "TSEN15",
  "TSEN2",
  "TSEN34",
  "TSEN54",
  "TSFM",
  "TSHB",
  "TSHR",
  "TSHZ1",
  "TSPAN12",
  "TSPAN7",
  "TSPEAR",
  "TSPOAP1",
  "TSPYL1",
  "TSR1",
  "TSR2",
  "TTBK2",
  "TTC12",
  "TTC19",
  "TTC21A",
  "TTC21B",
  "TTC26",
  "TTC29",
  "TTC37",
  "TTC5",
  "TTC7A",
  "TTC8",
  "TTF1",
  "TTI2",
  "TTLL5",
  "TTN",
  "TTPA",
  "TTR",
  "TUB",
  "TUBA1A",
  "TUBA3D",
  "TUBA4A",
  "TUBA8",
  "TUBB",
  "TUBB1",
  "TUBB2A",
  "TUBB2B",
  "TUBB3",
  "TUBB4A",
  "TUBB4B",
  "TUBB8",
  "TUBG1",
  "TUBGCP2",
  "TUBGCP4",
  "TUBGCP6",
  "TUFM",
  "TULP1",
  "TUSC3",
  "TWIST1",
  "TWIST2",
  "TWNK",
  "TXNDC15",
  "TXNL4A",
  "TXNRD2",
  "TYK2",
  "TYMP",
  "TYR",
  "TYROBP",
  "TYRP1",
  "U2AF1",
  "UBA1",
  "UBA2",
  "UBA5",
  "UBAP1",
  "UBE2A",
  "UBE2T",
  "UBE3A",
  "UBE3B",
  "UBE4A",
  "UBIAD1",
  "UBQLN2",
  "UBR1",
  "UBR4",
  "UBR7",
  "UBTF",
  "UCHL1",
  "UCP3",
  "UFC1",
  "UFM1",
  "UFSP2",
  "UGDH",
  "UGP2",
  "UGT1A1",
  "UMOD",
  "UMPS",
  "UNC119",
  "UNC13A",
  "UNC13D",
  "UNC45A",
  "UNC45B",
  "UNC80",
  "UNC93B1",
  "UNG",
  "UPB1",
  "UPF3B",
  "UQCC2",
  "UQCRB",
  "UQCRC1",
  "UQCRC2",
  "UQCRFS1",
  "UQCRQ",
  "UROC1",
  "UROD",
  "UROS",
  "USB1",
  "USH1C",
  "USH1G",
  "USH2A",
  "USP18",
  "USP26",
  "USP27X",
  "USP45",
  "USP48",
  "USP53",
  "USP7",
  "USP8",
  "USP9X",
  "USP9Y",
  "UVSSA",
  "VAC14",
  "VAMP1",
  "VAMP2",
  "VANGL1",
  "VANGL2",
  "VAPB",
  "VARS1",
  "VARS2",
  "VCAN",
  "VCL",
  "VCP",
  "VDR",
  "VEGFA",
  "VEGFC",
  "VHL",
  "VIM",
  "VIPAS39",
  "VKORC1",
  "VLDLR",
  "VMA21",
  "VPS11",
  "VPS13A",
  "VPS13B",
  "VPS13C",
  "VPS13D",
  "VPS16",
  "VPS33A",
  "VPS33B",
  "VPS35",
  "VPS35L",
  "VPS37A",
  "VPS41",
  "VPS45",
  "VPS4A",
  "VPS50",
  "VPS51",
  "VPS53",
  "VRK1",
  "VSIR",
  "VSX1",
  "VSX2",
  "VWA1",
  "VWA8",
  "VWF",
  "WAC",
  "WARS1",
  "WARS2",
  "WAS",
  "WASF1",
  "WASHC4",
  "WASHC5",
  "WBP11",
  "WBP2",
  "WDFY3",
  "WDPCP",
  "WDR1",
  "WDR11",
  "WDR19",
  "WDR26",
  "WDR35",
  "WDR36",
  "WDR37",
  "WDR4",
  "WDR45",
  "WDR45B",
  "WDR62",
  "WDR72",
  "WDR73",
  "WDR81",
  "WEE2",
  "WFS1",
  "WHRN",
  "WIPF1",
  "WIPI2",
  "WLS",
  "WNK1",
  "WNK4",
  "WNT1",
  "WNT10A",
  "WNT10B",
  "WNT2B",
  "WNT3",
  "WNT4",
  "WNT5A",
  "WNT7A",
  "WNT7B",
  "WNT9B",
  "WRAP53",
  "WRN",
  "WT1",
  "WWOX",
  "WWP1",
  "XDH",
  "XG",
  "XIAP",
  "XIST",
  "XK",
  "XPA",
  "XPC",
  "XPNPEP3",
  "XPR1",
  "XRCC1",
  "XRCC2",
  "XRCC3",
  "XRCC4",
  "XYLT1",
  "XYLT2",
  "YAP1",
  "YARS1",
  "YARS2",
  "YIF1B",
  "YIPF5",
  "YRDC",
  "YWHAG",
  "YY1",
  "YY1AP1",
  "ZAP70",
  "ZBTB11",
  "ZBTB16",
  "ZBTB18",
  "ZBTB20",
  "ZBTB24",
  "ZC3H14",
  "ZC4H2",
  "ZDHHC9",
  "ZEB1",
  "ZEB2",
  "ZFHX3",
  "ZFHX4",
  "ZFP57",
  "ZFPM2",
  "ZFYVE19",
  "ZFYVE26",
  "ZFYVE27",
  "ZIC1",
  "ZIC2",
  "ZIC3",
  "ZMIZ1",
  "ZMPSTE24",
  "ZMYM2",
  "ZMYND10",
  "ZMYND11",
  "ZMYND15",
  "ZNF142",
  "ZNF148",
  "ZNF292",
  "ZNF335",
  "ZNF341",
  "ZNF407",
  "ZNF408",
  "ZNF423",
  "ZNF462",
  "ZNF469",
  "ZNF513",
  "ZNF526",
  "ZNF644",
  "ZNF687",
  "ZNF699",
  "ZNF711",
  "ZNFX1",
  "ZNHIT3",
  "ZP1",
  "ZP2",
  "ZP3",
  "ZSWIM6",
];

export const defaultFiltersetsConf = {
  "main.symbol": {
    morbid_genes: {
      label: "Morbid Genes",
      values: morbidGenes,
    },
  },
  "main.gene_symbol": {
    morbid_genes: {
      label: "Morbid Genes",
      values: morbidGenes,
    },
  },
  "main.pathogenicity": {
    exclude_benign: {
      label: "Exclude Benign & Likely Benign",
      values: {
        0: "P",
        1: "LP",
        2: "VUSii",
        3: "VUSi",
        4: "VUS",
        5: "LB",
        6: "B",
      },
    },
    vus: {
      label: "Only VUS",
      values: {
        2: "VUSii",
        3: "VUSi",
        4: "VUS",
      },
    },
    pathogenic: {
      label: "Only pathogenic",
      values: {
        0: "P",
        1: "LP",
      },
    },
  },
  "main.significancy": {
    exclude_benign: {
      label: "Exclude Benign & Likely Benign",
      values: {
        0: "P",
        1: "LP",
        2: "VUSii",
        3: "VUSi",
        4: "VUS",
        5: "LB",
        6: "B",
      },
    },
    vus: {
      label: "Only VUS",
      values: {
        2: "VUSii",
        3: "VUSi",
        4: "VUS",
      },
    },
    pathogenic: {
      label: "Only pathogenic",
      values: {
        0: "P",
        1: "LP",
      },
    },
  },
  "main.consequence": {
    devastating: {
      label: "Devastating",
      values: [
        "stop gained",
        "inframe deletion",
        "splice donor variant",
        "splice acceptor variant",
        "frameshift variant",
        "inframe insertion",
        "start lost",
        "stop lost",
      ],
    },
    exonic: {
      label: "Exonic",
      values: [
        "splice acceptor variant",
        "splice donor variant",
        "stop gained",
        "stop lost",
        "frameshift variant",
        "inframe insertion",
        "inframe deletion",
        "missense variant",
        "splice region variant",
        "incomplete terminal",
        "synonymous variant",
        "coding sequence variant",
        "5 prime UTR variant",
        "3 prime UTR variant",
        "non coding transcript exon variant",
      ],
    },
  },
  "details4.clinvar_clnsig": {
    exclude_benign: {
      label: "Exclude Benign & Likely Benign",
      values: [
        "drug response",
        " confers sensitivity",
        " risk factor",
        "risk factor",
        " association",
        "Affects",
        "other",
        "confers sensitivity",
        "Conflicting interpretations of pathogenicity",
        " drug response",
        "Uncertain significance",
        "Pathogenic",
        " other",
        "protective",
        " protective",
        "association",
        "not provided",
        "Likely pathogenic",
        "Pathogenic/Likely pathogenic",
      ],
    },
    pathogenic: {
      label: "Pathogenic",
      values: [
        "Likely pathogenic",
        "Pathogenic/Likely pathogenic",
        "Conflicting interpretations of pathogenicity",
        "Pathogenic",
      ],
    },
  },
  "details3.exac_af": {
    rare_population: {
      label: "Rare in population",
      values: ["", 0.05],
    },
  },
  "Details3.af_exa": {
    rare_population: {
      label: "Rare in population",
      values: ["", 0.05],
    },
  },
  "details3.af": {
    rare_frequency: {
      label: "Rare in population",
      values: ["", 0.05],
    },
  },
  "details3.af_1000g": {
    rare_frequency: {
      label: "Rare in population",
      values: ["", 0.05],
    },
  },
  "details3.gnomad_af": {
    rare_frequency: {
      label: "Rare in population",
      values: ["", 0.05],
    },
  },
  "details3.af_exome_gnomad": {
    rare_frequency: {
      label: "Rare in population",
      values: ["", 0.05],
    },
  },
  "details3.max_af": {
    rare_frequency: {
      label: "Rare in population",
      values: ["", 0.05],
    },
  },
  gene: {
    morbid_genes: {
      label: "Morbid Genes",
      values: morbidGenes,
    },
  },
  morbid: {
    morbid_genes: {
      label: "Morbid Genes",
      values: morbidGenes,
    },
  },
};
