import React, { useState } from "react";
import { connect } from "react-redux";
import { signOut } from "src/redux/actions";
import { NavLink } from "react-router-dom";
import {
  CssBaseline,
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  Apps as AppsIcon,
  Help as HelpIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  brand: {
    width: "100%",
    height: "100%",
    padding: "5px",
    fontSize: "18px",
    fontWeight: "400",
    color: "inherit",
    textDecoration: "inherit",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textAlign: "center",
}));

const defaultSidebartListItems = [
  {
    href: "/",
    icon: HomeIcon,
    title: "Home",
  },
  {
    href: "/projects",
    icon: AppsIcon,
    title: "Projects",
  },
  {
    href: "/account",
    icon: AccountCircleIcon,
    title: "My Account",
  },
  {
    href: "/settings",
    icon: SettingsIcon,
    title: "Settings",
  },
  {
    href: "/help",
    icon: HelpIcon,
    title: "Help",
  },
];

function mapDispatchToPropsSignOut(dispatch) {
  return {
    signOut: () => dispatch(signOut()),
  };
}

const ConnectedSignOutButton = (props) => {
  const handleSignOut = () => {
    props.signOut();
  };

  return (
    <Button color="inherit" onClick={handleSignOut}>
      <ExitToAppIcon /> Sign Out
    </Button>
  );
};

const SignOutButton = connect(
  null,
  mapDispatchToPropsSignOut
)(ConnectedSignOutButton);

export const ResponsiveDrawer = (props) => {
  const { window } = props;
  const { title } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <NavLink to="/" className={classes.brand}>
          Massive Analyser
        </NavLink>
      </div>
      <Divider />
      <List>
        {defaultSidebartListItems.map((item, index) => (
          <ListItem
            button
            component={NavLink}
            key={item.title.toLowerCase()}
            to={item.href}
          >
            <ListItemIcon>
              <item.icon size="20" />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <SignOutButton />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};
