import {
  Box,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Popper,
  Select,
  Typography,
} from "@material-ui/core";
import { Close, Create, Delete } from "@material-ui/icons";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { apiDeleteAnnotationStatus } from "src/api/endpoints";
import { getTranslated } from "src/components/pages/reports/utils/helpers";

const useStyle = makeStyles((theme) => ({
  paper: {
    borderRadius: 20,
    marginTop: 5,
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    padding: 10,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  iconBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  marginAuto: {
    margin: "auto",
  },
}));

function Status({
  val,
  index,
  allData,
  setRefreshData,
  setCreateDialog,
  setUpdate,
  defaultStatus,
  organizationId,
  count,
  setType,
}) {
  const classes = useStyle();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newId, setNewId] = useState();

  const handleUpdate = () => {
    setUpdate(val);
    setType("update");
    setCreateDialog((prev) => !prev);
  };

  const handleDelete = () => {
    if (!newId)
      return enqueueSnackbar(
        t(
          "Please select a status for the deleted status. This status will be replaced with deleted status."
        ),
        {
          variant: "error",
        }
      );
    apiDeleteAnnotationStatus(organizationId, val.id, newId, (data, status) => {
      if (status === 204) {
        enqueueSnackbar(t("Succesfuly deleted"), {
          variant: "success",
        });
        setRefreshData((prev) => !prev);
        setDeleteDialog((prev) => !prev);
      } else {
        enqueueSnackbar(t("This status is being used in projects."), {
          variant: "error",
        });
      }
    });
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setDeleteDialog((prev) => !prev);
    setType("create");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDeleteDialog((prev) => !prev);
  };

  const handleNewId = (e) => {
    setNewId(e.target.value);
  };

  i18next.addResourceBundle("en", "translations", {
    [getTranslated(val.label, "en")]: getTranslated(val.label, "en"),
  });
  i18next.addResourceBundle("tr", "translations", {
    [getTranslated(val.label, "en")]: getTranslated(val.label, "tr"),
  });

  return (
    <Draggable key={index} draggableId={String(index)} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          //   style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <Paper elevation={3} className={classes.paper}>
            <Popper
              open={deleteDialog}
              anchorEl={anchorEl}
              placement={"bottom"}
              transition
            >
              {({ TransitionProps }) => (
                <Fade
                  {...TransitionProps}
                  timeout={350}
                  style={{ padding: 10 }}
                >
                  <Paper>
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} className={classes.center}>
                        <Typography>
                          {t("Are you sure you want to delete?")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="select-new-id">
                            {t("Select new status to replace previous status")}
                          </InputLabel>
                          <Select
                            labelId="select-new-id"
                            style={{ width: "100%" }}
                            error={newId === "undefined"}
                            value={newId}
                            onChange={handleNewId}
                          >
                            {Boolean(allData.length) &&
                              allData.map((data) => {
                                return (
                                  <MenuItem
                                    key={data.id}
                                    disabled={data.id === val.id}
                                    value={data.id}
                                  >
                                    {t(data.label?.en)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className={classes.center}>
                        <IconButton
                          size="small"
                          color="primary"
                          style={{ padding: 8, height: 40, width: 40 }}
                          onClick={handleDelete}
                        >
                          <Delete style={{ fontSize: 22, width: "100%" }} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6} className={classes.center}>
                        <IconButton
                          size="small"
                          color="primary"
                          style={{ padding: 8, height: 40, width: 40 }}
                          onClick={handleClose}
                        >
                          <Close style={{ fontSize: 22, width: "100%" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <Box className={classes.box}>
              <Box>
                {t(getTranslated(val.label))}
                {val.id === defaultStatus && "( Default )"}
              </Box>
              {!val.is_default ? (
                <Box className={classes.iconBox}>
                  <Box className={classes.icon}>
                    <IconButton
                      size="small"
                      color="primary"
                      style={{ padding: 8, height: 40, width: 40 }}
                      onClick={handleUpdate}
                    >
                      <Create style={{ fontSize: 22, width: "100%" }} />
                    </IconButton>
                  </Box>
                  <Box className={classes.icon}>
                    <IconButton
                      size="small"
                      color="primary"
                      style={{ padding: 8, height: 40, width: 40 }}
                      onClick={handleOpen}
                    >
                      <Delete style={{ fontSize: 22, width: "100%" }} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <span style={{ padding: 8, height: 40 }}>({t("Default")})</span>
              )}
            </Box>
          </Paper>
        </div>
      )}
    </Draggable>
  );
}

export default Status;
