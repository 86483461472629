import { Checkbox, TableCell, TableRow, TextField } from "@material-ui/core";
import React, { useRef } from "react";
import { getTranslated } from "src/components/pages/reports/utils/helpers";
import { TooltipTableCell } from "src/components/pages/reports/virtualizedTable/tooltipTableCell";
import clsx from "clsx";

function VariantRow({ extraFields, variant, setExtraFields, schema, classes }) {
  const innerRef = useRef();

  const selectRow = (id) => {
    if (extraFields.secondary_findings.includes(id)) {
      const index = extraFields.secondary_findings.indexOf(id);
      if (index > -1) {
        extraFields.secondary_findings.splice(index, 1);
      }
      extraFields.primary_tissues.push(id);
    } else {
      const index = extraFields.primary_tissues.indexOf(id);
      if (index > -1) {
        extraFields.primary_tissues.splice(index, 1);
      }
      extraFields.secondary_findings.push(id);
    }
    setExtraFields({ ...extraFields });
  };

  const addCommentToRow = (id, value) => {
    extraFields.variant_comments = {
      ...extraFields.variant_comments,
      [id]: value,
    };

    setExtraFields({ ...extraFields });
  };

  return (
    <TableRow
      className={clsx({
        [classes.primary_tissues]: !extraFields.secondary_findings.includes(
          variant.___row___
        ),
        [classes.secondary_findings]: extraFields.secondary_findings.includes(
          variant.___row___
        ),
      })}
    >
      <TableCell>
        <Checkbox
          color={
            extraFields.secondary_findings.includes(variant.___row___)
              ? "secondary"
              : "primary"
          }
          checked={true}
          onChange={(e) => selectRow(variant.___row___)}
        />
      </TableCell>
      {Object.keys(schema).map((key, index) => {
        const column = schema[key];
        return (
          (!column.representation.options.hasOwnProperty("visible") ||
            column.representation.options.visible) && (
            <TableCell ref={innerRef} key={index}>
              <TooltipTableCell
                column={column}
                className={""}
                height={200}
                alignment={column.numeric || false ? "right" : "left"}
                rowData={variant}
                innerRef={innerRef}
                data={variant[column.name]}
                exceptions={[]}
              />
            </TableCell>
          )
        );
      })}
      <TableCell>
        <TextField
          variant="outlined"
          style={{ minWidth: 200 }}
          value={extraFields.variant_comments[variant.___row___] || ""}
          onChange={(e) => addCommentToRow(variant.___row___, e.target.value)}
          label={getTranslated("Comment")}
        />
      </TableCell>
    </TableRow>
  );
}

export default VariantRow;
