// export const projectTypeForms = {
//   exome_fastq: {
//     name: "",
//     sample_kind: "paired_end",
//     kit: "twist",
//     sequencer: "mgi",
//     pipeline_class: "germline",
//     description: null,
//     project_type: "exome_fastq",
//   },
//   exome_vcf: {
//     name: "",
//     sample_kind: "single_end",
//     kit: "twist",
//     sequencer: "mgi",
//     pipeline_class: "germline_vcf",
//     description: null,
//     project_type: "exome_vcf",
//   },
//   exome_bam: {
//     name: "",
//     sample_kind: "single_end",
//     kit: "twist",
//     sequencer: "mgi",
//     pipeline_class: "germline_bam",
//     description: null,
//     project_type: "exome_bam",
//   },
//   trio: {
//     name: "",
//     sample_kind: "paired_end",
//     kit: "twist",
//     sequencer: "mgi",
//     pipeline_class: "trio",
//     description: null,
//     project_type: "trio",
//   },
//   acnv: {
//     name: "",
//     sample_kind: "single_end",
//     kit: "twist",
//     sequencer: "mgi",
//     pipeline_class: "acnv",
//     description: null,
//     project_type: "acnv",
//   },
// };

export const projectTypeForms = {
  exome_fastq: {
    name: "",
    sample_kind: "paired_end",
    kit: "",
    sequencer: "",
    pipeline_class: "",
    description: null,
    project_type: "exome_fastq",
  },
  exome_vcf: {
    name: "",
    sample_kind: "single_end",
    kit: "",
    sequencer: "mgi",
    pipeline_class: "",
    description: null,
    project_type: "exome_vcf",
  },
  exome_bam: {
    name: "",
    sample_kind: "single_end",
    kit: "",
    sequencer: "mgi",
    pipeline_class: "",
    description: null,
    project_type: "exome_bam",
  },
  trio: {
    name: "",
    sample_kind: "paired_end",
    kit: "",
    sequencer: "",
    pipeline_class: "trio",
    description: null,
    project_type: "trio",
  },
  acnv: {
    name: "",
    sample_kind: "single_end",
    kit: "",
    sequencer: "mgi",
    pipeline_class: "acnv",
    description: null,
    project_type: "acnv",
  },
  nanopore: {
    nanopore: {
      name: "",
      kit: "",
      description: null,
      sample_kind: "single_end",
      sequencer: "nanopore",
      pipeline_class: "germline",
      project_type: "exome_fastq",
    },
  },
  duo: {
    name: "",
    description: null,
    kit: "ces",
    sequencer: "mgi",
    sample_kind: "paired_end",
    pipeline_class: "duo",
    project_type: "duo",
  },
};
