import { Avatar, Grid, makeStyles, Paper, Tooltip } from "@material-ui/core";
import { format } from "date-fns";
import { CalendarToday } from "@material-ui/icons";
import React from "react";
import typography from "src/theme/typography";
import ProgressBar from "./progressBar";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import ProjectName from "./projectName";
import { useTranslation } from "react-i18next";
import { MdCheck, MdOutlineCancel } from "react-icons/md";
const useStyles = makeStyles((theme) => ({
  row: {
    border: ".5px solid rgba(0,0,0,0.1)",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    borderRadius: 10,
    transition: "all .3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[3],
      backgroundColor: theme.palette.background.secondBackground,
    },
    margin: "0 10px",
    padding: 10,
    cursor: "pointer",
  },
  center: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    gap: "10px",
    alignItems: "center",
  },
  status: {
    height: "100%",
    "& div[status]": {
      borderRadius: 10,
      height: "100%",
      display: "inline-block",
      backgroundColor: "#6FCF97",
      width: 5,
    },
    "& div[status='failed'] , div[status='cancelled']": {
      backgroundColor: "#EB5757",
    },
    "& div[status='analysing']": {
      animation: `$tableRow 3000ms ${theme.transitions.easing.easeOut} infinite`,
      backgroundColor: "#F7B924",
    },
    "& div[status='completed']": {
      backgroundColor: "#6FCF97",
    },
    "& div[status='pending']": {
      animation: `$tableRow 3000ms ${theme.transitions.easing.easeOut} infinite`,
      backgroundColor: "#3f51b5",
    },
  },
  "@keyframes tableRow": {
    "0%": {
      opacity: 0.3,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.3,
    },
  },
  typography: {
    height: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& p": {
      ...typography.h6,
      opacity: 0.5,
    },
    "& h2": {
      ...typography.h3,
    },
  },
  date: {
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  },
  kit: {
    fontSize: 12,
  },
  dateIcon: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "end",
    },
  },
  progress: {
    height: "100%",
    transition: "1s ease all",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#F7B924 !important",
    },
    "& .MuiLinearProgress-root": {
      padding: 5,
      borderRadius: 20,
    },
  },
  avatar: {
    fontSize: 30,
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

function Row({ val, ...props }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const rowClickHandler = (event) => {
    navigate(`/projects/${val.project ? val.project.id : val.id}`);
  };
  return (
    <Grid item xs={12} key={props.key}>
      <Paper className={classes.row} onClick={rowClickHandler}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} md={4} className={classes.status}>
            <Grid container>
              <Grid item className={classes.center}>
                <Grid container>
                  <Grid item xs={1}>
                    <div status={val.status} />
                  </Grid>
                  <Grid item xs={4}>
                    <Tooltip title={val.creator.created_by} placement="top">
                      <Avatar
                        className={classes.avatar}
                        src={val.creator.avatarUrl ? val.creator.avatarUrl : ""}
                      >
                        {val.creator?.created_by?.charAt(0)}
                      </Avatar>
                    </Tooltip>
                  </Grid>
                  <Grid item md={7}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={classes.typography}
                    >
                      {val.project ? (
                        <ProjectName
                          name={val.project.name}
                          style={{ fontSize: 12, opacity: 0.8 }}
                          title={t("Last Projects")}
                          variant="h5"
                          component="h6"
                        />
                      ) : (
                        ""
                      )}
                      <ProjectName
                        name={val.name}
                        title={
                          val.project ? t("Last Samples") : t("Last Projects")
                        }
                        style={{ fontSize: 14 }}
                        variant="h4"
                        component="h5"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.typography}
            >
              <Grid
                item
                sm={3}
                xs={12}
                className={clsx(classes.date, classes.dateIcon)}
              >
                <CalendarToday />
              </Grid>
              <Grid
                item
                sm={9}
                xs={12}
                className={classes.date}
                style={{ fontSize: 14 }}
              >
                {format(new Date(val.created_at), "dd/MM/yyyy")}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={clsx(classes.progress, classes.kit)}
            >
              <Grid item xs={12}>
                {val.project ? val.project.pipeline.name : val.pipeline.name}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.progress}
            >
              <ProgressBar
                fail={
                  <MdOutlineCancel
                    style={{
                      fill: "#EB5757",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                }
                classes={classes}
                completed={
                  <MdCheck
                    style={{
                      fill: "#6FCF97",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                }
                value={val.progress}
                status={val.status}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default Row;
