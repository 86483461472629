/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { apiLoadFilteredRowIds, apiSendVariantRows } from "src/api";
import { getBaseURL } from "src/config/api";
import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  CloudDownload as CloudDownloadIcon,
} from "@material-ui/icons";
import { useInterval } from "src/components/utils";
import { connect } from "react-redux";
import FlagIcon from "src/components/FlagIcon";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  langDropDown: {
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  downlaodLink: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1rem",
    textDecoration: "none !important",
    "& svg": {
      width: "5rem",
      height: "5rem",
    },
  },
  countDownText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
    "& #countdown": {
      fontSize: "3rem",
    },
  },
  hidden: {
    display: "none",
  },
}));

const countryFromLang = {
  en_GB: "gb",
  de: "de",
  ru: "ru",
  fr: "fr",
  it: "it",
  tr_TR: "tr",
  ar: "sa",
};

const langList = {
  en_GB: "English",
  // de: "Deutsch",
  // ru: "Pусский",
  // fr: "Française",
  // it: "Italiano",
  tr_TR: "Türkçe",
  // ar: "العربية",
};

const CustomDropdown = (props) => {
  const classes = useStyles();
  const { values, name, LabelTitle, langValue, setLangValue } = props;
  const [value, setValue] = useState(langValue);

  const handleChange = (event) => {
    const newVal = event.target.value;
    setValue(newVal);
    setLangValue(newVal);
  };

  return (
    <TextField
      fullWidth
      id={`select-${LabelTitle.toLowerCase()}`}
      name={name}
      select
      value={value}
      onChange={handleChange}
      label={LabelTitle}
      variant={"outlined"}
      required
      className={classes.langDropDown}
      margin="dense"
    >
      {Object.keys(values).map((langKey, index) => (
        <MenuItem value={langKey} key={index}>
          <FlagIcon code={countryFromLang[langKey]} className={classes.flag} />
          {langList[langKey]}
        </MenuItem>
      ))}
    </TextField>
  );
};

const mapStateToProps = (state) => {
  return { appConf: state.appConf };
};

const pdfPipelineClassSettings = [
  "germline",
  "germline_bam",
  "nanopore",
  "germline_vcf",
  "acnv",
  "somatic",
  "somatic_vcf",
  "somatic_bam",
  "trio",
  "acnv",
];

const ConnectedDownloadDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    setOpen,
    downloadLink,
    setDownloadLink,
    reportMeta,
    checkedRows,
    reportId,
  } = props;
  const [countDown, setCountDown] = useState(160);
  const [lang, setLang] = useState(props.appConf.lang);
  const { t } = useTranslation();

  useInterval(
    () => {
      setCountDown((prevCount) => {
        if (prevCount < 1) {
          setDownloadLink(undefined);
          return 160;
        }
        return prevCount - 1;
      });
    },
    downloadLink && open ? 1000 : null
  );

  useEffect(() => {
    setLang(props.appConf.lang);
  }, [props.appConf.lang]);

  const handleClose = () => {
    setCountDown(160);
    setDownloadLink(undefined);
    setLang(props.appConf.lang);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="download-dialog-title"
      aria-describedby="download-dialog-description"
    >
      <DialogTitle id="download-dialog-title">
        {t("Report file is ready")}
      </DialogTitle>
      <DialogContent>
        {!pdfPipelineClassSettings.includes(reportMeta.pipeline_class) && (
          <>
            <Typography>{t("Select the report language")}</Typography>
            <CustomDropdown
              name={"report_language"}
              langValue={lang}
              setLangValue={setLang}
              LabelTitle={t("Report Language")}
              values={langList}
            />
          </>
        )}
        <Typography>
          {t(
            "download_link_intro",
            "Your report file is ready. Download it using the link below: "
          )}
        </Typography>
        <Link
          href={
            !pdfPipelineClassSettings.includes(reportMeta.pipeline_class) ||
            downloadLink !== "local"
              ? `${downloadLink}?l=${lang}`
              : `${reportId}/pdf/false?variant_ids=${
                  checkedRows.length > 0 ? checkedRows.join(",") : "0"
                }`
          }
          target="_blank"
          className={classes.downlaodLink}
        >
          <CloudDownloadIcon />
          {t("Download report file")}
        </Link>
        <Typography className={classes.countDownText}>
          <Trans i18nKey="downloadLinkExpiryMessage">
            This link will expire in <span id="countdown">{{ countDown }}</span>{" "}
            seconds
          </Trans>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DownloadDialog = connect(mapStateToProps)(ConnectedDownloadDialog);

const AlertDialog = (props) => {
  const {
    open,
    setOpen,
    reportId,
    sendCheckedRows,
    checkedRows,
    setDownloadLink,
    setCheckedRows,
  } = props;
  const [confirmed, setConfirmed] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (confirmed && checkedRows.length) {
      sendCheckedRows(true, checkedRows);
      setConfirmed(false);
    }
  }, [confirmed, checkedRows]);

  const handleClose = () => {
    setDownloadLink(undefined);
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    setConfirmed(true);
    apiLoadFilteredRowIds(
      reportId,
      {
        filters: {
          combinator: "and",
          rules: [
            {
              field: "main.pathogenicity",
              operator: "in",
              value: "0,1",
            },
          ],
        },
      },
      (data, status) => {
        if (status === 200) {
          if (!data.count) {
            enqueueSnackbar(
              t(
                "There are no Pathogenic or likely pathogenic variants in this report."
              ),
              {
                variant: "error",
              }
            );
            return;
          }
          setCheckedRows(data.rows);
        } else {
          enqueueSnackbar(
            t("There was an error while selecting the rows. Please try again."),
            {
              variant: "error",
            }
          );
        }
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("Proceed without selecting rows?")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            "no_rows_selected_message",
            "You have not selected any rows for the report. Only records with 'Pathogenic' and 'Likely pathogenic' significancy will be included in your report. Do you want to proceed?"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("No")}
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          color="primary"
          autoFocus
        >
          {t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ReportGenerator = (props) => {
  const classes = useStyles();
  const { reportId, reportMeta, checkedRows, setCheckedRows, disabled } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [downloadLink, setDownloadLink] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDownloadDialogOpen(Boolean(downloadLink));
  }, [downloadLink]);

  const sendCheckedRows = (noRowsSelected, rows) => {
    setLoading(true);
    // if (reportMeta.pipeline_class !== "germline") {
    apiSendVariantRows(
      reportId,
      { rows: rows ?? checkedRows },
      (data, status) => {
        setLoading(false);
        if (status === 200) {
          // safeLogger(`${getBaseURL()}${data.download_address}`);
          if (!pdfPipelineClassSettings.includes(reportMeta.pipeline_class)) {
            setDownloadLink(`${getBaseURL()}${data.download_address}`);
          } else {
            setDownloadLink("local");
          }
        } else {
          // show error
          safeLogger(data);
        }
        if (
          noRowsSelected &&
          !pdfPipelineClassSettings.includes(reportMeta.pipeline_class)
        ) {
          setCheckedRows([]);
        }
      }
    );
    // }
  };

  const checkRowsStatus = () => {
    if (!checkedRows || !Boolean(checkedRows.length)) {
      if (reportMeta.project_type === "acnv") {
        apiLoadFilteredRowIds(reportId, {}, (data, status) => {
          if (status === 200) {
            setCheckedRows([...data.rows]);
            sendCheckedRows(undefined, data.rows);
          } else {
            enqueueSnackbar(
              t(
                "There was an error while selecting the rows. Please try again."
              ),
              {
                variant: "error",
              }
            );
          }
        });
      } else {
        setAlertDialogOpen(true);
      }
    } else {
      sendCheckedRows();
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        title={t("You can report maximum 200 variants at once.")}
        disableHoverListener={checkedRows.length <= 200}
      >
        <Box style={{ display: "flex" }}>
          {reportMeta.pipeline_class !== "duo" && (
            <Button
              size="small"
              startIcon={<AssignmentTurnedInIcon />}
              disabled={disabled || loading || checkedRows.length >= 200}
              onClick={checkRowsStatus}
              variant="contained"
              color="primary"
              style={{ marginRight: "1rem" }}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {t("Generate Report")}
            </Button>
          )}
        </Box>
      </Tooltip>
      <AlertDialog
        setDownloadLink={setDownloadLink}
        open={alertDialogOpen}
        setOpen={setAlertDialogOpen}
        sendCheckedRows={sendCheckedRows}
        reportId={reportId}
        checkedRows={checkedRows}
        setCheckedRows={setCheckedRows}
      />
      <DownloadDialog
        reportId={reportId}
        checkedRows={checkedRows}
        reportMeta={reportMeta}
        open={downloadDialogOpen}
        setOpen={setDownloadDialogOpen}
        downloadLink={downloadLink}
        setDownloadLink={setDownloadLink}
      />
    </React.Fragment>
  );
};

export default ReportGenerator;
