import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TextField, Button, makeStyles } from '@material-ui/core';
import { Save as SaveIcon } from "@material-ui/icons";
import { pageSizes } from "../../pages/reports/utils/helpers";
import { setSampleSize } from 'src/redux/actions';
import { apiUpdateProfile } from "src/api";
import safeLogger from "src/services/safeLogger";

const SampleSizeSection = ({ userObject, loadUser }) => {
  const useStyles = makeStyles((theme) => ({
    pageSize: {
      minWidth: 100,
    },
    section: {
      paddingTop: "20px",
    },
    submit: {
      marginLeft: "10px"
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const updateProfileCallback = (data, status) => {
    if (status === 200) {
      loadUser(data);
    } else {
      safeLogger(status, data);
    }
  };

  const handlePageSize = (e) => {
    dispatch(setSampleSize(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    apiUpdateProfile({ settings: userObject.settings }, updateProfileCallback);
  };

  const sizeState = useSelector((state) => state.userObject.settings.general.sampleSize);

  return (
    <div className={classes.section}>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          id="outlined-select-currency-native"
          select
          value={sizeState ? sizeState : 10}
          onChange={handlePageSize}
          className={classes.pageSize}
          SelectProps={{
            native: true,
          }}
        >
          {pageSizes.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          startIcon={<SaveIcon />}
        >
          {t("Save")}
        </Button>
      </form>
    </div>
  );
};

export default SampleSizeSection;
