/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Badge,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Link,
  Button,
} from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import { getBaseURL, authConf } from "src/config/api";
import { isJSONString } from "src/components/utils";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { updateActiveProjects, updateActiveSamples } from "src/redux/actions";
import { useTranslation } from "react-i18next";
import { apiLoadMoreNotifications, apiMarkNotificationsRead } from "src/api";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    borderBottom: "1px solid #e1e1e1",
    "&.unread": {
      backgroundColor: "#eeee2c54",
    },
    "&.unread:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    activeSamples: state.activeSamples,
    activeProjects: state.activeProjects,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateActiveSamples: (data) => dispatch(updateActiveSamples(data)),
    updateActiveProjects: (data) => dispatch(updateActiveProjects(data)),
  };
}

const ConnectedNotificationBox = (props) => {
  const classes = useStyles();
  const {
    userObject,
    activeSamples,
    activeProjects,
    updateActiveSamples,
    updateActiveProjects,
  } = props;
  const [notifications, setNotifications] = useState(undefined);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [channel, setChannel] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  safeLogger(userObject);

  useEffect(() => {
    safeLogger(notifications);
  }, [notifications]);

  useEffect(() => {
    if (userObject) {
      setNotificationsCount(userObject.notifications.count);
      setNotifications(userObject.notifications.latest);
    }
  }, [userObject]);

  useEffect(() => {
    if (notifications && userObject && !channel) {
      const notificationURL = `${getBaseURL()}/notifications/${
        userObject.id
      }?token=${localStorage.getItem(authConf.LOCAL_STORAGE_TOKEN_NAME)}`;
      const evtSource = new EventSource(notificationURL);
      setChannel(evtSource);
      evtSource.onmessage = function (event) {
        safeLogger(event);
        safeLogger(event.data);
        if (isJSONString(event.data)) {
          const notification = JSON.parse(event.data);
          if (notification.id) {
            switch (notification?.kind) {
              case "sample_status_update":
                for (let i = 0; i < notification.message.samples.length; i++) {
                  if (!(notification.message.samples[i].id in activeSamples)) {
                    activeSamples[notification.message.samples[i].id] = {};
                  }
                  activeSamples[notification.message.samples[i].id]["status"] =
                    notification.message.status;
                  if (
                    "reports" in notification.message &&
                    notification.message.reports.length
                  ) {
                    activeSamples[notification.message.samples[i].id][
                      "reports"
                    ] = notification.message.reports;
                  }
                }
                updateActiveSamples(JSON.parse(JSON.stringify(activeSamples)));
                break;
              case "project_status_update":
                if (!(notification.message.project.id in activeProjects)) {
                  activeProjects[notification.message.project.id] = {};
                }
                activeProjects[notification.message.project.id]["status"] =
                  notification.message.status;
                updateActiveProjects(
                  JSON.parse(JSON.stringify(activeProjects))
                );
                break;
              default:
                break;
            }
            const notification_ids = !!notifications
              ? notifications?.reduce((prev, curr) => [...prev, curr.id], [])
              : [];

            if (!notification_ids.includes(notification.id)) {
              // todo : Change notification.
              setNotifications((prev) => [notification, ...prev]);
              const message = getNotificationBody(notification);
              enqueueSnackbar(
                t(
                  message
                    .replace(/"([^"]*)"/g, '"{{name}}"')
                    .replaceAll('"', ""),
                  {
                    name: message
                      .match(/"([^"]*)"/g)
                      .map((val) => val.replace('"', ""))[0],
                  }
                ),
                {
                  variant: "info",
                }
              );
              setNewNotificationCount((count) => count + 1);
              if (
                notification?.kind === "project_status_update" &&
                notification.message.status === "completed"
              ) {
                const audio = new Audio("assets/sound/project_complete.mp3");
                audio.play();
              }
            }
          } else {
            switch (notification?.kind) {
              case "dispatch_progress_update":
                for (let i = 0; i < notification.message.samples.length; i++) {
                  if (!(notification.message.samples[i].id in activeSamples)) {
                    activeSamples[notification.message.samples[i].id] = {};
                  }
                  activeSamples[notification.message.samples[i].id][
                    "progress"
                  ] = notification.message.progress;
                }
                updateActiveSamples(JSON.parse(JSON.stringify(activeSamples)));
                if (!(notification.message.project.id in activeProjects)) {
                  activeProjects[notification.message.project.id] = {};
                }
                activeProjects[notification.message.project.id]["progress"] =
                  notification.message.project.progress;
                updateActiveProjects(
                  JSON.parse(JSON.stringify(activeProjects))
                );
                break;
              default:
                break;
            }
          }
        }
      };
    }
  }, [userObject, notifications]);

  const getNotificationBody = (notif) => {
    if (notif.message.body) return notif.message.body;
    switch (notif?.kind) {
      case "project_status_update":
        return `Project "${notif.message.project.name}" is ${notif.message.status}`;
      case "sample_status_update":
        return `Sample "${notif?.message?.samples[0]?.name}" is ${notif.message.status}`;
      default:
        return;
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    const notification_ids = [];
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].id && !notifications[i].is_read) {
        notification_ids.push(notifications[i].id);
        notifications[i].is_read = true;
      }
    }
    setNotifications(notifications);
    setAnchorEl(null);
    if (Boolean(notification_ids.length)) {
      apiMarkNotificationsRead({ ids: notification_ids }, (data, status) => {
        safeLogger(status);
      });
    }
  };

  const loadMore = () => {
    const lastId = notifications[notifications.length - 1].id;
    apiLoadMoreNotifications(lastId, (data, status) => {
      if (status === 200) {
        setNotifications((curr) => [...curr, ...data]);
      }
    });
  };

  return (
    <div>
      <IconButton
        aria-label="notifications messages"
        aria-controls="notifications-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        {notifications && (
          <Badge
            badgeContent={
              notifications.filter(
                (notif) => "is_read" in notif && !notif.is_read
              ).length
            }
            overlap="rectangular"
            color="secondary"
          >
            <NotificationsIcon />
          </Badge>
        )}
      </IconButton>
      {notifications && Boolean(notifications.length) && (
        <Menu
          id="notifications-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              minWidth: 350,
            },
          }}
        >
          {notifications &&
            notifications.map((item, index) => {
              return (
                <MenuItem
                  className={clsx(classes.menuItem, { unread: !item.is_read })}
                  key={index}
                  component={Link}
                  href={item.action_url}
                  color="textPrimary"
                >
                  {t(
                    getNotificationBody(item)
                      .replace(/"([^"]*)"/g, '"{{name}}"')
                      .replaceAll('"', ""),
                    {
                      name: getNotificationBody(item).match(/"([^"]*)"/g)[0],
                    }
                  )}
                </MenuItem>
              );
            })}
          {notifications &&
            notificationsCount &&
            notificationsCount >
              notifications.length - newNotificationCount && (
              <div>
                <Divider />
                <Button fullWidth onClick={() => loadMore()} variant="text">
                  {t("Load more")}
                </Button>
              </div>
            )}
        </Menu>
      )}
    </div>
  );
};

export const NotificationBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedNotificationBox);
