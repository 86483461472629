import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineCalculate } from "react-icons/md";

const useStyle = makeStyles((theme) => ({
  result: {
    marginTop: 0,
  },
  button: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  grid: {
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiGrid-item": {
      padding: "10px 5px",
    },
  },
}));

function LiftOverCalc() {
  const classes = useStyle();
  const { t } = useTranslation();
  const [alert, setAlert] = useState({
    textField: false,
    checkbox: false,
  });
  const [formData, setFormData] = useState({
    textField: "",
    checkbox: "",
  });
  const handleChange = (event) => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData[event.target.name] = event.target.value
      ? event.target.value
      : null;
    setFormData(newFormData);
  };
  const checkboxData = [
    ["hg19 => hg38", "hg19-to-hg38"],
    ["hg38 => hg19", "hg38-to-hg19"],
    ["hg38 => T2T v1.1", "hg38-to-t2t"],
  ];
  const handleAlert = () => {
    let hasErr = true;
    formData.textField === "" && (hasErr = false);
    formData.checkbox === "" && (hasErr = false);
    setAlert({
      textField: formData.textField === "",
      checkbox: formData.checkbox === "",
    });
    return hasErr;
  };

  const calculate = () => {
    if (handleAlert()) {
      window.open(
        `https://liftover.broadinstitute.org/#input=${formData.textField}&hg=${formData.checkbox}`
      );
    }
  };
  return (
    <Container>
      <FormControl error={Object.values(alert).filter((val) => val).length > 0}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <TextField
              name="textField"
              type="text"
              error={alert.textField}
              onKeyUp={handleChange}
              label={t("Input Cordinates")}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              aria-label="checkbox"
              name="checkbox"
              value={formData["checkbox"]}
              onChange={handleChange}
            >
              {checkboxData.map((val, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={val[1]}
                    control={<Radio alert={alert.checkbox} />}
                    label={val[0]}
                  />
                );
              })}
            </RadioGroup>
          </Grid>
          <Grid item className={classes.button} xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                calculate();
              }}
              startIcon={<MdOutlineCalculate />}
            >
              {t("Calculate")}
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Container>
  );
}

export default LiftOverCalc;
