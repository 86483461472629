import React, { useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import Page from "src/components/Page";
import { LanguageSection } from "./languageSection";
import { loadUser } from "src/redux/actions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReportSection } from "./reportSection";
import TabPanel from "src/components/tabPanel";
import ThemeChange from "src/layouts/DashboardLayout/themeChange";
import SampleSizeSection from "./sampleSizeSection";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    background: "inherit",
  },
  pageTitle: {
    marginBottom: "40px",
    color: `${theme.palette.text.primary} !important`,
  },
  sectionTitle: {
    marginBottom: "10px",
  },
  sectionDivider: {
    marginTop: "25px",
    width: "100%",
  },
}));

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => dispatch(loadUser()),
  };
}

const mapStateToProps = (state) => {
  return { userObject: state.userObject, appConf: state.appConf };
};

const ConnectedSettingsPage = (props) => {
  const classes = useStyles();
  const { loadUser, userObject, appConf } = props;
  const [tabValue, setTabValue] = useState(0);
  // const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // const theme = useSelector((state) => state.theme);
  // const dispatch = useDispatch();
  // const handleTheme = () => {
  //   dispatch(updateTheme(theme === "light" ? "dark" : "light"));
  // };

  return (
    <Page className={classes.root} title={t("Dashboard Settings")}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              className={classes.pageTitle}
              component="h1"
              variant="h2"
            >
              {t("Dashboard Settings")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tabs
              indicatorColor="primary"
              orientation="vertical"
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="Settings main tabs"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <Tab label={t("General")} />
              <Tab label={t("Advanced")} />
            </Tabs>
          </Grid>
          <Grid item xs={10}>
            <TabPanel value={tabValue} index={0}>
              <Typography
                variant="h4"
                component="h4"
                style={{ marginBottom: "15px" }}
              >
                {t("General")}
              </Typography>
              <Grid item xs={12}>
                <Typography
                  component="h2"
                  variant="h3"
                  className={classes.sectionTitle}
                >
                  {t("Language")}
                </Typography>
                <Typography>
                  {t("Choose the default dashboard language")}
                </Typography>
                <LanguageSection loadUser={loadUser} userObject={userObject} />
              </Grid>
              <Divider className={classes.sectionDivider} />
              {/* <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <Typography
                component="h2"
                variant="h3"
                className={classes.sectionTitle}
              >
                {t("Search")}
              </Typography>
              <Typography>
                {t("Choose which sections you want to view when using search")}
              </Typography>
              <SearchSection loadUser={loadUser} userObject={userObject} />
            </Grid> */}
              <Grid item xs={12} style={{ paddingTop: "20px" }}>
                <Typography
                  component="h2"
                  variant="h3"
                  className={classes.sectionTitle}
                >
                  {t("Sample Size")}
                </Typography>
                <Typography>
                  {t("Choose the default sample size for your tables")}
                </Typography>
                <SampleSizeSection loadUser={loadUser} userObject={userObject} />
              </Grid>
              <Divider className={classes.sectionDivider} />
              <Box
                paddingTop="10px"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="start"
              >
                <Typography
                  component="h2"
                  variant="h3"
                  className={classes.sectionTitle}
                >
                  {t("Change theme")}
                </Typography>
                <ThemeChange />
                <Typography>
                  {t("Choose the default dashboard language")}
                </Typography>
              </Box>
            </TabPanel>
            <Grid item xs={12}>
              <TabPanel value={tabValue} index={1}>
                <ReportSection
                  userObject={userObject}
                  loadUser={loadUser}
                  appConf={appConf}
                />
              </TabPanel>
            </Grid>
          </Grid>
          {/* <Divider className={classes.sectionDivider} /> */}
        </Grid>
      </Container>
    </Page>
  );
  // }
};

export const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSettingsPage);
