/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  makeStyles,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  Button,
} from "@material-ui/core";
import BreakpointMasonry from "src/components/masonryGrid";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import ChartWrapper from "../charts/chartWrapper";
import safeLogger from "src/services/safeLogger";
import { defaultFiltersetsConf, reportSectionsConf } from "src/config";
import { VirtualStatsTable } from "./virtualStatsTable";
import { FiltersetDialog } from "../../settings/tableConfigPage/filterset";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadUser, updateFilter, updateFilterStats } from "src/redux/actions";
import { getTranslated } from "../utils/helpers";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  tableTitle: {
    margin: "20px auto",
  },
  table: {
    "& .row-value": {
      textAlign: "end",
    },
    "& .row-value-trio": {
      textAlign: "start",
    },
  },
}));

const genebaseHeaders = [
  {
    name: "gene",
    kind: "string",
    label: "Gene",
  },
  {
    name: "coverage",
    kind: "numeric",
    label: "Coverage",
  },
];

const genebaseHeadersObject = {
  gene: {
    name: "gene",
    kind: "string",
    label: "Gene",
  },
  coverage: {
    name: "coverage",
    kind: "numeric",
    label: "Coverage",
  },
};

/**
 * Exomebase Res for table header and table header object
 */
const exomebaseResHeaders = [
  {
    name: "gene",
    kind: "string",
    label: "Gene",
  },
  {
    name: "exome",
    kind: "string",
    label: "Exome",
  },
  {
    name: "coverage",
    kind: "numeric",
    label: "Coverage",
  },
];

const exomebaseResHeadersObject = {
  gene: {
    name: "gene",
    kind: "string",
    label: "Gene",
  },
  exome: {
    name: "exome",
    kind: "string",
    label: "Exome",
  },
  coverage: {
    name: "coverage",
    kind: "numeric",
    label: "Coverage",
  },
};

/**
 * Exomebase Covarage for table header and table header object
 */
const exomebaseCovarageHeaders = [
  {
    name: "gene",
    width: 300,
    kind: "string",
    label: "Gene",
  },
  {
    name: "1X",
    width: 100,
    kind: "numeric",
    label: "1X",
  },
  {
    name: "10X",
    width: 100,
    kind: "numeric",
    label: "10X",
  },
  {
    name: "20X",
    width: 100,
    kind: "numeric",
    label: "20X",
  },
  {
    name: "50X",
    width: 100,
    kind: "numeric",
    label: "50X",
  },
  {
    name: "100X",
    width: 100,
    kind: "numeric",
    label: "100X",
  },
];

const exomebaseCovarageHeadersObject = {
  gene: {
    name: "gene",
    width: 300,
    kind: "string",
    label: "Gene",
  },
  "1X": {
    name: "1X",
    width: 100,
    kind: "numeric",
    label: "1X",
  },
  "10X": {
    name: "10X",
    width: 100,
    kind: "numeric",
    label: "10X",
  },
  "20X": {
    name: "20X",
    width: 100,
    kind: "numeric",
    label: "20X",
  },
  "50X": {
    name: "50X",
    width: 100,
    kind: "numeric",
    label: "50X",
  },
  "100X": {
    name: "100X",
    width: 100,
    kind: "numeric",
    label: "100X",
  },
};

/**
 * Exomebase Covarage for table header and table header object
 */
const exomebasePanelHeaders = [
  {
    name: "name",
    width: 300,
    kind: "string",
    label: "Name",
  },
  {
    name: "1X",
    width: 100,
    kind: "numeric",
    label: "1X",
  },
  {
    name: "10X",
    width: 100,
    kind: "numeric",
    label: "10X",
  },
  {
    name: "20X",
    width: 100,
    kind: "numeric",
    label: "20X",
  },
  {
    name: "50X",
    width: 100,
    kind: "numeric",
    label: "50X",
  },
  {
    name: "100X",
    width: 100,
    kind: "numeric",
    label: "100X",
  },
];

const exomebasePanelHeadersObject = {
  gene: {
    name: "name",
    width: 300,
    kind: "string",
    label: "Name",
  },
  "1X": {
    name: "1X",
    width: 100,
    kind: "numeric",
    label: "1X",
  },
  "10X": {
    name: "10X",
    width: 100,
    kind: "numeric",
    label: "10X",
  },
  "20X": {
    name: "20X",
    width: 100,
    kind: "numeric",
    label: "20X",
  },
  "50X": {
    name: "50X",
    width: 100,
    kind: "numeric",
    label: "50X",
  },
  "100X": {
    name: "100X",
    width: 100,
    kind: "numeric",
    label: "100X",
  },
};

/**
 * Exomebase Res for table header and table header object
 */

const trioGenebaseHeaders = [
  {
    name: "gene",
    kind: "string",
    label: "Gene",
  },
  {
    name: "coverage_mother",
    kind: "numeric",
    label: "Coverage (Mother)",
  },
  {
    name: "coverage_father",
    kind: "numeric",
    label: "Coverage (Father)",
  },
  {
    name: "coverage_child",
    kind: "numeric",
    label: "Coverage (Child)",
  },
];

const trioGenebaseHeadersObject = {
  gene: {
    name: "gene",
    kind: "string",
    label: "Gene",
  },
  coverage_mother: {
    name: "coverage_mother",
    kind: "numeric",
    label: "Coverage (Mother)",
  },
  coverage_father: {
    name: "coverage_father",
    kind: "numeric",
    label: "Coverage (Father)",
  },
  coverage_child: {
    name: "coverage_child",
    kind: "numeric",
    label: "Coverage (Child)",
  },
};

const ConnectedStatsTable = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [panel, setPanel] = useState([]);
  const dispatch = useDispatch();
  const {
    tableKey,
    tableData,
    userObject,
    reportMeta,
    version,
    appConf,
    title,
    helpInformation,
  } = props;
  const { t } = useTranslation();
  const handleFiltersChange = (e, values, type, filtersetName) => {
    if (type === "filterset-apply") {
      dispatch(
        updateFilterStats({
          [version >= 12 ? "main.gene_symbol" : "main.symbol"]: values,
        })
      );
      let tmpValues = {};
      values.forEach((value) => {
        tmpValues = { ...tmpValues, [value]: true };
      });
      dispatch(
        updateFilter({ gene: tmpValues, name: { [filtersetName]: true } })
      );
    }
  };

  return (
    <React.Fragment>
      {tableData[tableKey] && (
        <React.Fragment>
          <Box display="flex" justifyContent={"center"} alignItems="center">
            <Typography className={classes.tableTitle}>{t(title)}</Typography>
            <ChartWrapper
              title={helpInformation.label}
              helpDesc={helpInformation.description}
            />
          </Box>
          {tableKey === "genebase" ||
          tableKey === "panelPercentages" ||
          tableKey === "dijital_panel" ? (
            <Box style={{ display: "flex", gap: 10, flexDirection: "column" }}>
              <VirtualStatsTable
                version={version}
                tableData={tableData[tableKey]}
                exomebase={tableData["exomebase_res"]}
                exomebasePercent={
                  tableKey === "panelPercentages"
                    ? tableData["exomebase_percent"]
                    : []
                }
                cols={
                  tableKey === "exomebase_res"
                    ? exomebaseResHeaders
                    : tableKey === "panelPercentages"
                    ? exomebaseCovarageHeaders
                    : tableKey === "dijital_panel"
                    ? exomebasePanelHeaders
                    : genebaseHeaders
                }
                name={tableKey}
                colsObj={
                  tableKey === "exomebase_res"
                    ? exomebaseResHeadersObject
                    : tableKey === "panelPercentages"
                    ? exomebaseCovarageHeadersObject
                    : tableKey === "dijital_panel"
                    ? exomebasePanelHeadersObject
                    : genebaseHeadersObject
                }
              />
              {tableKey === "dijital_panel" && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                  >
                    {t("Digital Panel")}
                  </Button>
                  <FiltersetDialog
                    kind={"enumeration"}
                    appConf={appConf}
                    schemaId={reportMeta.schema_id}
                    colName={version >= 12 ? "main.gene_symbol" : "main.symbol"}
                    open={open}
                    setOpen={setOpen}
                    handleFiltersChange={handleFiltersChange}
                    userObject={userObject}
                    loadUser={loadUser}
                    defaultFiltersets={
                      defaultFiltersetsConf[
                        version >= 12 ? "main.gene_symbol" : "main.symbol"
                      ]
                    }
                  />
                </>
              )}
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label={`${title} table`}>
                <TableBody>
                  {Object.keys(tableData[tableKey]).map((dataKey, index) => (
                    <TableRow key={index}>
                      <TableCell>{t(dataKey)}</TableCell>
                      <TableCell className="row-value">
                        {tableData[tableKey][dataKey]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appConf: state.appConf,
    userObject: state.userObject,
  };
};

const StatsTable = connect(mapStateToProps)(ConnectedStatsTable);

const TrioStatsTable = (props) => {
  const classes = useStyles();
  const { tableData, tableKey, title, helpInformation } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box display="flex" alignItems="flex-end">
        <Typography className={classes.tableTitle}>{t(title)}</Typography>
        <ChartWrapper
          title={helpInformation.label}
          helpDesc={helpInformation.description}
        />
      </Box>
      {tableKey === "genebase" &&
      tableData[tableKey] &&
      trioGenebaseHeaders &&
      trioGenebaseHeadersObject ? (
        <VirtualStatsTable
          tableData={tableData[tableKey]}
          cols={trioGenebaseHeaders}
          colsObj={trioGenebaseHeadersObject}
        />
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label={`${title} table`}>
            {!Object.keys(tableData).includes(tableKey) && (
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{t("Mother")}</TableCell>
                  <TableCell>{t("Father")}</TableCell>
                  <TableCell>{t("Child")}</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {!Object.keys(tableData).includes(tableKey)
                ? Object.keys(tableData["child"][tableKey]).map(
                    (dataKey, index) => (
                      <TableRow key={index}>
                        <TableCell>{t(dataKey)}</TableCell>
                        <TableCell className="row-value-trio">
                          {tableData["mother"][tableKey][dataKey]}
                        </TableCell>
                        <TableCell className="row-value-trio">
                          {tableData["father"][tableKey][dataKey]}
                        </TableCell>
                        <TableCell className="row-value-trio">
                          {tableData["child"][tableKey][dataKey]}
                        </TableCell>
                      </TableRow>
                    )
                  )
                : Object.keys(tableData[tableKey]).map((dataKey, index) => (
                    <TableRow key={index}>
                      <TableCell>{t(dataKey)}</TableCell>
                      <TableCell className="row-value">
                        {tableData[tableKey][dataKey]}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

const StatsTableSection = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const {
    files,
    helpInformation,
    version,
    userObject,
    reportMeta,
    qualimapJson,
  } = props;
  const [tableData, setTableData] = useState({ ...qualimapJson });
  const [loadedComponents, setLoadedComponents] = useState({
    het_hom_ratio_res: false,
    tstv: false,
    coverage_table: false,
    tumor_burden: false,
    genebase: false,
    exomebase_res: false,
  });
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    if ("het_hom_ratio_res" in files) {
      try {
        fetch(getBaseURL() + files["het_hom_ratio_res"].download_address)
          .then((response) => {
            if (response.status === 200) return response.text();
            return;
          })
          .then((data) => {
            if (data) {
              if (reportMeta.project_type !== "trio") {
                tableData["het_hom_ratio_res"] = {};
              }
              const hetHomData = data.split("\n");
              for (let i = 0; i < hetHomData?.length; i++) {
                const rowData = hetHomData[i].split("\t");
                if (Boolean(rowData[0])) {
                  if (reportMeta.project_type === "trio") {
                    tableData[rowData[0].split(" ").pop()][
                      "het_hom_ratio_res"
                    ] = {
                      [rowData[0].replace(/(child|mother|father)/, "")]:
                        rowData[1],
                    };
                  } else {
                    tableData["het_hom_ratio_res"][rowData[0]] = rowData[1];
                  }
                }
              }
              if (mounted) {
                if (tableData) {
                  setTableData({ ...tableData });
                }
              }
            }
          })
          .then(() => {
            if (mounted) {
              setLoadedComponents((prevState) => {
                const s = Object.assign({}, prevState);
                s["het_hom_ratio_res"] = true;
                return s;
              });
            }
            return;
          });
      } catch (e) {
        safeLogger(e);
      }
    } else {
      if (mounted) {
        setLoadedComponents((prevState) => {
          const s = Object.assign({}, prevState);
          s["het_hom_ratio_res"] = true;
          return s;
        });
      }
    }

    return function cleanup() {
      mounted = false;
      return mounted;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if ("tstv" in files) {
      try {
        fetch(getBaseURL() + files["tstv"].download_address)
          .then((response) => {
            if (response.status === 200) return response.text();
            return;
          })
          .then((data) => {
            if (data) {
              tableData["tstv"] = {};
              const tstvData = data.split("\n");
              const rowData = tstvData[1].split("\t");
              tableData["tstv"]["Ts/Tv"] = rowData[2];
              if (mounted) {
                if (tableData) {
                  setTableData({ ...tableData });
                }
              }
            }
          })
          .then(() => {
            if (mounted) {
              setLoadedComponents((prevState) => {
                const s = Object.assign({}, prevState);
                s["tstv"] = true;
                return s;
              });
            }
            return;
          });
      } catch (e) {
        safeLogger(e);
      }
    } else {
      if (mounted) {
        setLoadedComponents((prevState) => {
          const s = Object.assign({}, prevState);
          s["tstv"] = true;
          return s;
        });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if ("tumor_burden" in files) {
      try {
        fetch(getBaseURL() + files["tumor_burden"].download_address)
          .then((response) => {
            if (response.status === 200) return response.text();
            return;
          })
          .then((data) => {
            if (data) {
              tableData["tumor_burden"] = {};
              const tumor_burden = data.split("\n");
              const rowData = tumor_burden[0].split("\t");
              tableData["tumor_burden"][rowData[0]] = rowData[1];
              if (mounted) {
                if (tableData) {
                  setTableData({ ...tableData });
                }
              }
            }
          })
          .then(() => {
            if (mounted) {
              setLoadedComponents((prevState) => {
                const s = Object.assign({}, prevState);
                s["tumor_burden"] = true;
                return s;
              });
            }
            return;
          });
      } catch (e) {
        safeLogger(e);
      }
    } else {
      if (mounted) {
        setLoadedComponents((prevState) => {
          const s = Object.assign({}, prevState);
          s["tumor_burden"] = true;
          return s;
        });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (reportMeta.project_type === "trio") {
      try {
        fetch(getBaseURL() + files["coverage_table-child"].download_address)
          .then((response) => response.text())
          .then((data) => {
            const coverageData = data.split("\n").slice(1);
            for (let i = 0; i < coverageData?.length; i++) {
              const rowData = coverageData[i].split("\t");
              if (Boolean(rowData[0])) {
                tableData["child"]["coverage_(inside_of_regions)"][rowData[0]] =
                  rowData[1];
              }
            }

            fetch(
              getBaseURL() + files["coverage_table-mother"].download_address
            )
              .then((response) => response.text())
              .then((data) => {
                const coverageData = data.split("\n").slice(1);
                for (let i = 0; i < coverageData?.length; i++) {
                  const rowData = coverageData[i].split("\t");
                  if (Boolean(rowData[0])) {
                    tableData["mother"]["coverage_(inside_of_regions)"][
                      rowData[0]
                    ] = rowData[1];
                  }
                }

                fetch(
                  getBaseURL() + files["coverage_table-father"].download_address
                )
                  .then((response) => response.text())
                  .then((data) => {
                    const coverageData = data.split("\n").slice(1);
                    for (let i = 0; i < coverageData?.length; i++) {
                      const rowData = coverageData[i].split("\t");
                      if (Boolean(rowData[0])) {
                        tableData["father"]["coverage_(inside_of_regions)"][
                          rowData[0]
                        ] = rowData[1];
                      }
                    }

                    if (mounted) {
                      if (tableData) {
                        setTableData({ ...tableData });
                      }
                    }
                  });
              });
          })
          .then(() => {
            if (mounted) {
              setLoadedComponents((prevState) => {
                const s = Object.assign({}, prevState);
                s["coverage_table"] = true;
                return s;
              });
            }
            return;
          });
      } catch (e) {
        safeLogger(e);
      }
    } else {
      if ("coverage_table" in files) {
        try {
          fetch(getBaseURL() + files["coverage_table"].download_address)
            .then((response) => response.text())
            .then((data) => {
              const coverageData = data.split("\n").slice(1);
              for (let i = 0; i < coverageData?.length; i++) {
                const rowData = coverageData[i].split("\t");
                if (Boolean(rowData[0])) {
                  tableData["coverage_(inside_of_regions)"][rowData[0]] =
                    rowData[1];
                }
              }
              if (mounted) {
                if (tableData) {
                  setTableData({ ...tableData });
                }
              }
            })
            .then(() => {
              if (mounted) {
                setLoadedComponents((prevState) => {
                  const s = Object.assign({}, prevState);
                  s["coverage_table"] = true;
                  return s;
                });
              }
              return;
            });
        } catch (e) {
          safeLogger(e);
        }
      } else {
        if (mounted) {
          setLoadedComponents((prevState) => {
            const s = Object.assign({}, prevState);
            s["coverage_table"] = true;
            return s;
          });
        }
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if ("exomebase_res" in files) {
      tableData["exomebase_res"] = [];
      try {
        fetch(getBaseURL() + files["exomebase_res"].download_address)
          .then((response) => response.text())
          .then((data) => {
            const exomebaseResData = data.split("\n").splice(1);
            for (let i = 0; i < exomebaseResData?.length; i++) {
              const rowData = exomebaseResData[i].split("\t");
              if (Boolean(rowData[0])) {
                tableData["exomebase_res"].push({
                  gene: rowData[0],
                  exome: rowData[1],
                  coverage: rowData[3],
                });
              }
            }
            if (tableData && mounted) {
              setTableData({ ...tableData });
            }
          })
          .then(() => {
            setLoadedComponents((prevState) => {
              const s = Object.assign({}, prevState);
              s["exomebase_res"] = true;
              return s;
            });
            return;
          });
      } catch (e) {
        safeLogger(e);
      }
    } else {
      if (mounted) {
        setLoadedComponents((prevState) => {
          const s = Object.assign({}, prevState);
          s["exomebase_res"] = true;
          return s;
        });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (reportMeta.project_type === "trio") {
      try {
        tableData["genebase"] = {};
        fetch(getBaseURL() + files["genebase-child"].download_address)
          .then((response) => response.text())
          .then((data) => {
            const genebaseData = data.split("\n").slice(1);
            for (let i = 0; i < genebaseData?.length; i++) {
              const rowData = genebaseData[i].split("\t");
              if (Boolean(rowData[0])) {
                if (!tableData["genebase"][rowData[0]]) {
                  tableData["genebase"][rowData[0]] = {};
                }
                tableData["genebase"][rowData[0]]["coverage_child"] =
                  parseFloat(rowData[1]);
              }
            }

            fetch(getBaseURL() + files["genebase-mother"].download_address)
              .then((response) => response.text())
              .then((data) => {
                const genebaseData = data.split("\n").slice(1);
                for (let i = 0; i < genebaseData?.length; i++) {
                  const rowData = genebaseData[i].split("\t");
                  if (Boolean(rowData[0])) {
                    if (!tableData["genebase"][rowData[0]]) {
                      tableData["genebase"][rowData[0]] = {};
                    }
                    tableData["genebase"][rowData[0]]["coverage_mother"] =
                      parseFloat(rowData[1]);
                  }
                }

                fetch(getBaseURL() + files["genebase-father"].download_address)
                  .then((response) => response.text())
                  .then((data) => {
                    const genebaseData = data.split("\n").slice(1);
                    for (let i = 0; i < genebaseData?.length; i++) {
                      const rowData = genebaseData[i].split("\t");
                      if (Boolean(rowData[0])) {
                        if (!tableData["genebase"][rowData[0]]) {
                          tableData["genebase"][rowData[0]] = {};
                        }
                        tableData["genebase"][rowData[0]]["coverage_father"] =
                          parseFloat(rowData[1]);
                      }
                    }

                    const tmpGenebase = [];

                    for (let item in tableData["genebase"]) {
                      if (tableData["genebase"][item]) {
                        tmpGenebase.push({
                          ...tableData["genebase"][item],
                          gene: item,
                        });
                      }
                    }
                    tableData["genebase"] = tmpGenebase;

                    if (mounted) {
                      if (tableData) {
                        setTableData({ ...tableData });
                      }
                    }
                  })
                  .then(() => {
                    if (mounted) {
                      setLoadedComponents((prevState) => {
                        const s = Object.assign({}, prevState);
                        s["genebase"] = true;
                        return s;
                      });
                    }
                    return;
                  });
              });
          });
      } catch (e) {
        safeLogger(e);
      }
    } else {
      if ("genebase" in files) {
        tableData["genebase"] = [];
        try {
          fetch(getBaseURL() + files["genebase"].download_address)
            .then((response) => response.text())
            .then((data) => {
              const genebaseData = data.split("\n").slice(1);
              for (let i = 0; i < genebaseData?.length; i++) {
                const rowData = genebaseData[i].split("\t");
                if (Boolean(rowData[0])) {
                  tableData["genebase"].push({
                    gene: rowData[0],
                    coverage: parseFloat(rowData[1]),
                  });
                }
              }
              if (mounted) {
                if (tableData) {
                  setTableData({ ...tableData });
                }
              }
            })
            .then(() => {
              if (mounted) {
                setLoadedComponents((prevState) => {
                  const s = Object.assign({}, prevState);
                  s["genebase"] = true;
                  return s;
                });
              }
              return;
            });
        } catch (e) {
          safeLogger(e);
        }
      } else {
        if (mounted) {
          setLoadedComponents((prevState) => {
            const s = Object.assign({}, prevState);
            s["genebase"] = true;
            return s;
          });
        }
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if ("gene_exons_percent_res" in files) {
      fetch(getBaseURL() + files["gene_exons_percent_res"].download_address)
        .then((response) => response.text())
        .then((data) => {
          tableData["exomebase_percent"] = [];
          const exomebasePercentData = data.split("\n");
          const tableHeader = exomebasePercentData[0].split("\t");
          exomebasePercentData.forEach((item) => {
            const rowData = item.split("\t");
            if (tableHeader) {
              tableData["exomebase_percent"].push(
                Object.assign(
                  {},
                  ...tableHeader.map((value, index) => {
                    return { [value]: rowData[index] };
                  })
                )
              );
            }
          });
        })
        .then(() => {
          if (mounted) {
            if (tableData) {
              setTableData({ ...tableData });
              let lastGene = "";
              let panelPercentages = [];
              let tmpPanelPercent = [];
              tableData["exomebase_percent"].forEach((value, index) => {
                if (index === 0) return;
                if (lastGene === value.gene) {
                  tmpPanelPercent.push(value);
                } else {
                  lastGene = value.gene;
                  panelPercentages.push(tmpPanelPercent);
                  tmpPanelPercent = [];
                  tmpPanelPercent.push(value);
                }
              });

              let newPanelPercentages = [];
              panelPercentages.forEach((value) => {
                let activeGene = "";
                let tmpExonPercantages = {};
                value.forEach((gene) => {
                  if (gene.gene !== activeGene && activeGene !== "")
                    console.error(activeGene);
                  activeGene = gene.gene;
                  let exons = JSON.parse(gene.result?.replaceAll("'", '"'));
                  Object.entries(exons).forEach((exon) => {
                    if (!tmpExonPercantages[exon[0]])
                      tmpExonPercantages[exon[0]] = 0;
                    tmpExonPercantages[exon[0]] += Number(exon[1]);
                  });
                });
                let exonPercantages = {};
                Object.entries(tmpExonPercantages).forEach(
                  (exon) =>
                    (exonPercantages[exon[0]] = (
                      exon[1] / value.length
                    ).toFixed(2))
                );
                if (value.length > 0) {
                  newPanelPercentages.push({
                    gene: activeGene,
                    ...exonPercantages,
                  });
                }
              });
              tableData["panelPercentages"] = newPanelPercentages;
              const filters =
                userObject.settings.report.filtersets["main.gene_symbol"] ??
                userObject.settings.report.filtersets["main.symbol"];
              const tmpPanel = [];
              Object.entries(filters).forEach((filter) => {
                filter[1].values.forEach((value) => {
                  if (tableData["panelPercentages"]) {
                    const index = tableData["panelPercentages"].findIndex(
                      (data) => value === data.gene
                    );
                    if (index >= 0) {
                      tmpPanel.push({
                        name: filter[1].label,
                        values: tableData["panelPercentages"][index],
                      });
                    }
                  }
                });
              });
              const newTmpPanel = [];
              let lastName = "";
              let lastIndex = -1;
              tmpPanel.forEach((val) => {
                if (val.name !== lastName) {
                  newTmpPanel.push(val);
                  lastIndex++;
                  lastName = val.name;
                } else {
                  Object.entries(val.values).forEach((arr) => {
                    if (arr[0] !== "gene") {
                      if (
                        parseFloat(newTmpPanel[lastIndex].values[arr[0]]) !== 0
                      ) {
                        newTmpPanel[lastIndex].values[arr[0]] = (
                          (parseFloat(newTmpPanel[lastIndex].values[arr[0]]) +
                            parseFloat(arr[1])) /
                          2
                        ).toFixed(2);
                      }
                    }
                  });
                }
              });
              const lastPanel = newTmpPanel.map((val) => {
                delete val.values.gene;
                return { name: val.name, ...val.values };
              });
              tableData["dijital_panel"] = lastPanel;
              setTableData({ ...tableData });
            }
          }
        });
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if ("fastp_report" in files) {
      try {
        fetch(getBaseURL() + files["fastp_report"].download_address)
          .then((response) => {
            if (response.status === 200) return response.json();
            return;
          })
          .then((data) => {
            if (data && data.summary && data.summary.after_filtering) {
              const q20Rate = data.summary.after_filtering.q20_rate;
              const q30Rate = data.summary.after_filtering.q30_rate;

              if (q20Rate !== undefined && q30Rate !== undefined) {
                const formattedQ20 = (q20Rate * 100).toFixed(2) + "%";
                const formattedQ30 = (q30Rate * 100).toFixed(2) + "%";


                tableData["globals"] = {
                  ...tableData["globals"],
                  "The rate of bases with a quality score of 20": formattedQ20,
                  "The rate of bases with a quality score of 30": formattedQ30
                };
                if (mounted) {
                  setTableData({ ...tableData });
                }
              }

            }
          })
          .then(() => {
            if (mounted) {
              setLoadedComponents((prevState) => {
                const s = Object.assign({}, prevState);
                s["fastp_report"] = true;
                return s;
              });
            }
            return;
          });
      } catch (e) {
        safeLogger(e);
      }
    } else {
      if (mounted) {
        setLoadedComponents((prevState) => {
          const s = Object.assign({}, prevState);
          s["fastp_report"] = true;
          return s;
        });
      }
    }
  
    return function cleanup() {
      mounted = false;
    };
  }, []);


  if (
    !Object.keys(loadedComponents)?.reduce(
      (prev, curr) => prev && loadedComponents[curr],
      true
    )
  ) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <BreakpointMasonry>
        {reportSectionsConf[reportMeta.project_type].statistics[
          reportMeta.schema_version
        ] ? (
          Array.from(
            reportSectionsConf[reportMeta.project_type].statistics[
              reportMeta.schema_version
            ]
          ).map((tableKey, index) => {
            const label = helpInformation[tableKey].label;

            if (helpInformation[tableKey] === undefined) {
              safeLogger("helpInformation undefined: ", tableKey);
            }
            return tableData ? (
              reportMeta.project_type === "trio" ? (
                tableKey in tableData || tableKey in tableData["child"] ? (
                  <TrioStatsTable
                    key={index}
                    version={version}
                    tableData={tableData}
                    tableKey={tableKey}
                    title={label}
                    helpInformation={helpInformation[tableKey]}
                  />
                ) : (
                  <></>
                )
              ) : (
                <StatsTable
                  key={index}
                  version={version}
                  tableKey={tableKey}
                  tableData={tableData}
                  reportMeta={reportMeta}
                  title={label}
                  helpInformation={helpInformation[tableKey]}
                />
              )
            ) : (
              <></>
            );
          })
        ) : (
          <></>
        )}
      </BreakpointMasonry>
    );
  }
};

export default StatsTableSection;
