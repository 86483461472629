/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const asc = (arr) => arr.sort((a, b) => a - b);

const getPercentile = (data, q) => {
  const arr = [];
  for (let i = 0; i < data.length; i++) {
    arr.push(data[i].y);
  }
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

const CoverageAcrossRefLineChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files } = props;
  const [exomeSections, setExomeSections] = useState(undefined);
  const [chrPrevPos, setChrPrevPos] = useState(undefined);
  const [chromosomePositions, setChromosomePositions] = useState(undefined);
  const [chromosomeLines, setChromosomeLines] = useState(undefined);
  const [qualimapJson, setQualimapJson] = useState(undefined);
  const [sampleData, setSampleData] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [gcData, setGcData] = useState(undefined);
  const [gcOptions, setGcOptions] = useState(undefined);

  const isPosInExome = (chrKey, position) => {
    if (!chrKey || !position || !exomeSections[chrKey]) {
      return false;
    }
    for (let i = 0; i < exomeSections[chrKey].length; i++) {
      //   if (chrKey === "chr1") {
      //     safeLogger(position, exomeSections[chrKey][i]);
      //   }
      if (
        position >= exomeSections[chrKey][i][0] &&
        position <= exomeSections[chrKey][i][1]
      ) {
        return true;
      }
    }
    return false;
  };

  const getChrKey = (position) => {
    const chrPosKeys = Object.keys(chromosomePositions);
    for (let i = 0; i < chrPosKeys.length; i++) {
      if (position <= chrPosKeys[i]) {
        return chromosomePositions[chrPosKeys[i]];
      }
    }
    return chromosomePositions[chrPosKeys[chrPosKeys.length - 1]];
  };

  const processData = (rawData) => {
    //calculateMean
    const labels = [];
    const data = [];
    const allData = [];
    //   let sum = 0;

    for (let i = 0; i < rawData.length - 1; i++) {
      const item = rawData[i].split("\t");
      const newItem = [parseFloat(item[0]), parseFloat(item[1])];
      allData.push(newItem[1]);
      // if (calculateMean) sum += newItem[1];
      // if (!data.length || newItem[1] !== data[data.length - 1].y) {
      //   labels.push(newItem[0]);
      const chrKey = getChrKey(newItem[0]);
      if (isPosInExome(chrKey, newItem[0])) {
        labels.push(newItem[0]);
        // data.push({ x: newItem[0], y: newItem[1] });
        data.push({ x: newItem[0], y: newItem[1] });
      }
      // }
    }

    //   const mean = calculateMean ? sum / data.length : undefined;

    return {
      labels,
      data,
      allData,
      // mean,
    };
  };

  // chart data
  useEffect(() => {
    if (chromosomeLines && exomeSections) {
      fetch(getBaseURL() + files["coverage_across_reference"].download_address)
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n").slice(1);
          const processedData = processData(rawData);

          safeLogger(processedData);

          let p75 = getPercentile(processedData.data, 0.75);
          p75 = p75 > 0 ? parseFloat((p75 * 1.8).toFixed(2)) : undefined;

          setSampleData({
            labels: processedData.labels,
            datasets: [
              {
                label: "Coverage",
                data: processedData.data,
                borderColor: colors[materialColors[3]][400],
                pointRadius: 0,
                borderWidth: 1,
              },
            ],
          });

          setOptions({
            annotation: {
              annotations: chromosomeLines,
            },
            maintainAspectRatio: false,
            responsive: true,
            title: {
              display: true,
              text: "Coverage across reference",
            },
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: { display: false },
            scales: {
              yAxes: [
                {
                  ticks: {
                    stepSize: 0.00249,
                    min: 0,
                    max: p75,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Coverage (X)",
                  },
                },
              ],
              xAxes: [
                {
                  type: "linear",
                  ticks: {
                    max: processedData.data[processedData.data.length - 1].x,
                    minRotation: 90,
                    callback: (value) => {
                      return Object.keys(chromosomePositions).includes(value)
                        ? chromosomePositions[value]
                        : undefined;
                    },
                    // callback: function (value, index, values) {
                    //   safeLogger(index);
                    //   // return processedData.labels[index];
                    //   return;
                    // },
                  },
                  scaleLabel: {
                    display: false,
                    labelString: "Position (bp)",
                  },
                },
              ],
            },
          });
        });
    }
  }, [chromosomeLines, exomeSections]);

  // sub chart data
  useEffect(() => {
    if (chromosomeLines && options && qualimapJson) {
      fetch("/assets/statistics/mapping_quality_across_reference.txt")
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n").slice(1);
          const processedData = processData(rawData);

          chromosomeLines.push({
            drawTime: "afterDatasetsDraw",
            type: "line",
            mode: "horizontal",
            scaleID: "y-axis-0",
            // value: processedData.mean,
            value: qualimapJson["coverage_(inside_of_regions)"].Mean,
            borderWidth: 1,
            borderColor: colors[materialColors[1]][300],
            borderDash: [10, 5],
            label: {
              content: "mean coverage",
              enabled: true,
              position: "right",
              fontColor: "#313131",
              fontSize: 10,
              backgroundColor: "rgba(0,0,0,0.1)",
              yAdjust: -5,
            },
          });

          setGcData({
            labels: processedData.labels,
            datasets: [
              {
                data: processedData.data,
                borderColor: colors[materialColors[4]][400],
                pointRadius: 0,
                borderWidth: 1,
              },
            ],
          });

          setGcOptions({
            annotation: {
              annotations: chromosomeLines,
            },
            maintainAspectRatio: false,
            responsive: true,
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: { display: false },
            scales: {
              yAxes: [
                {
                  ticks: {
                    stepSize: 25,
                    min: 0,
                    max: 100,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "GC (%)",
                  },
                },
              ],
              xAxes: [
                {
                  type: "linear",
                  position: "bottom",
                  ticks: {
                    max: processedData.data[processedData.data.length - 1].x,
                    // max: options.scales.xAxes[0].ticks.max,
                    minRotation: 90,
                    callback: (value) => {
                      return chromosomePositions[value];
                    },
                  },
                  afterBuildTicks: function (axis, ticks) {
                    ticks = Object.keys(chromosomePositions);
                    return ticks;
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Position (bp)",
                  },
                },
              ],
            },
          });
        });
    }
  }, [chromosomeLines, options, qualimapJson]);

  // chr positions
  useEffect(() => {
    let prevPosition = 0;
    const prevPos = {};
    const chrPositions = {};
    const chrLines = [];
    fetch("/assets/statistics/bam_chr_statsfrom_samtools")
      .then((response) => response.text())
      .then((data) => {
        let chrData = data.split("\n");
        chrData = chrData.slice(0, chrData.length - 1);
        for (let i = 0; i < chrData.length; i++) {
          const chromosome = chrData[i].split("\t");
          const position = prevPosition + parseFloat(chromosome[1]);

          const chrPosKeys = Object.keys(chrPositions);
          const chrPrevPos = chrPosKeys.length
            ? chrPosKeys[chrPosKeys.length - 1]
            : 0;
          prevPos[chromosome[0]] = parseFloat(chrPrevPos);

          chrPositions[position] = chromosome[0];
          chrLines.push({
            drawTime: "afterDatasetsDraw",
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: position,
            borderWidth: 1,
            borderColor: colors[materialColors[16]][300],
            borderDash: [10, 5],
            label: {
              // content: chrKeys[i],
              enabled: false,
              // position: "top",
              // fontColor: "#313131",
              // fontSize: 10,
              // backgroundColor: "rgba(0,0,0,0)",
              // yAdjust: (i % 2) * 15,
            },
          });
          prevPosition = position;
        }
        setChrPrevPos(prevPos);
        safeLogger(chrPositions);
        setChromosomePositions(chrPositions);
        setChromosomeLines(chrLines);
      });
  }, []);

  //qualimap json
  useEffect(() => {
    fetch("/assets/statistics/qualimapReport.json")
      .then((response) => response.json())
      .then((data) => {
        safeLogger(data);
        setQualimapJson(data);
      });
  }, []);

  // exome sections
  useEffect(() => {
    if (chromosomePositions && chrPrevPos) {
      fetch(
        "/assets/statistics/CES_v1_TE91140134_hg19_v3_190620042957_hg38_liftover.bed"
      )
        .then((response) => response.text())
        .then((data) => {
          const tmpExomeSections = {};
          let chrData = data.split("\n");
          for (let i = 0; i < chrData.length; i++) {
            const exome = chrData[i].split("\t");
            const chrKey = exome[0].split("_")[0];

            if (!tmpExomeSections[chrKey]) {
              tmpExomeSections[chrKey] = [];
            }

            tmpExomeSections[chrKey].push([
              parseFloat(exome[1]) + chrPrevPos[chrKey],
              parseFloat(exome[2]) + chrPrevPos[chrKey],
            ]);
          }
          safeLogger(tmpExomeSections);
          setExomeSections(tmpExomeSections);
        });
    }
  }, [chromosomePositions, chrPrevPos]);

  if (!sampleData || !options || !gcData || !gcOptions) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <div>
          <Line height={450} data={sampleData} options={options} />
        </div>
        <div style={{ paddingLeft: "10px" }}>
          <Line height={100} data={gcData} options={gcOptions} />
        </div>
      </ChartWrapper>
    );
  }
};

export default CoverageAcrossRefLineChart;
