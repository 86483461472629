import { projectsTableHeaders } from "./projects";
import { projectTypeForms } from "./projectTypeForms";
import { reportSectionsConf } from "./reports";
import { latestSettingsVersion } from "./userSettings";
import { defaultFiltersetsConf } from "./defaultFiltersets";

export {
  projectsTableHeaders,
  projectTypeForms,
  reportSectionsConf,
  latestSettingsVersion,
  defaultFiltersetsConf,
};

export const RECAPTCHA_SITE_KEY = "6LfdLnceAAAAAM-ORRYg2nYymuIW4N7Tpt1PKJuL";
