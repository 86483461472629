export const projectsTableHeaders = [
  {
    name: "name",
    type: "nofilter",
    label: "Title",
  },
  {
    name: "project_type",
    type: "string",
    label: "Type",
    backendName: "project_types",
  },
  {
    name: "pipeline_class",
    type: "string",
    label: "Pipeline",
    backendName: "pipeline_classes",
  },
  {
    name: "kit",
    type: "string",
    label: "Kit",
    backendName: "kits",
  },
  {
    name: "created_at",
    type: "date",
    label: "Created at",
  },
  {
    name: "created_by",
    type: "string",
    label: "User",
  },
  {
    name: "status",
    type: "progress",
    label: "Status",
  },
  {
    name: "actions",
    type: "action",
    label: "Actions",
    width: 60,
  },
];

export const projectsTableHeadersObject = {
  name: {
    type: "string",
    label: "Title",
  },
  project_type: {
    type: "string",
    label: "Type",
  },
  pipeline_class: {
    type: "string",
    label: "Pipeline",
  },
  kit: {
    type: "string",
    label: "Kit",
  },
  created_at: {
    type: "date",
    label: "Created at",
  },
  created_by: {
    type: "string",
    label: "User",
  },
  status: {
    type: "string",
    label: "Status",
  },
  actions: {
    type: "nofilter",
    label: "Actions",
    width: 60,
  },
};

export const sampleHeaders = {
  name: "Sample",
  status: "Status",
  started_at: "Started at",
  completed_at: "Completed at",
};

export const innerTableHeaders = {
  name: "Sample",
  started_at: "Started at",
  completed_at: "Completed at",
  status: "Status",
  actions: "Actions",
};

export const reportHeaders = {
  filename: "Report",
  processed_at: "Completed at",
};

export const virtualTableConf = {
  rowHeight: 53,
};

export const projectsDetailsConf = {
  exome_fastq: {
    summary: ["project_type", "pipeline_class", "kit", "sequencer"],
  },
  exome_vcf: {
    summary: ["project_type", "pipeline_class", "kit"],
  },
  exome_bam: {
    summary: ["project_type", "pipeline_class", "kit"],
  },
  trio: {
    summary: ["project_type", "pipeline_class", "kit"],
  },
  acnv: {
    summary: ["project_type", "kit"],
  },
};
