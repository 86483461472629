import { Alert } from "@material-ui/lab";
import React from "react";

function Warning({ children, style }) {
  return (
    <Alert style={style} severity="warning">
      {children}
    </Alert>
  );
}

export default Warning;
