import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  apiAcquirePayment,
  apiGetOrganizationDetails,
  apiProjectQuotePrice,
} from "src/api/endpoints";
import _ from "lodash";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    width: "50vw",
    borderRadius: theme.spacing(1),
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
    height: "100vh",
  },
  submit: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function ConnectCreditCalculate({
  projectId,
  arrangeSubmitBody,
  formData,
  setPaymentId,
  setPhase,
}) {
  const [quote, setQuote] = useState({});
  const [organization, setOrganization] = useState({});
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const submitBody = { ...arrangeSubmitBody() };
    for (const sample of submitBody["samples"]) {
      if (typeof sample.filename === "string") {
        sample.name = sample.filename;
      }

      if (typeof sample.pair_1 === "string") {
        sample.pair_1 = {
          name: sample.pair_1,
        };
      }

      if (typeof sample.pair_2 === "string") {
        sample.pair_2 = {
          name: sample.pair_2,
        };
      }
    }

    submitBody["sample_kind"] = formData["sample_kind"];
    submitBody["project_type"] = formData["project_type"];
    submitBody["pipeline_class"] = formData["pipeline_class"];
    submitBody["kit"] = formData["kit"];

    apiProjectQuotePrice(projectId, submitBody, (data, status) => {
      if (status === 200) {
        setQuote(data);
      } else if (status === 409) {
        enqueueSnackbar(
          t("Selected kit is wrong, please contact to Massive admins"),
          {
            variant: "error",
          },
        );
      }
    });
  }, []);

  useEffect(() => {
    apiGetOrganizationDetails(null, (data, status) => {
      if (status === 200) {
        setOrganization(data);
      }
    });
  }, []);

  const onSubmitClick = () => {
    const body = {};

    body["project_id"] = projectId;
    body["quote_id"] = quote.quote_id;

    apiAcquirePayment(body, (data, status) => {
      if (status === 200) {
        setPaymentId(data.payment_id);
        setPhase("upload");
      }
    });
  };

  const onCancelClick = () => {
    setPhase("canceled_credit");
  };

  if (_.isEmpty(quote) && _.isEmpty(organization)) {
    return <CircularProgress className={classes.spinner} />;
  } else
    return (
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <Box className={classes.header}>
            <Typography variant="h3">{t("Credit Quote")}</Typography>
            <Typography variant="h5">
              {t("Organization Balance: {{credit}}", {
                credit: organization.credit_balance,
              })}
            </Typography>
          </Box>
          <Typography variant="body1">
            {t(
              "This project will reserve {{credit}} credits until project is successfully done. {{credit}} credits will be spent once the project is complete.",
              {
                credit: quote.final_price,
              },
            )}
          </Typography>
          <Box className={classes.submit}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onCancelClick}
            >
              {t("Cancel")}
            </Button>
            <Button variant="outlined" color="primary" onClick={onSubmitClick}>
              {t("Submit")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
}

const mapStateToProps = (state) => {
  return {
    appConf: state.appConf,
    userObj: state.userObject,
  };
};

const CreditCalculate = connect(mapStateToProps)(ConnectCreditCalculate);

export default CreditCalculate;
