export const getFileExtension = (fileName) => {
  return fileName.split(".")[1];
};

export const isFileAcceptable = (fileName) => {
  const acceptedFileExtensions = ["fq", "fastq", "fastq.gz", "fq.gz"];
  const fileExtension = getFileExtension(fileName);

  if (!acceptedFileExtensions.includes(fileExtension)) {
    // file extension not supported
    return false;
  }

  fileName = fileName.split(".")[0].split("_");
  if (fileName.length < 3) {
    //file name format is not correct
    return false;
  }

  return true;
};

export const getFileUniqueName = (fileName) => {
  if (isFileAcceptable(fileName)) {
    return fileName.split(".")[0].split("_").slice(0, 3).join("_");
  }

  return false;
};

export const getFileCellValues = (fileName) => {
  if (isFileAcceptable(fileName)) {
    return getFileUniqueName(fileName).split("_");
  }
};

export const getFilePairNumber = (fileName) => {
  //not exactly pair number
  const strippedFileName = fileName.split(".")[0].split("_");
  return strippedFileName[strippedFileName.length - 1];
};

export const findParedFile = (fileName, files) => {
  if (isFileAcceptable(fileName)) {
    const pairNumber = getFilePairNumber(fileName);
    fileName = getFileUniqueName(fileName);
    let pairFile = false;
    for (let i = 0; i < files.length; i++) {
      if (isFileAcceptable(files[i].name)) {
        const testPairNumber = getFilePairNumber(files[i].name);
        const testFile = getFileUniqueName(files[i].name);
        if (fileName === testFile && pairNumber !== testPairNumber) {
          pairFile = files[i];
        }
      }
    }
    return pairFile;
  } else return false;
};
