/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { CircularProgress, colors, Paper, withStyles } from "@material-ui/core";
import { TooltipTableCell } from "src/components/pages/reports/virtualizedTable/tooltipTableCell";
import { FilterTableHeader } from "src/components/pages/reports/virtualizedTable/headers";
import { AutoSizer, Column, Table } from "react-virtualized";
import { cellDataGetter } from "src/components/pages/reports/utils/virtualizedCustom";
import { useTranslation, withTranslation } from "react-i18next";
import { filterRows, getBaseLanguage } from "../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { updateFilterStats } from "src/redux/actions";
import OpenCloseRow from "./exomebasePercantage";

const styles = (theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    flexWrap: "wrap",
  },
  applyFiltersBtn: {
    color: theme.palette.common.white,
    background: colors.green[400],
    "&:hover": {
      background: colors.green[600],
    },
  },
  filterableHeader: {
    paddingLeft: 0,
    borderBottom: "1px solid #E0E0E",
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    color: `${theme.palette.text.primary} !important`,

    "& > *": {
      fill: theme.palette.text.primary,
      // color: theme.palette.background.paper,
    },
    "& > div": {
      borderBottom: "none",
      // paddingRight: 0,
      // paddingLeft: 0,
      padding: 0,
    },
    "&:first-child > div": {
      paddingLeft: "3px",
    },
    "& .header-tooltip-wrapper": {
      display: "inline-flex",
      cursor: "pointer",
      color: `${theme.palette.text.primary} !important`,
    },
    "& .filter-button-wrapper": {
      paddingTop: "6px",
      "& *": {
        color: `${theme.palette.text.primary} !important`,
      },
    },
    "& .filtered path": {
      color: colors.orange[700],
    },
  },
  sliderMenu: {
    "& ul": {
      width: "200px",
      padding: "40px 20px 20px",
    },
  },
  autocompleteMenuItem: {
    "& .menuItemCheckBox": { marginRight: "1px" },
    "& .menuItemLabel": {
      display: "inline-block",
      verticalAlign: "middle",
      width: "200px",
    },
  },
  sortIndicator: {
    width: "20px",
  },
  ascSortIndicator: {
    transform: "rotate(90deg)",
  },
  descSortIndicator: {
    transform: "rotate(-90deg)",
  },
  table: {
    "& .ReactVirtualized__Table__rowColumn": {
      "&:first-child": {
        height: "100%",
        border: 0,
      },
      height: "100%",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
    "& .ReactVirtualized__Table__headerRow": {
      // paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
      // background: theme.palette.primary.main,
    },
    "& .ReactVirtualized__Table__headerColumn": {
      paddingRight: "0 !important",
      "&:first-child": {
        border: 0,
      },
      borderLeft: "1px solid #e0e0e0",
    },
    // "& .ReactVirtualized__Table__headerRow > div:first-child": {
    //   marginRight: "62px",
    // },
  },
  loadingTableRow: {
    border: "5px solid #FFF",
    cursor: "auto !important",
    "& .MuiSkeleton-root": {
      backgroundColor: "rgba(55, 71, 79, 0.2)",
    },
  },
  tableRow: {
    cursor: "auto",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    background: theme.palette.background.paper,
  },
  tableHeaderColor: {
    color: `${theme.palette.text.primary} !important`,
  },
  tableCell: {
    // width: "100%",
    fontSize: "0.875rem",
  },
  noClick: {
    cursor: "initial",
  },
  hidden: {
    display: "none",
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
  };

  constructor(props) {
    super(props);
    // Don't do this!
    this.state = { color: props.row };
  }

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  TooltipCellRenderer = ({ cellData, columnIndex, dataKey, rowData }) => {
    const { columns, classes, rowHeight } = this.props;

    return (
      <TooltipTableCell
        column={columns[columnIndex]}
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        height={rowHeight}
        // alignment={
        //   (columnIndex != null && columns[columnIndex].numeric) || false
        //     ? "right"
        //     : "left"
        // }
        data={cellData}
        exceptions={[]}
      />
    );
  };

  rowRenderer = ({ className, columns, index, key, rowData, style }) => {
    const a11yProps = { "aria-rowindex": index + 1 };

    if (rowData) {
      return (
        <OpenCloseRow
          a11yProps={a11yProps}
          className={className}
          style={style}
          rowData={rowData}
          exomebase={
            this.props.exomebase &&
            this.props.exomebase.filter((item) => item.gene === rowData.gene)
          }
          exomebasePercantage={
            this.props.exomebasePercent &&
            this.props.exomebasePercent.filter(
              (item) => item.gene === rowData.gene
            )
          }
          key={key}
          columns={columns}
        />
      );
    }
  };

  headerRenderer = ({ label, columnIndex }) => {
    const {
      sortState,
      setSortState,
      headerHeight,
      columns,
      setColumns,
      classes,
      filterState,
      t,
    } = this.props;

    return (
      <FilterTableHeader
        type="local"
        sortState={sortState}
        setSortState={setSortState}
        headerHeight={headerHeight}
        columns={columns}
        setColumns={setColumns}
        classes={classes}
        label={t(label)}
        columnIndex={columnIndex}
        filterState={filterState}
        headerIndex={columnIndex}
        tableKind="stats"
        filterType={
          columns[columnIndex].kind === "numeric"
            ? "range"
            : Object.keys(columns[columnIndex].filterValues)?.length < 5
            ? "select"
            : "search"
        }
      />
    );
  };

  render() {
    const { classes, columns, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref={(ref) => (this.tableRef = ref)}
            scrollToAlignment="start"
            height={height}
            width={width}
            rowHeight={53}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
            rowRenderer={this.rowRenderer}
          >
            {columns.map(({ name, ...other }, index) => {
              return (
                <Column
                  key={name}
                  cellDataGetter={cellDataGetter}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={clsx(classes.flexContainer)}
                  cellRenderer={this.TooltipCellRenderer}
                  dataKey={name}
                  {...other}
                  width={other.width || (width - 20) / columns?.length}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = withStyles(styles)(
  withTranslation()(MuiVirtualizedTable)
);

export const VirtualStatsTable = (props) => {
  const {
    tableData,
    cols,
    name,
    colsObj,
    exomebase,
    version,
    exomebasePercent,
  } = props;
  const [columns, setColumns] = useState(cols);
  const [defaultRows, setDefaultRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filterState, setFilterState] = useState({});
  const [isInitialFiltered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortState, setSortState] = useState({
    created_at: "DESC",
  });
  const [isInitiallySorted, setIsInitiallySorted] = useState(false);
  const geneState = useSelector((state) => state.activeGeneFilters);

  function sortRows(rows, sortState) {
    const collator = new Intl.Collator(getBaseLanguage("en"), {
      sensitivity: "base",
    }); // support for other locales?
    for (let item in sortState) {
      rows.sort((a, b) => {
        if (colsObj[item] === "string") {
          return sortState[item] === "ASC"
            ? collator.compare(a[item], b[item])
            : collator.compare(b[item], a[item]);
        } else {
          if (a[item] > b[item]) return sortState[item] === "ASC" ? 1 : -1;
          if (a[item] < b[item]) return sortState[item] === "ASC" ? -1 : 1;
          return 0;
        }
      });
    }

    return rows;
  }

  useEffect(() => {
    let mounted = true;

    if (sortState) {
      const sortedRows = sortRows(filteredRows, sortState);
      if (mounted) {
        setFilteredRows(sortedRows.slice(0));
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [sortState]);

  /**
   * Filterin stats table on componen mount
   */
  useEffect(() => {
    setDefaultRows([...tableData]);
    for (let i = 0; i < columns?.length; i++) {
      if (columns[i].kind === "numeric") {
        columns[i].filterRange = [];
      } else {
        columns[i].filterValues = {};
      }
      columns[i].activeFilterCount = 0;
      if (geneState[columns[i].name]) {
        columns[i].activeFilterCount++;
        columns[i].filterValues = geneState[columns[i].name];
      }
      for (let j = 0; j < tableData?.length; j++) {
        if (columns[i].kind === "numeric") {
          if (!columns[i]?.filterRange?.length) {
            columns[i].filterRange[0] = columns[i].filterRange[1] =
              tableData[j][columns[i].name];
          } else {
            if (
              typeof tableData[j][columns[i].name] !== "string" &&
              typeof columns[i].filterRange[0] !== "string" &&
              typeof columns[i].filterRange[1] !== "string"
            ) {
              if (tableData[j][columns[i].name] < columns[i].filterRange[0]) {
                columns[i].filterRange[0] = columns[i].minValue =
                  tableData[j][columns[i].name];
              }
              if (tableData[j][columns[i].name] > columns[i].filterRange[1]) {
                columns[i].filterRange[1] = columns[i].maxValue =
                  tableData[j][columns[i].name];
              }
            } else {
              if (
                parseFloat(tableData[j][columns[i].name]) <
                parseFloat(columns[i].filterRange[0])
              ) {
                columns[i].filterRange[0] = columns[i].minValue =
                  tableData[j][columns[i].name];
              }
              if (
                parseFloat(tableData[j][columns[i].name]) >
                parseFloat(columns[i].filterRange[1])
              ) {
                columns[i].filterRange[1] = columns[i].maxValue =
                  tableData[j][columns[i].name];
              }
            }
          }
        } else {
          if (
            columns[i].filterValues[tableData[j][columns[i].name]] === undefined
          ) {
            columns[i].filterValues[tableData[j][columns[i].name]] = false;
          }
        }
      }
    }

    setColumns([...columns]);
    setLoading(false);
  }, [geneState, tableData]);

  const dispatch = useDispatch();

  useEffect(() => {
    const tmpFilter = [];
    if (name === "genebase") {
      columns.forEach((e) => {
        if (e.name === "gene") {
          Object.entries(e.filterValues).forEach((val) => {
            if (val[1]) {
              tmpFilter.push(val[0]);
            }
          });
        }
      });
    }
    if (tmpFilter.length > 0) {
      dispatch(
        updateFilterStats({
          [version >= 12 ? "main.gene_symbol" : "main.symbol"]: tmpFilter,
        })
      );
    }
  }, [columns]);

  useEffect(() => {
    if (geneState) {
      setTimeout(() => {
        setColumns([...columns]);
      }, 500);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted && columns?.length && defaultRows) {
      const filteredData = filterRows(defaultRows, columns);
      setFilteredRows(filteredData);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [defaultRows, columns, isInitialFiltered]);

  useEffect(() => {
    if (filteredRows?.length && !isInitiallySorted) {
      if (sortState && tableData?.length) {
        setFilteredRows([...sortRows(tableData, sortState)]);
      }
      setIsInitiallySorted(true);
    }
  }, [filteredRows]);

  useEffect(() => {
    let tmpFilters = {};
    for (let i = 0; i < columns?.length; i++) {
      if (columns[i].activeFilterCount) {
        if (columns[i].kind === "numeric") {
          tmpFilters[columns[i].name] = [...columns[i]?.filterRange];
        } else {
          let tmpColFilters = [];
          const filterObjects = Object.entries(columns[i].filterValues);
          for (let j = 0; j < filterObjects?.length; j++) {
            if (filterObjects[j][1]) {
              tmpColFilters.push(filterObjects[j][0]);
            }
          }
          tmpFilters[columns[i].name] = tmpColFilters;
        }
      }
    }
    setFilterState(tmpFilters);
  }, [columns]);

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <Paper style={{ height: "300px", minWidth: "400px" }}>
        <VirtualizedTable
          height={300}
          columns={columns}
          setColumns={setColumns}
          rows={filteredRows}
          rowGetter={({ index }) => filteredRows[index]}
          rowCount={filteredRows?.length}
          exomebase={exomebase}
          exomebasePercent={exomebasePercent}
          //
          sort={sortState.sort}
          sortBy={undefined}
          sortDirection={undefined}
          //
          sortState={sortState}
          setSortState={setSortState}
          filterState={filterState}
        />
      </Paper>
    );
  }
};
