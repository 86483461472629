/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";
import { apiRelatedProjects } from "src/api/endpoints";
import FilterButton from "./filterButton";
import { ExternalLink } from "react-feather";
import { Link } from "react-router-dom";

function RelatedProjects({ id, gene, setCountRelated, column = "main.rs_id" }) {
  const [matchedProjects, setMatchedProjects] = useState(null);
  const [tmpProjects, setTmpProjects] = useState();
  const [filterRef, setFilterRef] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    apiRelatedProjects(gene, id, column, handleRelatedProjects);
    // return () => {
    //   setMatchedProjects(null);
    // };
  }, [id, gene]);

  const handleRelatedProjects = (data, status) => {
    if (status === 200) {
      setMatchedProjects(data);
      setTmpProjects(data.matches);
      setCountRelated(data.matches.length);
    } else {
      enqueueSnackbar(t("Something went wrong. Please try again later."), {
        variant: "error",
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "project_name",
        cell: (info) => (
          <Link
            component="button"
            variant="body2"
            target="_blank"
            style={{ textDecoration: "underline" }}
            color="primary"
            to={`/projects/${info.getValue().id}`}
          >
            <Box display="flex" alignItems="center" style={{ gap: 5 }}>
              {info.getValue().label}
              <ExternalLink size={14} />
            </Box>
          </Link>
        ),
        accessorFn: (row) => ({ label: row.project_name, id: row.project_id }),
        header: () => t("Project Name"),
      },
      {
        accessorFn: (row) => ({ label: row.report_name, id: row.report_id }),
        accessorKey: "report_name",
        cell: (info) => (
          <Link
            component="button"
            variant="body2"
            style={{ textDecoration: "underline" }}
            color="primary"
            target="_blank"
            to={`/reports/${
              info.getValue().id
            }?column=${column}&filter=${gene}`}
          >
            <Box display="flex" alignItems="center" style={{ gap: 5 }}>
              {info.getValue().label}
              <ExternalLink size={14} />
            </Box>
          </Link>
        ),
        header: () => t("Report Name"),
      },
      {
        accessorFn: (row) => row.project_type,
        accessorKey: "project_type",
        cell: (info) => info.getValue(),
        header: () => t("Project Type"),
      },
      {
        accessorFn: (row) => row.project_kit,
        accessorKey: "project_kit",
        cell: (info) => info.getValue(),
        header: () => t("Project Kit"),
      },
      {
        accessorFn: (row) => row.project_sequencer,
        accessorKey: "project_sequencer",
        cell: (info) => info.getValue(),
        header: () => t("Project Sequencer"),
      },
      {
        accessorFn: (row) => row.project_pipeline,
        accessorKey: "project_pipeline",
        cell: (info) => info.getValue(),
        header: () => t("Project Pipe"),
      },
    ],
    []
  );

  const table = useReactTable({
    data: matchedProjects ? matchedProjects.matches : [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const tableContainerRef = React.useRef(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;
  if (!matchedProjects)
    return (
      <Box
        minHeight={500}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  else {
    return (
      <Paper
        ref={tableContainerRef}
        style={{
          height: 600,
          overflow: "auto",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell
                      style={{
                        backgroundColor: "#388e3c",
                      }}
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ gap: 10 }}
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          <Box
                            style={{
                              cursor: "default",
                            }}
                            color="white !important"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Box>
                          <FilterButton
                            header={header}
                            filterRef={filterRef}
                            tmpProjects={tmpProjects}
                            matchedProjects={matchedProjects}
                            setMatchedProjects={setMatchedProjects}
                            setFilterRef={setFilterRef}
                          />
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </Box>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {paddingTop > 0 && (
              <TableRow>
                <TableCell style={{ height: `${paddingTop}px` }} />
              </TableRow>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <TableRow key={virtualRow.index}>
                  {row.getVisibleCells().map((cell, index) => {
                    return (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {paddingBottom > 0 && (
              <TableRow>
                <TableCell style={{ height: `${paddingBottom}px` }} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default RelatedProjects;
