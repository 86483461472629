import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer, List } from "react-virtualized";
import PopUpGenes from "./popUpGenes";
import RelatedGenes from "./relatedGenes";
import { getBaseURL } from "src/config/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  textField: {
    width: "80%",
  },
  button: {
    width: "5%",
  },
  selectedValues: {
    display: "flex",
    justifyItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  selectedValueHeader: {
    marginBottom: theme.spacing(1),
  },
  selectedValueBox: {
    width: "95%",
    margin: "auto",
  },
  selectedValueSubHeader: {
    width: "90%",
    margin: "auto",
    marginBottom: theme.spacing(1),
  },
  selectedValueSubDivider: {
    marginBottom: theme.spacing(1),
  },
  chipBox: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "start",
    overflowX: "auto",
    width: "100%",
  },
  chip: {
    "& .MuiChip-label": {
      color: "black !important",
    },
    borderWidth: 3,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  selectedMainBox: {
    height: "60vh",
    width: "96%",
    margin: "auto",
    marginTop: theme.spacing(2),
  },
  rowRenderer: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    width: "30%",
    color: `${theme.palette.secondary.main} !important`,
  },
  hr: {
    backgroundColor: theme.palette.primary.main,
  },
  headerBox: {
    gap: 4,
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    "& input": {
      width: "100%",
    },
  },
  focused: {
    transition: ".4s cubic-bezier(0.25, 0.1, 0.61, 0.7) all",
    width: "10%",
  },
  notFocused: {
    transition: ".4s cubic-bezier(0.25, 0.1, 0.61, 0.7) all",
    width: "70%",
  },
}));

function PanelApp({ selectedValues, setSelectedValues, reportFiles }) {
  const [panelAppFilters, setPanelAppFilters] = useState([]);
  const [dialogData, setDialogData] = useState([]);
  const [tmpPanelAppFilters, setTmpPanelAppFilters] = useState([]);
  const [focus, setFocus] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    fetch(
      reportFiles && reportFiles["panelapp_filters"]
        ? getBaseURL() + reportFiles["panelapp_filters"].download_address
        : "/S168146_panelapp_filters.tsv"
    )
      .then(async (response) => response.text())
      .then((value) => {
        let notUniqueResult = [];
        const rows = value.split("\n");
        let columns = rows[0].split("\t");
        delete rows[0];
        rows.forEach((row, index) => {
          let values = row.split("\t");
          notUniqueResult.push({
            [columns[0]]: values[0],
            [columns[1]]: values[1],
            [columns[2]]: values[2],
          });
        });
        let result = {};
        notUniqueResult.forEach((value) => {
          if (value.Level4) {
            const sourcesObj = value.Sources.split(";");
            let lastResult = {};
            sourcesObj.map((source) => {
              lastResult = {
                ...lastResult,
                [source]: {
                  color: getColor(source),
                  genes: value["Gene Symbol"]
                    .split(",")
                    .map((gene) => ({ label: gene, isActive: true })),
                  isActive: true,
                },
              };
            });
            result = {
              ...result,
              [value.Level4]: {
                ...result[value.Level4],
                ...lastResult,
              },
            };
          }
        });
        setPanelAppFilters(result);
        setTmpPanelAppFilters(result);
      })
      .catch((value) => {
        enqueueSnackbar(
          t(
            "unhandled_error_ressponse",
            "There was an error. Please try again"
          ),
          {
            variant: "error",
          }
        );
      });
  }, []);

  const getColor = (genes) => {
    let color = "blue";
    if (genes.includes("Red")) {
      color = "#e57373";
    } else if (genes.includes("Green")) {
      color = "#a5d6a7";
    } else if (genes.includes("list")) {
      color = "#7272f9";
    } else if (genes.includes("Amber")) {
      color = "#fb8c00";
    } else if (genes.includes("Literature")) {
      color = "tomato";
    } else if (genes.includes("GMS")) {
      color = "#a24604";
    }
    return color;
  };

  const handleOnDelete = (selectedValueName, expertName, gene) => {
    dialogData[1][expertName].genes = dialogData[1][expertName].genes.map(
      (geneName) => {
        if (geneName.label === gene) geneName.isActive = !geneName.isActive;
        return geneName;
      }
    );
    setDialogData([...dialogData]);
  };

  const deleteSelectedPanelAppFilter = (index) => {
    selectedValues.splice(index, 1);
    setSelectedValues([...selectedValues]);
  };

  const selectPanelAppFilter = (newValue) => {
    setSelectedValues((prev) => [...prev, newValue]);
  };

  const relatedGenesOnClick = (value, genes) => {
    dialogData[1][value[0]].isActive = !dialogData[1][value[0]].isActive;
    setDialogData([...dialogData]);
  };

  const handleDialogOpen = (data) => {
    setDialogData([...data]);
  };

  const search = (e) => {
    const filteredArray = Object.entries(panelAppFilters).filter((filter) =>
      filter[0].toLowerCase().includes(e.target.value)
    );
    let newTmpPanelAppFilters = {};
    filteredArray.forEach((value) => {
      newTmpPanelAppFilters = {
        ...newTmpPanelAppFilters,
        [value[0]]: value[1],
      };
    });
    setTmpPanelAppFilters(newTmpPanelAppFilters);
  };

  const handleDialogClose = () => {
    setDialogData([]);
  };

  if (!Object.values(panelAppFilters).length) return <CircularProgress />;
  else
    return (
      <Box mt={3} className={classes.selectedMainBox}>
        <Box className={classes.header} variant="h2">
          {t("Selected Values")}
        </Box>
        <Divider className={classes.hr} style={{ marginBottom: "20px" }} />
        <Box className={classes.selectedValues}>
          {selectedValues.length > 0
            ? selectedValues.map((selectedValue, index) => {
                return (
                  <RelatedGenes
                    key={index}
                    value={[
                      selectedValue[0],
                      { ...selectedValue[1], isActive: true },
                    ]}
                    onClick={() => deleteSelectedPanelAppFilter(index)}
                  />
                );
              })
            : t("Not any filter selected")}
        </Box>
        <>
          <Box display="flex" className={classes.headerBox} alignItems="center">
            <Typography className={classes.header} variant="h2">
              {t("Panel Filters")}
            </Typography>
            <TextField
              size="small"
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              variant="outlined"
              className={focus ? classes.notFocused : classes.focused}
              placeholder={t("Search")}
              onChange={search}
            />
          </Box>
          <Divider className={classes.hr} style={{ marginBottom: "20px" }} />
        </>
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              rowCount={Object.entries(tmpPanelAppFilters).length}
              rowHeight={70}
              rowRenderer={(props) =>
                RowRenderer({
                  ...props,
                  classes,
                  tmpPanelAppFilters,
                  handleDialogOpen,
                  t,
                })
              }
            />
          )}
        </AutoSizer>
        {dialogData.length > 0 && (
          <PopUpGenes
            genes={dialogData}
            handleOnDelete={handleOnDelete}
            handleDialogClose={handleDialogClose}
            selectPanelAppFilter={selectPanelAppFilter}
            setOpen={setDialogData}
            classes={classes}
            relatedGenesOnClick={relatedGenesOnClick}
            open={Boolean(dialogData.length)}
          />
        )}
      </Box>
    );
}

export default PanelApp;

const RowRenderer = ({
  key, // Unique key within array of rows
  index, // Index of row within collection
  isScrolling, // The List is currently being scrolled
  isVisible, // This row is visible within the List (eg it is not an overscanned row)
  style, // Style object to be applied to row (to position it)
  classes,
  handleDialogOpen,
  tmpPanelAppFilters,
  t,
}) => {
  return (
    <div key={key} style={style}>
      <Box className={classes.rowRenderer}>
        <Box>{Object.entries(tmpPanelAppFilters)[index][0]}</Box>
        <Box>
          <Button
            onClick={() =>
              handleDialogOpen(Object.entries(tmpPanelAppFilters)[index])
            }
          >
            {t("USE FILTER")}
          </Button>
        </Box>
      </Box>
      <Divider />
    </div>
  );
};
