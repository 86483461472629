import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  Paper,
  IconButton,
  InputBase,
  Link,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
  colors,
} from "@material-ui/core";
import {
  CalendarToday as CalendarTodayIcon,
  Category as CategoryIcon,
  Label as LabelIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { getBlogBaseURL, urlAPIConf } from "src/config/api";
import safeLogger from "src/services/safeLogger";
import { connect } from "react-redux";
import { getBaseLanguage } from "./reports/utils/helpers";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    color: colors.grey[800],
  },
  pageSubtitle: {
    marginBottom: theme.spacing(5),
    color: colors.grey[600],
  },
  inputWrapper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  articleCard: {
    margin: "auto",
    display: "flex",
    minHeight: 120,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& .MuiCardContent-root": {
      [theme.breakpoints.up("md")]: {
        width: "75%",
      },
    },
    "& .MuiCardActions-spacing": { marginLeft: "auto" },
    "& .card-meta": {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      "& .card-date": {
        verticalAlign: "super",
        marginRight: theme.spacing(1),
      },
      "& .group-icon": {
        marginRight: theme.spacing(1),
      },
      "& .MuiChip-root": {
        verticalAlign: "top",
        marginRight: theme.spacing(1),
        backgroundColor: colors.blueGrey[100],
        "& .MuiChip-label": {
          color: colors.blueGrey[700],
        },
      },
      "&  *": {
        color: theme.palette.grey[400],
        fill: theme.palette.grey[400],
      },
    },
  },

  articleMedia: {
    // width: "100%",
    width: "20%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      height: "200px",
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    appConf: state.appConf,
  };
};

const ConnectedHelpPage = (props) => {
  const classes = useStyles();
  const { appConf } = props;
  // const [ticketingFormDialogOpen, setTicketingFormDialogOpen] = useState(false);
  const [helpPosts, setHelpPosts] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSearch = (event) => {
    event.preventDefault();
    const query = event.target[0].value.replace(" ", "+");
    navigate(`/${urlAPIConf.SEARCH_SLUG}/?q=${query}`);
  };

  // const handleCloseTicketingFormDialog = () => {
  //   setTicketingFormDialogOpen(false);
  // };

  // const submitSupportTicket = (event) => {
  //   event.preventDefault();
  // };

  useEffect(() => {
    let mounted = true;
    if (appConf.lang) {
      fetch(
        `${getBlogBaseURL()}/${
          urlAPIConf.BASE_API_SLUG
        }/pages/?fields=_,id,title,intro,first_published_at,categories,tags,main_image&type=blog.BlogPage&order=-first_published_at&categories__in=3&locale=${getBaseLanguage()}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (mounted) {
            safeLogger(data.items);
            setHelpPosts(data.items);
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [appConf]);

  return (
    <Page className={classes.root} title={t("Help and Support")}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              component="h3"
              variant="h3"
              className={classes.pageTitle}
            >
              {t("Help and Support")}
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              className={classes.pageSubtitle}
            >
              {`Massive Analyser v${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid item xs={12}>
              <Paper
                component="form"
                className={classes.inputWrapper}
                onSubmit={handleSearch}
              >
                <InputBase
                  className={classes.input}
                  placeholder={t(
                    "help_page_search_placeholder",
                    "Find what you are looking for in our knowledge base..."
                  )}
                  inputProps={{ "aria-label": "search knowledge base" }}
                  fullWidth
                  variant="outlined"
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Trans i18nKey="help-into">
              <Typography style={{ marginTop: "20px" }}>
                We have a comprehensive set of articles and videos in our
                documentation and tutorials that explains how to use the
                application clearly. Please explore our knowledge base
                thoroughly to be able to use every page and functionality
                efficiently. However, if you were not able to find what you were
                looking for, or should you have any questions, recommendations,
                or comments, feel free to contact our support team through the{" "}
                <Link
                  href="https://share.hsforms.com/19O5cuaglSL64_fK0K9LD3Q5mxfi"
                  target="_blank"
                  // onClick={() => setTicketingFormDialogOpen(true)}
                  // style={{ cursor: "pointer" }}
                >
                  ticketing system
                </Link>
                , via email{" "}
                <Link href="emailto:ticketing@9467838.hubspot-inbox.com">
                  (ticketing@9467838.hubspot-inbox.com
                </Link>
                , or other channels provided in our{" "}
                <Link
                  target="_blank"
                  href="https://massivebioinformatics.com/iletisim/"
                >
                  contacts page
                </Link>
                .
              </Typography>
            </Trans>
            <Typography
              component="h4"
              variant="h4"
              style={{ margin: "35px auto 20px" }}
            >
              {t("Recent articles")}
            </Typography>
            {Boolean(helpPosts.length) &&
              helpPosts.map((article, index) => (
                <Card className={classes.articleCard} key={index}>
                  <CardMedia
                    className={classes.articleMedia}
                    image={
                      article.main_image
                        ? `${getBlogBaseURL()}/${article.main_image.url}`
                        : "/assets/images/misc/placeholder.png"
                    }
                    title={article.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      <Link href={`/${urlAPIConf.ARTICLES_SLUG}/${article.id}`}>
                        {article.title}
                      </Link>
                    </Typography>
                    <div className="card-meta">
                      <span>
                        <CalendarTodayIcon className="group-icon" />
                        <span className="card-date">
                          {format(
                            new Date(article.meta.first_published_at),
                            "dd/MM/yyyy"
                          )}
                        </span>
                      </span>

                      {Boolean(article.categories.length) && (
                        <span>
                          <CategoryIcon className="group-icon" />
                          {article.categories.map((item, index) => (
                            <Chip
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/${urlAPIConf.SEARCH_SLUG}/?category=${item.id}`
                                )
                              }
                              size="small"
                              label={t(item.name)}
                            />
                          ))}
                        </span>
                      )}
                    </div>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {article.intro}
                    </Typography>
                    <div className="card-meta">
                      {Boolean(article.tags.length) && (
                        <span>
                          <LabelIcon className="group-icon" />
                          {article.tags.map((item, index) => (
                            <Chip
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/${urlAPIConf.SEARCH_SLUG}/?tags=${item}`
                                )
                              }
                              size="small"
                              label={item}
                            />
                          ))}
                        </span>
                      )}
                    </div>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to={`/${urlAPIConf.ARTICLES_SLUG}/${article.id}`}
                      size="small"
                      color="primary"
                    >
                      {t("Learn More")}
                    </Button>
                  </CardActions>
                </Card>
              ))}
          </Grid>
          <Grid item xs={12} sm={3} className={classes.sidebar}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export const HelpPage = connect(mapStateToProps)(ConnectedHelpPage);
