/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const getLastChrSuffix = (dataset, chr) => {
  if (!dataset.length) {
    return 0;
  }

  let suffix = 0;

  for (let i = 0; i < dataset.length; i++) {
    const datasetLabel = dataset[i].label.split(" ");
    if (datasetLabel[0] === chr && parseInt(datasetLabel[1]) > suffix) {
      suffix = parseInt(datasetLabel[1]);
    }
  }

  return suffix;
};

const ChromosomeCopyRatioLineChartNoTreshold = (props) => {
  const classes = useStyles();
  const { helpInformation, files } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const [xRange, setXRange] = useState([]);
  const [chrReverse, setChrReverse] = useState(undefined);
  const [chromosomePositions, setChromosomePositions] = useState(undefined);
  const [chromosomeLines, setChromosomeLines] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    let prevPosition = 0;
    const chrPositions = {};
    const chrLines = [];
    fetch("/assets/statistics/bam_chr_statsfrom_samtools")
      .then((response) => response.text())
      .then((data) => {
        let chrData = data.split("\n");
        chrData = chrData.slice(0, chrData.length - 3); // remove chromosomes X, Y and M
        for (let i = 0; i < chrData.length; i++) {
          const chromosome = chrData[i].split("\t");
          const position = prevPosition + parseFloat(chromosome[1]);
          chrPositions[position] = chromosome[0];
          chrLines.push({
            drawTime: "afterDatasetsDraw",
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: position,
            borderWidth: 1,
            borderColor: colors[materialColors[16]][300],
            borderDash: [10, 5],
            label: {
              enabled: false,
            },
          });
          prevPosition = position;
        }
        if (mounted) {
          setChromosomePositions(chrPositions);
        }
      });
    if (mounted) {
      setChromosomeLines(chrLines);
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (chromosomePositions && chromosomeLines) {
      const reverseChromosomes = {};
      const chrPos = Object.keys(chromosomePositions);
      for (let i = 0; i < chrPos.length; i++) {
        if (i === 0) {
          reverseChromosomes["chr1"] = 0;
        } else {
          reverseChromosomes[chromosomePositions[chrPos[i]]] = chrPos[i - 1];
        }
      }
      try {
        fetch(getBaseURL() + files["copy_ratio"].download_address)
          .then((response) => response.text())
          .then((rawData) => {
            const labels = [];
            const dataset = [];

            rawData = rawData.split("\n");
            const columns = rawData[0].split("\t");
            const columnIndex = {};
            for (let i = 0; i < columns.length; i++) {
              columnIndex[columns[i]] = i;
            }

            rawData = rawData.slice(1);

            for (let i = 0; i < rawData.length - 1; i++) {
              const data = [];
              const row = rawData[i].split("\t");
              const chromosomeName =
                row[columnIndex["chromosome"]].split("_")[0];
              const start = parseInt(row[columnIndex["start"]]);
              let end = parseInt(row[columnIndex["end"]]);

              if (end - start < 10000000) {
                end += 10000000;
              }

              const position = parseInt(reverseChromosomes[chromosomeName]);
              const log2 = parseFloat(row[columnIndex["log2"]]);

              labels.push(start + position); // start
              data.push({
                x: start + position,
                y: log2,
              });
              labels.push(end + position); // end
              data.push({
                x: end + position,
                y: log2,
              });

              let suffix = getLastChrSuffix(dataset, chromosomeName) + 1;

              dataset.push({
                label: chromosomeName + " " + suffix,
                data: data,
                borderWidth: 5,
                pointRadius: 0,
                backgroundColor: "rgba(1, 1, 1, 0.2)",
                borderColor:
                  colors[
                    materialColors[
                      chromosomeName[chromosomeName.length - 1].charCodeAt() %
                        materialColors.length
                    ]
                  ][400],
              });
            }
            if (mounted) {
              setSampleData({
                labels: labels,
                datasets: [...dataset],
              });

              setXRange([labels[0], chrPos[chrPos.length - 2]]); // remove X and Y chromosomes
              setChrReverse(reverseChromosomes);
            }
          });
      } catch (e) {
        safeLogger(e);
        if (mounted) {
          setSampleData({ labels: [], datasets: [] });
        }
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [chromosomePositions, chromosomeLines]);

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    title: {
      display: true,
      text: t("Chromosomes-based copy ratio"),
    },
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: function (context) {
          return t("Copy ratio (log2)") + ": " + context.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: -3,
            max: 1.5,
          },
          scaleLabel: {
            display: true,
            labelString: t("Copy ratio (log2)"),
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            minRotation: 90,
            min: xRange[0],
            max: xRange[1],
            callback: (value) => {
              return chromosomePositions[value];
            },
          },
          afterBuildTicks: function (axis, ticks) {
            ticks = Object.values(chrReverse);
            return ticks;
          },
          scaleLabel: {
            display: true,
            labelString: t("Positions"),
          },
        },
      ],
    },
  };

  if (!sampleData || !chrReverse || !xRange.length) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <Line height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default ChromosomeCopyRatioLineChartNoTreshold;
