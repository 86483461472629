import React from "react";
import { useNavigate } from "react-router-dom";
import { apiPostError } from "src/api/endpoints";
import safeLogger from "../safeLogger";
import { ErrorAppCrashPage } from "src/components/pages/errors";
import TopBar from "src/layouts/MainLayout/TopBar";

class ErrorBoundaryClass extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return Boolean(this.state.error);
  // }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    safeLogger(error, errorInfo);
    if (!this.state.error) {
      this.setState({
        error: error.toString(),
        errorInfo: errorInfo.componentStack,
      });
    }
    if (process.env.NODE_ENV === "production") {
      this.setState({ error: null, errorInfo: null });
      apiPostError(
        { summary: error.toString(), details: errorInfo.componentStack },
        (data, status) => safeLogger(status, data)
      );
    }
  }

  render() {
    if (this.state.error) {
      // Error path
      return (
        <div>
          <TopBar />
          <ErrorAppCrashPage>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </ErrorAppCrashPage>
        </div>
      );
    } else {
      // Normally, just render children
      return this.props.children;
    }
  }
}

function ErrorBoundary(props) {
  let navigate = useNavigate();
  return <ErrorBoundaryClass {...props} navigate={navigate} />;
}

export default ErrorBoundary;
