import React, { useState } from "react";
import {
  Box,
  Button,
  CardActions,
  IconButton,
  makeStyles,
  Menu,
} from "@material-ui/core";
import { Help as HelpIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { marked } from "marked";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
  },
  menu: {
    "& .MuiMenu-paper": {
      minWidth: "450px",
    },
    "& .MuiList-padding": {
      padding: 3,
    },
  },
}));

const VariantDetailHelpButton = (props) => {
  const classes = useStyles();
  const { helpDesc } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  marked.use({
    async: false,
    pedantic: false,
    gfm: true,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Menu
        className={classes.menu}
        id="variant-detail-help-button"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box
          dangerouslySetInnerHTML={{
            __html: marked.parse(
              helpDesc.replace(/\*\*([^\*]+)\*\*/g, "<b>$1</b>")
            ),
          }}
        />
        <CardActions>
          <Button size="small" onClick={() => setAnchorEl(null)}>
            {t("Close")}
          </Button>
        </CardActions>
      </Menu>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <HelpIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default VariantDetailHelpButton;
