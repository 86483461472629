import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  SelectAll as SelectAllIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import React from "react";

export const SelectFilterHead = React.forwardRef((props, ref) => {
  const {
    handleSelectAll,
    tmpColumns,
    columnIndex,
    handleApplyFilters,
    classes,
    isDelete,
  } = props;
  const { t } = useTranslation();
  // const [isChecked, setIsChecked] = React.useState(checked ? false : true);
  // const selectOr = (event) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   setCheckboxChecked((prev) => !prev);
  // };

  return (
    <MenuItem innerRef={ref} onClick={handleSelectAll}>
      <Box display="flex" pr={2} width="100%">
        <Box flexGrow={1} onClick={handleSelectAll}>
          <Checkbox
            icon={<SelectAllIcon />}
            indeterminate={
              (tmpColumns[columnIndex] &&
                Boolean(tmpColumns[columnIndex].activeFilterCount) &&
                tmpColumns[columnIndex].filterValues &&
                tmpColumns[columnIndex].activeFilterCount !==
                  Object.keys(tmpColumns[columnIndex].filterValues).length) ||
              (tmpColumns[columnIndex] && tmpColumns[columnIndex].isTag)
            }
            checked={
              tmpColumns[columnIndex] &&
              Boolean(tmpColumns[columnIndex].activeFilterCount)
            }
          />
          {t(isDelete ? "UNSELECT ALL" : "SELECT ALL")}
        </Box>
        <Box
          ml="auto"
          display="flex"
          alignItems="center"
          style={{ padding: "0 30px" }}
        >
          {/* {Boolean(tmpColumns[columnIndex].multi_value) && (
              <Tooltip title={t("Check if you want the filter or")}>
                <Box
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    checkboxHandle(checked ? false : true);
                    // setIsChecked((prev) => !prev);
                    // setCheckboxChecked(checkboxChecked);
                  }}
                >
                  {t("Or")}
                  <Checkbox checked={checked ? true : false} />
                </Box>
              </Tooltip>
            )} */}
        </Box>
        <Box ml="auto" onClick={(e) => e.stopPropagation()}>
          <Tooltip title={t("Apply filters")}>
            <IconButton
              onClick={() => handleApplyFilters()}
              className={classes.applyFiltersBtn}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </MenuItem>
  );
});
