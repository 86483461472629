import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  textBox: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
    justifyContent: "start",
    overflow: "hidden",
  },
  label: {
    width: "100%",
    overflow: "hidden",
  },
  symbol: {
    width: "85%",
    overflow: "hidden",
  },
}));

function InputRow({ value, deleteRow }) {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Box className={classes.textBox}>
        <Typography variant="span" className={classes.label}>
          {value?.hpo_label ?? ""}
        </Typography>
        {/* <Typography variant="span" className={classes.symbol}>
          ('{value.hugo_symbol.join("','")}')
        </Typography> */}
      </Box>
      <IconButton
        onClick={() => deleteRow(value)}
        variant="contained"
        color="secondary"
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  // const onChange = (event, newValue, type, option) => {
  //   if (type === "select-option") {
  //     if (inputValue) {
  //       options.push(inputValue);
  //     }
  //     setInputValue(newValue);
  //     value.value = newValue;
  //     setOptions((prev) =>
  //       options.filter((option) => option.hpo !== newValue.hpo)
  //     );
  //     setPhenotype((prev) =>
  //       prev.map((item) => ({
  //         ...item,
  //         options: options.filter((option) => option.hpo !== newValue.hpo),
  //       }))
  //     );
  //   } else if (type === "clear") {
  //     options.push(inputValue);
  //     setInputValue(undefined);
  //     setOptions((prev) => [...options]);
  //     setPhenotype((prev) =>
  //       prev.map((item) => ({ ...item, options: options }))
  //     );
  //   }
  // };

  // return (
  //   <Autocomplete
  //     label={name}
  //     getOptionLabel={(option) => option.label + ` (${option.hpo})`}
  //     options={inputValue ? [...inputValue, options] : options}
  //     getOptionSelected={(option, value) => {
  //       return option.hpo === value.hpo;
  //     }}
  //     onChange={onChange}
  //     renderInput={(params) => (
  //       <TextField variant="outlined" {...params} label={name} />
  //     )}
  //     variant="outlined"
  //   />
  // );
}

export default InputRow;
