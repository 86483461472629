import { useEffect, useRef } from "react";

function userHasPermission(userPermissions, permissionType) {
  if (!userPermissions) {
    return;
  }
  return Object.keys(userPermissions ?? []).includes(permissionType);
}

function activeOrganizationExists(userObject) {
  if (
    (userObject && userObject.activeOrganization) ||
    localStorage.getItem("activeOrganizationId")
  ) {
    return true;
  }
  return false;
}

function arrayIntersection(arr1, arr2) {
  var s = new Set(arr2);
  return arr1.filter((item) => s.has(item));
}

function setDifference(setA, setB) {
  let _difference = new Set(setA);
  for (let elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

function getUserActivePermissions(userObject) {
  const activePermissions = {};

  const departmentPermissionKeys = new Set();

  for (let i = 0; i < userObject.departments.length; i++) {
    for (let item in userObject.departments[i].permissions) {
      departmentPermissionKeys.add(item);
      if (
        !Object.keys(activePermissions ?? []).includes(item) ||
        (activePermissions[item] === null &&
          userObject.departments[i].permissions[item])
      ) {
        activePermissions[item] = userObject.departments[i].permissions[item];
      }
    }
  }

  const teamPermissionKeys = new Set();

  for (let i = 0; i < userObject.teams.length; i++) {
    for (let item in userObject.teams[i].permissions) {
      teamPermissionKeys.add(item);
      if (
        !Object.keys(activePermissions ?? []).includes(item) ||
        (activePermissions[item] === null &&
          userObject.teams[i].permissions[item])
      ) {
        activePermissions[item] = userObject.teams[i].permissions[item];
      }
    }
  }

  let diff = setDifference(
    new Set(Object.keys(activePermissions)),
    teamPermissionKeys
  );

  for (let elem of diff) {
    delete activePermissions[elem];
  }

  const userPermissionKeys = new Set();

  for (let item in userObject.permissions) {
    userPermissionKeys.add(item);
    if (
      !Object.keys(activePermissions ?? []).includes(item) ||
      (activePermissions[item] === null && userObject.permissions[item])
    ) {
      activePermissions[item] = userObject.permissions[item];
    }
  }

  diff = setDifference(
    new Set(Object.keys(activePermissions)),
    userPermissionKeys
  );

  for (let elem of diff) {
    delete activePermissions[elem];
  }

  return activePermissions;
}

async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function getUserFullName(userObj) {
  if (userObj?.first_name && userObj.last_name) {
    return `${userObj?.first_name} ${userObj?.last_name}`;
  }
  if (userObj?.last_name) {
    return userObj?.last_name;
  }
  if (userObj?.first_name) {
    return userObj?.first_name;
  }

  return userObj?.username;
}

function isInt(n) {
  return Number(n) % 1 === 0;
}

function isJSONString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export {
  useInterval,
  digestMessage,
  userHasPermission,
  getUserFullName,
  activeOrganizationExists,
  getUserActivePermissions,
  isInt,
  arrayIntersection,
  isJSONString,
};
