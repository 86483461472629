export const fileDatas = {
  twist: 36807838,
  ces: 19952650,
  kapa_hyper_prep: 35793681,
  kapa_hyper_plus: 35793681,
  oncoplus: 2558943,
  oncoct: 382468,
  inv_twist_cancer_v2: 4292317,
  inv_twist_ces_v2: 12264084,
  inv_nanolung: 218331,
  inv_twist_brca: 155949,
  inv_nanohema: 1033050,
  inv_eu_genetics_alt1_panel: 1199673,
  inv_eu_genetics_alt2_panel: 64103,
  inv_eu_genetics_alt1_v2_panel: 957339,
  inv_alt3_panel: 113028,
  inv_cancer: 211015,
  inv_muscle: 576263,
  inv_neuro: 543475,
  Inv_850: 2332307,
  gt_idp: 11785466,
  gt_epileps: 709446,
  gt_kardiyo: 2108936,
  gt_kikp: 1223442,
  gt_mody: 624448,
  gt_ortak: 2919998,
  gt_pankans: 805345,
  kapa_hypercap_ds_ces4100: 12256095,
  kapa_hypercap_ds_neurodegeneration: 335006,
  kapa_hypercap_ds_sudden_cardiac_death: 772260,
  kapa_hypercap_hereditary: 9979442,
  kapa_hyperpete_hereditary_oncology: 202715,
  kapa_hyperpete_hotspotpanel: 36462,
  kapa_hyperpete_lungcancerfusion: 17591,
  kapa_hyperpete_newbornscreening: 293581,
  kapa_hyperpete_pancancer: 300589,
  ag_S33266340: 41623190,
  ag_S33266436: 41638207,
  ag_S33613271: 85390017,
};
