/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const ChromosomesLineChart = (props) => {
  const classes = useStyles();
  const { helpInformation, qualimapJson } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    try {
      const jsonData = qualimapJson.chromosome;
      const chromosomeObjectKeys = Object.keys(jsonData);
      const labels = [];
      const data = [];
      for (let i = 0; i < chromosomeObjectKeys.length - 1; i++) {
        labels.push(chromosomeObjectKeys[i]);
        data.push(
          parseFloat(
            jsonData[chromosomeObjectKeys[i]].mean_coverage.replace(",", "")
          )
        );
      }

      if (mounted) {
        setSampleData({
          labels: labels,
          datasets: [
            {
              label: t("Coverage"),
              data: data,
              backgroundColor: "rgba(126, 87, 194, 0.3)",
              borderColor: colors[materialColors[3]][400],
            },
          ],
        });
      }
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSampleData({ labels: [], datasets: [] });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      text: t("Chromosomes mean coverage"),
    },
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: function (context) {
          return t("Coverage") + ": " + context.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
          },
          scaleLabel: {
            display: true,
            labelString: "Coverage",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t("Chromosome"),
          },
        },
      ],
    },
  };

  if (!sampleData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <Line height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default ChromosomesLineChart;
