import { Box, IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import { Check } from "react-feather";

const PopoverContent = ({
  classes,
  activeColumnName,
  options,
  name,
  setTableFilters,
  tableFilters,
}) => {
  const [value, setValue] = useState();

  const onClickSuccess = () => {
    if (value === "") {
      setTableFilters({});
    } else {
      setTableFilters((prev) => ({
        ...prev,
        [activeColumnName]: value,
      }));
    }
  };

  return (
    <Box className={classes.popover}>
      {["project_type", "pipeline_class", "kit", "created_at"].includes(
        activeColumnName
      ) && (
        <Autocomplete
          options={options}
          onChange={(e, value) => {
            setValue(value ?? "");
          }}
          value={tableFilters[activeColumnName] ?? value}
          className={classes.popoverAutocomplete}
          getOptionLabel={(value) => {
            return Object.values(value)[0];
          }}
          renderInput={(props) => <TextField {...props} />}
        />
      )}
      <IconButton onClick={onClickSuccess}>
        <Check />
      </IconButton>
    </Box>
  );
};

export default PopoverContent;
