export const defaultConfig = {
  id: 23,
  version: "v13",
  latest: true,
  annotable: true,
  columns: [
    {
      name: "main.variation",
      kind: "enumeration",
      multi_value: null,
      label: {
        tr: "Varyant",
        en_GB: "Uploaded Variation",
      },
      description: {
        tr: "Genomik lokasyon ve değişim bilgisi",
        en_GB: "Identifier of uploaded variant",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 2,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "main.rs_id",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "RS ID",
        en_GB: "rs ID",
      },
      description: {
        tr: "Varyanta ait RS ID numarası",
        en_GB: "Identifier of existing variant",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 2,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "main.gene_symbol",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "Gen Sembolü",
        en_GB: "Gene Symbol",
      },
      description: {
        tr: "HGNC gen sembolü",
        en_GB: "HGNC Gene Symbol",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 1,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "main.variant_class",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "Varyant Sınıfı",
        en_GB: "Var. Class",
      },
      description: {
        tr: "SO varyant sınıfı",
        en_GB: "SO variant class",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 1,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "main.consequence",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "Varyant Sonucu",
        en_GB: "Consequence",
      },
      description: {
        tr: "Varyantın proteinetkisi",
        en_GB: "Consequence type",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 2,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "main.allele_depth",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "Allel Derinliği",
        en_GB: "Allele Depth",
      },
      description: {
        tr: "Referans / Değişim",
        en_GB: "Allele Depth (Reference/Alteration)",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 1,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "main.allele_fraction",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "Allel Fraksiyonu",
        en_GB: "Allele Frequency",
      },
      description: {
        tr: "Değişim derinliği / Total derinlik",
        en_GB: "Allelic Fraction (Alteration Depth/Total Depth)",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 1,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "main.total_depth",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "Derinlik",
        en_GB: "Total Depth",
      },
      description: {
        tr: "Varyantın tespit edildiği lokasyondaki total okuma Sayısı",
        en_GB: "Total depth at the position",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 1,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "main.pathogenicity_rules",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "Patojenisite Kuralları",
        en_GB: "Pathogenicity Rules",
      },
      description: {
        tr: "ACMG kılavuzuna göre belirlenmiş patojenisite kuralları",
        en_GB: "ACMG criteria",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 2,
          visible: true,
          component: "autocomplete",
          layout_visible: true,
          component_choices: [
            "PVS1 Supporting",
            "PVS1 Strong",
            "PVS1 Moderate",
            "PVS1",
            "PS4 Supporting",
            "PS4 Moderate",
            "PS4",
            "PS3 Supporting",
            "PS3 Moderate",
            "PS3",
            "PS2 Very Strong",
            "PS2 Supporting",
            "PS2 Moderate",
            "PS2",
            "PS1 Supporting",
            "PS1 Moderate",
            "PS1",
            "PP4 Strong",
            "PP4 Moderate",
            "PP4",
            "PP3 Strong",
            "PP3 Moderate",
            "PP3",
            "PP2 Moderate",
            "PP2",
            "PP1 Strong",
            "PP1 Moderate",
            "PP1",
            "PM6 Supporting",
            "PM6 Strong",
            "PM6",
            "PM5 Supporting",
            "PM5 Strong",
            "PM5",
            "PM4 Supporting",
            "PM4 Strong",
            "PM4",
            "PM3 Very Strong",
            "PM3 Supporting",
            "PM3 Strong",
            "PM3",
            "PM2 Supporting",
            "PM2",
            "PM1 Supporting",
            "PM1 Strong",
            "PM1 Strong",
            "PM1",
            "BS4 Supporting",
            "BS4",
            "BS3 Supporting",
            "BS3",
            "BS2 Supporting",
            "BS2 Stand Alone",
            "BS2",
            "BS1 Supporting",
            "BS1 Stand Alone",
            "BS1",
            "BP7 Strong",
            "BP7",
            "BP5 Strong",
            "BP5",
            "BP4",
            "BP3",
            "BP2 Strong",
            "BP2",
            "BP1 Strong",
            "BP1",
            "BA1",
          ],
        },
      },
      annotable: true,
    },
    {
      name: "main.pathogenicity",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "Patojenisite",
        en_GB: "Pathogenicity",
      },
      description: {
        tr: "ACMG kılavuzunda yer alan kuralların atanmasına göre tespit edilen patojenisite sınıflandırması",
        en_GB: "Pathogenicity classification",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          style: {
            0: {
              color: "red[900]",
            },
            1: {
              color: "red[500]",
            },
            2: {
              color: "orange[500]",
            },
            3: {
              color: "amber[500]",
            },
            4: {
              color: "yellow[600]",
            },
            5: {
              color: "lightGreen[500]",
            },
            6: {
              color: "green[500]",
            },
          },
          width: 1,
          visible: true,
          component: "dropdown",
          layout_visible: true,
          component_choices: {
            0: "P",
            1: "LP",
            2: "VUSii",
            3: "VUSi",
            4: "VUS",
            5: "LB",
            6: "B",
          },
          conditional_style: true,
        },
      },
      annotable: true,
    },
    {
      name: "main.hgvsc",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "HGVSc",
        en_GB: "HGVSc",
      },
      description: {
        tr: "Kodlanan bölgedeki baz değişiminin nomenklatüre ugun isimlendirmesi",
        en_GB: "Reference sequence based on a protein coding RNA",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 2,
          visible: true,
          hide_prefix: false,
          layout_visible: true,
          prefix_indicator: ":",
        },
      },
      annotable: false,
    },
    {
      name: "main.hgvsp",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "HGVSp",
        en_GB: "HGVSp",
      },
      description: {
        tr: "Varyantın amino asit değişiminin nomenklatüre uygun isimlendirmesi",
        en_GB: "Protein Variant Nomenclature",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 2,
          visible: true,
          hide_prefix: false,
          layout_visible: true,
          prefix_indicator: ":",
        },
      },
      annotable: false,
    },
    {
      name: "main.genotype",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "Zigosite",
        en_GB: "Zygosity",
      },
      description: {
        tr: "Varyanta ait heterozigosite/homozigosite bilgisi",
        en_GB: "Heterozygosity/Homozygosity",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 1,
          visible: true,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details1",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "Detaylar 1",
        en_GB: "Details1",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_table",
        options: {
          order: 0,
          title: "Main Details",
          visible: true,
          group_name: "details1",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.sift",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "SIFT",
        en_GB: "SIFT",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "1",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.sift4g_pred",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "SIFT4G Tahmin",
        en_GB: "SIFT4G Prediction",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: false,
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
          component_choices: ["T", "D"],
        },
      },
      annotable: false,
    },
    {
      name: "details2.sift4g_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "SIFT4G Skor",
        en_GB: "SIFT4G Score",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Supporting Pathogenic",
              value: 0.002,
            },
            {
              label: "Supporting Benign",
              value: 0.063,
            },
            {
              label: "Moderate Benign",
              value: 0.156,
            },
          ],
          order: 1,
          range: [-1, 1],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.polyphen",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "PolyPhen",
        en_GB: "PolyPhen",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "1",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.metalr_pred",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "MetaLR Tahmin",
        en_GB: "MetaLR Prediction",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: false,
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
          component_choices: ["T", "D"],
        },
      },
      annotable: false,
    },
    {
      name: "details2.metalr_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "MetaLR Skor",
        en_GB: "MetaLR Score",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Strong Pathogenic",
              value: 0.985,
            },
            {
              label: "Moderate Pathogenic",
              value: 0.91,
            },
            {
              label: "Supporting Pathogenic",
              value: 0.832,
            },
            {
              label: "Supporting Benign",
              value: 0.361,
            },
            {
              label: "Moderate Benign",
              value: 0.206,
            },
            {
              label: "Strong Benign",
              value: 0.0376,
            },
          ],
          order: 1,
          range: [0, 1],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.revel",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "REVEL",
        en_GB: "REVEL",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "1",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.mutationtaster_pred",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "MutationTaster Tahmin",
        en_GB: "MutationTaster Prediction",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: false,
          sub_group: {
            order: 0,
            title: "1",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.mutationtaster_score",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "MutationTaster Skor",
        en_GB: "MutationTaster Score",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "1",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.mutationassessor_pred",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "MutationAssessor Tahmin",
        en_GB: "MutationAssessor Prediction",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: false,
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
          component_choices: ["H", "L", "M", "N"],
        },
      },
      annotable: false,
    },
    {
      name: "details2.mutationassessor_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "MutationAssessor Skor",
        en_GB: "MutationAssessor Score",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Moderate Pathogenic",
              value: 3.5,
            },
            {
              label: "Supporting Pathogenic",
              value: 2.91,
            },
            {
              label: "Supporting Benign",
              value: 2.01,
            },
            {
              label: "Supporting Benign",
              value: 2.01,
            },
            {
              label: "Moderate Benign",
              value: 1.19,
            },
          ],
          order: 1,
          range: [1.19, 3.5],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.provean_pred",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "PROVEAN Tahmin",
        en_GB: "PROVEAN Prediction",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: false,
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
          component_choices: ["D", "N"],
        },
      },
      annotable: false,
    },
    {
      name: "details2.provean_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "PROVEAN Skor",
        en_GB: "PROVEAN Score",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Moderate Pathogenic",
              value: 6.67,
            },
            {
              label: "Supporting Pathogenic",
              value: 4.38,
            },
            {
              label: "Supoorting Benign",
              value: 2.29,
            },
            {
              label: "Moderate Benign",
              value: 1.73,
            },
          ],
          order: 1,
          range: [1.73, 6.67],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.fathmm_pred",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "FATHMM Tahmin",
        en_GB: "FATHMM Prediction",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: false,
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
          component_choices: ["T", "D"],
        },
      },
      annotable: false,
    },
    {
      name: "details2.fathmm_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "FATHMM Skor",
        en_GB: "FATHMM Score",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Supporting Pathogenic",
              value: 5.33,
            },
            {
              label: "Supporting Pathogenic",
              value: 4.4,
            },
            {
              label: "Supporting Benign",
              value: 0.46,
            },
            {
              label: "Moderate Benign",
              value: -1.42,
            },
          ],
          order: 1,
          range: [-1.42, 5.33],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.condel",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "Condel",
        en_GB: "Condel",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "1",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.deogen2_pred",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "DEOGEN2 Tahmin",
        en_GB: "DEOGEN2 Prediction",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: false,
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
          component_choices: ["T", "D"],
        },
      },
      annotable: false,
    },
    {
      name: "details2.deogen2_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "DEOGEN2 Skor",
        en_GB: "DEOGEN2 Score",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Strong Pathogenic",
              value: 0.969,
            },
            {
              label: "Moderate Pathogenic",
              value: 0.875,
            },
            {
              label: "Supoorting Pathogenic",
              value: 0.795,
            },
            {
              label: "Supporting Benign",
              value: 0.402,
            },
            {
              label: "Moderate Benign",
              value: 0.258,
            },
            {
              label: "Strong Benign",
              value: 0.0269,
            },
          ],
          order: 1,
          range: [0, 1],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "2",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.lrt_pred",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "LRT Tahmin",
        en_GB: "LRT Prediction",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: false,
          sub_group: {
            order: 0,
            title: "3",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
          component_choices: ["D", "N", "U"],
        },
      },
      annotable: false,
    },
    {
      name: "details2.lrt_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "LRT Skor",
        en_GB: "LRT Score",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Supporting Pathogenic",
              value: 0,
            },
            {
              label: "Supporting Benign",
              value: 0.000435,
            },
            {
              label: "Moderate Benign",
              value: -0.00687,
            },
          ],
          order: 1,
          range: [-1, 0],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "3",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.maxentscan_ref_alt_diff",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "MaxEntScan",
        en_GB: "MaxEntScan",
      },
      description: {
        tr: "Referans|Değişim|Fark",
        en_GB: "Reference|Alteration|Difference",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "1",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.eigen_raw_coding",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "Eigen-Raw Coding",
        en_GB: "Eigen-raw Coding",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "3",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.dann_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "DANN Skoru",
        en_GB: "DANN Score",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Supporting Pathogenic",
              value: 0.999,
            },
            {
              label: "Supporting Benign",
              value: 0.974,
            },
            {
              label: "Moderate Benign",
              value: 0.915,
            },
            {
              label: "Strong Benign",
              value: 0.478,
            },
          ],
          order: 1,
          range: [0, 1],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "3",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.loftool",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "LofTool",
        en_GB: "LoFtool",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "3",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.genesplicer",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "GeneSplicer",
        en_GB: "GeneSplicer",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 1,
          title: "Pathogenicity Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "1",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.rf_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "RF Skor",
        en_GB: "RF Score",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Strong Pathogenic",
              value: 0.999925,
            },
            {
              label: "Moderate Pathogenic",
              value: 0.999322,
            },
            {
              label: "Supoorting Pathogenic",
              value: 0.957813,
            },
          ],
          order: 1,
          range: [0, 1],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "3",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details2.ada_score",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ADA Skoru",
        en_GB: "ADA Score",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          marks: [
            {
              label: "Strong Pathogenic",
              value: 0.994,
            },
            {
              label: "Moderate Pathogenic",
              value: 0.832,
            },
            {
              label: "Supoorting Pathogenic",
              value: 0.584,
            },
          ],
          order: 1,
          range: [0, 1],
          title: "Pathogenicity Scores",
          visible: true,
          component: "slider",
          sub_group: {
            order: 0,
            title: "3",
            width: 4,
            hide_title: true,
          },
          group_name: "details2",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.ac_exac",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ExAC AS",
        en_GB: "ExAC AC",
      },
      description: {
        tr: "ExAC popülasyon veri tabanında varyantın görülme sayısı",
        en_GB: "ExAC allele count",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 29,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.ac_exomes_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom AS",
        en_GB: "GnomAD Exomes AC",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre allel sayısı",
        en_GB: "gnomAD exomes allele count",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 0,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.ac_genomes_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom AS",
        en_GB: "GnomAD Genomes AC",
      },
      description: {
        tr: "Varyantın GnomAD popülasyon veri tabanındaki allel sayısı",
        en_GB: "GnomAD genomes allele count",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 1,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.af_exac",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ExAC AF",
        en_GB: "ExAC AF",
      },
      description: {
        tr: "ExAC popülasyon veri tabanına göre varyantın global frekansı",
        en_GB: "ExAC total allele frequency",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 30,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          width: 1,
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details3.amr_af_exac",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ExAC AMR AF",
        en_GB: "ExAC AMR AF",
      },
      description: {
        tr: "ExAC popülasyon veri tabanına göre varyantın Amerikan popülasyonundaki frekansı",
        en_GB: "American population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 31,
            parent: 30,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.eas_af_exac",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ExAC EAS AF",
        en_GB: "ExAC EAS AF",
      },
      description: {
        tr: "ExAC popülasyon veri tabanına göre varyantın Doğu Asya popülasyonundaki frekansı",
        en_GB: "East Asian population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 32,
            parent: 30,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.fin_af_exac",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ExAC FiN AF",
        en_GB: "ExAC FIN AF",
      },
      description: {
        tr: "ExAC popülasyon veri tabanına göre varyantın Fin popülasyonundaki frekansı",
        en_GB: "Finnish population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 33,
            parent: 30,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.nfe_af_exac",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ExAC NFE AF",
        en_GB: "ExAC NFE AF",
      },
      description: {
        tr: "ExAC popülasyon veri tabanına göre varyantın Fin olmayan toplumlardaki frekansı",
        en_GB: "Non-Finnish population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 34,
            parent: 30,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.sas_af_exac",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ExAC SAS AF",
        en_GB: "ExAC SAS AF",
      },
      description: {
        tr: "ExAC popülasyon veri tabanına göre varyantın Güney Afrika popülasyonundaki frekansı",
        en_GB: "South Asian population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 35,
            parent: 30,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.af_1000g",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "1000G AF",
        en_GB: "1000G AF",
      },
      description: {
        tr: "1000Genomes popülasyon veri tabanına göre varyantın global frekansı",
        en_GB: "1000Genomes total allel frequency",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 20,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          width: 1,
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details3.afr_af_1000g",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "1000G AFR AF",
        en_GB: "1000G AFR AF",
      },
      description: {
        tr: "1000Genomes popülasyon veri tabanına göre varyantın Afrika popülasyonundaki frekansı",
        en_GB: "African population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 21,
            parent: 20,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.amr_af_1000g",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "1000G AMR AF",
        en_GB: "1000G AMR AF",
      },
      description: {
        tr: "1000Genomes popülasyon veri tabanına göre varyantın Doğu Asya popülasyonundaki frekansı",
        en_GB: "American population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 22,
            parent: 20,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.eas_af_1000g",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "1000G EAS AF",
        en_GB: "1000G EAS AF",
      },
      description: {
        tr: "1000Genomes popülasyon veri tabanına göre varyantın Doğu Asya popülasyonundaki frekansı",
        en_GB: "East Asian population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 23,
            parent: 20,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.eur_af_1000g",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "1000G EUR AF",
        en_GB: "1000G EUR AF",
      },
      description: {
        tr: "1000Genomes popülasyon veri tabanına göre varyantın Avrupa popülasyonundaki frekansı",
        en_GB: "European population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 24,
            parent: 20,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.sas_af_1000g",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "1000Genomes SAS AF",
        en_GB: "1000G SAS AF",
      },
      description: {
        tr: "1000Genomes popülasyon veri tabanına göre varyantın Güney Afrika popülasyonundaki frekansı",
        en_GB: "South Asian population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 25,
            parent: 20,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.aa_af_esp",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ESP AA AF",
        en_GB: "NHLBI-ESP AA AF",
      },
      description: {
        tr: "ESP popülasyon veri tabanına göre varyantın Afrikan-Amerikan popülasyonundaki frekansı",
        en_GB: "African American population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 26,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 1,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.ea_af_esp",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ESP EA AF",
        en_GB: "NHLBI-ESP EA AF",
      },
      description: {
        tr: "ESP popülasyon veri tabanına göre varyantın Avrupa-Amerikan popülasyonundaki frekansı",
        en_GB: "European American population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 27,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 1,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom AF",
        en_GB: "GnomAD Exome AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın global frekansı",
        en_GB: "GnomAD exomes total allel frequency",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          width: 1,
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details3.afr_af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom AFR AF",
        en_GB: "GnomAD Exome AFR AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın Afrika popülasyonundaki frekansı",
        en_GB: "African/American exomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 12,
            parent: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.amr_af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom AMR AF",
        en_GB: "GnomAD Exome AMR AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın Amerika popülasyonundaki frekansı",
        en_GB: "American exomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 13,
            parent: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.asj_af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom ASJ AF",
        en_GB: "GnomAD Exome ASJ AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın Aşkenazı Yahudi popülasyonundaki frekansı",
        en_GB: "Ashkenazi Jewish exomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 14,
            parent: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.eas_af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom EAS AF",
        en_GB: "GnomAD Exome EAS AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın Doğu Asya popülasyonundaki frekansı",
        en_GB: "East Asian exomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 15,
            parent: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.fin_af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom FİN AF",
        en_GB: "GnomAD Exome FIN AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın Fin popülasyonundaki frekansı",
        en_GB: "Finnish exomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 16,
            parent: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.nfe_af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom NFE AF",
        en_GB: "GnomAD Exome NFE AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın Fin olmayan toplumlardaki frekansı",
        en_GB: "Non-Finnish exomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 17,
            parent: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.oth_af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom AF",
        en_GB: "GnomAD Exome OTH AF",
      },
      description: {
        tr: 'GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın "Diğer" popülasyonlardaki frekansı',
        en_GB: "GnomAD exomes other",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 18,
            parent: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.sas_af_exome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Ekzom SAS AF",
        en_GB: "GnomAD Exome SAS AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki ekzom çalışmalarına göre varyantın Güney Afrika popülasyonundaki frekansı",
        en_GB: "South Asian exomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 19,
            parent: 11,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.af_genome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom AF",
        en_GB: "GnomAD Genome AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın global frekansı",
        en_GB: "GnomAD Genomes total allel frequency",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          width: 1,
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details3.afr_af_genome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom AFR AF",
        en_GB: "GnomAD Genome AFR AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın Afrika popülasyonundaki frekansı",
        en_GB: "African/American Genomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 3,
            parent: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.amr_af_genome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom AMR AF",
        en_GB: "GnomAD Genome AMR AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın Amerika popülasyonundaki frekansı",
        en_GB: "American Genomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 4,
            parent: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.asj_af_genome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom ASJ AF",
        en_GB: "GnomAD Genome ASJ AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın Aşkenazı Yahudi popülasyonundaki frekansı",
        en_GB: "Ashkenazi Jewish Genomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 5,
            parent: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.eas_af_genome_gnomad",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom EAS AF",
        en_GB: "GnomAD Genome EAS AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın Doğu Asya popülasyonundaki frekansı",
        en_GB: "East Asian Genomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 6,
            parent: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.fin_af_genome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom FİN AF",
        en_GB: "GnomAD Genome FIN AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın Fin popülasyonundaki frekansı",
        en_GB: "Finnish Genomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 7,
            parent: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.nfe_af_genome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom NFE AF",
        en_GB: "GnomAD Genome NFE AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın Fin olmayan toplumlardaki frekansı",
        en_GB: "Non-Finnish Genomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 8,
            parent: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.oth_af_genome_gnomad",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom Diğer",
        en_GB: "GnomAD Genome OTH AF",
      },
      description: {
        tr: 'GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın "Diğer" popülasyonlardaki frekansı',
        en_GB: "GnomAD Genomes other",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 9,
            parent: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.sas_af_genome_gnomad",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GnomAD Genom SAS AF",
        en_GB: "GnomAD Genome SAS AF",
      },
      description: {
        tr: "GnomAD popülasyon veri tabanındaki genom çalışmalarına göre varyantın Güney Afrika popülasyonundaki frekansı",
        en_GB: "South Asian Genomes population",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 10,
            parent: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.max_af",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "MAX AF",
        en_GB: "MAX AF",
      },
      description: {
        tr: "1000Genomes, ESP ve GnomAD veri tabanlarında gözlemlenen maksimum allel frekansı",
        en_GB:
          "Maximum observed allele frequency in 1000Genomes, ESP and gnomAD",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 28,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          width: 1,
          visible: true,
          sub_group: {
            order: 2,
            title: "Frequency",
            width: 6,
          },
          group_name: "details3",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details3.phastcons100way_vertebrate",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "PhastCons100way Omurgalı",
        en_GB: "PhastCons100way Vertebrate",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 0,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Conservation",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.phastcons17way_primate",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "PhastCons17way Primat",
        en_GB: "PhastCons17way Primate",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 1,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Conservation",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.phastcons470way_mammalian",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "PhastCons470way Memeli",
        en_GB: "PhastCons470way Mammalian",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 2,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Conservation",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.phylop100way_vertebrate",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "PhyloP100way Omurgalı",
        en_GB: "PhyloP100way Vertebrate",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 3,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Conservation",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.phylop17way_primate",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "PhyloP17way Primat",
        en_GB: "PhyloP17way Primate",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 4,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Conservation",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.phylop470way_mammalian",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "PhyloP470way Memeli",
        en_GB: "PhyloP470way Mammalian",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 5,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 0,
            title: "Conservation",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.gerp_rs",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "GERP++ RS",
        en_GB: "GERP++ RS",
      },
      description: {
        "*": "(missing translation)",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 6,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 1,
            title: "Conservation",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details3.blosum62",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "BLOSUM62",
        en_GB: "BLOSUM62",
      },
      description: {
        en_GB: "",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          tree: {
            node: 7,
          },
          order: 2,
          title: "Population Frequencies and Conservation Scores",
          visible: true,
          sub_group: {
            order: 1,
            title: "Conservation",
            width: 6,
          },
          group_name: "details3",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.clinvar_clinical_significance",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "ClinVar Klinik Önem",
        en_GB: "ClinVar Significancy",
      },
      description: {
        tr: "Varyantın ClinVar veri tabanına göre sınıflandırması",
        en_GB: "ClinVar clinical significancy",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          sub_group: {
            title: "Clinvar",
            width: 4,
          },
          group_name: "details4",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details4.clinvar_submission",
      kind: "counted",
      multi_value: null,
      label: {
        tr: "ClinVar Bildirimleri",
        en_GB: "ClinVar Submission",
      },
      description: {
        tr: "ClinVar veri tabanında varyant ile illgili bilidirlmiş sınıflandırmalar",
        en_GB: "ClinVar submission summary",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          helper:
            '<table><thead><tr><th>Classification</th><th>Acronym</th></tr></thead><tbody><tr><td>Benign</td><td style="color:#2c6b2e">**B**</td></tr><tr><td>Likely_benign</td><td style="color:#169c1a">**LB**</td></tr><tr><td>Uncertain_significance</td><td style="color:#fa7223">**VUS**</td></tr><tr><td>Likely_pathogenic</td><td style="color:#fa5050">**LP**</td></tr><tr><td>Pathogenic</td><td style="color:#f71414">**P**</td></tr><tr><td>drug_response</td><td style="color:#2c4c6b">**DR**</td></tr><tr><td>association</td><td style="color:#4275a6">**AS**</td></tr><tr><td>risk_factor</td><td style="color:#6242a6">**RF**</td></tr><tr><td>protective</td><td style="color:#77994d">**PR**</td></tr>    <tr><td>Affects</td><td style="color:grey">**AFF**</td></tr><tr><td>conflicting_data_from_submitters</td><td style="color:grey">**CO**</td></tr><tr><td>other</td><td style="color:grey">**OTH**</td></tr><tr><td>not_provided</td><td style="color:grey">**NP**</td></tr><tr><td>confers_sensitivity</td><td style="color:grey">**CS**</td></tr><tr><td>Establish_risk_allele</td><td style="color:grey">**ERA**</td></tr><tr><td>association_not_found</td><td style="color:grey">**NAN**</td></tr><tr><td>Uncertain_risk_allele</td><td style="color:grey">**URA**</td></tr></tbody></table>',
          visible: true,
          component: "badged-chip",
          sub_group: {
            title: "Clinvar",
            width: 4,
          },
          group_name: "details4",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details4.clinvar_review_status",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "ClinVar Değerlendirme Sonucu",
        en_GB: "ClinVar Review",
      },
      description: {
        tr: "ClinVar değerlendirme durumu",
        en_GB: "ClinVar Review Status",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          sub_group: {
            title: "Clinvar",
            width: 4,
          },
          group_name: "details4",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details4.clinvar_star",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "ClinVar Altın Yıldız",
        en_GB: "ClinVar Gold Star",
      },
      description: {
        tr: "ClinVar veri tabanındaki bildirimlere göre varyanta atanan yıldız sayısı",
        en_GB: "Visually representation of ClinVar review status",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          helper:
            '<table><thead><tr><th>Gold Star</th><th>Representation</th></tr></thead><tbody><tr><td>4 Stars</td><td style="color:grey">**Practice Guideline**</td></tr><tr><td>3 Stars</td><td style="color:grey">**Reviewed by Expert Panel**</td></tr><tr><td>2 Stars</td><td style="color:grey">**criteria provided, multiple submitters, no conflicts**</td></tr><tr><td>1 Stars</td><td style="color:grey">**criteria provided, conflicting interpretations or criteria provided, single submitter**</td></tr><tr><td>0 Stars</td><td style="color:grey">**no assertion for the individual variant or no assertion criteria provided or no assertion provided or no interpretation for the single variant**</td></tr></tbody></table>',
          visible: true,
          component: "star-rating",
          sub_group: {
            title: "Clinvar",
            width: 4,
          },
          group_name: "details4",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details4.phenotype_information",
      kind: "enumeration",
      multi_value: "|",
      label: {
        tr: "İlişkilendirilmiş Fenotip ",
        en_GB: "Phenotype information",
      },
      description: {
        tr: "İlişkilendirilmiş fenotip ve kalıtım bilgisi",
        en_GB: "Associated phenotype information with Inheritance",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          component: "collapse",
          sub_group: {
            title: "Associated Phenotype",
            width: 4,
          },
          group_name: "details4",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "details4.cosmic_primary_site_histology",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "COSMIC Birincil Doku ve Histoloji",
        en_GB: "COSMIC Primary site and histology",
      },
      description: {
        tr: "COSMIC veri tabanına göre varyantın bulunduğu birincil doku ve histoloji türü",
        en_GB: "The primary tissue/histology from which the sample originated",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          component: "json",
          sub_group: {
            title: "Cosmic",
            width: 4,
          },
          group_name: "details4",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.cosmic_tier",
      kind: "numeric",
      multi_value: ",",
      label: {
        tr: "COSMIC Tier",
        en_GB: "COSMIC Tier",
      },
      description: {
        tr: "COSMIC veri tabanına göre varyanta atanmış sınıflandırma (Tier) bilgisi",
        en_GB: "Somatic variant classification",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 1,
          visible: true,
          truncate: true,
          sub_group: {
            title: "Cosmic",
            width: 4,
          },
          group_name: "details4",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.drug_cosmic",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "COSMIC İlaç Direnci",
        en_GB: "COSMIC Drug",
      },
      description: {
        tr: "COSMIC veri tabanınca varyantın direnç göstermesi muhtemel olan ilaçlar",
        en_GB: "The name of the drug which the mutation confers resistance to.",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          sub_group: {
            title: "Cosmic",
            width: 4,
          },
          group_name: "details4",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.cosmic_fusion",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "COSMIC Füzyon",
        en_GB: "COSMIC Fusion",
      },
      description: {
        tr: "COSMIC veri tabanında genomik lokasyon ile ilişkilendirilmiş füzyon transkriptleri",
        en_GB:
          "Syntax describing the portions of mRNA present (in HGVS 'r.' format) from each gene",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          component: "dialog",
          sub_group: {
            title: "Cosmic",
            width: 4,
          },
          group_name: "details4",
          sub_component: "multi_value_separated",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.allele_registry_id_clingen",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "ClinGen ID",
        en_GB: "ClinGen ID",
      },
      description: {
        tr: "Varyantın ClinGen veri tabanındaki ID numarası",
        en_GB: "ClinGen Allele Registry ID",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          sub_group: {
            title: "ClinGen",
            width: 4,
          },
          group_name: "details4",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.disease_clingen",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "ClinGen Fenotip",
        en_GB: "ClinGen Disease",
      },
      description: {
        tr: "ClinGen veri tabanınca ilişkilendirilmiş fenotip bilgisi",
        en_GB: "ClinGen associated phenotype by the expert panel curators",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          sub_group: {
            title: "ClinGen",
            width: 4,
          },
          group_name: "details4",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.mode_of_inheritance_clingen",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "ClinGen Kalıtım Modu",
        en_GB: "ClinGen Inheritence",
      },
      description: {
        tr: "ClinGen veri tabanına göre belirlenen kalıtım modu",
        en_GB: "ClinGen Inheritence Pattern",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          sub_group: {
            title: "ClinGen",
            width: 4,
          },
          group_name: "details4",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.assertion_clingen",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "ClinGen Sınıflandırması",
        en_GB: "ClinGen Classification",
      },
      description: {
        tr: "Varyantın uzman panellerince deerlendirmesine göre sınıflandırması",
        en_GB: "ClinGen Assertion",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          sub_group: {
            title: "ClinGen",
            width: 4,
          },
          group_name: "details4",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.expert_panel_clingen",
      kind: "enumeration",
      multi_value: ",",
      label: {
        tr: "ClinGen Uzman Paneli",
        en_GB: "ClinGen Panel",
      },
      description: {
        tr: "ClinGen veri tabanınca küretasyonun ait olduğu uzman paneli",
        en_GB: "ClinGen Expert Panel",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          sub_group: {
            title: "ClinGen",
            width: 4,
          },
          group_name: "details4",
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "details4.hpo_term_pheno",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "HPO Terimi",
        en_GB: "HPO Term",
      },
      description: {
        tr: "Gen ile ilişkilendirilmiş HPO terimleri",
        en_GB: "Human Phenotype Ontology associated pehnotype",
      },
      representation: {
        kind: "tabbed_grid",
        options: {
          order: 3,
          title: "External Databases (ClinVar, COSMIC, Phenotype)",
          width: 2,
          visible: true,
          component: "dialog",
          sub_group: {
            title: "HPO",
            width: 4,
          },
          group_name: "details4",
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "links.uniprot_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "UniProt",
        en_GB: "UniProt",
      },
      description: {
        tr: "UniProt harici bağlantı",
        en_GB: "UniProt External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.hgnc_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "HGNC",
        en_GB: "HGNC",
      },
      description: {
        tr: "HGNV harici bağlantı",
        en_GB: "HGNC External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.pubmed_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "COSMIC PubMed",
        en_GB: "PubMed (COSMIC)",
      },
      description: {
        tr: "PubMed makale harici bağlantısı",
        en_GB: "PubMed (COSMIC) External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.dbsnp_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "dbSNP",
        en_GB: "dbSNP",
      },
      description: {
        tr: "dbSNP harici bağlantı",
        en_GB: "dbSNP External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.varsome_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "VarSome",
        en_GB: "VarSome",
      },
      description: {
        tr: "VarSome harici bağlantı ",
        en_GB: "VarSome External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.franklin_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "Franklin",
        en_GB: "Franklin",
      },
      description: {
        tr: "Franklin harici bağlantı",
        en_GB: "Franklin External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.sequence_ontology_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "Sequence Ontology",
        en_GB: "Sequence Ontology",
      },
      description: {
        tr: "Sekans Ontolojisi harici bağlantı",
        en_GB: "Sequence Ontology External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.genecards_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "GeneCards",
        en_GB: "GeneCards",
      },
      description: {
        tr: "GeneCards harici bağlantı",
        en_GB: "GeneCards External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.ucsc_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "UCSC",
        en_GB: "UCSC",
      },
      description: {
        tr: "UCSC harici bağlantı",
        en_GB: "UCSC External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.gnomad_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "GnomAD",
        en_GB: "GnomAD",
      },
      description: {
        tr: "GnomAD harici bağlantı",
        en_GB: "GnomAD External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.omim_gene_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "OMIM Gen",
        en_GB: "OMIM Gene",
      },
      description: {
        tr: "OMIM Gen bilgisi harici bağlantı",
        en_GB: "Gene Based OMIM External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.omim_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "OMIM",
        en_GB: "OMIM",
      },
      description: {
        tr: "OMIM harici bağlantısı",
        en_GB: "OMIM External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.hpo_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "HP",
        en_GB: "HP",
      },
      description: {
        tr: "HP harici bağlantı",
        en_GB: "HP External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.mondo_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "MONDO",
        en_GB: "MONDO",
      },
      description: {
        tr: "MONDO harici bağlantı",
        en_GB: "MONDO External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "links.clinvar_link",
      kind: "free_form",
      multi_value: "|",
      label: {
        tr: "ClinVar",
        en_GB: "ClinVar",
      },
      description: {
        tr: "ClinVar harici bağlantı",
        en_GB: "ClinVar External Link",
      },
      representation: {
        kind: "sidebar_link",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "warning_col",
      kind: "free_form",
      multi_value: ",",
      label: {
        tr: "Uyarı Sütunu",
        en_GB: "warning_col",
      },
      description: {
        tr: "",
      },
      representation: {
        kind: "metadata",
        options: {
          visible: true,
          layout_visible: false,
        },
      },
      annotable: false,
    },
    {
      name: "vfreq_in_house",
      kind: "numeric",
      multi_value: null,
      label: {
        "*": "(missing translation)",
        en: "Var. Freq. in House",
        "tr-TR": "Kurum İçi Varyant Frekansı",
      },
      description: {
        "*": "(missing translation)",
        en: "In-House Variant Frequency",
        "tr-TR": "Varyantın Kurum bazında Gözlemlenme Frekansı",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 1,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "vfreq_in_cloud",
      kind: "numeric",
      multi_value: null,
      label: {
        "*": "(missing translation)",
        en: "Var. Freq. in Cloud",
        "tr-TR": "Bulut İçi Varyant Frekansı",
      },
      description: {
        "*": "(missing translation)",
        en: "In-Cloud Variant Frequency",
        "tr-TR": "Varyantın Bulut bazında Gözlemlenme Frekansı",
      },
      representation: {
        kind: "main_table_cell",
        options: {
          width: 1,
          layout_visible: true,
        },
      },
      annotable: false,
    },
    {
      name: "___tagged___",
      kind: "boolean",
      multi_value: null,
      label: {
        tr: "Varyant Etiketi",
        en_GB: "Variant Tag",
      },
      description: {
        tr: "Favori varyantlar",
        en_GB: "Favoured variants",
      },
      unique_values: [],
      annotable: false,
      representation: {
        kind: "main_table_cell",
        options: {
          layout_visible: true,
        },
      },
    },
  ],
  identifier_column: "main.variation",
  created_at: "2024-08-07T12:54:13.079513Z",
  updated_at: "2024-08-07T12:54:13.079513Z",
};
