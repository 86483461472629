/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import HelpButton from "./helpButton";
import { Save as SaveIcon } from "@material-ui/icons";
import { apiOrganizationPermissions } from "src/api";
import { useSnackbar } from "notistack";
import { getTranslated } from "../../reports/utils/helpers";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formTitle: {
    marginBottom: theme.spacing(2),
  },
  formAction: {
    width: "100%",
    marginTop: "10px",
  },
}));

const Permissions = (props) => {
  const classes = useStyles();
  const {
    permissions,
    organization_id,
    id,
    type,
    appConf,
    submitCallback,
    membersCount,
  } = props;
  const [permissionDetails, setPermissionDetails] = useState(undefined);
  const [activePermissions, setActivePermissions] = useState(undefined);
  const [forcePermissionDialogOpen, setForcePermissionDialogOpen] =
    useState(false);
  const [forcePermissions, setForcePermissions] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleForcePermissionDialogOpen = () => {
    setForcePermissionDialogOpen(true);
  };

  const handleForcePermissionDialogClose = () => {
    setForcePermissionDialogOpen(false);
    setForcePermissions(false);
  };

  useEffect(() => {
    const permDetails = {};
    for (let i = 0; i < appConf.permissions.length; i++) {
      permDetails[appConf.permissions[i].permission_type] =
        appConf.permissions[i];
    }

    const filteredPermissions = Object.assign({}, permissions);
    for (let item in filteredPermissions) {
      if (!Object.keys(permDetails ?? []).includes(item)) {
        delete filteredPermissions[item];
      }
    }

    setPermissionDetails(permDetails);
    setActivePermissions(filteredPermissions);
  }, []);

  const handleChange = (event) => {
    if (permissionDetails[event.target.name].is_public) {
      const permission_type = event.target.name;
      const newPermissions = Object.assign({}, activePermissions);
      if (Object.keys(activePermissions ?? []).includes(permission_type)) {
        delete newPermissions[permission_type];
      } else {
        // TODO handle allowance
        newPermissions[permission_type] = null;
      }

      setActivePermissions(newPermissions);
    } else {
      enqueueSnackbar(t("You don't have permission to set this permission."), {
        variant: "error",
      });
    }
  };

  const submitPermissions = () => {
    for (let item in activePermissions) {
      if (!permissionDetails[item].is_public) {
        delete activePermissions[item];
      }
    }

    setLoading(true);
    apiOrganizationPermissions(
      organization_id,
      type,
      id,
      Object.keys(activePermissions),
      forcePermissions,
      (data, status) => {
        setLoading(false);
        if (status === 200) {
          setForcePermissionDialogOpen(false);
          enqueueSnackbar(t("Permissions changed successfully."), {
            variant: "success",
            key: "permissions",
          });
        } else {
          enqueueSnackbar(
            t(
              data && data.details
                ? data.details
                : `Unexpected error - status code: {{status}}`,
              {
                status,
              }
            ),
            {
              variant: "error",
            }
          );
        }

        if (submitCallback) {
          submitCallback(status === 200 ? data : null);
        }
      }
    );
  };

  if (!permissionDetails || !activePermissions) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Box>
        <FormControl component="fieldset">
          <FormLabel className={classes.formTitle} component="legend">
            {t("Permissions")}
          </FormLabel>
          <FormGroup>
            {Object.keys(permissionDetails).map((item) => (
              <div key={item}>
                <FormControlLabel
                  control={
                    <Switch
                      data-testid={`switch-${item}`}
                      inputProps={{ "data-testid": `input-${item}` }}
                      checked={Object.keys(activePermissions).includes(item)}
                      onChange={handleChange}
                      name={permissionDetails[item].permission_type}
                    />
                  }
                  label={getTranslated(permissionDetails[item].label)}
                />
                <HelpButton
                  helpDesc={
                    getTranslated(permissionDetails[item].description) ?? ""
                  }
                  title={getTranslated(permissionDetails[item].label)}
                />
              </div>
            ))}
          </FormGroup>
        </FormControl>
        {["team", "department"].includes(type) && membersCount > 0 && (
          <Dialog
            open={forcePermissionDialogOpen}
            onClose={handleForcePermissionDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("Apply permissions")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(
                  "force_permissions_message",
                  "The selected permissions will take effect for the new members added to this {{type}}. I you wish to apply the permissions to the exisiting members, please check the following box and proceed.",
                  { type: type }
                )}
              </DialogContentText>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={forcePermissions}
                        onChange={() =>
                          setForcePermissions((prevState) => !prevState)
                        }
                        name="force_permissions"
                      />
                    }
                    label={t(
                      "Apply the current permissions to the existing members"
                    )}
                  />
                </FormGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleForcePermissionDialogClose}
                color="secondary"
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={submitPermissions}
                disabled={loading}
              >
                {t("Proceed")}
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <div className={classes.formAction}>
          <Button
            variant="contained"
            color="primary"
            onClick={
              ["team", "department"].includes(type) && membersCount > 0
                ? handleForcePermissionDialogOpen
                : submitPermissions
            }
            data-testid="submit-permissions"
            startIcon={<SaveIcon />}
            disabled={loading}
          >
            {t("Apply")}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </div>
      </Box>
    );
  }
};

export default Permissions;
