import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { apiChangePassword } from "src/api";
import {
  Box,
  colors,
  InputAdornment,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";

const MIN_PASSWORD_LENGTH = 8;

const PASSWORD_STRENGTH_BAR_CONF = {
  1: { label: "Very weak", value: 20, color: "red" },
  2: { label: "Weak", value: 40, color: "orange" },
  3: { label: "Moderate", value: 60, color: "yellow" },
  4: { label: "Strong", value: 80, color: "lightGreen" },
  5: { label: "Very strong", value: 100, color: "green" },
};

const useStyles = makeStyles((theme) => ({
  root: {
    gap: "10px",
    "& .label": {
      flexBasis: "150px",
    },
    "& .MuiLinearProgress-root": {
      width: "100%",
    },
    "& .red .MuiLinearProgress-bar": {
      backgroundColor: colors["red"][500],
    },
    "& .orange .MuiLinearProgress-bar": {
      backgroundColor: colors["orange"][500],
    },
    "& .yellow .MuiLinearProgress-bar": {
      backgroundColor: colors["yellow"][500],
    },
    "& .lightGreen .MuiLinearProgress-bar": {
      backgroundColor: colors["lightGreen"][500],
    },
    "& .green .MuiLinearProgress-bar": {
      backgroundColor: colors["green"][500],
    },
  },
}));

const PasswordStrengthMeter = (props) => {
  const classes = useStyles();
  const { passwordStrength } = props;
  const { t } = useTranslation();
  return (
    <Box className={classes.root} display="flex" alignItems="center">
      <span className="label">
        {t(PASSWORD_STRENGTH_BAR_CONF[passwordStrength].label)}
      </span>
      <LinearProgress
        className={PASSWORD_STRENGTH_BAR_CONF[passwordStrength].color}
        variant="determinate"
        value={PASSWORD_STRENGTH_BAR_CONF[passwordStrength].value}
      />
    </Box>
  );
};

const ChangePassword = (props) => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [newData, setNewData] = useState({
    new: undefined,
    confirm: undefined,
  });
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setLoading(false);
    setPasswordStrength(0);
    setPasswordMatch(false);
    setNewData({ new: undefined, confirm: undefined });
    setOpen(false);
  };

  const checkStrength = (event) => {
    const password = event.target.value;
    setNewData({ ...newData, new: password });

    let hasUpperCase = /[A-Z]/.test(password);
    let hasLowerCase = /[a-z]/.test(password);
    let hasNumbers = /\d/.test(password);
    let hasNonalphas = /\W/.test(password);

    setPasswordStrength(
      hasUpperCase +
        hasLowerCase +
        hasNumbers +
        hasNonalphas +
        (password.length > 12)
    );
  };

  useEffect(() => {
    setPasswordMatch(
      newData.new && newData.confirm && newData.new === newData.confirm
    );
  }, [newData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const formElements = event.target.elements;

    if (
      formElements["new-password"].value !==
      formElements["confirm-password"].value
    ) {
      enqueueSnackbar(
        t("Passwords do not match! Please check and try again."),
        {
          variant: "error",
        }
      );
      return;
    }

    const password = formElements["new-password"].value;

    if (password.length < MIN_PASSWORD_LENGTH) {
      enqueueSnackbar(
        t(
          "password_length_error",
          "Passwords must be at least {{min_length}} characters long.",
          {
            min_length: MIN_PASSWORD_LENGTH,
          }
        ),
        {
          variant: "error",
        }
      );
      return;
    }

    if (passwordStrength < 3) {
      enqueueSnackbar(
        t(
          "The password is not strong enought. Please try with a stronger password."
        ),
        {
          variant: "error",
        }
      );
      return;
    }

    setLoading(true);

    const formData = {
      "current-password": formElements["current-password"].value,
      "new-password": formElements["new-password"].value,
      "confirm-password": formElements["confirm-password"].value,
    };

    apiChangePassword(formData, (data, status) => {
      setLoading(false);
      if (status === 202) {
        enqueueSnackbar(t("Your password was successfully changed."), {
          variant: "success",
        });
      } else if (status === 400) {
        enqueueSnackbar(t("Provided password is not valid"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t("Something went wrong. Please try again later."), {
          variant: "error",
        });
      }
      handleClose();
    });
  };

  return (
    <div>
      <Button variant="text" color="primary" onClick={handleClickOpen}>
        {t("Change password")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Change password")}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              {t("Please use the fields below to change your password.")}
              {t(
                "Your new password should be at least 8 characters long and should contain at least 1 lowercase letter, 1 uppercase letter and 1 number."
              )}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="current-password"
              label={t("Current password")}
              type="password"
              required
              fullWidth
            />
            <TextField
              margin="dense"
              name="new-password"
              label={t("New password")}
              type="password"
              required
              fullWidth
              onChange={checkStrength}
            />
            {passwordStrength > 0 && (
              <PasswordStrengthMeter passwordStrength={passwordStrength} />
            )}
            <TextField
              margin="dense"
              name="confirm-password"
              label={t("Confirm password")}
              type="password"
              required
              fullWidth
              onChange={(e) =>
                setNewData({ ...newData, confirm: e.target.value })
              }
              InputProps={{
                endAdornment: passwordMatch ? (
                  <InputAdornment position="end">
                    <CheckCircleIcon style={{ color: colors["green"][500] }} />
                  </InputAdornment>
                ) : undefined,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={handleClose} color="primary">
              {t("Cancel")}
            </Button>
            <Button
              disabled={
                loading ||
                !newData.new ||
                !newData.confirm ||
                !passwordMatch ||
                newData.new.length < MIN_PASSWORD_LENGTH ||
                passwordStrength < 3
              }
              type="submit"
              variant="contained"
              color="primary"
            >
              {t("Apply")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ChangePassword;
