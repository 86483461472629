import React, { useState } from "react";
import { Button, makeStyles, MenuItem, TextField } from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import FlagIcon from "src/components/FlagIcon";
import { apiUpdateProfile } from "src/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: "20px",
  },
  flag: {
    marginRight: "8px",
  },
  input: {
    minWidth: "200px",
    marginRight: "10px",
  },
}));

const countryFromLang = {
  en_GB: "gb",
  de: "de",
  ru: "ru",
  fr: "fr",
  it: "it",
  tr_TR: "tr",
  ar: "sa",
};

const CustomDropdown = (props) => {
  const classes = useStyles();
  const { values, name, LabelTitle, langValue, setLangValue } = props;
  const [value, setValue] = useState(langValue);

  const handleChange = (event) => {
    const newVal = event.target.value;
    setValue(newVal);
    setLangValue(newVal);
  };

  return (
    <TextField
      id={`select-${LabelTitle.toLowerCase()}`}
      name={name}
      select
      value={value}
      onChange={handleChange}
      label={LabelTitle}
      variant={"outlined"}
      required
      className={classes.input}
      margin="dense"
    >
      {Object.keys(values).map((langKey, index) => (
        <MenuItem value={langKey} key={index}>
          <FlagIcon code={countryFromLang[langKey]} className={classes.flag} />
          {langList[langKey]}
        </MenuItem>
      ))}
    </TextField>
  );
};

const langList = {
  en_GB: "English",
  // de: "Deutsch",
  // ru: "Pусский",
  // fr: "Française",
  // it: "Italiano",
  tr_TR: "Türkçe",
  // ar: "العربية",
};

export const LanguageSection = (props) => {
  const { loadUser, userObject } = props;
  const classes = useStyles();
  const [langValue, setLangValue] = useState(
    userObject.settings.general["defaultLang"] ?? "en"
  );
  const { t } = useTranslation();

  const updateProfileCallback = (data, status) => {
    if (status === 200) {
      loadUser(data);
    } else {
      safeLogger(status, data);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    userObject.settings.general["defaultLang"] = langValue;
    apiUpdateProfile({ settings: userObject.settings }, updateProfileCallback);
  };

  return (
    <div className={classes.section}>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <CustomDropdown
          name={"default_language"}
          langValue={langValue}
          setLangValue={setLangValue}
          userObject={userObject}
          LabelTitle={t("Dasboard Language")}
          values={langList}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          startIcon={<SaveIcon />}
          style={{ marginTop: "10px" }}
        >
          {t("Save")}
        </Button>
      </form>
    </div>
  );
};

//TODO add loading when saving
