import React, { useState } from "react";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import { apiUpdateProfile } from "src/api";
import { loadUser } from "src/redux/actions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { useSnackbar } from "notistack";
import ChangePassword from "./changePassword";

const useStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8),
    },
  },
  inputField: {
    flex: "1 1 150px",
  },
}));

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => dispatch(loadUser()),
  };
}

const ConnectedEditAccount = (props) => {
  const { userObj, loadUser } = props;
  const classes = useStyle();
  const [formData, setFormData] = useState({
    first_name: userObj?.first_name ?? "",
    last_name: userObj?.last_name ?? "",
    email: userObj?.email,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const updateProfileCallback = (data, status) => {
    if (status === 200) {
      enqueueSnackbar(t("User information was updated"), {
        variant: "success",
      });
      loadUser(data);
    } else {
      safeLogger(status, data);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    apiUpdateProfile(formData, updateProfileCallback);
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Box display="flex" mb={1} pt={1} style={{ gap: "15px" }}>
          <TextField
            size="small"
            className={classes.inputField}
            variant="outlined"
            inputProps={{ "data-testid": "first_name" }}
            name="first_name"
            label={t("First name")}
            value={formData?.first_name}
            onChange={handleChange}
            disabled={userObj?.privileges}
          />
          <TextField
            size="small"
            className={classes.inputField}
            variant="outlined"
            inputProps={{ "data-testid": "input-last_name" }}
            data-testid="last_name"
            name="last_name"
            label={t("Last name")}
            value={formData.last_name}
            onChange={handleChange}
            disabled={userObj?.privileges}
          />
          <TextField
            size="small"
            className={classes.inputField}
            variant="outlined"
            inputProps={{ "data-testid": "email" }}
            name="email"
            label={t("Email")}
            value={formData.email}
            onChange={handleChange}
            disabled={userObj?.privileges}
          />
        </Box>
        <Box display="flex" width="100%">
          <ChangePassword />
          <Button
            type="submit"
            variant="contained"
            data-testid="profile-save-button"
            startIcon={<SaveIcon />}
            color="primary"
            disabled={userObj?.privileges}
            style={{ marginLeft: "auto" }}
          >
            {t("Save")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export const EditAccount = connect(
  null,
  mapDispatchToProps
)(ConnectedEditAccount);
