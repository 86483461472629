/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Annotations from "./annotations";
import VariantComments from "./comments";
import { apiListVariantAnnotationsComments } from "src/api";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const AnnotationsAndComments = (props) => {
  const {
    appConf,
    userObject,
    reportId,
    reportMeta,
    setReportMeta,
    activeRowId,
    igvLocation,
    rowAnnotations,
    defaultColDetailsObject,
  } = props;
  const classes = useStyles();
  const [data, setData] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    if (activeRowId) {
      apiListVariantAnnotationsComments(
        reportId,
        activeRowId,
        (data, status) => {
          if (status === 200) {
            if (mounted) {
              setData(data);
            }
          }
        }
      );
    }

    return () => {
      mounted = false;
    };
  }, [activeRowId]);

  if (!activeRowId) {
    return (
      <Box p={4}>{t("select_row", "Select a row to show details...")}</Box>
    );
  } else if (!data) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Grid item container xs={12}>
        {reportMeta.annotable && (
          <Grid item xs={6}>
            <Box p={4}>
              <Annotations
                annotationHistory={data.annotations}
                defaultColDetailsObject={defaultColDetailsObject}
                annotations={rowAnnotations}
                appConf={appConf}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={reportMeta.annotable ? 6 : 12}>
          <Box p={4}>
            <VariantComments
              userObject={userObject}
              reportId={reportId}
              appConf={appConf}
              activeRowId={activeRowId}
              identifierValue={igvLocation}
              setReportMeta={setReportMeta}
              commentData={data.comments}
              setData={setData}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
};

export default AnnotationsAndComments;
