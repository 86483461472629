/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Page from "src/components/Page";
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  CircularProgress,
  Divider,
  Box,
  Tabs,
  Tab,
  colors,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { apiProjectDetails } from "src/api";
import { useSnackbar } from "notistack";
import { ProjectTimeline } from "./projectTimeline";
import { CommentSection } from "./comments";
import { DetailsTable } from "./detailsTable";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { userHasPermission } from "src/components/utils";
import { projectsDetailsConf } from "src/config/projects";
import Search from "../tableExtensions/search";
import moment from "moment";
import { apiReportsDetailsPagination } from "src/api/endpoints";
import Pagination from "src/components/pagination";
import { Replay } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    color: colors.grey[800],
  },
  pageTabs: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "#C2C2C2 2px solid",
    },
    "& .MuiTabs-indicator": {
      height: "50px",
      borderBottom: theme.palette.secondary.main + " 2px solid",
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiTab-root": {
      zIndex: 10,
    },
  },
  tabPanelRoot: {
    height: "inherit",
  },
  tableFixHeader: {
    marginTop: "10px",
    marginBottom: "25px",
    "& thead > tr > th": {
      color: "white !important",
    },
  },
  pointer: { cursor: "pointer" },
  tableHead: {
    // padding: theme.spacing(2),
    backgroundColor: theme.palette.success.dark,
    // color: theme.palette.background.paper,
    fontWeight: "700",
    fontSize: 14,

    "& > *": {
      color: theme.palette.background.paper,
    },
  },
  sampleTableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  metaSection: {
    "& .section-title": {
      marginBottom: "8px",
    },
    "& .key-string": {
      color: theme.palette.text.disabled,
    },
    "& .divider": {
      margin: "15px auto",
    },
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TabPanel = (props) => {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.tabPanelRoot}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const mapStateToPropsPage = (state) => {
  return {
    appConf: state.appConf,
    userObj: state.userObject,
  };
};

const ConnectedProjectDetailsPage = (props) => {
  const classes = useStyles();
  const { appConf, userObj } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [selectedRowId, setSelectedRowId] = useState(undefined);
  const [projectData, setProjectData] = useState(null);
  const [reports, setReports] = useState(null);
  const [projectTmpData, setProjectTmpData] = useState(null);
  const [tableTabValue, setTableTabValue] = useState("1");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [reportsCount, setReportsCount] = useState(0);
  const defaultPageSize = useSelector(
    (state) => state.userObject.settings.general.sampleSize
  );
  const [pageSize, setPageSize] = useState(
    defaultPageSize ? defaultPageSize : 10
  );
  const [sort, setSort] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("value");
  const [searchValue, setSearchValue] = useState(search ? search : "");

  const sampleColNames = [
    { label: t("") },
    { label: t("File name") },
    { label: t("Sample name") },
    { label: t("Created at") },
    { label: t("Started at") },
    { label: t("Status") },
    { label: t("Genes") },
    { label: t("Actions") },
  ];
  const reportColNames = [
    { label: "", sort: false, name: "" },
    { label: t("Sample name"), sort: true, name: "name" },
    { label: t("Actions"), sort: false, name: "actions" },
    { label: t("Completed at"), sort: true, name: "processed_at" },
    { label: t("Status"), sort: false, name: "status" },
    // t("Last Variant Update"),
  ];

  useEffect(() => {
    if (selectedRowId) {
      navigate(`/reports/${selectedRowId}`);
    }
  }, [selectedRowId]);

  useEffect(() => {
    setTableLoading(true);
    apiReportsDetailsPagination(
      id,
      page,
      (data, status) => {
        if (status === 200) {
          setReports(data.results);
          setLoading(false);
          setTableLoading(false);
          if (data.count !== reportsCount) setReportsCount(data.count);
        } else {
          safeLogger(data);
          enqueueSnackbar(
            t(
              "unhandled_error_ressponse",
              "There was an error. Please try again"
            ),
            {
              variant: "error",
            }
          );
        }
      },
      pageSize,
      sort,
      search
    );
  }, [page, pageSize, sort, searchParams]);

  const handleTableTabChange = (event, newValue) => {
    setTableTabValue(newValue);
  };

  const listProjectCallback = (data, status) => {
    if (status === 200) {
      setProjectData(data);
      setProjectTmpData(data);
    } else {
      safeLogger(data);
      enqueueSnackbar(
        t("unhandled_error_ressponse", "There was an error. Please try again"),
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    if (projectData) {
      if (projectData.status === "completed") {
        setTableTabValue("2");
      } else {
        for (let i = 0; i < projectData.samples.length; i++) {
          if (projectData.samples[i].status === "completed") {
            setTableTabValue("2");
            break;
          }
        }
      }
    }
  }, [projectData]);

  useEffect(() => {
    apiProjectDetails(id, listProjectCallback);
  }, []);

  if (!projectData || !reports) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Project Details")}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" style={{ gap: 10 }} alignItems="center">
                <Typography
                  className={classes.pageTitle}
                  component="h3"
                  variant="h3"
                >
                  {projectData.name}
                </Typography>
                <ReAnalyse userObj={userObj} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Tabs
                className={classes.pageTabs}
                value={tableTabValue}
                onChange={handleTableTabChange}
                variant="fullWidth"
              >
                <Tab
                  value="1"
                  label={t("Uploaded samples")}
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                {projectData.status !== "failed" &&
                  projectData.status !== "cancelled" && (
                    <Tab
                      value="2"
                      label={t("Analysis reports")}
                      id="tab-2"
                      aria-controls="tabpanel-2"
                    />
                  )}
              </Tabs>
              <TabPanel value={tableTabValue} index="1">
                <DetailsTable
                  userObj={userObj}
                  classes={classes}
                  tableType="samples"
                  colNames={sampleColNames}
                  projectData={projectData}
                  setSelectedRowId={setSelectedRowId}
                />
              </TabPanel>
              {projectData.status !== "failed" &&
                projectData.status !== "cancelled" && (
                  <TabPanel value={tableTabValue} index="2">
                    <>
                      <Search
                        search={search}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setSearchParams={setSearchParams}
                        searchParams={searchParams}
                        style={{ marginTop: 15 }}
                        data={{ reports, ...projectTmpData }}
                        setData={setProjectData}
                        setTmpData={setProjectTmpData}
                        tableTabValue={tableTabValue}
                        url={true}
                        type={"projectDetail"}
                      />
                      {reports.length ? (
                        <>
                          {tableLoading ? (
                            <Box height={400} position={"relative"}>
                              <CircularProgress className={classes.spinner} />
                            </Box>
                          ) : (
                            <DetailsTable
                              userObj={userObj}
                              classes={classes}
                              sort={sort}
                              setSort={setSort}
                              reports={reports}
                              tableType="reports"
                              colNames={reportColNames}
                              projectData={projectData}
                              setSelectedRowId={setSelectedRowId}
                            />
                          )}
                        </>
                      ) : (
                        <Typography style={{ marginTop: "25px" }}>
                          {t(
                            "no_reports_generated",
                            "There are no reports generated yet..."
                          )}
                        </Typography>
                      )}
                      <Pagination
                        page={page}
                        pageSize={pageSize}
                        projectDataCount={reportsCount}
                        setLoading={setLoading}
                        setPage={setPage}
                        setPageSize={setPageSize}
                      />
                    </>
                  </TabPanel>
                )}
              {userHasPermission(userObj.activePermissions, "comment") && (
                <CommentSection
                  userObj={userObj}
                  appConf={appConf}
                  projectData={projectData}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3} className={classes.metaSection}>
              <Typography className="section-title">
                {t("Description")}
              </Typography>
              <Typography variant="body2">{projectData.description}</Typography>
              <Typography variant="body2">
                <span className="key-string">
                  {t("Total number of samples")}
                  {": "}
                </span>
                {projectData?.group_serial_count ?? 0}
              </Typography>
              <Typography variant="body2">
                <span className="key-string">
                  {t("Last Update")}
                  {": "}
                </span>
                {moment(projectData?.updated_at).format("YYYY-MM-DD h:s") ?? 0}
              </Typography>
              <Divider className="divider" />
              <Typography className="section-title">
                {t("Configuration")}
              </Typography>
              {projectsDetailsConf[projectData.project_type] &&
                projectsDetailsConf[projectData.project_type].summary &&
                projectsDetailsConf[projectData.project_type].summary.includes(
                  "project_type"
                ) && (
                  <Typography variant="body2">
                    <span className="key-string">
                      {t("Project type")}
                      {": "}
                    </span>
                    {appConf.entities.project_types[projectData.project_type]}
                  </Typography>
                )}
              {projectsDetailsConf[projectData.project_type] &&
                projectsDetailsConf[projectData.project_type].summary &&
                projectsDetailsConf[projectData.project_type].summary.includes(
                  "kit"
                ) && (
                  <Typography variant="body2">
                    <span className="key-string">
                      {t("Kit")}
                      {": "}
                    </span>
                    {appConf.entities.kits[projectData.kit]}
                  </Typography>
                )}
              {projectsDetailsConf[projectData.project_type] &&
                projectsDetailsConf[projectData.project_type].summary &&
                projectsDetailsConf[projectData.project_type].summary.includes(
                  "squencer"
                ) && (
                  <Typography variant="body2">
                    <span className="key-string">
                      {t("Sequencer")}
                      {": "}
                    </span>
                    {appConf.entities.sequencers[projectData.sequencer]}
                  </Typography>
                )}
              {projectsDetailsConf[projectData.project_type] &&
                projectsDetailsConf[projectData.project_type].summary &&
                projectsDetailsConf[projectData.project_type].summary.includes(
                  "pipeline_class"
                ) && (
                  <Typography variant="body2">
                    <span className="key-string">
                      {t("Pipeline")}
                      {": "}
                    </span>
                    {
                      appConf.entities.pipeline_classes[
                        projectData.project_type
                      ][projectData.pipeline_class]
                    }
                  </Typography>
                )}
              {projectData.samples &&
                projectData.samples[0].hasOwnProperty("extras") &&
                projectData.samples[0].extras &&
                projectData.samples[0].extras.hasOwnProperty(
                  "primary_histology"
                ) && (
                  <Typography variant="body2">
                    <span className="key-string">
                      {t("Primary Histology")}
                      {": "}
                    </span>
                    {projectData.samples[0].extras.primary_histology}
                  </Typography>
                )}
              {projectData.samples &&
                projectData.samples[0].hasOwnProperty("extras") &&
                projectData.samples[0].extras &&
                projectData.samples[0].extras.hasOwnProperty(
                  "primary_tissue"
                ) && (
                  <Typography variant="body2">
                    <span className="key-string">
                      {t("Primary Tissue")}
                      {": "}
                    </span>
                    {projectData.samples[0].extras.primary_tissue}
                  </Typography>
                )}
              <Divider className="divider" />
              <Typography className="section-title">{t("Timeline")}</Typography>
              <ProjectTimeline data={projectData.timeline} />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
};

export const ProjectDetailsPage = connect(
  mapStateToPropsPage,
  null
)(ConnectedProjectDetailsPage);

const ReAnalyse = ({ userObj }) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleReAnalyse = () => {};
  return (
    userHasPermission(userObj.activePermissions, "re-analyse") && (
      <>
        <Tooltip
          title={t(
            "This button is allow to you all samples of project reanalyse."
          )}
        >
          <IconButton onClick={handleOpen} color="secondary">
            <Replay />
          </IconButton>
        </Tooltip>
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("The project will be reanalysed. Do you confirm?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("No")}
            </Button>
            <Button
              disabled={loading}
              onClick={handleReAnalyse}
              color="secondary"
              autoFocus
            >
              {t("Yes")}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};
