import React from "react";
import { makeStyles, Stepper, Step, StepLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    paddingRight: "0px important",
    paddingLeft: "0px important",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    backgroundColor: "inherit",
    "& .MuiStepIcon-completed": {
      color: theme.palette.success.main,
    },
  },
}));

export const PhaseStepper = (props) => {
  const classes = useStyles();
  const { phase } = props;
  const { t } = useTranslation();

  const phaseDescription = {
    projectInit: t("Fill in the project form and add sample files"),
    samplesMeta: t("Verify sample file configuration"),
    finalize: t("Submit the project and upload files"),
  };

  const phaseMap = {
    init: 0,
    sampleMeta: 1,
    upload: 2,
    submit: 3,
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={phaseMap[phase]} className={classes.stepper}>
        {Object.keys(phaseDescription).map((phaseKey, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={phaseKey} {...stepProps}>
              <StepLabel {...labelProps}>
                {phaseDescription[phaseKey]}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};
