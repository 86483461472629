import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Page from "src/components/Page";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
}));

export const NetworkErrorPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t("Network error")}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                alt=""
                src="/assets/images/errors/network.svg"
                style={{ maxHeight: "400px" }}
              />
              <Typography style={{ maxWidth: "420px", textAlign: "center" }}>
                {t(
                  "network_error_message",
                  "Unfortunately, the application was not able to connect to the server. Please make sure you are connected to the server and try again. If there is nothing wrong with your connection, this could be a network or server problem. In this case, please be patient and try again after a while."
                )}
              </Typography>
              {moment().format("YYYY-MM-DD hh:mm:ss")}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
