/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  // Prompt,
  useNavigate,
} from "react-router-dom";
import { apiCreateProject, apiSubmitProject } from "src/api";
import Page from "src/components/Page";
import {
  Grid,
  Container,
  Box,
  makeStyles,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  colors,
  CircularProgress,
  Badge,
  ButtonBase,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Link,
  TableCell,
  Modal,
  Fade,
  Backdrop,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { DropZone } from "./dropZone";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  ACNVSampleFilesTable,
  ExomeBAMSampleFilesTable,
  ExomeFastqSampleFilesTable,
  ExomeVcfSampleFilesTable,
  TrioSampleFilesTable,
} from "./sampleFilesTable";
import { PhaseStepper } from "./phaseStepper";
import AttachedStorage from "./attachedStorage";
import { ExistingProjectsTable } from "./existingProjects";
import { useTranslation } from "react-i18next";
import { userHasPermission } from "src/components/utils";
import safeLogger from "src/services/safeLogger";
import {
  Close as CloseIcon,
  NavigateBefore as NavigateBeforeIcon,
} from "@material-ui/icons";
import { projectTypeForms } from "src/config";
import CustomDropdown from "src/components/customDropdown";
import Warning from "./warninig";
import Nanaopore from "./nanopore";
import NanoporeForm from "./nanoporeForm";
import CreditCalculate from "./creditCalculate";
import Duo from "./duo";
import DuoForm from "./duoForm";
import { Autocomplete } from "@material-ui/lab";
import { ListboxComponent } from "../reports/virtualizedTable/listBoxComponent";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    marginBottom: "40px",
    color: colors.grey[800],
  },
  form: {
    width: "100%",
    "& input": {
      minWidth: "200px",
    },
    "& .MuiTextField-root": {
      minWidth: "200px",
      margin: "8px",
    },
  },
  uploadTabs: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "#C2C2C2 2px solid",
    },
    "& .MuiTabs-indicator": {
      height: "50px",
      borderBottom: theme.palette.secondary.main + " 2px solid",
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiTab-root": {
      zIndex: 10,
    },
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  tabPanelRoot: {
    height: "inherit",
  },
  hidden: {
    display: "none",
  },
  projectTypeButton: {
    padding: theme.spacing(3),
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  exomeDialog: {
    "& .MuiDialog-paper": {
      padding: "20px",
    },
    "& .vcf-warning": {
      border: `1px solid ${colors.orange[400]}`,
      borderRadius: "5px",
      padding: theme.spacing(1),
      background: colors.orange[100],
      marginBottom: theme.spacing(4),
    },
  },
}));

const TabPanel = (props) => {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.tabPanelRoot}
      role="tabpanel"
      hidden={value !== index}
      id={`upload-tabpanel-${index}`}
      aria-labelledby={`upload-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const ProjectTypeButton = (props) => {
  const classes = useStyles();
  const { title, iconSrc, hoverIconSrc, pipelineName, setProjectType } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(iconSrc);
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={4}>
      <Paper style={{ minWidth: "300px" }}>
        {pipelineName === "exome" && (
          <Dialog
            className={classes.exomeDialog}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="exome-dialog-title"
            open={dialogOpen}
          >
            <DialogTitle id="exome-dialog-title">
              {t("Sample file type")}

              <IconButton
                style={{
                  position: "absolute",
                  right: "8px",
                  top: "8px",
                  color: colors.grey[500],
                }}
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setDialogOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("Please choose the sample file type.")}
              </DialogContentText>
              <DialogContentText className="vcf-warning">
                {t(
                  "Please be advised that only specific VCF file formats are acceptable. Read more about acceptable files using the link below"
                )}
                :
                <Link
                  style={{ display: "block" }}
                  href="https://app.massiveanalyser.com/articles/15"
                  target="_blank"
                >
                  {t("Compatible files with our system")}
                </Link>
              </DialogContentText>
              <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 20 }}>
                <Warning style={{ fontSize: 14 }}>
                  {t(
                    "The reference genome version integrated into our system is GRCh38. Please ensure that your BAM and VCF files are aligned to the correct reference genome when uploading."
                  )}
                </Warning>
              </Grid>
              <Box display="flex" alignItems="center">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setProjectType("exome_fastq");
                    setDialogOpen(false);
                  }}
                >
                  FASTQ
                </Button>
                <Button
                  style={{ marginLeft: "auto" }}
                  variant="outlined"
                  onClick={() => {
                    setProjectType("exome_bam");
                    setDialogOpen(false);
                  }}
                >
                  BAM
                </Button>
                <Button
                  style={{ marginLeft: "auto" }}
                  variant="outlined"
                  onClick={() => {
                    setProjectType("exome_vcf");
                    setDialogOpen(false);
                  }}
                >
                  VCF
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        <ButtonBase
          onClick={() =>
            pipelineName === "exome"
              ? setDialogOpen(true)
              : setProjectType(pipelineName)
          }
          onMouseEnter={() => setImgSrc(hoverIconSrc)}
          onMouseLeave={() => setImgSrc(iconSrc)}
          className={classes.projectTypeButton}
        >
          <img height="100px" src={imgSrc} alt="" />
          <Typography>{title}</Typography>
        </ButtonBase>
      </Paper>
    </Box>
  );
};

const ProjectTypeForm = (props) => {
  const { setProjectType } = props;
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body2">
        {t("Please choose the project type")}:
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <ProjectTypeButton
            setProjectType={setProjectType}
            pipelineName="exome"
            title={t("Exome")}
            iconSrc="/assets/images/icons/genetics-outline2.svg"
            hoverIconSrc="/assets/images/icons/genetics-color2.svg"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProjectTypeButton
            setProjectType={setProjectType}
            pipelineName="trio"
            title={t("Trio")}
            iconSrc="/assets/images/icons/family-outline.svg"
            hoverIconSrc="/assets/images/icons/family-color2.svg"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProjectTypeButton
            setProjectType={setProjectType}
            pipelineName="acnv"
            title={t("Advance CNV Detector")}
            iconSrc="/assets/images/icons/acnv-outline.svg"
            hoverIconSrc="/assets/images/icons/acnv-color.svg"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProjectTypeButton
            setProjectType={setProjectType}
            pipelineName="nanopore"
            title={t("Nanopore")}
            iconSrc="/assets/images/icons/nanopore.svg"
            hoverIconSrc="/assets/images/icons/nanopore-color.svg"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProjectTypeButton
            setProjectType={setProjectType}
            pipelineName="duo"
            title={t("Duo Analysis")}
            iconSrc="/assets/images/icons/nanopore.svg"
            hoverIconSrc="/assets/images/icons/nanopore-color.svg"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Warning style={{ fontSize: 15 }}>
            {t(
              "The reference genome version integrated into our system is GRCh38. Please ensure that your BAM and VCF files are aligned to the correct reference genome when uploading."
            )}
          </Warning>
        </Grid>
      </Grid>
    </>
  );
};

const ExomeFastqForm = (props) => {
  const { disabled, appConf, handleChange, formData } = props;
  const { t } = useTranslation();
  let exome_fastq = {};
  Object.entries(appConf.entities.pipeline_classes["exome_fastq"]).map(
    (val) => {
      exome_fastq = { ...exome_fastq, [val[0]]: t(val[1]) };
      return val;
    }
  );

  const sequencers = appConf.entities.sequencers;
  if (sequencers.hasOwnProperty("nanopore")) {
    delete sequencers["nanopore"];
  }

  return (
    <>
      <Box minWidth="100%">
        <TextField
          label={t("Project Title")}
          name="name"
          variant="outlined"
          required
          disabled={disabled}
          onChange={handleChange}
          value={formData ? formData.name : ""}
        />
        <CustomDropdown
          name="sample_kind"
          formData={formData}
          labelTitle={t("Type")}
          values={{
            single_end: t("Single End"),
            paired_end: t("Paired End"),
          }}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={1}
        />
        <CustomDropdown
          name="kit"
          formData={formData}
          labelTitle={t("Kit")}
          values={appConf.entities.kits}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={0}
        />
        <CustomDropdown
          name="sequencer"
          formData={formData}
          labelTitle={t("Sequencer Machine")}
          values={sequencers}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={1}
        />
        <CustomDropdown
          name="pipeline_class"
          formData={formData}
          labelTitle={t("Pipeline Type")}
          values={exome_fastq ?? {}}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={1}
        />
        <CustomDropdown
          name="single_gene"
          formData={formData}
          // TODO: Change label
          labelTitle={t("Assay Type")}
          values={{
            probe: t("Probe Enrichment Sequencing"),
            amplification: t("PCR Amplification Sequencing"),
          }}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={1}
        />
      </Box>
      <TextField
        fullWidth
        id="project-desc"
        name="description"
        value={formData.description ?? ""}
        label={t("Project Description")}
        placeholder={t("Describe your project (optional)")}
        multiline
        rows={4}
        variant="outlined"
        disabled={disabled}
        onChange={handleChange}
      />
    </>
  );
};

// single_gene &&
// pipeline_class &&
// (pipeline_class === "germline" || pipeline_class.includes("germline")) &&
// (single_gene === "amplification" ||
//   single_gene.includes("amplification")) && (

export const ConnectedSingleGene = (props) => {
  const { single_gene, pipeline_class, appConf, disabled, handleChange, data } =
    props;
  const { t } = useTranslation();

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text").split("\n");

    // Check if the pasted value matches any of the existing options
    let matchedOption = pastedValue.filter((option) =>
      appConf.entities.genes.includes(option)
    );

    if (data && data["genes"] && data["genes"].length > 0) {
      matchedOption = matchedOption.filter((gene) => {
        return !data["genes"].includes(gene);
      });
      matchedOption = [...data["genes"], ...matchedOption];
    }

    handleChange("genes", matchedOption);
  };

  return (
    single_gene &&
    pipeline_class &&
    (pipeline_class === "germline" || pipeline_class.includes("germline")) &&
    (single_gene === "amplification" ||
      single_gene.includes("amplification")) && (
      <>
        <TableCell>
          <Autocomplete
            multiple
            style={{ maxWidth: 400 }}
            noOptionsText={t("No Options")}
            disableCloseOnSelect
            name="genes"
            value={
              data && data["genes"] && data["genes"].length > 0
                ? data["genes"]
                : []
            }
            options={appConf.entities.genes}
            ListboxComponent={ListboxComponent}
            onChange={(e, value) => handleChange("genes", value)}
            renderInput={(props) => (
              <TextField {...props} onPaste={handlePaste} />
            )}
          />
        </TableCell>
      </>
    )
  );
};

export const ConnectedPrimaryHistology = (props) => {
  const { pipeline_class, appConf, disabled, handleChange, data } = props;
  const { t } = useTranslation();
  return (
    pipeline_class &&
    (pipeline_class === "somatic" || pipeline_class.includes("somatic")) && (
      <>
        <TableCell>
          <CustomDropdown
            style={{ minWidth: 175, width: "100%" }}
            name="primary_tissue"
            formData={data}
            labelTitle={t("Tissues")}
            values={appConf.entities.tissues.reduce(
              (a, v) => ({ ...a, [v]: v }),
              {}
            )}
            disabled={disabled}
            changeHandler={(e) => handleChange(e.target.name, e.target.value)}
            defaultIndex={0}
          />
        </TableCell>
        <TableCell>
          <TextField
            style={{ minWidth: 175, width: "100%" }}
            label={t("Histology")}
            name="primary_histology"
            variant="outlined"
            disabled={disabled}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={data ? data.primary_histology : ""}
          />
        </TableCell>
      </>
    )
  );
};

const ExomeVcfForm = (props) => {
  const { disabled, appConf, handleChange, formData } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box minWidth="100%">
        <TextField
          label={t("Project Title")}
          name="name"
          variant="outlined"
          required
          disabled={disabled}
          onChange={handleChange}
          value={formData.name}
        />
        <CustomDropdown
          name="pipeline_class"
          formData={formData}
          labelTitle={t("Pipeline Type")}
          values={appConf.entities.pipeline_classes["exome_vcf"] ?? {}}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={1}
        />
        <CustomDropdown
          name="kit"
          formData={formData}
          labelTitle={t("Kit")}
          values={appConf.entities.kits}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={0}
        />
      </Box>
      <TextField
        fullWidth
        id="project-desc"
        name="description"
        value={formData.description ?? ""}
        label={t("Project Description")}
        placeholder={t("Describe your project (optional)")}
        multiline
        rows={4}
        variant="outlined"
        disabled={disabled}
        onChange={handleChange}
      />
    </>
  );
};

const ExomeBAMForm = (props) => {
  const { disabled, appConf, handleChange, formData } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box minWidth="100%">
        <TextField
          label={t("Project Title")}
          name="name"
          variant="outlined"
          required
          disabled={disabled}
          onChange={handleChange}
          value={formData.name}
        />
        <CustomDropdown
          name="pipeline_class"
          formData={formData}
          labelTitle={t("Pipeline Type")}
          values={appConf.entities.pipeline_classes["exome_bam"] ?? {}}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={1}
        />
        <CustomDropdown
          name="kit"
          formData={formData}
          labelTitle={t("Kit")}
          values={appConf.entities.kits}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={0}
        />
        <CustomDropdown
          name="single_gene"
          formData={formData}
          // TODO: Change label
          labelTitle={t("Assay Type")}
          values={{
            probe: t("Probe Enrichment Sequencing"),
            amplification: t("PCR Amplification Sequencing"),
          }}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={1}
        />
      </Box>
      <TextField
        fullWidth
        id="project-desc"
        name="description"
        value={formData.description ?? ""}
        label={t("Project Description")}
        placeholder={t("Describe your project (optional)")}
        multiline
        rows={4}
        variant="outlined"
        disabled={disabled}
        onChange={handleChange}
      />
    </>
  );
};

const ACNVForm = (props) => {
  const { disabled, appConf, handleChange, formData } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box minWidth="100%">
        <TextField
          label={t("Project Title")}
          name="name"
          variant="outlined"
          required
          disabled={disabled}
          onChange={handleChange}
          value={formData.name}
        />
        <CustomDropdown
          name="kit"
          formData={formData}
          labelTitle={t("Kit")}
          values={appConf.entities.kits}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={0}
        />
      </Box>
      <TextField
        fullWidth
        id="project-desc"
        name="description"
        value={formData.description ?? ""}
        label={t("Project Description")}
        placeholder={t("Describe your project (optional)")}
        multiline
        rows={4}
        variant="outlined"
        disabled={disabled}
        onChange={handleChange}
      />
    </>
  );
};

const TrioForm = (props) => {
  const { disabled, appConf, handleChange, formData } = props;
  const { t } = useTranslation();

  const sequencers = appConf.entities.sequencers;
  if (sequencers.hasOwnProperty("nanopore")) {
    delete sequencers["nanopore"];
  }

  return (
    <>
      <Box minWidth="100%">
        <TextField
          label={t("Project Title")}
          name="name"
          variant="outlined"
          required
          disabled={disabled}
          onChange={handleChange}
          value={formData.name}
        />
        <CustomDropdown
          name="kit"
          formData={formData}
          labelTitle={t("Kit")}
          values={appConf.entities.kits}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={0}
        />
        <CustomDropdown
          name="sequencer"
          formData={formData}
          labelTitle={t("Sequencer Machine")}
          values={sequencers}
          disabled={disabled}
          changeHandler={handleChange}
          defaultIndex={1}
        />
      </Box>
      <TextField
        fullWidth
        id="project-desc"
        name="description"
        value={formData.description ?? ""}
        label={t("Project Description")}
        placeholder={t("Describe your project (optional)")}
        multiline
        rows={4}
        variant="outlined"
        disabled={disabled}
        onChange={handleChange}
      />
    </>
  );
};

const mapStateToPropsPage = (state) => {
  return {
    appConf: state.appConf,
    userObj: state.userObject,
  };
};

const ConnectedCreateProjectPage = (props) => {
  const { appConf, userObj } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [projectType, setProjectType] = useState(undefined);
  const [uploadTabValue, setUploadTabValue] = useState(
    userHasPermission(userObj.activePermissions, "upload_file")
      ? "1"
      : userHasPermission(userObj.activePermissions, "read_attached_storage")
      ? "2"
      : userHasPermission(userObj.activePermissions, "read_cloud_storage")
      ? "3"
      : "4"
  );
  const [formData, setFormData] = useState(undefined);

  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [fileActionType, setFileActionType] = useState("upload"); // upload, select, attach, transfer
  const [remoteFiles, setRemoteFiles] = useState(undefined);
  const [files, setFiles] = useState([]);
  const [fileFullPaths, setFileFullPaths] = useState([]);
  const [fileMeta, setFileMeta] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [directory, setDirectory] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [phase, setPhase] = useState("init");
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (projectType === "duo") {
      setUploadTabValue("4");
    }
  }, [projectType]);

  useEffect(() => {
    if (projectType && formData?.name !== "") {
      setFormData(projectTypeForms[projectType]);
    }

    if (!projectType && files.length) {
      setFiles([]);
    }

    if (!projectType && formData) {
      setFormData(undefined);
    }
  }, [projectType]);

  const handleUploadTabChange = (event, newValue) => {
    setUploadTabValue(newValue);
  };

  useEffect(() => {
    if (projectId) {
      setDisabled(true);
    }
  }, [projectId]);

  const handleChange = (event) => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData[event.target.name] = event.target.value
      ? event.target.value
      : null;
    setFormData(newFormData);
  };

  const projectSubmitCallback = (data, status) => {
    if (status === 200) {
      enqueueSnackbar(
        t(
          "project_submit_success_message",
          "File uploads completed successfully. Redirecting to project page..."
        ),
        {
          variant: "success",
        }
      );
      navigate(
        `/projects?page=0&pageSize=10&value=&filterValue={}&sort={"created_at":"DESC"}`
      );
    } else {
      enqueueSnackbar(t("An error occured while submitting your project!"), {
        variant: "error",
      });
    }
  };

  const arrangeSubmitBody = () => {
    const submitBody = {};
    let tmpFileMeta;
    if (projectType.includes("exome_fastq")) {
      tmpFileMeta = fileMeta.map((meta) => {
        if (meta["merged"]) {
          if (formData["sample_kind"] === "paired_end") {
            files.forEach((file) => {
              meta["merged"] = meta["merged"].map((merged) => {
                if (file.name === merged["pair_1"].name) {
                  meta["pair_1"]["annexes"].push(
                    uploadTabValue === "1" ? file.flowIdentifier : file.name
                  );
                } else if (file.name === merged["pair_2"].name) {
                  meta["pair_2"]["annexes"].push(
                    uploadTabValue === "1" ? file.flowIdentifier : file.name
                  );
                }
                return merged;
              });
            });
          } else {
            if (!meta["annexes"]) meta["annexes"] = [];
            files.forEach((file) => {
              meta["merged"].forEach((merged) => {
                if (file.flowIdentifier === merged.flowIdentifier) {
                  meta["annexes"].push(
                    uploadTabValue === "1" ? file.flowIdentifier : file.name
                  );
                }
              });
            });
          }
          delete meta["merged"];
        }
        delete meta["flowIdentifier"];
        delete meta["pair_1"]["flowIdentifier"];
        delete meta["pair_2"]["flowIdentifier"];
        return meta;
      });
    } else {
      tmpFileMeta = fileMeta;
    }
    submitBody["action_type"] = fileActionType;
    submitBody["samples"] = tmpFileMeta;
    if (fileActionType !== "upload" && projectType !== "duo") {
      submitBody["files"] = files;
    }

    return submitBody;
  };

  useEffect(() => {
    let mounted = true;

    if (["init", "sampleMeta"].includes(phase) && disabled) {
      setDisabled(false);
    }
    if (phase === "init") {
      setFileMeta([]);
    } else if (phase === "upload") {
    } else if (phase === "without_credit_submit") {
      formData["organization"] = userObj.activeOrganization.id;
      if (!formData["description"]) formData["description"] = null;
      apiCreateProject(formData, (data, status) => {
        if (status === 201) {
          if (mounted) {
            setProjectId(data.id);
            setPhase("submit");
          }
        } else {
          enqueueSnackbar(t("An error happened while creating the project!"), {
            variant: "error",
          });
        }
      });
    } else if (phase === "credit_calculate") {
      if (formData) {
        formData["organization"] = userObj.activeOrganization.id;
        if (!formData["description"]) formData["description"] = null;
        apiCreateProject(formData, (data, status) => {
          if (status === 201) {
            if (mounted) {
              setProjectId(data.id);
            }
          } else {
            enqueueSnackbar(
              t("An error happened while creating the project!"),
              {
                variant: "error",
              }
            );
          }
        });
      }
    } else if (phase === "submit") {
      const submitBody = arrangeSubmitBody();
      if (paymentId) submitBody["payment_id"] = paymentId;

      if (
        !projectType.includes("exome_fastq") &&
        formData.sample_kind === "pair_end"
      ) {
        const tmpSamples = [];
        submitBody["samples"].forEach((sample) => {
          if (typeof sample.pair_1 === "string") {
            sample.pair_1 = { ...sample.pair_1, annexes: [] };
          }

          if (typeof sample.pair_2 === "string" || !sample.pair_2.annexes) {
            sample.pair_2 = { ...sample.pair_2, annexes: [] };
          }
          tmpSamples.push(sample);
        });
        submitBody["samples"] = tmpSamples;
      }
      apiSubmitProject(projectId, submitBody, projectSubmitCallback);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [phase]);

  const handleNext = (event) => {
    event.preventDefault();
    if (projectType === "acnv" && files.length < 2) {
      enqueueSnackbar(t("ACNV analysis requires at least 2 sample files."), {
        variant: "error",
      });
    } else if (projectType === "trio" && files.length !== 6) {
      enqueueSnackbar(t("Trio analysis requires exactly 6 sample files."), {
        variant: "error",
      });
    } else if (
      projectType === "exome_fastq" &&
      formData.sample_kind === "paired_end" &&
      files.length % 2 !== 0
    ) {
      enqueueSnackbar(t("For paired end analysis the files should be even!"), {
        variant: "error",
      });
    } else {
      if (files.length) {
        safeLogger(files);
        setPhase(
          phase === "canceled_credit" ? "credit_calculate" : "sampleMeta"
        );
      } else {
        enqueueSnackbar(t("Please select sample files to upload"), {
          variant: "error",
        });
      }
    }
  };

  return (
    <Page className={classes.root} title={t("Create a new project")}>
      <Container maxWidth="xl">
        <Modal
          onClose={() => {}}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          open={phase === "credit_calculate"}
        >
          <Fade in={phase === "credit_calculate"}>
            <CreditCalculate
              projectId={projectId}
              setPhase={setPhase}
              setPaymentId={setPaymentId}
              arrangeSubmitBody={arrangeSubmitBody}
              formData={formData}
            />
          </Fade>
        </Modal>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              component="h3"
              variant="h3"
              className={classes.pageTitle}
            >
              {t(
                projectType
                  ? "Create New {{projectType}} Project"
                  : "Create New Project",
                {
                  projectType: t(
                    projectType
                      ? appConf.entities.project_types[projectType] ??
                          projectType
                      : projectType
                  ),
                }
              )}
            </Typography>
          </Grid>
          {!projectType ? (
            <ProjectTypeForm setProjectType={setProjectType} />
          ) : phase === "sampleMeta" ? (
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <PhaseStepper phase={phase} />
              </Grid>
              {projectType === "exome_fastq" ? (
                <ExomeFastqSampleFilesTable
                  appConf={appConf}
                  formData={formData}
                  files={files}
                  uploadTabValue={uploadTabValue}
                  fileMeta={fileMeta}
                  setFileMeta={setFileMeta}
                  phase={phase}
                  setPhase={setPhase}
                />
              ) : projectType === "exome_vcf" ? (
                <ExomeVcfSampleFilesTable
                  formData={formData}
                  files={files}
                  fileMeta={fileMeta}
                  setFileMeta={setFileMeta}
                  phase={phase}
                  setPhase={setPhase}
                />
              ) : projectType === "exome_bam" ? (
                <ExomeBAMSampleFilesTable
                  formData={formData}
                  files={files}
                  fileMeta={fileMeta}
                  setFileMeta={setFileMeta}
                  phase={phase}
                  setPhase={setPhase}
                />
              ) : projectType === "trio" ? (
                <TrioSampleFilesTable
                  appConf={appConf}
                  formData={formData}
                  files={files}
                  fileMeta={fileMeta}
                  setFileMeta={setFileMeta}
                  phase={phase}
                  setPhase={setPhase}
                />
              ) : projectType === "acnv" ? (
                <ACNVSampleFilesTable
                  appConf={appConf}
                  formData={formData}
                  files={files}
                  fileMeta={fileMeta}
                  setFileMeta={setFileMeta}
                  phase={phase}
                  setPhase={setPhase}
                />
              ) : projectType === "nanopore" ? (
                <Nanaopore
                  appConf={appConf}
                  formData={formData}
                  directory={directory}
                  files={files}
                  fileMeta={fileMeta}
                  setFileMeta={setFileMeta}
                  phase={phase}
                  setPhase={setPhase}
                />
              ) : projectType === "duo" ? (
                <Duo
                  appConf={appConf}
                  formData={formData}
                  directory={directory}
                  files={files}
                  fileMeta={fileMeta}
                  setFileMeta={setFileMeta}
                  phase={phase}
                  setPhase={setPhase}
                />
              ) : (
                "a2"
              )}
            </Grid>
          ) : (
            <form style={{ width: "100%" }} onSubmit={handleNext}>
              <Grid item xs={12}>
                <PhaseStepper phase={phase} />
              </Grid>
              {formData && (
                <Grid item xs={12}>
                  {projectType === "exome_fastq" ? (
                    <div className={classes.form}>
                      <ExomeFastqForm
                        formData={formData}
                        disabled={disabled}
                        appConf={appConf}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : projectType === "exome_vcf" ? (
                    <div className={classes.form}>
                      <ExomeVcfForm
                        formData={formData}
                        disabled={disabled}
                        appConf={appConf}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : projectType === "exome_bam" ? (
                    <div className={classes.form}>
                      <ExomeBAMForm
                        formData={formData}
                        disabled={disabled}
                        appConf={appConf}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : projectType === "trio" ? (
                    <div className={classes.form}>
                      <TrioForm
                        formData={formData}
                        disabled={disabled}
                        appConf={appConf}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : projectType === "acnv" ? (
                    <div className={classes.form}>
                      <ACNVForm
                        formData={formData}
                        disabled={disabled}
                        appConf={appConf}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : projectType === "nanopore" ? (
                    <div className={classes.form}>
                      <NanoporeForm
                        formData={formData}
                        disabled={disabled}
                        setFormData={setFormData}
                        appConf={appConf}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : projectType === "duo" ? (
                    <div className={classes.form}>
                      <DuoForm
                        formData={formData}
                        disabled={disabled}
                        setFormData={setFormData}
                        appConf={appConf}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : (
                    <div> asfasfsasa</div>
                  )}
                </Grid>
              )}
              <>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Tabs
                    className={classes.uploadTabs}
                    value={uploadTabValue}
                    onChange={handleUploadTabChange}
                    variant="fullWidth"
                  >
                    {userHasPermission(
                      userObj.activePermissions,
                      "upload_file"
                    ) &&
                      projectType !== "duo" && (
                        <Tab
                          value="1"
                          label={
                            fileActionType === "upload" && files.length ? (
                              <Badge
                                badgeContent={files.length}
                                overlap="rectangular"
                                max={999}
                                color="secondary"
                              >
                                {t("local files")}
                              </Badge>
                            ) : (
                              t("local files")
                            )
                          }
                          id="upload-tab-1"
                          aria-controls="upload-tabpanel-1"
                          disabled={disabled}
                        />
                      )}
                    {userHasPermission(
                      userObj.activePermissions,
                      "attached_storage"
                    ) &&
                      projectType !== "duo" && (
                        <Tab
                          value="2"
                          label={
                            fileActionType === "attach" && files.length ? (
                              <Badge
                                badgeContent={files.length}
                                overlap="rectangular"
                                max={999}
                                color="secondary"
                              >
                                {t("server attached storage")}
                              </Badge>
                            ) : (
                              t("server attached storage")
                            )
                          }
                          id="upload-tab-2"
                          aria-controls="upload-tabpanel-2"
                          disabled={disabled}
                        />
                      )}
                    {userHasPermission(
                      userObj.activePermissions,
                      "cloud_storage"
                    ) &&
                      projectType !== "duo" && (
                        <Tab
                          value="3"
                          label={
                            fileActionType === "cloud" && files.length ? (
                              <Badge
                                badgeContent={files.length}
                                overlap="rectangular"
                                max={999}
                                color="secondary"
                              >
                                {t("cloud storage system")}
                              </Badge>
                            ) : (
                              t("cloud storage system")
                            )
                          }
                          id="upload-tab-3"
                          aria-controls="upload-tabpanel-3"
                          disabled={disabled}
                        />
                      )}
                    <Tab
                      value="4"
                      label={
                        fileActionType === "select" && files.length ? (
                          <Badge
                            badgeContent={files.length}
                            overlap="rectangular"
                            max={999}
                            color="secondary"
                          >
                            {t("existing projects")}
                          </Badge>
                        ) : (
                          t("existing projects")
                        )
                      }
                      id="upload-tab-4"
                      aria-controls="upload-tabpanel-4"
                      disabled={disabled}
                    />
                  </Tabs>
                  {userHasPermission(
                    userObj.activePermissions,
                    "upload_file"
                  ) && (
                    <TabPanel value={uploadTabValue} index="1">
                      <DropZone
                        projectType={projectType}
                        userObj={userObj}
                        fileFullPaths={fileFullPaths}
                        setFileFullPaths={setFileFullPaths}
                        fileActionType={fileActionType}
                        setFileActionType={setFileActionType}
                        files={files}
                        phase={phase}
                        directory={directory}
                        fileMeta={fileMeta}
                        setDirectory={setDirectory}
                        setFiles={setFiles}
                        projectId={projectId}
                        setProjectId={setProjectId}
                        disabled={disabled}
                        setPhase={setPhase}
                      />
                    </TabPanel>
                  )}
                  {userHasPermission(
                    userObj.activePermissions,
                    "attached_storage"
                  ) && (
                    <TabPanel value={uploadTabValue} index="2">
                      <AttachedStorage
                        userObj={userObj}
                        nextButtonLoading={nextButtonLoading}
                        setNextButtonLoading={setNextButtonLoading}
                        files={files}
                        phase={phase}
                        setFiles={setFiles}
                        remoteFiles={remoteFiles}
                        setRemoteFiles={setRemoteFiles}
                        projectId={projectId}
                        setProjectId={setProjectId}
                        setPhase={setPhase}
                        fileActionType={fileActionType}
                        setFileActionType={setFileActionType}
                        disabled={disabled}
                      />
                    </TabPanel>
                  )}
                  {userHasPermission(
                    userObj.activePermissions,
                    "cloud_storage"
                  ) && (
                    <TabPanel value={uploadTabValue} index="3">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img
                          alt=""
                          src="/assets/images/errors/under-construction.svg"
                          style={{
                            maxHeight: "400px",
                            marginTop: "-100px",
                          }}
                        />
                      </Box>
                    </TabPanel>
                  )}
                  <TabPanel value={uploadTabValue} index="4">
                    <ExistingProjectsTable
                      userObj={userObj}
                      projectId={projectId}
                      phase={phase}
                      projectType={projectType}
                      setPhase={setPhase}
                      setFiles={setFiles}
                      remoteFiles={remoteFiles}
                      setRemoteFiles={setRemoteFiles}
                      fileActionType={fileActionType}
                      setFileActionType={setFileActionType}
                      disabled={disabled}
                    />
                  </TabPanel>
                </Grid>
                <Box display="flex" mt={5} width="100%">
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<NavigateBeforeIcon />}
                    onClick={() => setProjectType(undefined)}
                  >
                    {t("Back")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                    }}
                    endIcon={<NavigateNextIcon />}
                    disabled={
                      (disabled || !Boolean(files.length)) &&
                      phase !== "canceled_credit"
                    }
                  >
                    {t(phase === "canceled_credit" ? "Submit" : "Next")}
                    {nextButtonLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </Box>
              </>
            </form>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export const PrimaryHistology = connect(mapStateToPropsPage)(
  ConnectedPrimaryHistology
);

export const SingleGene = connect(mapStateToPropsPage)(ConnectedSingleGene);

export const CreateProjectPage = connect(
  mapStateToPropsPage,
  null
)(ConnectedCreateProjectPage);

// TODO create project 1. assign users/participants. 2. Meta data optional fields: diagnostics, gender, age, ethnicity,
