import { Box, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

function DuoForm(props) {
  const { disabled, appConf, handleChange, formData } = props;

  const { t } = useTranslation();

  return (
    <>
      <Box minWidth="100%">
        <TextField
          label={t("Project Title")}
          name="name"
          style={{ width: "100%" }}
          variant="outlined"
          required
          disabled={disabled}
          onChange={handleChange}
          value={formData ? formData.name : ""}
        />
      </Box>
      <TextField
        fullWidth
        id="project-desc"
        name="description"
        value={formData.description ?? ""}
        label={t("Project Description")}
        placeholder={t("Describe your project (optional)")}
        multiline
        rows={4}
        row
        variant="outlined"
        disabled={disabled}
        onChange={handleChange}
      />
    </>
  );
}

export default DuoForm;
