/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  colors,
  Dialog,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiProjectDetails } from "src/api";
import safeLogger from "src/services/safeLogger";
import { DetailsTable } from "../projectDetails/detailsTable";
import { apiReportsDetailsPagination } from "src/api/endpoints";
import Search from "../tableExtensions/search";
import Pagination from "src/components/pagination";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    marginBottom: "40px",
    color: colors.grey[800],
  },
  pageTabs: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "#C2C2C2 2px solid",
    },
    "& .MuiTabs-indicator": {
      height: "50px",
      borderBottom: theme.palette.secondary.main + " 2px solid",
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiTab-root": {
      zIndex: 10,
    },
  },
  tabPanelRoot: {
    height: "inherit",
  },
  tableFixHeader: {
    marginTop: "10px",
    marginBottom: "25px",
  },
  tableHead: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.background.paper,
    fontWeight: "700",
    fontSize: 14,
    "& > *": {
      color: theme.palette.background.paper,
    },
  },
  dialog: {
    width: "100%",
    ".MuiDialog-paperWidthSm": {
      width: "100% !important",
      maxWidth: "100%",
    },
  },
  sampleTableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  metaSection: {
    "& .section-title": {
      marginBottom: "8px",
    },
    "& .key-string": {
      color: theme.palette.text.disabled,
    },
    "& .divider": {
      margin: "15px auto",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  return { userObject: state.userObject, appConf: state.appConf };
};

export default function ConnectedSamplesPopUp(props) {
  const [reports, setReports] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reportsCount, setReportsCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("value");
  const [searchValue, setSearchValue] = useState(search ? search : "");
  const [projectTmpData, setProjectTmpData] = useState(null);
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRowId, setSelectedRowId] = useState(undefined);
  const { t } = useTranslation();
  const {
    id,
    userObject,
    setProjectData,
    projectData,
    handleSelect,
    open,
    handleClose,
  } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const reportColNames = [
    { label: "", sort: false, name: "" },
    { label: t("Sample name"), sort: true, name: "name" },
    { label: t("Actions"), sort: false, name: "actions" },
    { label: t("Completed at"), sort: true, name: "processed_at" },
    { label: t("Status"), sort: false, name: "status" },
    // t("Last Variant Update"),
  ];

  useEffect(() => {
    if (selectedRowId) {
      navigate(`/reports/${selectedRowId}`);
      handleSelect();
      handleClose();
    }
  }, [selectedRowId]);

  useEffect(() => {
    setTableLoading(true);
    apiReportsDetailsPagination(
      id,
      page,
      (data, status) => {
        if (status === 200) {
          setReports(data.results);
          setTableLoading(false);
          if (data.count !== reportsCount) setReportsCount(data.count);
        } else {
          safeLogger(data);
          enqueueSnackbar(
            t(
              "unhandled_error_ressponse",
              "There was an error. Please try again"
            ),
            {
              variant: "error",
            }
          );
        }
      },
      pageSize,
      sort,
      searchValue
    );
  }, [page, pageSize, sort, searchParams]);

  const listProjectCallback = (data, status) => {
    if (status === 200) {
      safeLogger(data);
      setProjectData(data);
    } else {
      safeLogger(data);
      enqueueSnackbar(
        t("unhandled_error_ressponse", "There was an error. Please try again"),
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    apiProjectDetails(id, listProjectCallback);
  }, []);

  return (
    <Dialog
      style={{ width: "75%", maxHeight: "75%", margin: "auto" }}
      onClose={handleClose}
      className={classes.dialog}
      fullScreen
      TransitionComponent={Transition}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <Close></Close>
        </IconButton>
      </Toolbar>
      <DialogTitle id="simple-dialog-title">
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={{ fontSize: "24px" }}
        >
          {t("Project Samples")}
        </Typography>
      </DialogTitle>
      <Box style={{ margin: 25 }}>
        {reports && !tableLoading ? (
          <>
            <Search
              search={search}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              style={{ marginTop: 15 }}
              data={{ reports, ...projectTmpData }}
              setData={setProjectData}
              setTmpData={setProjectTmpData}
              url={true}
              type={"projectDetail"}
            />
            <DetailsTable
              userObj={userObject}
              classes={classes}
              sort={sort}
              setSort={setSort}
              tableType="reports"
              colNames={reportColNames}
              reports={reports}
              projectData={projectData}
              setSelectedRowId={setSelectedRowId}
            />
            <Pagination
              page={page}
              pageSize={pageSize}
              projectDataCount={reportsCount}
              setLoading={setTableLoading}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 500,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

export const SamplesPopUp = connect(mapStateToProps)(ConnectedSamplesPopUp);
