import { Grid } from "@material-ui/core";
import React from "react";
import Footer from "./footer";
import Header from "./header";
import Row from "./row/index";
function Table({ data, title, icon, url }) {
  return (
    <div>
      <Header title={title} icon={icon} />
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {data.map((val, key) => (
          <Row key={key} val={val} />
        ))}
      </Grid>
      <Footer url={url} />
    </div>
  );
}

export default Table;
