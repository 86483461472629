import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import { Save as SaveIcon } from "@material-ui/icons";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
}));

const CustomDropdown = (props) => {
  const { values, changeHandler, label, defaultValue, name } = props;
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event) => {
    changeHandler(event);
    const newVal = event.target.value;
    setValue(newVal);
  };

  return (
    <React.Fragment>
      <TextField
        id={`select-${name}`}
        select
        name={name}
        value={value}
        onChange={handleChange}
        label={label}
        variant={"outlined"}
        required
      >
        {Object.entries(values).map((item, index) => (
          <MenuItem value={item[0]} key={index}>
            {item[1]}
          </MenuItem>
        ))}
      </TextField>
    </React.Fragment>
  );
};

const sampleDepartments = {
  0: "Laboratory",
  1: "Management",
};
const sampleGroups = {
  0: "Clients",
  1: "Employees",
  2: "Project Owners",
  3: "Team Leaders",
  4: "Organization Managers",
};
export const EditUser = (props) => {
  const classes = useStyles();
  const [userData, setUserData] = useState({
    id: 1,
    username: "normal_employee",
    first_name: "Normal",
    last_name: "Employee",
    email: "normal_employee@sampleinstitute.com",
    group_id: 1,
    department_id: 1,
  });
  // const { t } = useTranslation();

  const handleDataChange = (event) => {
    const value = event.target.value;
    setUserData({ ...userData, [event.target.name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    safeLogger(userData);
  };

  return (
    <Page className={classes.root} title={"Account"}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h3" variant="h3">
              Edit User
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                label={"Username"}
                value={userData.username}
                name={"username"}
                onChange={handleDataChange}
                required
              />
              <TextField
                variant="outlined"
                label={"First Name"}
                value={userData.first_name}
                name={"first_name"}
                onChange={handleDataChange}
                required
              />
              <TextField
                variant="outlined"
                label={"Last Name"}
                value={userData.last_name}
                name={"last_name"}
                onChange={handleDataChange}
                required
              />
              <TextField
                variant="outlined"
                label={"Email"}
                value={userData.email}
                name={"email"}
                onChange={handleDataChange}
                required
                type="email"
              />
              <CustomDropdown
                label={"Department"}
                name={"department_id"}
                defaultValue={userData.department_id}
                changeHandler={handleDataChange}
                values={sampleDepartments}
              />
              <CustomDropdown
                label={"Group"}
                name={"group_id"}
                defaultValue={userData.group_id}
                changeHandler={handleDataChange}
                values={sampleGroups}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
