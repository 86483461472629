/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import {
  apiAddToSystemStatusChoices,
  apiAnnotationStatus,
  apiGetOrganizationDetails,
  apiUpdateAnnotationStatus,
  apiUpdateAnnotationStatusOrdinal,
} from "src/api/endpoints";
import { reorder } from "src/components/pages/settings/tableConfigPage/tableLayoutEditor";
import StatusCreateDialog from "./statusCreate";
import Status from "./status";
import { connect } from "react-redux";
import { setDefaultOptions } from "date-fns";

function AnnotationStatus({ organizationId, userId, statusChoices }) {
  const [annotationDatas, setAnnotationDatas] = useState(statusChoices);
  const [createDialog, setCreateDialog] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [type, setType] = useState("create");
  const [update, setUpdate] = useState();
  // useEffect(() => {
  //   apiAnnotationStatus(organizationId, (data, status) => {
  //     if (status === 200) {
  //       setAnnotationDatas(data);
  //     }
  //   });
  // }, [refreshData]);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    apiGetOrganizationDetails(organizationId, (data, status) => {
      if (status === 200) {
        if (mounted) {
          if (!data.status_choices.length)
            apiAddToSystemStatusChoices(organizationId);
          setAnnotationDatas(data.status_choices);
          setDefaultStatus(data?.default_report_status);
        }
      }
    });

    return () => {
      mounted = false;
    };
  }, [refreshData]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const cols = reorder(
      annotationDatas,
      result.source.index,
      result.destination.index
    );
    let data = cols.map((val, index) => {
      return { ...val, ordinal: index };
    });
    setAnnotationDatas(data);
  };
  const handleUpdateOrder = (result) => {
    let data = annotationDatas.forEach((val, index) => {
      apiUpdateAnnotationStatus(
        organizationId,
        val.id,
        val,
        (data, status) => {}
      );
    });
    enqueueSnackbar(t("Successfully updated"), {
      variant: "success",
    });
    // apiUpdateAnnotationStatusOrdinal(data, (data, status) => {
    //   if (status === 202) {
    //     enqueueSnackbar(t("Successfully updated"), {
    //       variant: "success",
    //     });
    //   } else {
    //     enqueueSnackbar(t("Something is wrong"), {
    //       variant: "error",
    //     });
    //   }
    // });
  };

  if (!annotationDatas) {
    return <CircularProgress />;
  } else {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <StatusCreateDialog
            setRefreshData={setRefreshData}
            organizationId={organizationId}
            userId={userId}
            count={annotationDatas.length}
            val={update}
            createDialog={createDialog}
            type={type}
            setCreateDialog={setCreateDialog}
          />
          <Button
            style={{ marginLeft: "16px", height: "30px" }}
            onClick={handleUpdateOrder}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Save />}
          >
            {t("Update")}
          </Button>
          <Button
            style={{ marginLeft: "16px", height: "30px" }}
            onClick={() => {
              setCreateDialog((prev) => !prev);
              setUpdate(null);
              setType("create");
            }}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Save />}
          >
            {t("Create")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DragDropContext
            // onBeforeDragStart={onBeforeDragStart}
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <ul ref={provided.innerRef} {...provided.droppableProps}>
                  {annotationDatas.map((val, index) => (
                    <Status
                      setUpdate={setUpdate}
                      setType={setType}
                      setCreateDialog={setCreateDialog}
                      organizationId={organizationId}
                      allData={annotationDatas}
                      defaultStatus={defaultStatus}
                      setRefreshData={setRefreshData}
                      key={index}
                      val={val}
                      index={index}
                      provided={provided}
                    />
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return { appConf: state.appConf };
};

export default connect(mapStateToProps)(AnnotationStatus);
