import React from "react";

function DnaIcon({ style, fill }) {
  return (
    <svg
      style={style}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          style={fill}
          d="M3497 5106 c-85 -31 -204 -177 -273 -335 -48 -109 -43 -152 19 -174
   47 -17 64 1 126 128 45 93 74 137 118 184 67 69 97 78 138 36 37 -36 32 -70
   -16 -127 -94 -112 -148 -240 -174 -416 -25 -167 -17 -423 20 -641 8 -47 13
   -86 12 -87 -3 -3 -160 36 -165 41 -13 12 -34 258 -37 422 -2 111 -6 210 -9
   221 -6 19 -45 42 -71 42 -8 0 -27 -11 -42 -24 l-28 -24 0 -194 c0 -106 6 -240
   13 -296 8 -57 12 -106 9 -108 -2 -2 -30 2 -63 8 -96 20 -469 17 -584 -5 -116
   -22 -303 -83 -397 -130 -263 -133 -490 -362 -612 -620 -57 -121 -88 -214 -118
   -355 -24 -113 -27 -150 -27 -327 0 -125 4 -227 13 -273 7 -40 11 -75 10 -76
   -2 -2 -51 3 -109 10 -147 18 -448 18 -577 -2 -249 -37 -441 -122 -584 -259
   -60 -57 -78 -90 -86 -153 -8 -73 12 -130 66 -183 49 -49 96 -69 166 -69 39 0
   47 -8 335 -294 198 -198 304 -297 323 -302 42 -10 81 22 85 71 3 37 -1 41
   -284 325 l-287 289 49 30 c62 40 131 65 229 86 l79 16 389 -389 389 -389 -15
   -74 c-20 -95 -49 -172 -87 -233 l-31 -48 -109 106 c-103 101 -112 107 -146
   104 -48 -4 -80 -43 -70 -84 5 -17 53 -75 122 -144 107 -108 114 -119 114 -156
   0 -129 101 -234 225 -234 69 0 109 20 181 93 160 163 254 410 273 721 8 134 0
   353 -17 476 -6 36 -8 67 -6 69 1 2 31 -2 66 -8 35 -6 129 -14 210 -18 128 -5
   150 -4 173 11 37 25 48 60 30 96 -19 36 -25 37 -195 44 -167 6 -258 22 -525
   91 -488 127 -863 154 -1136 85 -124 -32 -192 -67 -313 -160 -47 -36 -79 -38
   -111 -5 -41 40 -33 72 34 136 154 147 406 222 741 222 267 0 472 -33 890 -141
   244 -63 349 -76 538 -69 216 7 351 41 522 128 333 170 534 487 570 898 13 154
   -8 392 -46 522 -4 16 2 16 76 -1 45 -10 84 -21 87 -24 16 -16 34 -159 40 -311
   4 -132 2 -195 -11 -283 -73 -493 -370 -862 -816 -1012 -128 -44 -163 -83 -124
   -138 30 -43 56 -44 157 -11 491 164 825 555 924 1082 22 112 25 487 5 581 -6
   33 -10 61 -8 63 2 3 48 -1 103 -8 149 -20 486 -17 608 5 132 23 304 82 401
   137 103 58 228 179 243 234 23 85 7 157 -47 217 -51 57 -108 81 -183 76 l-59
   -3 -514 514 -515 515 6 45 c8 72 -12 129 -66 182 -66 66 -148 85 -234 55z
   m1193 -1408 c0 -15 -166 -86 -237 -102 -127 -28 -86 -58 -500 356 -314 314
   -373 377 -373 401 0 76 50 231 102 316 l22 35 493 -499 c271 -275 493 -503
   493 -507z m-586 -126 c-37 -8 -456 47 -471 62 -9 9 -51 295 -58 391 l-7 100
   276 -275 c189 -188 271 -276 260 -278z m841 53 c41 -40 33 -72 -34 -136 -180
   -171 -526 -253 -926 -220 -201 16 -358 44 -626 111 -317 80 -395 92 -569 92
   -161 1 -288 -17 -409 -58 -382 -127 -628 -407 -718 -816 -27 -126 -25 -446 5
   -583 11 -54 20 -101 18 -103 -2 -2 -40 4 -84 14 -66 14 -82 21 -86 38 -21 90
   -30 206 -30 366 0 152 4 203 22 287 90 415 317 713 675 883 208 99 403 136
   672 127 189 -7 216 -12 530 -90 436 -109 758 -141 1018 -102 173 26 296 77
   403 166 68 56 102 62 139 24z m-1950 -316 c106 -15 242 -49 255 -64 19 -23 60
   -231 69 -347 4 -62 6 -148 3 -192 l-4 -79 -344 344 -344 344 67 6 c79 6 199 2
   298 -12z m265 -913 c-30 -87 -130 -266 -149 -266 -3 0 -148 142 -321 315 -339
   338 -338 338 -394 294 -18 -14 -26 -30 -26 -52 0 -28 34 -66 317 -349 275
   -275 315 -319 303 -332 -21 -27 -155 -106 -231 -138 l-72 -30 -424 424 -425
   425 32 77 c32 76 117 217 140 231 8 5 39 -19 84 -64 58 -58 77 -71 103 -71 22
   0 38 8 52 26 36 45 28 69 -49 148 -38 40 -70 75 -70 78 0 18 180 118 265 148
   l50 18 416 -415 417 -416 -18 -51z m-787 -599 c-64 -15 -348 0 -445 22 -171
   39 -166 37 -178 82 -50 183 -73 416 -55 554 l7 58 354 -354 354 -354 -37 -8z
   m-1318 -262 c28 -3 112 -15 187 -27 104 -16 139 -25 143 -37 23 -73 72 -477
   60 -489 -6 -7 -565 548 -565 561 0 4 28 5 63 2 34 -3 85 -8 112 -10z m610
   -124 l50 -16 13 -80 c7 -44 15 -179 19 -300 4 -176 2 -241 -11 -322 -32 -205
   -110 -386 -210 -489 -60 -62 -92 -69 -131 -29 -38 37 -32 71 24 139 166 199
   216 523 151 968 -11 73 -20 139 -20 146 0 15 32 11 115 -17z"
        />
      </g>
    </svg>
  );
}

export default DnaIcon;
