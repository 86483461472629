import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Home, Apps, Help, Settings } from "@material-ui/icons";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useNavigate } from "react-router-dom";
import { signOut } from "src/redux/actions";
import { store } from "src/redux/store";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    color: `${theme.palette.text.primary} !important`,
  },
}));

const defaultSidebartListItems = [
  {
    href: "/",
    icon: Home,
    title: "Home",
  },
  {
    href: "/projects",
    icon: Apps,
    title: "Projects",
  },
  {
    href: "/account",
    icon: AccountCircle,
    title: "My Account",
  },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
  },
  {
    href: "/calculator",
    icon: Settings,
    title: "Calculator",
  },
  {
    href: "/help",
    icon: Help,
    title: "Help",
  },
];

export const NavBar = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const [auth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [redirectPath, setRedirectPath] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const goToAccount = () => {
    setRedirectPath("/account");
    handleClose();
  };

  const handleSignOut = () => {
    store.dispatch(signOut()); // TODO not use store
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (redirectPath) {
    navigate(redirectPath);
    // return <Redirect from={window.location.pathname} to={redirectPath} push />
  } else {
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
            {auth && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={goToAccount}>My Account</MenuItem>
                  <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.toolbar} />
          <Divider />
          <List>
            {defaultSidebartListItems.map((item, index) => (
              <ListItem
                button
                component={NavLink}
                key={item.title.toLowerCase()}
                to={item.href}
              >
                <ListItemIcon className={classes.listItem}>
                  <item.icon className={classes.listItem} size="20" />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
    );
  }
};

// {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//   <ListItem button key={text}>
//   <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
//   <ListItemText primary={text} />
//   </ListItem>
// ))}
