/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  // Checkbox,
  Chip,
  FormControlLabel,
  // FormControlLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { NavigateBefore } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Save } from "react-feather";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  chip: {
    "& span": {
      color: `${theme.palette.text.primary} !important`,
    },
  },
}));

function Nanaopore({
  directory,
  setPhase,
  // setFormData,
  files,
  fileMeta,
  setFileMeta,
}) {
  const isInputFormatValid = () => true;

  const isFileConfigUnique = () => true;

  const [formData, setFormData] = useState([]);

  const classes = useStyles();

  // const setFileMetaPromise = () =>
  //   new Promise(async (resolve, reject) => {
  //     let tmpFileMeta = [];
  //     for (let i = 0; i < directory.length; i++) {
  //       let item = directory[i];
  //       let folder = {};
  //       folder = {
  //         ...folder,
  //         file_name: getFileCellValues(item["name"]),
  //       };
  //       for (let j = 0; j < Object.values(formData[i]).length; j++) {
  //         let formItem = Object.values(formData[i])[j];
  //         let formName = Object.keys(formData[i])[j];
  //         folder = {
  //           ...folder,
  //           [formName]: formItem[formName],
  //         };
  //       }
  //       tmpFileMeta.push(folder);
  //       if (i === tableView.length - 1) {
  //         resolve(tmpFileMeta);
  //       }
  //     }
  //     // setFileMeta(tmpFileMeta);
  //   });

  const handleChange = (event, index) => {
    setFormData({
      ...formData,
      [index]: {
        ...formData[index],
        [event.target.name]: Number(event.target.value)
          ? Number(event.target.value)
          : event.target.value,
      },
    });
  };

  const handleCheckbox = (event, index) => {
    setFormData({
      ...formData,
      [index]: {
        ...formData[index],
        [event.target.name]: event.target.checked,
      },
    });
  };

  // const handleCheckbox = (event, index) => {
  //   setFormData({
  //     ...formData,
  //     [index]: {
  //       ...formData[index],
  //       [event.target.name]: event.target.checked,
  //     },
  //   });
  // };

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = [];
    await directory.map(async (value, index) => {
      let tmpformData = {
        filename: value["item"]["fullPath"],
        trim_min_len: formData[index][""],
      };
      await Object.entries(formData[index]).forEach((item) => {
        tmpformData = { ...tmpformData, [item[0]]: item[1] };
      });
      // tmpformData["calculate_frequency"] = true;
      data.push(tmpformData);
    });
    if (directory.length <= 0) {
      await files.map(async (value, index) => {
        let tmpformData = {
          filename: value.name,
        };
        await Object.entries(formData[index]).forEach((item) => {
          tmpformData = { ...tmpformData, [item[0]]: item[1] };
        });
        data.push(tmpformData);
      });
    }
    setFileMeta(data);
    if (isInputFormatValid()) {
      if (isFileConfigUnique()) {
        setPhase("credit_calculate");
      } else {
        enqueueSnackbar(
          t(
            "form_conf_not_unique_message",
            "Form configuration is not unique. Please check and try again."
          ),
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar(
        t(
          "form_input_not_valid_message",
          "Form input are not valid. Please check and try again."
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const newPromise = () =>
    new Promise((resolve, reject) => {
      let newTableView = [];
      let folderNames = [];
      directory.forEach((val, index) => {
        let item = val.item;
        if (val.isUploaded) {
          folderNames = {
            ...folderNames,
            [index]: {
              ...folderNames[index],
              barcode_code: item["name"],
              sample_name: item["name"],
              trim_min_len: 100,
              calculate_frequency: true,
            },
          };
          item.createReader().readEntries((entries) => {
            newTableView.push(entries);
            if (newTableView.length === directory.length) {
              resolve([newTableView, folderNames]);
            }
          });
        }
      });
    });

  const [tableView, setTableView] = useState([]);

useEffect(() => {
  if (directory.length > 0 && files.length > 0) {
    newPromise()
      .then((result) => {
        let totalSize = 0;
        
          files.forEach((value) => {
          totalSize += parseInt(value.byte_size);
        });
        setTableView(result[0]);
        const formDataWithSize = [{
          barcode_code: files[0].name.split(".")[0],
          sample_name: files[0].name.split(".")[0],
          trim_min_len: 100,
          calculate_frequency: true,
          byte_size: totalSize
        }];
        setFormData(formDataWithSize);
      })

  }
}, [files, directory]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Barcode")}</TableCell>
            <TableCell>{t("Full Path")}</TableCell>
            <TableCell>{t("Minimum Length")}</TableCell>
            <TableCell>{t("File Names")}</TableCell>
            <TableCell>{t("Variant Frequency Calculation")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(directory.length > 0 ? directory : files).map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    label={t("Barcode Code")}
                    // name={"barcode_code"}
                    type="text"
                    disabled={true}
                    value={
                      formData[index]?.barcode_code
                        ? formData[index]?.barcode_code
                        : item.name
                    }
                    defaultValue={
                      formData[index]?.barcode_code
                        ? formData[index]?.barcode_code
                        : item.name
                    }
                    // onChange={(e) => handleChange(e, index)}
                    style={{ width: "100%", margin: "0 5px 0 0" }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    label={t("Sample Name")}
                    name={"sample_name"}
                    type="text"
                    onChange={(e) => handleChange(e, index)}
                    value={
                      formData[index]?.sample_name
                        ? formData[index]?.sample_name
                        : item.name
                    }
                    defaultValue={
                      formData[index]?.sample_name
                        ? formData[index]?.sample_name
                        : item.name
                    }
                    style={{ width: "100%", margin: "0 5px 0 0" }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    label={t("Minumum Length")}
                    name={"trim_min_len"}
                    type="number"
                    onChange={(e) => handleChange(e, index)}
                    defaultValue={100}
                    value={formData[index]?.trim_min_len ?? item.trim_min_len}
                    style={{ width: "100%", margin: "0 5px 0 0" }}
                  />
                </TableCell>
                <TableCell>
                  <Box
                    maxWidth={500}
                    maxHeight={200}
                    style={{ overflowY: "scroll", margin: 0 }}
                  >
                    {tableView.length > 0 ? (
                      tableView[index].map((value, index) => (
                        <Chip
                          key={index}
                          className={classes.chip}
                          label={value.name}
                        />
                      ))
                    ) : (
                      <Chip label={item.name} />
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    onChange={(event) => {
                      handleCheckbox(event, index);
                    }}
                    checked={formData[index]?.calculate_frequency ?? true}
                    control={<Checkbox name="calculate_frequency" />}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box display="flex" width="100%" mt={5}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<NavigateBefore />}
          onClick={() => setPhase("init")}
        >
          {t("Back")}
        </Button>
        <Button
          style={{ marginLeft: "auto" }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          startIcon={<Save />}
        >
          {t("Submit")}
        </Button>
      </Box>
    </>
  );
}

export default Nanaopore;
