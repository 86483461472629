/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { apiCreateTeam, apiDeleteTeam } from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CreateDialog from "../createDialog";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  memberCountBadge: {
    "& .MuiBadge-badge": {
      right: "-10px",
    },
  },
}));

const tableHeaders = {
  name: "Team name",
  created_at: "Created at",
};

const TeamTableRow = (props) => {
  const classes = useStyles();
  const { organization_id, team, removeItem } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setLoading(false);
  };

  const handleDeleteObj = () => {
    setLoading(true);
    apiDeleteTeam(organization_id, team.id, (data, status) => {
      setLoading(false);
      setDialogOpen(false);
      removeItem(team.id);
      enqueueSnackbar(
        t("team_delete_success_message", "{{team_name}} team was deleted", {
          team_name: team.name,
        }),
        {
          variant: "success",
        }
      );
    });
  };

  return (
    <TableRow data-id={team.id} key={team.id}>
      {Object.keys(tableHeaders).map((headerKey, index) => {
        if (headerKey === "name") {
          return (
            <TableCell key={index}>
              <Badge
                max={9999}
                overlap="rectangular"
                className={classes.memberCountBadge}
                badgeContent={team.members_count}
                color="primary"
              >
                {team[headerKey]}
              </Badge>
            </TableCell>
          );
        }
        if (headerKey === "created_at") {
          return (
            <TableCell key={index}>
              {format(new Date(team[headerKey]), "dd/MM/yyyy")}
            </TableCell>
          );
        }
        return null;
      })}
      <TableCell>
        <IconButton
          data-testid="edit-team"
          onClick={() => navigate(`edit-team/${team.id}`)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          data-testid="delete-team-button"
          onClick={() => setDialogOpen(true)}
        >
          <DeleteIcon />
        </IconButton>
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("Delete team")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(
                "delete_team_message",
                "Are you sure you want to delete team '{{team_name}}'?",
                { team_name: team.name }
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t("No")}
            </Button>
            <Button
              onClick={handleDeleteObj}
              color="secondary"
              autoFocus
              disabled={loading}
            >
              {t("Yes")}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

export const TeamManagement = (props) => {
  const classes = useStyles();
  const { organization_id, teams, setOrganizationData } = props;
  const [createOpen, setCreateOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createFormData, setCreateFormData] = useState({
    name: "",
    description: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const removeItem = (id) => {
    setOrganizationData((prevState) => {
      const newData = Object.assign({}, prevState);
      for (let i = 0; i < newData.teams.length; i++) {
        if (newData.teams[i].id === id) {
          newData.teams.splice(i, 1);
          break;
        }
      }
      return newData;
    });
  };

  useEffect(() => {
    if (createFormData.name) {
      setLoading(true);
      apiCreateTeam(organization_id, createFormData, (data, status) => {
        setLoading(false);
        if (status === 201) {
          setCreateOpen(false);
          setOrganizationData((prevState) => {
            const newData = Object.assign({}, prevState);
            newData["teams"] = newData.teams.concat([data]);
            return newData;
          });
          enqueueSnackbar(
            t(
              "team_create_success_message",
              "'{{team_name}}' team was successfully created.",
              { team_name: createFormData.name }
            ),
            {
              variant: "success",
            }
          );
          setCreateFormData({
            name: "",
            description: null,
          });
        } else if (status === 400 && data["details"] === "duplicate name") {
          enqueueSnackbar(
            t(
              "team_create_duplicate_name_error",
              "A team with the name '{{team_name}}' already exists. Please choose a unique name and try again.",
              { team_name: createFormData.name }
            ),
            {
              variant: "error",
            }
          );
        }
      });
    }
  }, [createFormData]);

  if (!teams) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <div>
        <Grid container>
          <Grid item xs={12} style={{ display: "flex", marginBottom: "20px" }}>
            <Typography component="h4" variant="h4">
              {t("Team Management")}
            </Typography>
            <CreateDialog
              type="team"
              open={createOpen}
              setOpen={setCreateOpen}
              setCreateFormData={setCreateFormData}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12}>
            {teams.length ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {Object.keys(tableHeaders).map((headerKey, index) => (
                      <TableCell key={index}>
                        {t(tableHeaders[headerKey])}
                      </TableCell>
                    ))}
                    <TableCell>{t("Actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teams.map((team, index) => (
                    <TeamTableRow
                      removeItem={removeItem}
                      organization_id={organization_id}
                      team={team}
                      key={index}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography>
                {t(
                  "There are no teams in the organization. Click on the 'NEW TEAM' button to create a new team."
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
};
