/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  makeStyles,
  CircularProgress,
  colors,
  Typography,
  Chip,
  Grid,
  Table as MuiTable,
  TableRow,
  TableBody,
  TableCell as MuiTableCell,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Avatar,
  Tooltip,
  IconButton,
  Collapse,
  TableHead,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FilterButton } from "./components";
import { Rating } from "@material-ui/lab";
import {
  ExpandLess,
  ExpandMore,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@material-ui/icons";
import { isJSONString } from "src/components/utils";
import theme from "src/theme";
import { ClassificationSlider } from "./classificationSlider";
import VariantDetailHelpButton from "./variantDetailHelpButton";
import { getTranslated } from "../utils/helpers";
import { BadgedChip } from "../virtualizedTable/tooltipTableCell";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  subGroupTitle: {
    marginBottom: theme.spacing(1),
    color: `${theme.palette.secondary.main} !important`,
  },
  detailItemWrapper: {
    display: "inline-flex",
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  detailKey: {
    color: theme.palette.text.primary,
    wordWrap: "normal",
    wordBreak: "normal",
  },
  detailValue: {
    wordWrap: "break-word",
    minWidth: "100px",
  },
  detailValueTable: {
    marginLeft: "auto",
    textAlign: "center",
  },
  starRating: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  badgedChip: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  CustomChip: {
    "& .MuiChip-label": {
      color: "black !important",
    },
    "& .MuiAvatar-root": {
      color: "black !important",
      marginRight: "6px",
      marginleft: "-6px",
    },
    flexDirection: "row-reverse",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  detailContent: {
    width: "100%",
  },
  list: {
    listStyle: "none",
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  "red[500]": {
    background: colors.red[300],
    color: theme.palette.background.paper,
    "& .MuiAvatar-root": {
      background: colors.red[500],
    },
  },
  "red[300]": {
    background: colors.red[100],
    // color: theme.palette.background.paper,
    "& .MuiAvatar-root": {
      background: colors.red[300],
    },
  },
  "yellow[200]": {
    background: colors.orange[50],
    "& .MuiAvatar-root": {
      background: colors.orange[200],
    },
  },
  "amber[400]": {
    background: colors.orange[200],
    "& .MuiAvatar-root": {
      background: colors.orange[400],
    },
  },
  "orange[600]": {
    background: colors.orange[300],
    // color: theme.palette.background.paper,
    "& .MuiAvatar-root": {
      background: colors.orange[600],
    },
  },
  select_row: {
    color: `${theme.palette.text.primary} !important`,
  },
  "green[400]": {
    background: colors.green[200],
    // color: theme.palette.background.paper,
    "& .MuiAvatar-root": {
      background: colors.green[500],
      color: theme.palette.background.paper,
    },
  },
  "lightGreen[400]": {
    background: colors.lightGreen[200],
    "& .MuiAvatar-root": {
      background: colors.lightGreen[400],
      color: theme.palette.text.primary,
    },
  },
  bold: {
    fontWeight: "bold",
    color: theme.palette.warning.light,
  },
}));

export const CustomChip = (props) => {
  const classes = useStyles();
  const { label, count } = props;
  return (
    <Chip
      size="small"
      className={clsx(classes.CustomChip, {
        [classes["lightGreen[400]"]]: label === "LB",
        [classes["green[400]"]]: label === "B",
        [classes["yellow[200]"]]: label === "VUS",
        [classes["amber[400]"]]: label === "VUSi",
        [classes["orange[600]"]]: label === "VUSii",
        [classes["red[300]"]]: label === "LP",
        [classes["red[500]"]]: label === "P",
      })}
      style={{ color: `${theme === "dark" ? "black" : "black"} !important` }}
      avatar={<Avatar>{count}</Avatar>}
      label={
        <span
          style={{
            color: `${theme === "dark" ? "black" : "black"} !important`,
          }}
        >
          {label}
        </span>
      }
    />
  );
};

// const BadgedChip = (props) => {
//   const classes = useStyles();
//   const { label, count } = props;
//   return (
//     <Badge className={classes.badgedChip} badgeContent={count} color="primary">
//       <Chip
//         size="small"
//         className={clsx({
//           [classes["lightGreen[400]"]]: label === "LB",
//           [classes["green[400]"]]: label === "B",
//           [classes["yellow[200]"]]: label === "VUS",
//           [classes["amber[400]"]]: label === "VUSi",
//           [classes["orange[600]"]]: label === "VUSii",
//           [classes["red[300]"]]: label === "LP",
//           [classes["red[500]"]]: label === "P",
//         })}
//         label={label}
//       />
//     </Badge>
//   );
// };

const CustomDialog = (props) => {
  const classes = useStyles();
  const { data, hpo } = props;
  const [dialogData, setDialogData] = useState(data);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (dialogData.multi_value && hpo) {
      let filteredItems = "";
      const dialogValue = dialogData.value
        .split(dialogData.multi_value)
        .map((dataValue) => {
          let hasFilterItem = false;
          hpo.forEach((value) => {
            if (dataValue.includes(value)) {
              filteredItems += value + ",";
              hasFilterItem = true;
            }
          });
          if (!hasFilterItem) {
            return dataValue;
          } else {
            return "";
          }
        });
      dialogData.value = filteredItems + dialogValue.join(",");
      setDialogData({ ...dialogData });
    }
  }, []);

  return (
    <React.Fragment>
      <Tooltip
        title={dialogData.value
          .split(data.multi_value)
          .slice(0, 7)
          .map((item, index) => {
            return (
              <li
                className={clsx({
                  [classes.listItem]: true,
                  [classes.bold]: hpo
                    ? hpo.filter((value) => item.includes(value)).length
                    : false,
                })}
                key={index}
              >
                {item}
              </li>
            );
          })}
      >
        <Button size="small" variant="text" onClick={handleClickOpen}>
          <Box
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              maxHeight: 24,
            }}
          >
            {dialogData.value}
          </Box>
        </Button>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {getTranslated(data.label) ?? ""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.multi_value ? (
              <ul className={classes.list}>
                {dialogData.value.split(data.multi_value).map((item, index) => {
                  return (
                    <li
                      className={clsx({
                        [classes.listItem]: true,
                        [classes.bold]: hpo
                          ? hpo.filter((value) => item.includes(value)).length
                          : false,
                      })}
                      key={index}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            ) : (
              dialogData.value
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const Table = withStyles({
  root: {
    wordBreak: "break-word",
  },
})(MuiTable);

const AltTableRow = withStyles((theme) => ({
  root: {
    "&.main-table-row:nth-child(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TreeTableRow = (props) => {
  const {
    rowData,
    colIndexes,
    colDetails,
    setColDetails,
    filterState,
    isInnerTable,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const description = rowData.description ?? {};
  const label = rowData.label ?? {};
  return (
    <React.Fragment>
      <AltTableRow
        className={!isInnerTable ? "main-table-row" : ""}
        variant="body2"
      >
        <Box display="flex" width="100%">
          <TableCell className={classes.detailKey}>
            {"children" in rowData && (
              <IconButton
                aria-label="expand row"
                size="small"
                style={{ color: "#388e3c" }}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
            {rowData?.kind !== "free_form" && (
              <FilterButton
                colIndexes={colIndexes}
                colDetails={colDetails}
                setColDetails={setColDetails}
                filterState={filterState}
                column={rowData}
                filterType={
                  rowData?.kind === "numeric"
                    ? "range"
                    : Object.keys(rowData.filterValues).length > 15
                    ? "search"
                    : "select"
                }
              />
            )}
            <Tooltip title={getTranslated(description) ?? ""}>
              <span>{getTranslated(label) ?? ""}:</span>
            </Tooltip>
          </TableCell>
          <TableCell
            className={clsx(classes.detailValue, classes.detailValueTable)}
          >
            {rowData.value === "" ||
            rowData.value === undefined ||
            rowData.value === null ? (
              <div></div>
            ) : rowData.representation.options.component === "star-rating" ? (
              rowData.value >= 0 ? (
                <Rating
                  className={classes.starRating}
                  name="revstat"
                  readOnly
                  max={4}
                  value={parseFloat(rowData.value)}
                  precision={0.1}
                />
              ) : (
                ""
              )
            ) : rowData.representation.options.component === "json" ? (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                {isJSONString(rowData.value)
                  ? Object.keys(JSON.parse(rowData.value)).map(
                      (itemKey, index) => (
                        <BadgedChip
                          classes={classes}
                          itemKey={itemKey}
                          key={index}
                          data={JSON.parse(rowData.value)}
                          index={index}
                        />
                      )
                    )
                  : Object.keys(rowData.value).map((itemKey, index) => (
                      <BadgedChip
                        classes={classes}
                        itemKey={itemKey}
                        key={index}
                        data={rowData.value}
                        index={index}
                      />
                    ))}
              </Box>
            ) : rowData.representation.options.component === "badged-chip" ? (
              <div>
                <div>
                  {Object.keys(
                    isJSONString(rowData.value)
                      ? JSON.parse(rowData.value)
                      : rowData.value
                  ).map((itemKey, index) => (
                    <CustomChip
                      key={index}
                      label={itemKey}
                      count={
                        (rowData.value
                          ? JSON.parse(rowData.value)
                          : rowData.value)[itemKey]
                      }
                    />
                  ))}
                </div>
              </div>
            ) : rowData.representation.options.component === "dialog" ? (
              (rowData.value && rowData.value.length > 60) ||
              (rowData.multi_value &&
                rowData.value.indexOf(rowData.multi_value) !== -1) ? (
                <CustomDialog data={rowData} />
              ) : (
                rowData.value
              )
            ) : rowData.multi_value ? (
              <ul className={classes.list}>
                {rowData.value.split(rowData.multi_value).map((item, index) => (
                  <li className={classes.listItem} key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            ) : !isNaN(Number(rowData.value)) &&
              rowData.value.split(".")[1] === "0" ? (
              rowData.value.split(".")[0]
            ) : (
              rowData.value
            )}
          </TableCell>
        </Box>
        {"children" in rowData && (
          <Box>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            {Object.keys(rowData.children).map(
                              (node, index) => (
                                <TreeTableRow
                                  isInnerTable={true}
                                  rowData={rowData.children[node]}
                                  colIndexes={colIndexes}
                                  colDetails={colDetails}
                                  setColDetails={setColDetails}
                                  filterState={filterState}
                                  key={index}
                                />
                              )
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}
      </AltTableRow>
      {rowData.representation.options.component === "slider" &&
        !_.isEmpty(rowData.value) && (
          <AltTableRow className={"main-table-row"} variant="body2">
            <TableCell style={{ margin: "24px 6px", height: 150 }} colSpan={2}>
              <ClassificationSlider
                marks={rowData.representation.options.marks}
                value={rowData.value}
                range={rowData.representation.options.marks}
                reverse={rowData.representation.options.reverse}
              />
            </TableCell>
          </AltTableRow>
        )}
    </React.Fragment>
  );
};

const ContentRow = (props) => {
  const {
    classes,
    rows,
    row,
    colIndexes,
    colDetails,
    setColDetails,
    filterState,
    checked,
    setChecked,
    index,
  } = props;
  const label = rows[row].label ?? {};
  const description = rows[row].description ?? {};

  return (
    <>
      <AltTableRow className={"main-table-row"} variant="body2" key={index}>
        <TableCell className={classes.detailKey}>
          {rows[row].representation.options.component === "collapse" && (
            <IconButton
              aria-label="expand row"
              size="small"
              style={{ color: "#388e3c" }}
              onClick={() => setChecked((prev) => !prev)}
            >
              {checked ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          )}
          {rows[row]?.kind !== "free_form" && (
            <FilterButton
              colIndexes={colIndexes}
              colDetails={colDetails}
              setColDetails={setColDetails}
              filterState={filterState}
              column={rows[row]}
              filterType={
                rows[row]?.kind === "numeric"
                  ? "range"
                  : Object.keys(rows[row].filterValues).length > 15
                  ? "search"
                  : "select"
              }
            />
          )}
          <Tooltip title={getTranslated(description) ?? ""}>
            <span>
              {getTranslated(label) ?? ""}
              &nbsp;:
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.detailValue}>
          {rows[row].value === "" ||
          rows[row].value === undefined ||
          rows[row].value === null ? (
            <div></div>
          ) : rows[row].representation.options.component === "star-rating" ? (
            rows[row].value >= 0 ? (
              <Rating
                className={classes.starRating}
                name="revstat"
                readOnly
                max={4}
                value={parseFloat(rows[row].value)}
                precision={0.1}
              />
            ) : (
              ""
            )
          ) : rows[row].representation.options.component === "json" ? (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              {isJSONString(rows[row].value)
                ? Object.keys(JSON.parse(rows[row].value)).map(
                    (itemKey, index) => (
                      <BadgedChip
                        classes={classes}
                        itemKey={itemKey}
                        key={index}
                        data={JSON.parse(rows[row].value)}
                        index={index}
                      />
                    )
                  )
                : Object.keys(rows[row].value).map((itemKey, index) => (
                    <BadgedChip
                      classes={classes}
                      itemKey={itemKey}
                      key={index}
                      data={JSON.parse(rows[row].value)}
                      index={index}
                    />
                  ))}
            </Box>
          ) : rows[row].representation.options.component === "badged-chip" ? (
            <div>
              <div>
                {isJSONString(rows[row].value)
                  ? Object.keys(JSON.parse(rows[row].value)).map(
                      (itemKey, index) => (
                        <CustomChip
                          key={index}
                          label={itemKey}
                          count={JSON.parse(rows[row].value)[itemKey]}
                        />
                      )
                    )
                  : Object.keys(rows[row].value).map((itemKey, index) => (
                      <CustomChip
                        key={index}
                        label={itemKey}
                        count={rows[row].value[itemKey]}
                      />
                    ))}
              </div>
            </div>
          ) : rows[row].representation.options.component === "dialog" ? (
            (rows[row].value && rows[row].value.length > 60) ||
            (rows[row].multi_value &&
              rows[row].value.indexOf(rows[row].multi_value) !== -1) ? (
              <CustomDialog data={rows[row]} />
            ) : (
              rows[row].value
            )
          ) : rows[row].multi_value &&
            rows[row].representation.options.component !== "collapse" ? (
            <ul className={classes.list}>
              {rows[row].value
                .split(rows[row].multi_value)
                .map((item, index) => (
                  <li className={classes.listItem} key={index}>
                    {item}
                  </li>
                ))}
            </ul>
          ) : !isNaN(Number(rows[row].value)) &&
            (rows[row].value + "").split(".")[1] === "0" ? (
            (rows[row].value + "").split(".")[0]
          ) : rows[row].representation.options.component === "slider" &&
            !_.isEmpty(rows[row].value) ? (
            <ClassificationSlider
              marks={rows[row].representation.options.marks}
              value={rows[row].value}
              range={rows[row].representation.options.range}
              reverse={rows[row].representation.options.reverse}
            />
          ) : rows[row].representation.options.component !== "collapse" ? (
            rows[row].value
          ) : (
            ""
          )}
        </TableCell>
        {rows[row].representation.options.helper && (
          <VariantDetailHelpButton
            helpDesc={rows[row].representation.options.helper}
          />
        )}
      </AltTableRow>
      {rows[row].representation.options.component === "slider" &&
        !_.isEmpty(rows[row].value) && (
          <AltTableRow className={"main-table-row"} variant="body2" key={index}>
            <TableCell style={{ margin: "24px 6px", height: 50 }} colSpan={2}>
              <ClassificationSlider
                marks={rows[row].representation.options.marks}
                value={rows[row].value}
                range={rows[row].representation.options.range}
                reverse={rows[row].representation.options.reverse}
              />
            </TableCell>
          </AltTableRow>
        )}
    </>
  );
};

const ColumnTable = (props) => {
  const classes = useStyles();
  const { colIndexes, colDetails, setColDetails, filterState, rows } = props;
  const [treeData, setTreeData] = useState(undefined);

  function buildTreeTable(data) {
    const tree = {};

    for (let item in data) {
      if (!("parent" in data[item].representation.options.tree)) {
        tree[data[item].representation.options.tree.node] = data[item];
      }
    }

    for (let item in data) {
      if ("parent" in data[item].representation.options.tree) {
        if (
          !("children" in tree[data[item].representation.options.tree.parent])
        ) {
          tree[data[item].representation.options.tree.parent].children = {};
        }
        tree[data[item].representation.options.tree.parent].children[
          data[item].representation.options.tree.node
        ] = data[item];
      }
    }

    return tree;
  }

  useEffect(() => {
    if (rows) {
      if ("tree" in rows[Object.keys(rows)[0]].representation.options) {
        const treeTable = buildTreeTable(rows);
        setTreeData(treeTable);
      } else {
        setTreeData(null);
      }
    }
  }, [rows]);

  if (treeData !== undefined) {
    return treeData ? (
      <Table size="small">
        <TableBody>
          {Object.keys(treeData).map((node, index) => {
            return (treeData[node]?.representation?.options?.hasOwnProperty(
              "visible"
            ) &&
              treeData[node]?.representation?.options?.visible) ||
              !treeData[node]?.representation?.options?.hasOwnProperty(
                "visible"
              ) ? (
              <TreeTableRow
                rowData={treeData[node]}
                colIndexes={colIndexes}
                colDetails={colDetails}
                setColDetails={setColDetails}
                filterState={filterState}
                key={index}
              />
            ) : (
              ""
            );
          })}
        </TableBody>
      </Table>
    ) : (
      <Table size="small">
        <TableBody>
          {Object.keys(rows)
            .sort(
              (a, b) =>
                rows[a]?.representation?.options?.sub_group?.order -
                rows[b]?.representation?.options?.sub_group?.order
            )
            .map((row, index) => {
              return (rows[row]?.representation?.options?.hasOwnProperty(
                "visible"
              ) &&
                rows[row]?.representation?.options?.visible) ||
                !rows[row]?.representation?.options?.hasOwnProperty(
                  "visible"
                ) ? (
                <TreeDetailRow
                  rows={rows}
                  key={index}
                  row={row}
                  classes={classes}
                >
                  {(checked, setChecked) => (
                    <ContentRow
                      key={index}
                      row={row}
                      index={index}
                      colIndexes={colIndexes}
                      colDetails={colDetails}
                      setColDetails={setColDetails}
                      filterState={filterState}
                      rows={rows}
                      checked={checked}
                      setChecked={setChecked}
                      classes={classes}
                    />
                  )}
                </TreeDetailRow>
              ) : (
                ""
              );
            })}
        </TableBody>
      </Table>
    );
  } else {
    return <CircularProgress />;
  }
};

const TreeDetailRow = ({ children, rows, row, classes }) => {
  const [checked, setChecked] = useState(false);
  return (
    <>
      {children(checked, setChecked)}
      {rows[row].representation.options.component === "collapse" ? (
        <Collapse in={checked}>
          <Table>
            {(rows[row].value && rows[row].value.length > 60) ||
            (rows[row].multi_value &&
              rows[row].value.indexOf(rows[row].multi_value) !== -1) ? (
              rows[row].multi_value ? (
                <TableHead>
                  {rows[row].value
                    .split(rows[row].multi_value)
                    .map((item, index) => (
                      <AltTableRow key={index}>
                        <TableCell className={classes.listItem} key={index}>
                          {item}
                        </TableCell>
                      </AltTableRow>
                    ))}
                </TableHead>
              ) : (
                <AltTableRow>
                  <TableCell className={classes.listItem}>
                    {rows[row].value}
                  </TableCell>
                </AltTableRow>
              )
            ) : (
              <AltTableRow>
                <TableCell className={classes.listItem}>
                  {rows[row].value}
                </TableCell>
              </AltTableRow>
            )}
          </Table>
        </Collapse>
      ) : null}
    </>
  );
};

const SubGroupsLayout = (props) => {
  const classes = useStyles();
  const { colIndexes, colDetails, setColDetails, filterState, content } = props;
  if (!colIndexes || !colDetails) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Grid container spacing={2}>
        {Object.keys(content).map((subgroup, index) => {
          return (
            <Grid
              item
              container
              sm={12}
              md={content[subgroup].width ?? 12}
              key={index}
              alignContent="flex-start"
            >
              {!content[subgroup].hide_title && (
                <Grid item xs={12} style={{ borderBottom: "1px solid black" }}>
                  <Typography className={classes.subGroupTitle}>
                    {subgroup}
                  </Typography>
                </Grid>
              )}
              {content[subgroup].containsColumns ? (
                Object.keys(content[subgroup].columns)
                  .sort(
                    (a, b) =>
                      content[subgroup].columns[a]?.representation?.options
                        ?.sub_group?.order -
                      content[subgroup].columns[b]?.representation?.options
                        ?.sub_group?.order
                  )
                  .map((col, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={
                          Object.keys(content[subgroup].columns).length
                            ? Math.floor(
                                12 /
                                  Object.keys(content[subgroup].columns).length
                              )
                            : 12
                        }
                        key={index}
                      >
                        <ColumnTable
                          colIndexes={colIndexes}
                          colDetails={colDetails}
                          setColDetails={setColDetails}
                          filterState={filterState}
                          rows={content[subgroup].columns[col]}
                        />
                      </Grid>
                    );
                  })
              ) : (
                <Grid item xs={12}>
                  <ColumnTable
                    colIndexes={colIndexes}
                    colDetails={colDetails}
                    setColDetails={setColDetails}
                    filterState={filterState}
                    rows={content[subgroup].columns}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  }
};

const VariantDetailGrid = (props) => {
  const classes = useStyles();
  const {
    filterState,
    setColDetails,
    defaultColDetailsObject,
    colDetails,
    variantDetail,
  } = props;
  const [colIndexes, setColIndexes] = useState(undefined);
  const [subGroups, setSubGroups] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (colDetails) {
      const tmpColIndexes = {};
      for (let i = 0; i < colDetails?.length; i++) {
        tmpColIndexes[colDetails[i].name] = i;
      }
      setColIndexes(tmpColIndexes);
    }
  }, [colDetails]);

  useEffect(() => {
    let tmpSubGroups = null;
    // process representation options
    if (variantDetail && variantDetail !== "loading") {
      // const test = variantDetail.sort((a, b) => {
      //   const newValue = a[0];
      //   const oldValue = b[0];
      //   defaultColDetailsObject[
      //     newValue
      //   ].representation.options.sub_group.order = Math.floor(
      //     Math.random() * 10
      //   );
      //   defaultColDetailsObject[
      //     oldValue
      //   ].representation.options.sub_group.order = Math.floor(
      //     Math.random() * 10
      //   );
      //   return 0;
      // });

      for (let i = 0; i < variantDetail.length; i++) {
        const schemaDetails = defaultColDetailsObject[variantDetail[i][0]];
        if (
          schemaDetails.representation &&
          schemaDetails.representation.options
        ) {
          const options = schemaDetails.representation.options;
          if (options.sub_group) {
            if (!tmpSubGroups) {
              tmpSubGroups = {};
            }
            if (!tmpSubGroups[options.sub_group.title]) {
              tmpSubGroups[options.sub_group.title] = {};
              if (options.sub_group.hide_title) {
                tmpSubGroups[options.sub_group.title].hide_title = true;
              }
            }
            if (
              options.sub_group.width &&
              !tmpSubGroups[options.sub_group.title].width
            ) {
              tmpSubGroups[options.sub_group.title].width =
                options.sub_group.width;
            }
            if (!tmpSubGroups[options.sub_group.title].columns) {
              tmpSubGroups[options.sub_group.title].columns = {};
            }
            if (options.sub_group.column !== undefined) {
              tmpSubGroups[options.sub_group.title]["containsColumns"] = true;
              if (
                !tmpSubGroups[options.sub_group.title].columns[
                  options.sub_group.column
                ]
              ) {
                tmpSubGroups[options.sub_group.title].columns[
                  options.sub_group.column
                ] = {};
              }
              tmpSubGroups[options.sub_group.title].columns[
                options.sub_group.column
              ][variantDetail[i][0]] = Object.assign(
                {},
                defaultColDetailsObject[variantDetail[i][0]]
              );
              tmpSubGroups[options.sub_group.title].columns[
                options.sub_group.column
              ][variantDetail[i][0]].value = variantDetail[i][1];
            } else {
              tmpSubGroups[options.sub_group.title].columns[
                variantDetail[i][0]
              ] = Object.assign(
                {},
                defaultColDetailsObject[variantDetail[i][0]]
              );
              tmpSubGroups[options.sub_group.title].columns[
                variantDetail[i][0]
              ].value = variantDetail[i][1];
            }
          }
        }
      }
    }

    setSubGroups(tmpSubGroups);
  }, [variantDetail]);
  if (!variantDetail) {
    return (
      <Box className={classes.select_row}>
        {t("select_row", "Select a row to show details...")}
      </Box>
    );
  } else if (
    variantDetail === "loading" ||
    !colIndexes ||
    !colDetails ||
    subGroups === undefined
  ) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return subGroups ? (
      <SubGroupsLayout
        colIndexes={colIndexes}
        colDetails={colDetails}
        setColDetails={setColDetails}
        filterState={filterState}
        content={subGroups}
      />
    ) : (
      <Table size="small">
        <TableBody>
          {variantDetail.map((detailItem, index) => {
            let description =
              defaultColDetailsObject[detailItem[0]]?.description ?? {};
            let label = defaultColDetailsObject[detailItem[0]]?.label ?? {};
            return (
              <AltTableRow
                className={"main-table-row"}
                variant="body2"
                key={index}
              >
                <TableCell className={classes.detailKey}>
                  {colDetails[colIndexes[detailItem[0]]]?.kind !==
                    "free_form" && (
                    <FilterButton
                      colIndexes={colIndexes}
                      colDetails={colDetails}
                      setColDetails={setColDetails}
                      filterState={filterState}
                      column={colDetails[colIndexes[detailItem[0]]]}
                      filterType={
                        colDetails[colIndexes[detailItem[0]]]?.kind ===
                        "numeric"
                          ? "range"
                          : Object.keys(
                              colDetails[colIndexes[detailItem[0]]].filterValues
                            ).length > 15
                          ? "search"
                          : "select"
                      }
                    />
                  )}
                  <Tooltip title={t(getTranslated(description) ?? "")}>
                    <span>{t(getTranslated(label)) + ": "}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {defaultColDetailsObject[detailItem[0]].representation.options
                    .component === "star-rating" ? (
                    detailItem[1] >= 0 ? (
                      <Rating
                        className={classes.starRating}
                        name="revstat"
                        readOnly
                        max={4}
                        value={parseFloat(detailItem[1])}
                        precision={0.1}
                      />
                    ) : (
                      ""
                    )
                  ) : defaultColDetailsObject[detailItem[0]].representation
                      .options.component === "json" ? (
                    Object.keys(
                      isJSONString(detailItem[1])
                        ? JSON.parse(detailItem[1])
                        : detailItem[1]
                    ).map((itemKey, index) => (
                      <BadgedChip
                        classes={classes}
                        itemKey={itemKey}
                        key={index}
                        data={
                          isJSONString(detailItem[1])
                            ? JSON.parse(detailItem[1])
                            : detailItem[1]
                        }
                        index={index}
                      />
                    ))
                  ) : defaultColDetailsObject[detailItem[0]].representation
                      .options.component === "badged-chip" ? (
                    Object.keys(
                      detailItem[1] === "string"
                        ? JSON.parse(detailItem[1])
                        : detailItem[1]
                    ).map((itemKey, index) => (
                      <CustomChip
                        key={index}
                        label={itemKey}
                        count={
                          detailItem[1] === "string"
                            ? JSON.parse(detailItem[1])[itemKey]
                            : detailItem[1][itemKey]
                        }
                      />
                    ))
                  ) : defaultColDetailsObject[detailItem[0]].representation
                      .options.component === "dialog" &&
                    ((detailItem[1] && detailItem[1].length > 60) ||
                      (defaultColDetailsObject[detailItem[0]].multi_value &&
                        detailItem[1].indexOf(
                          defaultColDetailsObject[detailItem[0]].multi_value
                        ) !== -1)) ? (
                    <CustomDialog
                      data={{
                        value: detailItem[1],
                        label: getTranslated(label),
                        multi_value:
                          defaultColDetailsObject[detailItem[0]].multi_value,
                      }}
                    />
                  ) : (
                    <span className={classes.detailValue}>
                      {detailItem[1]
                        ? detailItem[1]
                        : (detailItem[1] + "").split(".")[1] === "0"
                        ? (detailItem[1] + "").split(".")[0]
                        : detailItem[1]}
                      {/* {!detailItem[1]
                        ? detailItem[1]
                        : (detailItem[1] + "").split(".")[1] === "0"
                        ? (detailItem[1] + "").split(".")[0]
                        : detailItem[1]} */}
                    </span>
                  )}
                </TableCell>
              </AltTableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
};

export { CustomDialog as TableDialog };

export default VariantDetailGrid;
