/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const MappedReadsNucleotideContentLineChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files, tagSuffix } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    // const re = RegExp(
    //   "mapped_reads_nucleotide_content(-mother|-father|-child)?"
    // );
    // const fileTag = Object.keys(files).find((tag) => re.test(tag));
    const fileTagSuffix = tagSuffix ? "-" + tagSuffix : "";
    try {
      fetch(
        getBaseURL() +
          files["mapped_reads_nucleotide_content" + fileTagSuffix]
            .download_address
      )
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n");
          rawData = rawData.slice(1);
          const labels = []; // position
          const dataA = [];
          const dataC = [];
          const dataG = [];
          const dataT = [];
          const dataN = [];
          for (let i = 0; i < rawData.length; i++) {
            const row = rawData[i].split("\t");
            if (isNaN(parseFloat(row[0]))) {
              continue;
            }
            labels.push(parseFloat(row[0]));
            dataA.push(parseFloat(row[1]));
            dataC.push(parseFloat(row[2]));
            dataG.push(parseFloat(row[3]));
            dataT.push(parseFloat(row[4]));
            dataN.push(parseFloat(row[5]));
          }

          if (mounted) {
            setSampleData({
              labels: labels,
              datasets: [
                {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  pointRadius: 0,
                  borderWidth: 2,
                  label: "A",
                  data: dataA,
                  borderColor: colors[materialColors[0]][400],
                },
                {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  pointRadius: 0,
                  borderWidth: 2,
                  label: "C",
                  data: dataC,
                  borderColor: colors[materialColors[3]][400],
                },
                {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  pointRadius: 0,
                  borderWidth: 2,
                  label: "G",
                  data: dataG,
                  borderColor: colors[materialColors[6]][400],
                },
                {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  pointRadius: 0,
                  borderWidth: 2,
                  label: "T",
                  data: dataT,
                  borderColor: colors[materialColors[9]][400],
                },
                {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  pointRadius: 0,
                  borderWidth: 2,
                  label: "N",
                  data: dataN,
                  borderColor: colors[materialColors[12]][400],
                },
              ],
            });
          }
        });
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSampleData({ labels: [], datasets: [] });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      text: t("Mapped reads nucleotide content"),
    },
    legend: { display: true },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
            stepSize: 5,
          },
          scaleLabel: {
            display: true,
            labelString: t("Nucleotide content (%)"),
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t("Position (bp)"),
          },
        },
      ],
    },
  };

  if (!sampleData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <Line height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default MappedReadsNucleotideContentLineChart;
