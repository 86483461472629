import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Page from "src/components/Page";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Avatar,
  Typography,
  colors,
  Box,
} from "@material-ui/core";
import {
  Business as BusinessIcon,
  BusinessCenter as BusinessCenterIcon,
  AccountBalanceWallet,
} from "@material-ui/icons";
import { EditAccount } from "./editAccount";
import { ManagementSection } from "./manage";
import { EditDepartment } from "./manage/departmentManagement/editDepartment";
import { EditUser } from "./manage/userManagement/editUser";
import { EditTeam } from "./manage/teamManagement/editTeam";
import { useTranslation } from "react-i18next";
import { getUserFullName, userHasPermission } from "src/components/utils";
import { apiGetOrganizationDetails } from "src/api";
import { apiAddToSystemStatusChoices } from "src/api/endpoints";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    marginBottom: "40px",
    color: colors.grey[800],
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "75px",
    height: "75px",
    marginTop: "-55px",
    "& img": {
      width: "75px",
      height: "75px",
    },
  },
  alertIcon: {
    height: "100%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
  },
  alertInside: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  return { userObject: state.userObject };
};

const ConnectedAccountPage = (props) => {
  const classes = useStyles();
  const [organizationData, setOrganizationData] = useState(undefined);

  const { userObject } = props;
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    apiGetOrganizationDetails(
      userObject.activeOrganization.id,
      (data, status) => {
        if (status === 200) {
          if (mounted) {
            if (!data.status_choices.length)
              apiAddToSystemStatusChoices(userObject.activeOrganization.id);
            setOrganizationData(data);
          }
        }
      }
    );

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Page className={classes.root} title={t("Account")}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h2">
              {t("Edit Account")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              className={classes.paper}
              style={{ color: colors.grey[700] }}
            >
              <Avatar
                className={classes.avatar}
                src={userObject?.avatar ?? ""}
              />
              <Typography component="h1" variant="h5">
                {getUserFullName(userObject)}
              </Typography>
              <Typography variant="subtitle1">{userObject?.email}</Typography>
              <Typography
                style={{ color: colors.grey[500], textAlign: "center" }}
              >
                {userObject?.position && (
                  <>
                    <BusinessCenterIcon /> {userObject?.position} <br />
                  </>
                )}
                {userObject?.department && userObject.company && (
                  <>
                    <BusinessIcon />
                    {`${userObject?.department} - ${userObject.company}`}
                  </>
                )}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <EditAccount userObj={userObject} />
          </Grid>
          {userHasPermission(userObject?.activePermissions, "organization") && (
            <>
              <Grid item xs={12}>
                {organizationData &&
                  _.isNumber(organizationData.credit_balance) && (
                    <Alert
                      icon={
                        <Box className={classes.alertIcon}>
                          <AccountBalanceWallet color="primary" />
                        </Box>
                      }
                      style={{ width: "100%" }}
                      variant="standard"
                      color="info"
                    >
                      <Box className={classes.alertInside}>
                        <Typography style={{ width: "100%" }}>
                          <b>{t("Balance")}</b> :{" "}
                          {organizationData.credit_balance}
                        </Typography>
                        <Typography style={{ width: "100%" }}>
                          <b>{t("Overdraft Limit")}</b> :&nbsp;
                          {organizationData.overdraft_limit}
                        </Typography>
                        <Link to={"transactions"}>
                          <Typography
                            color="primary"
                            style={{ textDecoration: "underline" }}
                          >
                            {t("See all transactions")}
                          </Typography>
                        </Link>
                      </Box>
                    </Alert>
                  )}
              </Grid>
              <ManagementSection
                organizationData={organizationData}
                setOrganizationData={setOrganizationData}
                userObj={userObject}
              />
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export const AccountPage = connect(mapStateToProps)(ConnectedAccountPage);

export { EditUser, EditTeam, EditDepartment };
