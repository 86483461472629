import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  TextField,
  MenuItem,
  Link,
  InputBase,
  Tooltip,
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import {
  HelpCircle as HelpCircleIcon,
  Home as HomeIcon,
  Grid as GridIcon,
  Settings as SettingsIcon,
  User as UserIcon,
} from "react-feather";
import NavItem from "./NavItems";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "src/components/utils";
import { loadUser } from "src/redux/actions";
import { apiLoadProfile } from "src/api";
import {
  Search as SearchIcon,
  ConfirmationNumberOutlined,
} from "@material-ui/icons";
import { urlAPIConf } from "src/config/api";
import { LanguageSelect } from "../languageSelect";
import { SignOutButton } from "../TopBar";
import { MdOutlineCalculate } from "react-icons/md";

const CustomDropdown = (props) => {
  const classes = useStyles();
  const { label, name, values, currentItem, changeCallback } = props;
  const [value, setValue] = useState(currentItem);

  const handleChange = (event) => {
    const newVal = event.target.value;
    setValue(newVal);
    changeCallback(newVal);
  };

  return (
    <TextField
      fullWidth
      size="small"
      id={`select-${name}`}
      name={name}
      select
      value={value}
      onChange={handleChange}
      label={label}
      variant={"outlined"}
      className={classes.input}
      margin="dense"
    >
      {values.map((organization, index) => (
        <MenuItem value={organization.id} key={index}>
          {organization.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

const mapStateToProps = (state) => {
  return { userObject: state.userObject };
};

function mapDispatchToProps(dispatch) {
  return {
    loadUser: (data) => dispatch(loadUser(data)),
  };
}

const ConnectedUserAvatar = (props) => {
  const classes = useStyles();
  const { userObject } = props;
  const { t } = useTranslation();

  const fullName = getUserFullName(userObject);

  // const UserPic = () => { return(userObject.avatar ?  <img src={userObject.avatar} alt="avatar"/> : <AlertCircleIcon />)}

  const handleOrganizationChange = (organization_id) => {
    apiLoadProfile(organization_id, (data, status) => {
      if (status === 200) {
        localStorage.setItem("activeOrganizationId", organization_id);
        window.location.reload();
      }
    });
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      className={classes.user}
      flexDirection="row"
      p={2}
    >
      <Tooltip title={fullName} aria-label={fullName} placement="right">
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={userObject.avatar}
          to="/account"
        >
          {fullName.charAt(0)}
        </Avatar>
      </Tooltip>
      <Hidden lgUp>
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {fullName}
        </Typography>
      </Hidden>
      {userObject.organizations.length > 1 && (
        <Box display="flex" width="100%">
          <CustomDropdown
            values={userObject.organizations}
            currentItem={userObject.activeOrganization.id}
            name={"organization"}
            label={t("Active organization")}
            changeCallback={handleOrganizationChange}
          />
        </Box>
      )}
    </Box>
  );
};

export const UserAvatar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedUserAvatar);

const useStyles = makeStyles((theme) => ({
  navbarFooter: {
    position: "absolute",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    bottom: 0,
    width: "100%",
    "& a": {
      // fontSize: "0.8em",
      textAlign: "center",
    },
    "& a:hover": {
      textDecoration: "none",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  input: {
    minWidth: 200,
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    borderRadius: theme.spacing(1),
    "& svg": {
      color: "#777",
    },
    "& #select-organization-label , #select-organization": {
      color: "white !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f5deb300",
    },
  },
  searchIcon: {
    padding: 0,
    paddingRight: theme.spacing(2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(4),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  mobileDrawer: {
    width: 64,
    [theme.breakpoints.down("md")]: {
      width: 264,
    },
  },
  desktopDrawer: {
    zIndex: 1,
    width: 64,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: theme.palette.primary.main,
    color: "white !important",
    border: "1px solid white",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  user: {
    gap: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const items = [
    {
      href: "/dashboard",
      icon: HomeIcon,
      title: t("Home"),
    },
    {
      href: '/projects?page=0&pageSize=10&value=&filterValue={}&sort={"created_at":"DESC"}',
      icon: GridIcon,
      title: t("Projects"),
    },
    {
      href: "/account",
      icon: UserIcon,
      title: t("Account"),
    },
    {
      href: "/settings",
      icon: SettingsIcon,
      title: t("Settings"),
    },
    {
      href: "/calculator",
      icon: MdOutlineCalculate,
      title: t("Calculator"),
    },
    {
      href: "/help",
      icon: HelpCircleIcon,
      title: t("Help"),
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const searchSubmitHandler = (event) => {
    event.preventDefault();
    const query = event.target[0].value.replace(" ", "+");
    event.target[0].value = "";
    navigate(
      `/${urlAPIConf.PROJECTS_SLUG}/?filterValue={}&value=${query}&page=0&pageSize=10&value=&&sort={"created_at":"DESC"}`
    );
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Divider />
      <Box p={2}>
        <List>
          <Hidden lgUp>
            <form className={classes.search} onSubmit={searchSubmitHandler}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={t("Search…")}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </form>
          </Hidden>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box className={classes.navbarFooter}>
        <Hidden lgUp>
          <Box display="flex" width="100%" justifyContent="center" mb={1}>
            <LanguageSelect />
            <SignOutButton />
          </Box>
        </Hidden>
        <Box
          m="auto"
          display="flex"
          xs={12}
          style={{ fontSize: "0.7rem", marginBottom: 20 }}
          flexDirection="column"
        >
          <Link
            href="https://support.massivebioinformatics.com/portal/en/newticket"
            target="_blank"
          >
            <Tooltip title={t("Massive Analyser Ticket")} placement="right">
              <ConfirmationNumberOutlined color="error" />
            </Tooltip>
          </Link>
        </Box>
        <Box
          m="auto"
          display="flex"
          xs={12}
          style={{ fontSize: "0.7rem" }}
          flexDirection="column"
        >
          <Link href="https://massivebioinformatics.com/wp-content/uploads/2021/08/Massive-ArGe-Aydinlatma-Metni.pdf">
            {t("Privacy Policy")}
          </Link>
          <Typography
            style={{ fontSize: "0.6rem", margin: "auto" }}
          >{`v${process.env.REACT_APP_VERSION}`}</Typography>
        </Box>
      </Box>
      <Box />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
