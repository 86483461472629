import { Collapse, List, ListItem } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";

function LiftoverItem({ classes, label, items }) {
  const [open, setOpen] = useState(true);
  const handleClick = (handle) => {
    setOpen(!open);
  };
  return (
    <List component="div" disablePadding>
      <ListItem button className={classes.item} onClick={handleClick}>
        {label}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((val) => {
            return (
              <ListItem button className={classes.altItem}>
                {val}
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
}

export default LiftoverItem;
