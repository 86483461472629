import { createTheme, colors, alpha } from "@material-ui/core";
// import { trTR } from "@material-ui/core/locale";
import shadows from "./shadows";
import typography from "./typography";

const main = alpha("#003866", 0.85);

const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 12,
    },
  },
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    svg: {
      primary: colors.common.white,
    },
    primary: {
      main: main,
    },
    secondary: {
      main: colors.pink[400],
    },
    main: {
      primary: colors.blueGrey[800],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    navbar: {
      primary: colors.pink[400],
      background: colors.grey[200],
    },
    tableLayout: {
      text: colors.grey[700],
    },
    title: {
      primary: colors.grey[800],
      secondary: colors.grey[600],
    },
    outline: {
      primary: alpha(main, 0.5),
    },
    header: {
      primary: colors.common.white,
    },
    tab: {
      primary: colors.common.black,
    },

    filter: {
      primary: "#FFFFFF",
      button: "#19BC21",
    },
    exons: {
      primary: main,
      secondary: colors.pink[400],
    },
    table: {
      cell: {
        text: {
          primary: colors.common.black,
        },
        background: {
          primary: "#FFFFFF",
        },
      },
    },
  },

  shadows,
  typography,
});

export default theme;
