import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  colors,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgressWithLabel } from "src/components/pages/projectDetails/detailsTable";
import safeLogger from "src/services/safeLogger";
import clsx from "clsx";
import { userHasPermission } from "src/components/utils";
import { format } from "date-fns";
import { connect } from "react-redux";
import { apiCancelProject } from "src/api";
import { useSnackbar } from "notistack";
import { Block, TableChart } from "@material-ui/icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  projectTable: {
    // "&::before": {
    //   content: "",
    //   position: "absolute",
    //   top: "-48px",
    //   height: "48px",
    //   backgroundColor: "#f57c00",
    // },
    "& .MuiTableCell-root": {
      padding: "6px 24px 6px 16px",
    },
    "& .MuiTableRow-root:hover": {
      backgroundColor: "#f1f1f1",
    },
    "& *": {
      boxSizing: "inherit",
    },
  },
  chip: {
    "& .MuiChip-label": {
      color: "white !important",
    },
  },
  clickableRow: {
    cursor: "pointer",
  },
  projectDetailTable: {
    marginBottom: "10px",
  },
  projectCompletedChip: {
    backgroundColor: theme.palette.success.main,
  },
  projectActiveChip: {
    backgroundColor: theme.palette.warning.main,
  },
  errorStatus: {
    backgroundColor: theme.palette.error.main,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  sampleRow: {
    cursor: "initial",
    backgroundColor: `${theme.palette.background.mainBackground} !important`,
    background: colors.grey[50],
    "&:hover": {
      background: colors.grey[100],
    },
  },
  rowActionBtn: {
    padding: 0,
  },
}));

const mapProgressStateToProps = (state) => {
  return {
    activeSamples: state.activeSamples,
    activeProjects: state.activeProjects,
    userObj: state.userObject,
  };
};

const ConnectedSampleRow = (props) => {
  const { sampleData, rowData, userObj, activeSamples } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const status =
    sampleData.id in activeSamples && activeSamples[sampleData.id].status
      ? activeSamples[sampleData.id].status
      : sampleData.status;

  const report_id =
    sampleData.id in activeSamples &&
    activeSamples[sampleData.id].reports &&
    activeSamples[sampleData.id].reports.length
      ? activeSamples[sampleData.id].reports[0].id
      : findReport(sampleData.name)
      ? findReport(sampleData.name).id
      : "";

  function findReport(sampleName) {
    for (let i = 0; i < rowData.reports.length; i++) {
      if (rowData.project_type === "trio") {
        if (rowData.reports[i].name === "trio_variant_table") {
          return rowData.reports[i];
        }
        continue;
      }
      if (rowData.project_type === "acnv") {
        if (rowData.reports[i].name === sampleName + "_cnv_table_v1") {
          return rowData.reports[i];
        }
        continue;
      }
      if (rowData.reports[i].name === sampleName) {
        return rowData.reports[i];
      }
    }
  }

  return (
    <TableRow
      onClick={(event) => event.stopPropagation()}
      className={classes.sampleRow}
    >
      <TableCell component="th" scope="row">
        {sampleData.name}
      </TableCell>
      <TableCell>
        {sampleData.started_at &&
          format(new Date(sampleData.started_at), "dd/MM/yyyy")}
      </TableCell>
      <TableCell>
        {sampleData.completed_at &&
          format(new Date(sampleData.completed_at), "dd/MM/yyyy")}
      </TableCell>
      <TableCell
        style={{
          padding:
            status === "analysing" ? "1px 24px 1px 16px" : "6px 24px 6px 16px",
        }}
      >
        {status === "analysing" &&
        ("progress" in sampleData || sampleData.id in activeSamples) ? (
          <CircularProgressWithLabel
            size={"30px"}
            value={
              activeSamples[sampleData.id] &&
              activeSamples[sampleData.id].progress
                ? activeSamples[sampleData.id].progress
                : sampleData.progress
            }
          />
        ) : (
          <Chip
            color="primary"
            size="small"
            className={clsx({
              [classes.chip]: true,
              [classes.projectActiveChip]: status === "analysing",
              [classes.projectCompletedChip]: status === "completed",
              [classes.errorStatus]:
                status === "failed" || status === "cancelled",
            })}
            label={t(status)}
          />
        )}
      </TableCell>
      <TableCell>
        {status === "pending" &&
          userHasPermission(userObj.activePermissions, "cancel_sample") && (
            <CancelAnalysisButton
              projectData={rowData}
              sampleData={sampleData}
              type="sample"
            />
          )}

        {status === "completed" && (
          <Tooltip title={t("Show variant analysis")}>
            <Link to={`/reports/${report_id}`}>
              <IconButton className={classes.rowActionBtn}>
                <TableChart color="primary" />
              </IconButton>
            </Link>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export const SampleRow = connect(mapProgressStateToProps)(ConnectedSampleRow);

const CancelAnalysisButton = (props) => {
  const classes = useStyles();
  const { type, projectData, sampleData } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setDialogOpen(false);
  };

  const handleCanel = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setLoading(true);
    apiCancelProject(
      projectData.id,
      sampleData ? sampleData.id : null,
      (data, status) => {
        if (status === 200) {
          setLoading(false);
          // TODO remove project from list if user has no priv to
          const type = sampleData ? "sample" : "project";
          if (type === "sample") {
            sampleData.status = "cancelled";
          } else if (type === "project") {
            projectData.status = "cancelled";
          }
          enqueueSnackbar(
            t(
              "analysis_cancelled_message",
              "{{type}} analysis was cancelled!",
              {
                type: type,
              }
            ),
            {
              variant: "warning",
            }
          );
        } else {
          safeLogger(data);
        }
      }
    );
    handleClose();
  };

  return (
    <>
      <Tooltip
        title={t("cancel_analysis_title", "Cancel {{type}} analysis", {
          type: type,
        })}
      >
        <IconButton
          className={classes.rowActionBtn}
          aria-label="delete"
          color="secondary"
          onClick={handleClickOpen}
        >
          <Block fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("cancel_analysis_title", "Cancel {{type}} analysis", {
            type: type,
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "cancel_analysis_text",
              "Are you sure you want to cancel {{type}} analysis? This action cannot be undone!",
              { type: type }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} color="primary">
            {t("No")}
          </Button>
          <Button
            disabled={loading}
            onClick={handleCanel}
            color="secondary"
            autoFocus
          >
            {t("Yes")}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
