import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  colors,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  ArrowRightAlt as ArrowRightAltIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { format } from "date-fns";
import { getTranslated } from "../utils/helpers";
import momentTimeZone from "moment-timezone";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  annotationHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  annotationSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  annotationHistory: {
    backgroundColor: colors.grey[100],
  },
  avatar: {},
  userMeta: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  arrow: {
    fill: colors.grey[500],
    margin: `0px ${theme.spacing(2)}px -${theme.spacing(1)}px`,
  },
  annotation: {
    backgroundColor: colors.orange[50],
    border: `1px solid ${colors.orange[100]}`,
    borderRadius: "8px",
    "& .metaName": {
      color: colors.grey[700],
      marginBottom: theme.spacing(1),
    },
    "& .anno-original": {
      color: colors.grey[600],
    },
    "& .anno-update": {
      color: colors.grey[700],
      fontWeight: "bold",
    },
  },
}));

const langToTimeZone = {
  tr_TR: "Europe/Istanbul",
  en_GB: "America/Chicago",
};

const AnnotationTimeline = (props) => {
  const classes = useStyles();
  const { columnName, defaultColDetailsObject, title, history, annotation } =
    props;
  const [expanded, setExpanded] = useState(false);
  const tz = momentTimeZone.tz.guess();
  return (
    <Box mb={4}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.annotationHeading}>{title}</Typography>
          <Box className={classes.annotationHeading}>
            {annotation.original && (
              <React.Fragment>
                <Typography display="inline">
                  {defaultColDetailsObject[columnName].representation.options &&
                  defaultColDetailsObject[columnName].representation.options
                    .component_choices.constructor === Object
                    ? defaultColDetailsObject[columnName].representation.options
                        .component_choices[annotation.original]
                    : annotation.original}
                </Typography>
                <ArrowRightAltIcon className={classes.arrow} />
              </React.Fragment>
            )}
            <Typography display="inline">
              {defaultColDetailsObject[columnName].representation.options &&
              defaultColDetailsObject[columnName].representation.options
                .component_choices.constructor === Object
                ? defaultColDetailsObject[columnName].representation.options
                    .component_choices[annotation.annotation]
                : annotation.annotation}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.annotationHistory}>
          <Timeline align="alternate">
            {history.map((item, idx) => (
              <TimelineItem key={`annotation-history-item${idx}`}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {format(new Date(item.created_at), "dd/MM/yyyy HH:mm")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    color={idx === history.length - 1 ? "primary" : "grey"}
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Box p={2} className={classes.annotation}>
                    <Typography variant="body1" className="metaName">
                      {item.username}
                    </Typography>
                    {item.from_value && (
                      <React.Fragment>
                        <Typography display="inline" className="anno-original">
                          {defaultColDetailsObject[columnName].representation
                            .options &&
                          defaultColDetailsObject[columnName].representation
                            .options.component_choices.constructor === Object
                            ? defaultColDetailsObject[columnName].representation
                                .options.component_choices[item.from_value]
                            : item.from_value}
                        </Typography>
                        <ArrowRightAltIcon className={classes.arrow} />
                      </React.Fragment>
                    )}
                    <Typography display="inline" className="anno-update">
                      {defaultColDetailsObject[columnName].representation
                        .options &&
                      defaultColDetailsObject[columnName].representation.options
                        .component_choices.constructor === Object
                        ? defaultColDetailsObject[columnName].representation
                            .options.component_choices[item.to_value]
                        : item.to_value}
                    </Typography>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const Annotations = (props) => {
  const classes = useStyles();
  const { defaultColDetailsObject, annotations, annotationHistory, appConf } =
    props;
  const [lang, setLang] = useState(appConf.lang);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLang(appConf.lang);
  }, [appConf]);

  return (
    <Box>
      <Typography component="h4" variant="h4" style={{ marginBottom: "10px" }}>
        {t("Annotations")}
      </Typography>
      <Box className={classes.commentSection}>
        {annotationHistory && Object.keys(annotationHistory).length
          ? Object.keys(annotationHistory).map((item, idx) => (
              <AnnotationTimeline
                key={`annotation-timeline-${idx}`}
                columnName={item}
                title={getTranslated(defaultColDetailsObject[item].label) ?? ""}
                history={annotationHistory[item]}
                annotation={annotations[item]}
                defaultColDetailsObject={defaultColDetailsObject}
                lang={lang}
              />
            ))
          : t("There are no annotations for this record.")}
      </Box>
    </Box>
  );
};

export default Annotations;
