import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { ListboxComponent } from "../../virtualizedTable/listBoxComponent";
import { AddCircleOutline, Close, Lock, LockOpen } from "@material-ui/icons";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import React, { memo, useEffect, useRef, useState } from "react";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import {
  defaultOperators,
  formatQuery,
  QueryBuilder,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { noop } from "@tanstack/react-table";
import { AsyncFilterField } from "src/components/pages/settings/tableConfigPage/tableLayoutEditor";
import { apiLoadSchemaColumnUniqueValues } from "src/api";
import { useSnackbar } from "notistack";
import { getTranslated } from "../../utils/helpers";
import { SharedVariants } from "../../virtualizedTable/tooltipTableCell";
import { isJSONString } from "src/components/utils";

const useStyles = makeStyles((theme) => ({
  rootFilters: {
    display: "flex",
    flexDirection: "column",
  },
  filterTypes: {
    margin: theme.spacing(1),
  },
  queryBox: {
    display: "flex",
    border: `1px solid ${theme.palette.background.paper}`,
    flexDirection: "column",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  queryValueBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "5px",
    margin: theme.spacing(1),
    flexWrap: "wrap",
  },
  query: {
    margin: theme.spacing(1),
    borderRadius: 5,
    border: `2px dashed ${theme.palette.text.primary}`,
    maxHeight: "30vh",
    overflowX: "auto",
    backgroundColor: theme.palette.filter.primary,
  },
  endBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: theme.spacing(1),
    gap: theme.spacing(1),
  },
  queryBuilder: {
    width: "100%",
    "& .ruleGroup-lock , .rule-lock": {
      marginLeft: "auto",
    },
    "& .MuiInputBase-input": {
      width: "100%",
    },
  },
  chip: {
    color: "inherit !important",
    mixBlendMode: "difference",
  },
}));

const operators = [
  ...defaultOperators.filter((op) => !["null", "notNull"].includes(op.name)),
  { name: "isNull", label: "is Null" },
  { name: "isNotNull", label: "is not Null" },
];

const Filter = memo(function Filter({
  fields,
  setOpen,
  schemaId,
  advanceFilter,
  setAdvanceFilter,
}) {
  const { t, i18n } = useTranslation();
  const columnFields = fields
    .filter(
      (field) =>
        field?.kind !== "free_form" &&
        (!field?.representation?.options?.hasOwnProperty("visible") ||
          (field?.representation?.options?.hasOwnProperty("visible") &&
            field?.representation?.options?.visible))
    )
    .map((field) => {
      return {
        ...field,
        name: field.name,
        label: field.label,
        kind: field.kind,
        inputType: field.kind === "boolean" ? "boolean" : "text",
        type: field.kind === "boolean" ? "boolean" : "text",
        representation: field.representation,
        filterValues: field.filterValues,
      };
    });

  const getOperators = (fieldName) => {
    const field = fields.find((fld) => fld.name === fieldName);
    if (!field) {
      return;
    }
    switch (field.kind) {
      case "enumeration":
        return [
          { name: "=", label: "=" },
          { name: "!=", label: "!=" },
          ...operators.filter(
            (op) =>
              ["in", "notIn", "isNull", "isNotNull"].includes(op.name) &&
              !["shared_variants"].includes(
                field.representation.options.component
              )
          ),
        ];

      case "booleean":
        return [
          { name: "=", label: "=" },
          { name: "!=", label: "!=" },
          ...operators.filter((op) =>
            ["in", "notIn", "isNull", "isNotNull"].includes(op.name)
          ),
        ];
      case "counted":
        return [
          { name: "=", label: "=" },
          { name: "!=", label: "!=" },
          { name: "<", label: "<" },
          { name: "<=", label: "<=" },
          { name: ">", label: ">" },
          { name: ">=", label: ">=" },
          ...operators.filter((op) =>
            ["in", "notIn", "isNull", "isNotNull"].includes(op.name)
          ),
        ];
      case "numeric":
        return [
          ...operators.filter((op) =>
            ["=", "!=", "in", "notIn"].includes(op.name)
          ),
          { name: "<", label: "<" },
          { name: "<=", label: "<=" },
          { name: ">", label: ">" },
          { name: ">=", label: ">=" },
        ];
      default:
        return operators;
    }
  };

  const onQueryChange = (q) => {
    setAdvanceFilter(q);
  };

  const classes = useStyles();
  return (
    <Box className={classes.queryBuilder}>
      <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
        <QueryBuilder
          query={advanceFilter}
          showCombinatorsBetweenRules={true}
          controlElements={{
            valueEditor: ValueEditor(schemaId),
            lockRuleAction: LockRuleAndGroupAction,
            lockGroupAction: LockRuleAndGroupAction,
            removeGroupAction: RemoveRuleAndGroupAction,
            removeRuleAction: RemoveRuleAndGroupAction,
            combinatorSelector: CombinatorSelector,
            inlineCombinator: CombinatorSelector,
            addGroupAction: AddRuleAndGroupAction,
            fieldSelector: FieldSelector,
            notToggle: NotToggle,
            operatorSelector: OperatorSelector,
            addRuleAction: AddRuleAndGroupAction,
          }}
          getOperators={getOperators}
          operators={operators}
          onQueryChange={onQueryChange}
          showLockButtons={true}
          showNotToggle={true}
          fields={columnFields}
        />
      </QueryBuilderDnD>
      {/* <pre>{formatQuery(query, "json")}</pre> */}
    </Box>
  );
});

const OperatorSelector = memo((props) => {
  const { handleOnChange, title, options, ...others } = props;
  const { t } = useTranslation();

  return (
    <FormControl style={{ minWidth: 100 }} variant="outlined">
      <InputLabel>{t(title)}</InputLabel>
      <Select {...others} onChange={(e) => handleOnChange(e.target.value)}>
        {options.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            {t(option.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

const NotToggle = memo((props) => {
  const { handleOnChange, label, ...others } = props;
  const { t } = useTranslation();
  return (
    <FormControlLabel
      label={t(label)}
      control={
        <Checkbox
          onChange={(e, checked) => {
            handleOnChange(checked);
          }}
          {...others}
        />
      }
    />
  );
});

const FieldSelector = memo((props) => {
  const { handleOnChange, options, title, value, fieldData } = props;
  const { t } = useTranslation();
  return (
    <Autocomplete
      {...props}
      style={{ minWidth: 400 }}
      options={options.map((option) => ({
        name: option.name,
        label: option.label,
      }))}
      onChange={(e, newValue) => {
        handleOnChange(newValue?.name || '');
      }}
      value={options.find((option) => option.name === value)}
      getOptionLabel={(option) => {
        return option ? getTranslated(option.label) : '';
      }}
      filterOptions={(options, state) => {
        const inputValue = state.inputValue?.toLowerCase() || '';
        return options.filter(option => {
          const label = getTranslated(option.label) || '';
          return label.toLowerCase().includes(inputValue);
        });
      }}
      renderInput={(params) => {
        return <TextField {...params} label={t(title)} variant="outlined" />;
      }}
    />
  );
});

const AddRuleAndGroupAction = memo((data) => {
  const { handleOnClick, label, ...props } = data;
  const { t } = useTranslation();

  return (
    <Button
      onClick={handleOnClick}
      children={t(label.replace("+", ""))}
      startIcon={<AddCircleOutline />}
      variant="contained"
      color={label === "+Rule" ? "primary" : "secondary"}
      {...props}
    />
  );
});

const CombinatorSelector = memo(({ handleOnChange, ...props }) => {
  const { t } = useTranslation();
  return (
    <ToggleButtonGroup
      value={props.value}
      exclusive
      onChange={(e, value) => handleOnChange(value)}
    >
      <ToggleButton value="and">{t("AND")}</ToggleButton>
      <ToggleButton value="or">{t("OR")}</ToggleButton>
    </ToggleButtonGroup>
  );
});

const RemoveRuleAndGroupAction = memo(({ handleOnClick, ...props }) => {
  return (
    <IconButton aria-label="delete" onClick={handleOnClick} size="small">
      <Close fontSize="inherit" />
    </IconButton>
  );
});

const LockRuleAndGroupAction = memo(({ disabled, handleOnClick, ...props }) => {
  return (
    <ToggleButtonGroup
      value={[disabled]}
      {...props}
      onChange={(val, newValue) => {
        if (newValue.length) {
          handleOnClick(val);
        }
      }}
    >
      <ToggleButton value={true}>
        <Lock />
      </ToggleButton>
      <ToggleButton value={false}>
        <LockOpen />
      </ToggleButton>
    </ToggleButtonGroup>
  );
});

const ValueEditor = (schemaId) =>
  memo(
    ({
      fieldData,
      title,
      handleOnChange,
      children,
      value,
      operator,
      ...props
    }) => {
      const classes = useStyles();
      const { t } = useTranslation();
      const [open, setOpenField] = useState();
      const [filterValue, setFilterValue] = useState(fieldData.filterValues);
      const [loading, setLoading] = useState();
      const { enqueueSnackbar } = useSnackbar();
      const [first, setFirst] = useState(true);
      const ref = useRef();
      const [numericValue, setNumericValue] = useState(
        fieldData?.kind === "counted" && !["in", "notIn"].includes(operator)
          ? value[1] ?? "0"
          : value === ""
          ? "0"
          : value === undefined
          ? ""
          : value.toString()
      );

      useEffect(() => {
        if (fieldData?.kind === "numeric" && value === "" && fieldData?.minValue !== undefined) {
          handleOnChange(fieldData.minValue.toString());
        }
      }, []);

      useEffect(() => {
        if (value === "" && first) {
          const result = (filterValue?.constructor === Object
            ? Object.keys(filterValue)?.length <= 0
              ? []
              : Object.keys(filterValue)
            : filterValue) || [];
            
          handleOnChange(result[0] || '');
          setFirst(false);
        }
      }, []);

      useEffect(() => {
        if (
          fieldData?.kind === "counted" &&
          !["in", "notIn"].includes(operator) &&
          value === "" &&
          first
        ) {
          handleOnChange([
            (filterValue.constructor === Object
              ? Object.keys(filterValue).length <= 0
                ? []
                : Object.keys(filterValue)
              : filterValue)[0],
            0,
          ]);
          setFirst(false);
        }
      }, []);

      useEffect(() => {
        let mounted = true;
        if (open && !Object.keys(filterValue).length && schemaId) {
          setLoading(true);
          apiLoadSchemaColumnUniqueValues(
            schemaId,
            fieldData.name,
            (data, status) => {
              if (status === 200) {
                if (mounted) {
                  if (!data.length) {
                    enqueueSnackbar(t("No data were found for this column."), {
                      variant: "error",
                    });
                    setFilterValue({});
                  } else {
                    setFilterValue(data);
                  }
                  setLoading(false);
                }
              }
            }
          );
        }

        return () => {
          mounted = false;
        };
      }, [open]);

      useEffect(() => {
        if (fieldData.kind === "numeric") {
          setNumericValue(value.toString());
        }
      }, [value]);

      if (["isNull", "isNotNull"].includes(operator)) return;

      return (
        <Box
          style={{
            overflowY: "auto",
            maxHeight: 200,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          {fieldData?.kind === "boolean" && (
            <Checkbox
              onClick={(e) => {
                handleOnChange(!e.target.checked);
              }}
              checked={Boolean(value)}
              value={!value}
              {...props}
            />
          )}
          {fieldData?.kind === "counted" &&
            !["in", "notIn"].includes(operator) && (
              <Box style={{ display: "flex", gap: 16 }}>
                <Autocomplete
                  {...props}
                  ListboxComponent={({ ...props }) => (
                    <Box width="100%">
                      <ListboxComponent {...props} />
                    </Box>
                  )}
                  style={{ minWidth: 200 }}
                  value={
                    value[0]
                      ? value[0]
                      : (filterValue.constructor === Object
                          ? Object.keys(filterValue).length <= 0
                            ? [Object.keys(filterValue)[0]]
                            : Object.keys(filterValue)
                          : filterValue
                        ).filter((val) => val)[0]
                  }
                  options={
                    filterValue.constructor === Object
                      ? Object.keys(filterValue).length <= 0
                        ? []
                        : Object.keys(filterValue)
                      : filterValue
                  }
                  loading={loading}
                  getOptionLabel={(option) => {
                    if (
                      fieldData.representation &&
                      fieldData.representation.options &&
                      fieldData.representation.options.component_choices &&
                      fieldData.representation.options.component_choices
                        .constructor === Object &&
                      fieldData.representation.options.component_choices[option]
                    ) {
                      return fieldData.representation.options.component_choices[
                        option
                      ];
                    } else {
                      return option;
                    }
                  }}
                  open={open}
                  onOpen={() => {
                    setOpenField(true);
                  }}
                  onChange={(e, newValue) =>
                    handleOnChange([newValue, value[1]])
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(title)}
                      variant="outlined"
                    />
                  )}
                />
                <TextField
                  {...props}
                  value={numericValue ?? null}
                  type="number"
                  style={{ marginLeft: 10, marginRight: 10 }}
                  color="secondary"
                  valueLabelDisplay="on"
                  ref={ref}
                  label={t("Piece")}
                  onChange={(e) => {
                    setNumericValue(e.target.value);
                  }}
                  onBlur={(e) => handleOnChange([value[0], numericValue])}
                />
              </Box>
            )}
          {fieldData?.kind === "counted" &&
            ["in", "notIn"].includes(operator) && (
              <Autocomplete
                {...props}
                ListboxComponent={({ ...props }) => (
                  <Box width="100%">
                    <ListboxComponent {...props} />
                  </Box>
                )}
                style={{ minWidth: 200 }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <>
                      <Chip
                        color="primary"
                        variant="outlined"
                        label={
                          <Typography className={classes.chip}>
                            {fieldData.representation &&
                            fieldData.representation.options &&
                            fieldData.representation.options
                              .component_choices &&
                            fieldData.representation.options.component_choices
                              .constructor === Object &&
                            fieldData.representation.options.component_choices[
                              option
                            ]
                              ? fieldData.representation.options
                                  .component_choices[option]
                              : option}
                          </Typography>
                        }
                        {...getTagProps({ index })}
                      />
                      {index === 44 && value.length > 45 && <>&nbsp;. . . </>}
                    </>
                  ))
                }
                value={
                  value ??
                  (filterValue.constructor === Object
                    ? Object.keys(filterValue).length <= 0
                      ? [Object.keys(filterValue)[0]]
                      : Object.keys(filterValue)
                    : filterValue
                  ).filter((val) => val)[0]
                }
                options={
                  filterValue.constructor === Object
                    ? Object.keys(filterValue).length <= 0
                      ? []
                      : Object.keys(filterValue)
                    : filterValue
                }
                loading={loading}
                getOptionLabel={(option) => {
                  if (
                    fieldData.representation &&
                    fieldData.representation.options &&
                    fieldData.representation.options.component_choices &&
                    fieldData.representation.options.component_choices
                      .constructor === Object &&
                    fieldData.representation.options.component_choices[option]
                  ) {
                    return fieldData.representation.options.component_choices[
                      option
                    ];
                  } else {
                    return option;
                  }
                }}
                open={open}
                onOpen={() => {
                  setOpenField(true);
                }}
                onChange={(e, value) => handleOnChange(value)}
                renderInput={(params) => (
                  <TextField {...params} label={t(title)} variant="outlined" />
                )}
              />
            )}

          {fieldData?.kind === "enumeration" && (
            <Box>
              <Autocomplete
                {...props}
                multiple={["in", "notIn"].includes(operator)}
                ListboxComponent={({ ...props }) => (
                  <Box width="100%">
                    <ListboxComponent {...props} />
                  </Box>
                )}
                style={{ minWidth: 200 }}
                renderOption={(option, props) => {
                  if (option) {
                    return (
                      <React.Fragment {...props}>
                        {fieldData.representation.options.component === "shared_variants" ? (
                          <SharedVariants data={option.replaceAll("'", '"')} />
                        ) : fieldData.representation.options.component_choices && 
                           fieldData.representation.options.component_choices.constructor === Object ? (
                          fieldData.representation.options.component_choices[option] || option
                        ) : (
                          option
                        )}
                      </React.Fragment>
                    );
                  }
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <>
                        <Chip
                          color="primary"
                          variant="outlined"
                          label={
                            <Typography className={classes.chip}>
                              {fieldData.representation &&
                              fieldData.representation.options &&
                              fieldData.representation.options
                                .component_choices &&
                              fieldData.representation.options.component_choices
                                .constructor === Object &&
                              fieldData.representation.options
                                .component_choices[option]
                                ? fieldData.representation.options
                                    .component_choices[option]
                                : option}
                            </Typography>
                          }
                          {...getTagProps({ index })}
                        />
                        {index === 44 && value.length > 45 && <>&nbsp;. . . </>}
                      </>
                    );
                  })
                }
                value={
                  ["in", "notIn"].includes(operator)
                    ? (value || "").split(",")[0] === ""
                      ? [
                          filterValue?.constructor === Object
                            ? Object.keys(filterValue)?.[0]
                            : filterValue?.[0],
                        ]
                      : (value || "").split(",")
                    : value
                    ? value
                    : filterValue?.constructor === Object
                    ? Object.keys(filterValue)?.[0]
                    : filterValue?.[0]
                }
                options={
                  filterValue.constructor === Object
                    ? Object.keys(filterValue).length <= 0
                      ? []
                      : Object.keys(filterValue)
                    : filterValue
                }
                loading={loading}
                getOptionLabel={(option) => {
                  if (
                    fieldData.representation &&
                    fieldData.representation.options &&
                    fieldData.representation.options.component_choices &&
                    fieldData.representation.options.component_choices
                      .constructor === Object &&
                    fieldData.representation.options.component_choices[option]
                  ) {
                    return fieldData.representation.options.component_choices[
                      option
                    ];
                  } else {
                    return option;
                  }
                }}
                open={open}
                onOpen={() => {
                  setOpenField(true);
                }}
                onChange={(e, value) =>
                  ["in", "notIn"].includes(operator)
                    ? handleOnChange(value.join(","))
                    : handleOnChange(value.toString())
                }
                renderInput={(params) => {
                  let newPart = <></>;
                  if (
                    fieldData.representation.options.component ===
                      "shared_variants" &&
                    params.inputProps.value
                  ) {
                    newPart = (
                      <SharedVariants
                        params={params}
                        data={params.inputProps.value.replaceAll("'", '"')}
                      />
                    );
                    if (
                      isJSONString(params.inputProps.value.replaceAll("'", '"'))
                    ) {
                      const value = JSON.parse(
                        params.inputProps.value.replaceAll("'", '"')
                      );
                      params.inputProps.value = `${t("Sample 1")} ${
                        value.sample1 === "+" ? "+" : "-"
                      } ${t("Sample 2")} ${value.sample2 === "+" ? "+" : "-"}`;
                    }
                  }
                  return (
                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      <TextField
                        {...params}
                        label={t(title)}
                        variant="outlined"
                      />
                      {newPart}
                    </Box>
                  );
                }}
              />
            </Box>
          )}
          {fieldData?.kind === "numeric" && (
            <>
              <TextField
                {...props}
                value={numericValue ? numericValue : fieldData.minValue}
                type="number"
                inputProps={{
                  step: 0.001,
                  shrink: true,
                  inputMode: "decimal",
                  pattern: "[0-9]*",
                  min: fieldData.minValue,
                  max: fieldData.maxValue,
                }}
                style={{ marginLeft: 10, marginRight: 10 }}
                color="secondary"
                valueLabelDisplay="on"
                ref={ref}
                onChange={(e) => {
                  if (
                    e.target.value >= fieldData.minValue &&
                    e.target.value <= fieldData.maxValue
                  ) {
                    setNumericValue(e.target.value.toString());
                  } else if (e.target.value >= fieldData.maxValue) {
                    setNumericValue(fieldData.maxValuevalue.toString());
                  } else if (e.target.value <= fieldData.minValue) {
                    setNumericValue(fieldData.minValuevalue.toString());
                  }
                }}
                onBlur={() => {
                  handleOnChange(numericValue);
                }}
              />
              <Typography>
                Minimum:&nbsp;{fieldData.minValue}&nbsp; Maximum:&nbsp;
                {fieldData.maxValue}
              </Typography>
            </>
          )}
        </Box>
      );
    }
  );

export default Filter;
