import { Box, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomDropdown from "src/components/customDropdown";
import { PrimaryHistology } from ".";

function NanoporeForm(props) {
  const { disabled, appConf, handleChange, setFormData, formData } = props;
  useEffect(() => {
    setFormData({
      sequencer: "nanopore",
      pipeline_class: "nanopore",
      sample_kind: "single_end",
      project_type: "exome_fastq",
      description: "",
    });
  }, []);

  const { t } = useTranslation();
  let nanopore = {};
  // TODO : this exome fastq changed to nanopore when fixed in backend
  Object.entries(appConf.entities.pipeline_classes["exome_fastq"]).map(
    (val) => {
      nanopore = { ...nanopore, [val[0]]: t(val[1]) };
      return val;
    },
  );
  if (formData)
    return (
      <>
        <Box minWidth="100%">
          <TextField
            label={t("Project Title")}
            name="name"
            variant="outlined"
            required
            disabled={disabled}
            onChange={handleChange}
            value={formData ? formData.name : ""}
          />
          <CustomDropdown
            name="sample_kind"
            formData={{ sample_kind: "single_end" }}
            labelTitle={t("Type")}
            values={{
              single_end: t("Single End"),
              paired_end: t("Paired End"),
            }}
            disabled={true}
            changeHandler={handleChange}
            defaultIndex={1}
          />
          <CustomDropdown
            name="kit"
            formData={formData}
            labelTitle={t("Kit")}
            values={appConf.entities.kits}
            disabled={disabled}
            changeHandler={handleChange}
            defaultIndex={0}
          />
          <CustomDropdown
            name="sequencer"
            formData={formData}
            labelTitle={t("Sequencer Machine")}
            values={appConf.entities.sequencers}
            changeHandler={handleChange}
            disabled={true}
            defaultIndex={1}
          />
          <CustomDropdown
            name="pipeline_class"
            formData={formData}
            labelTitle={t("Pipeline Type")}
            values={nanopore}
            changeHandler={handleChange}
            disabled={true}
            defaultIndex={1}
          />
          <PrimaryHistology
            formData={formData}
            appConf={appConf}
            disabled={disabled}
            handleChange={handleChange}
          />
        </Box>
        <TextField
          fullWidth
          id="project-desc"
          name="description"
          value={formData.description ?? ""}
          label={t("Project Description")}
          placeholder={t("Describe your project (optional)")}
          multiline
          minRows={4}
          variant="outlined"
          disabled={disabled}
          onChange={handleChange}
        />
      </>
    );
}

export default NanoporeForm;
