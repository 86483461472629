/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { getBaseURL } from "src/config/api";
import safeLogger from "src/services/safeLogger";
import { filterRows, getBaseLanguage } from "../utils/helpers";
import { CNVPlots } from "./CNVPlots";
import { CNVVirtualizedTable } from "./cnvVirtualized";
import { useTranslation } from "react-i18next";
import { FilterStatusBox } from "../filterStatus";

export { CNVPlots };

const useStyles = makeStyles((theme) => ({
  spinner: {
    top: "50%",
    left: "50%",
    position: "absolute",
  },
}));

export const CNVTable = (props) => {
  const { files } = props;
  const classes = useStyles();
  const [rawData, setRawData] = useState(undefined);
  const [cnvTableData, setCnvTableData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(undefined);
  const [defaultRows, setdefaultRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [defaultColDetails, setDefaultColDetails] = useState([]);
  const [tableColDetails, setTableColDetails] = useState([]);
  const [colDetailsObj, setColDetailsObj] = useState({});
  const [tableFilterState, setTableFilterState] = useState({});
  const [visibleCols, setVisibleCols] = useState(undefined);

  const [sortState, setSortState] = useState({});
  const { t } = useTranslation();

  // Sorting func
  function sortRows(rows, sortState) {
    const collator = new Intl.Collator(getBaseLanguage("en"), {
      sensitivity: "base",
    });
    for (let item in sortState) {
      if (colDetailsObj[item]?.kind === "free_form") {
        continue;
      }
      rows.sort((a, b) => {
        if (colDetailsObj[item]?.kind === "enumeration") {
          return sortState[item] === "ASC"
            ? collator.compare(a[item], b[item])
            : collator.compare(b[item], a[item]);
        } else if (colDetailsObj[item]?.kind === "numeric") {
          if (a[item] > b[item]) return sortState[item] === "ASC" ? 1 : -1;
          if (a[item] < b[item]) return sortState[item] === "ASC" ? -1 : 1;
          return 0;
        }
        return 0;
      });
    }

    return rows;
  }

  // if change any sort of columns this use effect runs.
  useEffect(() => {
    let mounted = true;

    if (sortState && Object.keys(colDetailsObj).length) {
      const sortedRows = !Object.keys(sortState).length
        ? defaultRows
        : sortRows(filteredRows, sortState);
      if (mounted) {
        setFilteredRows(sortedRows.slice(0));
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [sortState]);

  useEffect(() => {
    let mounted = true;
    if (mounted && tableColDetails && cnvTableData) {
      const filteredData = filterRows(cnvTableData, tableColDetails);
      setFilteredRows(filteredData);
      if (!defaultRows.length) {
        setdefaultRows(filteredData.slice(0));
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [cnvTableData, tableColDetails]);

  // get unique values for enumeration and numeric range for numeric columns
  useEffect(() => {
    let mounted = true;
    if (
      rawData &&
      rawData.length &&
      tableColDetails.length &&
      !cnvTableData.length
    ) {
      const tmpRowObjects = [];
      safeLogger(tableColDetails);
      for (let i = 0; i < rawData.length; i++) {
        const tmpRecord = {};
        const row = rawData[i];
        for (const col of tableColDetails) {
          if (row.hasOwnProperty(col.name)) {
            if (col?.kind === "enumeration") {
              if (!col.filterValues) {
                col.filterValues = new Set();
              }
              tmpRecord[col.name] = row[col.name];
              const uniqVals = row[col.name].split(","); // TODO define a solid structure for multivalue
              try {
                uniqVals.map((val) => col.filterValues.add(val));
              } catch (error) {
                safeLogger(col);
              }
            } else if (col?.kind === "numeric") {
              if (col.minValue === undefined) {
                col["minValue"] = 0;
              }
              if (col.maxValue === undefined) {
                col["maxValue"] = 0;
              }
              const tmpParsedValue = parseFloat(row[col.name]);
              if (isNaN(tmpParsedValue)) {
                safeLogger(row);
              }
              tmpRecord[col.name] = tmpParsedValue;
              if (tmpParsedValue < col.minValue) {
                col.minValue = tmpParsedValue;
              }
              if (tmpParsedValue > col.maxValue) {
                col.maxValue = tmpParsedValue;
              }
            } else if (col?.kind === "boolean") {
              tmpRecord[col.name] = row[col.name];
              col.filterValues.add({ true: true, false: false });
            } else {
              tmpRecord[col?.name] = row[col.name];
            }
          }
        }
        tmpRowObjects.push(tmpRecord);
      }

      if (mounted) {
        setCnvTableData(tmpRowObjects);
      }

      // prepareCols(tableColDetails);
      for (let i = 0; i < tableColDetails.length; i++) {
        tableColDetails[i]["activeFilterCount"] = 0;
        if (tableColDetails[i]?.kind === "enumeration") {
          const tmpArr = tableColDetails[i].filterValues
            ? Array.from(tableColDetails[i].filterValues)
            : [];
          const tmpFilterValues = {};
          for (let j = 0; j < tmpArr.length; j++) {
            tmpFilterValues[tmpArr[j]] = false;
          }
          tableColDetails[i].filterValues = tmpFilterValues;
        } else if (tableColDetails[i]?.kind === "numeric") {
          tableColDetails[i].filterRange = [
            tableColDetails[i].minValue,
            tableColDetails[i].maxValue,
          ];
        } else {
          tableColDetails[i].filterValues = {};
        }
      }
      if (mounted) {
        setTableColDetails(tableColDetails);
      }
    }
  }, [rawData, tableColDetails, cnvTableData]);

  useEffect(() => {
    let mounted = true;
    if (tableColDetails.length && cnvTableData.length) {
      const tmpVisibleCols = tableColDetails.filter((col, index) => {
        col["globalIndex"] = index;
        return (
          col?.representation?.kind === "main_table_cell" &&
          cnvTableData[0].hasOwnProperty(col.name)
        );
      });
      if (mounted) {
        setVisibleCols(tmpVisibleCols);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [tableColDetails, cnvTableData]);

  // load raw table data
  useEffect(() => {
    let mounted = true;
    try {
      // fetch("/160991_cnv_table_v1.tsv")
      fetch(getBaseURL() + files["cnv"].download_address)
        .then((response) => response.text())
        .then((data) => {
          data = data.split("\n");
          const headers = data[0].split("\t");
          // check if the last line is empty
          if (!data[data.length - 1]) {
            data = data.slice(1, data.length - 1);
          } else {
            data = data.slice(1);
          }

          for (const index in data) {
            let tmpRow = {};
            const row = data[index].split("\t");
            for (const headerIndex in headers) {
              const header = headers[headerIndex];
              tmpRow[header] = row[headerIndex];
            }
            data[index] = tmpRow;
          }

          if (mounted) {
            if (totalRowCount === undefined) {
              setTotalRowCount(data.length);
            }
            setRawData(data);
          }
        });
    } catch (error) {
      safeLogger(error);
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  // get cnv schema version from file
  // get schema with the version
  useEffect(() => {
    let mounted = true;

    let schemaVersion;
    const tableVersion = files["cnv"].name.slice(
      files["cnv"].name.lastIndexOf("_") + 1,
      files["cnv"].name.indexOf(".")
    );
    const re = new RegExp(/^v[0-9]+/);
    if (re.test(tableVersion)) {
      schemaVersion = tableVersion;
    } else {
      schemaVersion = "v1";
    }

    fetch(`/assets/pipeline/cnv/schema_${schemaVersion}.json`)
      .then((response) => response.json())
      .then((data) => {
        const colObj = {};
        for (let i = 0; i < data.length; i++) {
          colObj[data[i].name] = data[i];
        }

        if (mounted) {
          setColDetailsObj(colObj);
          setDefaultColDetails([...data]);
          setTableColDetails([...data]);
        }
      });

    return function cleanup() {
      mounted = false;
    };
  }, []);

  if (
    totalRowCount === undefined ||
    (totalRowCount !== 0 && !cnvTableData.length)
  ) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" width="100%">
            <Button
              size="small"
              variant="contained"
              href={getBaseURL() + files["cnv"].download_address}
              style={{
                marginTop: "1rem",
                marginBottom: "-1rem",
                marginLeft: "auto",
                span: {
                  color: "white !important",
                },
              }}
              color="primary"
            >
              {t("Download table")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={2}>
            <FilterStatusBox
              // reportMeta={reportMeta}
              setColDetails={setTableColDetails}
              colDetails={tableColDetails}
              setTableFilterTypes={() => {}}
              defaultColDetailsObject={colDetailsObj}
              setVisibleCols={setVisibleCols}
              defaultColDetails={defaultColDetails}
              setTableSortState={setSortState}
              tableFilterState={tableFilterState}
              setTableFilterState={setTableFilterState}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {totalRowCount === 0 ? (
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  alt=""
                  src="/assets/images/icons/dna-test.png"
                  width="150"
                />
                <Typography
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  {t("No variants were found in this sample.")}
                </Typography>
              </Box>
            ) : (
              filteredRows.length > 0 && (
                <CNVVirtualizedTable
                  height={400}
                  columns={tableColDetails}
                  visibleCols={visibleCols}
                  setColumns={setTableColDetails}
                  rows={filteredRows}
                  rowGetter={({ index }) => filteredRows[index]}
                  rowCount={filteredRows.length}
                  //
                  defaultColDetailsObject={colDetailsObj}
                  sort={sortState.sort}
                  sortBy={undefined}
                  sortDirection={undefined}
                  //
                  sortState={sortState}
                  setSortState={setSortState}
                  filterState={tableFilterState}
                />
              )
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
};
