import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExomeContent from "./exomeContent";

function OpenCloseRow({
  a11yProps,
  className,
  columns,
  exomebasePercantage,
  style,
}) {
  const [open, setOpen] = useState();
  const { t } = useTranslation();

  return (
    <div
      {...a11yProps}
      className={className}
      onClick={() => {
        setOpen((prev) => !prev);
      }}
      style={{
        ...style,
        backgroundColor: exomebasePercantage?.filter((exome) => exome.gene)
          ?.length
          ? "#2828280B"
          : "",
        borderBottom: "0.2px solid #CCCACA",
        cursor: exomebasePercantage?.filter((exome) => exome.gene)?.length
          ? "pointer"
          : "",
      }}
    >
      {columns}
      {exomebasePercantage?.filter((exome) => exome.gene)?.length > 0 && (
        <Dialog
          onClose={() => {
            setOpen((prev) => !prev);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          open={open}
          maxWidth={"xl"}
        >
          <DialogTitle>
            {t("Gene Name")} {columns[0]}
          </DialogTitle>
          <DialogContent>
            <ExomeContent exomebasePercantage={exomebasePercantage} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default OpenCloseRow;
