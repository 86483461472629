/* eslint-disable no-loop-func */
import { Box, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { TableConfigPage } from ".";
import { apiTableGetSchema } from "src/api/endpoints";
import { Autocomplete } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { defaultConfig } from "./defaultConfigWrapper";

function TableConfigWrapper({ appConf }) {
  const [schemas, setSchemas] = useState();
  const [tmp, setTmp] = useState();
  const [version, setVersion] = useState({});
  const [schemaObjects, setSchemaObjects] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState(undefined);
  const projectType =
    new URLSearchParams(useLocation().search).get("project_type") ??
    "exome_fastq";
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      appConf.schemas[projectType] &&
      schemaObjects.length === appConf.schemas[projectType].length &&
      !id
    ) {
      let newSchema = { columns: [] };
      for (let i = 0; schemaObjects.length > i; i++) {
        if (i === 0) newSchema = selectedSchema;
        newSchema.columns = [
          ...(i === 0 ? [] : newSchema.columns),
          ...schemaObjects[i].columns,
        ];
      }
      const filteredCols = [];
      let ObjCounter = {};
      for (let i = 0; newSchema.columns.length > i; i++) {
        const col = newSchema.columns[i];
        for (let j = 0; newSchema.columns.length > j; j++) {
          const insideCol = newSchema.columns[j];
          if (col.name === insideCol.name) {
            ObjCounter[col.name] = ObjCounter[col.name]
              ? ObjCounter[col.name] + 1
              : 1;
          }
        }
      }

      for (let i = 0; newSchema.columns.length > i; i++) {
        const col = newSchema.columns[i];
        if (ObjCounter[col.name]) {
          filteredCols.push(col);
          delete ObjCounter[col.name];
        }
      }

      newSchema.columns = filteredCols;
      setSchemas(newSchema);
    }
  }, [selectedSchema]);

  const schemaHandler = async () => {
    if (appConf.schemas[projectType]) {
      const schemaIds = appConf.schemas[projectType];
      let tmpSchemaObjects = {};
      let allColumns = [];
      for (const schemaId of schemaIds) {
        await apiTableGetSchema(schemaId, (cols) => {
          tmpSchemaObjects = {
            [cols.version]: cols,
            ...tmpSchemaObjects,
          };
          setVersion({
            version: Number(
              cols.version.replace("v", "").replace("/somatic", "")
            ),
          });
          allColumns.push(cols);
        });
      }
      if (allColumns.length > 0) {
        setSchemaObjects(() => allColumns);
        setTmp(tmpSchemaObjects);
      }
    }
  };

  useEffect(() => {
    if (!id) {
      schemaHandler();
    }
  }, []);

  return (
    <Box style={{ position: "relative" }}>
      {!schemas && !id && (
        <Box
          className="glass"
          style={{
            position: "fixed",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            width: "100%",
          }}
        >
          {schemaObjects.length > 0 && (
            <>
              <Typography>
                {t("Select the desired pipeline type for the filter options")}
              </Typography>
              <Autocomplete
                style={{ width: "60%" }}
                options={schemaObjects}
                onChange={(e, value) => {
                  setSelectedSchema(value);
                }}
                getOptionLabel={(options) => {
                  if (options.version.includes("/")) {
                    return t("Somatic");
                  } else {
                    return t("Germline");
                  }
                }}
                renderInput={(props) => (
                  <TextField label={t("Pipeline Type")} {...props} />
                )}
              />
            </>
          )}
        </Box>
      )}
      <TableConfigPage
        tmp={tmp}
        version={version}
        schemas={!schemas && !id ? defaultConfig : schemas}
      />
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    appConf: state.appConf,
    userObject: state.userObject,
  };
};

export default connect(mapStateToProps)(TableConfigWrapper);
