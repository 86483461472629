import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import HboFilter from "../../hboFilter";
import PanelApp from "../../panel";
import { useTranslation } from "react-i18next";
import { FiltersetTable } from "src/components/pages/settings/tableConfigPage/filterset";
import { defaultFiltersetsConf } from "src/config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  evenly: {
    display: "flex",
    justifyContent: "space-evenly",
    alignContent: "center",
    width: 300,
  },
  hr: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

function ConnectedOutSource({
  callback,
  type,
  label,
  appConf,
  schemaId,
  userObject,
  loadUser,
  colName,
}) {
  const [open, setOpen] = useState(false);
  const [intersection, setIntersection] = useState(false);
  const [pheno, setPheno] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [filtersets, setFiltersets] = useState([{ name: "", values: [] }]);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleApply = () => {
    callback(selectedValues, pheno, filtersets, colName);
    setOpen((prev) => !prev);
    setFiltersets([{ name: "", values: [] }]);
  };

  const handleFilterChange = (e, values, type, name) => {
    const existingFiltersetIndex = filtersets.findIndex(
      (filterset) => filterset.name === name
    );
    if (existingFiltersetIndex === -1) {
      setFiltersets((prevFiltersets) => [...prevFiltersets, { name, values }]);
    }
  };

  const handleOpen = (e) => setOpen((prev) => !prev);
  return (
    <Box className={classes.root}>
      <Button onClick={handleOpen} className={classes.evenly}>
        <PlusCircle />
        <Box>{label}</Box>
      </Button>
      <Dialog open={open} fullWidth={true} maxWidth="lg" onClose={handleOpen}>
        <DialogTitle>{t("Out Source Values")}</DialogTitle>
        <DialogContent>
          <Container maxWidth="lg" style={{ margin: "10px 0" }}>
            <Typography variant="h3" color="primary">
              {label}
            </Typography>
          </Container>
          {type === "hpo" && (
            <HboFilter
              intersection={undefined}
              setIntersection={setIntersection}
              pheno={pheno}
              setPheno={setPheno}
              setOpen={setOpen}
            />
          )}
          {type === "panel" && (
            <PanelApp
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          )}
          {type === "filtersets" && (
            <>
              <Box className={classes.header} variant="h2">
                {t("Selected Values")}
              </Box>
              <Divider
                className={classes.hr}
                style={{ marginBottom: "20px" }}
              />

              {filtersets &&
                filtersets.map(
                  (filterset, index) =>
                    filterset.name !== "" && (
                      <Button
                        className={classes.button}
                        key={index}
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          setFiltersets((prevFiltersets) =>
                            prevFiltersets.filter((_, i) => i !== index)
                          )
                        }
                      >
                        {filterset.name}
                      </Button>
                    )
                )}
              <FiltersetTable
                kind={"enumeration"}
                appConf={appConf}
                schemaId={schemaId}
                colName={colName}
                open={open}
                setOpen={() => {}}
                handleFiltersChange={(_e, values, type, name) =>
                  handleFilterChange(_e, values, type, name)
                }
                userObject={userObject}
                loadUser={loadUser}
                defaultFiltersets={defaultFiltersetsConf[colName]}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApply}>{t("Apply")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    userObject: state.userObject,
  };
};

const OutSource = connect(mapStateToProps)(ConnectedOutSource);

export default OutSource;
