import {
  LOAD_USER,
  AUTH_SIGNIN,
  SIGNOUT,
  LOAD_APP_CONF,
  SET_LANG,
  UPDATE_ACTIVE_SAMPLES,
  UPDATE_ACTIVE_PROJECTS,
  GENE_BASED_FILTER_CHANGE,
  GENE_BASED_FILTER_CHANGE_STATS,
  SET_THEME,
  CLEAR_ACTIVE_GENE_FILTERS,
  LOAD_ORGANIZATION,
  SET_ADVANCE_FILTER,
  SET_SAMPLE_SIZE,
} from "../constants";
import i18next from "src/services/i18n";

const initialState = {
  auth: "",
  appConf: null,
  userObject: null,
  activeSamples: {},
  activeProjects: {},
  activeGeneFilters: {},
  activeGeneFiltersVariant: {},
  theme: sessionStorage.getItem("theme") || "light",
};

const rootReducer = (state = initialState, action) => {
  if (action.type === LOAD_APP_CONF) {
    return Object.assign({}, state, { appConf: action.payload });
  }
  if (action.type === SET_LANG) {
    i18next.changeLanguage(action.payload);
    return Object.assign({}, state, {
      appConf: { ...state.appConf, lang: action.payload },
    });
  }
  if (action.type === LOAD_USER) {
    return Object.assign({}, state, { userObject: action.payload });
  }
  if (action.type === SET_ADVANCE_FILTER) {
    return Object.assign({}, state, { advanceFilter: action.payload });
  }
  if (action.type === AUTH_SIGNIN) {
    localStorage.setItem("mbiAuth", action.payload);
    return Object.assign({}, state, { auth: action.payload });
  }
  if (action.type === SIGNOUT) {
    localStorage.removeItem("mbiAuth");
    localStorage.removeItem("activeOrganizationId");
    return Object.assign({}, initialState, { auth: "" });
  }
  if (action.type === UPDATE_ACTIVE_SAMPLES) {
    return Object.assign({}, state, { activeSamples: action.payload });
  }
  if (action.type === UPDATE_ACTIVE_PROJECTS) {
    return Object.assign({}, state, { activeProjects: action.payload });
  }
  if (action.type === GENE_BASED_FILTER_CHANGE) {
    return Object.assign({}, state, { activeGeneFilters: action.payload });
  }
  if (action.type === GENE_BASED_FILTER_CHANGE_STATS) {
    return Object.assign({}, state, {
      activeGeneFiltersVariant: action.payload,
    });
  }
  if (action.type === CLEAR_ACTIVE_GENE_FILTERS) {
    return Object.assign({}, state, {
      activeGeneFilters: {},
      activeGeneFiltersVariant: {},
    });
  }
  if (action.type === SET_THEME) {
    sessionStorage.setItem("theme", action.payload);
    return Object.assign({}, state, { theme: action.payload });
  }
  if (action.type === LOAD_ORGANIZATION) {
    return Object.assign({}, state, { organization: action.payload });
  }
  if (action.type === SET_SAMPLE_SIZE) {
    return {
      ...state,
      userObject: {
        ...state.userObject,
        settings: {
          ...state.userObject.settings,
          general: {
            ...state.userObject.settings.general,
            sampleSize: action.payload
          }
        }
      }
    };
  }

  return state;
};

export default rootReducer;
