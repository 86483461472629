import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Toolbar, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {},
  toolbar: {
    height: 64,
  },
});

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/exomeLogoWhite.png"
            style={{ maxHeight: "25px" }}
          />
          <Typography
            variant="body2"
            style={{
              marginLeft: "8px",
              display: "inline-flex",
              textDecoration: "none",
              color: "#FFF",
              fontSize: "25px",
              fontWeight: "400",
            }}
          >
            {t("app_name", "Massive Analyser")}
          </Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
