import { Navigate } from "react-router-dom";
import {
  AccountPage,
  EditUser,
  EditTeam,
  EditDepartment,
  SearchResultsPage,
  CreateProjectPage,
  ProjectsPage,
  ReportPage,
  ProjectDetailsPage,
  HelpPage,
  SettingsPage,
  AppHomePage,
  SignInPage,
  ResetPasswordPage,
  Error404Page,
  Calculator,
  Error500Page,
  BlogPage,
  SelectOrganizationPage,
} from "./components";
import {
  Error400Page,
  Error403Page,
  Error503Page,
  Error504Page,
  ErrorAppCrashPage,
  MaintenanceModePage,
  NetworkErrorPage,
} from "./components/pages/errors";
import Filter from "./components/pages/reports/advancedFilter/filter/filter";
import Protvista from "./components/protvista";
import { userHasPermission } from "./components/utils";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";
import TableConfigWrapper from "./components/pages/settings/tableConfigPage/tableConfigWrapper";
import { ChartsConfigPageWrapper } from "./components/pages/settings/chartsConfigPage";
import SeeText from "./components/pages/seeText";
import MyDocument from "./components/pages/pdf";
import Transactions from "./components/pages/transactions";

const routes = (isLoggedIn, userPermissions, activeOrg) => [
  {
    path: "/",
    element: isLoggedIn ? (
      !activeOrg ? (
        <Navigate to="/select-organization" />
      ) : (
        <DashboardLayout />
      )
    ) : (
      <Navigate to="/signin" />
    ),
    children: [
      { path: "account/edit-user/:id", element: <EditUser /> },
      { path: "account/edit-team/:id", element: <EditTeam /> },
      { path: "account/edit-department/:id", element: <EditDepartment /> },
      { path: "account/transactions", element: <Transactions /> },
      { path: "account", element: <AccountPage /> },
      { path: "calculator", element: <Calculator /> },
      { path: "search/*", element: <SearchResultsPage /> },
      {
        path: "projects/create",
        element: userHasPermission(userPermissions, "create_project") ? (
          <CreateProjectPage />
        ) : (
          <Navigate replace={true} to="/403" />
        ),
      },
      { path: "projects/:id", element: <ProjectDetailsPage /> },
      { path: "projects", element: <ProjectsPage /> },
      { path: "reports/:id", element: <ReportPage /> },
      {
        path: "reports/:reportId/pdf/:isSecondary",
        children: [{ path: "", element: <MyDocument /> }],
      },
      { path: "settings/tableconf/create", element: <TableConfigWrapper /> },
      { path: "settings/tableconf/:id", element: <TableConfigWrapper /> },
      {
        path: "settings/chartsconf/create",
        element: <ChartsConfigPageWrapper />,
      },
      {
        path: "settings/chartsconf/:key",
        element: <ChartsConfigPageWrapper />,
      },
      { path: "settings", element: <SettingsPage /> },
      { path: "dashboard", element: <AppHomePage /> },
      { path: "help", element: <HelpPage /> },
      { path: "test", element: <Protvista /> },
      { path: "articles/:id", element: <BlogPage /> },
      { path: "oops", element: <ErrorAppCrashPage /> },
      { path: "404", element: <Error404Page /> },
      { path: "403", element: <Error403Page /> },
      { path: "400", element: <Error400Page /> },
      // { path: "neterror", element: <NetworkErrorPage /> },
      { path: "/", element: <Navigate replace={true} to="/dashboard" /> },
      { path: "/test", element: <Filter /> },
      { path: "*", element: <Navigate replace={true} to="/404" /> },
    ],
  },
  {
    path: "/signin",
    element: isLoggedIn ? (
      !activeOrg ? (
        <Navigate to="/select-organization" />
      ) : (
        <Navigate to="/dashboard" />
      )
    ) : (
      <MainLayout />
    ),
    children: [{ path: "", element: <SignInPage /> }],
  },
  // { path: "/pdf", element: <PDF /> },
  {
    path: "/reset-password",
    element: <MainLayout />,
    children: [{ path: "", element: <ResetPasswordPage /> }],
  },
  {
    path: "/select-organization",
    element: <MainLayout />,
    children: [{ path: "", element: <SelectOrganizationPage /> }],
  },
  {
    path: "/neterror",
    element: <MainLayout />,
    children: [{ path: "", element: <NetworkErrorPage /> }],
  },
  {
    path: "/500",
    element: <MainLayout />,
    children: [{ path: "", element: <Error500Page /> }],
  },
  {
    path: "/503",
    element: <MainLayout />,
    children: [{ path: "", element: <Error503Page /> }],
  },
  {
    path: "/504",
    element: <MainLayout />,
    children: [{ path: "", element: <Error504Page /> }],
  },
  {
    path: "/see-text",
    element: <MainLayout />,
    children: [{ path: "", element: <SeeText /> }],
  },
  {
    path: "/maintenance",
    // element: !isLoggedIn ? <MainLayout /> : <Navigate to="app/dashboard" />,
    element: <MainLayout />,
    children: [
      { path: "", element: <MaintenanceModePage /> },
      // { path: "articles/*", element: <BlogPage /> },
      // { path: "500", element: <Error500Page /> },
      // { path: "app-crash", element: <ErrorAppCrashPage /> },
      // { path: "503", element: <Error503Page /> },
      // { path: "504", element: <Error504Page /> },
      // { path: "404", element: <Error404Page /> },
      // { path: "403", element: <Error403Page /> },
      // { path: "400", element: <Error400Page /> },
      // { path: "neterror", element: <NetworkErrorPage /> },
      // { path: "/", element: <Navigate to="/app" /> },
      // { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
