/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Page from "src/components/Page";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { appPages } from "../../../config/appPages";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getBlogBaseURL, urlAPIConf } from "src/config/api";
import { connect } from "react-redux";
import safeLogger from "src/services/safeLogger";
import { getBaseLanguage } from "../reports/utils/helpers";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    paddingTop: "40px",
  },
  pageTitle: {
    marginBottom: "30px",
  },
  sectionTitle: {
    marginBottom: "20px",
  },
}));

// const getObjectLink = (objectType, id, parentId) => {
//   if (objectType === "projects") {
//     return `/projects/${id}`;
//   } else if (objectType === "samples") {
//     return `/reports/${id}`;
//   }
// };

const SearchSection = (props) => {
  const classes = useStyles();
  const { title, data } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Typography component="h3" variant="h3" className={classes.sectionTitle}>
        {t(title)}
      </Typography>
      {data.map((result, index) => (
        <Box key={index} mb={2}>
          {result.slug ? (
            <Link href={result.slug}>{result.title}</Link>
          ) : (
            <RouterLink to={`/${urlAPIConf.ARTICLES_SLUG}/${result.id}`}>
              {result.title}
            </RouterLink>
          )}
          {/* projects and samples search result */}
          <Typography variant="body2">
            {result.description ? result.description : result.intro}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { userObject: state.userObject, appConf: state.appConf };
};

const ConnectedSearchResultsPage = (props) => {
  const classes = useStyles();
  const { userObject, appConf } = props;
  const userSearchConf = userObject.settings.general["searchConf"];
  const [apiLoading, setApiLoading] = useState(true);
  const [offlineSearchLoading, setOfflineSearchLoading] = useState(true);
  const loading = apiLoading || offlineSearchLoading;
  const [searchResult, setSearchResult] = useState({
    Pages: [],
    Projects: [],
    Samples: [],
    Variants: [],
    Settings: [],
  });
  const query = useLocation().search;
  const { t } = useTranslation();

  // const searchCallback = (data, status) => {
  //   if (status === 200) {
  //     safeLogger(data);
  //   } else {
  //     safeLogger(data);
  //   }

  //   setApiLoading(false);
  // };

  // useEffect(() => {
  //   apiSearch(query, searchCallback);
  // }, []);

  useEffect(() => {
    let mounted = true;
    const q = new URLSearchParams(query).get("q");
    const category = new URLSearchParams(query).get("category");
    const tags = new URLSearchParams(query).get("tags");
    const author = new URLSearchParams(query).get("author");
    if ((q || category || tags || author) && appConf) {
      let filteredPages = [];
      if (q && userSearchConf.includes("pages")) {
        const queryRegex = new RegExp(q.split("+").join("|"), "i");

        filteredPages = appPages.filter((page) => {
          return (
            queryRegex.test(page.title) || queryRegex.test(page.description)
          );
        });
      }

      setOfflineSearchLoading(false);

      setApiLoading(true);

      // const blogCategories = {
      //   tutorials: 1,
      //   announcements: 2,
      //   analysis: 3,
      // }; // TODO based on settings, include the category in the query

      fetch(
        `${getBlogBaseURL()}/${
          urlAPIConf.BASE_API_SLUG
        }/pages/?fields=_,id,title,intro,first_published_at,categories,tags,main_image&type=blog.BlogPage&locale=${getBaseLanguage()}&order=-first_published_at${
          q ? "&search=" + q + "&search_operator=and" : ""
        }${category ? "&categories__in=" + category : ""}${
          tags ? "&tags=" + tags : ""
        }${author ? "&owner=" + author : ""}`
      )
        .then((response) => response.json())
        .then((data) => {
          safeLogger(data.items);
          const blogResult = {};
          if (data.items) {
            for (let i = 0; i < data.items.length; i++) {
              if (!blogResult[data.items[i].categories[0].name]) {
                blogResult[data.items[i].categories[0].name] = [];
              }
              blogResult[data.items[i].categories[0].name].push(data.items[i]);
            }
          }

          if (mounted) {
            setSearchResult((searchResult) => {
              return {
                ...searchResult,
                Pages: filteredPages,
                ...blogResult,
              };
            });
          }

          setApiLoading(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, [query, appConf]);

  useEffect(() => {
    if (!loading) {
      safeLogger(searchResult);
    }
  }, [loading]);

  if (loading) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Search Results")}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h2"
                className={classes.pageTitle}
              >
                {t("Search Results")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!loading &&
                Object.keys(searchResult).map((sectionKey, index) => {
                  if (searchResult[sectionKey].length) {
                    return (
                      <SearchSection
                        key={index}
                        title={sectionKey}
                        data={searchResult[sectionKey]}
                      />
                    );
                  } else return null;
                })}
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
};

export const SearchResultsPage = connect(mapStateToProps)(
  ConnectedSearchResultsPage
);
