import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  typography: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

function ProjectName({ name, title, style, variant, component }) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      {/* <Typography variant="p" style={{ fontSize: 10 }} component="p">
        {title} :
      </Typography> */}
      <Typography
        className={classes.typography}
        variant={variant}
        style={style}
        component={component}
      >
        {name}
      </Typography>
    </Grid>
  );
}

export default ProjectName;
