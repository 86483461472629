/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CustomTableCell from "./tableCell";
import React, { useCallback } from "react";
import Header from "./header";
import "./index.css";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  tableHeaderCell: {
    backgroundColor: "#388e3c",
    color: "white",
    maxHeight: 80,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px ${theme.spacing(2)}px`,
    maxWidth: 1500,
    borderRight: "0.3px solid rgba(0,0,0,0.5)",
  },
  tableRow: {
    minHeight: 30,
    "&:first-child": {
      minWidth: 150,
    },
  },
}));

function VariantTable({
  virtualRows,
  table,
  rows,
  colDetails,
  filters,
  columnHelper,
  dynamicRowCount,
  defaultColDetailsObject,
  setColDetails,
  selectedRow,
  setCheckedRows,
  tableKind,
  appConf,
  checkedRows,
  reportMeta,
  reportId,
  paddingTop,
  userObject,
  handleReorderColumnData,
  paddingBottom,
  isHaventCheckbox,
  loading,
  handleTagged,
  end,
  hpo,
  virtualTableHeight,
  rowClickHandler,
}) {
  return (
    <Table
      stickyHeader
      size="small"
      style={{ border: "0.3px solid rgba(0,0,0,0.1)" }}
    >
      <DragDropContext onDragEnd={handleReorderColumnData}>
        <TableHead
          style={{
            maxWidth: "100%",
            maxHeight: 250,
            minHeight: 200,
            overflow: "scroll",
          }}
        >
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <TableHeader
                appConf={appConf}
                setCheckedRows={setCheckedRows}
                checkedRows={checkedRows}
                tableKind={tableKind}
                reportId={reportId}
                ref={provided.innerRef}
                provided={provided}
                isHaventCheckbox={isHaventCheckbox}
                dynamicRowCount={dynamicRowCount}
                setColDetails={setColDetails}
                colDetails={colDetails}
                defaultColDetailsObject={defaultColDetailsObject}
                filters={filters}
                columnHelper={columnHelper}
                table={table}
              />
            )}
          </Droppable>
        </TableHead>
      </DragDropContext>
      <CustomTableBody
        loading={loading}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        setCheckedRows={setCheckedRows}
        reportMeta={reportMeta}
        handleTagged={handleTagged}
        checkedRows={checkedRows}
        isHaventCheckbox={isHaventCheckbox}
        userObject={userObject}
        virtualTableHeight={virtualTableHeight}
        hpo={hpo}
        defaultColDetailsObject={defaultColDetailsObject}
        reportId={reportId}
        selectedRow={selectedRow}
        rowClickHandler={rowClickHandler}
        virtualRows={virtualRows}
        rows={rows}
      />
      <TableFooter>{end}</TableFooter>
    </Table>
  );
}

export default VariantTable;

const CustomTableBody = React.memo(
  ({
    paddingTop,
    paddingBottom,
    setCheckedRows,
    reportMeta,
    checkedRows,
    defaultColDetailsObject,
    isHaventCheckbox,
    reportId,
    loading,
    selectedRow,
    userObject,
    rowClickHandler,
    handleTagged,
    virtualTableHeight,
    virtualRows,
    hpo,
    rows,
  }) => {
    if (loading) {
      return (
        <div style={{ width: "100%", height: virtualTableHeight }}>
          <CircularProgress
            style={{ top: "48%", left: "48%", position: "absolute" }}
          />
        </div>
      );
    } else {
      return (
        <TableBody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];

            return (
              <CustomTableRow
                key={row.id}
                row={row}
                reportMeta={reportMeta}
                rowClickHandler={rowClickHandler}
                selectedRow={selectedRow}
              >
                {row.getVisibleCells().map((cell, index) => {
                  return (
                    <CustomTableCell
                      value={cell.getContext().getValue()}
                      reportId={reportId}
                      setCheckedRows={setCheckedRows}
                      hpo={hpo}
                      reportMeta={reportMeta}
                      isHaventCheckbox={isHaventCheckbox}
                      handleTagged={handleTagged}
                      userObject={userObject}
                      checkedRows={checkedRows}
                      defaultColDetailsObject={defaultColDetailsObject}
                      key={index}
                      index={index}
                      cell={cell}
                    />
                  );
                })}
              </CustomTableRow>
            );
          })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </TableBody>
      );
    }
  }
);

const CustomTableRow = React.memo(
  ({ children, rowClickHandler, row, reportMeta, selectedRow }) => {
    const theme = useSelector((state) => state.theme);

    const handleClick = (event) => {
      rowClickHandler({
        event,
        rowData: row.original,
        index: row.id,
      });
    };

    const getRowClassName = useCallback(() => {
      let isAnnotated = false;

      if (reportMeta?.commented_rows?.includes(row.original.___row___)) {
        isAnnotated = true;
      } else {
        for (let colName in row.original.___annotation___) {
          if (
            row.original?.___annotation___[colName]?.annotation &&
            row.original?.___annotation___[colName]?.annotation !==
              row.original?.___annotation___[colName]?.original
          ) {
            isAnnotated = true;
            break;
          }
        }
      }
      return isAnnotated;
    }, []);

    return (
      <TableRow
        key={row.id}
        onClick={handleClick}
        style={{
          backgroundColor:
            selectedRow === row.id
              ? theme === "dark"
                ? "rgba(198, 198, 198, 0.70)"
                : "rgba(0, 56, 102, 0.19)"
              : getRowClassName()
              ? theme === "dark"
                ? "rgba(198, 198, 198, 0.35)"
                : "rgba(0, 56, 102, 0.03)"
              : theme === "dark"
              ? "#424242"
              : "white",
          cursor: "pointer",
        }}
      >
        {children}
      </TableRow>
    );
  }
);

const TableHeader = React.memo(
  React.forwardRef(
    (
      {
        table,
        provided,
        columnHelper,
        setCheckedRows,
        setColDetails,
        checkedRows,
        tableKind,
        isHaventCheckbox,
        appConf,
        colDetails,
        reportId,
        filters,
        dynamicRowCount,
        defaultColDetailsObject,
      },
      ref
    ) => {
      const classes = useStyle();
      return table.getHeaderGroups().map((headerGroup) => (
        <TableRow
          ref={ref}
          style={{ position: "sticky", top: 0, left: 0, zIndex: 60 }}
          {...provided.droppableProps}
          className={classes.tableRow}
          key={headerGroup.id}
        >
          {headerGroup.headers.map((header, index) => {
            return (
              <CustomTableCellHeader
                key={index}
                variant="head"
                index={index}
                header={header}
                width={header.getSize()}
                minWidth={header.getSize() > 1000 ? 1000 : header.getSize()}
                className={classes.tableHeaderCell}
              >
                {header.isPlaceholder ? null : (
                  <Header
                    setCheckedRows={setCheckedRows}
                    checkedRows={checkedRows}
                    appConf={appConf}
                    isHaventCheckbox={isHaventCheckbox}
                    tableKind={tableKind}
                    dynamicRowCount={dynamicRowCount}
                    reportId={reportId}
                    setColDetails={setColDetails}
                    colDetails={colDetails}
                    defaultColDetailsObject={defaultColDetailsObject}
                    index={index}
                    table={table}
                    header={header}
                    columnHelper={columnHelper}
                    filters={filters}
                  />
                )}
              </CustomTableCellHeader>
            );
          })}
          {provided.placeholder}
        </TableRow>
      ));
    }
  )
);

const CustomTableCellHeader = React.memo(
  ({ children, index, className, header, colSpan }) => {
    const size = header.getSize();

    return (
      <TableCell
        key={index}
        variant="head"
        size="small"
        style={{
          zIndex: 0,
          width: index === 0 && size < 200 ? 230 : size,
          minWidth: index === 0 && size < 200 ? 230 : size,
          maxWidth: index === 0 && size < 200 ? 230 : size,
          flexWrap: index === 0 ? "no-wrap !important" : "",
        }}
        className={className}
        colSpan={colSpan}
      >
        {children}
      </TableCell>
    );
  }
);
