/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Box,
  Button,
  colors,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Page from "src/components/Page";
import { Save as SaveIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { loadUser } from "src/redux/actions";
import { apiUpdateProfile } from "src/api";
import safeLogger from "src/services/safeLogger";
import { reportSectionsConf } from "src/config";
import { apiTableGetSchema } from "src/api/endpoints";
import { getBaseLanguage } from "../../reports/utils/helpers";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    background: "inherit",
  },
  pageTitle: {
    marginBottom: "40px",
    color: colors.grey[800],
  },
  sectionTitle: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  form: {
    width: "100%",
  },
}));

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => dispatch(loadUser()),
  };
}

const mapStateToProps = (state) => {
  return {
    userObject: state.userObject,
    appConf: state.appConf,
  };
};

const ConnectedChartsConfigPage = (props) => {
  const classes = useStyles();
  const { userObject, loadUser, appConf, schema } = props;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { key } = useParams();
  const projectType = new URLSearchParams(useLocation().search).get(
    "project_type"
  );

  const [chartOptions, setChartOptions] = useState(
    reportSectionsConf[projectType] &&
      reportSectionsConf[projectType]?.charts[schema[0].version]
      ? reportSectionsConf[projectType]?.charts[schema[0].version]?.reduce(
          (dict, currVal) => Object.assign(dict, { [currVal]: false }),
          {}
        )
      : reportSectionsConf["exome_fastq"]?.charts["v10"]?.reduce(
          (dict, currVal) => Object.assign(dict, { [currVal]: false }),
          {}
        )
  );

  const [isEditMode, setIsEditMode] = useState(false);
  const [title, setTitle] = useState("");
  const [chartsPreset, setChartsPreset] = useState([]);
  const [helpInformation, setHelpInformation] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    if (appConf) {
      var myHeaders = new Headers();
      myHeaders.append("pragma", "no-cache");
      myHeaders.append("cache-control", "no-cache");
      fetch(
        `/assets/statistics/stats_doc_${getBaseLanguage(appConf.lang)}.json`,
        {
          cache: "no-cache",
          headers: myHeaders,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (mounted) {
            setHelpInformation(data);
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [appConf]);

  useEffect(() => {
    if (chartsPreset.length) {
      for (let i = 0; i < chartsPreset.length; i++) {
        chartOptions[chartsPreset[i]] = true;
      }
      safeLogger(chartOptions);
      setChartOptions({ ...chartOptions });
    }
  }, [chartsPreset]);

  useEffect(() => {
    if (key) {
      setIsEditMode(true);
      setChartsPreset(
        userObject.settings.report[projectType].charts[
          appConf.schemas[projectType].version
        ][key].charts
      );
      setTitle(
        userObject.settings.report[projectType].charts[
          appConf.schemas[projectType].version
        ][key].name
      );
    }
    setLoading(false);
  }, []);

  const titleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const handleChange = (event) => {
    setChartOptions({
      ...chartOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const newPresetKey = isEditMode
      ? key
      : title.toLowerCase().replace(" ", "_");

    if (
      newPresetKey === "default" ||
      (!isEditMode &&
        userObject.settings.report[projectType].charts[
          appConf.schemas[projectType].version
        ] &&
        Object.keys(
          userObject.settings.report[projectType].charts[
            appConf.schemas[projectType].version
          ]
        ).includes(newPresetKey))
    ) {
      enqueueSnackbar(
        t(
          "chart_preset_name_not_unique_error",
          "A chart preset with the name '{{title}}' already exists! Please use a unique name and try again.",
          { title: title }
        ),
        { variant: "error" }
      );
      return;
    }

    const chartsList = [];
    const chartOptionKeys = Object.keys(chartOptions);
    for (let i = 0; i < chartOptionKeys.length; i++) {
      if (chartOptions[chartOptionKeys[i]]) {
        chartsList.push(chartOptionKeys[i]);
      }
    }

    if (
      !userObject.settings.report[projectType].charts[
        appConf.schemas[projectType].version
      ]
    ) {
      userObject.settings.report[projectType].charts[
        appConf.schemas[projectType].version
      ] = {};
    }

    let prevDefaultStatus = undefined;

    if (
      isEditMode &&
      userObject.settings.report[projectType].charts[
        appConf.schemas[projectType].version
      ][newPresetKey].is_default
    ) {
      prevDefaultStatus =
        userObject.settings.report[projectType].charts[
          appConf.schemas[projectType].version
        ][newPresetKey].is_default;
    }

    userObject.settings.report[projectType].charts[
      appConf.schemas[projectType].version
    ][newPresetKey] = {
      name: title,
      charts: chartsList,
    };

    if (prevDefaultStatus) {
      userObject.settings.report[projectType].charts[
        appConf.schemas[projectType].version
      ][newPresetKey].is_default = prevDefaultStatus;
    }

    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      if (status === 200) {
        loadUser(userObject);
        navigate("/settings");
      } else {
        enqueueSnackbar(data, { variant: "error" });
      }
    });
  };

  if (loading || !helpInformation) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Dashboard Settings")}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.pageTitle}
                component="h3"
                variant="h3"
              >
                {t("Statistics charts preset")}
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <form className={classes.form} onSubmit={submitHandler}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={t("Preset Name")}
                  name="preset-name"
                  required
                  disabled={isEditMode}
                  className={classes.inputField}
                  value={title}
                  onChange={titleChangeHandler}
                  style={{ marginBottom: "15px" }}
                />
                {Object.keys(chartOptions).map((chartKey, index) => {
                  return (
                    <FormGroup row key={index}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={chartOptions[chartKey]}
                            onChange={handleChange}
                            name={chartKey}
                          />
                        }
                        label={helpInformation[chartKey].label}
                      />
                    </FormGroup>
                  );
                })}

                <Box display="flex" pb={6}>
                  <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "auto" }}
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
};

export const ChartsConfigPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedChartsConfigPage);

const ConnectedChartsConfigPageWrapper = ({ appConf }) => {
  const [schema, setSchema] = useState([]);
  const classes = useStyles();
  const projectType =
    new URLSearchParams(useLocation().search).get("project_type") ??
    "exome_fastq";
  useEffect(() => {
    if (appConf.schemas[projectType]) {
      const schemaIds = appConf.schemas[projectType];
      schemaIds.forEach(async (schemaId, index) => {
        await apiTableGetSchema(schemaId, (cols, status) => {
          setSchema((prev) => [...prev, cols]);
        });
      });
    }
  }, []);
  if (!schema.length) return <CircularProgress className={classes.spinner} />;
  return <ChartsConfigPage schema={schema} />;
};

export const ChartsConfigPageWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedChartsConfigPageWrapper);
