/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

function getColor(d, a1, a2) {
  return a1.map((p, i) => Math.floor(a1[i] + d * (a2[i] - a1[i])));
}

const asc = (arr) => arr.sort((a, b) => a - b);

const IndelLenChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const [isBackgroundSet, setIsBackgroundSet] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["indel_len"].download_address)
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n");
          const axisLabels = rawData[0];
          rawData = rawData.slice(1);
          const labels = []; // Indel
          const data = []; // Length
          // const backgroundColor = [];
          // const hoverBackgroundColor = [];
          for (let i = 0; i < rawData.length; i++) {
            const row = rawData[i].split("\t");
            labels.push(row[0]);
            data.push(row[1]);
            // backgroundColor.push(
            //   colors[materialColors[i % materialColors.length]][300]
            // );
            // hoverBackgroundColor.push(
            //   colors[materialColors[i % materialColors.length]][500]
            // );
          }

          if (mounted) {
            setSampleData({
              labels: labels,
              datasets: [
                {
                  label: axisLabels.split("\t")[0],
                  data: data,
                  // backgroundColor: backgroundColor,
                  // hoverBackgroundColor: hoverBackgroundColor,
                },
              ],
            });
          }
        });
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSampleData({ labels: [], data: [] });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (sampleData && !isBackgroundSet) {
      const backgroundColor = [];
      const hoverBackgroundColor = [];
      const data = sampleData.datasets[0].data;
      const sorted = asc(data.slice(0));
      for (let i = 0; i < data.length; i++) {
        const dataIndex = sorted.indexOf(data[i]);
        let colorOpacity = dataIndex / sorted.length;

        // colorOpacity = rangeMap(colorOpacity, 0, 1, 0.4, 0.9);
        // const hoverColorOpacity = parseFloat(colorOpacity) + 0.1;

        // backgroundColor.push(`rgba(183, 28, 28, ${colorOpacity})`);
        // hoverBackgroundColor.push(`rgba(183, 28, 28, ${hoverColorOpacity})`);

        const barColor = getColor(colorOpacity, [255, 193, 7], [244, 67, 54]);

        backgroundColor.push(`rgba(${barColor.join(",")}, 0.7)`);
        hoverBackgroundColor.push(`rgba(${barColor.join(",")}, 1)`);
      }

      sampleData.datasets[0].backgroundColor = backgroundColor;
      sampleData.datasets[0].hoverBackgroundColor = hoverBackgroundColor;
      setIsBackgroundSet(true);
      setSampleData({ ...sampleData });
    }
  }, [sampleData]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      text: t("Indel Length"),
    },
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: function (context) {
          return t("Count") + ": " + context.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t("Count"),
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t("Indel length"),
          },
        },
      ],
    },
    // legend: {
    //   position: "left",
    //   labels: {
    //     boxWidth: 10,
    //   },
    // },
  };

  if (!sampleData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <Bar height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default IndelLenChart;
