import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    "& .protvista-loader": {
      animation: `$tableRow 1000ms ${theme.transitions.easing.easeOut} infinite`,
    },
    "& .track-label": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "white",
    },
    "& .odd": {
      "&:hover": {
        backgroundColor: `${theme.palette.background.paper} !important`,
      },
      backgroundColor: theme.palette.background.default,
    },
    "& .even": {
      "&:hover": {
        backgroundColor: `${theme.palette.background.default} !important`,
      },
      backgroundColor: theme.palette.background.paper,
    },
    "& .category-label": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "white",
      position: "relative",
      paddingLeft: theme.spacing(3),
      "&.open::before": {
        content: "'▼'",
        border: 0,
        position: "absolute",
        left: theme.spacing(1),
      },
      "&::before": {
        content: "'▶'",
        border: 0,
        position: "absolute",
        left: theme.spacing(1),
      },
    },
    "& .category__track": {
      "& .track-label": {
        paddingLeft: theme.spacing(6),
      },
    },
    "& .protvista-uniprot-structure__structure": {
      zIndex: 0,
      marginBottom: theme.spacing(6),
    },
    "& .tooltip-body": {
      p: {
        color: "white !important",
      },
    },
    "& tr": {
      "& th": {
        backgroundColor: `${theme.palette.background.paper} !important`,
        color: theme.palette.text.primary,
      },
      "& .filter-wrap": {
        display: "flex",
        transition: "1s ease all",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "&:hover": {
          "& select": {
            opacity: 1,
            filter: "alpha(opacity=100)",
          },
        },
        "&::before": {
          content: '"▼"',
          left: "10%",
          top: 0,
          position: "absolute",
          display: "inline-block",
          width: 0,
          height: 0,
        },
        "& select": {
          opacity: 0.5,
          filter: "alpha(opacity=40)",
          transition: "0.2s ease all",
          padding: theme.spacing(1),
          borderRadius: theme.spacing(1),
        },
      },
    },
  },
  "@keyframes tableRow": {
    "0%": {
      opacity: 0.1,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.1,
    },
  },
}));

function Protvista({ uniprot = "Q7Z5J4" }) {
  const ref = useRef();
  const [update, setUpdate] = useState(1);
  const classes = useStyles();

  useEffect(() => {
    setUpdate(0);
    setTimeout(() => setUpdate(1), 500);
  }, [uniprot]);

  return (
    <Box padding={2} className={classes.root}>
      {update === 1 ? (
        <protvista-uniprot ref={ref} accession={uniprot} />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={800}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default Protvista;
