import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Link, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },
}));

export function Copyright() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link
        className={classes.link}
        color="inherit"
        href="https://massivebioinformatics.com/"
      >
        Massive Bioinformatics
      </Link>{" "}
      {new Date().getFullYear()}
      {". "}
      <Link
        className={classes.link}
        color="inherit"
        href="https://massivebioinformatics.com/wp-content/uploads/2021/08/Massive-ArGe-Aydinlatma-Metni.pdf"
      >
        {t("Privacy Policy")}
      </Link>
    </Typography>
  );
}
