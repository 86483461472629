import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdNewLabel } from "react-icons/md";
import Header from "../table/header";
import { useNavigate } from "react-router-dom";
import MediaCard from "./card";
import Footer from "../table/footer";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  paper: {
    position: "relative",
    height: "100%",
    minHeight: 300,
    backgroundColor: theme.palette.background.secondBackground,
    color: theme.palette.text.primary,
    border: ".5px solid rgba(0,0,0,0.1)",
    overflow: "hidden",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    borderRadius: 10,
  },
  card: {
    width: "100%",
    "& .MuiCardHeader-root": {
      padding: 6,
    },
    "& .MuiCardContent-root:last-child": {
      //   padding: 0,
    },
  },
  articleMedia: {
    // width: "100%",
    width: "20%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      height: "200px",
    },
  },
  box: {
    width: "100%",
  },
}));

function Announcment({ values, title, url }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/articles/${id}`);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={classes.paper}>
          <Grid item xs={12}>
            <Header title={t(title)} icon={<MdNewLabel />} />
          </Grid>
          {values?.items?.length ? (
            values.items.map((value, key) => {
              return (
                <Box
                  key={key}
                  onClick={() => handleClick(value.id)}
                  className={classes.box}
                >
                  <MediaCard value={value} />
                </Box>
              );
            })
          ) : (
            <CircularProgress className={classes.spinner} />
          )}
          <Footer url={url} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Announcment;
