import { authConf, urlAPIConf, getBaseURL } from "src/config/api";
import axios from "axios";
import { createBrowserHistory } from "history";
import safeLogger from "src/services/safeLogger";

const history = createBrowserHistory();

const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const noIntercept = function (status) {
  return true;
};

export const setAuthToken = (token, callback) => {
  if (!axios.defaults.headers.common[authConf.AUTH_HEADER]) {
    axios.defaults.headers.common[authConf.AUTH_HEADER] =
      authConf.AUTH_TOKEN_PREFIX + token;
  }
  if (callback) callback();
};

export const removeAuthToken = (callback) => {
  if (axios.defaults.headers.common[authConf.AUTH_HEADER]) {
    delete axios.defaults.headers.common[authConf.AUTH_HEADER];
    localStorage.removeItem(authConf.LOCAL_STORAGE_TOKEN_NAME);
  }
  if (callback) callback();
};

const authToken = localStorage.getItem(authConf.LOCAL_STORAGE_TOKEN_NAME);

if (authToken) {
  if (!axios.defaults.headers.common[authConf.AUTH_HEADER])
    setAuthToken(authToken);
} else {
  removeAuthToken();
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (process.env.REACT_APP_ENV === "local") {
      safeLogger(error.config);
      safeLogger(error);
      return Promise.reject(error);
    }
    // const originalRequest = error.config;
    if (typeof error.response === "undefined") {
      window.location.href = "/neterror";
      safeLogger("undefined error");
      return;
    }
    if (error.response.status === 503) {
      // return <Navigate to="/404" replace={true} />;
      // window.location.href = "/404";
      history.replace("/503");
      window.location.reload();
      return;
    }
    if (error.response.status === 404) {
      // return <Navigate to="/404" replace={true} />;
      // window.location.href = "/404";
      history.replace("/404");
      window.location.reload();
      return;
    }
    if (error.response.status === 403) {
      // window.location.href = "/403";
      history.replace("/403");
      window.location.reload();
      return;
    }
    if (error.response.status === 400) {
      // window.location.href = "/400";
      history.replace("/400");
      window.location.reload();
      return;
    }
    if (error.response.status === 401) {
      // && !originalRequest._retry
      // originalRequest._retry = true
      removeAuthToken();
      localStorage.removeItem("mbiAuth");
      // return <Navigate to="/signin" />;
      // window.location.href = "/signin";
      history.replace("/signin");
      window.location.reload();
      return;
    }
    if (error.response.status === 500) {
      // return <Navigate to="/500" />;
      // window.location.href = "/500";
      safeLogger("Network Err");
      history.replace("/500");
      window.location.reload();
      return;
    }
    if (error.response.status === 503) {
      // window.location.href = "/503";
      // history.replace("/503");
      history.replace("/maintenance");
      window.location.reload();
      return;
    }
    if (error.response.status === 504) {
      // window.location.href = "/504";
      history.replace("/504");
      window.location.reload();
      return;
    }
    return Promise.reject(error);
  },
);

export const apiLoadAppConf = async (callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.APP_CONFIG_SLUG;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    safeLogger(e);
  }
};

export const apiLoadProfile = async (activeOrganizationId, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.PROFILE_SLUG
  }${activeOrganizationId ? "?organization_id=" + activeOrganizationId : ""}`;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    safeLogger(e);
  }
};

export const apiLoadOrganizationKits = async (
  activeOrganizationId,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${activeOrganizationId}/${urlAPIConf.KITS_SLUG}`;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    safeLogger(e);
  }
};

export const apiUpdateProfile = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROFILE_SLUG;

  try {
    const response = await axios.patch(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    safeLogger(e);
  }
};

export const apiLoadDashboard = async (organization_id, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.DASHBOARD_SLUG
  }?organization_id=${organization_id}`;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiSignIn = async (data, callback) => {
  const url =
    getBaseURL() + "/" + urlAPIConf.BASE_API_SLUG + "/" + urlAPIConf.LOGIN_SLUG;
  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    callback(e);
  }
};

export const sessionUnlock = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.UNLOCK_SLUG;
  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    callback(e);
  }
};

export const sessionLock = async (callback) => {
  const url =
    getBaseURL() + "/" + urlAPIConf.BASE_API_SLUG + "/" + urlAPIConf.LOCK_SLUG;
  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: defaultHeaders,
        validateStatus: noIntercept,
      },
    );
    callback(response.data, response.status);
  } catch (e) {
    callback(e);
  }
};

export const apiSignOut = async (callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.LOGOUT_SLUG;
  try {
    const response = await axios.post(url, {}, { headers: defaultHeaders });
    callback(response.data, response.status);
    if (axios.defaults.headers.common[authConf.AUTH_HEADER])
      delete axios.defaults.headers.common[authConf.AUTH_HEADER];
  } catch (e) {
    safeLogger(e);
  }
};

export const apiChangePassword = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.CHANGE_PASSWORD_SLUG;

  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    callback(e);
  }
};

export const apiResetPassword = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.RESET_PASSWORD_SLUG;

  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    callback(e);
  }
};

export const apiMarkNotificationsRead = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.NOTIFICATIONS_SLUG;

  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    callback(e);
  }
};

export const apiLoadMoreNotifications = async (id, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.NOTIFICATIONS_SLUG +
    `?id=${id}`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    callback(e);
  }
};

// export const apiLoadVariant = async (sampleId, callback) => {
//   const url = testAPIConf.VARIANT_DATA_MOCK_URL;

//   try {
//     const response = await axios.get(url, {
//       headers: { responseType: "text/plain", timeout: 20000 },
//     });
//     if (response.status === 200) {
//       callback(response.data, response.status);
//     }
//   } catch (e) {
//     safeLogger(e);
//   }
// };

// export const apiLoadVariantDetails = async (variandId, callback) => {
//   const url = testAPIConf.VARIANT_DETAILS_MOCK_URL;

//   try {
//     const response = await axios.get(url, {
//       headers: { responseType: "text/plain", timeout: 20000 },
//     });
//     if (response.status === 200) {
//       callback(response.data, response.status);
//     }
//   } catch (e) {
//     safeLogger(e);
//   }
// }; // TODO check (duplicate)

export const apiLoadReportDetails = async (reportId, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.REPORTS_SLUG +
    "/" +
    reportId;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    safeLogger(e);
  }
};

export const apiLoadReportVariantData = async (
  reportId,
  organization,
  pageOffset,
  pageSize,
  body,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.REPORTS_SLUG +
    "/" +
    reportId +
    "/" +
    urlAPIConf.VARIANT_QUERY_SLUG +
    "?page=" +
    pageOffset +
    "&page_size=" +
    pageSize +
    "&organization=" +
    organization;
  try {
    const response = await axios.post(url, body, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiLoadFilteredRowIds = async (reportId, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.REPORTS_SLUG +
    "/" +
    reportId +
    "/" +
    urlAPIConf.VARIANT_IDENTIFY_FILTERED_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiSendVariantAnnotation = async (reportId, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.REPORTS_SLUG +
    "/" +
    reportId +
    "/" +
    urlAPIConf.VARIANT_ANNOTATE_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 202) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
}; // TODO going to change in backend

export const apiSendVariantRows = async (reportId, data, callback) => {
  // TODO rename -> generate report
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.REPORTS_SLUG +
    "/" +
    reportId +
    "/" +
    urlAPIConf.VARIANT_GENERATE_REPORT_SLUG;

  safeLogger(data);
  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiListProjects = async (
  organization_id,
  page,
  callback,
  pageSize = 10,
  sortState,
  filterState,
  search,
  projectType,
) => {
  let filter = Object.entries(filterState)
    .map((filter) => {
      let column = filter[0];
      let value = Object.entries(filter[1])[0][0];
      return `${column}=${value}`;
    })
    .join("&");
  if (!Object.entries(filterState).length) filter = "";
  const sort = Object.entries(sortState)[0];
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.PROJECTS_SLUG
  }?organization_id=${organization_id}&page=${page}&page_size=${pageSize}${
    projectType ? `&project_type=${projectType}` : ""
  }${filter !== "" ? `&${filter}` : ""}${
    sort ? `&order=${sort[0]}&direction=${sort[1]}` : ""
  }${search ? `&name=${search}` : ""}`;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    safeLogger(e);
  }
};

export const apiReportsDetailsPagination = async (
  id,
  page,
  callback,
  pageSize = 5,
  sort,
  filter,
) => {
  const porcessedSort = Object.entries(sort)[0];
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    id +
    "/reports" +
    `?page=${page}&page_size=${pageSize}` +
    `${
      porcessedSort
        ? `&order=${porcessedSort[0]}&direction=${porcessedSort[1]}`
        : ""
    }${filter ? `&name=${filter}` : ""}`;
  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiProjectDetails = async (id, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    id;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiReportAnnotationStatusUpdate = async (id, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.REPORTS_SLUG +
    "/" +
    id +
    "/" +
    urlAPIConf.ANNOTATION_STATUS_UPDATE;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 202) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiAnnotationStatus = async (id, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.ANNOTATION_STATUS +
    `?organization_id=${id}`;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiUpdateAnnotationStatusOrdinal = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.ANNOTATION_STATUS +
    "/" +
    urlAPIConf.UPDATE_ANNOTATION_STATUS;

  try {
    const response = await axios.put(url, data, { headers: defaultHeaders });
    if (response.status === 202) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiDeleteAnnotationStatus = async (
  organizationId,
  id,
  newId,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.ORGANIZATION_SLUG +
    "/" +
    organizationId +
    "/" +
    urlAPIConf.REPORT_STATUS_SLUG +
    "?id=" +
    id +
    "&new_status_id=" +
    newId;
  try {
    const response = await axios.delete(url, { headers: defaultHeaders });
    if (response.status === 204) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiUpdateAnnotationStatus = async (
  organization_id,
  id,
  data,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.ORGANIZATION_SLUG +
    "/" +
    organization_id +
    "/" +
    urlAPIConf.REPORT_STATUS_SLUG +
    `?id=${id}`;

  try {
    const response = await axios.patch(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiUpdateDefaultAnnotationStatus = async (
  organization_id,
  data,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.ORGANIZATION_SLUG +
    "/" +
    organization_id;

  try {
    const response = await axios.patch(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiCreateAnnotationStatus = async (id, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.ORGANIZATION_SLUG +
    "/" +
    id +
    "/" +
    urlAPIConf.REPORT_STATUS_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiCreateProject = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiSelectExistingSamples = async (projectId, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    projectId +
    "/" +
    urlAPIConf.PROJECT_SELECT_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiListAttachedStorage = async (callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECT_ATTACH_SLUG;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiGetAttachedStorageContent = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECT_ATTACH_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiAttachStorageSubmit = async (projectId, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    projectId +
    "/" +
    urlAPIConf.PROJECT_ATTACH_SUBMIT_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiGetAttachStorageProgress = async (projectId, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    projectId +
    "/" +
    urlAPIConf.PROJECT_ATTACH_SUBMIT_SLUG;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiSubmitProject = async (projectId, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    projectId +
    "/" +
    urlAPIConf.PROJECT_SUBMIT_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiReRunProject = async (
  projectId,
  samples,
  payment_id,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    projectId +
    "/" +
    urlAPIConf.PROJECT_RERUN_SLUG;

  try {
    const response = await axios.post(
      url,
      samples ? { samples, payment_id } : { samples: [], payment_id },
      {
        headers: defaultHeaders,
      },
    );
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiCancelProject = async (projectId, sampleId, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    projectId +
    "/" +
    urlAPIConf.PROJECT_CANCEL_SLUG;

  const bodyData = sampleId !== null ? { sample: sampleId } : {};

  try {
    const response = await axios.post(url, bodyData, {
      headers: defaultHeaders,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiSearch = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.SEARCH_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiLoadSetting = async (param, callback) => {
  let url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.SETTING_SLUG;
  if (param) {
    url = url + "/" + param;
  }

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const exomebasePercent = async (fileUrl, callback) => {
  // const url = getBaseURL() + "/" + urlAPIConf.BASE_API_SLUG + "/" + fileUrl;
  try {
    const response = await axios.get(fileUrl, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiPostSetting = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.SETTING_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiGetTableConf = async (
  projectType,
  organization_id,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG +
    "?project_type=" +
    projectType +
    "&organization_id=" +
    organization_id;
  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiTableConfDetails = async (id, projectType, callback) => {
  let url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG +
    "/" +
    id;

  if (["acnv", "trio"].includes(projectType)) {
    url = url + "?project_type=" + projectType;
  }

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiTableGetSchema = async (id, callback) => {
  let url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.SCHEMA_SLUG +
    "/" +
    id;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiPostTableConf = async (data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG;
  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 200 || response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiUpdateTableConf = async (id, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG +
    "/" +
    id;

  try {
    const response = await axios.patch(url, data, { headers: defaultHeaders });
    if (response.status === 200 || response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiPartialUpdateTableConf = async (id, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG +
    "/" +
    id;

  try {
    const response = await axios.patch(url, data, { headers: defaultHeaders });
    if (response.status === 200 || response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiPublishFilter = async (id, activeOrganization, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG +
    "/" +
    id +
    "/" +
    urlAPIConf.PUBLISH_SLUG +
    "?organization_id=" +
    activeOrganization;

  try {
    const response = await axios.post(url, {}, { headers: defaultHeaders });
    if (response.status === 200 || response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiUnPublishFilter = async (id, activeOrganization, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG +
    "/" +
    id +
    "/" +
    urlAPIConf.UN_PUBLISH_SLUG +
    "?organization_id=" +
    activeOrganization;

  try {
    const response = await axios.post(url, {}, { headers: defaultHeaders });
    if (response.status === 200 || response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiDeleteTableConf = async (id, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG +
    "/" +
    id;

  try {
    const response = await axios.delete(url, { headers: defaultHeaders });
    if (response.status === 204) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiLoadSchemaColumnUniqueValues = async (
  schemaId,
  columnName,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.FILTERS_SLUG +
    "/" +
    urlAPIConf.FILTERS_IDENTIFY_SLUG;

  try {
    const response = await axios.post(
      url,
      { schema_id: schemaId, column_name: columnName },
      { headers: defaultHeaders },
    );
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    callback(e);
  }
};

export const apiListVariantAnnotationsComments = async (
  reportId,
  rowId,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.REPORTS_SLUG +
    "/" +
    reportId +
    "/" +
    urlAPIConf.COMMENT_SLUG +
    "?row_id=" +
    rowId;

  try {
    const response = await axios.get(url, { headers: defaultHeaders });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    safeLogger(e);
  }
};

export const apiPostVariantComment = async (reportId, data, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.REPORTS_SLUG +
    "/" +
    reportId +
    "/" +
    urlAPIConf.COMMENT_SLUG;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiPostComment = async (
  id,
  activeOrganizationId,
  data,
  callback,
) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.PROJECTS_SLUG +
    "/" +
    id +
    "/" +
    urlAPIConf.COMMENT_SLUG +
    "?organization_id=" +
    activeOrganizationId;

  try {
    const response = await axios.post(url, data, { headers: defaultHeaders });
    if (response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiPostError = async (data, callback) => {
  const url =
    getBaseURL() + "/" + urlAPIConf.BASE_API_SLUG + "/" + urlAPIConf.ERROR_SLUG;

  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiAddToSystemStatusChoices = async (id, callback) => {
  const url =
    getBaseURL() +
    "/" +
    urlAPIConf.BASE_API_SLUG +
    "/" +
    urlAPIConf.ORGANIZATION_SLUG +
    "/" +
    id +
    "/" +
    urlAPIConf.CUSTOMISE_REPORT_STATUSES;

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: defaultHeaders,
        validateStatus: noIntercept,
      },
    );
    if (response.status === 201) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiGetOrganizationRedux = async (id, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${id}/anonymous-information`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiGetOrganizationDetails = async (id, callback) => {
  let activeOrganization = id;
  if (!activeOrganization) {
    activeOrganization = localStorage.getItem("activeOrganizationId");
  }
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${activeOrganization}`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiGetOrganizationMembers = async (id, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${id}/${urlAPIConf.ORGANIZATION_MEMBERS_SLUG}`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiCreateTeam = async (organization_id, data, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${urlAPIConf.ORGANIZATION_TEAM_SLUG}`;

  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiGetTeamDetails = async (organization_id, team_id, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${urlAPIConf.ORGANIZATION_TEAM_SLUG}?id=${team_id}`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiUpdateTeamDetails = async (
  organization_id,
  team_id,
  data,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${urlAPIConf.ORGANIZATION_TEAM_SLUG}?id=${team_id}`;

  try {
    const response = await axios.patch(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiDeleteTeam = async (organization_id, team_id, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${urlAPIConf.ORGANIZATION_TEAM_SLUG}?id=${team_id}`;

  try {
    const response = await axios.delete(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 204) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiCreateDepartment = async (organization_id, data, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${urlAPIConf.ORGANIZATION_DEPARTMENT_SLUG}`;

  try {
    const response = await axios.post(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiGetDepartmentDetails = async (
  organization_id,
  department_id,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${
    urlAPIConf.ORGANIZATION_DEPARTMENT_SLUG
  }?id=${department_id}`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiUpdateDepartmentDetails = async (
  organization_id,
  department_id,
  data,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${
    urlAPIConf.ORGANIZATION_DEPARTMENT_SLUG
  }?id=${department_id}`;

  try {
    const response = await axios.patch(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiDeleteDepartment = async (
  organization_id,
  department_id,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${
    urlAPIConf.ORGANIZATION_DEPARTMENT_SLUG
  }?id=${department_id}`;

  try {
    const response = await axios.delete(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 204) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiOrganizationAddMembers = async (
  organization_id,
  type,
  entity_id,
  members,
  forcePermission,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${urlAPIConf.ORGANIZATION_MEMBERS_SLUG}`;

  const body = {
    members: members,
    force_permissions: forcePermission,
  };

  body[`${type}_id`] = entity_id;

  try {
    const response = await axios.post(url, body, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiOrganizationRemoveMembers = async (
  organization_id,
  type,
  entity_id,
  members,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${urlAPIConf.ORGANIZATION_MEMBERS_SLUG}`;

  const body = {
    members: members,
  };

  body[`${type}_id`] = entity_id;

  try {
    const response = await axios.delete(url, {
      data: body,
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apitOrganizationSamplesCounts = async (
  organization_id,
  start_date,
  end_date,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_RUNNED_SAMPLES_SLUG
  }?organization_id=${organization_id}${
    start_date ? `&start_date=${start_date}` : ``
  }${end_date ? `&end_date=${end_date}` : ``}`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiRelatedProjects = async (gene, id, column, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.REPORTS_SLUG
  }/${id}/${urlAPIConf.CORRELATE}?column=${column}&value=${gene}`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    if (response.status === 200) {
      callback(response.data, response.status);
    }
  } catch (e) {
    // callback(e);
  }
};

export const apiVariantTagCreate = async (
  organization_id,
  variant_id,
  report_id,
  user_id,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.VARIANT_TAG
  }`;

  const body = {
    organization: organization_id,
    variant_id: variant_id,
    report: report_id,
    created_by: user_id,
  };

  try {
    const response = await axios.post(url, body, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiNanoporeForm = async (id, data, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.REPORTS_SLUG
  }/${id}`;

  try {
    const response = await axios.put(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiDiagnoseAnalysisGet = async (id, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.DIAGNOSE_ANALYSIS_SLUG
  }/${id}`;

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiVariantTagDelete = async (id, data, callback) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.VARIANT_TAG
  }/${id}`;

  try {
    const response = await axios.delete(url, data, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiOrganizationPermissions = async (
  organization_id,
  type,
  entity_id,
  permissions,
  forcePermissions,
  callback,
) => {
  const url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${organization_id}/${urlAPIConf.ORGANIZATION_PERMISSIONS_SLUG}`;

  const body = {
    permissions: permissions,
    force_permissions: forcePermissions,
  };

  body[`${type}_id`] = entity_id;

  try {
    const response = await axios.post(url, body, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiTransactions = async (start_date, end_date, callback) => {
  const activeOrganization = localStorage.getItem("activeOrganizationId");
  let url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${activeOrganization}/${urlAPIConf.TRANSACTIONS}`;

  if (start_date || end_date) {
    url += `?`;
  }

  if (start_date) {
    url += `start_date=${start_date}${end_date && "&"}`;
  }

  if (end_date) {
    url += `end_date=${end_date}`;
  }

  try {
    const response = await axios.get(url, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiProjectQuotePrice = async (projectId, body, callback) => {
  const activeOrganization = localStorage.getItem("activeOrganizationId");
  let url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${activeOrganization}/${urlAPIConf.QUOTE_PRICE}`;

  try {
    const response = await axios.post(url, body, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiAcquirePayment = async (body, callback) => {
  const activeOrganization = localStorage.getItem("activeOrganizationId");
  let url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${activeOrganization}/${urlAPIConf.ACQUIRE_PAYMENT}`;

  try {
    const response = await axios.post(url, body, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiTopupCredit = async (credit, callback) => {
  const activeOrganization = localStorage.getItem("activeOrganizationId");
  let url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${
    urlAPIConf.ORGANIZATION_SLUG
  }/${activeOrganization}/${urlAPIConf.TOP_UP}`;

  try {
    const response = await axios.post(url, credit, {
      headers: defaultHeaders,
      validateStatus: noIntercept,
    });
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiCustomReport = async (
  reportId,
  rows,
  extra_fields,
  callback
) => {
  let url = `${getBaseURL()}/${urlAPIConf.BASE_API_SLUG}/${urlAPIConf.REPORTS_SLUG}/${reportId}/${urlAPIConf.VARIANT_GENERATE_REPORT_SLUG}`;
  try {
    const response = await axios.post(
      url,
      {
        rows,
        extra_fields
      },
      {
        headers: defaultHeaders,
        validateStatus: noIntercept,
      }
    );
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};

export const apiGetReport = async (
  downloadAddress,
  callback
) => {
  let url = `${getBaseURL()}${downloadAddress}`;
  try {
    const response = await axios.get(
      url,
      {
        headers: defaultHeaders,
        validateStatus: noIntercept,
        responseType: 'blob'
      }
    );
    callback(response.data, response.status);
  } catch (e) {
    if (e.response) {
      callback(e.response.statusText, e.response.status);
    } else {
      callback(e);
    }
  }
};
