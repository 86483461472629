import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    cursor: "pointer",
    width: "fit-content",
    alignItems: "center",
    gap: theme.spacing(1),
    transition: ".4s ease all",
    opacity: (props) => (props.value[1].isActive ? 1 : 0.5),
    "&:hover": {
      opacity: 0.5,
    },
    textDecoration: (props) =>
      props.value[1].isActive ? "none" : "line-through",
  },
  lineTrought: {
    position: "relative",
    transition: "1s ease all",
    "&:before": {
      content: '""',
      transition: ".4s ease all",
      position: "absolute",
      top: "50%",
      transform: "translate(0,-50%)",
      left: 0,
      width: (props) => (props.value[1].isActive ? 0 : "100%"),
      height: 1,
      backgroundColor: theme.palette.text.primary,
    },
    "&:hover": {
      opacity: 0.5,
      "&, &:before,": {
        width: "100%",
      },
    },
  },
  leftColor: {
    width: 10,
    height: 20,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: (props) => props.value[1].color,
  },
}));

function RelatedGenes({ value, onClick }) {
  const classes = useStyles({ value });
  const { t } = useTranslation();
  return (
    <Box className={classes.root} onClick={onClick}>
      {value[1].color && <Box className={classes.leftColor} />}
      <Box width="fit-content">
        <Typography className={classes.lineTrought}>
          {value[0] === "-" ? t("Empty") : value[0]}
        </Typography>
      </Box>
    </Box>
  );
}

export default RelatedGenes;
