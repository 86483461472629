/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const MappedReadsClippingProfileLineChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files, tagSuffix } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    // const re = RegExp("mapped_reads_clipping_profile(-mother|-father|-child)?");
    // const fileTag = Object.keys(files).find((tag) => re.test(tag));
    const fileTagSuffix = tagSuffix ? "-" + tagSuffix : "";
    try {
      fetch(
        getBaseURL() +
          files["mapped_reads_clipping_profile" + fileTagSuffix]
            .download_address
      )
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n");
          rawData = rawData.slice(1);
          const labels = []; // Read position (bp)
          const data = []; // Clipped bases (%)
          for (let i = 0; i < rawData.length; i++) {
            const row = rawData[i].split("\t");
            if (isNaN(parseFloat(row[0]))) {
              continue;
            }
            labels.push(parseFloat(row[0]));
            data.push(row[1]);
          }

          if (mounted) {
            setSampleData({
              labels: labels,
              datasets: [
                {
                  pointRadius: 0,
                  label: t("Sample"),
                  data: data,
                  backgroundColor: "rgba(239, 83, 80, 0.3)",
                  borderColor: colors[materialColors[0]][400],
                },
              ],
            });
          }
        });
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSampleData({ labels: [], datasets: [] });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const options = {
    // elements: {
    //   line: {
    //     tension: 0,
    //   },
    // },
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      text: t("Mapped reads clipping profile"),
    },
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
            stepSiz: 5,
          },
          scaleLabel: {
            display: true,
            labelString: t("Clipped bases (%)"),
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t("Read position (bp)"),
          },
        },
      ],
    },
  };

  if (!sampleData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <Line height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default MappedReadsClippingProfileLineChart;
