import React, { useState, useEffect } from "react";
import { apiResetPassword } from "src/api";
import grey from "@material-ui/core/colors/grey";
import { Copyright } from "../copyright";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CheckCircle as CheckCircleIcon,
  Lock as LockIcon,
} from "@material-ui/icons";
import {
  CssBaseline,
  Grid,
  Container,
  Box,
  Button,
  TextField,
  CircularProgress,
  makeStyles,
  Typography,
  colors,
  LinearProgress,
  InputAdornment,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "src/config";
import { useRef } from "react";

const MIN_PASSWORD_LENGTH = 8;

const PASSWORD_STRENGTH_BAR_CONF = {
  1: { label: "Very weak", value: 20, color: "red" },
  2: { label: "Weak", value: 40, color: "orange" },
  3: { label: "Moderate", value: 60, color: "yellow" },
  4: { label: "Strong", value: 80, color: "lightGreen" },
  5: { label: "Very strong", value: 100, color: "green" },
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100vw",
    minHeight: `calc(100vh - 64px)`,
    display: "flex",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  errorBox: {
    display: "flex",
    padding: "13px 22px",
    border: "1px solid lightcoral",
    background: "lightpink",
    borderRadius: "8px",
    color: "#414141",
  },
  form: {
    minWidth: "250px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resetForm: {
    color: grey[600],
    maxWidth: "520px",
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    borderRadius: "1.5em",
  },
  passwordMeter: {
    gap: "10px",
    "& .label": {
      flexBasis: "150px",
    },
    "& .MuiLinearProgress-root": {
      width: "100%",
    },
    "& .red .MuiLinearProgress-bar": {
      backgroundColor: colors["red"][500],
    },
    "& .orange .MuiLinearProgress-bar": {
      backgroundColor: colors["orange"][500],
    },
    "& .yellow .MuiLinearProgress-bar": {
      backgroundColor: colors["yellow"][500],
    },
    "& .lightGreen .MuiLinearProgress-bar": {
      backgroundColor: colors["lightGreen"][500],
    },
    "& .green .MuiLinearProgress-bar": {
      backgroundColor: colors["green"][500],
    },
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PasswordStrengthMeter = (props) => {
  const classes = useStyles();
  const { passwordStrength } = props;
  const { t } = useTranslation();
  return (
    <Box className={classes.passwordMeter} display="flex" alignItems="center">
      <span className="label">
        {t(PASSWORD_STRENGTH_BAR_CONF[passwordStrength].label)}
      </span>
      <LinearProgress
        className={PASSWORD_STRENGTH_BAR_CONF[passwordStrength].color}
        variant="determinate"
        value={PASSWORD_STRENGTH_BAR_CONF[passwordStrength].value}
      />
    </Box>
  );
};

const ResetPasswordPage = (props) => {
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(undefined);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [reCAPTCHAValue, setReCAPTCHAValue] = useState(undefined);
  const [newData, setNewData] = useState({
    new: undefined,
    confirm: undefined,
  });
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const tk = searchParams.get("token");
    if (tk) {
      setToken(searchParams.get("token"));
    } else {
      navigate("/403");
    }
  }, [navigate, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkStrength = (event) => {
    const password = event.target.value;
    setNewData({ ...newData, new: password });

    let hasUpperCase = /[A-Z]/.test(password);
    let hasLowerCase = /[a-z]/.test(password);
    let hasNumbers = /\d/.test(password);
    let hasNonalphas = /\W/.test(password);

    setPasswordStrength(
      hasUpperCase +
      hasLowerCase +
      hasNumbers +
      hasNonalphas +
      (password.length > 12)
    );
  };

  useEffect(() => {
    setPasswordMatch(
      newData.new && newData.confirm && newData.new === newData.confirm
    );
  }, [newData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!reCAPTCHAValue) {
      enqueueSnackbar(t("Please check the reCAPTCHA box."), {
        variant: "error",
      });
      return;
    }
    const formElements = event.target.elements;

    const password = formElements["new-password"].value;

    if (password.length < MIN_PASSWORD_LENGTH) {
      enqueueSnackbar(
        t(
          "password_length_error",
          "Passwords must be at least {{min_length}} characters long.",
          {
            min_length: MIN_PASSWORD_LENGTH,
          }
        ),
        {
          variant: "error",
        }
      );
      return;
    }

    if (passwordStrength < 3) {
      enqueueSnackbar(
        t(
          "The password is not strong enought. Please try with a stronger password."
        ),
        {
          variant: "error",
        }
      );
      return;
    }

    const data = {
      action: "set-password",
      "new-password": formElements["new-password"].value,
      "confirm-password": formElements["confirm-password"].value,
      token: token,
      recaptcha: reCAPTCHAValue,
    };
    setLoading(true);

    apiResetPassword(data, (data, status) => {
      if (status === 202) {
        enqueueSnackbar(t("Your password was successfully changed."), {
          variant: "success",
        });
        navigate("/signin");
      } else {
        enqueueSnackbar(t("Something went wrong. Please try again later."), {
          variant: "error",
        });
      }
      setLoading(false);
    });
  };

  return (
    <main>
      <Box
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Container>
          <CssBaseline />
          {token ? (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Box display="flex" width="100%" justifyContent="center">
                    <Box className={classes.resetForm}>
                      <LockIcon
                        style={{ fontSize: "3rem", marginBottom: "4px" }}
                      />
                      <Typography
                        component="h1"
                        variant="h5"
                        align="center"
                        style={{ marginBottom: "8px" }}
                      >
                        {t("RESET PASSWORD")}
                      </Typography>
                      <Typography variant="body1">
                        {t("Fill in the form bellow to reset your password.")}
                      </Typography>
                      <Typography variant="body1">
                        {t(
                          "Your new password should be at least 8 characters long and should contain at least 1 lowercase letter, 1 uppercase letter and 1 number."
                        )}
                      </Typography>
                      <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                          margin="dense"
                          name="new-password"
                          label={t("New password")}
                          type="password"
                          required
                          fullWidth
                          onChange={checkStrength}
                        />
                        {passwordStrength > 0 && (
                          <PasswordStrengthMeter
                            passwordStrength={passwordStrength}
                          />
                        )}
                        <TextField
                          margin="dense"
                          name="confirm-password"
                          label={t("Confirm password")}
                          type="password"
                          required
                          fullWidth
                          onChange={(e) =>
                            setNewData({
                              ...newData,
                              confirm: e.target.value,
                            })
                          }
                          InputProps={{
                            endAdornment: passwordMatch ? (
                              <InputAdornment position="end">
                                <CheckCircleIcon
                                  style={{ color: colors["green"][500] }}
                                />
                              </InputAdornment>
                            ) : undefined,
                          }}
                        />
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                          mt={2}
                          style={{ gap: "10px" }}
                        >
                          <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={(value) => setReCAPTCHAValue(value)}
                          />
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={
                              loading ||
                              !newData.new ||
                              !newData.confirm ||
                              !passwordMatch ||
                              newData.new.length < MIN_PASSWORD_LENGTH ||
                              passwordStrength < 3 ||
                              !reCAPTCHAValue
                            }
                          >
                            {t("Reset password")}
                            {loading && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box mt={8}>
                <Copyright />
              </Box>{" "}
            </>
          ) : (
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
        </Container>
      </Box>
    </main>
  );
};

export default ResetPasswordPage;
