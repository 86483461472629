import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { getBaseLanguage } from "src/components/pages/reports/utils/helpers";

// const resources = {
//   en: {
//     translations: {},
//   },
//   de: {
//     translations: DE_TRANSLATIONS,
//   },
//   ru: {
//     translations: RU_TRANSLATIONS,
//   },
//   fr: {
//     translations: FR_TRANSLATIONS,
//   },
//   it: {
//     translations: IT_TRANSLATIONS,
//   },
//   tr: {
//     translations: TR_TRANSLATIONS,
//   },
//   ar: {
//     translations: AR_TRANSLATIONS,
//   },
// };

i18n
  .use(Backend)
  .use(
    resourcesToBackend((language, namespace) =>
      import(`../../languages/${getBaseLanguage(language)}/${namespace}.json`)
    )
  )
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    // resources,
    lng: "en",
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    react: {
      useSuspense: false,
      // wait: false,
    },
  });

export default i18n;
