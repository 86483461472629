export const membersTableHeader = [
  {
    name: "username",
    label: "Username",
  },
  {
    name: "full_name",
    label: "Full name",
  },
  {
    name: "email",
    label: "Email",
  },
];

export const membersTableHeaderObject = {
  username: {
    name: "username",
    label: "Username",
  },
  full_name: {
    name: "full_name",
    label: "Full name",
  },
  email: {
    name: "email",
    label: "Email",
  },
};
