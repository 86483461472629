/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { loadUser, setLang } from "src/redux/actions";
import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import FlagIcon from "src/components/FlagIcon";
import safeLogger from "src/services/safeLogger";
import { Helmet } from "react-helmet";
import { apiUpdateProfile } from "src/api";

const useStyles = makeStyles((theme) => ({
  flag: {
    marginRight: "2px",
  },
  flagButton: {
    borderRadius: "3px",
  },
}));

const langList = {
  en_GB: "English",
  // de: "Deutsch",
  // ru: "Pусский",
  // fr: "Française",
  // it: "Italiano",
  tr_TR: "Türkçe",
  // sa: "العربية",
};

function mapDispatchToProps(dispatch) {
  return {
    setLang: (lang) => dispatch(setLang(lang)),
  };
}

const mapStateToProps = (state) => {
  return {
    appConf: state.appConf,
    userObject: state.userObject,
  };
};

const countryFromLang = {
  en_GB: "gb",
  de: "de",
  ru: "ru",
  fr: "fr",
  it: "it",
  tr_TR: "tr",
  ar: "sa",
};

const ConnectedLanguageSelect = (props) => {
  const classes = useStyles();
  const { appConf, userObject, setLang } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const updateProfileCallback = (data, status) => {
    if (status === 200) {
      loadUser({ ...data });
    } else {
      safeLogger(status, data);
    }
  };

  useEffect(() => {
    safeLogger(appConf);
    if (!(userObject.settings.general["defaultLang"] in countryFromLang)) {
      userObject.settings.general["defaultLang"] = "en_GB";
      appConf.lang = "en_GB";
      apiUpdateProfile(
        { settings: userObject.settings },
        updateProfileCallback
      );
    }
    // document.querySelector("html").lang = appConf.lang;
  }, [appConf]);

  useEffect(() => {
    setLang(
      userObject.settings && userObject.settings.general["defaultLang"]
        ? userObject.settings.general["defaultLang"]
        : appConf.lang
    );
  }, [userObject]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetLang = (event) => {
    // const newLang = langsFromCountry[event.currentTarget.dataset.lang]
    const newLang = event.currentTarget.dataset.lang;
    setLang(newLang);
    handleClose();
  };

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang:
            userObject.settings && userObject.settings.general["defaultLang"],
        }}
      />
      <IconButton color="inherit" onClick={handleClick}>
        <FlagIcon
          squared
          code={
            countryFromLang[
              userObject.settings && userObject.settings.general["defaultLang"]
                ? appConf.lang
                : userObject.settings.general["defaultLang"]
            ]
          }
          className={classes.flagButton}
        />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(langList).map((langKey, index) => (
          <MenuItem key={index} onClick={handleSetLang} data-lang={langKey}>
            <FlagIcon
              code={countryFromLang[langKey]}
              className={classes.flag}
            />
            {langList[langKey]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export const LanguageSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedLanguageSelect);
