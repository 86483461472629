import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  makeStyles,
  colors,
  Typography,
  TextField,
  Button,
  Avatar,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-US";
import { Send as SendIcon } from "@material-ui/icons";
import { apiPostVariantComment } from "src/api";
import { userHasPermission } from "src/components/utils";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  detailItemWrapper: {
    display: "inline-flex",
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& .detail-key": {
      color: colors.grey[600],
      marginRight: theme.spacing(1),
    },
    "& .detail-value": {
      wordWrap: "break-word",
    },
  },
  detailContent: {
    width: "100%",
  },
  commentSection: {
    maxHeight: "40vh",
    overflowY: "auto",
  },
  userMeta: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  comment: {
    backgroundColor: colors.lightBlue[50],
    border: `1px solid ${colors.lightBlue[100]}`,
    borderRadius: "8px",
    "& .metaName": {
      color: colors.grey[700],
    },
    "& .metaDate": {
      width: "100%",
      fontSize: "0.8rem",
      color: colors.grey[500],
    },
    "& .comment-body": {
      width: "100%",
    },
  },
}));

const fnsLocales = {
  tr: tr,
  en: en,
};

const Comment = React.forwardRef(function Comment(props, ref) {
  const { data, lang } = props;
  const classes = useStyles();
  return (
    <Box
      ref={ref}
      display="flex"
      flexWrap="wrap"
      p={2}
      mb={2}
      className={classes.comment}
    >
      <Avatar
        className={classes.avatar}
        src={data?.avatar}
        alt={data?.author_username}
      />
      <Box className={classes.userMeta}>
        <Typography className="metaName">{data.author_username}</Typography>
        <Typography className="metaDate">
          {formatDistanceToNow(new Date(data.created_at), {
            addSuffix: true,
            locale: fnsLocales[lang],
          })}
        </Typography>
      </Box>
      <Box width="100%" pl={7}>
        <Typography className="comment-body">{data.body}</Typography>
      </Box>
    </Box>
  );
});

const NewCommentBox = (props) => {
  const {
    userObject,
    identifierValue,
    reportId,
    setReportMeta,
    rowId,
    comments,
    setData,
  } = props;
  const [value, setValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const changeHandler = (event) => {
    setValue(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (value) {
      apiPostVariantComment(
        reportId,
        { body: value, identifier_value: identifierValue },
        (data, status) => {
          if (status === 201) {
            setData((prevData) => {
              return {
                ...prevData,
                comments: [
                  ...comments,
                  {
                    author_username: userObject.username,
                    created_at: new Date(),
                    body: value,
                  },
                ],
              };
            });
            setReportMeta((prevState) => {
              const newState = Object.assign({}, prevState);
              newState.commented_rows.push(rowId);
              return newState;
            });
            enqueueSnackbar(
              t(
                "variant_comment_success",
                "You comment was successfully added. In total {{rowCount}} rows were affected.",
                { rowCount: data["affected_rows"] }
              ),
              {
                variant: "success",
              }
            );
          } else {
            enqueueSnackbar(
              t("There was an error while adding the annotation."),
              {
                variant: "error",
              }
            );
          }
        }
      );
      setValue("");
    }
  };

  return (
    <form
      style={{ marginTop: "20px", display: "flex", flexWrap: "wrap" }}
      onSubmit={submitHandler}
    >
      <TextField
        disabled={
          userHasPermission(userObject.activePermissions, "annotate_report")
            ? false
            : true
        }
        label={t("Comment")}
        placeholder={t("Write a new comment for this variant")}
        multiline
        fullWidth
        variant="outlined"
        value={
          userHasPermission(userObject.activePermissions, "annotate_report")
            ? value
            : t("You don't have privilege to comment")
        }
        onChange={changeHandler}
      />
      <Button
        startIcon={<SendIcon />}
        style={{ marginLeft: "auto", marginTop: "15px" }}
        type="submit"
        variant="contained"
        color="primary"
        disabled={
          userHasPermission(userObject.activePermissions, "annotate_report")
            ? false
            : true
        }
      >
        {t("Send")}
      </Button>
    </form>
  );
};

const VariantComments = (props) => {
  const classes = useStyles();
  const {
    reportId,
    identifierValue,
    setReportMeta,
    activeRowId,
    commentData,
    setData,
    userObject,
    appConf,
  } = props;
  const [lang, setLang] = useState(appConf.lang);
  const { t } = useTranslation();

  const ref = useRef();

  useEffect(() => {
    setLang(appConf.lang);
  }, [appConf]);

  useEffect(() => {
    if (ref.current) {
      ref.current.parentNode.scrollTop = ref.current.offsetTop;
      //   ref.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "end",
      //   });
    }
  }, [ref]);

  return (
    <Box>
      <Typography component="h4" variant="h4" style={{ marginBottom: "10px" }}>
        {t("Comments")}
      </Typography>
      {Boolean(commentData.length) ? (
        <Box className={classes.commentSection}>
          {commentData.map((comment, index) => (
            <Comment
              // ref={index === commentData.length - 1 ? ref : null}
              key={index}
              data={comment}
              lang={lang}
            />
          ))}
        </Box>
      ) : (
        <Typography>{t("There are no comments for this record.")}</Typography>
      )}
      <NewCommentBox
        identifierValue={identifierValue}
        userObject={userObject}
        reportId={reportId}
        rowId={activeRowId}
        comments={commentData}
        setData={setData}
        setReportMeta={setReportMeta}
      />
    </Box>
  );
};

export default VariantComments;
