/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  colors,
  Container,
  CssBaseline,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { getBlogBaseURL, urlAPIConf } from "src/config/api";
import { useNavigate, useParams } from "react-router-dom";
import {
  CalendarToday as CalendarTodayIcon,
  Category as CategoryIcon,
  Label as LabelIcon,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    marginBottom: "40px",
    color: colors.grey[800],
  },
  contentRoot: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    "& ul, & ol": {
      paddingLeft: theme.spacing(5),
    },
    "& li": {
      padding: "5px 0",
    },
    "& p": {
      padding: "10px 0",
    },
    "& img": {
      maxWidth: "100%",
    },
    "& h1": {
      marginBottom: "2rem",
    },
    "& h2, h3, h4, h5, h6": {
      margin: "1rem auto",
    },
    "& .card-meta": {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      "& .card-date": {
        verticalAlign: "super",
        marginRight: theme.spacing(1),
      },
      "& .group-icon": {
        marginRight: theme.spacing(1),
      },
      "& .MuiChip-root": {
        verticalAlign: "top",
        marginRight: theme.spacing(1),
        backgroundColor: colors.blueGrey[100],
        "& .MuiChip-label": {
          color: colors.blueGrey[700],
        },
      },
      "&  *": {
        color: theme.palette.grey[400],
        fill: theme.palette.grey[400],
      },
    },
  },
}));

export const BlogPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [pageContent, setPageContent] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    // const path = window.location.href;
    // fetch(path + ".md")
    //   .then((response) => {
    //     safeLogger(response);
    //     return response.text();
    //   })
    //   .then((data) => {
    //     if (!data) {
    //       navigate("/404");
    //       return;
    //     }
    //     setPageContent(data);
    //   });
    fetch(`${getBlogBaseURL()}/${urlAPIConf.BASE_API_SLUG}/pages/${id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (!data) {
          navigate("/404");
          return;
        }
        setPageContent(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  // TODO get page title from page content

  if (!pageContent) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Help and Support")}>
        <CssBaseline />
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box className={classes.contentRoot}>
                <h1>{pageContent.title}</h1>
                <div className="card-meta">
                  <Chip
                    avatar={
                      <Avatar
                        alt={pageContent.owner.username}
                        src={`${getBlogBaseURL()}${pageContent.owner_avatar}`}
                      />
                    }
                    label={`${pageContent.owner.first_name} ${pageContent.owner.last_name}`}
                    onClick={() =>
                      navigate(
                        `/${urlAPIConf.SEARCH_SLUG}/?author=${pageContent.owner.id}`
                      )
                    }
                  />
                  <span>
                    <CalendarTodayIcon className="group-icon" />
                    <span className="card-date">
                      {pageContent.latest_revision_created_at.split("T")[0]}
                    </span>
                  </span>
                  <span>
                    {Boolean(pageContent.categories.length) && (
                      <CategoryIcon className="group-icon" />
                    )}
                    {pageContent.categories.map((item, index) => {
                      return (
                        <Chip
                          key={index}
                          onClick={() => {
                            navigate(
                              `/${urlAPIConf.SEARCH_SLUG}/?category=${item.id}`
                            );
                          }}
                          size="small"
                          label={item.name}
                        />
                      );
                    })}
                  </span>
                  <span>
                    {Boolean(pageContent.tags.length) && (
                      <LabelIcon className="group-icon" />
                    )}
                    {pageContent.tags.map((item, index) => {
                      return (
                        <Chip
                          key={index}
                          onClick={() => {
                            navigate(
                              `/${urlAPIConf.SEARCH_SLUG}/?tags=${item}`
                            );
                          }}
                          size="small"
                          label={item}
                        />
                      );
                    })}
                  </span>
                </div>
                <Markdown>
                  {pageContent.body_markdown
                    ? pageContent.body_markdown
                    : pageContent.body_html}
                </Markdown>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
};
