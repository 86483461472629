import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OpenInNew } from "@material-ui/icons";
import { getBaseLanguage, getTranslated } from "./utils/helpers";

const re = new RegExp("[^/]+(?=/$|$)");

const LinksDialog = (props) => {
  const { label, links } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ListItem button onClick={() => setOpen(true)}>
        <ListItemText primary={label} />
      </ListItem>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="link-dialog-title"
        open={open}
        style={{ minWidth: "400px" }}
      >
        <DialogTitle id="link-dialog-title">{`${label} Links`}</DialogTitle>
        <List>
          {links.map((link, index) => {
            return Boolean(link) ? (
              <ListItem
                button
                component={Link}
                key={link}
                href={link}
                target="_blank"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ListItemText primary={link.match(re)[0]} />
                <OpenInNew />
              </ListItem>
            ) : null;
          })}
        </List>
      </Dialog>
    </React.Fragment>
  );
};

const SidebarLinks = (props) => {
  const { classes, detailLinks, defaultColDetailsObject } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid item sm={12} md={2} className={classes.detailsLinks}>
      <Typography component="h6" variant="h6" className="filterstitle">
        {t("Outsource links")}
      </Typography>
      <Divider />
      <List>
        {detailLinks.map((linkItem, index) => {
          const isMultiLink = linkItem[1] && linkItem[1].includes("|");
          const label = defaultColDetailsObject[linkItem[0]].label
            ? defaultColDetailsObject[linkItem[0]].label
            : {};
          return linkItem[1] ? (
            isMultiLink ? (
              <LinksDialog
                key={linkItem[1]}
                label={getTranslated(label)}
                links={linkItem[1].split("|")}
              />
            ) : (
              <ListItem
                button
                component={Link}
                key={linkItem[0]}
                href={linkItem[1]}
                target="_blank"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ListItemText primary={getTranslated(label)} />
                <OpenInNew />
              </ListItem>
            )
          ) : null;
        })}
      </List>
    </Grid>
  );
};

export default SidebarLinks;
