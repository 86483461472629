/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Search({
  data,
  setData,
  url,
  style,
  type,
  searchValue,
  setSearchValue,
  searchParams,
  setSearchParams,
  search,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (searchValue !== "" && data) {
      // setData(handleSearch());
    }
  }, [data]);

  useEffect(() => {
    if (search !== searchValue && data && type === "projects") {
      setSearchValue(search ? search : "");
    }
  }, [search]);

  useEffect(() => {
    if (searchValue === "" && data && type === "projectDetail") {
      // setData(handleSearch());
    }
  }, []);

  /**
   * searching in projects
   * @returns filtered Projects
   */
  // const handleSearch = () => {
  //   if (type === "projects") {
  //     const filteredPorjectData = [];
  //     data.forEach((val) => {
  //       let tmpVal = val;
  //       const isHasSample = [];
  //       tmpVal.samples.forEach((sample) => {
  //         if (
  //           sample.name.toLowerCase().includes(searchValue.toLowerCase()) ||
  //           searchValue.length === 0
  //         )
  //           isHasSample.push(sample);
  //       });
  //       // tmpVal.samples = isHasSample;
  //       if (
  //         val.name.toLowerCase().includes(searchValue.toLowerCase()) ||
  //         isHasSample.length > 0
  //       ) {
  //         filteredPorjectData.push({ ...tmpVal, samples: isHasSample });
  //       }
  //     });
  //     return filteredPorjectData;
  //   } else if (type === "projectDetail") {
  //     const isHasSample = data.samples.filter((sample) =>
  //       sample.name.toLowerCase().includes(searchValue.toLowerCase())
  //     );
  //     const isHasReport = data.reports.filter((sample) =>
  //       sample.name.toLowerCase().includes(searchValue.toLowerCase())
  //     );
  //     return { ...data, samples: isHasSample, reports: isHasReport };
  //   } else {
  //     const filteredPorjectData = data.filter((val) => {
  //       const isHasSample = val.samples.filter((sample) =>
  //         sample.name.includes(searchValue)
  //       );
  //       return val.name.includes(searchValue) || isHasSample.length > 0;
  //     });
  //     return filteredPorjectData;
  //   }
  // };

  return (
    <Grid item xs={12}>
      <Grid
        container
        style={Object.assign(
          {},
          { justifyContent: "center", alignItems: "center" },
          style
        )}
      >
        <Grid item xs={10}>
          <TextField
            style={{ width: "100%" }}
            label={t("Search")}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (url) {
                  searchParams.set("value", searchValue);
                  setSearchParams(searchParams);
                  if (search !== searchValue && url) {
                    setSearchParams((prev) => ({
                      ...prev,
                      value: searchValue,
                    }));
                  }
                  //setData(handleSearch());
                }
              }
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (search !== searchValue && url) {
                setSearchParams({ value: searchValue });
              }
              // setData(handleSearch());
            }}
            style={{ width: "100%", margin: "0 10px" }}
          >
            {t("Search")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
