import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  colors,
} from "@material-ui/core";
import Papa from "papaparse";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { apiLoadReportVariantData } from "src/api";
import { withStyles } from "@material-ui/styles";
import { Assignment as AssignmentIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { getBaseLanguage, getTranslated } from "./utils/helpers";

const DELIMITER = "\t";
const NEWLINE = "\r\n";
const META_COLS = ["warning_col", "___row___", "___annotation___"];
const ALT_VALUE_COLS = ["main.significancy", "main.pathogenicity"];

function replaceColName(headers, columnDetails, lang) {
  let newHeaders = "";
  for (let i = 0; i < headers.length; i++) {
    if (columnDetails[headers[i]]) {
      newHeaders += getTranslated(columnDetails[headers[i]].label);
      if (i < headers.length - 1) {
        newHeaders += DELIMITER;
      }
    }
  }

  newHeaders += NEWLINE;
  return newHeaders;
}

function removeMetaCols(data, colDetails) {
  let newData = [...data];
  for (let i = 0; i < newData.length; i++) {
    for (let j = 0; j < META_COLS.length; j++) {
      if (META_COLS[j] === "___annotation___") {
        for (let item in newData[i][META_COLS[j]]) {
          if (newData[i][META_COLS[j]][item].annotation) {
            newData[i][item] = newData[i][META_COLS[j]][item].annotation;
          }
        }
      }
      delete newData[i][META_COLS[j]];
    }

    for (let j = 0; j < ALT_VALUE_COLS.length; j++) {
      if (!(ALT_VALUE_COLS[j] in colDetails)) {
        continue;
      }
      newData[i][ALT_VALUE_COLS[j]] =
        colDetails[ALT_VALUE_COLS[j]].representation.options.component_choices[
          newData[i][ALT_VALUE_COLS[j]]
        ];
    }
  }

  return newData;
}

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(colors.lightGreen[300]),
    backgroundColor: colors.lightGreen[300],
    "&:hover": {
      backgroundColor: colors.lightGreen[500],
    },
  },
}))(Button);

const RowDataCopier = (props) => {
  const {
    disabled,
    checkedRows,
    reportMeta,
    visibleCols,
    defaultColDetailsObject,
    totalRowCount,
    userObject,
  } = props;
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([
    "vfreq_in_house",
    "vfreq_in_cloud",
    "details4.clin_star_inf",
    "details4.clinvar_star",
    "main.variant_class",
    "main.variant_class",
    "main.res",
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const copySelectedRowsData = (allCols = false) => {
    setLoading(true);
    const visibleColNames = visibleCols
      .reduce((prev, curr) => [...prev, curr.name], [])
      .filter((colName) => {
        if (allCols) return true;
        return !selectedRows.includes(colName);
      });

    apiLoadReportVariantData(
      reportMeta.id,
      userObject.activeOrganization.id,
      0,
      totalRowCount,
      {
        filters: {
          combinator: "and",
          rules: [
            {
              field: "___id___",
              operator: "in",
              value: checkedRows.join(","),
            },
          ],
        },
      },

      (data, status) => {
        if (status === 200) {
          const newData = removeMetaCols(data.results, defaultColDetailsObject);
          let result = Papa.unparse(newData, {
            delimiter: DELIMITER,
            newline: NEWLINE,
            columns: allCols ? null : visibleColNames,
          });

          result = result.split(NEWLINE);
          const headers = replaceColName(
            result[0].split(DELIMITER),
            defaultColDetailsObject,
            i18n.language
          );

          result.shift();
          result = headers + result.join(NEWLINE);

          navigator.clipboard.writeText(result).then(() => {
            enqueueSnackbar(t("Row data copied to clipboard."), {
              variant: "success",
            });
          });
          setLoading(false);
        }
      }
    );
  };

  const handleSelectNotCopiedCol = (e) => {
    if (!e.target.checked) {
      selectedRows.push(e.target.name);
    } else {
      if (selectedRows.length) {
        const index = selectedRows.indexOf(e.target.name);

        if (index >= 0) {
          selectedRows.splice(index, 1);
        }
      }
    }
    setSelectedRows([...selectedRows]);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen((prev) => !prev)}>
        <DialogTitle>{t("Choose the columns to omit from copied")}</DialogTitle>
        {loading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: 16,
            }}
          >
            <Box style={{ padding: 16 }}>
              <CircularProgress />
            </Box>
            {t("Copying datas, please wait")}
          </Box>
        ) : (
          <DialogContent>
            {visibleCols.map((col, index) => {
              return (
                <Box key={index}>
                  <Checkbox
                    name={col.name}
                    checked={!Boolean(selectedRows.includes(col.name))}
                    onClick={handleSelectNotCopiedCol}
                  />
                  {getTranslated(col.label)}
                </Box>
              );
            })}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => copySelectedRowsData()}
          >
            {t("Copy")}
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            disabled={loading}
            onClick={() => setOpen((prev) => !prev)}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <ColorButton
        size="small"
        startIcon={<AssignmentIcon />}
        variant="contained"
        disabled={disabled || !checkedRows.length}
        onClick={() => copySelectedRowsData(true)}
        style={{ marginRight: "1rem" }}
      >
        {t("copy data (all)")}
      </ColorButton>
      <ColorButton
        size="small"
        startIcon={<AssignmentIcon />}
        variant="contained"
        disabled={!visibleCols || disabled || !checkedRows.length}
        onClick={() => setOpen((prev) => !prev)}
      >
        {t("copy data")}
      </ColorButton>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { userObject: state.userObject };
};

// use help button here for buttons
export default connect(mapStateToProps)(RowDataCopier);
