import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    borderBottom: ".5px solid rgba(0,0,0,0.3)",
    padding: theme.spacing(1),
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    "& > div:first-child": {
      paddingRight: theme.spacing(1),
    },
    "& svg": {
      fontSize: 30,
    },
  },
}));

function Header({ icon, title }) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={clsx(classes.header, classes.center, classes.icon)}
    >
      <Grid item>{icon}</Grid>
      <Grid item>{title}</Grid>
    </Grid>
  );
}

export default Header;
