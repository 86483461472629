import { Box, Switch, Typography } from "@material-ui/core";
import { NightsStay, WbSunny } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "src/redux/actions";

function ThemeChange({ color }) {
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const handleTheme = (theme) => {
    dispatch(updateTheme(theme === "light" ? "dark" : "light"));
  };

  return (
    <Box
      padding="10px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <WbSunny
        onClick={() => handleTheme("dark")}
        style={{ cursor: "pointer" }}
      />
      <Switch
        checked={theme === "dark"}
        onChange={() => handleTheme(theme)}
        name="checkedA"
        inputProps={{ "data-testid": "dark-mode" }}
      />
      <NightsStay
        onClick={() => handleTheme("light")}
        style={{ cursor: "pointer" }}
      />
      <Typography
        data-testid="dark-mode-text"
        style={{
          display: "none",
          color: "white !important",
        }}
        id="dark-mode-text"
        variant="body2"
        color="textSecondary"
      >
        {theme === "dark" ? "Dark" : "Light"}
      </Typography>
    </Box>
  );
}

export default ThemeChange;
