/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { apiCreateDepartment, apiDeleteDepartment } from "src/api";
import CreateDialog from "../createDialog";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  memberCountBadge: {
    "& .MuiBadge-badge": {
      right: "-10px",
    },
  },
}));

const tableHeaders = {
  name: "Department name",
  created_at: "Created at",
};

const DepartmentTableRow = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { organization_id, department, removeItem } = props;
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setLoading(false);
  };

  const handleDeleteObj = () => {
    setLoading(true);
    apiDeleteDepartment(organization_id, department.id, (data, status) => {
      setLoading(false);
      setDialogOpen(false);
      removeItem(department.id);
      enqueueSnackbar(
        t(
          "department_delete_success_message",
          "{{department_name}} department was deleted",
          {
            department_name: department.name,
          }
        ),
        {
          variant: "success",
        }
      );
    });
  };

  return (
    <TableRow data-id={department.id} key={department.id}>
      {Object.keys(tableHeaders).map((headerKey, index) => {
        if (headerKey === "name") {
          return (
            <TableCell
              key={index}
              data-testid={`name-${department[headerKey].split(" ").join("-")}`}
            >
              <Badge
                max={9999}
                overlap="rectangular"
                className={classes.memberCountBadge}
                badgeContent={department.members_count}
                color="primary"
              >
                {department[headerKey]}
              </Badge>
            </TableCell>
          );
        }
        if (headerKey === "created_at") {
          return (
            <TableCell key={index}>
              {format(new Date(department[headerKey]), "dd/MM/yyyy")}
            </TableCell>
          );
        }
        return null;
      })}
      <TableCell>
        <IconButton
          onClick={() => navigate(`edit-department/${department.id}`)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          data-testid={`delete-${department.name.split(" ").join("-")}`}
          onClick={() => setDialogOpen(true)}
        >
          <DeleteIcon />
        </IconButton>
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            data-testid={`modal-${department.name.split(" ").join("-")}`}
          >
            {t("Delete department")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(
                "delete_department_message",
                "Are you sure you want to delete department '{{department_name}}'?",
                { department_name: department.name }
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t("No")}
            </Button>
            <Button
              onClick={handleDeleteObj}
              color="secondary"
              data-testid="delete-department-button"
              autoFocus
              disabled={loading}
            >
              {t("Yes")}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

export const DepartmentManagement = (props) => {
  const { organization_id, departments, setOrganizationData } = props;
  const classes = useStyles();
  const [createOpen, setCreateOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createFormData, setCreateFormData] = useState({
    name: "",
    description: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const removeItem = (id) => {
    setOrganizationData((prevState) => {
      const newData = Object.assign({}, prevState);
      for (let i = 0; i < newData.departments.length; i++) {
        if (newData.departments[i].id === id) {
          newData.departments.splice(i, 1);
          break;
        }
      }
      return newData;
    });
  };

  useEffect(() => {
    if (createFormData.name) {
      setLoading(true);
      apiCreateDepartment(organization_id, createFormData, (data, status) => {
        setLoading(false);
        if (status === 201) {
          setCreateOpen(false);
          setOrganizationData((prevState) => {
            const newData = Object.assign({}, prevState);
            newData["departments"] = newData.departments.concat([data]);
            return newData;
          });
          enqueueSnackbar(t("Created department"), {
            variant: "success",
          });
          setCreateFormData({
            name: "",
            description: null,
          });
        } else if (status === 400 && data["details"] === "duplicate name") {
          enqueueSnackbar(
            t(
              "department_create_duplicate_name_error",
              "A department with the name '{{department_name}}' already exists. Please choose a unique name and try again.",
              { department_name: createFormData.name }
            ),
            {
              variant: "error",
            }
          );
        }
      });
    }
  }, [createFormData]);

  if (!departments) {
    return <CircularProgress classes={classes.spinner} />;
  } else {
    return (
      <div>
        <Grid container>
          <Grid item xs={12} style={{ display: "flex", marginBottom: "20px" }}>
            <Typography component="h4" variant="h4">
              {t("Department Management")}
            </Typography>
            <CreateDialog
              type="department"
              open={createOpen}
              setOpen={setCreateOpen}
              setCreateFormData={setCreateFormData}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12}>
            {departments.length ? (
              <Table size="small" data-testid={"table"}>
                <TableHead>
                  <TableRow>
                    {Object.keys(tableHeaders).map((headerKey, index) => (
                      <TableCell key={index}>
                        {t(tableHeaders[headerKey])}
                      </TableCell>
                    ))}
                    <TableCell>{t("Actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departments.map((department, index) => (
                    <DepartmentTableRow
                      organization_id={organization_id}
                      removeItem={removeItem}
                      department={department}
                      key={index}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography>
                {t(
                  "There are no departments in the organization. Click on the 'NEW DEPARTMENT' button to create a new department."
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
};
