import React from "react";
import {
  NavLink as RouterLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  Hidden,
  ListItem,
  makeStyles,
  Tooltip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.main.primary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    minWidth: 0,
    padding: "10px 0",
    textTransform: "none",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "10px 8px",
    },
  },
  title: {
    marginRight: "auto",
    marginLeft: 12,
  },
  active: {
    backgroundColor: `${theme.palette.navbar.background} !important`,
    color: theme.palette.secondary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& svg": {
      color: `${theme.palette.navbar.primary} !important`,
    },
  },
}));

const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
  const classes = useStyles();
  let resolved = useResolvedPath(href);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Hidden mdDown>
        <Tooltip
          title={title}
          aria-label={title}
          placement="right"
          style={{
            width: "100%",
          }}
        >
          <Button
            className={clsx(classes.button, { [classes.active]: match })}
            component={RouterLink}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            to={href}
          >
            {Icon && <Icon style={{ textAlign: "center" }} size="20" />}
          </Button>
        </Tooltip>
      </Hidden>
      <Hidden lgUp>
        <ListItem
          className={clsx(classes.item, className)}
          disableGutters
          {...rest}
        >
          <Button
            className={clsx(classes.button, { [classes.active]: match })}
            component={RouterLink}
            to={href}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
          </Button>
        </ListItem>
      </Hidden>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
