import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CreateDialog = (props) => {
  const classes = useStyles();
  const { type, open, setOpen, loading, setCreateFormData } = props;

  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formElements = event.target.elements;
    setCreateFormData({
      name: formElements["name"].value,
      description: formElements["description"].value
        ? formElements["description"].value
        : null,
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="create-dialog-title"
        aria-describedby="create-dialog-description"
      >
        <DialogTitle id="create-dialog-title" data-testid="create-dialog-title">
          {type === "team"
            ? t("Create a new team")
            : t("Create a new department")}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText id="create-dialog-description">
              {t(`Fill in the form below to create a new ${type}`)}
            </DialogContentText>
            <TextField
              variant="outlined"
              name="name"
              inputProps={{ "data-testid": "name-input" }}
              data-testid="name"
              label={t("Name")}
              required
              fullWidth
            />
            <TextField
              variant="outlined"
              name="description"
              inputProps={{ "data-testid": "description-input" }}
              data-testid="description"
              label={t("Description")}
              fullWidth
              multiline
              style={{ marginTop: "10px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              data-testid="save-button"
              color="primary"
              autoFocus
              disabled={loading}
            >
              {t("Save")}
              {loading && (
                <CircularProgress
                  data-testid="delete-department-spinner"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Button
        variant="contained"
        color="primary"
        data-testid="create-button"
        startIcon={<AddIcon />}
        style={{ marginLeft: "auto" }}
        onClick={() => setOpen(true)}
      >
        {t(`New ${type}`)}
      </Button>
    </>
  );
};

export default CreateDialog;
