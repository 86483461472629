import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  makeStyles,
  Menu,
  Typography,
} from "@material-ui/core";
import { Help as HelpIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
  },
  menu: {
    "& .MuiMenu-paper": {
      maxWidth: "400px",
    },
    "& .MuiList-padding": {
      padding: 0,
    },
  },
}));

const HelpButton = (props) => {
  const classes = useStyles();
  const { title, helpDesc, extraLink } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Menu
        className={classes.menu}
        id="help-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="body2" component="p">
              {helpDesc}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={() => setAnchorEl(null)}>
              {t("Close")}
            </Button>
            {extraLink && <Button size="small">{t("Learn More")}</Button>}
          </CardActions>
        </Card>
      </Menu>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <HelpIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default HelpButton;
