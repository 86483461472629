import { alpha, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
        // color: theme.palette.text.primary,
      },
      // "*#igv-div > p , h1 , h2 , h3 , h4 , h5 , h6 , a , label , div": {
      //   color: `black !important`,
      // },
      "#igv-div , #zsiq_float": {
        "& p , h1 , h2 , h3 , h4 , h5 , h6 , a , label , div": {
          color: `initial`,
        },
      },
      "p , h1 , h2 , h3 , h4 , h5 , h6 , a , label , div": {
        color: `${theme.palette.text.primary}`,
      },
      ".MuiTableCell-root": {
        "& .header-tooltip-wrapper": {
          color: "white !important",
        },
      },
      ".MuiChip-deletableColorSecondary > span": {
        color: `${theme.palette.text.primary} !important`,
      },
      ".MuiChip-label , #sign_in": {
        "& h1 , p": {
          color: `white !important`,
        },
        color: `${theme.palette.text.primary} !important`,
      },
      ".MuiCircularProgress-root + .MuiBox-root": {
        "& .MuiTypography-root": {
          color: `${theme.palette.text.primary} !important`,
        },
      },
      ".MuiLink-root": {},
      button: {
        "not(header > button)": {
          "& svg": {
            color: `${theme.palette.svg.primary} !important`,
          },
        },
      },
      ".MuiButton-textPrimary": {
        color: `${theme.palette.main.primary} !important`,
        backgroundColor: `${alpha(theme.palette.main.primary, 0.1)} !important`,
      },
      ".MuiButton-containedPrimary": {
        "& span": {
          color: `white !important`,
        },
        "& svg": {
          color: `${theme.palette.svg.primary} !important`,
        },
      },
      "header svg , th svg , .MuiButton-contained svg": {
        color: theme.palette.common.white,
      },
      ".MuiButton-outlinedSecondary svg": {
        color: `${theme.palette.secondary.main}`,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      ".MuiButton-outlinedPrimary": {
        border: `1px solid ${theme.palette.outline.primary}`,
        color: theme.palette.outline.primary,
        "& span": {
          color: `${theme.palette.outline.text} !important`,
        },
      },
      ".MuiTabs-root, MuiTab-wrapper , .MuiTypography-root , .MuiBox-root": {
        "& .MuiTab-wrapper": {
          color: `${theme.palette.main.primary} !important`,
        },
        "& #dark-mode-text:not": {
          // color: `${theme.palette.main.primary} !important`,
        },
      },
      ".MuiTextField-root , .MuiFormLabel-root": {
        color: `${theme.palette.text.primary} !important`,
      },
      body: {
        backgroundColor: theme.palette.background.mainBackground,
        height: "100%",
        width: "100%",
      },
      a: {
        color: `${theme.palette.text.primary} !important`,

        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".MuiTooltip-tooltip": {
        fontSize: "0.8rem !important",
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
