import { Box, Typography } from "@material-ui/core";

export const ChartLegend = (props) => {
  const { items, style } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={style}
    >
      {items.map((item, index) => (
        <Box mr={5} key={index} display="flex">
          <Box
            mr={1}
            alignSelf="center"
            width="10px"
            height="10px"
            style={{ backgroundColor: item.color }}
          ></Box>
          <Typography>{item.name}</Typography>
        </Box>
      ))}
    </Box>
  );
};
