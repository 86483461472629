import { Box, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  tabPanel: {
    background: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const classes = useStyle();
  const { children, value, index, width, ...other } = props;

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: width ? width : "%80" }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default TabPanel;
