import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Page from "src/components/Page";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
}));

export const Error504Page = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t("Error 504 - Gateway timeout")}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                alt=""
                src="/assets/images/errors/504.svg"
                style={{ maxHeight: "400px" }}
              />
              <Typography style={{ maxWidth: "420px", textAlign: "center" }}>
                {t(
                  "504_message",
                  "The server did not respond in time. Please try again later."
                )}
              </Typography>
              {moment().format("YYYY-MM-DD hh:mm:ss")}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
