/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { materialColors } from "./conf";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

// const asc = (arr) => arr.sort((a, b) => a - b);

const processData = (rawData, calculateMean) => {
  const labels = [];
  const data = [];
  const allData = [];
  let sum = 0;

  for (let i = 0; i < rawData.length - 1; i++) {
    const item = rawData[i].split("\t");
    const newItem = [parseFloat(item[0]), parseFloat(item[1])];
    allData.push(newItem[1]);
    if (calculateMean) sum += newItem[1];
    if (!data.length || newItem[1] !== data[data.length - 1].y) {
      labels.push(newItem[0]);
      data.push({ x: newItem[0], y: newItem[1] });
    }
  }

  const mean = calculateMean ? sum / data.length : undefined;

  return {
    labels,
    data,
    allData,
    mean,
  };
};

const InsertSizeAcrossReferenceLineChart = (props) => {
  const classes = useStyles();
  const [chromosomePositions, setChromosomePositions] = useState(undefined);
  const [chromosomeLines, setChromosomeLines] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [options, setOptions] = useState(undefined);

  useEffect(() => {
    if (chromosomeLines) {
      fetch("/assets/statistics/insert_size_across_reference.txt")
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n").slice(1);
          const processedData = processData(rawData, true);

          setData({
            labels: processedData.labels,
            datasets: [
              {
                data: processedData.data,
                borderColor: colors[materialColors[9]][400],
                pointRadius: 0,
                borderWidth: 2,
              },
            ],
          });

          setOptions({
            annotation: {
              annotations: chromosomeLines,
            },
            maintainAspectRatio: false,
            responsive: true,
            elements: {
              line: {
                tension: 0,
              },
            },
            title: {
              display: true,
              text: "Insert size across reference",
            },
            legend: { display: false },
            scales: {
              yAxes: [
                {
                  ticks: {
                    stepSize: 25000,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Insert size (bp)",
                  },
                },
              ],
              xAxes: [
                {
                  type: "linear",
                  position: "bottom",
                  ticks: {
                    max: processedData.data[processedData.data.length - 1].x,
                    // max: options.scales.xAxes[0].ticks.max,
                    minRotation: 90,
                    callback: (value) => {
                      return chromosomePositions[value];
                    },
                  },
                  afterBuildTicks: function (axis, ticks) {
                    ticks = Object.keys(chromosomePositions);
                    return ticks;
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Position (bp)",
                  },
                },
              ],
            },
          });
        });
    }
  }, [chromosomeLines]);

  useEffect(() => {
    let prevPosition = 0;
    const chrPositions = {};
    const chrLines = [];
    fetch("/assets/statistics/qualimapReport.json")
      .then((response) => response.json())
      .then((data) => {
        const chrKeys = Object.keys(data.chromosome);
        for (let i = 0; i < chrKeys.length; i++) {
          const position =
            prevPosition + parseFloat(data.chromosome[chrKeys[i]].length);
          chrPositions[position] = chrKeys[i];
          chrLines.push({
            drawTime: "afterDatasetsDraw",
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: position,
            borderWidth: 1,
            borderColor: colors[materialColors[16]][300],
            borderDash: [10, 5],
            label: {
              // content: chrKeys[i],
              enabled: false,
              // position: "top",
              // fontColor: "#313131",
              // fontSize: 10,
              // backgroundColor: "rgba(0,0,0,0)",
              // yAdjust: (i % 2) * 15,
            },
          });
          prevPosition = position;
        }
        setChromosomePositions(chrPositions);
        setChromosomeLines(chrLines);
      });
  }, []);

  if (!data || !options) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <div className={classes.root}>
        <div style={{ paddingLeft: "10px" }}>
          <Line height={500} data={data} options={options} />
        </div>
      </div>
    );
  }
};

export default InsertSizeAcrossReferenceLineChart;
