/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Add, NavigateBefore, Remove } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Save } from "react-feather";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  chip: {
    "& span": {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  root: {
    width: "100%",
  },
  button: {
    padding: theme.spacing(2),
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
}));

function Duo({ directory, setPhase, files, setFileMeta }) {
  const isInputFormatValid = () => true;
  const isFileConfigUnique = () => true;
  const [formData, setFormData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = [];
    if (
      formData.filter(
        (data) => _.isEmpty(data.pair_1) || _.isEmpty(data.pair_2)
      ).length > 0
    ) {
      enqueueSnackbar(
        t(
          "form_input_not_valid_message",
          "Form input are not valid. Please check and try again."
        ),
        {
          variant: "error",
        }
      );
      return;
    }
    formData.forEach((form) => {
      let tmpRow = {
        name: form.name,
        reports: [form.pair_1.sample_id, form.pair_2.sample_id],
      };
      data.push(tmpRow);
    });
    setFileMeta(data);
    if (isInputFormatValid()) {
      if (isFileConfigUnique()) {
        setPhase("without_credit_submit");
      } else {
        enqueueSnackbar(
          t(
            "form_conf_not_unique_message",
            "Form configuration is not unique. Please check and try again."
          ),
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar(
        t(
          "form_input_not_valid_message",
          "Form input are not valid. Please check and try again."
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const removeRow = (index) => {
    if (index > -1) {
      formData.splice(index, 1);
    }
    setFormData([...formData]);
  };

  const addRow = () => {
    formData.push({});
    setFormData([...formData]);
  };

  const handleAutoCompleteChange = (index, name) => (e, value) => {
    formData[index][name] = value;
    formData.forEach((data) => {
      if (data?.pair_1) {
        data.name = data.pair_1.name
          .split("/")
          [data.pair_1.name.split("/").length - 1].replace(".tsv", "");
        data.name += "-";
      }
      if (data?.pair_2) {
        data.name += data.pair_2.name
          .split("/")
          [data.pair_2.name.split("/").length - 1].replace(".tsv", "");
      }
    });
    setFormData([...formData]);
  };

  // useEffect(() => {
  //   formData.map((data) => {
  //     if (data?.pair_1) {
  //       data.name =
  //         data.pair_1.name.split("/")[data.pair_1.name.split("/").length - 1];
  //       data.name += "-";
  //     }
  //     if (data?.pair_2) {
  //       data.name +=
  //         data.pair_2.name.split("/")[data.pair_2.name.split("/").length - 1];
  //     }
  //   });
  //   setFormData([...formData]);
  // }, [formData]);

  useEffect(() => {
    // Initialize to minimum form data
    if (files.length > 0) {
      files.forEach((item, index) => {
        formData.push({});
      });
      setFormData([...formData]);
    }
  }, []);

  return (
    <Box className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Report Name")}</TableCell>
            <TableCell>{t("First Duo Report")}</TableCell>
            <TableCell>{t("Second Duo Report")}</TableCell>
            <TableCell width={20}>{t("Remove")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formData.map((data, index) => {
            return (
              <DuoTableRow
                index={index}
                data={data}
                files={files}
                handleAutoCompleteChange={handleAutoCompleteChange}
                t={t}
                removeRow={removeRow}
              />
            );
          })}
        </TableBody>
      </Table>
      <Box className={classes.button}>
        <Button variant="contained" onClick={addRow} color="primary">
          <Add />
        </Button>
      </Box>
      <Box display="flex" width="100%" mt={5}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<NavigateBefore />}
          onClick={() => setPhase("init")}
        >
          {t("Back")}
        </Button>
        <Button
          style={{ marginLeft: "auto" }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          startIcon={<Save />}
        >
          {t("Submit")}
        </Button>
      </Box>
    </Box>
  );
}

export default Duo;
function DuoTableRow({
  data,
  files,
  index,
  handleAutoCompleteChange,
  t,
  removeRow,
}) {
  return (
    <TableRow>
      <TableCell>
        <TextField
          variant="outlined"
          value={data["name"] || ""}
          fullWidth
          disabled
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          name="pair_1"
          options={files}
          value={data["pair_1"] || null}
          noOptionsText={t("No Options")}
          onChange={handleAutoCompleteChange(index, "pair_1")}
          getOptionLabel={(option) =>
            option &&
            option.name &&
            option.name.split("/")[option.name.split("/").length - 1]
          }
          renderInput={(props) => (
            <TextField
              {...props}
              variant="outlined"
              name="pair_1"
              label={t("First Duo Report")}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          name="pair_2"
          options={files}
          value={data["pair_2"] || null}
          onChange={handleAutoCompleteChange(index, "pair_2")}
          getOptionLabel={(option) =>
            option &&
            option.name &&
            option.name.split("/")[option.name.split("/").length - 1]
          }
          noOptionsText={t("No Options")}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="outlined"
              name="pair_2"
              label={t("Second Duo Report")}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => removeRow(index)}
        >
          <Remove />
        </Button>
      </TableCell>
    </TableRow>
  );
}
