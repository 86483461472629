import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { I18nextProvider } from "react-i18next";
import i18n from "src/services/i18n";
import GlobalStyles from "src/components/GlobalStyles";
import lightTheme from "src/theme";
import "./App.css";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import routes from "./routes";
import AppInitiator from "./components/appInitiator";
import { activeOrganizationExists } from "./components/utils";
import darkTheme from "./theme/darkTheme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

export const queryClient = new QueryClient();
const App = () => {
  const theme = useSelector((state) => state.theme);
  const isLoggedIn = useSelector((state) => {
    return Boolean(state.auth) || Boolean(localStorage.getItem("mbiAuth"));
  });
  const isInitiated = useSelector((state) => {
    return Boolean(state.userObject) && Boolean(state.appConf);
  });

  const activeOrg = useSelector((state) => {
    return activeOrganizationExists(state.userObject);
  });

  const userPermissions = useSelector((state) => {
    return state.userObject && state.userObject.activePermissions
      ? state.userObject.activePermissions
      : undefined;
  });

  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  

  const routing = useRoutes(routes(isLoggedIn, userPermissions, activeOrg));

  if (
    isLoggedIn &&
    (!isInitiated ||
      (localStorage.getItem("activeOrganizationId") && !userPermissions))
  ) {
    return <AppInitiator />;
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
            <GlobalStyles />
            <SnackbarProvider maxSnack={3}>
              <ReactQueryDevtools
                initialIsOpen={process.env.REACT_APP_ENV === "development"}
              />
              {showDevtools && (
                <React.Suspense fallback={null}>
                  <ReactQueryDevtoolsProduction />
                </React.Suspense>
              )}
              {routing}
            </SnackbarProvider>
          </ThemeProvider>
        </I18nextProvider>
      </QueryClientProvider>
    );
  }
};

export default App;
