/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Tooltip,
  Chip,
  Typography,
  TextField,
  MenuItem,
  makeStyles,
  InputLabel,
  Select,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
  alpha,
} from "@material-ui/core";
import clsx from "clsx";
import {
  ExpandMore as ExpandMoreIcon,
  ClearAll as ClearAllIcon,
  Save as SaveIcon,
  OpenInNew,
  Refresh as RefreshIcon,
  Feedback,
} from "@material-ui/icons";
import {
  apiGetTableConf,
  apiPostTableConf,
  apiTableConfDetails,
} from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { Alert, Rating } from "@material-ui/lab";
import { generateLocus } from "./igvBrowser";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearActiveGeneFilters, updateAdvanceFilter } from "src/redux/actions";
import i18n from "src/services/i18n";
import { apiTableGetSchema } from "src/api/endpoints";
import { getBaseLanguage, getTranslated } from "./utils/helpers";
import { getBaseURL } from "src/config/api";
import { SharedVariants } from "./virtualizedTable/tooltipTableCell";

const useStyles = makeStyles((theme) => ({
  autoLeft: {
    marginLeft: "auto",
  },
  appliedFiltersSummary: {
    "& > .MuiAccordionSummary-content > *": {
      marginRight: "16px",
    },
    "& .accordion-title": {
      paddingTop: "13px",
    },
    "& .accordion-counter": {
      marginTop: "13px",
      pointer: "cursor",
      backgroundColor: `${alpha(theme.palette.secondary.main, 0.5)} !important`,
    },
    "& .MuiAccordionSummary-content": {
      marginBottom: "8px",
      marginTop: "8px",
    },
    "& .icon-button": {
      height: "48px",
    },
    "& .filter-status-btn": {
      height: "2rem",
      marginTop: "0.5rem",
    },
    "& .dropdown-wrapper": {
      minWidth: "200px",
      "& #preset-filter-dropdown-label": {
        backgroundColor: `${theme.palette.background.paper}`,
        borderRight: `4px solid ${theme.palette.background.paper}`,
        borderLeft: `4px solid ${theme.palette.background.paper}`,
      },
    },
  },
  appliedFiltersDetails: {
    maxHeight: "10vh",
    overflowY: "auto",
    background: theme.palette.action.hover,
    "& .filter-detail-wrapper": {
      display: "inline-flex",
      flexWrap: "wrap",
      marginRight: theme.spacing(2),
      "& .filter-label": {
        marginRight: "8px",
      },
      "& .filter-chip": {
        marginRight: "4px",
        marginBottom: "4px",
      },
    },
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  appliedFilterCount: {
    backgroundColor: `${alpha(theme.palette.secondary.main, 0.2)} !important`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CustomDropDown = (props) => {
  const classes = useStyles();
  const {
    loadedColDetails,
    setColDetails,
    presetFilter,
    igvLoading,
    reportMeta,
    igvLocation,
    openIGVLocus,
    handleChangePresetFilter,
    presetFilterList,
  } = props;
  const { t } = useTranslation();

  const handleReset = (event) => {
    event.stopPropagation();
    if (loadedColDetails) {
      setColDetails(loadedColDetails);
    }
  };

  return (
    <React.Fragment>
      {igvLocation && !reportMeta.project_type.includes("vcf") && (
        <Tooltip title={t("Go to position of the variant in desktop IGV ")}>
          <Button
            className={`${classes.autoLeft} filter-status-btn`}
            onClick={openIGVLocus}
            color="primary"
            variant="outlined"
            size="small"
            disabled={igvLoading}
            startIcon={<OpenInNew />}
          >
            {t("Open in Desktop IGV")}
            {igvLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Tooltip>
      )}
      {presetFilter && (
        <Tooltip title={t("Refresh current filter")}>
          <Button
            className={`${classes.autoLeft} filter-status-btn`}
            onClick={handleReset}
            color="secondary"
            variant="outlined"
            size="small"
            startIcon={<RefreshIcon />}
          >
            {t("Refresh")}
          </Button>
        </Tooltip>
      )}
      <FormControl
        key="filter-select"
        className={clsx({
          [classes.autoLeft]: !presetFilter,
          "dropdown-wrapper": true,
        })}
        margin="dense"
        variant="outlined"
      >
        <InputLabel id="preset-filter-dropdown-label">
          {t("Preset filter")}
        </InputLabel>
        <Select
          labelId="preset-filter-dropdown-label"
          id="preset-filter-dropdown"
          value={presetFilter}
          onChange={handleChangePresetFilter}
          onClose={(event) => event.stopPropagation()}
        >
          {Boolean(presetFilterList.length) &&
            presetFilterList.map((presetFilter, index) => {
              return (
                <MenuItem key={index} value={presetFilter.id}>
                  {presetFilter.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userObject: state.userObject,
    appConf: state.appConf,
    advanceFilter: state.advanceFilter,
  };
};

export const ConnectedFilterStatusBox = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [filterCount, setFilterCount] = useState(0);
  const [presetFilterList, setPresetFilterList] = useState([]);
  const [presetFilter, setPresetFilter] = useState("");
  const [tmpPresetFilter, setTmpPresetFilter] = useState("");
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [presetLoading, setPresetLoading] = useState(false);
  const [loadedColDetails, setLoadedColDetails] = useState(null);
  const [colFiltersets, setColFiltersets] = useState({});
  const {
    tableType,
    reportMeta,
    defaultColDetailsObject,
    defaultColDetails,
    advanceFilter,
    appConf,
    processColDetails,
    open,
    userObject,
    setOpen,
    files,
    SetIdentifierColumn,
    projectData,
    colDetails,
    setColDetails,
    setTableSortState,
    setTableFilterTypes,
    tableFilterState,
    igvLocation,
    setTableFilterState,
    filteredRowCount,
    setAdvanceFilter,
  } = props;
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [igvLoading, setIgvLoading] = useState(false);

  const openIGVLocus = (event) => {
    event.stopPropagation();
    let igvTracks = "";

    const sendRequest = (file) => {
      setIgvLoading(true);
      fetch(
        `http://localhost:60151/load?genome=${
          projectData.genome
        }&${file}&locus=${generateLocus(igvLocation)}`
      )
        .then((response) => {
          safeLogger(response);
        })
        .catch((error) => {
          setOpenDialog(true);
        })
        .finally(() => {
          setIgvLoading(false);
        });
    };

    if (files["mutect2_bam"] && files["mutect2_bai"]) {
      igvTracks += `&file=${getBaseURL()}${
        files["mutect2_bam"].download_address
      }&index=${getBaseURL()}${files["mutect2_bai"].download_address}`;
      sendRequest(igvTracks);
    }

    if (files["bam"] && files["bai"]) {
      igvTracks = `&file=${getBaseURL()}${
        files["bam"].download_address
      }&index=${getBaseURL()}${files["bai"].download_address}`;
      sendRequest(igvTracks);
    }

    if (files["haplotypecaller_bam"] && files["haplotypecaller_bai"]) {
      igvTracks = `&file=${getBaseURL()}${
        files["haplotypecaller_bam"].download_address
      }&index=${getBaseURL()}${files["haplotypecaller_bai"].download_address}`;
      sendRequest(igvTracks);
    }

    if (files["vcf"] && files["vcf_idx"]) {
      igvTracks = `&file=${getBaseURL()}${
        files["vcf"].download_address
      }&index=${getBaseURL()}${files["vcf_idx"].download_address}`;
      sendRequest(igvTracks);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (reportMeta) {
      apiGetTableConf(
        reportMeta.project_type,
        userObject.activeOrganization.id,
        (data, status) => {
          if (status === 200) {
            const schemaFilteredList = data.filter((item) => {
              return item.schema_version === reportMeta.schema_version;
            });
            safeLogger("filtered list", schemaFilteredList);
            if (mounted) {
              setPresetFilterList(schemaFilteredList);
            }
            for (let i = 0; i < schemaFilteredList.length; i++) {
              if (schemaFilteredList[i].is_default) {
                if (mounted) {
                  setTmpPresetFilter(schemaFilteredList[i].id);
                  setPresetFilter(schemaFilteredList[i].id);
                }
              }
            }
          } else {
            safeLogger(data);
          }
        }
      );
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const findColRangeValues = (name) => {
    const targetCol = defaultColDetailsObject[name];
    if (targetCol) {
      if (targetCol !== undefined) {
        return [targetCol.minValue, targetCol.maxValue];
      } else {
        return false;
      }
    }

    return false;
  };

  const findColUniqueValues = (name) => {
    const targetCol = defaultColDetailsObject[name];
    if (targetCol && targetCol.name === name) {
      // safeLogger(
      //   "col saved filterValues: ",
      //   targetCol.name,
      //   targetCol.filterValues
      // );
      if (targetCol.filterValues) {
        const colUniqueValues = JSON.parse(
          JSON.stringify(targetCol.filterValues)
        );
        Object.keys(colUniqueValues).map(
          (key) => (colUniqueValues[key] = false)
        );
        return colUniqueValues;
      } else {
        return false;
      }
    }

    return false;
  };

  useEffect(() => {
    let mounted = true;

    if (presetFilter && reportMeta) {
      setPresetLoading(true);
      apiTableConfDetails(
        presetFilter,
        reportMeta.project_type,
        (data, status) => {
          if (status === 200) {
            const presetFilters = data.data.config
              ? data.data.config
              : data.data;

            for (let i = 0; i < presetFilters.length; i++) {
              if (presetFilters[i]?.kind === "numeric") {
                const rangeValues = findColRangeValues(presetFilters[i].name);
                if (rangeValues && presetFilters[i]?.filterRange) {
                  if (presetFilters[i]?.filterRange[0] === "") {
                    presetFilters[i].filterRange[0] = rangeValues[0];
                  }
                  if (presetFilters[i]?.filterRange[1] === "") {
                    presetFilters[i].filterRange[1] = rangeValues[1];
                  }
                }
                if (rangeValues) {
                  presetFilters[i].minValue = rangeValues[0];
                  presetFilters[i].maxValue = rangeValues[1];
                } else if (presetFilters[i]?.filterRange) {
                  presetFilters[i].minValue = presetFilters[i]?.filterRange[0];
                  presetFilters[i].maxValue = presetFilters[i]?.filterRange[1];
                }
                if (!presetFilters[i]?.filterRange) {
                  presetFilters[i].filterRange = rangeValues;
                }
              } else if (presetFilters[i]?.kind !== "free_form") {
                let activeFilters = presetFilters[i].filterValues;
                const uniqueValues = findColUniqueValues(presetFilters[i].name);
                if (!activeFilters) {
                  activeFilters = [];
                }
                if (uniqueValues) {
                  presetFilters[i].filterValues = uniqueValues;
                  if (presetFilters[i].activeFilterCount) {
                    for (let j = 0; j < activeFilters.length; j++) {
                      presetFilters[i].filterValues[activeFilters[j]] = true;
                    }
                  }
                } else if (presetFilters[i]?.kind === "boolean") {
                } else if (presetFilter[i]?.kind === "counted") {
                  const newFilterValues = {};
                  for (let j = 0; j < activeFilters.length; j++) {
                    newFilterValues[activeFilters[j]] = true;
                  }
                  presetFilters[i].filterValues = newFilterValues;
                } else {
                  const newFilterValues = {};
                  for (let j = 0; j < activeFilters.length; j++) {
                    newFilterValues[activeFilters[j]] = true;
                  }
                  presetFilters[i].filterValues = newFilterValues;
                }
              }
            }

            safeLogger("how", presetFilters);

            if (true) {
              dispatch(updateAdvanceFilter(null));
              setColDetails(presetFilters);
              setLoadedColDetails(JSON.parse(JSON.stringify(presetFilters)));
              setPresetLoading(false);
              if (data.data.advanceFilter)
                dispatch(updateAdvanceFilter(data.data.advanceFilter));
            }
          } else {
            safeLogger(data);
            // enqueueSnackbar(data, { variant: "error" });
          }
        }
      );
    } else {
    }

    return function cleanup() {
      mounted = false;
    };
  }, [presetFilter]);

  const geneState = useSelector((state) => state.activeGeneFiltersVariant);

  useEffect(() => {
    let filtersets = {};
    let tmpFilters = {};
    let tmpFilterTypes = {};
    let newFilterCount = 0;
    for (let i = 0; i < colDetails?.length; i++) {
      if (colDetails[i].activeFilterCount) {
        if (colDetails[i].filtersetName) {
          filtersets[colDetails[i].name] = [colDetails[i].filtersetName];
        }
        if (colDetails[i].name === "___tagged___") {
          if (colDetails[i].filterValues) {
            let hasFilter = false;
            for (const item in colDetails[i].filterValues) {
              if (colDetails[i].filterValues[item]) {
                hasFilter = item === "true" ? true : false;
              }
            }
            tmpFilters[colDetails[i].name] = hasFilter;
          }
          // tmpFilterTypes[colDetails[i].name] = "OR";
        } else if (colDetails[i]?.kind === "numeric") {
          tmpFilters[colDetails[i].name] = [...colDetails[i]?.filterRange];
          newFilterCount++;
        } else {
          let tmpColFilters = [];
          const filterObjects = Object.entries(colDetails[i].filterValues);
          for (let j = 0; j < filterObjects.length; j++) {
            if (filterObjects[j][1]) {
              tmpColFilters.push(filterObjects[j][0]);
              newFilterCount++;
            }
          }
          tmpFilters[colDetails[i].name] = tmpColFilters;
        }
      }
    }

    const addCounter = () => {
      newFilterCount++;
    };

    if (!geneState?.combinator) {
      for (let item in geneState) {
        if (geneState[item] && geneState[item].length) {
          tmpFilters[item] = [
            ...geneState[item].filter((value, index, self) => {
              if (self.indexOf(value) === index) {
                addCounter();
                return true;
              }
              return false;
            }),
            ...(tmpFilters[item] ?? []),
          ];
        }
      }
    } else {
      tmpFilters = geneState;
    }

    setTableFilterTypes(tmpFilterTypes);
    setColFiltersets(filtersets);
    setTableFilterState(tmpFilters);
    setFilterCount(newFilterCount);
  }, [colDetails, geneState]);

  const handleDelete = (colName, filterKey) => {
    let newColFilterpresets = { ...colFiltersets };
    let newColDetails = colDetails.slice(0);
    for (let i = 0; i < newColDetails.length; i++) {
      if (newColDetails[i].name === colName) {
        if (newColDetails[i].filtersetName) {
          newColDetails[i].activeFilterCount = 0;
          delete newColFilterpresets[colName];
          newColDetails[i].filterValues[filterKey] = {};
        } else {
          newColDetails[i].filterValues[filterKey] = false;
          newColDetails[i].activeFilterCount--;
        }
        break;
      }
    }
    setColFiltersets(newColFilterpresets);
    setColDetails(newColDetails);
  };

  const handleDeleteRange = (colName) => {
    let newColDetails = colDetails.slice(0);
    for (let i = 0; i < newColDetails.length; i++) {
      if (newColDetails[i].name === colName) {
        newColDetails[i].filterRange = [
          newColDetails[i].minValue,
          newColDetails[i].maxValue,
        ];
        newColDetails[i].activeFilterCount = 0;
        break;
      }
    }
    setColDetails(newColDetails);
  };

  const dispatch = useDispatch();

  const handleClearAll = (event) => {
    event.stopPropagation();
    apiTableGetSchema(reportMeta.schema_id, (cols, status) => {
      SetIdentifierColumn(cols.identifier_column);
      cols = cols.columns.map((value) => {
        return {
          ...value,
          filterValues: reportMeta.column_unique_values[value.name]
            ? reportMeta.column_unique_values[value.name].filter(
                (filter) => filter !== ""
              )
            : [],
          unique_values: reportMeta.column_unique_values[value.name]
            ? reportMeta.column_unique_values[value.name].filter(
                (filter) => filter !== ""
              )
            : [],
        };
      });

      const processedCols = processColDetails(cols);
      dispatch(clearActiveGeneFilters());
      dispatch(updateAdvanceFilter(undefined));
      setPresetFilter(tmpPresetFilter);
      setColDetails(processedCols.colDetails);
      setTableSortState({});
      setTableFilterState({});
      setAdvanceFilter({
        combinator: "or",
        rules: [],
      });
      // enqueueSnackbar(t("Please select first preset filter."), {
      //   variant: "error",
      // });
    });
  };

  const saveFilterCallback = (data, status) => {
    if (status === 201) {
      const newPresetFilterList = presetFilterList.slice(0);
      newPresetFilterList.push(data);
      setPresetFilterList(newPresetFilterList);
      enqueueSnackbar(
        t(
          "table_conf_saved_message",
          "Table configuration {{name}} successfully saved.",
          { name: data.name }
        ),
        {
          variant: "success",
        }
      );
    } else if (status === 409) {
      enqueueSnackbar(
        t(
          "new_table_conf_unique_name_error",
          "A Table configuration with the name chosen already exists! Please use a unique name and try again."
        ),
        {
          variant: "error",
        }
      );
    } else {
      enqueueSnackbar(
        "An error occured while saving your table configurations.",
        {
          variant: "error",
        }
      );
    }
    setSaveDialogOpen(false);
    setLoading(false);
  };

  const handleSaveFilter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    let newFilterConf = colDetails;

    for (let i = 0; i < newFilterConf.length; i++) {
      if (tableFilterState[newFilterConf[i].name]) {
        if (newFilterConf[i]?.kind === "enumeration") {
          newFilterConf[i].filterValues =
            tableFilterState[newFilterConf[i].name];
        }
      } else {
        if (newFilterConf[i]?.kind === "enumeration") {
          newFilterConf[i].filterValues = [];
        } else if (
          newFilterConf[i]?.kind === "numeric" &&
          newFilterConf[i]?.filterRange
        ) {
          delete newFilterConf[i]?.filterRange;
        }
      }
    }

    const filterConf = {
      name: event.target[0].value,
      schema: reportMeta.schema_id,
      data: {
        advanceFilter: advanceFilter,
        config: newFilterConf,
        type: advanceFilter ? "advance_filter" : "filter",
      },
    };
    apiPostTableConf(filterConf, saveFilterCallback);
  };

  const handleChangePresetFilter = (event) => {
    event.stopPropagation();
    setPresetFilter(event.target.value);
  };

  const handleSaveDialogOpen = (event) => {
    if (event) event.stopPropagation();
    setSaveDialogOpen(true);
  };

  const handleSaveDialogClose = (event) => {
    if (event) event.stopPropagation();
    setSaveDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const expandIconElement = <ExpandMoreIcon id="expand-more-icon" />;

  return (
    <Box width="100%">
      <Accordion>
        <AccordionSummary
          className={classes.appliedFiltersSummary}
          expandIcon={expandIconElement}
          aria-controls="applied-filters-content"
          id="applied-filters-headers"
        >
          <Typography className="accordion-title">
            {t("Applied Filters")}
          </Typography>
          <Chip
            className={clsx({
              [classes.appliedFiltersCount]: true,
              "accordion-counter": true,
            })}
            size="small"
            color="secondary"
            label={filterCount}
            variant="outlined"
          />
          <Tooltip title={t("Reset filters and layout to default")}>
            <Button
              className="filter-status-btn"
              size="small"
              variant="contained"
              onClick={handleClearAll}
              color="secondary"
              startIcon={<ClearAllIcon />}
            >
              {t("Reset")}
            </Button>
          </Tooltip>
          {advanceFilter && (
            <Tooltip title={t("Advance Filter is active")}>
              <Button
                color="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                <Feedback />
              </Button>
            </Tooltip>
          )}
          {/* <Tooltip
            title={
              isOverflow
                ? t("Expand the variant table")
                : t("Compress the variant table")
            }
          >
            <Button
              className="filter-status-btn"
              size="small"
              variant="outlined"
              onClick={(e) => {
                setIsOverflow(!isOverflow);
                setRefresh(!refresh);
              }}
              color="primary"
              startIcon={<Launch />}
            >
              {isOverflow ? t("Compress") : t("Expand")}
            </Button>
          </Tooltip> */}
          {tableType === "variant" && (
            <React.Fragment>
              <div>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t("Unable to open sample in IGV")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t(
                        "Sample could not be opened in IGV. Please make sure IGV is installed and running. If the problem persists, please contact support."
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseDialog}
                      color="primary"
                      autoFocus
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <Tooltip title={t("Save current layout and filters")}>
                <Button
                  className="filter-status-btn"
                  size="small"
                  variant="contained"
                  onClick={handleSaveDialogOpen}
                  color="primary"
                  startIcon={<SaveIcon />}
                >
                  {t("Save")}
                </Button>
              </Tooltip>
              <Dialog
                onClick={(event) => event.stopPropagation()}
                open={saveDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleSaveDialogClose}
                aria-labelledby="save-filter-dialog-title"
                aria-describedby="save-filter-dialog-description"
              >
                <DialogTitle id="save-filter-dialog-title">
                  {t("Save the current layout and filters")}
                </DialogTitle>
                <form onSubmit={handleSaveFilter}>
                  <DialogContent>
                    <DialogContentText id="save-filter-dialog-description">
                      {t(
                        "You can save your current filters by specifying a name in the field below"
                      )}
                    </DialogContentText>
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      id="name"
                      label={t("Filter name")}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleSaveDialogClose} color="secondary">
                      {t("Cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      disabled={loading}
                    >
                      {t("Save")}
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
              {filteredRowCount !== undefined && (
                <Typography
                  className={classes.autoLeft}
                  style={{ lineHeight: "3rem" }}
                >
                  {`${t("Filtered variant count")}: ${filteredRowCount}`}
                </Typography>
              )}
              {presetLoading ? (
                <CircularProgress className={classes.autoLeft} />
              ) : null}
              <CustomDropDown
                presetFilter={presetFilter}
                reportMeta={reportMeta}
                loadedColDetails={loadedColDetails}
                setColDetails={setColDetails}
                igvLoading={igvLoading}
                igvLocation={igvLocation}
                openIGVLocus={openIGVLocus}
                handleChangePresetFilter={handleChangePresetFilter}
                presetFilterList={presetFilterList}
              />
            </React.Fragment>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.appliedFiltersDetails}>
          <Box>
            {Object.keys(tableFilterState).map((filterKey, index) => {
              const label = defaultColDetailsObject[filterKey]?.label
                ? defaultColDetailsObject[filterKey]?.label
                : {};
              return (
                <Box className="filter-detail-wrapper" key={index}>
                  <Typography className="filter-label">
                    {getTranslated(label)}
                  </Typography>
                  {colFiltersets[filterKey] ? (
                    <Chip
                      className="filter-chip"
                      color="secondary"
                      key={index}
                      label={colFiltersets[filterKey]}
                      variant="outlined"
                      onDelete={() =>
                        handleDelete(filterKey, colFiltersets[filterKey])
                      }
                      size="small"
                    />
                  ) : tableFilterState[filterKey].length === 2 &&
                    typeof tableFilterState[filterKey][0] === "number" &&
                    typeof tableFilterState[filterKey][1] === "number" ? (
                    // numeric column filter value
                    <Chip
                      className="filter-chip"
                      color="secondary"
                      key={index}
                      label={
                        tableFilterState[filterKey][0] +
                        " - " +
                        tableFilterState[filterKey][1]
                      }
                      variant="outlined"
                      onDelete={() => handleDeleteRange(filterKey)}
                      size="small"
                    />
                  ) : (
                    filterKey !== "___tagged___" &&
                    tableFilterState[filterKey].map((filterItem, index) => (
                      <Chip
                        className="filter-chip"
                        color="secondary"
                        key={index}
                        label={
                          defaultColDetailsObject[filterKey]?.representation
                            .options &&
                          defaultColDetailsObject[filterKey]?.representation
                            .options.component &&
                          defaultColDetailsObject[filterKey]?.representation
                            .options.component === "star-rating" ? (
                            <Rating
                              className={classes.starRating}
                              name="revstat"
                              readOnly
                              max={4}
                              value={parseFloat(filterItem)}
                              precision={0.1}
                            />
                          ) : defaultColDetailsObject[filterKey]?.representation
                              .options.component === "shared_variants" ? (
                            <SharedVariants
                              data={filterItem.replaceAll("'", '"')}
                            />
                          ) : defaultColDetailsObject[filterKey]?.representation
                              .options &&
                            defaultColDetailsObject[filterKey]?.representation
                              .options.component_choices &&
                            defaultColDetailsObject[filterKey]?.representation
                              .options.component_choices.constructor ===
                              Object ? (
                            defaultColDetailsObject[filterKey]?.representation
                              .options.component_choices[filterItem]
                          ) : (
                            filterItem
                          )
                        }
                        variant="outlined"
                        onDelete={() => handleDelete(filterKey, filterItem)}
                        size="small"
                      />
                    ))
                  )}
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export const FilterStatusBox = connect(mapStateToProps)(
  ConnectedFilterStatusBox
);
