/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
}));

const ACGTContentPieChart = (props) => {
  const classes = useStyles();
  const { helpInformation, qualimapJson } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const [percentLabels, setPercentLabels] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    try {
      const jsonData = qualimapJson["acgt_content_(inside_of_regions)"];
      const dataKeys = Object.keys(jsonData);
      const labels = [];
      const data = [];
      const backgroundColor = [];
      const hoverBackgroundColor = [];
      for (let i = 0; i < dataKeys.length - 2; i++) {
        labels.push(dataKeys[i].replace("/percentage", ""));
        const newValue = jsonData[dataKeys[i]].split(" / ");
        data.push(parseInt(newValue[0].replace(/,/g, "")));
        percentLabels[parseInt(newValue[0].replace(/,/g, ""))] = newValue[1];
        backgroundColor.push(
          colors[materialColors[i % materialColors.length]][300]
        );
        hoverBackgroundColor.push(
          colors[materialColors[i % materialColors.length]][500]
        );
      }

      if (mounted) {
        setSampleData({
          labels: labels,
          datasets: [
            {
              label: t("ACGT Content"),
              data: data,
              backgroundColor: backgroundColor,
              hoverBackgroundColor: hoverBackgroundColor,
            },
          ],
        });

        setPercentLabels(percentLabels);
      }
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSampleData({ labels: [], datasets: [] });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const options = {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return percentLabels[value];
        },
        color: "#FFF",
      },
    },
    title: {
      display: true,
      text: t("ACGT Content"),
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      // display: false,
      position: "left",
      labels: {
        boxWidth: 10,
      },
    },
  };

  if (!sampleData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
        style={{ maxHeight: "400px" }}
      >
        <Pie
          width={400}
          height={400}
          data={sampleData}
          options={options}
          plugins={[ChartDataLabels]}
        />
      </ChartWrapper>
    );
  }
};

export default ACGTContentPieChart;
