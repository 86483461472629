/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import { VirtualMembersTable } from "./membersTable";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { apiGetOrganizationMembers, apiOrganizationAddMembers } from "src/api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    "& .MuiDialog-paper": {
      minHeight: "50vh",
    },
  },
  formControl: {
    position: "absolute",
    bottom: "7px",
  },
}));

export default function AddMemberDialog(props) {
  const classes = useStyles();
  const {
    organization_id,
    department_id,
    type,
    currentMemberIds,
    setDepartmentData,
  } = props;
  const [open, setOpen] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [availableMembers, setAvailableMembers] = useState(undefined);
  const [availableMemberIds, setAvailableMemberIds] = useState(undefined);
  const [forcePermissions, setForcePermissions] = useState(true);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCheckedRows([]);
    setForcePermissions(true);
  };

  const handleAddMembers = () => {
    setLoading(true);
    apiOrganizationAddMembers(
      organization_id,
      type,
      department_id,
      checkedRows,
      forcePermissions,
      (data, status) => {
        setLoading(false);
        if (status === 200) {
          setDepartmentData(data);
          enqueueSnackbar(t("Selected members were added successfully."), {
            variant: "success",
          });
        }
        handleClose();
      }
    );
  };

  useEffect(() => {
    apiGetOrganizationMembers(organization_id, (data, status) => {
      if (status === 200) {
        const otherMembers = data.filter(
          (member) => !(currentMemberIds ?? []).includes(member.id)
        );
        setAvailableMembers(otherMembers);
      }
    });
  }, [currentMemberIds]);

  useEffect(() => {
    if (availableMembers) {
      const memberIds = availableMembers.reduce(
        (arr, currVal) => arr.concat([currVal.id]),
        []
      );
      setAvailableMemberIds(memberIds);
    }
  }, [availableMembers]);

  return (
    <React.Fragment>
      <Button
        startIcon={<AddCircleIcon />}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{ marginLeft: "auto" }}
      >
        {t("Add members")}
      </Button>
      <Dialog
        className={classes.dialog}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="add-member-dialog-title"
        aria-describedby="add-member-dialog-description"
      >
        <DialogTitle id="add-member-dialog-title">
          {t("Add members")}
        </DialogTitle>
        {availableMembers ? (
          <>
            <DialogContent>
              {availableMembers.length ? (
                <React.Fragment>
                  <VirtualMembersTable
                    height={300}
                    checkedRows={checkedRows}
                    setCheckedRows={setCheckedRows}
                    membersData={availableMembers}
                    memberIds={availableMemberIds}
                  />
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={forcePermissions}
                            onChange={() =>
                              setForcePermissions((prevState) => !prevState)
                            }
                            name="force_permissions"
                          />
                        }
                        label={t(
                          "Apply the current permissions to the new members"
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                </React.Fragment>
              ) : (
                <Typography>
                  {t(
                    "add_members_all_users_are_members_message",
                    "All possible users are already a member of this {{type}}.",
                    { type: type }
                  )}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                {t("Cancel")}
              </Button>
              {Boolean(availableMembers.length) && (
                <Button
                  onClick={handleAddMembers}
                  color="primary"
                  autoFocus
                  variant="contained"
                  disabled={loading}
                >
                  {t("Add selected members")}
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              )}
            </DialogActions>
          </>
        ) : (
          <CircularProgress />
        )}
      </Dialog>
    </React.Fragment>
  );
}
