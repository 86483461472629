/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

// const asc = (arr) => arr.sort((a, b) => a - b);

// const getPercentile = (data, q) => {
//   const arr = [];
//   for (let i = 0; i < data.length; i++) {
//     arr.push(data[i].y);
//   }
//   const sorted = asc(arr);
//   const pos = (sorted.length - 1) * q;
//   const base = Math.floor(pos);
//   const rest = pos - base;
//   if (sorted[base + 1] !== undefined) {
//     return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
//   } else {
//     return sorted[base];
//   }
// };

const processData = (rawData, calculateMean) => {
  const labels = [];
  const data = [];
  const allData = [];
  let sum = 0;

  for (let i = 0; i < rawData.length - 1; i++) {
    const item = rawData[i].split("\t");
    const newItem = [parseFloat(item[0]), parseFloat(item[1])];
    allData.push(newItem[1]);
    if (calculateMean) sum += newItem[1];
    if (!data.length || newItem[1] !== data[data.length - 1].y) {
      labels.push(newItem[0]);
      data.push({ x: newItem[0], y: newItem[1] });
    }
  }

  const mean = calculateMean ? sum / data.length : undefined;

  return {
    labels,
    data,
    allData,
    mean,
  };
};

const MappingQualityAcrossReferenceLineChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files, tagSuffix } = props;
  const [chromosomePositions, setChromosomePositions] = useState(undefined);
  const [chromosomeLines, setChromosomeLines] = useState(undefined);
  const [gcData, setGcData] = useState(undefined);
  const [xMax, setXMax] = useState(undefined);
  // const [gcOptions, setGcOptions] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    // const re = RegExp(
    //   "mapping_quality_across_reference(-mother|-father|-child)?"
    // );
    // const fileTag = Object.keys(files).find((tag) => re.test(tag));
    const fileTagSuffix = tagSuffix ? "-" + tagSuffix : "";
    if (chromosomeLines) {
      try {
        fetch(
          getBaseURL() +
            files["mapping_quality_across_reference" + fileTagSuffix]
              .download_address
        )
          .then((response) => response.text())
          .then((rawData) => {
            rawData = rawData.split("\n").slice(1);
            const processedData = processData(rawData, true);

            if (mounted) {
              setGcData({
                labels: processedData.labels,
                datasets: [
                  {
                    data: processedData.data,
                    backgroundColor: "rgba(92, 107, 192, 0.3)",
                    borderColor: colors[materialColors[4]][400],
                    pointRadius: 0,
                    borderWidth: 1,
                  },
                ],
              });
            }

            if (mounted) {
              setXMax(processedData.data[processedData.data.length - 1].x);
            }
          });
      } catch (e) {
        safeLogger(e);
        if (mounted) {
          setGcData({ labels: [], datasets: [] });
        }
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [chromosomeLines]);

  useEffect(() => {
    let mounted = true;
    let prevPosition = 0;
    const chrPositions = {};
    const chrLines = [];
    fetch("/assets/statistics/bam_chr_statsfrom_samtools")
      .then((response) => response.text())
      .then((data) => {
        let chrData = data.split("\n");
        chrData = chrData.slice(0, chrData.length - 1);
        for (let i = 0; i < chrData.length; i++) {
          const chromosome = chrData[i].split("\t");
          const position = prevPosition + parseFloat(chromosome[1]);
          chrPositions[position] = chromosome[0];
          chrLines.push({
            drawTime: "afterDatasetsDraw",
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: position,
            borderWidth: 1,
            borderColor: colors[materialColors[16]][300],
            borderDash: [10, 5],
            label: {
              // content: chrKeys[i],
              enabled: false,
              // position: "top",
              // fontColor: "#313131",
              // fontSize: 10,
              // backgroundColor: "rgba(0,0,0,0)",
              // yAdjust: (i % 2) * 15,
            },
          });
          prevPosition = position;
        }
        if (mounted) {
          setChromosomePositions(chrPositions);
          setChromosomeLines(chrLines);
        }
      });

    return function cleanup() {
      mounted = false;
    };
  }, []);

  // useEffect(() => {
  //   let prevPosition = 0;
  //   const chrPositions = {};
  //   const chrLines = [];
  //   fetch("/assets/statistics/qualimapReport.json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const chrKeys = Object.keys(data.chromosome);
  //       for (let i = 0; i < chrKeys.length; i++) {
  //         const position =
  //           prevPosition + parseFloat(data.chromosome[chrKeys[i]].length);
  //         chrPositions[position] = chrKeys[i];
  //         chrLines.push({
  //           drawTime: "afterDatasetsDraw",
  //           type: "line",
  //           mode: "vertical",
  //           scaleID: "x-axis-0",
  //           value: position,
  //           borderWidth: 1,
  //           borderColor: colors[materialColors[16]][300],
  //           borderDash: [10, 5],
  //           label: {
  //             // content: chrKeys[i],
  //             enabled: false,
  //             // position: "top",
  //             // fontColor: "#313131",
  //             // fontSize: 10,
  //             // backgroundColor: "rgba(0,0,0,0)",
  //             // yAdjust: (i % 2) * 15,
  //           },
  //         });
  //         prevPosition = position;
  //       }
  //       setChromosomePositions(chrPositions);
  //       setChromosomeLines(chrLines);
  //     });
  // }, []);

  const options = {
    annotation: {
      annotations: chromosomeLines,
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    title: {
      display: true,
      text: t("Mapping quality across reference"),
    },
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: function (context) {
          return t("Mapping quality") + ": " + context.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            stepSize: 10,
            max: 250,
          },
          scaleLabel: {
            display: true,
            labelString: t("Mapping quality"),
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            max: xMax,
            // max: options.scales.xAxes[0].ticks.max,
            minRotation: 90,
            callback: (value) => {
              return chromosomePositions[value];
            },
          },
          afterBuildTicks: function (axis, ticks) {
            ticks = Object.keys(chromosomePositions);
            return ticks;
          },
          scaleLabel: {
            display: true,
            labelString: t("Position (bp)"),
          },
        },
      ],
    },
  };

  if (!gcData || !xMax) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <div style={{ paddingLeft: "10px" }}>
          <Line height={450} data={gcData} options={options} />
        </div>
      </ChartWrapper>
    );
  }
};

export default MappingQualityAcrossReferenceLineChart;
