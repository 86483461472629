import React, { useState, useEffect } from "react";
import {
  Typography,
  Avatar,
  Grid,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import { Send as SendIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { apiPostComment } from "src/api/endpoints";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-US";

const fnsLocales = {
  tr: tr,
  en: en,
};

const useStyles = makeStyles((theme) => ({
  CommentSection: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    "& .section-title": {
      marginBottom: theme.spacing(3),
    },
  },
  commentBox: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& .comment-author": {
      marginBottom: theme.spacing(1),
    },
    "& .comment-date": {
      color: theme.palette.text.disabled,
      marginTop: theme.spacing(2),
    },
  },
  commentForm: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    border: "1px solid " + theme.palette.grey[200],
    borderRadius: "4px",
  },
}));

export const CommentSection = (props) => {
  const classes = useStyles();
  const { projectData, appConf, userObj } = props;
  const [commentText, setCommentText] = useState("");
  const [commentsList, setCommentsList] = useState(projectData.comments);
  const [lang, setLang] = useState(appConf.lang);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setCommentText(event.target.value);
  };

  const commentSubmitHandler = (event) => {
    event.preventDefault();

    apiPostComment(
      projectData.id,
      userObj.activeOrganization.id,
      { body: commentText },
      (data, status) => {
        if (status === 201) {
          setCommentText("");
          setCommentsList([
            ...commentsList,
            {
              author_username: data.author_username,
              body: data.body,
              created_at: data.created_at,
            },
          ]);
        }
      }
    );
  };

  useEffect(() => {
    setLang(appConf.lang);
  }, [appConf]);

  return (
    <div className={classes.CommentSection}>
      <Typography component="h2" variant="h3" className="section-title">
        {t("Comments")}
      </Typography>
      {Boolean(commentsList.length) &&
        commentsList.map((item, index) => {
          return (
            <Grid item xs={12} key={index} className={classes.commentBox}>
              <Grid item xs={1}>
                <Avatar src={item.avatarLink ? item.avatarLink : ""} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  component="h4"
                  variant="h4"
                  className="comment-author"
                >
                  {item.author_username}
                </Typography>
                <Typography component="p" variant="body1">
                  {item.body}
                </Typography>
                <Typography variant="body2" className="comment-date">
                  {formatDistanceToNow(new Date(item.created_at), {
                    addSuffix: true,
                    locale: fnsLocales[lang],
                  })}
                </Typography>
              </Grid>
            </Grid>
          );
        })}

      <Grid
        item
        xs={12}
        className={classes.commentForm}
        style={{ display: "flex" }}
      >
        <Grid item xs={1}>
          <Avatar src="" /> {/* TODO Connect to user object and get avatar */}
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={commentSubmitHandler}>
            <TextField
              label={t("Comment")}
              placeholder={t("Write a comment for this project")}
              multiline
              fullWidth
              variant="outlined"
              value={commentText}
              onChange={handleChange}
            />
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Button
                style={{ marginLeft: "auto" }}
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
              >
                {t("Send")}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};
// TODO pagination, reply to comment
