/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Box, colors, makeStyles, Typography } from "@material-ui/core";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import formatDuration from "date-fns/formatDuration";
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-US";

const fnsLocales = {
  tr: tr,
  en: en,
};

const useStyles = makeStyles((theme) => ({
  speedLabelText: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(1),
  },
}));

export const NetworkActivityMonitor = (props) => {
  const classes = useStyles();
  const { currentSpeed, averageSpeed, uploadRemainingTime, appConf } = props;
  const [time, setTime] = useState(new Date());
  const [averageDataset, setAverageDataset] = useState([]);
  const [currentDataset, setCurrentDataset] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const { t } = useTranslation();

  const chartRef = useRef(null);

  function parseSeconds(seconds) {
    let numYears = Math.floor(seconds / 31536000);
    let numDays = Math.floor((seconds % 31536000) / 86400);
    let numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    let numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    let numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    return {
      years: numYears,
      days: numDays,
      hours: numHours,
      minutes: numMinutes,
      seconds: numSeconds,
    };
  }

  useEffect(() => {
    Chart.plugins.unregister(ChartDataLabels);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let currSpeed = currentSpeed;
    if (!window.navigator.onLine) {
      currSpeed = "0 MB/s";
    }

    let tmpDataset = currentDataset;
    tmpDataset.push(parseFloat(currSpeed.split(" ")));
    if (tmpDataset.length > 100) {
      tmpDataset = tmpDataset.slice(tmpDataset.length - 100, tmpDataset.length);
    }
    setCurrentDataset([...tmpDataset]);
    if (averageSpeed !== 0 || !averageDataset.length) {
      averageDataset.push(parseFloat(averageSpeed.split(" ")));
    } else {
      averageDataset.push(averageDataset[averageDataset.length - 1]);
    }
    tmpDataset = averageDataset;
    tmpDataset.push(parseFloat(averageSpeed.split(" ")));
    if (tmpDataset.length > 100) {
      tmpDataset = tmpDataset.slice(tmpDataset.length - 100, tmpDataset.length);
    }
    setAverageDataset([...tmpDataset]);

    tmpDataset = chartLabels;
    tmpDataset.push(time);
    if (tmpDataset.length > 100) {
      tmpDataset = tmpDataset.slice(tmpDataset.length - 100, tmpDataset.length);
    }
    setChartLabels([...tmpDataset]);
  }, [time]);

  return (
    <Box width="100%" mb={4}>
      <Typography>
        <span className={classes.speedLabelText}>
          {t("Current uploading speed")}:
        </span>
        <span style={{ color: colors.red[400] }}>{currentSpeed}</span>
      </Typography>
      <Typography>
        <span className={classes.speedLabelText}>
          {t("Average uploading speed")}:
        </span>
        <span style={{ color: colors.blue[400] }}>{averageSpeed}</span>
      </Typography>
      <Typography>
        <span className={classes.speedLabelText}>{t("Remaining time")}:</span>
        <span>
          {uploadRemainingTime === Number.POSITIVE_INFINITY
            ? null
            : formatDuration(parseSeconds(uploadRemainingTime), {
                locale: fnsLocales[appConf.lang],
              })}
        </span>
      </Typography>
      <Box maxHeight="150px" height="150px">
        <Bar
          ref={chartRef}
          redraw={true}
          data={{
            labels: chartLabels,
            datasets: [
              {
                type: "line",
                label: t("AVERAGE SPEED"),
                borderColor: colors.blue[300],
                hoverBackgroundColor: colors.blue[400],
                borderWidth: 2,
                fill: false,
                data: averageDataset,
                pointRadius: 0,
              },
              {
                type: "bar",
                label: t("CURRENT SPEED"),
                backgroundColor: colors.red[200],
                hoverBackgroundColor: colors.red[300],
                barThickness: 10,
                data: currentDataset,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            animation: {
              duration: 0,
            },
            elements: {
              line: {
                tension: 0,
              },
            },
            scales: {
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "MB/s",
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Box>
  );
};
