import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const roles = { mother: "Mother", father: "Father", child: "Child" };

const commonCharts = ["indel_length", "transitions_and_transversions"];

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  chartContainer: {
    position: "relative",
    marginTop: theme.spacing(2),
  },
}));

const TrioCharts = (props) => {
  const classes = useStyles();
  const { chartOptions, getChartComponent, files } = props;
  return (
    <React.Fragment>
      <Grid container spacing={6} style={{ marginTop: "40px" }}>
        {Object.keys(roles).map((roleKey, idx) => (
          <Grid item container xs={12} sm={4} key={idx}>
            <Grid item xs={12}>
              <Typography align="center">{roles[roleKey]}</Typography>
            </Grid>
            {Object.keys(chartOptions).forEach((chartKey, index) => {
              if (chartOptions[chartKey] && !commonCharts.includes(chartKey)) {
                const chartComponentObj = getChartComponent(chartKey, roleKey);
                if (!chartComponentObj) {
                  return <div></div>;
                }

                if (chartComponentObj.fileDependencies) {
                  for (
                    let i = 0;
                    i < chartComponentObj.fileDependencies?.length;
                    i++
                  ) {
                    const re = new RegExp(
                      chartComponentObj.fileDependencies[i] +
                        "(-mother|-father|-child)?"
                    );
                    if (!Object.keys(files).some((tag) => re.test(tag))) {
                      return <div key={index}></div>;
                    }
                  }
                }

                return (
                  <Grid
                    className={classes.chartContainer}
                    item
                    xs={12}
                    key={index}
                  >
                    {chartComponentObj.component}
                  </Grid>
                );
              }
            })}
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default TrioCharts;
