export const cellDataGetter = (_ref) => {
  var dataKey = _ref.dataKey,
    rowData = _ref.rowData;
  if (rowData) {
    if (typeof rowData.get === "function") {
      return rowData.get(dataKey);
    } else {
      return rowData[dataKey];
    }
  }
};
