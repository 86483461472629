import { makeStyles, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import clsx from "clsx";
import { ArrowDropUp } from "@material-ui/icons";

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      width: "95%",
      flexDirection: "column",
      paddingBottom: theme.spacing(2),
    },
    child: {
      padding: `${theme.spacing(0.7)}px 0`,
      display: "flex",
      fontSize: 14,
      position: "relative",
    },
    read: {
      backgroundColor: theme.palette.exons.primary,
      width: `${props.value}%`,
      animation: `$read 0.5s ${theme.transitions.easing.easeInOut}`,
    },
    notRead: {
      backgroundColor: theme.palette.exons.secondary,
      // backgroundColor: "#FFF !important",
      width: `${100 - props.value}%`,
      animation: `$notRead 0.5s ${theme.transitions.easing.easeInOut} 0.5s both`,
    },
    value: {
      flexDirection: "column",
      position: "relative",
      width: `${props.value}%`,
      animation: `$read 0.5s ${theme.transitions.easing.easeInOut}`,
      right: -5,
      textAlign: "right",
      "& svg": {
        fontSize: 20,
      },
    },
    arrow: {
      position: "absolute",
      top: 0,
      right: 0,
      "& svg": {
        transform: "translate(25%, 0)",
      },
    },
    valueChild: {
      position: "absolute",
      top: 10,
      right: 0,
      transform: "translate(50%, 10%)",
    },
    percantage: {
      width: "100%",
      margin: "auto",
      maxWidth: 360,
      color: "white",
      borderRadius: theme.shape.borderRadius,
      display: "flex",
      overflow: "hidden",
    },
    "@keyframes read": {
      "0%": { width: 0, display: "none" },
      "100%": { width: `${props.value}%` },
    },
    "@keyframes notRead": {
      "0%": { width: 0, display: "block" },
      "100%": { width: `${100 - props.value}%` },
    },
  }));

function CellContent({ value }) {
  const classes = useStyles({
    value,
  })();

  return (
    <div className={classes.root}>
      <Value classes={classes} value={value} />
      <span className={clsx(classes.child, classes.value)}>
        <span className={classes.arrow}>
          <ArrowDropUp />
        </span>
        <span className={classes.valueChild}>{value}%</span>
      </span>
    </div>
  );
}

function Value({ classes, value }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={classes.percantage}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Tooltip open={open} title={`${value}%`}>
        <div className={clsx(classes.child, classes.read)} />
      </Tooltip>
      <Tooltip open={open} title={`${100 - value}%`}>
        <div className={clsx(classes.child, classes.notRead)} />
      </Tooltip>
    </div>
  );
}

export default CellContent;
