import { createTheme, colors, alpha } from "@material-ui/core";
// import { trTR } from "@material-ui/core/locale";
import shadows from "./shadows";
import typography from "./typography";

const main = alpha("#003866", 0.85);

const darkTheme = createTheme({
  palette: {
    type: "dark",
    background: {
      mainBackground: "#212021",
      secondBackground: "#2D2D2D",
      dark: "#2D2D2D",
      // default: colors.common.white,
      // paper: colors.common.white,
    },
    svg: {
      primary: colors.grey[600],
    },
    primary: {
      main: main,
    },
    main: {
      primary: colors.common.white,
    },
    secondary: {
      main: colors.pink[400],
    },
    text: {
      primary: colors.common.white,
      secondary: colors.blueGrey[600],
    },
    navbar: {
      primary: colors.pink[200],
      background: colors.grey[700],
    },
    title: {
      primary: colors.grey[50],
      secondary: colors.grey[300],
    },
    header: {
      primary: colors.common.white,
    },
    tab: {
      primary: colors.common.white,
    },
    outline: {
      primary: alpha(main, 0.5),
      text: colors.common.white,
    },
    filter: {
      primary: "#2D2D2D7A",
      button: "#388e3c",
    },
    tableLayout: {
      text: "white",
    },
    exons: {
      primary: colors.grey[400],
      secondary: colors.pink[400],
    },
    table: {
      cell: {
        text: {
          primary: colors.common.white,
        },
        background: {
          primary: "#121212",
        },
      },
    },
  },
  shadows,
  typography,
});

export default darkTheme;
