/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const getLastChrSuffix = (dataset, chr) => {
  if (!dataset.length) {
    return 0;
  }

  let suffix = 0;

  for (let i = 0; i < dataset.length; i++) {
    const datasetLabel = dataset[i].label.split(" ");
    if (datasetLabel[0] === chr && parseInt(datasetLabel[1]) > suffix) {
      suffix = parseInt(datasetLabel[1]);
    }
  }

  return suffix;
};

const CNLineChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const [xRange, setXRange] = useState([]);
  const [chrReverse, setChrReverse] = useState(undefined);
  const [chromosomePositions, setChromosomePositions] = useState(undefined);
  const [sex, setSex] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["report_information"].download_address)
        .then((response) => response.json())
        .then((data) => {
          const gender = data.gender
            ? data.gender === "XY"
              ? "male"
              : "female"
            : "male";
          if (mounted) {
            setSex(gender);
          }
        });
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSex("male");
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  // load chromosome lines
  useEffect(() => {
    let mounted = true;
    if (sex) {
      let prevPosition = 0;
      const chrPositions = {};
      fetch("/assets/statistics/bam_chr_statsfrom_samtools")
        .then((response) => response.text())
        .then((data) => {
          let chrData = data.split("\n");
          const uselessChr = sex === "female" ? 2 : 1;
          chrData = chrData.slice(0, chrData.length - uselessChr);
          for (let i = 0; i < chrData.length; i++) {
            const chromosome = chrData[i].split("\t");
            const position = prevPosition + parseFloat(chromosome[1]);
            chrPositions[position] = chromosome[0];
            prevPosition = position;
          }
          if (mounted) {
            setChromosomePositions(chrPositions);
          }
        });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [sex]);

  // process cnv data and add them to chart dataset
  useEffect(() => {
    let mounted = true;
    if (chromosomePositions && sex) {
      const uselessChr = sex === "female" ? 2 : 1;
      const reverseChromosomes = {};
      const chrPos = Object.keys(chromosomePositions);
      for (let i = 0; i < chrPos.length; i++) {
        if (i === 0) {
          reverseChromosomes["chr1"] = 0;
        } else {
          reverseChromosomes[chromosomePositions[chrPos[i]]] = chrPos[i - 1];
        }
      }
      try {
        fetch(getBaseURL() + files["calling"].download_address)
          .then((response) => response.text())
          .then((rawData) => {
            const labels = [];
            const dataset = [];

            rawData = rawData.split("\n");
            const columns = rawData[0].split("\t");
            const columnIndex = {};
            for (let i = 0; i < columns.length; i++) {
              columnIndex[columns[i]] = i;
            }

            rawData = rawData.slice(1);

            for (let i = 0; i < rawData.length - 1; i++) {
              const data = [];
              const row = rawData[i].split("\t");
              const chromosomeName =
                row[columnIndex["chromosome"]].split("_")[0];
              const start = parseInt(row[columnIndex["start"]]);
              let end = parseInt(row[columnIndex["end"]]);
              const copyNumber = parseInt(row[columnIndex["cn"]]);

              if (end - start < 10000000) {
                end += 10000000;
              }

              const position = parseInt(reverseChromosomes[chromosomeName]);
              labels.push(start + position); // start
              data.push({
                x: start + position,
                y: copyNumber,
              });
              labels.push(end + position); // end
              data.push({
                x: end + position,
                y: copyNumber,
              });

              let suffix = getLastChrSuffix(dataset, chromosomeName) + 1;

              dataset.push({
                label: chromosomeName + " " + suffix,
                data: data,
                borderWidth: 5,
                pointRadius: 0,

                borderColor:
                  copyNumber === 0
                    ? colors.red[400]
                    : copyNumber === 1
                    ? sex === "male" &&
                      ["chrX", "chrY"].includes(chromosomeName)
                      ? colors.green[400]
                      : colors.pink[400]
                    : copyNumber === 2
                    ? sex === "male" &&
                      ["chrX", "chrY"].includes(chromosomeName)
                      ? colors.pink[400]
                      : colors.green[400]
                    : copyNumber === 3
                    ? colors.blue[400]
                    : copyNumber === 4
                    ? colors.indigo[400]
                    : colors.grey[400],
              });
            }
            if (mounted) {
              setSampleData({
                labels: labels,
                datasets: [...dataset],
              });

              setXRange([labels[0], chrPos[chrPos.length - uselessChr]]);
              setChrReverse(reverseChromosomes);
            }
          });
      } catch (e) {
        safeLogger(e);
        if (mounted) {
          setSampleData({ labels: [], datasets: [] });
        }
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [chromosomePositions, sex]);

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    title: {
      display: true,
      text: t("Chromosomes-based copy numbers"),
    },
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: function (context) {
          return t("Copy number") + ": " + context.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 4,
          },
          afterBuildTicks: function (axis, ticks) {
            return [0, 1, 2, 3, 4];
          },
          scaleLabel: {
            display: true,
            labelString: t("CN"),
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            minRotation: 90,
            min: xRange[0],
            max: xRange[1],
            callback: (value) => {
              return chromosomePositions[value];
            },
          },
          afterBuildTicks: function (axis, ticks) {
            ticks = Object.values(chrReverse);
            return ticks;
          },
          scaleLabel: {
            display: true,
            labelString: t("Positions"),
          },
        },
      ],
    },
  };

  if (!sampleData || !chrReverse || !xRange.length) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <Line height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default CNLineChart;
