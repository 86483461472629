/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

// function rangeMap(x, inMin, inMax, outMin, outMax) {
//   return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
// }

function getColor(d, a1, a2) {
  return a1.map((p, i) => Math.floor(a1[i] + d * (a2[i] - a1[i])));
}

const asc = (arr) => arr.sort((a, b) => a - b);

// const getPercentile = (data, q) => {
//   // const arr = [];
//   // for (let i = 0; i < data.length; i++) {
//   //   arr.push(data[i]);
//   // }
//   const sorted = asc(data);
//   const pos = (sorted.length - 1) * q;
//   const base = Math.floor(pos);
//   const rest = pos - base;
//   if (sorted[base + 1] !== undefined) {
//     return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
//   } else {
//     return sorted[base];
//   }
// };

const GenomicFractionCoverageBarChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files, tagSuffix } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const [isBackgroundSet, setIsBackgroundSet] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    // const re = RegExp("genome_fraction_coverage(-mother|-father|-child)?");
    // const fileTag = Object.keys(files).find((tag) => re.test(tag));
    const fileTagSuffix = tagSuffix ? "-" + tagSuffix : "";
    try {
      fetch(
        getBaseURL() +
          files["genome_fraction_coverage" + fileTagSuffix].download_address
      )
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n");
          const axisLabels = rawData[0];
          rawData = rawData.slice(1);
          const labels = []; // coverage
          const data = []; // coverage (fraction of reference)
          // const backgroundColor = [];
          // const hoverBackgroundColor = [];
          for (let i = 0; i < rawData.length; i++) {
            const row = rawData[i].split("\t");
            labels.push(row[0]);
            data.push(row[1]);
            // backgroundColor.push(
            //   colors[materialColors[i % materialColors.length]][300]
            // );
            // hoverBackgroundColor.push(
            //   colors[materialColors[i % materialColors.length]][500]
            // );
          }

          if (mounted) {
            setSampleData({
              labels: labels,
              datasets: [
                {
                  label: axisLabels.split("\t")[0],
                  data: data,
                  // backgroundColor: backgroundColor,
                  // hoverBackgroundColor: hoverBackgroundColor,
                },
              ],
            });
          }
        });
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSampleData({ labels: [], datasets: [] });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (sampleData && !isBackgroundSet) {
      const backgroundColor = [];
      const hoverBackgroundColor = [];
      const data = sampleData.datasets[0].data;
      const sorted = asc(data.slice(0));
      for (let i = 0; i < data.length; i++) {
        const dataIndex = sorted.indexOf(data[i]);
        let colorOpacity = dataIndex / sorted.length;

        // colorOpacity = rangeMap(colorOpacity, 0, 1, 0.4, 0.9);
        // const hoverColorOpacity = parseFloat(colorOpacity) + 0.1;

        // backgroundColor.push(`rgba(183, 28, 28, ${colorOpacity})`);
        // hoverBackgroundColor.push(`rgba(183, 28, 28, ${hoverColorOpacity})`);

        const barColor = getColor(colorOpacity, [255, 193, 7], [76, 175, 80]);

        backgroundColor.push(`rgba(${barColor.join(",")}, 0.7)`);
        hoverBackgroundColor.push(`rgba(${barColor.join(",")}, 1)`);
      }

      sampleData.datasets[0].backgroundColor = backgroundColor;
      sampleData.datasets[0].hoverBackgroundColor = hoverBackgroundColor;
      setIsBackgroundSet(true);
      setSampleData({ ...sampleData });
    }
  }, [sampleData]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      text: t("Genomic fraction coverage"),
    },
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: function (context) {
          return t("Fraction of reference") + ": " + context.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            stepSize: 5,
            max: 100,
          },
          scaleLabel: {
            display: true,
            labelString: t("Fraction of reference"),
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t("Coverage (X)"),
          },
        },
      ],
    },
  };

  if (!sampleData || !isBackgroundSet) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <Bar height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default GenomicFractionCoverageBarChart;
