const layoutVisibleCols = [
  "main.uploaded_variation",
  "main.variation",
  "main.existing_variation",
  "main.rs_id",
  "main.symbol",
  "main.gene_symbol",
  "main.variant_class",
  "main.consequence",
  "main.ad",
  "Main.allele_depth",
  "main.af_vcf",
  "Main.allele_fraction",
  "main.dp",
  "main.total_depth",
  "main.res",
  "main.pathogenicity_rules",
  "main.significancy",
  "main.pathogenicity",
  "main.hgvsc",
  "main.genotype",
  "details3.exac_af",
  "details3.af_exac",
  "details3.af",
  "Details3.af_1000g",
  "details3.gnomad_af",
  "details3.af_exome_gnomad",
  "details3.max_af",
  "details4.clinvar_clinical_significance",
  "details4.clinvar_submission",
  "details4.clinvar_clnsigconf",
  "details4.clinvar_submission",
  "details4.clinvar_clnrevstat",
  "details4.clinvar_review_status",
  "details4.clin_star_inf",
  "details4.clinvar_star",
  "details4.primary_site",
  "details4.cosmic_primary_site",
  "details4.primary_histology",
  "details4.cosmic_primary_histology",
  "details4.tier",
  "details4.cosmic_tier",
  "details4.drug_cosmic",
  "details4.cosmic_fusion",
  "details4.phenotype_information",
  "details4.drug/phenotype",
  "main.gt_mother",
  "main.gt_father",
  "main.gt_child",
  "main.inheritance",
  "chromosome",
  "start",
  "end",
  "gene",
  "morbid",
  "type",
  "length(kb)",
  "log2",
  "cn",
  "depth",
  "probes",
  "weight",
  "decipher",
  "dgv",
];

function adaptUserCols(cols) {
  return cols.map((col) =>
    layoutVisibleCols.includes(col.name.toLowerCase())
      ? {
          ...col,
          representation: {
            ...col.representation,
            options: { ...col.representation.options, layout_visible: true },
          },
        }
      : col
  );
}

export { adaptUserCols };
