/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const asc = (arr) => arr.sort((a, b) => a - b);

const getPercentile = (data, q) => {
  const arr = [];
  for (let i = 0; i < data.length; i++) {
    arr.push(data[i].y);
  }
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

const processData = (rawData, calculateMean) => {
  const labels = [];
  const data = [];
  const allData = [];
  let sum = 0;

  for (let i = 0; i < rawData.length - 1; i++) {
    const item = rawData[i].split("\t");
    const newItem = [parseFloat(item[0]), parseFloat(item[1])];
    allData.push(newItem[1]);
    if (calculateMean) sum += newItem[1];
    if (!data.length || newItem[1] !== data[data.length - 1].y) {
      labels.push(newItem[0]);
      data.push({ x: newItem[0], y: newItem[1] });
    }
  }

  const mean = calculateMean ? sum / data.length : undefined;

  return {
    labels,
    data,
    allData,
    mean,
  };
};

const CoverageLineChart = (props) => {
  const classes = useStyles();
  const { helpInformation } = props;
  const [chromosomePositions, setChromosomePositions] = useState(undefined);
  const [chromosomeLines, setChromosomeLines] = useState(undefined);
  const [qualimapJson, setQualimapJson] = useState(undefined);
  const [sampleData, setSampleData] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [gcData, setGcData] = useState(undefined);
  const [gcOptions, setGcOptions] = useState(undefined);

  useEffect(() => {
    if (chromosomeLines) {
      fetch("/assets/statistics/coverage_across_reference.txt")
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n").slice(1);
          const processedData = processData(rawData);

          let p75 = getPercentile(processedData.data, 0.75);
          p75 = p75 > 0 ? parseFloat((p75 * 1.8).toFixed(2)) : undefined;

          setSampleData({
            labels: processedData.labels,
            datasets: [
              {
                label: "Coverage",
                data: processedData.data,
                borderColor: colors[materialColors[3]][400],
                pointRadius: 0,
                borderWidth: 1,
              },
            ],
          });

          setOptions({
            annotation: {
              annotations: chromosomeLines,
            },
            maintainAspectRatio: false,
            responsive: true,
            title: {
              display: true,
              text: "Coverage across reference",
            },
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: { display: false },
            scales: {
              yAxes: [
                {
                  ticks: {
                    stepSize: 0.00249,
                    min: 0,
                    max: p75,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Coverage (X)",
                  },
                },
              ],
              xAxes: [
                {
                  type: "linear",
                  ticks: {
                    max: processedData.data[processedData.data.length - 1].x,
                    minRotation: 90,
                    callback: (value) => {
                      return Object.keys(chromosomePositions).includes(value)
                        ? chromosomePositions[value]
                        : undefined;
                    },
                    // callback: function (value, index, values) {
                    //   safeLogger(index);
                    //   // return processedData.labels[index];
                    //   return;
                    // },
                  },
                  scaleLabel: {
                    display: false,
                    labelString: "Position (bp)",
                  },
                },
              ],
            },
          });
        });
    }
  }, [chromosomeLines]);

  useEffect(() => {
    if (chromosomeLines && options && qualimapJson) {
      fetch("/assets/statistics/mapping_quality_across_reference.txt")
        .then((response) => response.text())
        .then((rawData) => {
          rawData = rawData.split("\n").slice(1);
          const processedData = processData(rawData, true);

          chromosomeLines.push({
            drawTime: "afterDatasetsDraw",
            type: "line",
            mode: "horizontal",
            scaleID: "y-axis-0",
            // value: processedData.mean,
            value: qualimapJson["coverage_(inside_of_regions)"].Mean,
            borderWidth: 1,
            borderColor: colors[materialColors[1]][300],
            borderDash: [10, 5],
            label: {
              content: "mean coverage",
              enabled: true,
              position: "right",
              fontColor: "#313131",
              fontSize: 10,
              backgroundColor: "rgba(0,0,0,0.1)",
              yAdjust: -5,
            },
          });

          setGcData({
            labels: processedData.labels,
            datasets: [
              {
                data: processedData.data,
                borderColor: colors[materialColors[4]][400],
                pointRadius: 0,
                borderWidth: 1,
              },
            ],
          });

          setGcOptions({
            annotation: {
              annotations: chromosomeLines,
            },
            maintainAspectRatio: false,
            responsive: true,
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: { display: false },
            scales: {
              yAxes: [
                {
                  ticks: {
                    stepSize: 25,
                    min: 0,
                    max: 100,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "GC (%)",
                  },
                },
              ],
              xAxes: [
                {
                  type: "linear",
                  position: "bottom",
                  ticks: {
                    max: processedData.data[processedData.data.length - 1].x,
                    // max: options.scales.xAxes[0].ticks.max,
                    minRotation: 90,
                    callback: (value) => {
                      return chromosomePositions[value];
                    },
                  },
                  afterBuildTicks: function (axis, ticks) {
                    ticks = Object.keys(chromosomePositions);
                    return ticks;
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Position (bp)",
                  },
                },
              ],
            },
          });
        });
    }
  }, [chromosomeLines, options, qualimapJson]);

  useEffect(() => {
    let prevPosition = 0;
    const chrPositions = {};
    const chrLines = [];
    fetch("/assets/statistics/bam_chr_statsfrom_samtools")
      .then((response) => response.text())
      .then((data) => {
        let chrData = data.split("\n");
        chrData = chrData.slice(0, chrData.length - 1);
        for (let i = 0; i < chrData.length; i++) {
          const chromosome = chrData[i].split("\t");
          const position = prevPosition + parseFloat(chromosome[1]);
          chrPositions[position] = chromosome[0];
          chrLines.push({
            drawTime: "afterDatasetsDraw",
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: position,
            borderWidth: 1,
            borderColor: colors[materialColors[16]][300],
            borderDash: [10, 5],
            label: {
              // content: chrKeys[i],
              enabled: false,
              // position: "top",
              // fontColor: "#313131",
              // fontSize: 10,
              // backgroundColor: "rgba(0,0,0,0)",
              // yAdjust: (i % 2) * 15,
            },
          });
          prevPosition = position;
        }
        setChromosomePositions(chrPositions);
        setChromosomeLines(chrLines);
      });
  }, []);

  useEffect(() => {
    fetch("/assets/statistics/qualimapReport.json")
      .then((response) => response.json())
      .then((data) => {
        setQualimapJson(data);
      });
  }, []);

  if (!sampleData || !options || !gcData || !gcOptions) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <div>
          <Line height={450} data={sampleData} options={options} />
        </div>
        <div style={{ paddingLeft: "10px" }}>
          <Line height={100} data={gcData} options={gcOptions} />
        </div>
      </ChartWrapper>
    );
  }
};

export default CoverageLineChart;
