import {
  DialogContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Download } from "react-feather";
import { getBaseURL } from "src/config/api";
import _ from "lodash";

export function DiagnosisMaterialsTable({ t, rowData }) {
  return (
    <DialogContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("File Name")}</TableCell>
            <TableCell>{t("Download")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(rowData.diagnosis_materials) &&
            Object.entries(rowData.diagnosis_materials).map((file) => {
              //   let fileName = file[0].split("/");
              //   fileName = fileName[fileName.length - 1];
              return (
                <TableRow>
                  <TableCell>{file[0]}</TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      href={
                        "/see-text?download_address=" +
                        file[1] +
                        "&file_name=" +
                        file[0]
                      }
                    >
                      <IconButton>
                        <Download />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </DialogContent>
  );
}
