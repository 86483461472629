export const appPages = [
  {
    title: "Dashboard",
    slug: "/dashboard",
    description: "Massive Analyser dashboard",
  },
  {
    title: "Projects",
    slug: "/projects",
    description: "List and access all the projects",
  },
  {
    title: "Create project",
    slug: "/projects/create",
    description:
      "Create a new project and upload sample files and submit for analysis",
  },
  {
    title: "Account",
    slug: "/account",
    description:
      "Manage your account details such as first name, last name, email address, ...",
  },
  {
    title: "Settings",
    slug: "/settings",
    description:
      "Manage dashboard settings such as language preferences, variant table layout and filter configuration",
  },
  {
    title: "Help",
    slug: "/help",
    description: "Dashboard help page",
  },
];
