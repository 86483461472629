import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import { materialColors } from "./conf";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const DuplicationRateHistogram = () => {
  const classes = useStyles();
  const [sampleData, setSampleData] = useState(undefined);

  useEffect(() => {
    fetch("/assets/statistics/duplication_rate_histogram.txt")
      .then((response) => response.text())
      .then((rawData) => {
        rawData = rawData.split("\n");
        const axisLabels = rawData[0];
        rawData = rawData.slice(1);
        const labels = []; // Duplication rate
        const data = []; // Number of loc
        const backgroundColor = [];
        const hoverBackgroundColor = [];
        for (let i = 0; i < rawData.length; i++) {
          const row = rawData[i].split("\t");
          labels.push(row[0]);
          data.push(row[1]);
          backgroundColor.push(
            colors[materialColors[i % materialColors.length]][300]
          );
          hoverBackgroundColor.push(
            colors[materialColors[i % materialColors.length]][500]
          );
        }

        setSampleData({
          labels: labels,
          datasets: [
            {
              label: axisLabels.split("\t")[0],
              data: data,
              backgroundColor: backgroundColor,
              hoverBackgroundColor: hoverBackgroundColor,
            },
          ],
        });
      });
  }, []);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      text: "Duplication rate histogram",
    },
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            stepSize: 25000,
          },
          scaleLabel: {
            display: true,
            labelString: "Number of loc",
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Duplication rate",
          },
        },
      ],
    },
  };

  if (!sampleData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <div className={classes.root}>
        <Bar height={500} data={sampleData} options={options} />
      </div>
    );
  }
};

export default DuplicationRateHistogram;
