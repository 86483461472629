import { TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";

function CustomPdfInput({ input, onChange, objectKey, value }) {

  useEffect(() => {
    if (input.type === "date" && !value[objectKey][input.name]) {
      onChange(input.name, moment().format("YYYY-MM-DD"), objectKey);
    }
  }, [input.type, input.name, objectKey, onChange, value]);

  if (input.type === "date") {
    return (
      <TextField
        type={input.type}
        label={input.label}
        variant="outlined"
        name={input.name}
        value={value[objectKey][input.name] || moment().format("YYYY-MM-DD")}
        onChange={(e) => onChange(e.target.name, e.target.value, objectKey)}
      />
    );
  } else if (input.type === "string") {
    return (
      <TextField
        variant="outlined"
        name={input.name}
        value={value[objectKey][input.name] ?? ""}
        label={input.label}
        onChange={(e) => onChange(e.target.name, e.target.value, objectKey)}
      />
    );
  }
}

export default CustomPdfInput;
