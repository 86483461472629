import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Convert from "ansi-to-html";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiDiagnoseAnalysisGet } from "src/api/endpoints";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  ansi: {
    backgroundColor: "#E1E1E1B0",
    width: "100%",
    padding: theme.spacing(2),
    "& span": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

function FailLogs({ id, isFailed }) {
  const [log, setLog] = useState();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const regex = new RegExp(/(\r\n?|\n)/g);

  const classes = useStyles();
  const callback = (data, status) => {
    if (status === 200) {
      data = data.replace(regex, "<br/>");
      data = data.replace("--------------------------------+", "-+");
      const length = 100000;
      const dataFristLength = data.length;
      const array = [];
      if (data.length > length) {
        for (let i = length; dataFristLength > i; i += length) {
          const newData = data.split("", length);
          array.push(newData.concat(""));
          data = data.slice(length);
        }
        if (data.length > 0) {
          const newData = data.split("", length);
          array.push(newData.concat(""));
          data = data.slice(length);
        }
      }
      setLog(array.length > 0 ? array : data);
    } else {
      enqueueSnackbar(t("Someting went wrong"), {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (id && isFailed) {
      apiDiagnoseAnalysisGet(id, callback);
    }
  }, [id, isFailed]);
  if (!isFailed) {
    return <Typography>{t("Sample hasn't error log")}</Typography>;
  }
  var convert = new Convert();
  return (
    <Box
      style={{
        display: !log && "flex",
        justifyContent: !log && "center",
        alignItems: !log && "center",
        height: 500,
        overflow: "scroll",
      }}
    >
      {log ? (
        <>
          {_.isArray(log) ? (
            <>
              {log.map((value) => (
                <Box
                  className={classes.ansi}
                  dangerouslySetInnerHTML={{
                    __html: convert.toHtml(value),
                  }}
                />
              ))}
            </>
          ) : (
            <Box
              className={classes.ansi}
              dangerouslySetInnerHTML={{
                __html: convert.toHtml(log),
              }}
            />
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

export default FailLogs;
