import {
  Container,
  makeStyles,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Page from "src/components/Page";
import TabPanel from "src/components/tabPanel";
import { Avarage } from "./avarage";
import Liftover from "./liftover";
const mapStateToPropsPage = (state) => {
  return {
    appConf: state.appConf,
    userObj: state.userObject,
  };
};

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    color: grey[800],
  },
  grid: {
    "& .MuiGrid-item": {},
  },
}));

const ConnectedCalculator = (props) => {
  const classes = useStyles();
  // const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { appConf } = props;
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Page className={classes.root} title={t("Calculator")}>
      <Container>
        <Grid container className={classes.grid} spacing={3}>
         <Grid item xs={12} /*  style={{ display: "flex" }} */>  
            <Box>
              <Typography
                component="h3"
                variant="h3"
                data-testid="page-title"
                className={classes.pageTitle}
              >
                {t("Calculator")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={3}>
            <Tabs
              orientation="vertical"
              value={tabValue}
              onChange={handleChangeTab}
              className={classes.tabs}
            >
              <Tab label={t("Expected Coverage Calculator")} />
              <Tab label={t("Lıftover")} />
            </Tabs>
          </Grid>
          <Grid item xs={8} md={9}>
            <TabPanel value={tabValue} index={0} width={"%100"}>
              <Avarage appConf={appConf} />
            </TabPanel>
            <TabPanel value={tabValue} index={1} width={"%100"}>
              <Liftover appConf={appConf} />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export const Calculator = connect(
  mapStateToPropsPage,
  null
)(ConnectedCalculator);
