/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Flow from "@flowjs/flow.js";
import { useSnackbar } from "notistack";
import { apiCancelProject } from "src/api";
import { getBaseURL, urlAPIConf } from "src/config/api";
import {
  makeStyles,
  Box,
  Typography,
  LinearProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  CircularProgress,
  Chip,
  Grid,
} from "@material-ui/core";
import {
  Cancel as CancelIcon,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  AttachFile as AttachFileIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  ExpandMore as ExpandMoreIcon,
  Check,
  Close,
} from "@material-ui/icons";
import { NetworkActivityMonitor } from "./networkActivityMonitor";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { apiPostError } from "src/api/endpoints";

const useDropZoneStyles = makeStyles((theme) => ({
  hidden: {
    display: "none !important",
  },
  mainZone: {
    alignItems: "center",
    // display: "flex",
    display: "block",
    padding: "40px",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    minHeight: "250px",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "4px dashed #e1e1e1",
    "&.hover": {
      backgroundColor: "azure",
      borderColor: "lightblue",
    },
  },
  progressAccordion: {
    width: "100%",
    "& .total-progress-text": {
      marginLeft: theme.spacing(4),
    },
    "& .main-progress-bar .MuiLinearProgress-root": {
      top: "22px",
    },
    "& .progress-percent": {
      marginLeft: "5px",
      marginTop: "14px",
    },
    "& .progress-details": {
      backgroundColor: "#F0F0F0",
    },
  },
  message: {
    color: theme.palette.text.disabled,
    textAlign: "center",
    zIndex: "10",
    // width: "100%",
    fontSize: "30px",
    fontWeight: "400",
  },
  overlay: {
    cursor: "default",
    background: theme.palette.action.disabled,
    opacity: "0.5",
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0",
    left: "0",
    zIndex: "100",
  },
  fileInput: {
    display: "flex",
    zIndex: "10",
    position: "absolute",
    top: "-500px",
  },
  filesContainer: {
    zIndex: "100",
    "& .single-file": {
      position: "relative",
      cursor: "default",
    },
    "& .file-delete-icon": {
      cursor: "pointer",
      transition: "all .3s",
      backgroundColor: theme.palette.background.paper,
      position: "absolute",
      zIndex: "200",
      padding: "5px",
      color: "grey",
      top: "-20px",
      right: "-20px",
      fontSize: "40px",
      boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px",
      borderRadius: "50%",
      "&:hover": {
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 2px 4px",
        "& path": {
          transition: "all .3s",
          color: theme.palette.error.main,
        },
      },
    },
    "& .file-paper-clip": {
      backgroundColor: theme.palette.background.paper,
      fontSize: "40px",
      padding: "5px",
      boxShadow: "0 1px 3px rgba(0,0,0,0), 0 1px 2px",
    },
    "& .file-meta-name": {
      marginLeft: "30px",
    },
  },
}));

const IconLeftAccordionSummary = withStyles({
  expandIcon: {
    order: -1,
  },
})(AccordionSummary);

const SingleFileProgressBar = (props) => {
  const { file } = props;
  const [progress, setProgress] = useState(file.progress() * 100);
  const [progressColor, setProgressColor] = useState("secondary");

  file.flowObj.on("progress", function () {
    setProgress(file.progress() * 100);
  });

  useEffect(() => {
    if (file.retryCount > 0) {
      file.retryCount = 0;
    }
    if (progress === 100) {
      setProgressColor("primary");
    }
    // const timer = setInterval(() => {
    //   setProgress(file.progress() * 100);
    // }, 500);
    // return () => {
    //   clearInterval(timer);
    // };
  }, [progress]);

  return (
    <Box display="flex" alignItems="center" mt={2} mb={2}>
      {file.name}
      <Box width="100%" ml={2} mr={1}>
        <LinearProgress
          variant="determinate"
          color={progressColor}
          value={progress}
        />
      </Box>
      <Box minWidth={35} mr={1}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          progress
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

// const UploadProgressBar = (props) => {
//   //   const classes = useStyles();
//   const [progress, setProgress] = useState(0);
//   const { flowObject } = props;

//   flowObject.on("complete", () => {
//     safeLogger("upload completed");
//   });

//   // useEffect(() => {
//   //   safeLogger(flowObject.files);
//   // }, [flowObject]);

//   // useEffect(() => {
//   //   const timer = setInterval(() => {
//   //     safeLogger(flowObject.progress());
//   //     setProgress(flowObject.progress());
//   //   }, 500);
//   //   return () => {
//   //     clearInterval(timer);
//   //   };
//   // }, []);

//   return (
//     <React.Fragment>
//       {flowObject.files &&
//         flowObject.files.map((file, index) => {
//           return (
//             <div key={index}>
//               {file.name}
//               <SingleFileProgressBar file={file} />
//             </div>
//           );
//         })}
//     </React.Fragment>
//   );
// };

const mapStateToPropsDropZone = (state) => {
  return {
    authToken: state.authToken || localStorage.getItem("mbiAuth"),
    appConf: state.appConf,
  };
};

const ConnectedDropZone = (props) => {
  const classes = useDropZoneStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [dragHoverClass, setDragHoverClass] = useState("");

  const [showProgress, setShowProgress] = useState(false);
  const [expandedProgress, setExpandedProgress] = useState(false);
  const [progressColor, setProgressColor] = useState("secondary");
  const [totalUploadProgress, setTotalUploadProgress] = useState(0);

  const [zeroSpeedCount, setZeroSpeedCount] = useState(0);
  const [currentSpeed, setCurrentSpeed] = useState("0 MB/s");
  const [averageSpeed, setAverageSpeed] = useState("0 MB/s");
  const [uploadRemainingTime, setUploadRemainingTime] = useState(
    Number.POSITIVE_INFINITY
  );

  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [isUploadOnPause, setIsUploadOnPause] = useState(false);

  const [cancelDialog, setCancelDialog] = useState(false);

  const { t } = useTranslation();

  const {
    appConf,
    projectId,
    setProjectId,
    projectType,
    disabled,
    setFileFullPaths,
    setDirectory,
    directory,
    files,
    phase,
    setFiles,
    setPhase,
    fileActionType,
    setFileActionType,
  } = props; // TODO if userObj.privilege doesnt contain upload, disable, or navigate to forbidden page
  const zoneMessage = t(
    "Drag and drop your sample files here or click to browse"
  );

  const [loading, setLoading] = useState();
  const [notIncludesFiles, setNotIncludesFiles] = useState([]);

  useEffect(() => {
    setLoading(false);
  }, []);
  const flow = new Flow({
    target:
      getBaseURL() +
      "/" +
      urlAPIConf.BASE_API_SLUG +
      "/" +
      urlAPIConf.PROJECTS_SLUG +
      "/" +
      projectId +
      "/" +
      urlAPIConf.PROJECT_UPLOAD_SLUG,
    simultaneousUploads: 1,
    testChunks: false,
    chunkSize: 10 * 1024 * 1024,
    forceChunkSize: true,
    maxChunkRetries: undefined,
    progressCallbacksInterval: 500,
    generateUniqueIdentifier: function (file) {
      return `${file.size}-${file.name}`;
    },
    speedSmoothingFactor: 0.02,
    headers: {
      Authorization: "Token " + props.authToken,
    },
  });

  // TODO change testChunks for production

  useEffect(() => {
    if (!flow.support) {
      enqueueSnackbar(
        t([
          "browser_nojs_error",
          "Your browser does'nt support chunk-based file upload. use the native uploader instead",
        ]),
        {
          variant: "error",
        }
      );
      // TODO Render normal file uploader
    }
  }, []);

  flow.on("complete", function () {
    setProgressColor("primary");
    setPhase("submit");
  });

  useEffect(() => {
    if (uploadingFiles.length < 2000) {
      for (let i = 0; i < uploadingFiles.length; i++) {
        const file = uploadingFiles[i];
        if (!file.paused && !file.error) {
          if (file.averageSpeed) {
            setAverageSpeed(getMBSpeed(file.averageSpeed));
          }
          if (file.currentSpeed || zeroSpeedCount > 3) {
            safeLogger("current speed: ", file.currentSpeed);
            setCurrentSpeed(getMBSpeed(file.currentSpeed));
          }
          if (!file.currentSpeed) {
            if (zeroSpeedCount > 4) {
              setZeroSpeedCount(0);
            } else {
              setZeroSpeedCount(zeroSpeedCount + 1);
            }
          }
          setUploadRemainingTime(file.flowObj.timeRemaining());
        }
      }
    }
  }, [totalUploadProgress]);

  flow.on("progress", function () {
    const progress = flow.progress() * 100;
    setTotalUploadProgress(progress);
    // if (progress === 100) {
    //   setProgressColor("primary");
    //   setPhase("submit");
    // }
  });

  flow.on("fileRetry", function (file, chunk) {
    if (!file["retryCount"]) {
      file["retryCount"] = 0;
    }
    file.retryCount += 1;

    if (window.navigator.onLine && file.retryCount > 1000) {
      file.retryCount = 0;
      apiPostError(
        {
          summary: "Upload error: exceeded max retry while online",
          details: `filename: ${file.name}`,
        },
        (data, status) => {
          safeLogger(status, data);
        }
      );
    }

    setCurrentSpeed("0 MB/s");
    const atChunk = getReadableFileSize(chunk.startByte, true);
    enqueueSnackbar(
      t(
        "file_upload_error_at_byte",
        "There was an error while uploading {{filename}} at {{chunk}}. Retrying...",
        {
          filename: file.name,
          chunk: atChunk,
        }
      ),
      {
        preventDuplicate: true,
        variant: "warning",
      }
    );
  });

  flow.on("fileSuccess", function (file, message) {
    enqueueSnackbar(
      t("file_upload_success", "{{filename}} was successfully uploaded", {
        filename: file.name,
      }),
      {
        variant: "success",
      }
    );
  });

  flow.on("fileError", function (file, message) {
    enqueueSnackbar(
      t("file_error", "An error occured for {{file.name}}: {{message}}", {
        filename: file.name,
        message: message,
      }),
      {
        variant: "error",
      }
    );
    uploadCancelHandler();
  });

  flow.on("error", function (message, file, chunk) {
    const atChunk = getReadableFileSize(chunk.startByte, true);

    if (window.navigator.onLine) {
      apiPostError(
        {
          summary: "Upload error: an error occued",
          details: `${message}  filename: ${file.name}`,
        },
        (data, status) => {
          safeLogger(status, data);
        }
      );
    }

    enqueueSnackbar(
      t(
        "upload_error",
        "An error occured for {{filename}} at {{chunk}}: {{message}}",
        { filename: file.name, chunk: atChunk, message: message }
      ),
      {
        variant: "error",
      }
    );
    uploadCancelHandler();
  });

  const getReadableFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };

  // const getReadableSpeed = (bytes) => {
  //   const dp = 1;
  //   const thresh = 1000;

  //   if (Math.abs(bytes) < thresh) {
  //     return bytes + " B/s";
  //   }

  //   const units = ["KB/s", "MB/s", "GB/s"];
  //   let u = -1;
  //   const r = 10 ** dp;

  //   do {
  //     bytes /= thresh;
  //     ++u;
  //   } while (
  //     Math.round(Math.abs(bytes) * r) / r >= thresh &&
  //     u < units.length - 1
  //   );

  //   return bytes.toFixed(dp) + " " + units[u];
  // };

  const getMBSpeed = (bytes) => {
    return (bytes / 1000000).toFixed(1) + " MB/s";
  };

  const isFileInList = (file) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].name === file.name && files[i].size === file.size) {
        enqueueSnackbar(
          t("{{file_name}} is already in the list", { file_name: file.name }),
          {
            variant: "warning",
          }
        );
        return true;
      }
    }
    return false;
  };

  // const isInFolderList = (folder) => {
  //   for (let i = 0; i < folders.length; i++) {
  //     if (folders[i].name === folder.name && folders[i].size === folder.size) {
  //       enqueueSnackbar(t(folder.name + " is already in the list"), {
  //         variant: "warning",
  //       });
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  // const addSelectedFolders = (foldersList) => {
  //   let prevFolders = folders;
  //   let newFolders = [];
  //   if (fileActionType !== "upload") {
  //     prevFolders = [];
  //     setFileActionType("upload");
  //   }

  //   for (let i = 0; i < foldersList.length; i++) {
  //     if (!isInFolderList(foldersList[i])) {
  //       newFolders.push(foldersList[i]);
  //       enqueueSnackbar(t(foldersList[i].name + " successfully added"), {
  //         variant: "success",
  //       });
  //       setFiles([...prevFolders, ...newFolders]);
  //     } else {
  //       enqueueSnackbar(t("Wrong file type"));
  //     }
  //   }
  // };

  const addSelectedFiles = (fileList) => {
    safeLogger(fileList);
    safeLogger(fileList.length);
    let prevFiles = files;

    if (fileActionType !== "upload") {
      prevFiles = [];
      setFileActionType("upload");
    }

    if (fileList.length) {
      let tmpFiles = [];
      for (let i = 0; i < fileList.length; i++) {
        if (!isFileInList(fileList[i]) && checkFile(fileList[i].name)) {
          tmpFiles.push(fileList[i]);
          // enqueueSnackbar(t(fileList[i].name + " successfully added"), {
          //   variant: "success",
          // });
        } else {
          enqueueSnackbar(t("Wrong file type"), {
            variant: "error",
          });
        }
      }
      setFiles([...prevFiles, ...tmpFiles]);
    } else {
      for (let i = 0; i < fileList.length; i++) {
        safeLogger("... file[" + i + "].name = " + fileList[i].name);
      }
    }
  };

  const overlayClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const fileInputChangeHandler = (event) => {
    addSelectedFiles(event.target.files);
  };

  const dragEnterHandler = (event) => {
    if (!disabled) {
      event.preventDefault();
      setDragHoverClass("hover");
    }
  };
  const dragLeaveHandler = (event) => {
    if (!disabled) {
      event.preventDefault();
      setDragHoverClass("");
    }
  };

  const dragOverHandler = (event) => {
    if (!disabled) {
      event.preventDefault();
      setDragHoverClass("hover");
    }
  };

  function getEntriesAsPromise(dirEntry) {
    return new Promise(async (resolve, reject) => {
      const result = [];
      let dirFiles = [];
      let notIncludesFiles = [];
      let fullPath = dirEntry.fullPath;

      async function scanFiles(item) {
        if (item.isDirectory) {
          let dirReader = item.createReader();
          let entries = [];
          let tmpEntires;
          fullPath = item.fullPath;
          do {
            tmpEntires = await new Promise((resolve, reject) => {
              dirReader.readEntries(resolve, reject);
            });
            entries.push(...tmpEntires);
          } while (tmpEntires.length === 100);
          if (entries.length < 1000) {
            if (item.name.includes("barcode"))
              dirFiles.push({ item, isUploaded: true });
            for (let entry of entries) {
              if (
                (entry.isDirectory &&
                  (entry.name.includes("barcode") ||
                    entry.name.includes("fastq_pass"))) ||
                entry.isFile
              ) {
                await scanFiles(entry);
              }
            }
          } else {
            if (item.name.includes("barcode"))
              dirFiles.push({ item, isUploaded: false });

            notIncludesFiles.push(dirReader);
          }
        } else if (item.isFile && dirFiles.length < 50) {
          item.file(function (file) {
            if (!isFileInList(file) && checkFile(file.name)) {
              const newFile = file;
              newFile.relativePath = item.fullPath.replace(item.name, "");
              newFile.flowIdentifier = `${item.name}`;
              newFile.byte_size = file.size;
              result.push(newFile);
            } else
              enqueueSnackbar(t("Wrong file type"), {
                variant: "error",
              });
          });
        }
      }

      await scanFiles(dirEntry).then(() => {
        if (notIncludesFiles.length <= 0)
          setTimeout(
            () => resolve([result, dirFiles, notIncludesFiles, fullPath]),
            1000
          );
        else
          setTimeout(
            () => resolve([[], dirFiles, notIncludesFiles, fullPath]),
            1000
          );
      });
    });
  }

  const checkFile = (data) => {
    const passDatas = {
      exome_fastq: [".fastq", ".fastq.gz", ".fq", ".fq.gz"],
      nanopore: [
        ".fastq",
        ".fastq.gz",
        ".fq",
        ".fq.gz",
        ".zip",
        ".tar.gz",
        ".tar.bz2",
        ".tar.xz",
        ".tgz",
        ".tbz2",
        ".txz",
      ],
      exome_bam: [".bam"],
      exome_vcf: [".vcf"],
      trio: [".fastq", ".fastq.gz", ".fq", ".fq.gz"],
      acnv: [".bam"],
    };
    let filePass = false;
    passDatas[projectType].map((value) => {
      if (filePass) return true;
      filePass = data.includes(value);
      return filePass;
    });
    return filePass;
  };

  async function dropHandler(event) {
    if (!disabled) {
      event.preventDefault();
      setDragHoverClass("");
      let fileList = [];

      if (event.dataTransfer.items) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          if (
            checkFile(event.dataTransfer.items[i].getAsFile().name) ||
            event.dataTransfer.items[i].webkitGetAsEntry().isDirectory
          ) {
            if (!event.dataTransfer.items[i].webkitGetAsEntry().isDirectory) {
              let file = event.dataTransfer.items[i].getAsFile();
              file.flowIdentifier = `${file.name}`;
              file.byte_size = file.size;
              fileList.push(file);
            } else {
              var item = event.dataTransfer.items[i].webkitGetAsEntry();
              if (item) {
                // traverseFileTree(item, fileList);
                setLoading(true);
                await getEntriesAsPromise(item).then((dirFiles) => {
                  if (dirFiles[2].length > 0) {
                    setDirectory(dirFiles[1]);
                    setNotIncludesFiles(dirFiles[2]);
                    setFileFullPaths(dirFiles[3]);
                  } else {
                    setDirectory((prev) => [...prev, ...dirFiles[1]]);
                    setNotIncludesFiles((prev) => [...prev, ...dirFiles[2]]);
                    setFileFullPaths((prev) => [...prev, ...dirFiles[3]]);
                  }
                  setFiles((prev) => [...prev, ...dirFiles[0]]);
                  setLoading(false);
                });
              }
            }
          } else {
            enqueueSnackbar(t("Wrong file type"), {
              variant: "error",
            });
          }
        }
      } else {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          safeLogger(
            "... file[" + i + "].name = " + event.dataTransfer.files[i].name
          );
        }
      }
      addSelectedFiles(fileList);
    }
  }

  useEffect(() => {
    if (projectId && fileActionType === "upload" && phase === "upload") {
      enqueueSnackbar(
        t(
          "project_created_uploading_message",
          "Project created. Uploading files. Please wait..."
        ),
        {
          variant: "success",
        }
      );
      flow.addFiles(files);
      flow.upload();
      setIsUploadOnPause(false);
      setUploadingFiles(flow.files);
      setShowProgress(true);
    }
  }, [projectId, phase]);

  const deleteFile = (event, file) => {
    event.preventDefault();
    event.stopPropagation();
    setFiles(files.filter((f) => f !== file));
    enqueueSnackbar(
      t("file_removed_message", "{{filename}} was removed from the list", {
        filename: file.name,
      }),
      {
        variant: "error",
      }
    );
  };

  const uploadCancelHandler = () => {
    if (uploadingFiles.length) {
      const flowObj = uploadingFiles[0].flowObj;
      flowObj.cancel();
      apiCancelProject(projectId, null, (data, status) => {
        if (status === 200) {
          setProjectId(null);
          setUploadingFiles([]);
          setTotalUploadProgress(0);
          setShowProgress(false);
          setPhase("init");
          enqueueSnackbar(
            t("file_upload_cancelled", "File uploads was cancelled!"),
            {
              variant: "warning",
            }
          );
        } else {
          safeLogger(data);
        }
      });
    }
    setCancelDialog(false);
  };

  const uploadPauseHandler = (event) => {
    if (event) {
      event.stopPropagation();
    }
    if (uploadingFiles.length) {
      const flowObj = uploadingFiles[0].flowObj;

      if (flowObj.isUploading()) {
        flowObj.pause();
        setIsUploadOnPause(true);
        setCurrentSpeed("0 B/s");
      }
    }
  };

  const uploadResumeHandler = (event) => {
    if (event) {
      event.stopPropagation();
    }
    if (uploadingFiles) {
      const flowObj = uploadingFiles[0].flowObj;

      if (!flowObj.isUploading()) {
        flowObj.resume();
        setIsUploadOnPause(false);
      }
    }
  };

  const handleCancelDialogOpen = (event) => {
    event.stopPropagation();
    uploadPauseHandler();
    setCancelDialog(true);
  };

  const handleCloseNotIncludedFiles = (e) => {
    setNotIncludesFiles([]);
  };

  const handleCancelDialogClose = () => {
    uploadResumeHandler();
    setCancelDialog(false);
  };
  return (
    <React.Fragment>
      <label
        htmlFor="file-input"
        className={clsx(classes.mainZone, dragHoverClass)}
        onDragEnter={dragEnterHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
      >
        {(!files.length || fileActionType !== "upload") && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            {!loading ? (
              <>
                <Typography className={classes.message}>
                  {zoneMessage}
                </Typography>
                <CloudUploadIcon
                  style={{
                    display: "flex",
                    fill: "rgba(0, 0, 0, 0.38)",
                    marginTop: "15px",
                    fontSize: "6em",
                  }}
                />
              </>
            ) : (
              <CircularProgress />
            )}
          </Box>
        )}
        <input
          id="file-input"
          name="file"
          type="file"
          multiple
          className={classes.fileInput}
          onChange={fileInputChangeHandler}
        />
        <Box id="filesIcons" className={classes.filesContainer} width="100%">
          {files &&
            fileActionType === "upload" &&
            files.slice(0, 2000).map((file, index) =>
              index < 100 ? (
                <Box
                  key={index}
                  width={{
                    xs: "100%",
                    sm: "50%",
                  }}
                  pr={5}
                  mb={5}
                  display="inline-flex"
                  className="single-file"
                  onClick={(event) => event.preventDefault()}
                >
                  <Box position="relative">
                    <AttachFileIcon className="file-paper-clip" />
                    <DeleteIcon
                      className={clsx("file-delete-icon", {
                        [classes.hidden]: disabled,
                      })}
                      onClick={(event) => {
                        deleteFile(event, file);
                      }}
                    />
                  </Box>
                  <Box className="file-meta-name">
                    <Typography>{file.name}</Typography>
                    <Typography>
                      {getReadableFileSize(file.size, true)}
                    </Typography>
                  </Box>
                </Box>
              ) : null
            )}
        </Box>
        <div
          className={clsx(classes.overlay, {
            [classes.hidden]: !disabled,
          })}
          onClick={overlayClickHandler}
        ></div>
      </label>
      {
        <Dialog
          open={notIncludesFiles.length > 0}
          onClose={handleCloseNotIncludedFiles}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("These folders are not included in the upload")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(
                "Folders has 1,000 or more files, please upload with zipping tool"
              )}
            </DialogContentText>
            <Grid container spacing={2}>
              {directory.map((file, index) => {
                return (
                  <Grid key={index} item xs={3}>
                    <Chip
                      style={{
                        backgroundColor: file.isUploaded ? "green" : "#ff5d50",
                        color: "white",
                      }}
                      label={
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <span style={{ marginRight: 5 }}>
                            {file.item.name}
                          </span>
                          {file.isUploaded ? <Check /> : <Close />}
                        </Box>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
            <DialogContentText style={{ marginTop: 20 }}>
              {t(
                "The following folders are not included due to the maximum number of updatable   files."
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNotIncludedFiles} color="primary">
              {t("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      }
      {showProgress ? (
        <Box
          display="flex"
          alignItems="center"
          mt={4}
          flexDirection="column"
          width="100%"
        >
          <NetworkActivityMonitor
            appConf={appConf}
            uploadRemainingTime={uploadRemainingTime}
            currentSpeed={currentSpeed}
            averageSpeed={averageSpeed}
          />
          <Accordion
            expanded={expandedProgress}
            onChange={() => setExpandedProgress(!expandedProgress)}
            className={classes.progressAccordion}
          >
            <IconLeftAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="total-progressbar-content"
              id="total-progressbar"
            >
              <Typography className="total-progress-text">
                {t("Total Upload Progress")}
              </Typography>
              <Box width="100%" mr={1} className="main-progress-bar">
                <LinearProgress
                  variant="determinate"
                  color={progressColor}
                  value={totalUploadProgress}
                />
              </Box>
              <Box minWidth={35}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="progress-percent"
                >{`${Math.round(totalUploadProgress)}%`}</Typography>
              </Box>
              {isUploadOnPause ? (
                <IconButton onClick={uploadResumeHandler}>
                  <PlayArrowIcon />
                </IconButton>
              ) : (
                <IconButton onClick={uploadPauseHandler}>
                  <PauseIcon />
                </IconButton>
              )}
              <IconButton onClick={handleCancelDialogOpen}>
                <CancelIcon />
              </IconButton>
            </IconLeftAccordionSummary>
            <AccordionDetails className="progress-details">
              <Box width="100%">
                {showProgress &&
                  uploadingFiles.slice(0, 10).map((file, index) => {
                    return <SingleFileProgressBar file={file} key={index} />;
                  })}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Dialog
            open={cancelDialog}
            onClose={handleCancelDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("Cancel file upload?")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(
                  "file_upload_cancel_prompt",
                  "Are you sure you want to cancel uploading? All the progress will be lost!"
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDialogClose} color="primary">
                {t("No")}
              </Button>
              <Button onClick={uploadCancelHandler} color="secondary" autoFocus>
                {t("Yes")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export const DropZone = connect(
  mapStateToPropsDropZone,
  null
)(ConnectedDropZone);
