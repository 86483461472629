import {
  LOAD_APP_CONF,
  SET_LANG,
  LOAD_USER,
  AUTH_SIGNIN,
  SIGNOUT,
  UPDATE_ACTIVE_SAMPLES,
  UPDATE_ACTIVE_PROJECTS,
  GENE_BASED_FILTER_CHANGE,
  GENE_BASED_FILTER_CHANGE_STATS,
  LOAD_ORGANIZATION,
  SET_THEME,
  CLEAR_ACTIVE_GENE_FILTERS,
  SET_ADVANCE_FILTER,
  SET_SAMPLE_SIZE,
} from "../constants";

export function setLang(payload) {
  return { type: SET_LANG, payload };
}

export function loadAppConf(payload) {
  return { type: LOAD_APP_CONF, payload };
}

export function loadOrganizationData(payload) {
  return { type: LOAD_ORGANIZATION, payload };
}

export function loadUser(payload) {
  return { type: LOAD_USER, payload };
}

export function authSignin(payload) {
  return { type: AUTH_SIGNIN, payload };
}

export function signOut() {
  return { type: SIGNOUT };
}

export function updateSamples(payload) {
  return { type: AUTH_SIGNIN, payload };
}

export function updateActiveSamples(payload) {
  return { type: UPDATE_ACTIVE_SAMPLES, payload };
}

export function updateActiveProjects(payload) {
  return { type: UPDATE_ACTIVE_PROJECTS, payload };
}

export function updateFilter(payload) {
  return { type: GENE_BASED_FILTER_CHANGE, payload };
}

export function updateFilterStats(payload) {
  return { type: GENE_BASED_FILTER_CHANGE_STATS, payload };
}

export function updateTheme(payload) {
  return { type: SET_THEME, payload };
}

export function clearActiveGeneFilters(payload) {
  return { type: CLEAR_ACTIVE_GENE_FILTERS, payload };
}

export function updateAdvanceFilter(payload) {
  return { type: SET_ADVANCE_FILTER, payload };
}

export function setSampleSize(payload) {
  return { type: SET_SAMPLE_SIZE, payload };
}