/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { apiUpdateProfile } from "src/api";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  defaultRow: {
    backgroundColor: theme.palette.success.light,
  },
}));

const ChartPresetRow = (props) => {
  const {
    projectType,
    schemaVersion,
    userObject,
    setChartsPresets,
    chartsPresets,
    presetKey,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDeleteObj = () => {
    let newPresets = { ...chartsPresets };
    delete newPresets[presetKey];
    userObject.settings.report[projectType].charts[schemaVersion] = newPresets;
    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      if (status === 200) {
        setChartsPresets(newPresets);
        enqueueSnackbar(t("Chart preset was successfully deleted"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(data, { variant: "error" });
      }
    });
    handleCloseDialog();
  };

  const handleSetDefault = () => {
    const newPresets = { ...chartsPresets };
    for (let item in newPresets) {
      if (newPresets[item].is_default) {
        newPresets[item].is_default = false;
        break;
      }
    }
    newPresets[presetKey].is_default = true;
    userObject.settings.report[projectType].charts[schemaVersion] = newPresets;
    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      if (status === 200) {
        setChartsPresets(newPresets);
        enqueueSnackbar(
          t(
            "chart_preset_set_as_default_message",
            "{{name}} was set as default charts configuration",
            {
              name: newPresets[presetKey].name,
            }
          ),
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(data, { variant: "error" });
      }
    });
  };

  const handleUnsetDefault = () => {
    const newPresets = { ...chartsPresets };
    newPresets[presetKey].is_default = false;
    userObject.settings.report[projectType].charts[schemaVersion] = newPresets;
    apiUpdateProfile({ settings: userObject.settings }, (data, status) => {
      if (status === 200) {
        setChartsPresets(newPresets);
        enqueueSnackbar(
          t(
            "chart_preset_default_status_cleared",
            "{{name}} default status was cleared",
            {
              name: newPresets[presetKey].name,
            }
          ),
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(data, { variant: "error" });
      }
    });
  };

  return (
    <TableRow>
      <TableCell>{chartsPresets[presetKey].name}</TableCell>
      <TableCell>
        {chartsPresets[presetKey].is_default ? (
          <Chip
            icon={<CheckIcon />}
            label={t("Default")}
            onDelete={handleUnsetDefault}
          />
        ) : (
          <Button variant="outlined" onClick={handleSetDefault}>
            {t("Set as default")}
          </Button>
        )}
        <Link to={`chartsconf/${presetKey}?project_type=${projectType}`}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
        a
        <IconButton onClick={handleOpenDialog}>
          <DeleteIcon />
        </IconButton>
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Delete chart preset?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(
                "delete_chart_preset_prompt",
                "Are you sure you want to delete chart preset '{{name}}'?",
                { name: chartsPresets[presetKey].name }
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t("No")}
            </Button>
            <Button onClick={handleDeleteObj} color="secondary" autoFocus>
              {t("Yes")}
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

export const ChartsConfigSection = (props) => {
  const classes = useStyles();
  const { appConf, userObject, projectTypeConf } = props;
  const [loading, setLoading] = useState(true);
  const [chartsPresets, setChartsPresets] = useState({});
  const { t } = useTranslation();

  const tableHeaders = [t("Name"), t("Actions")];

  useEffect(() => {
    setChartsPresets(
      userObject.settings.report[projectTypeConf.name] &&
        userObject.settings.report[projectTypeConf.name].charts
        ? userObject.settings.report[projectTypeConf.name].charts[
            appConf.schemas[projectTypeConf.name].version
          ] ?? {}
        : {}
    );
    setLoading(false);
  }, []);

  if (loading) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return Object.keys(chartsPresets).filter((key) => key !== "default")
      .length ? (
      <Box>
        <Table size="small" style={{ marginTop: "15px" }}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(chartsPresets)
              .filter((key) => key !== "default")
              .map((itemKey, index) => (
                <ChartPresetRow
                  projectType={projectTypeConf.name}
                  userObject={userObject}
                  schemaVersion={appConf.schemas[projectTypeConf.name].version}
                  chartsPresets={chartsPresets}
                  presetKey={itemKey}
                  setChartsPresets={setChartsPresets}
                  key={index}
                />
              ))}
          </TableBody>
        </Table>
      </Box>
    ) : (
      <div></div>
    );
  }
};
