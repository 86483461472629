/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  colors,
  Dialog,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import {
  Cancel,
  Error as ErrorIcon,
  TramOutlined,
  Warning as WarningIcon,
} from "@material-ui/icons";
import { Autocomplete, Rating } from "@material-ui/lab";
import { apiSendVariantAnnotation } from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { List } from "react-virtualized";
import { isJSONString } from "src/components/utils";
import { TableDialog } from "../details/variantDetailGrid";
import { getTranslated } from "../utils/helpers";

const useStyles = makeStyles((theme) => ({
  cellText: {
    // maxWidth: "8vw",
    padding: "0px",
    fontSize: "0.875rem",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
  },
  exceptionCell: {
    "& > div": {
      width: "84%",
    },
  },
  dropDown: {
    position: "relative",
    "& .ReactVirtualized__Grid__innerScrollContainer": {
      margin: 0,
      width: "100%",
    },
    "& .MuiInputBase-root": {
      marginTop: "8px",
    },
    "& .MuiSelect-root": {
      paddingBottom: 0,
    },
    "& label": {
      fontSize: "0.8rem",
    },
  },
  "red[900]": {
    color: colors.red[900],
    "& .MuiInputBase-input": {
      color: colors.red[900],
    },
  },
  "red[500]": {
    color: colors.red[600],
    "& .MuiInputBase-input": {
      color: colors.red[600],
    },
  },
  "yellow[600]": {
    color: colors.orange[400],
    "& .MuiInputBase-input": {
      color: colors.orange[400],
    },
  },
  "amber[500]": {
    color: colors.orange[600],
    "& .MuiInputBase-input": {
      color: colors.orange[600],
    },
  },
  "orange[500]": {
    color: colors.orange[900],
    "& .MuiInputBase-input": {
      color: colors.orange[900],
    },
  },
  "green[500]": {
    color: colors.green[800],
    "& .MuiInputBase-input": {
      color: colors.green[800],
    },
  },
  "lightGreen[500]": {
    color: colors.green[600],
    "& .MuiInputBase-input": {
      color: colors.green[600],
    },
  },
  "blue[500]": {
    color: colors.indigo[500] + " !important",
    "& .MuiInputBase-input": {
      color: colors.indigo[500],
    },
  },
  warningIcon: {
    fill: theme.palette.warning.light,
    width: "0.8em",
    height: "0.8em",
  },
  errorIcon: {
    fill: theme.palette.error.light,
    width: "0.8em",
    height: "0.8em",
  },
  starRating: {
    fontSize: "1.3rem",
  },
  ".MuiAutocomplete-paper": {
    width: 150,
  },
  autoComplete: {
    width: "100%",
    "& .MuiInputBase-root": {},
    "& .MuiSelect-root": {
      paddingBottom: 0,
    },
    "& label": {
      fontSize: "0.8rem",
    },
    "& .MuiAutocomplete-clearIndicator": {
      display: "none",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "0 !important",
      paddingRight: "25px !important",
      "& .MuiAutocomplete-input": {
        minWidth: "auto",
        padding: "3px 0 !important",
      },
    },
    "& .MuiAutocomplete-popper": {
      width: "100%",
    },
    "& .autocompleteTags": {
      marginBottom: "-6px",
      maxWidth: "80%",
    },
  },
  listBox: {
    width: "100%",
    "& > li": {
      width: "100%",
      margin: 0,
    },
    "& .ReactVirtualized__Grid__innerScrollContainer": {
      width: "100% !important",
      maxWidth: "100% !important",
      margin: 0,
    },
    "& .MuiAutocomplete-option": {
      padding: "2px 4px",
      lineHeight: 1,
      width: "100%",
      margin: 0,
      "& > div": {
        width: "100%",
        margin: 0,
        display: "flex",
        alignItems: "center",
      },
    },
  },
  linkButtonCell: {
    lineHeight: "1",
  },
}));

const CellLoaing = withStyles({
  root: {
    position: "absolute",
    right: 0,
  },
})(CircularProgress);

const CustomDropdown = React.memo((props) => {
  const classes = useStyles();
  const {
    reportId,
    reportMeta,
    column,
    annotable,
    rowData,
    value,
    options,
    conditionalStyle,
    style,
  } = props;
  const [dropdownValue, setDropdownValue] = useState(value);
  const [original, setOriginal] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleChange = useCallback((event) => {
    if (annotable) {
      setLoading(true);
      const value = event.target.value;
      apiSendVariantAnnotation(
        reportId,
        {
          row_id: rowData.___row___,
          identifier_value: rowData[reportMeta.identifier_column],
          column_name: column.name,
          value:
            column.multi_value && typeof newVal !== "string" ? [value] : value,
        },
        (data, status) => {
          if (status === 202) {
            rowData.___annotation___[column.name].annotation = value;
            setOriginal(
              value !== rowData.___annotation___[column.name].original
                ? rowData.___annotation___[column.name].original
                : undefined
            );
            setDropdownValue(value);
            enqueueSnackbar(
              t(
                "variant_annotation_success",
                "Annotation was successfully added."
              ),
              {
                variant: "success",
              }
            );
          } else {
            enqueueSnackbar(
              t("There was an error while adding the annotation."),
              {
                variant: "error",
              }
            );
          }
          setLoading(false);
        }
      );
    } else {
      enqueueSnackbar(t("This area is not changable."), {
        variant: "info",
      });
    }
  }, []);

  useEffect(() => {
    if (
      column.annotable &&
      rowData.___annotation___[column.name] &&
      rowData.___annotation___[column.name].annotation &&
      rowData.___annotation___[column.name].annotation !==
        rowData.___annotation___[column.name].original
    ) {
      setDropdownValue(rowData.___annotation___[column.name].annotation);
      setOriginal(rowData.___annotation___[column.name].original);
    } else {
      setDropdownValue(value);
    }

    return () => {
      setOriginal(undefined);
      setDropdownValue(undefined);
    };
  }, [value]);

  const selectColor =
    style[
      Number(dropdownValue) >= 0
        ? options.constructor.name === "Array"
          ? options[dropdownValue]
          : Object.keys(options).filter((optionKey) => {
              // eslint-disable-next-line eqeqeq
              return optionKey.toString() == dropdownValue;
            })[0]
        : Object.keys(options).filter((key) => options[key] === dropdownValue)
            .length > 0
        ? Object.keys(options).filter(
            (key) => options[key] === dropdownValue
          )[0]
        : dropdownValue
    ];

  return (
    <React.Fragment>
      <TextField
        fullWidth
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        select
        value={dropdownValue}
        onChange={handleChange}
        label={
          original
            ? `MAS: ${
                options.constructor === Object ? options[original] : original
              }`
            : undefined
        }
        disabled={props.disabled}
        className={clsx(classes.dropDown, {
          [classes["green[500]"]]: dropdownValue === "Benign",
          [classes["lightGreen[500]"]]: dropdownValue === "Likely benign",
          [classes["yellow[600]"]]: dropdownValue === "VUS",
          [classes["amber[500]"]]: dropdownValue === "VUSi",
          [classes["orange[500]"]]: dropdownValue === "VUSii",
          [classes["red[500]"]]: dropdownValue === "Likely pathogenic",
          [classes["red[900]"]]: dropdownValue === "Pathogenic",
          [classes[selectColor ? selectColor.color : ""]]: !!selectColor,
        })}
        InputProps={{
          disabled: loading,
          endAdornment: loading ? (
            <CellLoaing color="inherit" size={20} />
          ) : null,
        }}
      >
        {options.constructor.name === "Array"
          ? options.map((item, index) => (
              <MenuItem
                // onClick={(event) => event.stopPropagation()}
                value={item}
                key={index}
                className={clsx({
                  [classes[style[item].color]]: conditionalStyle,
                  [classes["yellow[600]"]]: item === "VUS",
                  [classes["amber[500]"]]: item === "VUSi",
                  [classes["orange[500]"]]: item === "VUSii",
                  [classes["lightGreen[500]"]]: item === "Likely benign",
                  [classes["green[500]"]]: item === "Benign",
                  [classes["red[500]"]]: item === "Likely pathogenic",
                  [classes["red[900]"]]: item === "Pathogenic",
                })}
              >
                {item}
              </MenuItem>
            ))
          : Object.keys(options).map((item, index) => (
              <MenuItem
                // onClick={(event) => event.stopPropagation()}
                value={Number(dropdownValue) >= 0 ? item : options[item]}
                key={index}
                className={clsx({
                  [classes[style[item].color]]: conditionalStyle,
                })}
              >
                {options[item]}
              </MenuItem>
            ))}
      </TextField>
    </React.Fragment>
  );
});

// const DDItem =
React.memo(({ classes, options, style, conditionalStyle }) => {
  return options.constructor.name === "Array"
    ? options.map((item, index) => (
        <MenuItem
          // onClick={(event) => event.stopPropagation()}
          value={item}
          key={index}
          className={clsx({
            [classes[style[item].color]]: conditionalStyle,
            [classes["yellow[600]"]]: item === "VUS",
            [classes["amber[500]"]]: item === "VUSi",
            [classes["orange[500]"]]: item === "VUSii",
            [classes["lightGreen[500]"]]: item === "Likely benign",
            [classes["green[500]"]]: item === "Benign",
            [classes["red[500]"]]: item === "Likely pathogenic",
            [classes["red[900]"]]: item === "Pathogenic",
          })}
        >
          {item}
        </MenuItem>
      ))
    : Object.keys(options).map((item, index) => (
        <MenuItem
          // onClick={(event) => event.stopPropagation()}
          value={item}
          key={index}
          className={clsx({
            [classes[style[item].color]]: conditionalStyle,
          })}
        >
          {options[item]}
        </MenuItem>
      ));
});

const ExceptionComponent = React.memo((props) => {
  const classes = useStyles();
  const { exception } = props;
  const { t } = useTranslation();
  const exceptions = {
    exception_variant: <ErrorIcon className={classes.errorIcon} />,
    secondary_finding: <WarningIcon className={classes.warningIcon} />,
  };
  return exception.description ? (
    <Tooltip title={t(exception.description)}>
      {exceptions[exception.type ?? exception]}
    </Tooltip>
  ) : (
    exceptions[exception.type ?? exception]
  );
});

const ListboxComponent = React.memo(
  React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { children, role, setOpen, setRefList, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 32;

    return (
      <div className={classes.dropDown}>
        <div {...other} style={{ width: "100%" }}>
          <ClickAwayListener onClickAway={() => setOpen && setOpen(false)}>
            <List
              className={classes.listBox}
              height={200}
              width={300}
              rowHeight={itemSize}
              style={{ width: "100%" }}
              overscanCount={5}
              rowCount={itemCount}
              rowRenderer={(props) => {
                return React.cloneElement(children[props.index], {
                  style: props.style,
                });
              }}
              role={role}
            />
          </ClickAwayListener>
        </div>
        <IconButton
          size="small"
          color="secondary"
          style={{
            position: "fixed",
            top: 10,
            right: 10,
          }}
          onClick={() => setOpen && setOpen(false)}
        >
          <Cancel fontSize="small" />
        </IconButton>
      </div>
    );
  })
);

const VirtualizedAutocomplete = React.memo((props) => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      component="div"
      className="input-"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <VirtualizedAutocompleteChild {...props} setOpen={setOpen} open={open} />
      {/* {!open ? (
        <KeyboardArrowUp
          onMouseDown={(e) => {
            setOpen(!open);
          }}
        />
      ) : (
        <KeyboardArrowDown
          onMouseDown={(e) => {
            setOpen(!open);
          }}
        />
      )} */}
    </Box>
  );
});

const VirtualizedAutocompleteChild = React.memo((props) => {
  const { classes, reportId, setOpen, reportMeta, column, rowData, data } =
    props;
  const [value, setValue] = useState(data);
  const [original, setOriginal] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const text = useRef();
  const [isOverflowed, setIsOverflowed] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleChange = (event, newVal, reason, details) => {
    if ([13, 8].includes(event.keyCode)) {
      event.preventDefault();
      return;
    }
    setLoading(TramOutlined);
    apiSendVariantAnnotation(
      reportId,
      {
        row_id: rowData.___row___,
        identifier_value: rowData[reportMeta.identifier_column],
        column_name: column.name,
        value: column.multi_value ? newVal : newVal.join(","),
      },
      (data, status) => {
        if (status === 202) {
          rowData.___annotation___[column.name].annotation = String(newVal);
          setOriginal(
            String(newVal) !== rowData.___annotation___[column.name].original
              ? rowData.___annotation___[column.name].original
              : undefined
          );
          setValue(newVal);
          enqueueSnackbar(
            t(
              "variant_annotation_success",
              "Annotation was successfully added."
            ),
            {
              variant: "success",
            }
          );
        } else {
          enqueueSnackbar(
            t("There was an error while adding the annotation."),
            {
              variant: "error",
            }
          );
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (text.current) {
      setIsOverflowed(text.current.scrollWidth > text.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    if (
      column.annotable &&
      rowData.___annotation___[column.name] &&
      rowData.___annotation___[column.name].annotation &&
      rowData.___annotation___[column.name].annotation !==
        rowData.___annotation___[column.name].original
    ) {
      const anno = rowData.___annotation___[column.name].annotation;
      setValue(
        anno.includes(",") ? anno.split(",") : anno.length ? [anno] : []
      );
      setOriginal(rowData.___annotation___[column.name].original);
    } else {
      setOriginal(undefined);
      setValue(data);
    }

    return () => {
      setValue(undefined);
    };
  }, [data]);

  return (
    <Autocomplete
      loading={loading}
      id={props.id}
      size="medium"
      className={classes.autoComplete}
      multiple
      freeSolo
      open={props.open}
      // disableListWrap
      disabled={loading}
      // onClose={() => setOpen(false)}
      fullWidth
      onBlur={(e) => setOpen(false)}
      ChipProps={{ onDelete: undefined }}
      disableCloseOnSelect
      ListboxComponent={(preps, ref) => (
        <ListboxComponent setOpen={setOpen} {...preps} ref={ref} />
      )}
      options={props.options}
      value={value}
      closeIcon={<CloseIcon />}
      onChange={handleChange}
      onClick={(e) => {
        e.stopPropagation();
      }}
      getOptionLabel={(option) => option}
      renderOption={(option, { selected }) => {
        return (
          <div
            style={{
              width: "100%",
            }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Checkbox
              className="menuItemCheckBox"
              checked={value.includes(option)}
            />
            <Typography
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {option}
            </Typography>
          </div>
        );
      }}
      renderTags={(selected) => {
        let renderTagsValue = selected
          .map(function (elem) {
            return elem;
          })
          .join(", ");

        return (
          <Tooltip
            title={renderTagsValue ? renderTagsValue : ""}
            disableHoverListener={!isOverflowed}
          >
            <Typography
              className={"autocompleteTags"}
              variant="body2"
              noWrap={true}
              color="textPrimary"
            >
              {renderTagsValue}
            </Typography>
          </Tooltip>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            onFocus={() => {
              setOpen(true);
            }}
            style={{
              width: "100%",
            }}
            {...params}
            label={original ? `MAS: ${original}` : undefined}
            onClick={(e) => e.stopPropagation()}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CellLoaing color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
});

export const TooltipTableCell = React.memo(
  React.forwardRef((props, ref) => {
    const classes = useStyles();
    const {
      reportMeta,
      reportId,
      data,
      column,
      VariantTag,
      hpo,
      rowData,
      exceptions,
    } = props;
    const innerRef = useRef();
    const [isOverflowed, setIsOverflowed] = useState(false);
    useEffect(() => {
      if (ref?.current) {
        setIsOverflowed(
          innerRef?.current
            ? innerRef.current.clientWidth < ref.current.clientWidth + 10
            : ref.current.scrollWidth > ref.current.clientWidth
        );
      }
    }, []);

    const getSmallestData = (column, data) => {
      let tmpArray = [
        [0, column?.representation?.options?.component_choices[data]],
      ];
      if (
        data.includes(",") &&
        column?.representation &&
        column?.representation.options &&
        column?.representation?.options?.component_choices
      ) {
        tmpArray = [];
        let splitedData = data.split(",");
        splitedData.forEach((val) => {
          Object.entries(
            column?.representation?.options?.component_choices
          ).forEach((choice) => {
            if (choice[1] === val) {
              tmpArray.push([Number(choice[0]), val]);
            }
          });
        });
      } else {
        return data;
      }
      tmpArray = tmpArray.sort((a, b) => {
        if (a[0] < b[0]) {
          return -1;
        } else if (a[0] > b[0]) {
          return 1;
        }
        return 0;
      });

      return tmpArray[0][1];
    };

    const label = column.label ?? {};

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          maxHeight: 40,
          width: "100%",
          paddingLeft: 15,
        }}
      >
        {column.representation ? (
          column.representation.options &&
          column.representation.options.component === "star-rating" ? (
            data >= 0 && (
              <Rating
                className={classes.starRating}
                name="revstat"
                readOnly
                max={4}
                value={parseFloat(data)}
                precision={0.1}
              />
            )
          ) : column.representation.options.component === "shared_variants" ? (
            <SharedVariants data={data.replaceAll("'", '"')} />
          ) : column.representation.options &&
            column.representation.options.component === "link_button" ? (
            <Button
              onClick={(e) => e.stopPropagation()}
              className={classes.linkButtonCell}
              size="small"
              variant="outlined"
              href={data}
              target="_blank"
            >
              {getTranslated(label) ?? ""}
            </Button>
          ) : column.representation.options &&
            column.representation.options.component === "autocomplete" &&
            column.annotable &&
            typeof data === "string" ? (
            <Tooltip
              title={(data.includes(",")
                ? data.split(",")
                : data.length
                ? [data]
                : []
              ).join(", ")}
              placement="top"
              disableHoverListener={!isOverflowed}
            >
              <VirtualizedAutocomplete
                type={column.representation.options.component}
                classes={classes}
                reportId={reportId}
                style={column.representation.options.style ?? {}}
                reportMeta={reportMeta}
                column={column}
                rowData={rowData}
                options={column.representation.options.component_choices}
                data={
                  data.includes(",")
                    ? data.split(",")
                    : data.length
                    ? [data]
                    : []
                }
              />
            </Tooltip>
          ) : column.representation.options &&
            column.representation.options.component === "dropdown" ? (
            <Tooltip
              title={column.representation.options.component_choices[data]}
              placement="top"
              disableHoverListener={!isOverflowed}
            >
              <CustomDropdown
                annotable={column.annotable}
                type={column.representation.options.component}
                options={column.representation.options.component_choices}
                labelValue={
                  column.representation.options.component_choices[data]
                }
                conditionalStyle={
                  column.representation.options.conditional_style
                }
                style={column.representation.options.style ?? {}}
                value={data}
                column={column}
                classes={classes}
                rowData={rowData}
                reportMeta={reportMeta}
                reportId={reportId}
              />
            </Tooltip>
          ) : (column.representation.options &&
              column.representation.options.component === "badged-chip") ||
            column.representation.options.component === "json" ? (
            <Tooltip
              title={
                data
                  ? column.multi_value && isJSONString(data)
                    ? Object.keys(JSON.parse(data)).map((itemKey, index) => (
                        <>
                          · {itemKey}:{JSON.parse(data)[itemKey]} <br />
                        </>
                      ))
                    : Object.keys(data).map((itemKey, index) => (
                        <>
                          · {itemKey}:{data[itemKey]} <br />
                        </>
                      ))
                  : ""
              }
              disableHoverListener={!isOverflowed}
              placement="top-start"
            >
              <Typography noWrap className={classes.cellText} ref={innerRef}>
                {isJSONString(data)
                  ? Object.keys(JSON.parse(data)).map((itemKey, index) => (
                      <BadgedChip
                        classes={classes}
                        itemKey={itemKey}
                        key={index}
                        data={JSON.parse(data)}
                        index={index}
                      />
                    ))
                  : Object.keys(data).map((itemKey, index) => (
                      <BadgedChip
                        classes={classes}
                        itemKey={itemKey}
                        key={index}
                        data={data}
                        index={index}
                      />
                    ))}
              </Typography>
            </Tooltip>
          ) : column.representation.options &&
            column.representation.options.hide_prefix &&
            typeof data === "string" ? (
            <Tooltip
              title={
                data
                  ? data.split(
                      column.representation.options.prefix_indicator
                    )[1]
                  : ""
              }
              disableHoverListener={!isOverflowed}
              placement="top-start"
            >
              <Typography noWrap className={classes.cellText} ref={innerRef}>
                {data.split(column.representation.options.prefix_indicator)[1]}
              </Typography>
            </Tooltip>
          ) : column.name === "___tagged___" ? (
            VariantTag
          ) : column.representation.options?.component === "dialog" ? (
            <TableDialog data={{ value: data, ...column }} hpo={hpo} />
          ) : column.representation.options?.component === "smallest" ? (
            <Tooltip
              title={
                data
                  ? column.multi_value
                    ? data.split(column.multi_value).map((val) => (
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          style={{
                            marginBottom: 4,
                            fontSize: 14,
                            width: "100%",
                          }}
                        >
                          · {val}
                        </Typography>
                      ))
                    : data
                  : ""
              }
              disableHoverListener={!isOverflowed}
              placement="top-start"
            >
              <Typography
                style={{ color: "green", padding: 1.23 }}
                noWrap
                className={clsx(classes.cellText, {
                  [column.representation.options &&
                  column.representation.options.style &&
                  column.representation.options.style[data] &&
                  column.representation.options.style[data]?.color
                    ? classes[column.representation.options.style[data]?.color]
                    : ""]:
                    column.representation.options &&
                    column.representation.options.conditional_style,
                })}
                ref={innerRef}
              >
                <>{getSmallestData(column, data)}</>
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                data
                  ? column.multi_value
                    ? data.split(column.multi_value).map((val) => (
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          style={{
                            marginBottom: 4,
                            fontSize: 14,
                            width: "100%",
                          }}
                        >
                          · {val}
                        </Typography>
                      ))
                    : data
                  : ""
              }
              disableHoverListener={!isOverflowed}
              placement="top-start"
            >
              <Typography
                noWrap
                className={clsx(classes.cellText, {
                  [column.representation.options &&
                  column.representation.options.style &&
                  column.representation.options.style[data] &&
                  column.representation.options.style[data]?.color
                    ? classes[column.representation.options.style[data]?.color]
                    : ""]:
                    column.representation.options &&
                    column.representation.options.conditional_style,
                })}
                ref={innerRef}
              >
                {column.name.includes("vfreq")
                  ? parseFloat(data).toFixed(3)
                  : column.name.includes("significancy")
                  ? "data"
                  : data}
              </Typography>
            </Tooltip>
          )
        ) : (
          <Tooltip
            title={
              data
                ? column.multi_value
                  ? data.split(column.multi_value).map((val) => (
                      <Typography
                        variant="subtitle1"
                        color="inherit"
                        style={{ marginBottom: 4, fontSize: 14, width: "100%" }}
                      >
                        · {val}
                      </Typography>
                    ))
                  : data
                : ""
            }
            disableHoverListener={!isOverflowed}
            placement="top-start"
          >
            <Typography className={classes.cellText} noWrap ref={innerRef}>
              {data}
            </Typography>
          </Tooltip>
        )}
        {Boolean(exceptions.length) &&
          exceptions.map((item, index) => (
            <ExceptionComponent exception={item} key={index} />
          ))}
      </div>
    );
  })
);

export const SharedVariants = ({ data }) => {
  const { t } = useTranslation();
  if (isJSONString(data)) {
    const json = JSON.parse(data);

    return (
      <Tooltip
        title={
          <>
            {json.sample1 === "+" && json.sample2 === "+" && (
              <Typography style={{ color: "white" }}>
                {t("The variant is detected in both samples.")}
              </Typography>
            )}
            {json.sample1 === "+" && json.sample2 !== "+" && (
              <Typography style={{ color: "white" }}>
                {t("The variant is detected in Sample 1.")}
              </Typography>
            )}
            {json.sample2 === "+" && json.sample1 !== "+" && (
              <Typography style={{ color: "white" }}>
                {t("The variant is detected in Sample 2.")}
              </Typography>
            )}
          </>
        }
      >
        <Box style={{ display: "flex" }}>
          {json.sample1 === "-" ? (
            <img
              alt="1_gray"
              height="48"
              src="/assets/images/icons/1_gray.svg"
            />
          ) : (
            <img
              alt="1_gray"
              height="48"
              src="/assets/images/icons/1_red.svg"
            />
          )}
          {json.sample2 === "-" ? (
            <img
              alt="1_gray"
              height="48"
              src="/assets/images/icons/2_gray.svg"
            />
          ) : (
            <img
              alt="1_gray"
              height="48"
              src="/assets/images/icons/2_red.svg"
            />
          )}
        </Box>
      </Tooltip>
    );
  } else {
    return t("Wrong Type");
  }
};

// const HPOTerms = ({ data }) => {
//   const { t } = useTranslation();
//   const handleOpen = () => {
//     setOpen((prev) => !prev);
//   };
//   const [open, setOpen] = useState(false);
//   return (
//     <>
//       <Button onClick={handleOpen}>{t("HPO Terms")}</Button>
//       <Dialog open={open}>
//         {data.map((value) => {
//           return "";
//         })}
//       </Dialog>
//     </>
//   );
// };

// const CustomDialog =
React.memo(
  ({
    type,
    classes,
    reportId,
    reportMeta,
    value,
    options,
    column,
    conditionalStyle,
    style,
    rowData,
    data,
    labelValue,
  }) => {
    const [open, setOpen] = useState();
    const { t } = useTranslation();
    return (
      <span
        style={{ width: "100%" }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Button
          size="small"
          style={{ marginLeft: 10 }}
          variant="outlined"
          className={clsx({
            [classes["green[500]"]]: value === "Benign",
            [classes["lightGreen[500]"]]: value === "Likely benign",
            [classes["yellow[600]"]]: value === "VUS",
            [classes["amber[500]"]]: value === "VUSi",
            [classes["orange[500]"]]: value === "VUSii",
            [classes["red[500]"]]: value === "Likely pathogenic",
            [classes["red[900]"]]: value === "Pathogenic",
            [style[value] ? classes[style[value].color] : ""]: conditionalStyle,
          })}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen((prev) => !prev);
          }}
        >
          {type === "autocomplete" ? (
            <>{data ? data.join(", ") : t("Empty")}</>
          ) : (
            labelValue
          )}
        </Button>
        <Dialog
          onClose={() => {
            setOpen((prev) => !prev);
          }}
          style={{ minWidth: 300, minHeight: 300 }}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <span style={{ margin: 30, minWidth: 300 }}>
            {type === "autocomplete" ? (
              <VirtualizedAutocomplete
                classes={classes}
                reportId={reportId}
                reportMeta={reportMeta}
                column={column}
                rowData={rowData}
                options={options}
                data={data}
              />
            ) : (
              <CustomDropdown
                options={options}
                conditionalStyle={conditionalStyle}
                style={style}
                value={value}
                column={column}
                rowData={rowData}
                reportMeta={reportMeta}
                reportId={reportId}
              />
            )}
          </span>
        </Dialog>
      </span>
    );
  }
);

export const BadgedChip = React.memo(({ classes, itemKey, data, index }) => {
  return (
    <span>
      <span
        className={clsx({
          [classes["yellow[600]"]]: itemKey === "VUS",
          [classes["amber[500]"]]: itemKey === "VUSi",
          [classes["orange[500]"]]: itemKey === "VUSii",
          [classes["lightGreen[500]"]]: itemKey === "LB",
          [classes["green[500]"]]: itemKey === "B",
          [classes["red[500]"]]: itemKey === "LP",
          [classes["red[900]"]]: itemKey === "P",
        })}
      >
        {itemKey}
      </span>
      : {data[itemKey]}
      {index !== Object.keys(data).length - 1 ? ", " : ""}
    </span>
  );
});
