/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { apiLoadReportDetails, apiLoadReportVariantData } from "src/api";
import { apiTableGetSchema } from "src/api/endpoints";
import GermlinePDF from "./germline";
import { getTranslated, prepareFiles } from "../reports/utils/helpers";
import { Alert } from "@material-ui/lab";

// Create Document Component
const MyDocument = () => {
  const [value, setValue] = useState();
  const [schema, setSchema] = useState();
  const [files, setFiles] = useState();
  const [reportDetails, setReportDetails] = useState();
  const variantIds = new URLSearchParams(useLocation().search).get(
    "variant_ids"
  );
  const { reportId } = useParams();

  useEffect(() => {
    let tempSchema = {};
    apiLoadReportDetails(reportId, async (data, status) => {
      if (status === 200) {
        setReportDetails(data);
        setFiles(prepareFiles(data.files, data.unified_output));
        apiTableGetSchema(data.schema, (data, status) => {
          if (status === 200) {
            for (const column of data.columns) {
              tempSchema = {
                ...tempSchema,
                [column.name]: column,
              };
            }
          }
        }).then(() => {
          setSchema(tempSchema);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (variantIds !== "0") {
      apiLoadReportVariantData(
        reportId,
        4,
        0,
        200,
        {
          filters: {
            combinator: "and",
            rules: [
              {
                field: "___id___",
                operator: "in",
                value: variantIds,
              },
            ],
          },
        },
        (value) => {
          setValue(value);
        }
      );
    } else {
      setValue(["Not Found In DB"]);
    }
  }, []);

  const variants_length = useCallback(() => variantIds.split(",").length, []);
  if (variants_length >= 200) {
    return (
      <Box
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          WebkitFlexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          <Typography>
            {getTranslated("Maximum reportable variant amount is 200")}
          </Typography>
        </Alert>
        <Button onClick={() => window.history.back()}>
          {getTranslated("Go back")}
        </Button>
      </Box>
    );
  }

  if (!value || !schema || !reportDetails) {
    return (
      <Box
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  
  if (reportDetails.pipeline_class.includes("germline")) {
    return (
      <GermlinePDF
        files={files}
        variants={value}
        schema={schema}
        reportId={reportId}
      />
    );
  } else if (reportDetails.pipeline_class.includes("somatic")) {
    return (
      <GermlinePDF
        files={files}
        variants={value}
        schema={schema}
        reportId={reportId}
      />
    );
  } else if (reportDetails.pipeline_class.includes("trio")) {
    return (
      <GermlinePDF
        files={files}
        variants={value}
        schema={schema}
        reportId={reportId}
      />
    );
  }
    else if (reportDetails.pipeline_class.includes("nanopore")) {
      return (
        <GermlinePDF
          files={files}
          variants={value}
          schema={schema}
          reportId={reportId}
        />
      );
  }
};

export default MyDocument;
