import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import React from "react";
import RelatedGenes from "./relatedGenes";
import { useTranslation } from "react-i18next";

function PopUpGenes({
  genes,
  open,
  setOpen,
  selectPanelAppFilter,
  handleDialogClose,
  classes,
  handleOnDelete,
  relatedGenesOnClick,
}) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="lg">
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">{genes[0]}</Typography>
        <Divider style={{ marginTop: "10px" }} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box display="flex" style={{ gap: 10 }} flexWrap="wrap">
            {Object.entries(genes[1]).map((value, index) => (
              <RelatedGenes
                key={index}
                value={value}
                onClick={() => relatedGenesOnClick(value, genes[0])}
              />
            ))}
          </Box>
          <Divider style={{ margin: "10px 0" }} />
          <Box style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
            {Object.entries(genes[1]).map(
              (value) =>
                value[1].isActive &&
                value[1].genes.map((gene, index) => {
                  return (
                    gene.isActive && (
                      <Chip
                        key={index}
                        label={gene.label}
                        variant="outlined"
                        onDelete={() =>
                          handleOnDelete(genes[0], value[0], gene.label)
                        }
                        style={{
                          color: "white",
                          backgroundColor: value[1].color,
                        }}
                      />
                    )
                  );
                })
            )}
          </Box>
        </DialogContentText>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              selectPanelAppFilter(genes);
              setOpen([]);
            }}
          >
            {t("Apply")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default PopUpGenes;
