/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef } from "react";
// import clsx from "clsx";
import {
  // Box,
  // Button,
  // colors,
  // TableCell,
  // Typography,
  Tooltip,
} from "@material-ui/core";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
// import { TooltipTableCell } from "../virtualizedTable/tooltipTableCell";
// import { Skeleton } from "@material-ui/lab";
// import { FilterTableHeader } from "../virtualizedTable/headers";
// import { AutoSizer, Column, Table } from "react-virtualized";
// import { cellDataGetter } from "../utils/virtualizedCustom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { End } from "../reportVirtiualizedTable";
import { useVirtual } from "react-virtual";
import { reorder } from "../../settings/tableConfigPage/tableLayoutEditor";
import VariantTable from "../reportVirtiualizedTable/table";

// const styles = (theme) => ({
//   flexContainer: {
//     display: "flex",
//     alignItems: "center",
//     boxSizing: "border-box",
//   },
//   applyFiltersBtn: {
//     color: theme.palette.common.white,
//     background: colors.green[400],
//     "&:hover": {
//       background: colors.green[600],
//     },
//   },
//   filterableHeader: {
//     paddingLeft: 0,
//     borderBottom: "1px solid #E0E0E",
//     display: "flex",
//     backgroundColor: theme.palette.success.dark,
//     color: theme.palette.background.paper,
//     "& > *": {
//       fill: "#FFF",
//       color: theme.palette.background.paper,
//     },
//     "& > div": {
//       borderBottom: "none",
//       paddingRight: 0,
//       paddingLeft: 0,
//     },
//     "&:first-child > div": {
//       paddingLeft: "3px",
//     },
//     "& .header-tooltip-wrapper": {
//       display: "inline-flex",
//       cursor: "pointer",
//     },
//     "& .filter-button-wrapper": {
//       paddingTop: "6px",
//       "& *": {
//         color: "#FFF",
//       },
//       "& .filtered path": {
//         color: colors.orange[300],
//       },
//     },
//   },
//   sliderMenu: {
//     "& ul": {
//       width: "200px",
//       padding: "40px 20px 20px",
//     },
//   },
//   autocompleteMenuItem: {
//     "& .menuItemCheckBox": { marginRight: "1px" },
//     "& .menuItemLabel": {
//       display: "inline-block",
//       verticalAlign: "middle",
//       width: "200px",
//     },
//   },
//   sortIndicator: {
//     marginLeft: "-22px",
//     width: "20px",
//   },
//   ascSortIndicator: {
//     transform: "rotate(90deg)",
//   },
//   descSortIndicator: {
//     transform: "rotate(-90deg)",
//   },
//   table: {
//     "& .ReactVirtualized__Table__headerRow": {
//       flip: false,
//       // paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
//       paddingRight: "0 !important",
//     },
//   },
//   loadingTableRow: {
//     border: "5px solid #FFF",
//     cursor: "auto !important",
//     "& .MuiSkeleton-root": {
//       backgroundColor: "rgba(55, 71, 79, 0.2)",
//     },
//   },
//   tableRow: {
//     cursor: "pointer",
//   },
//   tableRowHover: {
//     "&:hover": {
//       backgroundColor: theme.palette.grey[200],
//     },
//   },
//   tableRowSelected: {
//     border: "1px solid " + colors.amber[200] + " !important",
//     backgroundColor: colors.amber[100] + " !important",
//     // backgroundColor: theme.palette.warning.light + " !important",
//   },
//   lossCell: {
//     color: colors.red[500] + " !important",
//   },
//   gainCell: {
//     color: colors.indigo[500] + " !important",
//   },
//   cnvFilterButton: {
//     "& > button": {
//       minWidth: 0,
//       padding: "5px 0 0 5px",
//     },
//     "& svg": {
//       width: "0.8em",
//     },
//   },
//   tableCell: {
//     padding: 5,
//     flex: 1,
//     minHeight: "31px",
//     "&.darkred-label": {
//       color: theme.palette.error.dark,
//     },
//     "&.red-label": {
//       color: theme.palette.error.light,
//     },
//     "&.yellow-label": {
//       color: theme.palette.warning.dark,
//     },
//     "&.darkgreen-label": {
//       color: theme.palette.success.dark,
//     },
//     "&.green-label": {
//       color: theme.palette.success.light,
//     },
//   },
//   linkButtonCell: {
//     "& .MuiButton-root": {
//       lineHeight: "1",
//     },
//   },
//   noClick: {
//     cursor: "initial",
//   },
//   hidden: {
//     display: "none",
//   },
// });

// class MuiVirtualizedTable extends React.PureComponent {
//   static defaultProps = {
//     headerHeight: 48,
//     rowHeight: 32,
//   };

//   getRowClassName = ({ index }) => {
//     const { classes, onRowClick } = this.props;
//     return clsx(classes.tableRow, classes.flexContainer, {
//       [classes.tableRowHover]: index !== -1 && onRowClick != null,
//       [classes.tableRowSelected]: index === this.props.selectedRow,
//     });
//   };

//   //   cellRenderer = ({ cellData, columnIndex }) => {
//   //     const { columns, classes, rowHeight, onRowClick } = this.props;
//   //     return (
//   //       <TableCell
//   //         component="div"
//   //         className={clsx(classes.tableCell, classes.flexContainer, {
//   //           [classes.noClick]: onRowClick == null,
//   //         })}
//   //         variant="body"
//   //         style={{ height: rowHeight }}
//   //         align={
//   //           (columnIndex != null && columns[columnIndex].numeric) || false
//   //             ? "right"
//   //             : "left"
//   //         }
//   //       >
//   //         {cellData}
//   //       </TableCell>
//   //     );
//   //   };

//   TooltipCellRenderer = ({ cellData, columnIndex, dataKey }) => {
//     const { columns, classes, rowHeight, onRowClick } = this.props;
//     this.myRef = React.createRef();
//     return columns[columnIndex].representation.options.component ===
//       "link_button" ? (
//       <TableCell
//         ref={(ref) => (this.myRef.current = ref)}
//         component="div"
//         variant="body"
//         align="left"
//         className={clsx(
//           classes.tableCell,
//           classes.flexContainer,
//           classes.linkButtonCell,
//           {
//             [classes.noClick]: onRowClick == null,
//           }
//         )}
//         style={{
//           height: { rowHeight },
//         }}
//       >
//         <Button size="small" variant="outlined" href={cellData} target="_blank">
//           {columns[columnIndex].label}
//         </Button>
//       </TableCell>
//     ) : (
//       <span ref={(ref) => (this.myRef.current = ref)}>
//         <TooltipTableCell
//           column={columns[columnIndex]}
//           tdRef={this.myRef}
//           className={clsx(classes.tableCell, classes.flexContainer, {
//             [classes.noClick]: onRowClick == null,
//             [classes.lossCell]: dataKey === "type" && cellData === "Loss",
//             [classes.gainCell]: dataKey === "type" && cellData === "Gain",
//           })}
//           height={rowHeight}
//           alignment={
//             (columnIndex != null && columns[columnIndex].numeric) || false
//               ? "right"
//               : "left"
//           }
//           data={cellData}
//           exceptions={[]}
//         />
//       </span>
//     );
//   };

//   rowRenderer = ({
//     className,
//     columns,
//     index,
//     key,
//     onRowClick,
//     onRowDoubleClick,
//     onRowMouseOut,
//     onRowMouseOver,
//     onRowRightClick,
//     rowData,
//     style,
//   }) => {
//     const a11yProps = { "aria-rowindex": index + 1 };

//     const rowDataLength = rowData ? Object.keys(rowData).length : 0;

//     if (
//       Boolean(rowDataLength) &&
//       (onRowClick ||
//         onRowDoubleClick ||
//         onRowMouseOut ||
//         onRowMouseOver ||
//         onRowRightClick)
//     ) {
//       a11yProps["aria-label"] = "row";
//       a11yProps.tabIndex = 0;

//       if (onRowClick) {
//         a11yProps.onClick = (event) => onRowClick({ event, index, rowData });
//       }
//       if (onRowDoubleClick) {
//         a11yProps.onDoubleClick = (event) =>
//           onRowDoubleClick({ event, index, rowData });
//       }
//       if (onRowMouseOut) {
//         a11yProps.onMouseOut = (event) =>
//           onRowMouseOut({ event, index, rowData });
//       }
//       if (onRowMouseOver) {
//         a11yProps.onMouseOver = (event) =>
//           onRowMouseOver({ event, index, rowData });
//       }
//       if (onRowRightClick) {
//         a11yProps.onContextMenu = (event) =>
//           onRowRightClick({ event, index, rowData });
//       }
//     }

//     if (rowData) {
//       return Boolean(rowDataLength) ? ( //loadedRowsMap[index] === STATUS_LOADED &&
//         <div
//           {...a11yProps}
//           className={className}
//           key={key}
//           role="row"
//           style={style}
//         >
//           {columns}
//         </div>
//       ) : (
//         <div style={style} className={className} key={index}>
//           <Skeleton variant="rect" width={"100%"} height={22} />
//         </div>
//       );
//     }
//   };

//   headerRenderer = ({ label, columnIndex }) => {
//     const {
//       sortState,
//       setSortState,
//       headerHeight,
//       columns,
//       setColumns,
//       classes,
//     } = this.props;

//     return (
//       <FilterTableHeader
//         sortState={sortState}
//         setSortState={setSortState}
//         headerHeight={headerHeight}
//         columns={columns}
//         setColumns={setColumns}
//         filterState={this.props.filterState}
//         classes={classes}
//         label={label}
//         columnIndex={columnIndex}
//         tableKind="cnv"
//         filterType={
//           columns[columnIndex].kind === "numeric"
//             ? "range"
//             : columns[columnIndex].filterValues &&
//               Object.keys(columns[columnIndex].filterValues).length < 15
//             ? "select"
//             : "search"
//         }
//       />
//     );
//   };

//   emptyResultRenderer = (props) => {
//     return (
//       <Box
//         display="flex"
//         flexDirection="column"
//         height="100%"
//         justifyContent="center"
//         alignItems="center"
//       >
//         <img alt="" src="/assets/images/icons/not-found.png" width="150" />
//         <Typography
//           style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
//         >
//           {this.props.t("No results were found based on the active filters.")}
//         </Typography>
//       </Box>
//     );
//   };

//   render() {
//     const {
//       classes,
//       columns,
//       visibleCols,
//       rowHeight,
//       headerHeight,
//       ...tableProps
//     } = this.props;
//     return (
//       <AutoSizer>
//         {({ height, width }) => (
//           <Table
//             scrollToAlignment="start"
//             height={height}
//             width={width}
//             rowHeight={rowHeight}
//             gridStyle={{
//               direction: "inherit",
//             }}
//             headerHeight={headerHeight}
//             className={classes.table}
//             {...tableProps}
//             rowClassName={this.getRowClassName}
//             rowRenderer={this.rowRenderer}
//             noRowsRenderer={this.emptyResultRenderer}
//           >
//             {visibleCols.map(({ name, ...other }, index) => {
//               return (
//                 <Column
//                   key={name}
//                   cellDataGetter={cellDataGetter}
//                   headerRenderer={(headerProps) =>
//                     this.headerRenderer({
//                       ...headerProps,
//                       columnIndex: index,
//                     })
//                   }
//                   className={clsx(classes.flexContainer)}
//                   cellRenderer={this.TooltipCellRenderer}
//                   dataKey={name}
//                   {...other}
//                   width={other.width || width / visibleCols.length}
//                 />
//               );
//             })}
//           </Table>
//         )}
//       </AutoSizer>
//     );
//   }
// }

// export const CNVVirtualizedTable = withStyles(styles)(
//   withTranslation()(MuiVirtualizedTable)
// );

export const CNVVirtualizedTable = ({
  columns,
  setColumns,
  rows,
  defaultColDetailsObject,
  userObject,
  filterState,
  sortState,
  setSortState,
  appConf,
}) => {
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const ref = useRef();
  const [selectedRow] = useState(null);
  const [dynamicRowCount] = useState(0);
  const [tmpColumnHelper, setTmpColumnHelper] = useState([]);
  const [visibleCols, setVisibleCols] = useState([]);

  useEffect(() => {
    let tmpHelper = [];
    const tmpVisibleCols = [];
    columns.forEach((col) => {
      tmpHelper = { ...tmpHelper, [col.name]: col };
      if (rows[0].hasOwnProperty(col.name))
        tmpVisibleCols.push(
          columnHelper.accessor(col.name, {
            id: col.name,
            header: () => (
              <Tooltip title={t(col.description)}>
                <span>{t(col.label)}</span>
              </Tooltip>
            ),
            cell: (info) => info.getValue(),
            footer: (info) => info.column.id,
          })
        );
    });
    setTmpColumnHelper(tmpHelper);
    setVisibleCols(tmpVisibleCols);
    // setColumns(tmpVisibleCols);
  }, []);

  const handleReorderColumnData = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const cols = reorder(
        visibleCols,
        result.source.index,
        result.destination.index
      );

      setVisibleCols(cols);
    },
    [visibleCols]
  );

  if (tmpColumnHelper.length && visibleCols.length && rows.length) {
    return "loading";
  } else {
    return (
      <div
        ref={ref}
        style={{ maxHeight: 390, minHeight: 390, overflow: "scroll" }}
      >
        <CNVTable
          // rows={newRows}
          // virtualRows={virtualRows}
          rows={rows}
          tmpColumnHelper={tmpColumnHelper}
          visibleCols={visibleCols}
          isFetching={false}
          reportId={null}
          innerRef={ref}
          colDetails={columns}
          virtualTableHeight={390}
          setColDetails={setColumns}
          // paddingTop={paddingTop}
          handleReorderColumnData={handleReorderColumnData}
          defaultColDetailsObject={defaultColDetailsObject}
          // paddingBottom={paddingBottom}
          selectedRow={selectedRow}
          dynamicRowCount={dynamicRowCount}
          userObject={userObject}
          // table={table}
          reportMeta={{}}
          loading={false}
          setCheckedRows={() => {}}
          tableColDetails={columns}
          appConf={appConf}
          checkedRows={[]}
          rowClickHandler={() => {}}
          columnHelper={tmpColumnHelper}
          filters={{
            tableSortState: sortState,
            setTableSortState: setSortState,
            tableFilterState: filterState,
            setTableFilterState: () => {},
          }}
          end={
            <End
              t={t}
              columns={columns}
              end={rows.length >= dynamicRowCount && rows.length >= 0}
              isFetching={false}
            />
          }
          classes={{}}
        />
      </div>
    );
  }
};

const CNVTable = ({ rows, visibleCols, innerRef, ...others }) => {
  const table = useReactTable({
    data: rows,
    columns: visibleCols,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
  });

  const newRows = table.getRowModel().rows;

  const rowVirtualizer = useVirtual({
    parentRef: innerRef,
    size: newRows.length,
    overscan: 7,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <VariantTable
      rows={newRows}
      virtualRows={virtualRows}
      paddingTop={paddingTop}
      table={table}
      isHaventCheckbox={true}
      tableKind="cnv"
      paddingBottom={paddingBottom}
      {...others}
    />
  );
};
