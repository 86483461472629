/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apitOrganizationSamplesCounts } from "src/api/endpoints";
import { materialColors } from "src/components/pages/reports/charts/conf";
import PieChart from "./chart";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import moment from "moment";
import { useRef } from "react";
import { localeTr } from "../../../reports/utils/helpers";
function DatePickerComponent(props) {
  const { setSelectedDate, selectedDate } = props;
  // const [tmpDate, setTmpDate] = useState(selectedDate);
  // const handleDateChange = () => {
  //   setSelectedDate(selectedDate);
  // };

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    localeTr();
    moment.locale(language.includes("tr") ? "tr" : "en");
  }, [language]);

  const handleTmpChange = (date, index) => {
    let tmp = selectedDate;
    if (date && date[date?.length - 1] !== "_") {
      tmp[index] = moment(new Date(date));
    }
    setSelectedDate([...tmp]);
  };

  return (
    <LocalizationProvider
      dateLibInstance={moment}
      dateAdapter={MomentAdapter}
      locale={language.includes("tr") ? "tr" : "en"}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            clearable
            cancelLabel={t("Cancel")}
            okLabel={t("Ok")}
            clearLabel={t("Clear")}
            format="MM/dd/yyyy"
            maxDate={new Date()}
            margin="normal"
            id="date-picker-inline"
            inputProps={{ "data-testid": "date-picker-first" }}
            value={selectedDate[0]}
            onChange={(date, value) => handleTmpChange(date, 0)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            label={t("Select first date")}
            renderInput={(props) => (
              <TextField style={{ width: "100%" }} {...props} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            clearable={true}
            cancelLabel={t("Cancel")}
            okLabel={t("Ok")}
            clearLabel={t("Clear")}
            maxDate={new Date()}
            format="MM/dd/yyyy"
            inputProps={{ "data-testid": "date-picker-second" }}
            margin="normal"
            id="date-picker-inline"
            value={selectedDate[1]}
            onChange={(date, value) => handleTmpChange(date, 1)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            label={t("Select second date")}
            renderInput={(props) => (
              <TextField style={{ width: "100%" }} {...props} />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          {/* <Button
            onClick={handleDateChange}
            data-testid="show-between-dates"
            variant="contained"
            color="secondary"
          >
            {t("Show Between Dates")}
          </Button> */}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

function RunnedSamples({ classes, organization_id }) {
  const [organizationData, setOrganizationData] = useState();
  function getRandomInt(max, multiple) {
    return Math.floor(Math.random() * max) * multiple;
  }

  const [selectedDate, setSelectedDate] = useState([
    moment().subtract(1, "year"),
    moment(),
  ]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    if (isMounted) {
      const now = moment().format("YYYY-MM-DD");
      apitOrganizationSamplesCounts(
        organization_id,
        selectedDate[0].format("YYYY-MM-DD") !== now ||
          selectedDate[1].format("YYYY-MM-DD") !== now
          ? selectedDate[0].format("YYYY-MM-DD")
          : null,
        selectedDate[1].format("YYYY-MM-DD") !== now ||
          selectedDate[0].format("YYYY-MM-DD") !== now
          ? selectedDate[1].format("YYYY-MM-DD")
          : null,
        (val) => organizationManipluate(val, isMounted)
      );
    }
    return () => {
      isMounted = false;
    };
  }, [selectedDate]);

  // useEffect(() => {
  //   apitOrganizationSamplesCounts(
  //     organization_id,
  //     "",
  //     "",
  //     organizationManipluate
  //   );
  // }, []);

  const organizationManipluate = async (val, mounted) => {
    let tmpOrganizationData = [];
    tmpOrganizationData = await Object.values(val).map((items, index) => {
      let datasets = [];
      let labels = [];
      let backgroundColor = [];
      for (let i = 0; items.length > i; i++) {
        let tmpItem = Object.values(items[i]);
        datasets.push(tmpItem[1]);
        labels.push(tmpItem[0]);
        backgroundColor.push(
          colors[materialColors[getRandomInt(17, 1)]][getRandomInt(9, 100)]
        );
      }
      return {
        title: Object.keys(val)[index],
        datasets: {
          datasets: [
            {
              label: t(Object.keys(val)[index]),
              data: datasets,
              backgroundColor: backgroundColor,
            },
          ],
          labels,
        },
      };
    });
    if (mounted) setOrganizationData(tmpOrganizationData);
  };

  const ref = useRef(null);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setHeight(ref?.current?.clientHeight);
    setWidth(ref?.current?.clientWidth);
  });

  if (!organizationData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return organizationData.map((items, key) => {
      return (
        <Grid item xs={6} key={key} innerRef={ref}>
          <Paper
            className={clsx(classes.paper, classes.runnedItem)}
            style={{
              marginTop: 0,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <h3 style={{ margin: "auto 0", textAlign: "center" }}>
                {t(items.title)}
              </h3>
              <Box data-testid="total">
                {t("Total")} :&nbsp;
                {items.datasets.datasets[0].data.reduce((a, b) => a + b, 0)}
              </Box>
            </Box>
            <div>
              <PieChart
                height={height}
                width={width}
                key={key}
                datas={items.datasets}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <DatePickerComponent
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                language={i18n.language}
                items={items}
              />
            </div>
          </Paper>
        </Grid>
      );
    });
  }
}

export default RunnedSamples;
