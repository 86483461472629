import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
}));

const MismatchIndelsPieChart = (props) => {
  const classes = useStyles();
  const { helpInformation } = props;

  const [sampleData, setSampleData] = useState(undefined);

  useEffect(() => {
    fetch("/assets/statistics/qualimapReport.json")
      .then((response) => response.json())
      .then((jsonData) => {
        jsonData = jsonData["mismatches_and_indels_(inside_of_regions)"];
        const labels = ["Mismatches", "Insertions", "Deletions"];
        const data = [];
        const backgroundColor = [];
        const hoverBackgroundColor = [];
        for (let i = 0; i < labels.length; i++) {
          data.push(parseInt(jsonData[labels[i]].replace(/,/g, "")));
          backgroundColor.push(
            colors[materialColors[i % materialColors.length]][300]
          );
          hoverBackgroundColor.push(
            colors[materialColors[i % materialColors.length]][500]
          );
        }

        labels[0] = "SNP";

        setSampleData({
          labels: labels,
          datasets: [
            {
              label: "Mismatches and Indels",
              data: data,
              backgroundColor: backgroundColor,
              hoverBackgroundColor: hoverBackgroundColor,
            },
          ],
        });
      });
  }, []);

  const options = {
    title: {
      display: true,
      text: "Mismatches and Indels",
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: "#fff",
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: "left",
      labels: {
        boxWidth: 10,
      },
    },
  };

  if (!sampleData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <Pie
          height={500}
          data={sampleData}
          options={options}
          plugins={[ChartDataLabels]}
        />
      </ChartWrapper>
    );
  }
};

export default MismatchIndelsPieChart;
