/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  colors,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { getBaseURL } from "src/config/api";
import ChartWrapper from "../charts/chartWrapper";
import safeLogger from "src/services/safeLogger";
import { ChartLegend } from "../charts/components";
import { getBaseLanguage } from "../utils/helpers";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  chartWrapper: {
    maxHeight: "500px",
    position: "relative",
  },
}));

const copyNumberLines = [
  { value: 1.17, cn: 4, color: "indigo" },
  { value: 0.807, cn: 3, color: "blue" },
  { value: 0.322, cn: 2, color: "green" },
  { value: -0.415, cn: 1, color: "pink" },
  { value: -2, cn: 0, color: "red" },
];

const stripes = [
  { color: "rgba(17, 24, 87, 0.2)", height: 0.073333333 },
  { color: "rgba(13, 71, 161, 0.2)", height: 0.080666667 },
  { color: "rgba(0, 188, 212, 0.2)", height: 0.107777778 },
  { color: "rgba(76, 175, 80, 0.2)", height: 0.163777778 },
  { color: "rgba(255, 64, 129, 0.2)", height: 0.352222222 },
  { color: "rgba(125, 0, 0, 0.2)", height: 0.222222222 },
];

const ScatteredPlot = (props) => {
  const classes = useStyles();
  const { chrName, cnrData, cnsData } = props;
  const [lineChartData, setLineChartData] = useState(undefined);
  const [bubbleChartData, setBubbleChartData] = useState(undefined);
  const [xRange, setxRange] = useState([undefined, undefined]);
  //   const [bubbleChartOptions, setBubbleChartOptions] = useState(undefined);

  const [cnRanges, setCnRanges] = useState(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    Chart.plugins.unregister(ChartDataLabels);
  }, []);

  useEffect(() => {
    Chart.pluginService.register({
      beforeDraw: function (chart, easing) {
        if (chart.config.options.chartArea === "cnv") {
          var ctx = chart.chart.ctx;
          var chartArea = chart.chartArea;

          ctx.save();

          ctx.clearRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top,
          );

          let filledHeight = 0;
          for (let i = 0; i < stripes.length; i++) {
            const stripHeight =
              (chartArea.bottom - chartArea.top) * stripes[i].height;
            ctx.fillStyle = stripes[i].color;
            ctx.fillRect(
              chartArea.left,
              chartArea.top + filledHeight,
              chartArea.right - chartArea.left,
              stripHeight,
            );
            filledHeight += stripHeight;
          }

          ctx.restore();
        }
      },
    });
  }, []);

  useEffect(() => {
    const rangeLines = [];
    for (let i = 0; i < copyNumberLines.length; i++) {
      rangeLines.push({
        drawTime: "afterDatasetsDraw",
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: copyNumberLines[i].value,
        borderWidth: 1,
        borderColor: colors[copyNumberLines[i].color][300],
        // borderDash: [10, 5],
        label: {
          content: `cn ${copyNumberLines[i].cn} (${copyNumberLines[i].value}) `,
          enabled: false,
          position: "right",
          fontColor: "#313131",
          fontSize: 10,
          backgroundColor: "rgba(0,0,0,0)",
          yAdjust: 10,
          // yAdjust: (i % 2) * 15,
        },
      });
    }
    setCnRanges(rangeLines);
  }, []);

  useEffect(() => {
    const labels = [];
    const dataset = [];
    for (let i = 0; i < cnsData.length; i++) {
      let rangeChanged = false;
      if (!xRange[0] || cnsData[i]["start"] < xRange[0]) {
        xRange[0] = cnsData[i]["start"];
        rangeChanged = true;
      }
      if (!xRange[1] || cnsData[i]["end"] > xRange[1]) {
        xRange[1] = cnsData[i]["end"];
        rangeChanged = true;
      }
      if (rangeChanged) {
        setxRange([...xRange]);
      }

      const data = [];
      labels.push(cnsData[i]["start"]);
      data.push({ x: cnsData[i]["start"], y: cnsData[i]["log2"] });
      labels.push(cnsData[i]["end"]);
      data.push({ x: cnsData[i]["end"], y: cnsData[i]["log2"] });

      dataset.push({
        label: i,
        type: "line",
        data: data,
        borderWidth: 5,
        pointRadius: 0,
        borderColor: colors["orange"][500],
        backgroundColor: "rgba(0, 0, 0, 0)",
      });
    }

    // setLineChartData({
    //     labels: labels,
    //   datasets: [...dataset],
    // });

    setLineChartData(dataset);

    // setLineChartOptions({
    //   //   animation: {
    //   //     duration: 0,
    //   //   },
    //   maintainAspectRatio: false,
    //   responsive: true,
    //   elements: {
    //     line: {
    //       tension: 0,
    //     },
    //   },
    //   title: {
    //     display: true,
    //     // text: t("Chromosomes-based copy ratio"),
    //     text: "chr" + chrName,
    //   },
    //   legend: { display: false },
    //   //   tooltips: {
    //   //     callbacks: {
    //   //       label: function (context) {
    //   //         return t("Copy ratio (log2)") + ": " + context.yLabel;
    //   //       },
    //   //     },
    //   //   },
    //   scales: {
    //     yAxes: [
    //       {
    //         ticks: {
    //           //   min: xRange[0],
    //           //     max: xRange[1],
    //         },
    //         scaleLabel: {
    //           display: true,
    //           labelString: t("Copy ratio (log2)"),
    //         },
    //       },
    //     ],
    //     xAxes: [
    //       {
    //         type: "linear",
    //         position: "bottom",
    //         ticks: {
    //           min: xRange[0],
    //           max: xRange[1],
    //           callback: (value) => {
    //             return Math.round(value / 1000000);
    //           },
    //         },
    //         scaleLabel: {
    //           display: true,
    //           labelString: t("Positions") + " (Mb)",
    //         },
    //       },
    //     ],
    //   },
    // });
  }, []);

  useEffect(() => {
    //   const labels = []
    const data = [];

    for (let i = 0; i < cnrData.length; i++) {
      if (cnrData[i]["log2"] > 1.5 || cnrData[i]["log2"] < -3) {
        continue;
      }
      const weight = cnrData[i]["weight"];
      data.push({
        x: cnrData[i]["start"],
        y: cnrData[i]["log2"],
        r: weight * 5 < 1 ? 1 : weight * 5,
      });
    }

    // setBubbleChartData({
    //   labels: "Bubbles",
    //   datasets: [
    //     {
    //       type: "bubble",
    //       label: ["things"],
    //       backgroundColor: "rgba(0, 0, 0, 0.2)",
    //       borderColor: "rgba(0, 0, 0, 0.4)",
    //       data: data,
    //     },
    //   ],
    // });

    setBubbleChartData([
      {
        type: "bubble",
        label: "",
        backgroundColor: "rgba(100, 100, 100, 0.4)",
        borderColor: "rgba(100, 100, 100, 0.6)",
        data: data,
      },
    ]);
  }, []);

  useEffect(() => {
    if (lineChartData && bubbleChartData) {
      // safeLogger([...lineChartData, ...bubbleChartData]);
    }
  }, [lineChartData, bubbleChartData]);

  const options = {
    chartArea: "cnv",
    annotation: {
      // annotations: chromosomeLines,
      annotations: cnRanges,
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    title: {
      display: true,
      // text: t("Chromosomes-based copy ratio"),
      text: "chr" + chrName,
    },
    legend: { display: false },
    //   tooltips: {
    //     callbacks: {
    //       label: function (context) {
    //         return t("Copy ratio (log2)") + ": " + context.yLabel;
    //       },
    //     },
    //   },
    scales: {
      yAxes: [
        {
          ticks: {
            min: -3,
            max: 1.5,
          },
          afterBuildTicks: function (axis, ticks) {
            const cnLineValues = [];
            for (let i = 0; i < copyNumberLines.length; i++) {
              cnLineValues.push(copyNumberLines[i].value);
            }
            return [1.5, 0, -3].concat(cnLineValues);
          },
          scaleLabel: {
            display: true,
            labelString: t("Copy ratio (log2)"),
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            min: xRange[0],
            max: xRange[1],
            callback: (value) => {
              return Math.round(value / 1000000);
            },
          },
          scaleLabel: {
            display: true,
            labelString: t("Positions") + " (Mb)",
          },
        },
      ],
    },
  };

  if (lineChartData) {
    return (
      <Box>
        <ChartLegend
          style={{ marginTop: "-24px" }}
          items={[
            { name: t("CN 4"), color: "rgba(13, 71, 161, 0.5)" },
            { name: t("CN 3"), color: "rgba(0, 188, 212, 0.5)" },
            { name: t("CN 2"), color: "rgba(76, 175, 80, 0.5)" },
            { name: t("CN 1"), color: "rgba(255, 64, 129, 0.5)" },
            { name: t("CN 0"), color: "rgba(125, 0, 0, 0.5)" },
          ]}
        />
        <div className={classes.chartWrapper}>
          <Bar
            height={500}
            data={{
              labels: [],
              datasets: [...lineChartData, ...bubbleChartData],
            }}
            // options={lineChartOptions}
            options={options}
          />
        </div>
        {/* <Line
          height={400}
          data={lineChartData}
          options={lineChartOptions}
          plugins={[]}
        /> */}
        {/* <Bubble
          height={100}
          data={bubbleChartData}
          //   options={bubbleChartOptions}
          plugins={[]}
        /> */}
      </Box>
    );
  } else {
    return <CircularProgress className={classes.spinner} />;
  }
};

const ScatteredPlotNoTreshold = (props) => {
  const classes = useStyles();
  const { chrName, cnrData, cnsData } = props;
  const [lineChartData, setLineChartData] = useState(undefined);
  const [bubbleChartData, setBubbleChartData] = useState(undefined);
  const [xRange, setxRange] = useState([undefined, undefined]);

  const { t } = useTranslation();

  useEffect(() => {
    Chart.plugins.unregister(ChartDataLabels);
  }, []);

  useEffect(() => {
    const labels = [];
    const dataset = [];
    for (let i = 0; i < cnsData.length; i++) {
      let rangeChanged = false;
      if (!xRange[0] || cnsData[i]["start"] < xRange[0]) {
        xRange[0] = cnsData[i]["start"];
        rangeChanged = true;
      }
      if (!xRange[1] || cnsData[i]["end"] > xRange[1]) {
        xRange[1] = cnsData[i]["end"];
        rangeChanged = true;
      }
      if (rangeChanged) {
        setxRange([...xRange]);
      }

      const data = [];
      labels.push(cnsData[i]["start"]);
      data.push({ x: cnsData[i]["start"], y: cnsData[i]["log2"] });
      labels.push(cnsData[i]["end"]);
      data.push({ x: cnsData[i]["end"], y: cnsData[i]["log2"] });

      dataset.push({
        label: i,
        type: "line",
        data: data,
        borderWidth: 5,
        pointRadius: 0,
        borderColor: colors["orange"][500],
        backgroundColor: "rgba(0, 0, 0, 0)",
      });
    }

    setLineChartData(dataset);
  }, []);

  useEffect(() => {
    const data = [];

    for (let i = 0; i < cnrData.length; i++) {
      if (cnrData[i]["log2"] > 1.5 || cnrData[i]["log2"] < -3) {
        continue;
      }
      const weight = cnrData[i]["weight"];
      data.push({
        x: cnrData[i]["start"],
        y: cnrData[i]["log2"],
        r: weight * 5 < 1 ? 1 : weight * 5,
      });
    }

    setBubbleChartData([
      {
        type: "bubble",
        label: "",
        backgroundColor: "rgba(100, 100, 100, 0.4)",
        borderColor: "rgba(100, 100, 100, 0.6)",
        data: data,
      },
    ]);
  }, []);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    title: {
      display: true,
      text: "chr" + chrName,
    },
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            min: -3,
            max: 1.5,
          },
          afterBuildTicks: function (axis, ticks) {
            const cnLineValues = [];
            for (let i = 0; i < copyNumberLines.length; i++) {
              cnLineValues.push(copyNumberLines[i].value);
            }
            return [1.5, 0, -3].concat(cnLineValues);
          },
          scaleLabel: {
            display: true,
            labelString: t("Copy ratio (log2)"),
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            min: xRange[0],
            max: xRange[1],
            callback: (value) => {
              return Math.round(value / 1000000);
            },
          },
          scaleLabel: {
            display: true,
            labelString: t("Positions") + " (Mb)",
          },
        },
      ],
    },
  };

  if (lineChartData) {
    return (
      <div className={classes.chartWrapper}>
        <Bar
          height={500}
          data={{
            labels: [],
            datasets: [...lineChartData, ...bubbleChartData],
          }}
          options={options}
        />
      </div>
    );
  } else {
    return <CircularProgress className={classes.spinner} />;
  }
};

export const CNVPlots = (props) => {
  const { files, appConf, reportMeta } = props;
  const classes = useStyles();
  const [plotChromosomes, setPlotChromosomes] = useState(undefined);
  const [plotSwitches, setPlotSwitches] = useState({});
  const [cnsData, setCnsData] = useState(undefined);
  const [cnrData, setCnrData] = useState(undefined);
  const [helpInformation, setHelpInformation] = useState(undefined);
  const { t } = useTranslation();

  const processFile = (data) => {
    let tableData = data.split("\n");
    const headerRow = tableData[0].split("\t");
    const columnIndex = {};
    headerRow.map((header, index) => (columnIndex[header] = index));

    tableData = tableData.slice(1);
    const tmpObjects = {};
    for (let i = 0; i < tableData.length; i++) {
      const dataRow = tableData[i].split("\t");
      if (!dataRow.length) continue;
      const chrName = dataRow[columnIndex["chromosome"]]
        .split("_")[0]
        .replace("chr", "");
      if (!chrName) continue;
      if (!tmpObjects[chrName]) {
        tmpObjects[chrName] = [];
      }
      tmpObjects[chrName].push({
        start: parseInt(dataRow[columnIndex["start"]]),
        end: parseInt(dataRow[columnIndex["end"]]),
        log2: parseFloat(dataRow[columnIndex["log2"]]),
        weight: parseFloat(dataRow[columnIndex["weight"]]),
      });
    }
    // safeLogger(tmpObjects);
    return tmpObjects;
  };

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["cnr"].download_address)
        .then((response) => response.text())
        .then((data) => {
          if (mounted) {
            setCnrData(processFile(data));
          }
        });
    } catch (error) {
      safeLogger(error);
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (plotChromosomes) {
      const tmpSwitches = {};
      plotChromosomes.map((chr) => (tmpSwitches[`chr${chr}`] = true));
      setPlotSwitches(tmpSwitches);
    }
  }, [plotChromosomes]);

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["cnv"].download_address)
        .then((response) => response.text())
        .then((data) => {
          const targetChromosomes = new Set();
          data = data.split("\n");

          // check if the last line is empty
          if (!data[data.length - 1]) {
            data = data.slice(1, data.length - 1);
          } else {
            data = data.slice(1);
          }

          for (let i = 0; i < data.length; i++) {
            const row = data[i].split("\t");
            targetChromosomes.add(row[0].replace("chr", ""));
          }

          if (mounted) {
            setPlotChromosomes(Array.from(targetChromosomes));
          }
        });
    } catch (error) {
      safeLogger(error);
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["cns"].download_address)
        .then((response) => response.text())
        .then((data) => {
          if (mounted) {
            setCnsData(processFile(data));
          }
        });
    } catch (error) {
      safeLogger(error);
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-cache");
    fetch(
      `/assets/statistics/stats_doc_${getBaseLanguage(appConf.lang)}.json`,
      {
        cache: "no-cache",
        headers: myHeaders,
      },
    ) // TODO translate to all languages
      .then((response) => response.json())
      .then((data) => {
        if (mounted) {
          setHelpInformation(data);
        }
      });

    return function cleanup() {
      mounted = false;
    };
  }, [appConf]);

  const handleSwitchChange = (event) => {
    setPlotSwitches({
      ...plotSwitches,
      [event.target.name]: event.target.checked,
    });
  };

  if (plotChromosomes && cnsData && cnrData) {
    return plotChromosomes.length ? (
      <Box>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography
                component="h4"
                variant="h4"
                style={{ margin: "13px 0" }}
              >
                {t("Plots")}
              </Typography>
              <ChartWrapper
                title={helpInformation["scatter_plot"]?.label}
                helpDesc={helpInformation["scatter_plot"]?.description}
              />
            </Box>
            <FormGroup row style={{ margin: "30px auto" }}>
              {Object.keys(plotSwitches).map((plotKey, index) => {
                if (
                  reportMeta.project_type === "acnv" ||
                  (reportMeta.project_type !== "acnv" &&
                    !["chrX", "chrY"].includes(plotKey))
                ) {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Switch
                          checked={plotSwitches[plotKey]}
                          onChange={handleSwitchChange}
                          name={plotKey}
                        />
                      }
                      label={plotKey}
                    />
                  );
                }
                return null;
              })}
            </FormGroup>
          </Grid>
          {plotChromosomes.map((chr, index) => {
            if (plotSwitches[`chr${chr}`]) {
              return (
                <Grid item xs={12} sm={6} key={index}>
                  {reportMeta.project_type === "acnv" ? (
                    <ScatteredPlot
                      chrName={chr}
                      cnsData={cnsData[chr]}
                      cnrData={cnrData[chr]}
                    />
                  ) : (
                    !["X", "Y"].includes(chr) && (
                      <ScatteredPlotNoTreshold
                        chrName={chr}
                        cnsData={cnsData[chr]}
                        cnrData={cnrData[chr]}
                      />
                    )
                  )}
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </Box>
    ) : (
      <div></div>
    );
  } else {
    return <CircularProgress className={classes.spinner} />;
  }
};
