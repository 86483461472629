/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  makeStyles,
  Menu as MuiMenu,
  MenuItem,
  Slider,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  ToggleOn as ToggleOnIcon,
  ToggleOff as ToggleOffIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { apiLoadFilteredRowIds } from "src/api";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import safeLogger from "src/services/safeLogger";
import { SelectFilterHead } from "./selectFilterHead";
import { VirtualizedAutocomplete } from "./autoComplete";
import DraggableHeader from "./draggableHeader";

const Menu = React.forwardRef((props, ref) => {
  return <MuiMenu ref={ref} {...props} />;
});

const SortIndicator = (props) => {
  const { sortDirection, classes } = props;

  return (
    <ArrowRightAltIcon
      className={clsx(classes.sortIndicator, {
        [classes.ascSortIndicator]: sortDirection === "ASC",
        [classes.descSortIndicator]: sortDirection === "DESC",
      })}
    />
  );
};

const HeaderCheckbox = (props) => {
  const {
    type,
    rowIds,
    checkedRows,
    setCheckedRows,
    totalRowCount,
    filterState,
    sortState,
    reportId,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const clickHandler = (event) => {
    event.stopPropagation();
    if (checkedRows.length === 0) {
      if (type === "members") {
        setCheckedRows(rowIds);
      } else {
        safeLogger(filterState);
        if (!filterState || Object.keys(filterState).length === 0) {
          const checkedTmp = [];
          for (let i = 1; i <= totalRowCount; i++) {
            checkedTmp.push(i);
          }
          setCheckedRows(checkedTmp);
        } else {
          // TODO make api call to get the row ids
          apiLoadFilteredRowIds(
            reportId,
            {
              filters: filterState,
              ordering: sortState,
            },
            (data, status) => {
              if (status === 200) {
                setCheckedRows(data.rows);
              } else {
                enqueueSnackbar(
                  t(
                    "There was an error while selecting the rows. Please try again."
                  ),
                  {
                    variant: "error",
                  }
                );
              }
            }
          );
        }
      }
    } else {
      setCheckedRows([]);
    }
  };

  return (
    <Checkbox
      color="default"
      indeterminate={
        checkedRows.length > 0 && checkedRows.length !== totalRowCount
      }
      checked={checkedRows.length > 0}
      onClick={clickHandler}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  filtered: {
    "& #filter-icon path": {
      padding: "2px",
      color: `${theme.palette.secondary.main} !important`,
    },
  },
}));

export const FilterTableHeader = (props) => {
  const {
    appConf,
    classes,
    headerHeight,
    label,
    columns,
    setColumns,
    columnIndex,
    sortState,
    setSortState,
    headerIndex,
    checkedRows,
    setCheckedRows,
    totalRowCount,
    filterState,
    reportId,
    tableKind,
    expandOverride,
    filterType,
    fullWidth,
    setVisibleCols,
    visibleCols,
    isOverflow,
    rowIds,
  } = props;
  const filterClasses = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const showSortIndicator = Object.keys(sortState).includes(
    columns[columnIndex].name
  );

  const [autoCompleteValue, setAutoCompleteValue] = useState([]);

  const [rangeStep, setRangeStep] = useState(null);

  const [checked, setChecked] = useState(
    columns[columnIndex] &&
      columns[columnIndex]?.filterRange &&
      columns[columnIndex]?.filterRange.length >= 3
  );

  const [activeRange, setActiveRange] = useState([
    Number(
      columns[columnIndex]?.filterRange
        ? columns[columnIndex]?.filterRange[0]
        : columns[columnIndex].minValue
    ),
    Number(
      columns[columnIndex]?.filterRange
        ? columns[columnIndex]?.filterRange[1]
        : columns[columnIndex].maxValue
    ),
  ]);
  const [tmpColumns, setTmpColumns] = useState([...columns]);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    if (
      filterType === "range" &&
      columns[columnIndex] &&
      columns[columnIndex]?.filterRange &&
      columns[columnIndex]?.filterRange.lenth
    ) {
      if (
        columns[columnIndex]?.filterRange[1] -
          columns[columnIndex]?.filterRange[0] <
        10
      ) {
        if (mounted) {
          setRangeStep(
            (columns[columnIndex]?.filterRange[1] -
              columns[columnIndex]?.filterRange[0]) /
              20
          );
        }
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setTmpColumns([...columns]);
    if (filterType === "search") {
      if (!columns[columnIndex].activeFilterCount) {
        setAutoCompleteValue([]);
      } else {
        const newValue = [];
        Object.keys(columns[columnIndex].filterValues).forEach((key) => {
          if (columns[columnIndex].filterValues[key]) {
            newValue.push(key);
          }
        });
        setAutoCompleteValue(newValue);
      }
    }
  }, [columns]);

  const handleAutocompleteChange = (event, activeValues, option) => {
    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));
    const filterKeys = Object.keys(newColDetails[columnIndex].filterValues);
    for (let i = 0; i < filterKeys.length; i++) {
      if (
        activeValues.includes(filterKeys[i]) &&
        !newColDetails[columnIndex].filterValues[filterKeys[i]]
      ) {
        newColDetails[columnIndex].filterValues[filterKeys[i]] = true;
        newColDetails[columnIndex].activeFilterCount++;
        continue;
      } else if (
        !activeValues.includes(filterKeys[i]) &&
        newColDetails[columnIndex].filterValues[filterKeys[i]]
      ) {
        newColDetails[columnIndex].filterValues[filterKeys[i]] = false;
        newColDetails[columnIndex].activeFilterCount--;
        continue;
      }
    }

    setTmpColumns(newColDetails);
    // setColumns(newColDetails);
    setAutoCompleteValue(activeValues);
  };

  const handleApplyRange = (event, newValue) => {
    event.stopPropagation();
    const newColDetails = JSON.parse(JSON.stringify(columns));

    if (
      newValue[0] !== newColDetails[columnIndex].minValue ||
      newValue[1] !== newColDetails[columnIndex].maxValue
    ) {
      newColDetails[columnIndex].activeFilterCount = 1;
    } else {
      newColDetails[columnIndex].activeFilterCount = 0;
    }

    if (checked) {
      newColDetails[columnIndex].activeFilterCount = 1;
      newValue.push("null");
    }

    newColDetails[columnIndex].filterRange = newValue;

    setActiveRange([...newValue]);
    setColumns([...newColDetails]);
  };

  const handleRangeChange = (event, newValue) => {
    event.stopPropagation();
    setActiveRange(newValue);
  };

  const handleSelectAll = () => {
    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));

    if (
      !newColDetails[columnIndex].activeFilterCount ||
      (newColDetails[columnIndex].activeFilterCount &&
        newColDetails[columnIndex].activeFilterCount !==
          Object.keys(newColDetails[columnIndex].filterValues).length)
    ) {
      const newAutocompleteValues = [];
      for (let item in newColDetails[columnIndex].filterValues) {
        newAutocompleteValues.push(item);
        if (!newColDetails[columnIndex].filterValues[item]) {
          newColDetails[columnIndex].filterValues[item] = true;
          newColDetails[columnIndex].activeFilterCount++;
        }
      }
      if (filterType === "search") {
        setAutoCompleteValue(newAutocompleteValues);
      }
    } else {
      for (let item in newColDetails[columnIndex].filterValues) {
        newColDetails[columnIndex].filterValues[item] = false;
      }
      newColDetails[columnIndex].activeFilterCount = 0;
      if (filterType === "search") {
        setAutoCompleteValue([]);
      }
    }

    setTmpColumns(newColDetails);
    // setColumns(newColDetails);
    // handleClose();
  };

  const handleSelect = (event, item) => {
    event.stopPropagation();

    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));

    if (!newColDetails[columnIndex].filterValues[item]) {
      newColDetails[columnIndex].activeFilterCount++;
    } else {
      newColDetails[columnIndex].activeFilterCount--;
    }

    newColDetails[columnIndex].filterValues[item] =
      !newColDetails[columnIndex].filterValues[item];

    setTmpColumns(newColDetails);
    // setColumns(newColDetails);
    // handleClose();
  };

  useEffect(() => {}, [checked]);

  const checkboxHandle = (e) => {
    setChecked(e.target.checked);
    // handleApplyRange({ stopPropagation: () => {} }, activeRange);
  };

  const handleApplyFilters = () => {
    setColumns(tmpColumns);
    handleClose();
  };

  const handleSort = (event) => {
    const newSortState = { ...sortState };
    if (newSortState[columns[columnIndex].name]) {
      if (newSortState[columns[columnIndex].name] === "ASC") {
        newSortState[columns[columnIndex].name] = "DESC";
      } else {
        delete newSortState[columns[columnIndex].name];
      }
    } else {
      newSortState[columns[columnIndex].name] = "ASC";
    }
    setSortState(newSortState);
  };

  const handleSelectFilteredAll = (value) => {
    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));
    let newAutocompleteValues = autoCompleteValue;
    let filters = {};
    for (let item in newColDetails[columnIndex].filterValues) {
      if (item.includes(value)) filters = { ...filters, [item]: false };
    }
    for (let item in filters) {
      if (
        newColDetails[columnIndex].filterValues[item] &&
        !(newColDetails[columnIndex].filterValues[item] in filters)
      ) {
        newColDetails[columnIndex].filterValues[item] = false;
        newColDetails[columnIndex].activeFilterCount--;
        newAutocompleteValues = newAutocompleteValues.filter(
          (filter) => !filter.includes(item)
        );
      } else {
        newAutocompleteValues.push(item);
        if (!newColDetails[columnIndex].filterValues[item]) {
          newColDetails[columnIndex].filterValues[item] = true;
          newColDetails[columnIndex].activeFilterCount++;
        }
      }
    }
    if (filterType === "search") {
      setAutoCompleteValue(newAutocompleteValues);
    }

    setTmpColumns(newColDetails);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
      setTmpColumns([...columns]);
      if (filterType === "search") {
        const newAutocompleteValues = [];
        for (let item in columns[columnIndex].filterValues) {
          if (columns[columnIndex].filterValues[item]) {
            newAutocompleteValues.push(item);
          }
        }
        setAutoCompleteValue(newAutocompleteValues);
      }
    }
    setAnchorEl(null);
  };

  return (
    <div
      className={clsx(classes.filterableHeader)}
      is-overflow={isOverflow ? "true" : "false"}
      style={{
        height: headerHeight,
        flex: "none",
      }}
    >
      {headerIndex === 0 && tableKind === "variant" && (
        <HeaderCheckbox
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          totalRowCount={totalRowCount}
          sortState={sortState}
          filterState={filterState}
          reportId={reportId}
        />
      )}
      {headerIndex === 0 && tableKind === "members" && (
        <HeaderCheckbox
          type="members"
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          totalRowCount={totalRowCount}
          rowIds={rowIds}
        />
      )}
      {headerIndex === 0 && tableKind === "project" && (
        <Box style={{ opacity: 0, marginRight: "13px" }}>
          <Tooltip title={expandOverride ? "Collapse all" : "Expand all"}>
            <IconButton
              className={clsx({
                [classes.toggleActive]: expandOverride,
              })}
              onClick={(event) => {
                event.stopPropagation();
                // setExpandOverride(!expandOverride); // TODO fix the row height recompute problem
              }}
            >
              {expandOverride ? <ToggleOnIcon /> : <ToggleOffIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <TableCell
        component="div"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
        }}
        className={clsx(classes.flexContainer, classes.noClick)}
        variant="head"
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        {showSortIndicator && columns[columnIndex].type !== "nofilter" && (
          <SortIndicator
            sortDirection={sortState[columns[columnIndex].name]}
            classes={classes}
          />
        )}
        <Tooltip
          placement="top"
          className="header-tooltip-wrapper"
          title={
            columns[columnIndex].description
              ? t(columns[columnIndex].description)
              : ""
          }
        >
          <span
            className={classes.tableHeaderColor}
            style={{ paddingLeft: 5 }}
            onClick={
              columns[columnIndex].type !== "nofilter" ? handleSort : null
            }
          >
            {t(label)}
          </span>
        </Tooltip>
      </TableCell>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {columns[columnIndex].type !== "nofilter" &&
            columns[columnIndex].kind !== "free_form" && (
              <div
                className={clsx("filter-button-wrapper", {
                  [classes.cnvFilterButton]: tableKind === "cnv",
                })}
              >
                <Button
                  aria-controls="filter-menu-button"
                  className={clsx({
                    [filterClasses.filtered]:
                      filterState &&
                      Object.keys(filterState).includes(
                        columns[columnIndex].name
                      ),
                  })}
                  onClick={handleClick}
                >
                  <FilterListIcon
                    id="filter-icon"
                    // className={clsx({
                    //   [filterClasses.filtered]:
                    //     filterState &&
                    //     Object.keys(filterState).includes(
                    //       columns[columnIndex].name
                    //     ),
                    // })}
                  />
                </Button>
                {columns[columnIndex].type !== "nofilter" &&
                  filterType === "select" && (
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <SelectFilterHead
                        handleSelectAll={handleSelectAll}
                        tmpColumns={tmpColumns}
                        columnIndex={columnIndex}
                        handleApplyFilters={handleApplyFilters}
                        classes={classes}
                      />
                      {Object.entries(tmpColumns[columnIndex].filterValues).map(
                        (item, index) => {
                          let altLabel;
                          if (tableKind === "project") {
                            switch (columns[columnIndex].name) {
                              case "project_type":
                                altLabel =
                                  appConf.entities.project_types[item[0]];
                                break;
                              case "pipeline_class":
                                for (let projectType in appConf.entities
                                  .pipeline_classes) {
                                  if (
                                    appConf.entities.pipeline_classes[
                                      projectType
                                    ][item[0]]
                                  ) {
                                    altLabel =
                                      appConf.entities.pipeline_classes[
                                        projectType
                                      ][item[0]];
                                  }
                                }
                                break;
                              case "kit":
                                altLabel = appConf.entities.kits[item[0]];
                                break;
                              case "created_at":
                                altLabel = format(
                                  new Date(item[0]),
                                  "dd/MM/yyyy HH:mm"
                                );
                                break;
                              default:
                                break;
                            }
                          } else if (tableKind === "variant") {
                            if (
                              columns[columnIndex].representation.options &&
                              columns[columnIndex].representation.options
                                .component === "star-rating"
                            ) {
                              altLabel =
                                item[0] >= 0 ? (
                                  <Rating
                                    className={classes.starRating}
                                    name="revstat"
                                    readOnly
                                    key={index}
                                    max={4}
                                    value={parseFloat(item[0])}
                                    precision={0.1}
                                  />
                                ) : (
                                  ""
                                );
                            } else if (
                              columns[columnIndex].representation.options &&
                              columns[columnIndex].representation.options
                                .component_choices &&
                              columns[columnIndex].representation.options
                                .component_choices.constructor === Object
                            ) {
                              altLabel = (
                                <span
                                  className={clsx({
                                    [classes["green[500]"]]: item[0] === "6",
                                    [classes["lightGreen[500]"]]:
                                      item[0] === "5",
                                    [classes["yellow[600]"]]: item[0] === "4",
                                    [classes["amber[500]"]]: item[0] === "3",
                                    [classes["orange[500]"]]: item[0] === "2",
                                    [classes["red[500]"]]: item[0] === "1",
                                    [classes["red[900]"]]: item[0] === "0",
                                  })}
                                  // className={clsx({
                                  //   [classes[
                                  //     columns[columnIndex].representation.options
                                  //       .style[item[0]].color
                                  //   ]]:
                                  //     columns[columnIndex].representation.options &&
                                  //     columns[columnIndex].representation.options
                                  //       .conditional_style &&
                                  //     columns[columnIndex].representation.options
                                  //       .style[item[0]],
                                  // })}
                                >
                                  {
                                    columns[columnIndex].representation.options
                                      .component_choices[item[0]]
                                  }
                                </span>
                              );
                            }
                          }
                          return (
                            <MenuItem
                              onClick={(e) => handleSelect(e, item[0])}
                              key={index}
                              data-index={index}
                            >
                              <Checkbox
                                checked={item[1]}
                                inputProps={{ "aria-labelledby": index }}
                              />
                              {altLabel ?? item[0]}
                            </MenuItem>
                          );
                        }
                      )}
                    </Menu>
                  )}
                {columns[columnIndex].type !== "nofilter" &&
                  filterType === "search" && (
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <SelectFilterHead
                        handleSelectAll={handleSelectAll}
                        tmpColumns={tmpColumns}
                        columnIndex={columnIndex}
                        handleApplyFilters={handleApplyFilters}
                        classes={classes}
                      />
                      <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                        <VirtualizedAutocomplete
                          appConf={appConf}
                          tableKind={tableKind}
                          handleSelectFilteredAll={handleSelectFilteredAll}
                          classes={classes}
                          columns={tmpColumns}
                          columnIndex={columnIndex}
                          handleChange={handleAutocompleteChange}
                          options={Object.keys(
                            tmpColumns[columnIndex].filterValues
                          )}
                          controlledValue={autoCompleteValue}
                        />
                      </MenuItem>
                    </Menu>
                  )}
                {columns[columnIndex].type !== "nofilter" &&
                  filterType === "range" && (
                    <Menu
                      onClick={(event) => event.stopPropagation()}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      className={classes.sliderMenu}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Slider
                          value={activeRange ?? [0, 100]}
                          onChange={handleRangeChange}
                          onChangeCommitted={handleApplyRange}
                          getAriaValueText={(value) => value ?? ""}
                          aria-labelledby={`${label}-silder`}
                          min={
                            isNaN(Number(columns[columnIndex].minValue))
                              ? 0
                              : Number(columns[columnIndex].minValue)
                          }
                          max={
                            isNaN(Number(columns[columnIndex].maxValue))
                              ? 100
                              : Number(columns[columnIndex].maxValue)
                          }
                          step={rangeStep ? rangeStep : 1}
                          valueLabelDisplay="on"
                        />
                        {["details3.af", "details3.af_1000g"].includes(
                          columns[columnIndex].name
                        ) && (
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title={t("Filters empty values")}>
                              <Checkbox
                                style={{ marginLeft: 25 }}
                                checked={checked}
                                onChange={checkboxHandle}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </Tooltip>
                            <Tooltip title={t("Apply filters")}>
                              <IconButton
                                onClick={(e) => {
                                  handleApplyRange(e, activeRange);
                                }}
                                className={classes.applyFiltersBtn}
                              >
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </Menu>
                  )}
              </div>
            )}
          {tableKind === "variant" && (
            <DraggableHeader
              visibleCols={visibleCols}
              headerIndex={headerIndex}
              fullWidth={fullWidth}
              setVisibleCols={setVisibleCols}
            />
          )}
        </div>
        {/* <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "e-resize",
          }}
          onMouseDown={initDrag}
        >
          <DragHandle style={{ transform: "rotate(90deg)" }} />
        </Box> */}
      </div>
    </div>
  );
};
