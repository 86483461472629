import { Checkbox, IconButton, makeStyles, TableCell } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { TooltipTableCell } from "../../virtualizedTable/tooltipTableCell";
import safeLogger from "src/services/safeLogger";
import { Star, StarBorder } from "@material-ui/icons";
import { apiSendVariantAnnotation } from "src/api/endpoints";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    maxWidth: 100,
    overflow: "hidden",
    paddingTop: 2,
    paddingBottom: 2,
    borderRight: "0.3px solid rgba(0,0,0,0.1)",
    color: theme.palette.filter.primary,
  },
}));

const CustomTableCell = React.memo(function CustomTableCell({
  cell,
  defaultColDetailsObject,
  handleTagged,
  setCheckedRows,
  checkedRows,
  hpo,
  value,
  isHaventCheckbox,
  reportMeta,
  userObject,
  reportId,
  index,
}) {
  const innerRef = useRef();
  const handleCheck = () => {
    const arrayIndex = checkedRows.indexOf(cell.row.original["___row___"]);
    if (arrayIndex > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const arrayIndex = checkedRows.indexOf(cell.row.original["___row___"]);
      if (arrayIndex > -1) {
        // only splice array when item is found
        checkedRows.splice(arrayIndex, 1); // 2nd parameter means remove one item only
      } else {
        checkedRows.push(cell.row.original["___row___"]);
      }
      setCheckedRows([...checkedRows]);
    },
    [cell.row.original, checkedRows, setCheckedRows]
  );

  let exceptions = [];

  if (
    cell.row.original &&
    Object.keys(cell.row.original).length &&
    cell.row.original.warning_col
  ) {
    try {
      exceptions = JSON.parse(cell.row.original.warning_col)[cell.column.id]
        ? JSON.parse(cell.row.original.warning_col)[cell.column.id]
        : [];
    } catch (error) {
      safeLogger("exception error", cell.row.original);
    }
  }
  return (
    <Cell
      cell={cell}
      index={index}
      defaultColDetailsObjectMemo={defaultColDetailsObject}
      reportMeta={reportMeta}
      reportId={reportId}
      exceptions={exceptions}
      hpo={hpo}
      ref={innerRef}
      handleTagged={handleTagged}
      isHaventCheckbox={isHaventCheckbox}
      handleCheck={handleCheck}
      userObject={userObject}
      handleClick={handleClick}
      value={value}
      setCheckedRows={setCheckedRows}
      checkedRows={checkedRows}
    />
  );
});

const Cell = React.memo(
  React.forwardRef(
    (
      {
        cell,
        index,
        handleClick,
        defaultColDetailsObjectMemo,
        value,
        isHaventCheckbox,
        reportMeta,
        reportId,
        hpo,
        userObject,
        handleTagged,
        exceptions,
        handleCheck,
      },
      ref
    ) => {
      const classes = useStyles();
      return (
        <TableCell
          variant="body"
          className={classes.tableCell}
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "100%",
            maxHeight: 30,
            minHeight: 30,
          }}
        >
          <div
            ref={ref}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "100%",
              display: "flex",
              maxHeight: 30,
              minHeight: 30,
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {index === 0 && !isHaventCheckbox && (
              <Checkbox onClick={handleClick} checked={handleCheck()} />
            )}
            <TooltipTableCell
              height={200}
              alignment={
                (cell.row.index != null &&
                  defaultColDetailsObjectMemo[cell.column.id].numeric) ||
                false
                  ? "right"
                  : "left"
              }
              ref={ref}
              hpo={hpo}
              VariantTag={
                <VariantTag
                  index={index}
                  cell={cell}
                  handleTagged={handleTagged}
                  reportMeta={reportMeta}
                  userObject={userObject}
                  reportId={reportId}
                />
              }
              data={value}
              column={defaultColDetailsObjectMemo[cell.column.id]}
              exceptions={exceptions}
              rowData={cell.row.original}
              reportId={reportId}
              reportMeta={reportMeta}
            />
          </div>
        </TableCell>
      );
    }
  )
);

export default CustomTableCell;

const VariantTag = React.memo(
  ({ cell, reportId, reportMeta, handleTagged }) => {
    const [tagged, setTagged] = useState(cell.row.original["___tagged___"]);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
      // setTagged(cell.row.original["___tagged___"]);
    }, [cell]);

    const tag = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const tmpPostData = {
        row_id: cell.row.original["___row___"],
        column_name: cell.column.id,
        identifier_value: reportMeta.identifier_column,
        value: !tagged,
      };
      apiSendVariantAnnotation(reportId, tmpPostData, (data, status) => {
        if (status === 202) {
          // cell.row.original["___tagged___"] =
          //   !cell.row.original["___tagged___"];
          setTagged((prev) => !prev);
          handleTagged(cell.row.original["___row___"]);
          enqueueSnackbar(
            t(tagged ? "Tag succesfuly removed." : "Tag succesfuly added."),
            {
              variant: "success",
            }
          );
        }
      });
    };
    return (
      <IconButton onClick={tag} color="secondary" style={{ padding: "5px 0" }}>
        {tagged ? <Star /> : <StarBorder />}
      </IconButton>
    );
  }
);
