import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { getTranslated } from "src/components/pages/reports/utils/helpers";
import VariantRow from "./variantRow";

function Variants({ schema, variants, classes, extraFields, setExtraFields }) {
  return (
    <Box style={{ overflow: "scroll", maxWidth: "100vw" }}>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell>{getTranslated("Select Column")}</TableCell>
            {Object.keys(schema).map((key, index) => {
              const column = schema[key];
              return (
                (!column.representation.options.hasOwnProperty("visible") ||
                  column.representation.options.visible) && (
                  <TableCell key={index}>
                    {getTranslated(column.label)}
                  </TableCell>
                )
              );
            })}
            <TableCell>{getTranslated("Comment")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variants.results.map((variant, index) => {
            return (
              <VariantRow
                variant={variant}
                setExtraFields={setExtraFields}
                extraFields={extraFields}
                schema={schema}
                key={index}
                classes={classes}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}

export default Variants;
