import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { isJSONString } from "src/components/utils";
import i18n from "src/services/i18n";

export const getColMinValue = (variantsData, colIndex) => {
  let tmp = [];
  for (let i = 0; i < variantsData.length; i++) {
    let val = parseFloat(variantsData[i][colIndex]);
    if (!isNaN(val)) {
      tmp.push(val);
    }
  }
  return Math.min(...tmp);
};

export function isEmpty(val) {
  return val === undefined || val == null || val.length <= 0 ? true : false;
}

export const pageSizes = [5, 10, 20, 50, 100, 200];

export const getColMaxValue = (variantsData, colIndex) => {
  let tmp = [];
  for (let i = 0; i < variantsData.length; i++) {
    let val = parseFloat(variantsData[i][colIndex]);
    if (!isNaN(val)) {
      tmp.push(val);
    }
  }
  return Math.max(...tmp);
};

export const filterRows = (rows, columns) => {
  /*
    filter the rows based on filterValues
  */

  // safeLogger(rows);
  // safeLogger(columns);

  let filteredRows = rows.slice(0);

  for (let i = 0; i < columns.length; i++) {
    // safeLogger(columns[i]);
    if (columns[i].activeFilterCount) {
      // safeLogger(columns[i]);
      filteredRows = filteredRows.filter((row) => {
        // safeLogger(columns[i].filterValues[row[columns[i].name]]);
        if (columns[i].kind === "numeric") {
          return (
            columns[i]?.filterRange[0] <= row[columns[i].name] &&
            columns[i]?.filterRange[1] >= row[columns[i].name]
          );
        } else {
          if (row[columns[i].name] && row[columns[i].name].includes(",")) {
            const separatedValues = row[columns[i].name].split(",");
            for (let j = 0; j < separatedValues.length; j++) {
              if (columns[i]?.filterValues[separatedValues[j]]) {
                return true;
              }
            }
            return false;
          } else {
            return columns[i]?.filterValues[row[columns[i].name]];
          }
        }
      });
    }
  }

  return filteredRows;
};

export const getBaseLanguage = (language = i18n.language) => {
  return language.split("_")[0];
};

export const getTranslated = (translationObject, language = i18n.language) => {
  if (typeof translationObject === "string") {
    if (!isJSONString(translationObject.replaceAll("'", '"'))) {
      return translationObject;
    } else {
      translationObject = JSON.parse(translationObject.replaceAll("'", '"'));
    }
  }
  if (!translationObject) return "";
  if (typeof translationObject !== "string") {
    if (_.get(translationObject, language)) {
      return _.get(translationObject, language);
    }
  }
  const lang = getBaseLanguage(language);
  const objectLang = Object.keys(translationObject).filter(function (k) {
    return ~k.indexOf(lang);
  })[0];
  return translationObject[objectLang] ?? translationObject["*"];
};

export const prepareFiles = (files, unified_output, data) => {
  let preparedFiles = {};
  for (const file in files) {
    const tags = files[file].tags;
    if (file === "hpo_pheno.tsv") {
      files["hpo_pheno"] = files[file];
    } else if (file === "panelapp_filters.tsv") {
      files["panelapp_filters"] = files[file];
    }

    if (!unified_output) {
      // TODO fix after release
      if (
        data &&
        data.name &&
        file.split("/")[0] !== data.name.replace("_cnv_table_v1", "")
      ) {
        continue;
      }
    }

    for (const tag of tags) {
      preparedFiles = {
        ...preparedFiles,
        [tag]: files[file],
      };
    }
  }
  return preparedFiles;
};

export const localeTr = () => {
  const suffixes = {
    1: "'inci",
    5: "'inci",
    8: "'inci",
    70: "'inci",
    80: "'inci",
    2: "'nci",
    7: "'nci",
    20: "'nci",
    50: "'nci",
    3: "'üncü",
    4: "'üncü",
    100: "'üncü",
    6: "'ncı",
    9: "'uncu",
    10: "'uncu",
    30: "'uncu",
    60: "'ıncı",
    90: "'ıncı",
  };

  const tr = moment.updateLocale("tr", {
    months:
      "Ocak_Şubat_Mart_Nisan_Mayıs_Haziran_Temmuz_Ağustos_Eylül_Ekim_Kasım_Aralık".split(
        "_",
      ),
    monthsShort: "Oca_Şub_Mar_Nis_May_Haz_Tem_Ağu_Eyl_Eki_Kas_Ara".split("_"),
    weekdays: "Pazar_Pazartesi_Salı_Çarşamba_Perşembe_Cuma_Cumartesi".split(
      "_",
    ),
    weekdaysShort: "Paz_Pzt_Sal_Çar_Per_Cum_Cmt".split("_"),
    weekdaysMin: "Pz_Pt_Sa_Ça_Pe_Cu_Ct".split("_"),
    meridiem: function (hours, _minutes, isLower) {
      if (hours < 12) {
        return isLower ? "öö" : "ÖÖ";
      } else {
        return isLower ? "ös" : "ÖS";
      }
    },
    meridiemParse: /öö|ÖÖ|ös|ÖS/,
    isPM: function (input) {
      return input === "ös" || input === "ÖS";
    },
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD.MM.YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd, D MMMM YYYY HH:mm",
    },
    calendar: {
      sameDay: "[bugün saat] LT",
      nextDay: "[yarın saat] LT",
      nextWeek: "[gelecek] dddd [saat] LT",
      lastDay: "[dün] LT",
      lastWeek: "[geçen] dddd [saat] LT",
      sameElse: "L",
    },
    relativeTime: {
      future: "%s sonra",
      past: "%s önce",
      s: "birkaç saniye",
      ss: "%d saniye",
      m: "bir dakika",
      mm: "%d dakika",
      h: "bir saat",
      hh: "%d saat",
      d: "bir gün",
      dd: "%d gün",
      w: "bir hafta",
      ww: "%d hafta",
      M: "bir ay",
      MM: "%d ay",
      y: "bir yıl",
      yy: "%d yıl",
    },
    ordinal: function (number) {
      if (number === 0) {
        // special case for zero
        return number + "'ıncı";
      }
      const a = number % 10,
        b = (number % 100) - a,
        c = number >= 100 ? 100 : number;
      return number + (suffixes[a] || suffixes[b] || suffixes[c]);
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 7, // The week that contains Jan 7th is the first week of the year.
    },
  });

  return tr;
};
