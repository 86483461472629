import {
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Info, Save } from "@material-ui/icons";
import { apiReportAnnotationStatusUpdate } from "src/api/endpoints";
import { useSnackbar } from "notistack";
import i18next from "i18next";
import { connect } from "react-redux";
import { getBaseLanguage, getTranslated } from "../reports/utils/helpers";

function Status({ data, value, id, organization, appConf }) {
  const [statusData, setStatusData] = useState(
    value?.id
      ? value?.id
      : organization?.default_report_status
      ? organization?.default_report_status
      : data[0]?.id
  );
  const statusChoices = data.length ? data : organization.status_choices;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = ({ target }) => {
    setStatusData(target.value);
  };

  const saveStatus = () => {
    apiReportAnnotationStatusUpdate(
      id,
      { status: statusData },
      (data, status) => {
        if (status === 202) {
          enqueueSnackbar(t("Status changed"), {
            variant: "success",
          });
        }
      }
    );
  };

  statusChoices.forEach((val) => {
    i18next.addResourceBundle("en", "translations", {
      [getTranslated(val.label, "en")]: getTranslated(val.label, "en"),
    });
    i18next.addResourceBundle("tr", "translations", {
      [getTranslated(val.label, "tr")]: getTranslated(val.label, "tr"),
    });
  });

  return (
    <span style={{ display: "flex" }}>
      <FormControl style={{ width: "100%", margin: "0 10px" }}>
        <Select
          style={{ width: "100%" }}
          value={data.length ? statusData : organization.status_choices[0].id}
          onChange={handleChange}
        >
          {statusChoices.map((val, index) => {
            return (
              <MenuItem key={index} value={val.id}>
                {getTranslated(val.label)}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style={{ width: "100%", margin: "0 10px" }}>
          {t("Determines what status the report is")}
        </FormHelperText>
      </FormControl>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <IconButton size="small" color="primary">
          <Tooltip placement="top" title={value.evaluated_at}>
            <Info style={{ fontSize: 22, width: "100%" }} />
          </Tooltip>
        </IconButton> */}
        <IconButton
          size="small"
          color="primary"
          style={{ padding: 8, height: 40, width: 40 }}
          onClick={saveStatus}
        >
          <Save style={{ fontSize: 22, width: "100%" }} />
        </IconButton>
        {/* <Button
          onClick={saveStatus}
          variant="contained"
          color="primary"
          size="small"
        >
          <Save style={{ fontSize: 22 }} />
        </Button> */}
      </div>
    </span>
  );
}

const mapStateToProps = (state) => {
  return {
    organization: state.organization,
    appConf: state.appConf,
  };
};

export default connect(mapStateToProps)(Status);
