/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, makeStyles, colors } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { materialColors } from "./conf";
import ChartWrapper from "./chartWrapper";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import Chart from "chart.js";
import { ChartLegend } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
  },
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

const getLastChrSuffix = (dataset, chr) => {
  if (!dataset.length) {
    return 0;
  }

  let suffix = 0;

  for (let i = 0; i < dataset.length; i++) {
    const datasetLabel = dataset[i].label.split(" ");
    if (datasetLabel[0] === chr && parseInt(datasetLabel[1]) > suffix) {
      suffix = parseInt(datasetLabel[1]);
    }
  }

  return suffix;
};

const copyNumberLines = [
  { value: 1.17, cn: 4, color: "indigo" },
  { value: 0.807, cn: 3, color: "blue" },
  { value: 0.322, cn: 2, color: "green" },
  { value: -0.415, cn: 1, color: "pink" },
  { value: -2, cn: 0, color: "red" },
];

const stripes = [
  { color: "rgba(17, 24, 87, 0.2)", height: 0.073333333 },
  { color: "rgba(13, 71, 161, 0.2)", height: 0.080666667 },
  { color: "rgba(0, 188, 212, 0.2)", height: 0.107777778 },
  { color: "rgba(76, 175, 80, 0.2)", height: 0.163777778 },
  { color: "rgba(255, 64, 129, 0.2)", height: 0.352222222 },
  { color: "rgba(125, 0, 0, 0.2)", height: 0.222222222 },
];

const ChromosomeCopyRatioLineChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files } = props;
  const [sampleData, setSampleData] = useState(undefined);
  const [xRange, setXRange] = useState([]);
  const [chrReverse, setChrReverse] = useState(undefined);
  const [chromosomePositions, setChromosomePositions] = useState(undefined);
  const [chromosomeLines, setChromosomeLines] = useState(undefined);
  const [cnRanges, setCnRanges] = useState(undefined);
  const [sex, setSex] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["report_information"].download_address)
        .then((response) => response.json())
        .then((data) => {
          const gender = data.gender
            ? data.gender === "XY"
              ? "male"
              : "female"
            : "male";
          if (mounted) {
            setSex(gender);
          }
        });
    } catch (e) {
      safeLogger(e);
      if (mounted) {
        setSex("male");
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    Chart.pluginService.register({
      beforeDraw: function (chart, easing) {
        if (chart.config.options.chartArea === "cnv") {
          var ctx = chart.chart.ctx;
          var chartArea = chart.chartArea;

          ctx.save();

          ctx.clearRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
          );

          // var grd = ctx.createLinearGradient(0, 0, 200, 0);
          // grd.addColorStop(0, "red");
          // grd.addColorStop(1, "white");

          // ctx.fillStyle = grd;

          // ctx.fillStyle = chart.config.options.chartArea.background;
          // ctx.fillRect(
          //   chartArea.left,
          //   chartArea.top,
          //   chartArea.right - chartArea.left,
          //   chartArea.bottom - chartArea.top
          // );

          let filledHeight = 0;
          for (let i = 0; i < stripes.length; i++) {
            const stripHeight =
              (chartArea.bottom - chartArea.top) * stripes[i].height;
            ctx.fillStyle = stripes[i].color;
            ctx.fillRect(
              chartArea.left,
              chartArea.top + filledHeight,
              chartArea.right - chartArea.left,
              stripHeight
            );
            filledHeight += stripHeight;
          }

          ctx.restore();
        }
      },
    });
  }, []);

  useEffect(() => {
    const rangeLines = [];
    for (let i = 0; i < copyNumberLines.length; i++) {
      rangeLines.push({
        drawTime: "afterDatasetsDraw",
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: copyNumberLines[i].value,
        borderWidth: 1,
        borderColor: colors[copyNumberLines[i].color][300],
        // borderDash: [10, 5],
        label: {
          content: `cn ${copyNumberLines[i].cn} (${copyNumberLines[i].value}) `,
          enabled: false,
          position: "right",
          fontColor: "#313131",
          fontSize: 10,
          backgroundColor: "rgba(0,0,0,0)",
          yAdjust: 10,
          // yAdjust: (i % 2) * 15,
        },
      });
    }
    setCnRanges(rangeLines);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (sex) {
      let prevPosition = 0;
      const chrPositions = {};
      const chrLines = [];
      fetch("/assets/statistics/bam_chr_statsfrom_samtools")
        .then((response) => response.text())
        .then((data) => {
          let chrData = data.split("\n");
          const uselessChr = sex === "female" ? 2 : 3;
          chrData = chrData.slice(0, chrData.length - uselessChr);
          for (let i = 0; i < chrData.length; i++) {
            const chromosome = chrData[i].split("\t");
            const position = prevPosition + parseFloat(chromosome[1]);
            chrPositions[position] = chromosome[0];
            chrLines.push({
              drawTime: "afterDatasetsDraw",
              type: "line",
              mode: "vertical",
              scaleID: "x-axis-0",
              value: position,
              borderWidth: 1,
              borderColor: colors[materialColors[16]][300],
              borderDash: [10, 5],
              label: {
                // content: chrKeys[i],
                enabled: false,
                // position: "top",
                // fontColor: "#313131",
                // fontSize: 10,
                // backgroundColor: "rgba(0,0,0,0)",
                // yAdjust: (i % 2) * 15,
              },
            });
            prevPosition = position;
          }
          if (mounted) {
            setChromosomePositions(chrPositions);
          }
        });
      if (mounted) {
        setChromosomeLines(chrLines);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [sex]);

  // useEffect(() => {
  //   let prevPosition = 0;
  //   const chrPositions = {};
  //   const chrLines = [];
  //   fetch("/assets/statistics/qualimapReport.json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const chrKeys = Object.keys(data.chromosome);
  //       for (let i = 0; i < chrKeys.length; i++) {
  //         const position =
  //           prevPosition + parseFloat(data.chromosome[chrKeys[i]].length);
  //         chrPositions[chrKeys[i]] = position;
  //         chrLines.push({
  //           drawTime: "afterDatasetsDraw",
  //           type: "line",
  //           mode: "vertical",
  //           scaleID: "x-axis-0",
  //           value: position,
  //           borderWidth: 1,
  //           borderColor: colors[materialColors[16]][300],
  //           borderDash: [10, 5],
  //           label: {
  //             // content: chrKeys[i],
  //             enabled: false,
  //             // position: "bottom",
  //             // fontColor: "#313131",
  //             // fontSize: 10,
  //             // backgroundColor: "rgba(0,0,0,0)",
  //             // yAdjust: (i % 2) * 15,
  //           },
  //         });
  //         prevPosition = position;
  //       }

  //       safeLogger("chromosome positions", chrPositions);
  //       setChromosomePositions(chrPositions);
  //     });
  //   setChromosomeLines(chrLines);
  // }, []);

  // const initOptions = {
  //   animation: {
  //     duration: 0,
  //   },
  //   annotation: {
  //     annotations: chromosomeLines,
  //   },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   elements: {
  //     line: {
  //       tension: 0,
  //     },
  //   },
  //   title: {
  //     display: true,
  //     text: t("Chromosomes-based copy ratio"),
  //   },
  //   legend: { display: false },
  //   tooltips: {
  //     callbacks: {
  //       label: function (context) {
  //         return t("Copy ratio (log2)") + ": " + context.yLabel;
  //       },
  //     },
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           min: -1.5,
  //           max: 1.5,
  //         },
  //         scaleLabel: {
  //           display: true,
  //           labelString: t("Copy ratio (log2)"),
  //         },
  //       },
  //     ],
  //     xAxes: [
  //       {
  //         type: "linear",
  //         position: "bottom",
  //         ticks: {
  //           minRotation: 90,
  //           // min: labels[0],
  //           // max: chrPos[chrPos.length - 1],
  //           callback: (value) => {
  //             // safeLogger(reverseChromosomes[value]);
  //             return chromosomePositions[value];
  //           },
  //         },
  //         // afterBuildTicks: function (axis, ticks) {
  //         //   ticks = Object.values(reverseChromosomes);
  //         //   return ticks;
  //         // },
  //         scaleLabel: {
  //           display: true,
  //           labelString: t("Positions"),
  //         },
  //       },
  //     ],
  //   },
  // };

  useEffect(() => {
    let mounted = true;
    if (chromosomePositions && chromosomeLines && sex) {
      const reverseChromosomes = {};
      const chrPos = Object.keys(chromosomePositions);
      for (let i = 0; i < chrPos.length; i++) {
        if (i === 0) {
          reverseChromosomes["chr1"] = 0;
        } else {
          reverseChromosomes[chromosomePositions[chrPos[i]]] = chrPos[i - 1];
        }
      }
      try {
        fetch(getBaseURL() + files["calling"].download_address)
          .then((response) => response.text())
          .then((rawData) => {
            const labels = [];
            const dataset = [];

            rawData = rawData.split("\n");
            const columns = rawData[0].split("\t");
            const columnIndex = {};
            for (let i = 0; i < columns.length; i++) {
              columnIndex[columns[i]] = i;
            }

            rawData = rawData.slice(1);

            for (let i = 0; i < rawData.length - 1; i++) {
              const data = [];
              const row = rawData[i].split("\t");
              const chromosomeName =
                row[columnIndex["chromosome"]].split("_")[0];
              const start = parseInt(row[columnIndex["start"]]);
              let end = parseInt(row[columnIndex["end"]]);

              if (end - start < 10000000) {
                end += 10000000;
              }

              const position = parseInt(reverseChromosomes[chromosomeName]);
              const log2 = parseFloat(row[columnIndex["log2"]]);
              labels.push(start + position); // start
              data.push({
                x: start + position,
                y: log2,
              });
              labels.push(end + position); // end
              data.push({
                x: end + position,
                y: log2,
              });

              let suffix = getLastChrSuffix(dataset, chromosomeName) + 1;

              const copyNumber = parseInt(row[columnIndex["cn"]]);

              dataset.push({
                label: chromosomeName + " " + suffix,
                data: data,
                borderWidth: 5,
                pointRadius: 0,
                backgroundColor: "rgba(1, 1, 1, 0.2)",
                // borderColor:
                //   colors[
                //     materialColors[
                //       chromosomeName[chromosomeName.length - 1].charCodeAt() %
                //         materialColors.length
                //     ]
                //   ][400],
                borderColor:
                  copyNumber === 0
                    ? colors.red[400]
                    : copyNumber === 1
                    ? sex === "male" &&
                      ["chrX", "chrY"].includes(chromosomeName)
                      ? colors.green[400]
                      : colors.pink[400]
                    : copyNumber === 2
                    ? sex === "male" &&
                      ["chrX", "chrY"].includes(chromosomeName)
                      ? colors.pink[400]
                      : colors.green[400]
                    : copyNumber === 3
                    ? colors.blue[400]
                    : copyNumber === 4
                    ? colors.indigo[400]
                    : colors.grey[400],
              });
            }
            if (mounted) {
              setSampleData({
                labels: labels,
                datasets: [...dataset],
              });

              setXRange([labels[0], chrPos[chrPos.length - 2]]);
              setChrReverse(reverseChromosomes);
            }
          });
      } catch (e) {
        safeLogger(e);
        if (mounted) {
          setSampleData({ labels: [], datasets: [] });
        }
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [chromosomePositions, chromosomeLines, sex]);

  const options = {
    chartArea: "cnv",
    animation: {
      duration: 0,
    },
    annotation: {
      // annotations: chromosomeLines,
      annotations: cnRanges,
    },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    title: {
      display: true,
      text: t("Chromosomes-based copy ratio"),
    },
    legend: { display: false },
    tooltips: {
      callbacks: {
        label: function (context) {
          return t("Copy ratio (log2)") + ": " + context.yLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: -3,
            max: 1.5,
          },
          afterBuildTicks: function (axis, ticks) {
            const cnLineValues = [];
            for (let i = 0; i < copyNumberLines.length; i++) {
              cnLineValues.push(copyNumberLines[i].value);
            }
            return [1.5, 0, -3].concat(cnLineValues);
          },
          scaleLabel: {
            display: true,
            labelString: t("Copy ratio (log2)"),
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            minRotation: 90,
            min: xRange[0],
            max: xRange[1],
            callback: (value) => {
              // safeLogger(reverseChromosomes[value]);
              return chromosomePositions[value];
            },
          },
          afterBuildTicks: function (axis, ticks) {
            ticks = Object.values(chrReverse);
            return ticks;
          },
          scaleLabel: {
            display: true,
            labelString: t("Positions"),
          },
        },
      ],
    },
  };

  if (!sampleData || !chrReverse || !xRange.length) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
      >
        <ChartLegend
          style={{ marginTop: "-24px" }}
          items={[
            { name: t("CN 4"), color: "rgba(13, 71, 161, 0.5)" },
            { name: t("CN 3"), color: "rgba(0, 188, 212, 0.5)" },
            { name: t("CN 2"), color: "rgba(76, 175, 80, 0.5)" },
            { name: t("CN 1"), color: "rgba(255, 64, 129, 0.5)" },
            { name: t("CN 0"), color: "rgba(125, 0, 0, 0.5)" },
          ]}
        />
        <Line height={450} data={sampleData} options={options} />
      </ChartWrapper>
    );
  }
};

export default ChromosomeCopyRatioLineChart;
