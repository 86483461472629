import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { memo } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CellContent from "./tableCell";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    overflow: "scroll",
  },
  tableContainer: {
    height: 500,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function ExomeContent({ exomebasePercantage }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      width={1000}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Exons")}</TableCell>
              {Object.keys(
                JSON.parse(
                  exomebasePercantage[0]["result"]?.replaceAll("'", '"')
                )
              ).map((val, index) => {
                if (val) return <TableCell key={index}>{val}</TableCell>;
              })}
              {/* <TableCell>1X</TableCell>
              <TableCell>10X</TableCell>
              <TableCell>50X</TableCell>
              <TableCell>100X</TableCell> 
              <TableCell>{t("Depth")}</TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {exomebasePercantage
              .sort((item, nextItem) => {
                return Number(item.exon.split("E")[1]) <
                  Number(nextItem.exon.split("E")[1])
                  ? -1
                  : 1;
              })
              .map((row, key) => (
                <TableRow key={key}>
                  <TableCell size="medium" style={{ width: 100 }}>
                    {row.exon}
                  </TableCell>
                  {Object.values(
                    JSON.parse(row["result"]?.replaceAll("'", '"'))
                  ).map((val, index) => {
                    return (
                      <TableCell
                        size="medium"
                        key={index}
                        style={{ width: 200 }}
                      >
                        <CellContent value={val} />
                      </TableCell>
                    );
                  })}
                  {/* <TableCell size="medium" style={{ width: 200 }}>
                    <CellContent value={row["1X"]} />
                  </TableCell>
                  <TableCell size="medium" style={{ width: 200 }}>
                    <CellContent value={row["10X"]} />
                  </TableCell>
                  <TableCell size="medium" style={{ width: 200 }}>
                    <CellContent value={row["50X"]} />
                  </TableCell>
                  <TableCell size="medium" style={{ width: 200 }}>
                    <CellContent value={row["100X"]} />
                  </TableCell>
                  <TableCell size="medium" style={{ width: 100 }}>
                    {(
                      (Number(row["1X"]) / 100 +
                        Number(row["10X"]) / 100 +
                        Number(row["50X"]) / 100 +
                        Number(row["100X"]) / 100) /
                      0.04
                    ).toFixed(1)}
                    %
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default memo(ExomeContent);
