import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./services/ErrorBoundry";
import safeLogger from "./services/safeLogger";
import { createRoot } from "react-dom/client";

const serveMock = process.env.REACT_APP_SERVE_MSW === "true";
safeLogger("REACT_APP_SERVE_MSW", serveMock);

if (process.env.REACT_APP_ENV === "local") {
  window.store = store;
}

if (process.env.REACT_APP_ENV === "local" && serveMock) {
  const { mockServer } = require("src/testServer/mock");
  mockServer.start();
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);

if (process.env.REACT_APP_ENV !== "development" || !serveMock) {
  safeLogger("unregistering");
  serviceWorker.unregister();
}

// serviceWorker.unregister();
