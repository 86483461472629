export const LOAD_USER = "LOAD_USER";
export const LOAD_APP_CONF = "LOAD_APP_CONF";
export const SET_LANG = "SET_LANG";
export const AUTH_SIGNIN = "AUTH_SIGNIN";
export const SIGNOUT = "SIGNOUT";
export const UPDATE_ACTIVE_SAMPLES = "UPDATE_ACTIVE_SAMPLES";
export const UPDATE_ACTIVE_PROJECTS = "UPDATE_ACTIVE_PROJECTS";
export const GENE_BASED_FILTER_CHANGE = "GENE_BASED_FILTER_CHANGE";
export const GENE_BASED_FILTER_CHANGE_STATS = "GENE_BASED_FILTER_CHANGE_STATS";
export const SET_THEME = "SET_THEME";
export const CLEAR_ACTIVE_GENE_FILTERS = "CLEAR_ACTIVE_GENE_FILTERS";
export const LOAD_ORGANIZATION = "LOAD_ORGANIZATION";
export const SET_ADVANCE_FILTER = "SET_ADVANCE_FILTER";
export const SET_SAMPLE_SIZE = "SET_SAMPLE_SIZE";
