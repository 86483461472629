/* eslint-disable react-hooks/exhaustive-deps */
import { Add, Check, FilterList, Remove } from "@material-ui/icons";
import { CgArrowsExchangeAltV, CgArrowUp } from "react-icons/cg";
import { flexRender } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import disableScroll from "disable-scroll";
import { apiLoadFilteredRowIds } from "src/api";
import safeLogger from "src/services/safeLogger";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import {
  Box,
  Button,
  Checkbox,
  colors,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  makeStyles,
  Menu,
  MenuItem,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { SelectFilterHead } from "../../virtualizedTable/selectFilterHead";
import { Rating } from "@material-ui/lab";
import { VirtualizedAutocomplete } from "../../virtualizedTable/autoComplete";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { NumericField } from "src/components/pages/settings/tableConfigPage/tableLayoutEditor";
import { isEmpty } from "../../utils/helpers";
// import Filter from "./Filter";
import HeaderInfoButton from "./headerInfoButton";
import { isJSONString } from "src/components/utils";
import { SharedVariants } from "../../virtualizedTable/tooltipTableCell";

const useStyle = makeStyles((theme) => ({
  applyFiltersBtn: {
    color: theme.palette.common.white,
    background: colors.green[400],
    "&:hover": {
      background: colors.green[600],
    },
  },
  sliderMenu: {
    "& ul": {
      width: "350px",
    },
  },
  filtered: {
    color: "pink !important",
    backgroundColor: "",
  },
  slider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    padding: "60px 30px 30px",
    "& .MuiSlider-valueLabel > span": {
      width: "50px !important",
      height: "50px !important",
      transform: "rotate(-45deg) translate(12%, -38%)",
    },
  },
  autocompleteMenuItem: {
    "& .menuItemCheckBox": { marginRight: "1px" },
    display: "flex",
    alignItems: "center",
    width: "200px",
  },

  "red[900]": {
    color: colors.red[900],
    "& .MuiInputBase-input": {
      color: colors.red[900],
    },
  },
  "red[500]": {
    color: colors.red[600],
    "& .MuiInputBase-input": {
      color: colors.red[600],
    },
  },
  "yellow[600]": {
    color: colors.orange[400],
    "& .MuiInputBase-input": {
      color: colors.orange[400],
    },
  },
  "amber[500]": {
    color: colors.orange[600],
    "& .MuiInputBase-input": {
      color: colors.orange[600],
    },
  },
  "orange[500]": {
    color: colors.orange[900],
    "& .MuiInputBase-input": {
      color: colors.orange[900],
    },
  },
  "green[500]": {
    color: theme.palette.success.dark,
    "& .MuiInputBase-input": {
      color: theme.palette.success.dark,
    },
  },
  "lightGreen[500]": {
    color: theme.palette.success.light,
    "& .MuiInputBase-input": {
      color: theme.palette.success.light,
    },
  },
  starRating: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  boolean: {},
  sliderTextField: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    gap: theme.spacing(0.2),
  },
  stepButtons: {
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
  activeButton: {
    backgroundColor: colors.green[400],
  },
  sliderThumb: {
    "&.MuiSlider-valueLabel": {
      width: "45px !important",
      height: "45px !important",
      transform: "rotate(-45deg) translate(10%, -28%)",
    },
  },
}));

const Header = React.memo(function Header({
  filters,
  setCheckedRows,
  checkedRows,
  header,
  colDetails,
  reportId,
  setColDetails,
  columnHelper,
  tableKind,
  isHaventCheckbox,
  appConf,
  table,
  dynamicRowCount,
  index,
}) {
  const advanceFilter = useSelector((state) => state.advanceFilter);
  const [orderBy, setOrderBy] = React.useState(
    filters.tableSortState[header.id]
      ? filters.tableSortState[header.id] === "ASC"
        ? 2
        : 3
      : 1
  );
  const tmpColDetails = columnHelper[header.column.id];
  const columnResizeMode = "onChange";
  const { t } = useTranslation();

  const orderHandler = () => {
    switch (orderBy) {
      case 1:
        filters.setTableSortState({
          ...filters.tableSortState,
          [header.id]: "ASC",
        });
        setOrderBy((prev) => prev + 1);
        break;
      case 2:
        filters.setTableSortState({
          ...filters.tableSortState,
          [header.id]: "DESC",
        });
        setOrderBy((prev) => prev + 1);
        break;
      case 3:
        delete filters.tableSortState[header.id];
        filters.setTableSortState({ ...filters.tableSortState });
        setOrderBy(1);
        break;
      default:
        setOrderBy(1);
        break;
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    if (!isDragging) {
      disableScroll.off();
    } else {
      disableScroll.on();
    }
    delete draggableStyle.zIndex;
    return {
      ...draggableStyle,
      zIndex: isDragging ? "99999 !important" : "2px !important",
      // change background colour if dragging
      // backgroundColor: isDragging ? "lightgreen" : "#388e3c",
      color: isDragging ? "black" : "white",

      // styles we need to apply on draggables
    };
  };

  if (!colDetails[tmpColDetails?.globalIndex]) {
    return;
  }
  if (colDetails[tmpColDetails.globalIndex].filterValues === undefined) {
    colDetails[tmpColDetails.globalIndex].filterValues = {};
  }

  return (
    <Draggable key={index} draggableId={String(index)} index={index}>
      {(provided, snapshot) => (
        <div
          style={{
            height: "content-fit",
            color: "white",
            whiteSpace: "nowrap",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            textOverflow: "clip",
            justifyContent: "space-between",
            flexBasis: 200,
            alignItems: "center",
          }}
        >
          {index === 0 && !isHaventCheckbox && (
            <div>
              <HeaderCheckbox
                colDetails={columnHelper}
                setCheckedRows={setCheckedRows}
                checkedRows={checkedRows}
                reportId={reportId}
                totalRowCount={dynamicRowCount}
                sortState={filters.tableSortState}
                filterState={filters.tableFilterState}
              />
            </div>
          )}
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, {
              ...provided.draggableProps.style,
              whiteSpace: "nowrap",
            })}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {(!advanceFilter ||
              Object.keys(advanceFilter)?.length <= 0 ||
              advanceFilter?.rules.length <= 0) && (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  orderHandler();
                }}
                style={{
                  cursor: "pointer",
                  fontSize: 24,
                  display: "flex",
                  transition: "all 0.3s ease",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  transform: `rotate(${
                    filters.tableSortState[header.id] === "ASC" ? `0` : `180deg`
                  })`,
                }}
              >
                {!filters.tableSortState[header.id] ? (
                  <CgArrowsExchangeAltV />
                ) : (
                  <CgArrowUp />
                )}
              </div>
            )}
            <div
              onMouseDown={header.getResizeHandler()}
              onClick={(event) => event.preventDefault()}
              onMouseUp={(event) => event.preventDefault()}
              onTouchStart={header.getResizeHandler()}
              className={`resizer ${
                header.column.getIsResizing() ? "isResizing" : ""
              }`}
              style={{
                transform:
                  columnResizeMode === "onEnd" && header.column.getIsResizing()
                    ? `translateX(${
                        table.getState().columnSizingInfo.deltaOffset
                      }px)`
                    : "",
              }}
            ></div>
            {tmpColDetails &&
              (!advanceFilter ||
                Object.keys(advanceFilter)?.length <= 0 ||
                advanceFilter?.rules.length <= 0) && (
                <HeaderFilter
                  columns={colDetails}
                  label={tmpColDetails.label}
                  name={tmpColDetails.name}
                  setColumns={setColDetails}
                  columnIndex={tmpColDetails.globalIndex}
                  appConf={appConf}
                  filterState={filters.tableFilterState}
                  tableKind={tableKind ?? "variant"}
                  filterType={
                    tmpColDetails?.kind === "numeric"
                      ? "range"
                      : Object.keys(tmpColDetails.filterValues).length > 15
                      ? "search"
                      : colDetails[tmpColDetails.globalIndex]?.kind ===
                        "boolean"
                      ? "boolean"
                      : "select"
                  }
                />
              )}
            <div>
              {tmpColDetails.representation.help && (
                <HeaderInfoButton
                  helpDesc={
                    i18next.language === "en"
                      ? tmpColDetails.description.en
                      : tmpColDetails.description[1]
                  }
                  // title={tmpColDetails.representation.title}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
});

export const HeaderCheckbox = React.memo((props) => {
  const {
    type,
    rowIds,
    checkedRows,
    setCheckedRows,
    totalRowCount,
    filterState,
    sortState,
    reportId,
    colDetails,
  } = props;
  const advanceFilter = useSelector((state) => state.advanceFilter);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const clickHandler = async (event) => {
    event.stopPropagation();
    if (checkedRows.length === 0) {
      if (type === "members") {
        setCheckedRows(rowIds);
      } else {
        safeLogger(filterState);
        if (
          !(
            advanceFilter &&
            Object.keys(advanceFilter).length &&
            advanceFilter.rules.length > 0
          ) &&
          (!filterState || Object.keys(filterState).length === 0)
        ) {
          const checkedTmp = [];
          for (let i = 0; i <= totalRowCount; i++) {
            checkedTmp.push(i);
          }
          setCheckedRows(checkedTmp);
        } else {
          let tmpFilterState = {
            combinator: "and",
            rules: [],
          };
          if (
            Object.keys(filterState).length ||
            (Object.keys(advanceFilter).length &&
              advanceFilter.rules.length > 0)
          ) {
            if (!advanceFilter) {
              await Object.entries(filterState).map(async (filter) => {
                const name = filter[0];
                const values = filter[1];
                if (colDetails[name].kind === "enumeration") {
                  tmpFilterState.rules.push({
                    field: name,
                    operator: "in",
                    // value must be string
                    value: values.join(","),
                  });
                } else if (colDetails[name].kind === "numeric") {
                  await values.forEach((value, index) => {
                    tmpFilterState.rules.push({
                      field: name,
                      operator: index === 0 ? ">=" : "<=",
                      // value must be string
                      value: value.toString(),
                    });
                  });
                } else if (colDetails[name].kind === "boolean") {
                  tmpFilterState.rules.push({
                    field: name,
                    operator: "=",
                    // value must be string
                    value: values,
                  });
                }
              });
            } else {
              tmpFilterState = advanceFilter;
            }
          }
          // TODO make api call to get the row ids
          apiLoadFilteredRowIds(
            reportId,
            {
              filters: tmpFilterState,
              ordering: sortState,
            },
            (data, status) => {
              if (status === 200) {
                setCheckedRows(data.rows);
              } else {
                enqueueSnackbar(
                  t(
                    "There was an error while selecting the rows. Please try again."
                  ),
                  {
                    variant: "error",
                  }
                );
              }
            }
          );
        }
      }
    } else {
      setCheckedRows([]);
    }
  };

  return (
    <Checkbox
      color="primary"
      style={{ color: "white" }}
      indeterminate={
        checkedRows.length > 0 && checkedRows.length !== totalRowCount
      }
      checked={checkedRows.length > 0}
      onClick={clickHandler}
    />
  );
});

export default Header;

const HeaderFilter = ({
  appConf,
  label,
  name,
  columns,
  setColumns,
  filterState,
  columnIndex,
  tableKind,
  filterType,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [radioValue, setRadioValue] = useState({});
  const [autoCompleteValue, setAutoCompleteValue] = useState(
    Array.isArray(filterState[columns[columnIndex].name])
      ? filterState[columns[columnIndex].name]
      : []
  );
  const classes = useStyle();
  const [rangeStep, setRangeStep] = useState(0.05);
  const [activeRange, setActiveRange] = useState([
    columns[columnIndex].filterRange
      ? columns[columnIndex].filterRange[0]
      : columns[columnIndex].minValue,
    columns[columnIndex].filterRange
      ? columns[columnIndex].filterRange[1]
      : columns[columnIndex].maxValue,
  ]);

  useEffect(() => {
    setActiveRange([
      columns[columnIndex].filterRange
        ? columns[columnIndex].filterRange[0]
        : columns[columnIndex].minValue,
      columns[columnIndex].filterRange
        ? columns[columnIndex].filterRange[1]
        : columns[columnIndex].maxValue,
    ]);
  }, [columns[columnIndex].filterRange]);

  const [tmpColumns, setTmpColumns] = useState(columns);
  const { t } = useTranslation();

  useEffect(() => {
    setTmpColumns([...columns]);
  }, [columns]);

  const tmpColumnIndex = tmpColumns.findIndex((val) => val.name === name);

  useEffect(() => {
    let mounted = true;
    if (filterType === "range") {
      if (
        columns[columnIndex]?.filterRange &&
        Array.isArray(columns[columnIndex]?.filterRange) &&
        columns[columnIndex]?.filterRange?.length >= 2 &&
        columns[columnIndex]?.filterRange[1] -
          columns[columnIndex]?.filterRange[0] <
          10
      ) {
        if (mounted) {
          setRangeStep(
            (columns[columnIndex]?.filterRange[1] -
              columns[columnIndex]?.filterRange[0]) /
              20
          );
        }
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const handleApplyRange = (event, newValue) => {
    event.stopPropagation();
    const newColDetails = JSON.parse(JSON.stringify(columns));
    const tmpValues = [];
    if (
      newValue[0] !== newColDetails[columnIndex].minValue ||
      newValue[1] !== newColDetails[columnIndex].maxValue
    ) {
      newColDetails[columnIndex].activeFilterCount++;
    } else {
      newColDetails[columnIndex].activeFilterCount--;
    }

    Object.entries(radioValue).forEach((e) => {
      if (e && e[1]) {
        tmpValues.push(e[0]);
      }
    });

    newColDetails[columnIndex].filterRange = [...newValue, ...tmpValues];

    setActiveRange([...newValue]);
    setColumns([...newColDetails]);
  };

  const handleSelectAll = () => {
    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));

    if (
      !newColDetails[columnIndex].activeFilterCount ||
      (newColDetails[columnIndex].activeFilterCount &&
        newColDetails[columnIndex].activeFilterCount !==
          Object.keys(newColDetails[columnIndex].filterValues).length)
    ) {
      const newAutocompleteValues = [];
      for (let item in newColDetails[columnIndex].filterValues) {
        newAutocompleteValues.push(item);
        if (!newColDetails[columnIndex].filterValues[item]) {
          newColDetails[columnIndex].filterValues[item] = true;
          newColDetails[columnIndex].activeFilterCount++;
        }
      }
      if (filterType === "search") {
        setAutoCompleteValue(newAutocompleteValues);
      }
    } else {
      for (let item in newColDetails[columnIndex].filterValues) {
        newColDetails[columnIndex].filterValues[item] = false;
      }
      newColDetails[columnIndex].activeFilterCount = 0;
      if (filterType === "search") {
        setAutoCompleteValue([]);
      }
    }

    setTmpColumns(newColDetails);
    // setColumns(newColDetails);
    // handleClose();
  };

  const handleSelect = (event, item) => {
    event.stopPropagation();

    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));

    if (!newColDetails[columnIndex].filterValues[item]) {
      newColDetails[columnIndex].activeFilterCount++;
    } else {
      newColDetails[columnIndex].activeFilterCount--;
    }

    newColDetails[columnIndex].filterValues[item] =
      !newColDetails[columnIndex].filterValues[item];

    setTmpColumns(newColDetails);
    // setColumns(newColDetails);
    // handleClose();
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
      setTmpColumns([...columns]);
      if (filterState[columns[columnIndex].name]) {
        filterState[columns[columnIndex].name].forEach((item) => {
          if (item in columns[columnIndex].filterValues) {
            columns[columnIndex].filterValues[item] = true;
          }
        });
      }
      if (filterType === "search") {
        const newAutocompleteValues = [];
        for (let item in columns[columnIndex].filterValues) {
          if (columns[columnIndex].filterValues[item]) {
            newAutocompleteValues.push(item);
          }
        }
        setAutoCompleteValue(newAutocompleteValues);
      }
    }
    setAnchorEl(null);
  };

  const geneState = useSelector((state) => state.activeGeneFiltersVariant);

  const handleApplyFilters = () => {
    setColumns([...tmpColumns]);
    handleClose();
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleRadioChange = (event, value) => {
    event.stopPropagation();

    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));
    if (
      event.target.checked &&
      newColDetails[columnIndex].filterValues[event.target.name] === undefined
    ) {
      newColDetails[columnIndex].filterValues = {
        ...newColDetails[columnIndex].filterValues,
        [event.target.value]: true,
      };
      newColDetails[columnIndex].activeFilterCount++;
    } else if (event.target.checked) {
      newColDetails[columnIndex].filterValues[event.target.name] = true;
      newColDetails[columnIndex].activeFilterCount++;
    } else {
      newColDetails[columnIndex].filterValues[event.target.name] = false;
      delete newColDetails[columnIndex].filterValues[event.target.name];
      if (newColDetails[columnIndex].activeFilterCount <= 0) {
        newColDetails[columnIndex].activeFilterCount = 0;
      } else {
        newColDetails[columnIndex].activeFilterCount--;
      }
    }

    setRadioValue((prev) => ({
      ...prev,
      [event.target.value]: event.target.checked,
    }));

    setTmpColumns(newColDetails);
    // setColumns(newColDetails);
    // handleClose();
  };

  const handleAutocompleteChange = (event, activeValues, option) => {
    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));
    const filterKeys = Object.keys(newColDetails[columnIndex].filterValues);
    for (let i = 0; i < filterKeys.length; i++) {
      if (
        activeValues.includes(filterKeys[i]) &&
        !newColDetails[columnIndex].filterValues[filterKeys[i]]
      ) {
        newColDetails[columnIndex].filterValues[filterKeys[i]] = true;
        newColDetails[columnIndex].activeFilterCount++;
        continue;
      } else if (
        !activeValues.includes(filterKeys[i]) &&
        newColDetails[columnIndex].filterValues[filterKeys[i]]
      ) {
        newColDetails[columnIndex].filterValues[filterKeys[i]] = false;
        newColDetails[columnIndex].activeFilterCount--;
        continue;
      }
    }

    setTmpColumns(newColDetails);
    // setColumns(newColDetails);
    setAutoCompleteValue(activeValues);
  };

  const checkboxHandle = (isChecked) => {
    setTmpColumns((prev) => {
      prev[columnIndex].filterType = isChecked;
      return [...prev];
    });
    // handleApplyRange({ stopPropagation: () => {} }, activeRange);
  };

  useEffect(() => {
    if (filterType === "search") {
      const newAutocompleteValues = [];
      if (filterState[columns[columnIndex].name]) {
        newAutocompleteValues.push(...filterState[columns[columnIndex].name]);
      }
      setAutoCompleteValue(newAutocompleteValues);
    }
  }, [geneState, anchorEl]);

  // This useEffect if has many filters add to filtervalues
  // useEffect(() => {
  //   if (
  //     tmpColumns[tmpColumnIndex].multi_value &&
  //     (tmpColumns[tmpColumnIndex].kind === "enumeration" ||
  //       tmpColumns[tmpColumnIndex].kind === "numeric")
  //   ) {
  //     const tmpFilters = [
  //       { "<unary>": false },
  //       { "<many>": false },
  //       { "<empty>": false },
  //     ];
  //     tmpFilters.forEach((item) => {
  //       tmpColumns[tmpColumnIndex].filterValues = {
  //         ...tmpColumns[tmpColumnIndex].filterValues,
  //         ...item,
  //       };
  //     });
  //     setTmpColumns([...tmpColumns]);
  //   }
  // }, []);

  const handleRangeChange = (event, newValue) => {
    event.stopPropagation();
    setActiveRange(newValue);
  };

  const handleBoolean = (e, column, value) => {
    e.preventDefault();
    e.stopPropagation();
    column.filterValues = value;
    let hasAnyFilter = false;
    for (const item in value) {
      if (value[item]) {
        hasAnyFilter = true;
      }
    }
    if (hasAnyFilter) {
      column.activeFilterCount = 1;
    } else {
      column.activeFilterCount = 0;
    }
    setTmpColumns([...tmpColumns]);
  };

  const handleSelectFilteredAll = (value) => {
    const newColDetails = JSON.parse(JSON.stringify(tmpColumns));
    let newAutocompleteValues = autoCompleteValue;
    let filters = {};
    for (let item in newColDetails[columnIndex].filterValues) {
      if (item.toLowerCase().includes(value))
        filters = { ...filters, [item]: false };
    }
    for (let item in filters) {
      if (
        newColDetails[columnIndex].filterValues[item] &&
        !(newColDetails[columnIndex].filterValues[item] in filters)
      ) {
        newColDetails[columnIndex].filterValues[item] = false;
        newColDetails[columnIndex].activeFilterCount--;
        newAutocompleteValues = newAutocompleteValues.filter(
          (filter) => !filter.includes(item)
        );
      } else {
        newAutocompleteValues.push(item);
        if (!newColDetails[columnIndex].filterValues[item]) {
          newColDetails[columnIndex].filterValues[item] = true;
          newColDetails[columnIndex].activeFilterCount++;
        }
      }
    }
    if (filterType === "search") {
      setAutoCompleteValue(newAutocompleteValues);
    }

    setTmpColumns(newColDetails);
  };

  const stepps = [0.01, 0.1, 0.5, 1];

  const handleStepChange = (value) => {
    setRangeStep(value);
  };

  const handleRangeChangeTextField = (event, newValue) => {
    event.stopPropagation();
    const newColDetails = JSON.parse(JSON.stringify(columns));
    const tmpValues = [];

    if (
      newValue !==
      newColDetails[columnIndex][event.name === "max" ? "maxValue" : "minValue"]
    ) {
      newColDetails[columnIndex].activeFilterCount++;
    } else {
      newColDetails[columnIndex].activeFilterCount--;
    }

    Object.entries(radioValue).forEach((e) => {
      if (e && e[1]) {
        tmpValues.push(e[0]);
      }
    });

    let minOrMaxValue;

    if (event.target.name === "max") {
      minOrMaxValue = [
        newColDetails[columnIndex].filterRange[0] === "NaN"
          ? newColDetails[columnIndex].maxValue
          : newColDetails[columnIndex].filterRange[0],
        event.target.value,
      ];
    } else {
      minOrMaxValue = [
        event.target.value,
        newColDetails[columnIndex].filterRange[1] === "NaN"
          ? newColDetails[columnIndex].minValue
          : newColDetails[columnIndex].filterRange[1],
      ];
    }
    newColDetails[columnIndex].filterRange = minOrMaxValue;

    // setColumns([...newColDetails]);
    setActiveRange([...minOrMaxValue]);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {columns[columnIndex].type !== "nofilter" &&
          columns[columnIndex]?.kind !== "free_form" && (
            <div
              className={clsx("filter-button-wrapper", {
                [classes.cnvFilterButton]: tableKind === "cnv",
              })}
            >
              <Button
                aria-controls="filter-menu-button"
                aria-haspopup="true"
                style={{ padding: 0, minWidth: 35, color: "white" }}
                onClick={handleClick}
              >
                <FilterList
                  className={clsx({
                    [classes.filtered]:
                      filterState &&
                      Object.keys(filterState).includes(
                        columns[columnIndex].name
                      ),
                  })}
                  style={{
                    color: filterState[columns[columnIndex].name]
                      ? colors.grey[500]
                      : "white",
                    dropShadow: "3.4px 6.7px 6.7px hsl(0deg 0% 0% / 40%)",
                  }}
                />
              </Button>
              {columns[columnIndex].type !== "nofilter" &&
                filterType === "select" && (
                  <CustomMenu
                    column={tmpColumns[columnIndex]}
                    setRadioValue={handleRadioChange}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    radioValue={radioValue}
                    classes={classes}
                  >
                    <SelectFilterHead
                      handleSelectAll={handleSelectAll}
                      tmpColumns={tmpColumns}
                      checkboxHandle={checkboxHandle}
                      checked={tmpColumns[columnIndex]?.filterType}
                      columnIndex={tmpColumnIndex}
                      handleApplyFilters={handleApplyFilters}
                      classes={classes}
                    />
                    {Object.entries(
                      tmpColumns[tmpColumnIndex]?.filterValues ?? {}
                    ).map((item, index) => {
                      let altLabel;
                      if (tableKind === "project") {
                        switch (columns[columnIndex].name) {
                          case "project_type":
                            altLabel = appConf.entities.project_types[item[0]];
                            break;
                          case "pipeline_class":
                            for (let projectType in appConf.entities
                              .pipeline_classes) {
                              if (
                                appConf.entities.pipeline_classes[projectType][
                                  item[0]
                                ]
                              ) {
                                altLabel =
                                  appConf.entities.pipeline_classes[
                                    projectType
                                  ][item[0]];
                              }
                            }
                            break;
                          case "kit":
                            altLabel = appConf.entities.kits[item[0]];
                            break;
                          case "created_at":
                            altLabel = format(
                              new Date(item[0]),
                              "dd/MM/yyyy HH:mm"
                            );
                            break;
                          default:
                            break;
                        }
                      } else if (tableKind === "variant") {
                        if (
                          columns[columnIndex].representation.options &&
                          columns[columnIndex].representation.options
                            .component === "star-rating"
                        ) {
                          altLabel =
                            item[0] >= 0 ? (
                              <Rating
                                className={classes.starRating}
                                name="revstat"
                                readOnly
                                key={index}
                                max={4}
                                value={parseFloat(item[0])}
                                precision={0.1}
                              />
                            ) : (
                              ""
                            );
                        } else if (
                          columns[columnIndex].representation.options &&
                          columns[columnIndex].representation.options
                            .component === "shared_variants"
                        ) {
                          altLabel = (
                            <SharedVariants
                              data={item[0].replaceAll("'", '"')}
                            />
                          );
                        } else if (
                          columns[columnIndex].representation.options &&
                          columns[columnIndex].representation.options
                            .component_choices &&
                          columns[columnIndex].representation.options
                            .component_choices.constructor === Object
                        ) {
                          altLabel = (
                            <span
                              className={clsx({
                                [classes["green[500]"]]:
                                  item[0] === "6" || item[0] === "B",
                                [classes["lightGreen[500]"]]:
                                  item[0] === "5" || item[0] === "LB",
                                [classes["yellow[600]"]]:
                                  item[0] === "4" || item[0] === "VUS",
                                [classes["amber[500]"]]:
                                  item[0] === "3" || item[0] === "VUSi",
                                [classes["orange[500]"]]:
                                  item[0] === "2" || item[0] === "VUSii",
                                [classes["red[500]"]]:
                                  item[0] === "1" || item[0] === "LP",
                                [classes["red[900]"]]:
                                  item[0] === "0" || item[0] === "P",
                              })}
                            >
                              {columns[columnIndex].representation.options
                                .component_choices[item[0]] ?? item[0]}
                            </span>
                          );
                        }
                      }
                      if (!["<empty>", "<unary>", "<many>"].includes(item[0])) {
                        return (
                          <MenuItem
                            onClick={(e) => handleSelect(e, item[0])}
                            key={index}
                            data-index={index}
                          >
                            <Checkbox
                              checked={item[1]}
                              inputProps={{ "aria-labelledby": index }}
                            />
                            {altLabel ?? t(item[0])}
                          </MenuItem>
                        );
                      } else return null;
                    })}
                  </CustomMenu>
                )}
              {columns[columnIndex].type !== "nofilter" &&
                filterType === "search" && (
                  <CustomMenu
                    radioValue={radioValue}
                    setRadioValue={handleRadioChange}
                    column={tmpColumns[columnIndex]}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    classes={classes}
                  >
                    <SelectFilterHead
                      handleSelectAll={handleSelectAll}
                      tmpColumns={tmpColumns}
                      columnIndex={tmpColumnIndex}
                      checked={tmpColumns[columnIndex]?.filterType}
                      checkboxHandle={checkboxHandle}
                      handleApplyFilters={handleApplyFilters}
                      classes={classes}
                    />
                    <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                      <VirtualizedAutocomplete
                        appConf={appConf}
                        handleSelectFilteredAll={handleSelectFilteredAll}
                        tableKind={tableKind}
                        classes={classes}
                        columns={tmpColumns}
                        columnIndex={tmpColumnIndex}
                        handleChange={handleAutocompleteChange}
                        options={Object.keys(
                          tmpColumns[tmpColumnIndex]?.filterValues
                        )}
                        controlledValue={autoCompleteValue}
                      />
                    </MenuItem>
                  </CustomMenu>
                )}
              {columns[columnIndex].type !== "nofilter" &&
                filterType === "range" && (
                  <CustomMenu
                    radioValue={radioValue}
                    setRadioValue={handleRadioChange}
                    column={tmpColumns[columnIndex]}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    classes={classes}
                  >
                    <Box className={classes.slider}>
                      <Slider
                        value={activeRange}
                        classes={classes.sliderThumb}
                        // ThumbComponent={(props) => {
                        //   return (
                        //     <Tooltip title={activeRange}>
                        //       <Box {...props} style={{ padding: 2 }}>
                        //         {props.value}
                        //       </Box>
                        //     </Tooltip>
                        //   );
                        // }}
                        onChange={handleRangeChange}
                        onChangeCommitted={handleApplyRange}
                        aria-labelledby={`${label}-silder`}
                        getAriaValueText={(value) => value}
                        min={columns[columnIndex].minValue}
                        max={columns[columnIndex].maxValue}
                        step={
                          columns[columnIndex] &&
                          columns[columnIndex].representation.options &&
                          columns[columnIndex].representation.options &&
                          columns[columnIndex].representation.options.range_step
                            ? columns[columnIndex].representation.options
                                .range_step
                            : rangeStep
                        }
                        valueLabelDisplay="on"
                      />
                      <Box style={{ paddingTop: 20 }}>
                        <Typography>
                          <b>{t("Range")}</b> :{" "}
                          {activeRange ? activeRange[0] : ""} -{" "}
                          {activeRange ? activeRange[1] : ""}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.sliderTextField}>
                      {!(
                        columns[columnIndex] &&
                        columns[columnIndex].representation.options &&
                        columns[columnIndex].representation.options &&
                        columns[columnIndex].representation.options.range_step
                      ) &&
                        stepps.map((value, index) => {
                          return (
                            <Button
                              size="small"
                              className={clsx({
                                [classes.activeButton]: value === rangeStep,
                                [classes.stepButtons]: true,
                              })}
                              key={index}
                              onClick={() => handleStepChange(value)}
                            >
                              {value}
                            </Button>
                          );
                        })}
                    </Box>
                    {/* <Box>
                         <Box className={classes.sliderTextField}>
                          <Input
                            inputProps={{
                              step: rangeStep ? rangeStep : 1,
                              min: columns[columnIndex]["minValue"],
                              max: columns[columnIndex]["maxValue"],
                              type: "number",
                              "aria-labelledby": "input-slider",
                            }}
                            id="min"
                            aria-labelledby={`${label}-silder-text-min`}
                            onChange={handleRangeChangeTextField}
                            value={
                              activeRange[0] < columns[columnIndex]["minValue"]
                                ? columns[columnIndex]["minValue"]
                                : activeRange[0]
                            }
                            placeholder={t("Min")}
                            maxLength={columns[columnIndex]["minValue"]}
                            // step="0.01"
                            type="number"
                            name="min"
                            label={t("Min")}
                          />
                          <Input
                            inputProps={{
                              step: rangeStep ? rangeStep : 1,
                              min: columns[columnIndex]["minValue"],
                              max: columns[columnIndex]["maxValue"],
                              type: "number",
                              "aria-labelledby": "input-slider",
                            }}
                            id="max"
                            aria-labelledby={`${label}-silder-text-max`}
                            onChange={handleRangeChangeTextField}
                            value={
                              activeRange[1] > columns[columnIndex]["maxValue"]
                                ? columns[columnIndex]["maxValue"]
                                : activeRange[1]
                            }
                            placeholder={t("Max")}
                            // step="0.01"
                            type="number"
                            maxLength={columns[columnIndex]["maxValue"]}
                            name="max"
                            label={t("Max")}
                          />
                          <IconButton
                            color="primary"
                            onClick={handleRangeChangeTextField}
                          >
                            <Check />
                          </IconButton>
                        </Box>
                      </Box> */}
                  </CustomMenu>
                )}
              {columns[columnIndex].type !== "nofilter" &&
                filterType === "boolean" && (
                  <CustomMenu
                    radioValue={radioValue}
                    setRadioValue={handleRadioChange}
                    column={tmpColumns[columnIndex]}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    classes={classes}
                  >
                    <SelectFilterHead
                      handleSelectAll={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleBoolean(e, tmpColumns[columnIndex], {
                          true: false,
                          false: false,
                        });
                      }}
                      isDelete={true}
                      tmpColumns={tmpColumns}
                      columnIndex={tmpColumnIndex}
                      checked={tmpColumns[columnIndex]?.filterType}
                      checkboxHandle={checkboxHandle}
                      handleApplyFilters={handleApplyFilters}
                      classes={classes}
                    />
                    <BooleanItems
                      handleBoolean={handleBoolean}
                      tmpColumns={tmpColumns}
                      columnIndex={columnIndex}
                    />
                  </CustomMenu>
                )}
            </div>
          )}
      </div>
    </div>
  );
};

const BooleanItems = ({ handleBoolean, tmpColumns, columnIndex }) => {
  const { t } = useTranslation();
  return (
    <>
      <MenuItem
        onClick={(e) => {
          handleBoolean(e, tmpColumns[columnIndex], {
            true: true,
            false: false,
          });
        }}
      >
        <Checkbox
          checked={
            !isEmpty(tmpColumns[columnIndex]?.filterValues)
              ? tmpColumns[columnIndex]?.filterValues.true
              : null
          }
        />
        {t("Tagged")}
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleBoolean(e, tmpColumns[columnIndex], {
            true: false,
            false: true,
          });
        }}
      >
        <Checkbox
          checked={
            !isEmpty(tmpColumns[columnIndex]?.filterValues)
              ? tmpColumns[columnIndex]?.filterValues.false
              : null
          }
        />
        {t("Untagged")}
      </MenuItem>
    </>
  );
};

const CustomMenu = ({
  column,
  anchorEl,
  handleClose,
  classes,
  children,
  handleSelect,
  setRadioValue,
  radioValue,
}) => {
  return (
    <Menu
      id="simple-menu"
      onClick={(event) => event.stopPropagation()}
      anchorEl={anchorEl}
      style={{ width: 500 }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={classes.sliderMenu}
    >
      {children}
      {column.multi_value &&
        (column?.kind === "enumeration" || column?.kind === "numeric") && (
          <FormGroup
            row
            style={{ justifyContent: "space-between", padding: "0px 16px" }}
          >
            {/* <FormControlLabel
              labelPlacement="bottom"
              onChange={setRadioValue}
              value="<empty>"
              control={<Checkbox checked={radioValue["<empty>"]} />}
              label="Empty"
              name="empty"
            />
            <FormControlLabel
              labelPlacement="bottom"
              onChange={setRadioValue}
              value="<unary>"
              control={<Checkbox checked={radioValue["<unary>"]} />}
              label="Unary"
              name="unary"
            />
            <FormControlLabel
              labelPlacement="bottom"
              onChange={setRadioValue}
              value="<many>"
              control={<Checkbox checked={radioValue["<many>"]} />}
              label="Many"
              name="many"
            /> */}
          </FormGroup>
        )}
    </Menu>
  );
};
