import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { Send as SendIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    marginTop: 20,
    borderTop: ".5px solid rgba(0,0,0,0.3)",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
  },
}));

function Footer({ url }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url);
  };

  return (
    <div className={classes.footer}>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={<SendIcon />}
      >
        {t("See More")}
      </Button>
    </div>
  );
}

export default Footer;
