import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

const useMenuItemStyle = makeStyles((theme) => ({
  root: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "inline-block",
    verticalAlign: "middle",
    width: "200px",
  },
}));

export const TooltipMenuItem = (props) => {
  const classes = useMenuItemStyle();
  const { content } = props;
  const [isOverflowed, setIsOverflowed] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (ref?.current) {
      setIsOverflowed(ref.current.offsetWidth < ref.current.scrollWidth);
    }
  }, [ref]);

  return (
    <div className={classes.root}>
      <Tooltip title={content} disableHoverListener={!isOverflowed}>
        <Typography noWrap innerRef={ref}>
          {content}
        </Typography>
      </Tooltip>
    </div>
  );
};
