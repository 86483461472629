export const authConf = {
  AUTH_HEADER: "Authorization",
  AUTH_TOKEN_PREFIX: "Token ",
  LOCAL_STORAGE_TOKEN_NAME: "mbiAuth",
};

export const urlAPIConf = {
  BASE_API_SLUG: "api",
  ARTICLES_SLUG: "articles",
  APP_CONFIG_SLUG: "application-configuration",
  USERS_SLUG: "users",
  PROFILE_SLUG: "profile",
  LOGIN_SLUG: "login",
  LOCK_SLUG: "lock",
  UNLOCK_SLUG: "unlock",
  LOGOUT_SLUG: "logout",
  CHANGE_PASSWORD_SLUG: "change-password",
  RESET_PASSWORD_SLUG: "reset-password",
  NOTIFICATIONS_SLUG: "notifications",
  SEARCH_SLUG: "search",
  DASHBOARD_SLUG: "dashboard",
  SETTING_SLUG: "setting",
  FILTERS_SLUG: "filters",
  SCHEMA_SLUG: "schemas",
  PUBLISH_SLUG: "publish",
  UN_PUBLISH_SLUG: "unpublish",
  FILTERS_IDENTIFY_SLUG: "identify",
  PROJECTS_SLUG: "projects",
  PROJECT_UPLOAD_SLUG: "upload",
  PROJECT_SELECT_SLUG: "select",
  PROJECT_ATTACH_SLUG: "attached-storage",
  PROJECT_ATTACH_SUBMIT_SLUG: "attach",
  PROJECT_SUBMIT_SLUG: "submit",
  PROJECT_CANCEL_SLUG: "cancel",
  PROJECT_RERUN_SLUG: "rerun",
  REPORTS_SLUG: "reports",
  VARIANT_QUERY_SLUG: "query",
  VARIANT_GENERATE_REPORT_SLUG: "generate-report",
  VARIANT_ANNOTATE_SLUG: "annotate",
  VARIANT_IDENTIFY_FILTERED_SLUG: "identify",
  COMMENT_SLUG: "comment",
  ANNOTATION_STATUS_UPDATE: "update-evaluation-status",
  ANNOTATION_STATUS: "annotation-status",
  UPDATE_ANNOTATION_STATUS: "update-ordinal",
  ERROR_SLUG: "error",
  ORGANIZATION_SLUG: "organization",
  CUSTOMISE_REPORT_STATUSES: "customise-report-statuses",
  REPORT_STATUS_SLUG: "report-status",
  VARIANT_TAG: "variant-tag",
  ORGANIZATION_MEMBERS_SLUG: "members",
  ORGANIZATION_PERMISSIONS_SLUG: "permissions",
  ORGANIZATION_TEAM_SLUG: "team",
  ORGANIZATION_DEPARTMENT_SLUG: "department",
  DIAGNOSE_ANALYSIS_SLUG: "diagnose-analysis",
  KITS_SLUG: "kits",
  ORGANIZATION_RUNNED_SAMPLES_SLUG: "runned-samples",
  CORRELATE: "correlate",
  TRANSACTIONS: "transactions",
  PRICING: "pricing",
  QUOTE_PRICE: "quote-price",
  ACQUIRE_PAYMENT: "acquire-payment",
  TOP_UP: "top-up",
  GENERATE_CUSTOM_REPORT: "generate-custom-report",
};

export const testAPIConf = {
  BASE_URL: "https://api-dev.massbio.info/",
  // Mockey tests
  CREATE_PROJECT_MOCK_URL:
    "https://run.mocky.io/v3/15624255-d4f5-49b1-bf57-ea217141fa38",
  VARIANT_DETAILS_MOCK_URL:
    "https://run.mocky.io/v3/0660b8f4-27ae-4709-86ef-cbcf2022a0a6",
  VARIANT_DATA_MOCK_URL:
    "https://run.mocky.io/v3/c7031cac-ac8d-49bd-a7d7-6139a0fc8015",
  USER_OBJECT_MOCK_URL:
    "https://run.mocky.io/v3/1e502f67-3f9e-4423-81da-844507c7df9f",
};

export const getBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      if (window.location.href.includes("app.massiveanalyser.com")) {
        return "https://app.massiveanalyser.com";
      } else {
        return process.env.REACT_APP_CLIENT_URL;
      }
    case "development":
      if (window.location.href.includes("app-dev.massivebioinformatics.com")) {
        return "https://app-dev.massivebioinformatics.com";
      } else {
        return process.env.REACT_APP_CLIENT_URL;
      }
    default:
      if (window.location.href.includes("app-dev.massivebioinformatics.com")) {
        return "https://app.massiveanalyser.com";
      } else {
        return process.env.REACT_APP_CLIENT_URL;
      }
  }
};

export const getBlogBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "https://tea.massiveanalyser.com";
    case "development":
      if (window.location.href.includes("app-dev.massivebioinformatics.com")) {
        return "https://tea.massiveanalyser.com";
      } else {
        return "https://tea.massiveanalyser.com";
      }
    default:
      if (window.location.href.includes("app-dev.massivebioinformatics.com")) {
        return "https://tea.massiveanalyser.com";
      } else {
        return "https://tea.massiveanalyser.com";
      }
  }
};
