import {
  Collapse,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import LiftOverCalc from "./liftoverCalc";
import LiftoverItem from "./liftoverItem";

const useStyles = makeStyles((theme) => ({
  item: {
    paddingLeft: theme.spacing(4),
  },
  altItem: {
    paddingLeft: theme.spacing(8),
  },
  desc: {
    marginBottom: theme.spacing(4),
  },
}));

function Liftover() {
  const datas = {
    Position: ["chr8:141310715", "chr8-141310715", "chr8   141310715"],
    Variant: [
      "chr8:141310715 T>G",
      "chr8-141310715-T-G",
      "NM_000552.4(VWF):c.3797C>A (p.Pro1266Gln)",
    ],
    Intervals: ["chr8:141310715-141310720", "chr8   141310715   141310720"],
  };

  const { t } = useTranslation();

  const [format, setFormatOpen] = useState(true);
  const classes = useStyles();

  const handleInputFormat = () => {
    setFormatOpen(!format);
  };
  return (
    <>
      <Typography className={classes.desc}>
        <Trans>
        {t(
          "Lift over variants, positions, or intervals from one reference genome version to another. This web interface supports more input formats and requires fewer clicks than existing interfaces like UCSC LiftOver and Ensembl Assembly Converter. Under the hood, it uses the UCSC liftOver command-line tool."
        )}
        </Trans>
      </Typography>
      <LiftOverCalc />
      <List>
        <ListItem button onClick={handleInputFormat}>
          {t("Input formats")}
          {format ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={format} timeout="auto" unmountOnExit>
          {Object.entries(datas).map((val) => {
            return (
              <LiftoverItem items={val[1]} label={val[0]} classes={classes} />
            );
          })}
        </Collapse>
      </List>
    </>
  );
}

export default Liftover;
