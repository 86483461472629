/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  CircularProgress,
  colors,
  Paper,
  TableCell,
  withStyles,
  Chip,
  Checkbox,
} from "@material-ui/core";
import { TooltipTableCell } from "src/components/pages/reports/virtualizedTable/tooltipTableCell";
import { Skeleton } from "@material-ui/lab";
import { FilterTableHeader } from "src/components/pages/reports/virtualizedTable/headers";
import { AutoSizer, Column, Table } from "react-virtualized";
import { cellDataGetter } from "src/components/pages/reports/utils/virtualizedCustom";
import { useTranslation, withTranslation } from "react-i18next";
import { filterRows } from "src/components/pages/reports/utils/helpers";
import { membersTableHeader } from "src/config/accountManagement";
import { getUserFullName } from "src/components/utils";

const styles = (theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    flexWrap: "wrap",
  },
  applyFiltersBtn: {
    color: theme.palette.common.white,
    background: colors.green[400],
    "&:hover": {
      background: colors.green[600],
    },
  },
  filterableHeader: {
    paddingLeft: 0,
    borderBottom: "1px solid #E0E0E",
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    "& > *": {
      fill: "#FFF",
      color: theme.palette.background.paper,
    },
    "& > div": {
      borderBottom: "none",
      paddingRight: 0,
      paddingLeft: 0,
    },
    "&:first-child > div": {
      paddingLeft: "3px",
    },
    "& .header-tooltip-wrapper": {
      display: "inline-flex",
      cursor: "pointer",
    },
    "& .filter-button-wrapper": {
      paddingTop: "6px",
      "& *": {
        color: "#FFF",
      },
    },
    "& .filtered path": {
      color: colors.orange[300],
    },
  },
  sliderMenu: {
    "& ul": {
      width: "200px",
      padding: "40px 20px 20px",
    },
  },
  autocompleteMenuItem: {
    "& .menuItemCheckBox": { marginRight: "1px" },
    "& .menuItemLabel": {
      display: "inline-block",
      verticalAlign: "middle",
      width: "200px",
    },
  },
  sortIndicator: {
    marginLeft: "-7px",
    width: "20px",
  },
  ascSortIndicator: {
    transform: "rotate(90deg)",
  },
  descSortIndicator: {
    transform: "rotate(-90deg)",
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      // paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
      paddingRight: "0 !important",
      //   paddingLeft: "62px",
      background: theme.palette.primary.main,
    },
    "& .ReactVirtualized__Table__headerRow > div:first-child": {
      marginRight: "62px",
    },
  },
  loadingTableRow: {
    border: "5px solid #FFF",
    cursor: "auto !important",
    "& .MuiSkeleton-root": {
      backgroundColor: "rgba(55, 71, 79, 0.2)",
    },
  },
  tableRow: {
    cursor: "pointer",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    background: theme.palette.background.paper,
    "& .completedChip": { backgroundColor: theme.palette.success.main },
    "& .activeChip": { backgroundColor: theme.palette.warning.main },
    "& .errorChip": { backgroundColor: theme.palette.error.main },
  },
  tableCell: {
    width: "100%",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "& < $innerTable:hover": {
      background: theme.palette.background.paper,
    },
  },
  tableRowSelected: {
    border: "1px solid " + colors.amber[200] + " !important",
    backgroundColor: colors.amber[100] + " !important",
    // backgroundColor: theme.palette.warning.light + " !important",
  },
  memberRow: {
    display: "flex",
    width: "100%",
    "& .collaps-button": {
      padding: "11px",
    },
  },
  innerTable: {
    width: "100%",
    "& .inner-table-header": {
      cursor: "initial",
      background: colors.grey[50],
    },
  },
  linkButtonCell: {
    "& .MuiButton-root": {
      lineHeight: "1",
    },
  },
  noClick: {
    cursor: "initial",
  },
  hidden: {
    display: "none",
  },
  projectCompletedChip: {
    backgroundColor: theme.palette.success.main,
  },
  projectActiveChip: {
    backgroundColor: theme.palette.warning.main,
  },
  errorStatus: {
    backgroundColor: theme.palette.error.main,
  },
  toggleActive: {
    color: "#FFF",
    transition: "color 0.3s",
  },
});

// const useStyles = makeStyles((theme) => ({
//   projectTable: {
//     "& .MuiTableCell-root": {
//       padding: "6px 24px 6px 16px",
//     },
//     "& .MuiTableRow-root:hover": {
//       backgroundColor: "#f1f1f1",
//     },
//     "& *": {
//       boxSizing: "inherit",
//     },
//   },
//   clickableRow: {
//     cursor: "pointer",
//   },
//   projectDetailTable: {
//     marginBottom: "10px",
//   },
//   projectCompletedChip: {
//     backgroundColor: theme.palette.success.main,
//   },
//   projectActiveChip: {
//     backgroundColor: theme.palette.warning.main,
//   },
//   errorStatus: {
//     backgroundColor: theme.palette.error.main,
//   },
//   visuallyHidden: {
//     border: 0,
//     clip: "rect(0 0 0 0)",
//     height: 1,
//     margin: -1,
//     overflow: "hidden",
//     padding: 0,
//     position: "absolute",
//     top: 20,
//     width: 1,
//   },
//   buttonProgress: {
//     color: theme.palette.success.main,
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     marginTop: -12,
//     marginLeft: -12,
//   },
//   rowActionBtn: {
//     padding: 0,
//   },
// }));

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.sortState !== prevProps.sortState ||
      this.props.rows !== prevProps.rows
    ) {
      this.tableRef.recomputeRowHeights();
      this.tableRef.forceUpdateGrid();
    }
  }

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  TooltipCellRenderer = ({ cellData, columnIndex, dataKey, rowData }) => {
    const { columns, classes, rowHeight, onRowClick, t } = this.props;

    return dataKey === "status" ? (
      <TableCell
        component="div"
        variant="body"
        className={classes.tableCell}
        style={{ padding: "14px" }}
      >
        <Chip
          color="primary"
          size="small"
          className={clsx({
            [classes.projectActiveChip]: cellData === "analysing",
            [classes.projectCompletedChip]: cellData === "completed",
            [classes.errorStatus]:
              cellData === "failed" || cellData === "cancelled",
          })}
          label={t(cellData)}
        />
      </TableCell>
    ) : dataKey === "actions" ? (
      <TableCell component="div"></TableCell>
    ) : (
      <TooltipTableCell
        column={columns[columnIndex]}
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        height={rowHeight}
        alignment={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
        data={cellData}
        exceptions={[]}
      />
    );
  };

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }) => {
    const a11yProps = { "aria-rowindex": index + 1 };
    const rowDataLength = rowData ? Object.keys(rowData).length : 0;

    if (
      Boolean(rowDataLength) &&
      (onRowClick ||
        onRowDoubleClick ||
        onRowMouseOut ||
        onRowMouseOver ||
        onRowRightClick)
    ) {
      a11yProps["aria-label"] = "row";
      a11yProps.tabIndex = 0;

      if (onRowClick) {
        a11yProps.onClick = (event) => onRowClick({ event, index, rowData });
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event) =>
          onRowDoubleClick({ event, index, rowData });
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event) =>
          onRowMouseOut({ event, index, rowData });
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event) =>
          onRowMouseOver({ event, index, rowData });
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event) =>
          onRowRightClick({ event, index, rowData });
      }
    }

    const checkboxClickHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if ((this.props.checkedRows ?? []).includes(rowData.id)) {
        this.props.checkedRows.splice(
          this.props.checkedRows.indexOf(rowData.id),
          1
        );
      } else {
        this.props.checkedRows.push(rowData.id);
      }
      this.props.setCheckedRows([...this.props.checkedRows]);
    };

    if (rowData) {
      return Boolean(rowDataLength) ? (
        <div
          {...a11yProps}
          className={className}
          key={key}
          role="row"
          style={style}
        >
          <div className={this.props.classes.memberRow}>
            <Checkbox
              checked={(this.props.checkedRows ?? []).includes(rowData.id)}
              onClick={checkboxClickHandler}
            />
            {columns}
          </div>
        </div>
      ) : (
        <div style={style} className={className} key={index}>
          <Skeleton variant="rect" width={"100%"} height={22} />
        </div>
      );
    }
  };

  headerRenderer = ({ label, columnIndex }) => {
    const {
      sortState,
      setSortState,
      headerHeight,
      columns,
      setColumns,
      classes,
      filterState,
      checkedRows,
      setCheckedRows,
      memberIds,
      rows,
      t,
    } = this.props;

    return (
      <FilterTableHeader
        type="local"
        sortState={sortState}
        setSortState={setSortState}
        headerHeight={headerHeight}
        columns={columns}
        setColumns={setColumns}
        classes={classes}
        label={t(label)}
        columnIndex={columnIndex}
        filterState={filterState}
        headerIndex={columnIndex}
        tableKind="members"
        checkedRows={checkedRows}
        setCheckedRows={setCheckedRows}
        rowIds={memberIds}
        totalRowCount={rows.length}
        filterType={
          Object.keys(columns[columnIndex].filterValues).length < 5
            ? "select"
            : "search"
        }
      />
    );
  };

  render() {
    const { classes, columns, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref={(ref) => (this.tableRef = ref)}
            scrollToAlignment="start"
            height={height}
            width={width}
            rowHeight={53}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
            rowRenderer={this.rowRenderer}
          >
            {columns.map(({ name, ...other }, index) => {
              return (
                <Column
                  key={name}
                  cellDataGetter={cellDataGetter}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={clsx(classes.flexContainer)}
                  cellRenderer={this.TooltipCellRenderer}
                  dataKey={name}
                  {...other}
                  width={
                    other.width || width / columns.length - 62 / columns.length
                  }
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const MembersVirtualizedTable = withStyles(styles)(
  withTranslation()(MuiVirtualizedTable)
);

export const VirtualMembersTable = (props) => {
  const {
    userObj,
    membersData,
    memberIds,
    checkedRows,
    setCheckedRows,
    height,
  } = props;
  const [columns, setColumns] = useState([...membersTableHeader]);
  const [defaultRows, setDefaultRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filterState, setFilterState] = useState({});
  const [loading, setLoading] = useState(true);
  const [sortState, setSortState] = useState({
    username: "DESC",
  });
  const [isInitiallySorted, setIsInitiallySorted] = useState(false);

  function sortRows(rows, sortState) {
    const collator = new Intl.Collator("en", { sensitivity: "base" }); // support for other locales?
    for (let item in sortState) {
      rows.sort((a, b) => {
        return sortState[item] === "ASC"
          ? collator.compare(a[item], b[item])
          : collator.compare(b[item], a[item]);
      });
    }

    return rows;
  }

  useEffect(() => {
    let expandedModified = false;
    for (let i = 0; i < filteredRows.length; i++) {
      if (filteredRows[i].expanded) {
        expandedModified = true;
        filteredRows[i].expanded = false;
      }
    }
    if (expandedModified) {
      setFilteredRows([...filteredRows]);
    }
    let mounted = true;

    if (sortState) {
      const sortedRows = sortRows(filteredRows, sortState);
      if (mounted) {
        setFilteredRows(sortedRows.slice(0));
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [sortState]);

  useEffect(() => {
    setDefaultRows([...membersData]);

    for (let i = 0; i < columns.length; i++) {
      columns[i].filterValues = {};
      columns[i].activeFilterCount = 0;
      for (let j = 0; j < membersData.length; j++) {
        membersData[j]["full_name"] = getUserFullName(membersData[j]);

        if (
          columns[i].filterValues[membersData[j][columns[i].name]] === undefined
        ) {
          columns[i].filterValues[membersData[j][columns[i].name]] = false;
        }
      }
    }

    setColumns([...columns]);
    setLoading(false);
  }, [membersData]);

  useEffect(() => {
    let mounted = true;
    if (mounted && columns.length && defaultRows) {
      const filteredData = filterRows(defaultRows, columns);
      setFilteredRows(filteredData);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [defaultRows, columns]);

  useEffect(() => {
    if (filteredRows.length && !isInitiallySorted) {
      if (sortState && membersData.length) {
        setFilteredRows([...sortRows(membersData, sortState)]);
      }
      setIsInitiallySorted(true);
    }
  }, [filteredRows]);

  useEffect(() => {
    // let filtersets = {};
    let tmpFilters = {};
    // let newFilterCount = 0;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].activeFilterCount) {
        let tmpColFilters = [];
        const filterObjects = Object.entries(columns[i].filterValues);
        for (let j = 0; j < filterObjects.length; j++) {
          if (filterObjects[j][1]) {
            tmpColFilters.push(filterObjects[j][0]);
            //   newFilterCount++;
          }
        }
        tmpFilters[columns[i].name] = tmpColFilters;
      }
    }
    setFilterState(tmpFilters);
    // setFilterCount(newFilterCount);
  }, [columns]);

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <Paper>
        <MembersVirtualizedTable
          height={height ?? 500}
          columns={columns}
          setColumns={setColumns}
          rows={filteredRows}
          rowGetter={({ index }) => filteredRows[index]}
          rowCount={filteredRows.length}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
          memberIds={memberIds}
          //   onRowClick={rowClickHandler}
          //
          sort={sortState.sort}
          sortBy={undefined}
          sortDirection={undefined}
          //
          sortState={sortState}
          setSortState={setSortState}
          userObj={userObj}
          filterState={filterState}
        />
      </Paper>
    );
  }
};
