/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import Ideogram from "ideogram";
import "./CNVChromosomeChart.css";
import ChartWrapper from "./chartWrapper";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { ChartLegend } from "./components";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  ideoContainer: {
    "& #_ideogram": {
      minWidth: "80vw !important",
      margin: "auto",
      display: "block",
    },
    "& #_ideogramInnerWrap": {
      overflowX: "auto !important",
    },
    "& #_ideogramMiddleWrap": {
      height: "465px !important",
    },
  },
}));

function rangeMap(x, inMin, inMax, outMin, outMax) {
  return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

// function getColor(d, a1, a2) {
//   return a1.map((p, i) => Math.floor(a1[i] + d * (a2[i] - a1[i])));
// }

const CNVChromosomesChart = (props) => {
  const classes = useStyles();
  const { helpInformation, files } = props;
  const [ideoConf, setIdeoConf] = useState(undefined);
  // const [ideo, setIdeo] = useState(undefined);
  const [sex, setSex] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    try {
      fetch(getBaseURL() + files["report_information"].download_address)
        .then((response) => response.json())
        .then((data) => {
          const gender = data.gender
            ? data.gender === "XY"
              ? "male"
              : "female"
            : "male";
          if (mounted) {
            setSex(gender);
          }
        });
    } catch (error) {
      safeLogger(error);
      if (mounted) {
        setSex("male");
      }
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (sex) {
      try {
        fetch(getBaseURL() + files["cnv"].download_address)
          .then((response) => response.text())
          .then((data) => {
            const headerNames = ["chromosome", "start", "end", "log2"];
            const headerIndex = {};
            let cnvData = data.split("\n");
            const cnvHeaderRow = cnvData[0].split("\t");
            headerNames.map(
              (headerKey) =>
                (headerIndex[headerKey] = cnvHeaderRow.indexOf(headerKey))
            );

            const log2Range = [0, 0];
            const annotations = {};
            cnvData = cnvData.slice(1);

            for (let i = 0; i < cnvData.length; i++) {
              const cnvRow = cnvData[i].split("\t");
              const log2Val = parseFloat(cnvRow[headerIndex["log2"]]);
              if (log2Val >= 0) {
                if (log2Val > log2Range[1]) {
                  log2Range[1] = log2Val;
                }
              } else {
                if (log2Val < log2Range[0]) {
                  log2Range[0] = log2Val;
                }
              }
            }

            for (let i = 0; i < cnvData.length; i++) {
              const cnvRow = cnvData[i].split("\t");
              const chrName = cnvRow[headerIndex["chromosome"]].replace(
                "chr",
                ""
              );
              if (!chrName) continue;
              if (!Object.keys(annotations).includes(chrName)) {
                annotations[chrName] = [];
              }
              const start = parseInt(cnvRow[headerIndex["start"]]);
              const length = parseInt(cnvRow[headerIndex["end"]]) - start;
              //   const end = parseInt(cnvRow[headerIndex["end"]]);

              const log2Val = parseFloat(cnvRow[headerIndex["log2"]]);

              //   const rgbArr =
              //     log2Val >= 0
              //       ? getColor(
              //           rangeMap(log2Val, 0, log2Range[1], 0, 1),
              //           [255, 255, 255],
              //           [183, 28, 28]
              //         )
              //       : getColor(
              //           rangeMap(log2Val, log2Range[0], 0, 0, 1),
              //           [13, 71, 161],
              //           [255, 255, 255]
              //         );

              const color =
                log2Val >= 0
                  ? `rgba(13, 71, 161, ${rangeMap(
                      log2Val,
                      0,
                      log2Range[1],
                      0.15,
                      1
                    )})`
                  : `rgba(183, 28, 28, ${rangeMap(
                      log2Val,
                      log2Range[0],
                      0,
                      1,
                      0.15
                    )})`;

              //   const color =
              //     log2Val >= 0 ? "rgba(183, 28, 28, 0)" : "rgba(13, 71, 161, 0)";

              //   const color = `rgba(${rgbArr.join(",")}, 0.9)`;

              annotations[chrName].push([
                `test-${parseInt(Math.random() * 100)}`,
                start,
                length,
                color,
                log2Val,
              ]);
              //   annotationsArr.push({
              //     name: `test-${parseInt(Math.random() * 100)}`,
              //     chr: chrName,
              //     start: start,
              //     end: end,
              //     color: color,
              //   });
            }

            const annArr = [];
            Object.keys(annotations).map((annKey) =>
              annArr.push({ chr: annKey, annots: annotations[annKey] })
            );

            // safeLogger(annArr);
            // safeLogger(annotationsArr);

            const enhanceTooltipContent = (annot) => {
              //   safeLogger(annot);
              return annot;
            };

            if (mounted) {
              setIdeoConf({
                rotatable: false,
                //   showFullyBanded: false,
                //   showAnnotTooltip: false,
                //   rows: 2,
                organism: "human",
                sex: sex,
                chrWidth: 20,
                //   annotationsPath: "data/annotations/10_virtual_cnvs.json",
                //   annotations: sampleCNVData,

                annotations: {
                  log2Range: log2Range,
                  keys: ["chr", "start", "length", "color"],
                  annots: annArr,
                },

                //   annotations: annotationsArr,
                annotationsLayout: "overlay",
                container: "#ideo-container",
                onWillShowAnnotTooltip: enhanceTooltipContent,
              });
            }
          });
      } catch (error) {
        safeLogger(error);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [sex]);

  // useEffect(() => {
  //   let mounted = true;
  //   if (ideoConf) {
  //     const ideoIns = new Ideogram(ideoConf);
  //     if (mounted) {
  //       setIdeo(ideoIns);
  //     }
  //   }

  //   return function cleanup() {
  //     mounted = false;
  //   };
  // }, [ideoConf]);

  if (!ideoConf) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <ChartWrapper
        title={helpInformation.label}
        helpDesc={helpInformation.description}
        style={{ maxHeight: "none" }}
      >
        <Typography align="center">{helpInformation.label}</Typography>
        <ChartLegend
          style={{ margin: "24px" }}
          items={[
            { name: t("Gain"), color: "rgb(13, 71, 161)" },
            { name: t("Loss"), color: "rgb(183, 28, 28)" },
          ]}
        />
        <div className={classes.ideoContainer} id="ideo-container"></div>
      </ChartWrapper>
    );
  }
};

export default CNVChromosomesChart;
